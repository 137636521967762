import React from "react";

export default function ScanIcon({stroke}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 26.168 26.448"
    >
      <g id="SCA" transform="translate(-239.056 -745.25)">
        <g
          id="Layer_76"
          data-name="Layer 76"
          transform="translate(244.932 750.925)"
        >
          <rect
            id="Rectangle_174"
            data-name="Rectangle 174"
            width="14"
            height="14"
            rx="2"
            transform="translate(0.536 0.549)"
            fill="none"
            stroke={stroke}
            stroke-width="1.5"
          />
        </g>
        <path
          id="Path_25993"
          data-name="Path 25993"
          d="M389.934,6397.172v-5.543s-.239-1.733,2.39-1.733h5.616"
          transform="translate(-150.103 -5643.896)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Path_25996"
          data-name="Path 25996"
          d="M389.934,6389.9v5.543s-.239,1.733,2.39,1.733h5.616"
          transform="translate(-150.117 -5626.224)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Path_25994"
          data-name="Path 25994"
          d="M397.934,6397.172v-5.543s.239-1.733-2.39-1.733h-5.616"
          transform="translate(-133.471 -5643.896)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Path_25995"
          data-name="Path 25995"
          d="M397.934,6389.9v5.543s.239,1.733-2.39,1.733h-5.616"
          transform="translate(-133.485 -5626.224)"
          fill="none"
          stroke={stroke}
          stroke-linecap="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
  );
}
