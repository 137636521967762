/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  consumerRatingFlag: false,
  user: {
    fullname: "",
    email: "",
    phone: "",
    phone_code: "",
    is_email_verified: true,
    is_phone_verified: false,
    status: false,
    role_id: 0,
    created_at: "",
    rating: "",
    twenty_ltr_discount: null,
    five_ltr_discount: null,
    two_ltr_discount: null,
    one_ltr_discount: null,
    twohnd_ml_discount: null,
    fivehnd_ml_discount: null,
    consumer_id: 0,
  },
  consumer: {
    billing_name: "",
    gst: "",
    gst_file_url: "",
    profile_pic_url: "",
    status: true,
    rating: "",
    supplier_type: 0,
  },
  isLoading: true,
};

const userProfileReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_USER_PROFILE:
      //   console.log("------- (userProfileReducer) User Details--------");
      //   console.log(JSON.stringify(action));
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        user: action.payload.profile.user,
        consumer: action.payload.profile.consumer,
      };
    case actionType.SIGN_OUT:
      return {
        ...initialProfileState,
      };
    case actionType.RATING_BY_CONSUMER_STATE:
      return {
        ...state,
        consumerRatingFlag: action.payload,
      };

    default:
      return state;
  }
};

export default userProfileReducer;

//Just for Re
// profile: {
//     user: {
//       fullname: "",
//       email: "",
//       phone: "",
//       phone_code: "",
//       is_email_verified: false,
//       is_phone_verified: false,
//       status: false,
//       role_id: 0,
//       created_at: "",
//       rating: "",
//       twenty_ltr_discount: null,
//       five_ltr_discount: null,
//       two_ltr_discount: null,
//       one_ltr_discount: null,
//       twohnd_ml_discount: null,
//       fivehnd_ml_discount: null,
//       consumer_id: 0,
//     },
//     consumer: {
//       billing_name: "",
//       gst: "",
//       gst_file_url: "",
//       profile_pic_url: "",
//       status: true,
//       rating: "",
//       supplier_type: 0,
//     },
//   },
