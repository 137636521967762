/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../Assets/AppTheme/appColors";

export const styles = {
  button: {
    borderRadius: "20%",
    textTransform: "none",
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    textAlign: "center",
    fontSize: "14px",
    // minWidth: "160px",
    // minHeight: "40px",
    // maxWidth: "160px",
    // maxHeight: "40px",
    fontWeight: "bold",
    "&:hover": {
      boxShadow: "none",
      backgroundColor:appColors.textColorNew,
      // color: "white",
    },
    "&:active": {
      color: appColors.textColorNew,
    },
  },
};
