/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { Card, Box, Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import appColors from "../../../Assets/AppTheme/appColors";
import danger from "../../../Assets/newMobileIcon/Danger.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export default function PaymentPendingCard({
  data,
  key,
  handelCancelOrder,
  handelDetailClick,
}) {
  const history = useHistory();
  return (
    <Box
      sx={{
        margin: "0 .7rem .5rem .7rem",
        // height: "6rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        padding: ".5rem",
        boxShadow: 2,
        borderRadius: "10px",
        bgcolor: appColors.cardColor,
      }}
      key={key}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: ".2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: ".9rem",
              color: appColors.headerTextColor,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            The payment for your order {data?.order_id} was unsuccessful
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={danger}
              alt="Bookwater"
              style={{ height: "2rem", width: "2.5rem" }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            // marginTop: ".2rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => handelDetailClick(data?.order_id)}
            sx={{
              // height: "28px",
              fontSize: ".8rem",
              bgcolor: appColors.textColorNew,
              fontWeight: 500,
              marginRight: "1rem",
            }}
          >
            {window.innerWidth > 350 ? "View Order" : "View"}
          </Button>

          {data?.payment_type != 300 && (
            <Button
              variant="outlined"
              color="error"
              size="small"
              onClick={() => {
                handelCancelOrder(data?.order_id);
              }}
              sx={{
                // height: "28px",
                fontSize: ".8rem",
                color: appColors.Failed,
                fontWeight: 500,
                // marginRight: "1rem",
              }}
            >
              Cancel Order
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
