// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 24/Nov/2023
// File Name: Circles.jsx
// Author: Gowtham Prasath

/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { Component } from "react";
import { Button, Typography } from "@mui/material";
import { findDOMNode } from "react-dom";
import PropTypes from "prop-types";
import injectStyle from "./injectStyle";
import order from "../../Assets/newMobileIcon/Subscriptions.svg";
import scan from "../../Assets/newMobileIcon/SCAN.svg";
import refer from "../../Assets/newMobileIcon/Refer & Earn.svg";
import menu from "../../Assets/newMobileIcon/MENU.svg";
import waterDropImag from "../../Assets/newMobileIcon/waterDrop.svg";
import wallet from "../../Assets/newMobileIcon/Water Wallet.svg";
import products from "../../Assets/newMobileIcon/home/PurchaseCan.svg";
import address from "../../Assets/newMobileIcon/Booking/Delivery Address.svg";
import appColors from "../../Assets/AppTheme/appColors";
export default class Circles extends Component {
  constructor(props) {
    super(props);
    injectStyle(`
    @keyframes innerPulse {
      0%      { transform: scale(1.0); }    
      100%    { transform: scale(1.1); }
    }`);

    injectStyle(`
    @keyframes outerPulse {
      0%      { transform: scale(1.0); opacity: 0.9 }    
      100%    { transform: scale(2.0); opacity: 0.0 }
    }`);
    this.state = {
      pos: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
        width: 1,
      },
    };
    this.handleResize = () => {
      this.onResize(window.innerWidth);
    };
    this.handleClick = (e) => {
      if (!this.node.contains(e.target)) {
        // this.props.onClose();
      }
    };
  }

  onResize() {
    this.getComponentPosition();
    const vw = window.innerWidth * window.devicePixelRatio;
    const vh = window.innerHeight * window.devicePixelRatio;
    const drawTextAboveCenter = vh / 2 / this.state.pos.top < 1.0;
    const drawTextLeftOfCenter = vw / 2 / this.state.pos.top > 1.0;
    //Todo: check the other side
    const minimalDistanceToViewport =
      vw - (this.state.pos.left + this.state.pos.width / 2);
    this.setState({
      drawTextAboveCenter,
      drawTextLeftOfCenter,
      minimalDistanceToViewport,
    });
  }

  getStyles() {
    const { backgroundColor } = this.props;
    const {
      pos,
      open,
      drawTextAboveCenter,
      drawTextLeftOfCenter,
      minimalDistanceToViewport,
    } = this.state;
    const circleSize = pos.width + 40;
    const outerCircleSize = window.innerHeight + 50;
    const textBoxHeight = 100;
    const textBoxPaddingAtCircle = 800 * (1 / outerCircleSize) * 50;
    const textBoxPadding = 20;
    const textBoxWidth =
      outerCircleSize / 2 +
      Math.min(minimalDistanceToViewport, outerCircleSize / 2) -
      (textBoxPaddingAtCircle + textBoxPadding);
    return {
      root: {
        zIndex: 1000,
      },
      circles: {
        position: "absolute",
        top: pos.top - 20,
        left: pos.left - 20,
        opacity: open ? 1 : 0,
        pointerEvents: open ? "inherit" : "none",
      },
      pulseInnerCircle: {
        position: "absolute",
        transformOrigin: "center center",
        height: `${5.25}rem`,
        width: `${5.25}rem`,
        borderRadius: "50%",
        backgroundColor: "white",
        animation: open
          ? "innerPulse 872ms 1.2s cubic-bezier(0.4, 0, 0.2, 1) alternate infinite"
          : null,
        transform: open ? "scale(1)" : "scale(0)",
        transition: "transform 225ms cubic-bezier(0.4, 0, 0.2, 1)",
      },
      pulseOuterCircle: {
        position: "absolute",
        transformOrigin: "center center",
        height: `${5.25}rem`,
        width: `${5.25}rem`,
        borderRadius: "50%",
        backgroundColor: "white",
        opacity: 0,
        animation: open
          ? "outerPulse 1744ms 1.2s cubic-bezier(0.4, 0, 0.2, 1) infinite"
          : null,
      },
      outerCircle: {
        position: "absolute",
        transformOrigin: "center center",
        transform: open ? "scale(1.0)" : "scale(0.8)",
        transition:
          "transform 225ms cubic-bezier(0.4, 0, 0.2, 1), opacity 225ms cubic-bezier(0.4, 0, 0.2, 1)",
        marginTop: `-${outerCircleSize / 2 - circleSize / 2}px`,
        marginLeft: `-${outerCircleSize / 2 - circleSize / 2}px`,
        height: `${outerCircleSize}px`,
        width: `${outerCircleSize}px`,
        borderRadius: "50%",
        backgroundColor,
        opacity: open ? 0.96 : 0,
      },
      textBox: {
        fontFamily: "Roboto",
        position: "absolute",
        paddingLeft: textBoxPaddingAtCircle,
        paddingRight: textBoxPadding,
        width: window.innerWidth - 10,
        height: textBoxHeight,
        marginTop:
          this.props.title === "Water Wallet" ||
          this.props.title === "Water Drops"
            ? window.innerHeight / 1.7
            : this.props.title === "Add Address"
            ? window.innerHeight / 2
            : this.props.title === "Subscribe Your Order"
            ? window.innerHeight / 5.4
            : this.props.title === "Products" && window.innerHeight < 680
            ? window.innerHeight / 4.4
            : this.props.title === "Products" && window.innerHeight > 680
            ? window.innerHeight / 2.4
            : this.props.title === "Scan QR" ||
              this.props.title === "Refer & Earn"
            ? window.innerHeight / 8
            : window.innerHeight / 4.4,
        marginLeft:
          this.props.title === "Subscribe Your Order"
            ? outerCircleSize / 2.3
            : this.props.title === "Refer & Earn"
            ? outerCircleSize / 2.8
            : this.props.title === "Scan QR"
            ? outerCircleSize / 6
            : this.props.title === "Menu"
            ? outerCircleSize / 12
            : this.props.title === "Water Wallet"
            ? outerCircleSize / 10
            : this.props.title === "Water Drops"
            ? outerCircleSize / 5
            : this.props.title === "Add Address"
            ? outerCircleSize / 4
            : this.props.title === "Products"
            ? outerCircleSize / 3.6
            : "",
        color: "white",
        fontSize: ".9rem",
      },
    };
  }

  close() {
    if (this.content != null) {
      this.setState({ pos: this.content.getBoundingClientRect(), open: false });
    }
  }

  open() {
    if (this.content != null) {
      this.handleResize();
      this.setState({ pos: this.content.getBoundingClientRect(), open: true });
    }
  }

  componentDidMount() {
    this.initializeComponent();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the relevant props have changed
    if (prevProps.element !== this.props.element) {
      // If changed, reinitialize the component
      this.initializeComponent();
    }
  }

  initializeComponent() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleResize);
    window.addEventListener("mousedown", this.handleClick, false);
    this.content = findDOMNode(this.props.element);
    this.setState({ pos: this.content.getBoundingClientRect() });
    this.updateInterval = setInterval(() => {
      this.getComponentPosition();
    }, 50);
  }

  componentWillUnmount() {
    // Don't forget to remove event listeners and clear intervals
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleResize);
    window.removeEventListener("mousedown", this.handleClick, false);
    clearInterval(this.updateInterval);
  }

  getComponentPosition() {
    if (this.state.open) {
      const pos = this.content.getBoundingClientRect();
      if (pos.top !== this.state.pos.top || pos.left !== this.state.pos.left) {
        this.setState({ pos });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleResize);
    window.removeEventListener("mousedown", this.handleClick, false);
    clearInterval(this.updateInterval);
    this.content = null;
  }
  handleButtonClick = () => {
    if (this.props.componentState !== this.props.triggerState) {
      this.props.setPromptStates((prevStates) => ({
        ...prevStates,
        [this.props.componentState]: false,
        [this.props.triggerState]: true,
      }));
    } else {
      this.props.setPromptStates((prevStates) => ({
        ...prevStates,
        bookWater: false,
        scanQR: false,
        subscribe: false,
        menu: false,
        wallet: false,
        waterdrop: false,
        address: false,
        products: false,
      }));
      localStorage.setItem("visitedBeforeHome", true);
      localStorage.setItem("isUpdatedTour", true);
    }
  };
  render() {
    const styles = this.getStyles();

    return (
      <div
        ref={(node) => (this.node = node)}
        style={styles.root}
        onClick={this.handleButtonClick}
      >
        <div style={styles.circles}>
          <div
            style={{
              zIndex: 33,
              position: "absolute",
              marginLeft:
                this.props.title === "Water Wallet"
                  ? "1.5rem"
                  : this.props.title === "Water Drops"
                  ? "1.5rem"
                  : "1.5rem",
              marginTop:
                this.props.title === "Water Wallet"
                  ? "1.5rem"
                  : this.props.title === "Water Drops"
                  ? "1.5rem"
                  : "1.5rem",
            }}
          >
            <img
              style={{ height: "2.3rem", width: "2.3rem" }}
              src={
                this.props.title === "Subscribe Your Order"
                  ? order
                  : this.props.title === "Refer & Earn"
                  ? refer
                  : this.props.title === "Scan QR"
                  ? scan
                  : this.props.title === "Menu"
                  ? menu
                  : this.props.title === "Water Drops"
                  ? waterDropImag
                  : this.props.title === "Water Wallet"
                  ? wallet
                  : this.props.title === "Add Address"
                  ? address
                  : this.props.title === "Products"
                  ? products
                  : ""
              }
              alt=""
            />
          </div>
          <div style={styles.outerCircle}>
            <div style={styles.textBox}>
              <Typography
                variant="title"
                style={{ color: "white", fontWeight: 600, fontSize: "1.2rem" }}
              >
                {this.props.title}
              </Typography>
              <br />
              <Typography
                variant="body1"
                style={{ color: "white", fontWeight: 500 }}
              >
                <i>{this.props.description}</i>
              </Typography>{" "}
              <Typography
                variant="body1"
                style={{ color: "white", marginTop: ".5rem" }}
              >
                {this.props.subdescription}
              </Typography>
              <Typography
                variant="body1"
                style={{ color: "white", marginTop: ".5rem" }}
              >
                {this.props.subdescription1}
              </Typography>
            </div>
          </div>
          <div style={styles.pulseInnerCircle} />
          <div style={styles.pulseOuterCircle} />
        </div>
      </div>
    );
  }
}

Circles.propTypes = {
  /** Fired when the the prompt is visible and clicked. */
  onClose: PropTypes.func.isRequired,
  /** Override the inline-styles of the circles element. */
  style: PropTypes.object,
  /** Defines the title text **/
  title: PropTypes.string.isRequired,
  /** Defines the description text **/
  description: PropTypes.string.isRequired,
};
