// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  statusCode:0,
  error: true,
  pinCodeAreas: [],
  pincodeAreaFlag: false, 
  isTankersLoaded: false,   
  bookingSummaryFlag: false,
  isOrderInitiated: false,
  convFeePercent: "",
  orderID: "", 
  gateWayFee: 0,
  waterTankers: [],
  sewageTankers: [],
  pdwList: [],
  is_subscribed: false,
  // repeat_order: []
};

const bookNowReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.SEARCH_AVAILABILITY:
      return {
        ...state,
        error: action.payload.error,
        pinCodeAreas: action.payload.data.data,
        pincodeAreaFlag: action.payload.statusFlag,
        isTankersLoaded: action.payload.statusFlag,
      };

    case actionType.WATER_TANKER_LIST:
      return {
        ...state,
        error: action.payload.error,
        convFeePercent: action.payload.data.conv_fee_percent,
        gateWayFee: action.payload.data.gateway_fcharges,
        waterTankers: action.payload.data.ltb_tankers,
        isTankersLoaded: action.payload.statusFlag,
      };
    case actionType.SEWAGE_TANKER_LIST:
      return {
        ...state,
        error: action.payload.error,
        convFeePercent: action.payload.data.conv_fee_percent,
        gateWayFee: action.payload.data.gateway_fcharges,
        sewageTankers: action.payload.data.ltb_tankers,
        isTankersLoaded: action.payload.statusFlag,
      };
    // case actionType.PDW_TANKER_LIST:
    //   console.log("actionType.PDW_TANKER_LIST")
    //   console.log(action.payload)
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     convFeePercent: action.payload.data.conv_fee_percent,
    //     gateWayFee: action.payload.data.gateway_fcharges,
    //     pdwList: action.payload.data.packagedrinkingwater,
    //     is_subscribed: action.payload.data.is_order_subscription_exists,
    //     isTankersLoaded: action.payload.statusFlag,
    //   };
    case actionType.BOOK_TANKER_ORDER:
      return {
        ...state,
        error: action.payload.error,
        orderId: action.payload.data.order_id[0],
        message: action.payload.data.msg,
        isOrderInitiated: action.payload.statusFlag,
      };
    case actionType.BOOK_PDW_ORDER:
      return {
        ...state,
        error: action.payload.error,
        orderId: action.payload.data.order_id,
        message: action.payload.data.msg,
        isOrderInitiated: action.payload.statusFlag,
        statusCode:action.payload.errorCode,
      };
      case actionType.BOOK_PDW_SUBSCRIBE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        orderId: action.payload.data.order_id,
        message: action.payload.data.msg,
        isOrderInitiated: action.payload.statusFlag,
      };
      case actionType.UPDATE_PDW_SUBSCRIBE_ORDER:
      return {
        ...state,
        error: action.payload.error,
        orderId: action.payload.data.order_id,
        message: action.payload.data.msg,
        isOrderInitiated: action.payload.statusFlag,
      };
      case actionType.GET_REPEAT_ORDER: 
      return {
        ...state,
        error: action.payload.error, 
        orderId: action.payload.data.order_id,
        message: action.payload.data.msg,
        // isOrderInitiated: action.payload.statusFlag,
        repeatOrder: action.payload.data.data,
      };
      case actionType.SEND_EMAIL_OTP:
        return {
          ...state,
          error: action.payload.error,
          sendEmailOtp: action.payload.data
        }
    case actionType.SIGN_OUT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default bookNowReducer;
