/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import * as FcIcons from "react-icons/fc";
import { Snackbar, Link } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import chipIcon from "../../../Assets/newMobileIcon/chip.svg";
import { styles } from "../../../Pages/MyAccounts/tabs/virtualAccount/virtualBankAccountStyle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { makeStyles } from "@mui/styles";
import Bicon from "../../../Assets/newMobileIcon/Water wallet without Card Background.svg";
import { useDispatch, useSelector } from "react-redux";
import { Alert } from "@mui/material";
import { GetWalletDetails } from "../../../Redux/actions/paymentMethodAction";
import CustomConsole from "../../../coustomConsole";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles({
  atmCard: {
    width: "auto",
    backgroundColor: "#0690fd",
    color: "white",
    borderRadius: 10,
    margin: "1rem",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  cardNumber: {
    fontSize: ".8rem",
  },
  cardHolder: {
    fontSize: ".8rem",
  },
  expiration: {
    fontSize: ".8rem",
  },
});
function VBDetailsDialogBox(props) {
  const dispatch = useDispatch();
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const classes = useStyles();
  CustomConsole("VBDetailsDialogBox ");
  CustomConsole(props);
  CustomConsole(props.currentWalletAmount);
  React.useEffect(() => {
    CustomConsole(walletDetails);
    if (walletDetails.walletAmount > props.currentWalletAmount) {
      CustomConsole("amount changed");
      props.handleClose();
      props.setWalletFlag(true);
    }
  }, [walletDetails]);

  React.useEffect(() => {
    let interval = setInterval(() => {
      dispatch(GetWalletDetails());
      // CustomConsole(walletDetails);
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const bgcolor = appColors.backGroundGradient;
  const bankDetails = [
    {
      icon: <FcIcons.FcPortraitMode size={20} />,
      heading: "Customer Name :",
      answer: props.bankDetails.name,
    },
    {
      icon: <FcIcons.FcSmartphoneTablet size={20} />,
      heading: "Mobile Number :",
      answer: "+91 - " + props.bankDetails.mobileNo,
    },
    {
      icon: <FcIcons.FcInvite size={20} />,
      heading: "Email Address :",
      answer: props.bankDetails.email,
    },
    {
      icon: <FcIcons.FcRules size={20} />,
      heading: "Account Number :",
      answer: props.bankDetails.virtualAccountNo,
    },
    {
      icon: <FcIcons.FcDataEncryption size={20} />,
      heading: "IFSC Code :",
      answer: props.bankDetails.ifscCode,
    },
    {
      icon: <FcIcons.FcLibrary size={20} />,
      heading: "Bank Name :",
      answer: props.bankDetails.bankName,
    },
    {
      icon: <FcIcons.FcDebt size={20} />,
      heading: "Benificiary Name :",
      answer: props.bankDetails.beneficiaryName,
    },
  ];
  const DISABLE_PAYSHARP = 0;
  const handleDisablePaysharp = () => {
    props.handlePaysharpStatus({
      orderId: props.orderId,
      status: DISABLE_PAYSHARP,
    });
    CustomConsole("Diable Pay Sharp for the Order");
  };
  const handleClose = () => {
    props.handleClose();
  };
  setTimeout(() => {
    props.handleClose();
  }, 300000);

  const copyReferalCode = (code) => {
    CustomConsole(code);
    navigator.clipboard.writeText(code);
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 1000);
  };
  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      onClose={handleClose}
      sx={{ justifyContent: "center", alignItem: "center" }}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.DialogContent
        sx={{
          backgroundColor: appColors.secondaryCard,
        }}
      >
        <Material.Card className={classes.atmCard}>
          <Material.CardContent sx={{ zIndex: 5 }}>
            <Material.Box sx={styles.chipCard1}>
              <Material.Box>
                <Material.Typography className={classes.expiration}>
                  Beneficiary:
                </Material.Typography>
                <Material.Typography className={classes.expiration}>
                  {props.bankDetails.beneficiaryName}{" "}
                  <ContentCopyIcon
                    sx={{
                      color: appColors.lightGreen,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyReferalCode(props.bankDetails.beneficiaryName);
                    }}
                  />
                </Material.Typography>
                <Material.Typography className={classes.expiration}>
                  Bank:
                </Material.Typography>
                <Material.Typography className={classes.expiration}>
                  {props.bankDetails.bankName}{" "}
                  <ContentCopyIcon
                    sx={{
                      color: appColors.lightGreen,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyReferalCode(props.bankDetails.bankName);
                    }}
                  />
                </Material.Typography>
              </Material.Box>
            </Material.Box>
            <Material.Box sx={styles.chipCard}>
              <Material.Box>
                <Material.Typography className={classes.cardNumber}>
                  Acct:
                </Material.Typography>

                <Material.Typography className={classes.cardNumber}>
                  {props.bankDetails.virtualAccountNo}{" "}
                  <ContentCopyIcon
                    sx={{
                      color: appColors.lightGreen,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyReferalCode(props.bankDetails.virtualAccountNo);
                    }}
                  />
                </Material.Typography>
                <Material.Typography className={classes.cardHolder}>
                  Ifsc:
                </Material.Typography>

                <Material.Typography className={classes.cardHolder}>
                  {props.bankDetails.ifscCode}{" "}
                  <ContentCopyIcon
                    sx={{
                      color: appColors.lightGreen,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyReferalCode(props.bankDetails.ifscCode);
                    }}
                  />
                </Material.Typography>
              </Material.Box>
              <Material.Box>
                <img
                  alt=""
                  src={chipIcon}
                  style={{ height: "2rem", marginTop: "2rem" }}
                />
              </Material.Box>
            </Material.Box>
          </Material.CardContent>
          <img
            alt=""
            src={Bicon}
            style={{
              position: "absolute",
              marginLeft: { sm: "15rem", xs: "10rem" },
              objectFit: "cover",
            }}
          />
        </Material.Card>
        <Material.Box>
          <Material.Typography
            sx={{
              color: appColors.darkGray,
              fontSize: "14px",
              marginTop: "10px",
            }}
          >
            Note: To make a Bank Transfer to your water wallet,add your virtual
            bank account details mentioned above in your banks
            Mobile/NetBanking.
          </Material.Typography>
        </Material.Box>

        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          {props.price ? (
            <Material.Button
              variant="contained"
              onClick={handleDisablePaysharp}
              sx={{
                marginRight: "10px",
                width: "170px",
                color: "white",
                backgroundColor: "blue",
                "&:hover": { backgroundColor: "green" },
              }}
            >
              Disable Paysharp
            </Material.Button>
          ) : (
            ""
          )}

          <Material.Button
            variant="contained"
            onClick={handleClose}
            size="small"
            sx={{
              marginLeft: "10px",
              width: "170px",
              color: "white",
              backgroundColor: appColors.textColorNew,
              "&:hover": { backgroundColor: "green" },
            }}
          >
            Close
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="info" sx={{ width: "100%" }}>
          Copied to the clipboard
        </Alert>
      </Snackbar>
    </Material.Dialog>
  );
}

export default VBDetailsDialogBox;
