/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description NewTranscationcard.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Typography, Box, Snackbar, Link } from "@mui/material";
import { Alert } from "@mui/material";
import moment from "moment";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styles } from "./transactionsStyle";
import Rupee from "../../Assets/AppIcons/Rupee.svg";
import appColors from "../../Assets/AppTheme/appColors";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

const tankerType = {
  WATER: 1,
  SEWAGE: 2,
  PACKAGED_DRINKING_WATER: 3,
};
export default function NewTranscationCard(props, key) {
  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD MMM YYYY");
    return momentEndDate;
  };
  // console.log("Transactions: " + JSON.stringify(props));
  // console.log(props.transactions);
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const copyReferalCode = (code) => {
    // console.log(code);
    navigator.clipboard.writeText(code);
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 1000);
  };

  //function to handel button click
  const handleButtonClick = () => {
    window.fcWidget.open();
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        margin: "0 0 2rem 0",
        justifyContent: "space-around",
        borderRadius: ".5rem",
      }}
      key={key}
    >
      <Card
        sx={{
          width: 300,
          borderRadius: ".4rem",
          boxShadow: 5,
          backgroundColor: appColors.cardColor,
        }}
      >
        <Box
          sx={[
            styles.transcationCardHeader,
            {
              backgroundColor:
                props.transactions.payment_status === "SUCCESS"
                  ? appColors.Sucsess
                  : appColors.Failed,
            },
          ]}
        >
          {" "}
          <img
            src={Rupee}
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              objectFit: "cover",
              zIndex: 1,
            }}
          />
          <Typography
            sx={{
              color: "black",
              fontSize: ".8rem",
              fontWeight: "bold",
            }}
          >
            {props.transactions.payment_status === "SUCCESS"
              ? "SUCCESSFUL"
              : props.transactions.payment_status}
          </Typography>
          <Typography
            sx={{
              color: "black",
              zIndex: 5,
              fontWeight: "bold",
              fontSize: ".9rem",
            }}
          >
            {" "}
            {endDateConverter(props.transactions.transaction_ts)}{" "}
          </Typography>
          {/* <img
                        src={cardDesign}
                        style={{ position: "absolute", height: '100%', objectFit: "cover", zIndex: 1 }}
                    /> */}
        </Box>
        <CardContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginBottom: ".5rem",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography sx={{ color: appColors.darkGray, fontSize: ".8rem" }}>
                <span
                  style={{
                    color: appColors.textColorNew,
                    fontSize: ".8rem",
                    fontWeight: "bold",
                  }}
                >
                  Ref #:
                </span>
                {props.transactions.transaction_id}
                <span>
                  {" "}
                  <ContentCopyIcon
                    sx={{
                      color: "#49B1EC",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      copyReferalCode(props.transactions.transaction_id);
                    }}
                  />
                </span>
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginBottom: ".5rem",
              justifyContent: "space-between",
            }}
          >
            {" "}
            <Box sx={{ marginRight: "1rem" }}>
              <Typography
                sx={{
                  color: appColors.textColorNew,
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                Order Id
              </Typography>
              <Typography sx={{ color: appColors.darkGray, fontSize: ".8rem" }}>
                {props.transactions.order_id}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: appColors.textColorNew,
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                Debit Amount
              </Typography>
              <Typography sx={{ color: appColors.darkGray, fontSize: ".8rem" }}>
                {" "}
                ₹ {props.transactions.amount}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginBottom: ".5rem",
              justifyContent: "space-between",
            }}
          >
            {/* <Box>
                            <Typography sx={{ color: appColors.textColorNew, fontSize: ".8rem", fontWeight: "bold" }}>Order Id</Typography>
                            <Typography sx={{ color:appColors.darkGray, fontSize: ".8rem" }}>  {props.transactions.order_id}</Typography>
                        </Box> */}

            <Box>
              <Typography
                sx={{
                  color: appColors.textColorNew,
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                Payment Method
              </Typography>
              <Typography sx={{ color: appColors.darkGray, fontSize: ".8rem" }}>
                {props.transactions.payment_method}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: appColors.textColorNew,
                  fontSize: ".8rem",
                  fontWeight: "bold",
                  marginRight: "2.5rem",
                }}
              >
                Status
              </Typography>
              <Typography sx={{ color: appColors.darkGray, fontSize: ".8rem" }}>
                {props.transactions.payment_status}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: ".8rem",
            }}
          >
            <PrimaryButton
              handleButtonClick={handleButtonClick}
              loading={false}
              btnName="Contact Support"
            />
          </Box>
        </CardContent>
        <Snackbar
          open={openSnackBar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "center", zIndex: 1 }}
        >
          <Alert severity="info" sx={{ width: "100%" }}>
            Copied to the clipboard
          </Alert>
        </Snackbar>
      </Card>
    </Box>
  );
}
