/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import appColors from "../../../Assets/AppTheme/appColors";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import { Box, Typography } from "@mui/material";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { ListAvailabileTankers } from "../../../Redux/actions/bookNowAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
export default function ExitBookingDialog({ open, onClose, pageName }) {
  const histroy = useHistory();
  //Function for handling cancel order Option
  const handleClose = () => {
    onClose(false);
  };

  //function to handel route button
  const handelRouteButton = () => {
    histroy.push("/mobile");
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: appColors.secondaryCard,
            color: appColors.darkGray,
            display: "flex",
            justifyContent: "center",
          }}
        >
          Exit Booking?
        </DialogTitle>
        <DialogContent
          sx={{ bgcolor: appColors.secondaryCard, color: appColors.darkGray }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: appColors.darkGray }}
          >
            <Typography>
              {pageName && pageName.includes("editSubscribe")
                ? "Are you sure want to leave this page without saving your changes?"
                : "Are you sure you want to cancel your current booking?"}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: appColors.secondaryCard,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handelRouteButton}
            sx={{
              bgcolor: appColors.textColorNew,
              color: appColors.white,
              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            Exit
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: appColors.textColorNew,
              color: appColors.white,
              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            Continue
          </Button>
        </DialogActions>
        <img
          alt=""
          src={closeIcon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            height: "30px",
          }}
          onClick={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
}
