/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description BookWater App Authentication Page With Forget password Component
 * @date 13/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and Other Files

import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
// import authStyles from "../../Pages/Authentiacation/authenticationStyles";
import AuthTextField from "../TextFields/AuthTextFields";
import appMeta from "../../Assets/AppMeta/appMeta";
import PrimaryButton from "../Buttons/PrimaryButton";
// import ForgetPasswordStepper from "./ForgetPasswordStepper";
import { useDispatch, useSelector } from "react-redux";
// import { forgotPasswordSendOTP } from "../../Redux/Actions/ProfileActions";
import validator from "validator";
// import useToastMessage from "../../Hooks/useToast";
// import appConstants from "../../Assets/AppMeta/appConstants";
import { toast } from "react-toastify";
import authStyles from "../../Pages/Authentication/authenticationStyles";
import { forgotPasswordSendOTP } from "../../Redux/actions/ProfileActions";
import CustomConsole from "../../coustomConsole";

const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    // padding: "1%",
    marginTop: "3%",
    gap: "1%",
    marginRight:".6rem"
  },
};

function ForgetPassword(props) {
  // Variables declared

  const [isEpValid, setIsEpValid] = React.useState("");


  const [submitEmailPhoneFlag, setSubmitEmailPhoneFlag] = React.useState(false);
  // Importing dispatch to create actions for (Redux)
  const dispatch = useDispatch();
  // Importing useSelector to use the authentication store
  const authenticationDetails = useSelector((state) => state.authReducer);
  // Handling button click after for sending OTP to User
  function validateEmailPhone(emailPhone) {
    let ret = false;
    if (validator.isEmail(emailPhone) === true) {
      ret = true;
      props.setEmail(emailPhone);
    } else {
      if (
        emailPhone.length === 10 &&
        validator.isMobilePhone(emailPhone) === true
      ) {
        ret = true;
        props.setPhone(emailPhone);
      } else {
        ret = false;
      }
    }
    return ret;
  }
  const handleSubmitSendOTP = () => {
    if (validateEmailPhone(props.emailPhone) === true) {
      setSubmitEmailPhoneFlag(true);
      CustomConsole(props.emailPhone);
      if (isEpValid === true) {
        // Email/Phone is proper
        dispatch(forgotPasswordSendOTP(props.email, props.phone));
        props.setEmailPhone("");
      }
    } else {
      CustomConsole("Error");
      props.setSubmitToVerify(false);
      toast.dark("Please enter valid Input", {
        position: "bottom-right",
        autoClose: 1500,
        type: "error",
      });
    }
  };

  useEffect(() => {
  
    validateEmailPhone(props.emailPhone);
  }, [props.emailPhone, props.email, props.phone]);

  useEffect(() => {
    CustomConsole(authenticationDetails);
    if (
      authenticationDetails.emailPhoneFPError === false &&
      submitEmailPhoneFlag === true
    ) {
      props.setSubmitToVerify(true);
      setSubmitEmailPhoneFlag(false);
    } else {
      props.setSubmitToVerify(false);
      setSubmitEmailPhoneFlag(false);
    }
  }, [authenticationDetails]);
  return (
    <Box sx={styles.mainContainer}>
      <Typography sx={authStyles.authHeader}>
        Enter your registered Phone number to receive OTP
      </Typography>

      {/* Field to enter the Email/Phone */}
      <AuthTextField
        lable={"Phone"}
        type={appMeta.textFieldTypes.PHONE}
        setValue={props.setEmailPhone}
        value={props.emailPhone}
        isValid={isEpValid}
        setIsValid={setIsEpValid}
        isRequired={true}
        isHintRequired={false}
      />
      <PrimaryButton btnName="Submit" handleButtonClick={handleSubmitSendOTP} />
    </Box>
  );
}

export default ForgetPassword;
