/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";
//Get all the Packaged Drinking Water Payment Pending Orders
export const SearchAvailability = (pincode) => {
  // CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/TM/searchAreasbyPincode`, { pincode });
      CustomConsole("SearchAvailability Areas: ");
      CustomConsole(response);
      // CustomConsole(`response.data.data.length: ${response.data.data.length}`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        if (response.data.data.length > 0) {
          // CustomConsole("success: Response in SearchAvailability Action Error");
          dispatch({
            type: actionType.SEARCH_AVAILABILITY,
            payload: { data: response.data, statusFlag: true },
          });
          return response
        } else {
          toast.dark("PIN Code May Invalid/ No Areas found for the PIN Code", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in SearchAvailability Action Error");
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Get all the Packaged Drinking Water Payment Pending Orders
//TM/search_tankers/1/pincode/603102/id/8885313161?status=true&price_range=&tds_range=
//&capacity_range=&supplier_rating=&area_name=Manamai
//Tanker Type: 1/2/3
//PinCode
//Phone Number
//status: verified/not
//price_range
//tds_range
//capacity_range
//supplier_rating
//area_name
//TM/search_tankers/${tankerType}/pincode/${pinCode}/${phone}/filterData
export const ListAvailabileTankers = (data) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/TM/search_tankers/${data.tankerType}/pincode/${data.pincode}/id/${data.phone}?status=${data.status}&price_range=${data.price_range}&tds_range=${data.tds_range}&capacity_range=${data.capacity_range}&supplier_rating=${data.supplier_rating}&area_name=${data.area_name}`
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("REsponse for search tankers");
        CustomConsole(response);
        let act = "";
        switch (data.tankerType) {
          case "1":
            act = actionType.WATER_TANKER_LIST;
            break;
          case "2":
            act = actionType.SEWAGE_TANKER_LIST;
            break;
          case "3":
            act = actionType.PDW_TANKER_LIST;
            break;
          default:
            break;
        }

        dispatch({
          type: act,
          payload: {
            data: response.data,
            // error: response.data.error,
            statusFlag: true,
          },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const RepeatOrder = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get("/OM/orders/RecentOrder");

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("REsponse for Repeat order");
        CustomConsole(response);
        dispatch({
          type: actionType.GET_REPEAT_ORDER,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const SendEmailOtp = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post("/SCM/sendEmailVerifyOTP");

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("REsponse: " + JSON.stringify(response.data));
        // CustomConsole(response.data.verifyToken);
        sessionStorage.setItem("verify_token", response.data.verifyToken);
        const verified_token = sessionStorage.getItem("verify_token");
        // CustomConsole("verified_token");
        // CustomConsole(verified_token);
        // CustomConsole("response send email otp");
        // CustomConsole(response);
        dispatch({
          type: actionType.SEND_EMAIL_OTP,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Book a Tanker (Water/Sewage)
export const BookTanker = (bookingDetails) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/orders`, bookingDetails);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("bookTanker RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_TANKER_ORDER,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Book  Packaged Drinking Water
export const BookPackagedDrinkingWater = (
  bookingDetails,
  paymentMethod,
  depositDetails
) => {
  let api = useAxios();
  CustomConsole("BookPackagedDrinkingWater");
  CustomConsole(bookingDetails);
  CustomConsole(paymentMethod);
  CustomConsole(depositDetails);
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/OM/PackagedDrinkingorders`,
        bookingDetails
      );
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("BookPackagedDrinkingWater RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_PDW_ORDER,
          payload: {
            data: { order_id: response.data.order_id },
            statusFlag: true,
          },
        });
      } else {
        dispatch({
          type: actionType.BOOK_PDW_ORDER,
          payload: {
            data: response.data,
            errorCode: 400,
          },
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      CustomConsole(error.response.data);
      dispatch({
        type: actionType.BOOK_PDW_ORDER,
        payload: {
          data: error.response.data,
          statusFlag: false,
          errorCode: 400,
        },
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

// export const CreateOrder = (orderFinalPrice) => {
//   let api = useAxios();
//   return async (dispatch) => {
//     try {
//       let response = await api.post(`/PM/razorpay/createOrder`, {
//         amount: parseFloat(orderFinalPrice) * 100,
//       });
//       CustomConsole("--------PaybyRazorPay Redux--------");
//       CustomConsole(response);
//       if (response.status === AppMetaData.httpStatus.SUCCESS) {
//         CustomConsole("--------PaybyRazorPay Inside Redux--------");
//         dispatch({
//           type: actionType.PAY_BY_RAZORPAY_NEW,
//           payload: { data: response.data, statusFlag: true },
//         });
//       }
//     } catch (error) {
//       CustomConsole("Error: " + JSON.stringify(error));
//     }
//   };
// };

export const SubscriptionOrderPWD = (p) => {
  let api = useAxios();
  CustomConsole("SubscriptionOrderPWD");

  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/subscribeOrder`, p);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("SubscriptionOrderPWD RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_PDW_SUBSCRIBE_ORDER,
          payload: { data: response.data, statusFlag: true },
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast.dark(`Unable to process the subscription`, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const UpdateSubscriptionOrder = (p) => {
  let api = useAxios();
  CustomConsole("UpdateSubscriptionOrder");

  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/updateSubscribeOrder`, p);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("UpdateSubscriptionorder RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_PDW_SUBSCRIBE_ORDER,
          payload: { data: response.data, statusFlag: true },
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        toast.dark(`Unable to process the subscription`, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
// export const SubscriptionOrderPWD = (subscriptionDetails, bookingDetails, paymentMethod, depositDetails) => {
//   CustomConsole("-------------- SubscriptionOrderPWD -----------------");
//   let api = useAxios();
//   return async (dispatch) => {
//     try {
//       let response = await api.post(`/OM/subscribeOrder`, subscriptionDetails, bookingDetails);
//       if (response.status === AppMetaData.httpStatus.SUCCESS) {
//         CustomConsole("Subscribed RESPONSE: ");
//         CustomConsole(response);
//         dispatch({
//           type: actionType.BOOK_PDW_SUBSCRIBE_ORDER,
//           payload: { data: response.data, statusFlag: true },
//         });
//       } else {
//         CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
//       }
//     } catch (error) {
//       CustomConsole("Error: " + JSON.stringify(error));
//       toast.dark(error.response.data.msg, {
//         position: "bottom-right",
//         autoClose: 3000,
//         type: "error",
//       });
//     }
//   };
// };

//Book  Packaged Drinking Water
export const AssignSupplierForPDW = (bookingDetails) => {
  CustomConsole("-------------- AssignSupplierForPDW -----------------");
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/AssignSupplier`, bookingDetails);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("AssignSupplierForPDW RESPONSE: ");
        CustomConsole(response);
        dispatch({
          type: actionType.BOOK_PDW_ORDER,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

/*
[07:28 pm] Tahir Shaik
{   "error":false,   "order_id":[      "BTORD-7040"   ],   "msg":"Order Initiated!!!",   "supplier_id":286,   "packaged_drinking_water":{      "fivehndml_capacity_amt":0,      "fivehndml_capacity_qty":0,      "fivehndml_capacity_totamt":0,      "fiveltr_capacity_amt":0,      "fiveltr_capacity_qty":0,      "fiveltr_capacity_totamt":0,      "oneltr_capacity_amt":0,      "oneltr_capacity_qty":0,      "oneltr_capacity_totamt":0,      "twenty_ltr_capacity_amt":50,      "twenty_ltr_capacity_totamt":500,      "twentyltr_capacity_qty":10,      "twohndml_capacity_amt":0,      "twohndml_capacity_qty":0,      "twohndml_capacity_totamt":0,      "twoltr_capacity_amt":0,      "twoltr_capacity_qty":0,      "twoltr_capacity_totamt":0   }}

[07:28 pm] Tahir Shaik
{
"order_id":"BTORD-7040",
"packaged_drinking_water":{
"fivehndml_capacity_amt":0.0,
"fivehndml_capacity_qty":0.0,
"fivehndml_capacity_totamt":0.0,
"fiveltr_capacity_amt":0.0,
"fiveltr_capacity_qty":0.0,
"fiveltr_capacity_totamt":0.0,
"oneltr_capacity_amt":0.0,
"oneltr_capacity_qty":0.0,
"oneltr_capacity_totamt":0.0,
"twenty_ltr_capacity_amt":50.0,
"twenty_ltr_capacity_totamt":500.0,
"twentyltr_capacity_qty":10.0,
"twohndml_capacity_amt":0.0,
"twohndml_capacity_qty":0.0,
"twohndml_capacity_totamt":0.0,
"twoltr_capacity_amt":0.0,
"twoltr_capacity_qty":0.0,
"twoltr_capacity_totamt":0.0
},
"payment_method":"PAYSHARP",
"supplier_id":"286"
}

{
"order_id":"BTORD-7040",
"packaged_drinking_water":{
"fivehndml_capacity_amt":0.0,
"fivehndml_capacity_qty":0.0,
"fivehndml_capacity_totamt":0.0,
"fiveltr_capacity_amt":0.0,
"fiveltr_capacity_qty":0.0,
"fiveltr_capacity_totamt":0.0,
"oneltr_capacity_amt":0.0,
"oneltr_capacity_qty":0.0,
"oneltr_capacity_totamt":0.0,
"twenty_ltr_capacity_amt":50.0,
"twenty_ltr_capacity_totamt":500.0,
"twentyltr_capacity_qty":10.0,
"twohndml_capacity_amt":0.0,
"twohndml_capacity_qty":0.0,
"twohndml_capacity_totamt":0.0,
"twoltr_capacity_amt":0.0,
"twoltr_capacity_qty":0.0,
"twoltr_capacity_totamt":0.0
},
"payment_method":"PAYSHARP",
"supplier_id":"286"
}
*/
