import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";
export const ReturnCansAction = (reqObj) => {
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(`/OM/CreateWithdrawalOrders`, reqObj);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans");
        CustomConsole(response);
        dispatch({
          type: actionType.RETURN_CANS_REQUEST,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const ReturnCansOtp = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/withdrawal/otp`);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans OTP");
        CustomConsole(response);
        dispatch({
          type: actionType.RETURN_CANS_OTP,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const DamageCansData = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/consumer/getDamagedCans`);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans DATA");
        CustomConsole(response);
        dispatch({
          type: actionType.DAMAGE_CANS_DATA,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const DamageCansAccept = (returnOrderId, reqObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/canReturn/consumer/accept/${returnOrderId}`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans accept");
        CustomConsole(response);
        dispatch({
          type: actionType.DAMAGE_CANS_ACCEPT,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
export const DamageCansReject = (returnOrderId, reqObj) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(
        `/OM/canReturn/consumer/reject/${returnOrderId}`,
        reqObj
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans reject");
        CustomConsole(response);
        dispatch({
          type: actionType.DAMAGE_CANS_ACCEPT,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

// export const ReturnCansOrderList = (reqObj) => {
//   let api = useAxios();
//   CustomConsole(reqObj);
//   return async (dispatch) => {
//     try {
//       let response = await api.get(`/OM/withdrawalOrderList`, reqObj);

//       if (response.status === AppMetaData.httpStatus.SUCCESS) {
//         // CustomConsole("REsponse: " + JSON.stringify(response.data));
//         CustomConsole("Response for return cans order list");
//         CustomConsole(response);
//         dispatch({
//           type: actionType.GET_RETURN_CAN_LIST,
//           payload: { data: response.data, statusFlag: true },
//         });
//       } else {
//         // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
//       }
//     } catch (error) {
//       CustomConsole("Error: " + JSON.stringify(error));
//       toast.dark(error.response.data.msg, {
//         position: "bottom-right",
//         autoClose: 3000,
//         type: "error",
//       });
//     }
//   };
// };
