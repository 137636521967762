/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios";
import actionType from "./../actions/actionTypes";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import CustomConsole from "../../coustomConsole";
export const GetRecentActivies = () => {
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post(`/OM/CentralDashBoard`, {});
    if (response.status === AppMetaData.httpStatus.SUCCESS) {
      dispatch({
        type: actionType.GET_RECENT_ACTIVITIES,
        payload: response.data,
      });
    } else {
      // CustomConsole("ELSE: Response in GetUserProfile Action Error");
    }
  };
};

export const GetPwdOrderOtp = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/pwdOrderOtp/ongoing`, {});
      CustomConsole("----otp response-----");
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PWD_ORDER_OTP,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

export const ReplacementCansOtp = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/consumer/replaceCans/getReplacementOtp`
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for replacement cans OTP");
        CustomConsole(response);
        dispatch({
          type: actionType.REPLACEMENT_CANS_OTP,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};

//For getting recent order otp and other details
export const AllOrderOtpPaymentDetails = (id) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/consumer/recent/orders`
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for replacement cans OTP");
        CustomConsole(response);
        dispatch({
          type: actionType.ALL_OTP_AND_ORDER_DETAILS,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    }
  };
};
