/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Pravesh
 * @description BookWater App Authentication Page With Verification OTP Component
 * @date 13/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and Other Files

import React, { useRef, useState, useEffect } from "react";
import "./OtpInput.css"; // Import the CSS file
import { Box, Button, Typography } from "@mui/material";
import authStyles from "./../../Pages/Authentication/authenticationStyles";
// import appColors from "../../Assets/AppTheme/appColors";
import AuthTextField from "../TextFields/AuthTextFields";
import OtpInput from "react-otp-input";
import appMeta from "../../Assets/AppMeta/appMeta";
import PrimaryButton from "../Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { forgotPasswordNewPassword } from "../../Redux/actions/ProfileActions";
import { forgotPasswordUserVerify } from "../../Redux/actions/authAction";
import { toast } from "react-toastify";
import { resendOtp } from "../../Redux/actions/authAction";
import AuthPasswordTextField from "../TextFields/AuthPasswordTextField";
import CustomConsole from "../../coustomConsole";
const styles = {
  mainContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    // padding: "1%",
    marginTop: "3%",
    gap: "20px",
  },
};

const VerificationWithOTP = (props) => {
  // Using ref to auto focus next input field if user enter OTP
  const inputs = useRef([]);

  // Importing dispatch from react-redux for performing actions
  const dispatch = useDispatch();

  // Importing useSelector from react-redux to access redux store data
  const authenticationDetails = useSelector((state) => state.authReducer);
  // Variables declared
  const [otp, setOtp] = React.useState();
  const [password, setPassword] = React.useState("");
  const [reTypePassword, setReTypePassword] = React.useState("");
  const [isPsValid, setIsPsValid] = React.useState(false);
  const [isReTypeValid, setIsReTypeValid] = React.useState(false);
  const [otpSubmit, setOtpSubmit] = React.useState(false);
  const [emailVerify, setEmailVerify] = useState(false);
  const [phoneVerify, setPhoneVerify] = useState(false);
  //Dialog Box Variables for Verificaition
  const timerStart = 30; //Seconds
  const [emailCounter, setEmailCounter] = useState(timerStart);
  const [phoneCounter, setPhoneCounter] = useState(0);

  CustomConsole(props.email, props.phone);

  // Once there is response from the API this useEffect get executed
  React.useEffect(() => {
    CustomConsole(authenticationDetails);
    CustomConsole(props.email, props.phone);
    if (
      authenticationDetails.passwordUpdateError === false &&
      otpSubmit === true
    ) {
      // if password updated successfully, showing the user login UI
      props.setForgetPassword(false);
      props.setSubmitToVerify(false);
      setOtpSubmit(false);
    } else {
      CustomConsole("Else part error in useEffect");
    }
  }, [authenticationDetails]);

  // Submit button function
  const handleSubmitNewPassword = () => {
    // With new password and OTP recieved

    if (password === reTypePassword) {
      if (
        password !== undefined &&
        otp !== undefined &&
        password?.length !== 0 &&
        otp?.length === 6
      ) {
        dispatch(forgotPasswordNewPassword(password, otp));
        setOtpSubmit(true);
      } else {
        toast.dark("Please enter your OTP/Password", {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
        setOtpSubmit(false);
      }
    } else {
      toast.dark("Enter password and Re-entered password is not same", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  //   CustomConsole(window.navigator)
  // //For Autofilling otp for forgot password
  //   useEffect(() => {
  //     if ("OTPCredential" in window) {
  //       const ac = new AbortController();
  //       CustomConsole(ac);
  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal
  //         })
  //         .then((otpCredential) => {
  //           CustomConsole(otpCredential);
  //           CustomConsole(otpCredential.code);
  //           setOtp(otpCredential.code);
  //           ac.abort();
  //         })
  //         .catch((err) => {
  //           ac.abort();
  //           CustomConsole(err);
  //         });
  //     }
  //   }, []);

  useEffect(() => {
    const timer =
      emailCounter > 0 &&
      setInterval(() => setEmailCounter(emailCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [emailCounter]);

  useEffect(() => {
    const timer =
      phoneCounter > 0 &&
      setInterval(() => setPhoneCounter(phoneCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [phoneCounter]);

  //Resend E-Mail OTP
  const handleEmailResend = () => {
    setEmailCounter(timerStart);
    CustomConsole(props.email, props.emailPhone);
    //Re-Send OTP to Email
    dispatch(forgotPasswordUserVerify(props.email, props.phone));
  };
  return (
    <Box sx={styles.mainContainer}>
      <Box>
        <Typography sx={authStyles.authHeader}>
          Enter the 6 digit OTP sent to{" "}
          {props.phone !== "" ? props.phone : props.email}
        </Typography>
      </Box>
      <Box className="otp-container">
        <OtpInput
          value={otp}
          onChange={(otp) => {
            // Ensure only numbers are entered
            const filteredOtp = otp.replace(/\D/g, ""); // Remove non-digit characters
            setOtp(filteredOtp);
          }}
          inputStyle="inputStyle"
          numInputs={6}
          renderSeparator={<span></span>}
          renderInput={(props) => <input {...props} />}
        />
      </Box>
      <Box>
        {/*  Email OTP Resent Button */}
        <Button
          disabled={emailCounter !== 0 || emailVerify}
          sx={{
            color: "blue",
            fontWeight: "bold",
            textTransform: "none",
            "&:hover": { color: "white" },
          }}
          onClick={handleEmailResend}
        >
          {/* Resend OTP in */}
          {/* E-Mail Resent OTP Timer */}
          {emailCounter === 0 ? (
            <span style={{ color: "#0CD8BE" }}>Resend OTP</span>
          ) : (
            <span
              style={{
                color: "#0CD8BE",
                fontWeight: "bold",
                marginLeft: "5px",
              }}
            >
              Please Wait 00:{emailCounter} Seconds to Regenerate OTP
            </span>
          )}
        </Button>
      </Box>
      <Box>
        <Typography sx={authStyles.authHeader}>Reset your password</Typography>
      </Box>
      <AuthPasswordTextField
        lable={"Password"}
        type={appMeta.textFieldTypes.PASSWORD}
        isStrongPasswordRequired={false}
        value={password}
        setValue={setPassword}
        isRequired={true}
        isValid={isPsValid}
        setIsValid={setIsPsValid}
        isRememberMe={false}
        isHintRequired={false}
      />
      <AuthPasswordTextField
        lable="re-enter password"
        type={appMeta.textFieldTypes.PASSWORD}
        isStrongPasswordRequired={false}
        value={reTypePassword}
        setValue={setReTypePassword}
        isRequired={true}
        isValid={isReTypeValid}
        setIsValid={setIsReTypeValid}
        isRememberMe={false}
        isHintRequired={false}
      />
      <PrimaryButton
        btnName="Submit"
        handleButtonClick={handleSubmitNewPassword}
      />
    </Box>
  );
};

export default VerificationWithOTP;
