import React from "react";
import * as Material from "@mui/material";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import appColors from "../../../Assets/AppTheme/appColors";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import appTheme from "../../../Assets/AppTheme/appTheme";
import CustomConsole from "../../../coustomConsole";

export default function BookingAddressCard({
  primaryAddress,
  setchooseAddressDialog,
}) {
  const iconList = IconListManager();
  const { pathname } = useLocation();
  const name = "";
  const pageTitleSideBar = pathname.split("/dashboard/");

  CustomConsole(primaryAddress, "primaryaddress");
  return (
    <>
      <Material.Typography sx={newBookingstyles.cardHeaderStyle}>
        Delivery Address
      </Material.Typography>
      <Material.Card sx={newBookingstyles.cardStylesAddress}>
        <Material.Box sx={newBookingstyles.MainBox}>
          {primaryAddress?.pincode !== undefined ? (
            <>
              <Material.Typography sx={newBookingstyles.BoxHeader1}>
                <img alt="" src={iconList.locationIcon} />
                &nbsp;&nbsp;
                {primaryAddress != undefined &&
                primaryAddress.address_type != undefined
                  ? primaryAddress.address_type
                  : ""}
              </Material.Typography>
              <Material.Typography sx={newBookingstyles.addressText}>
                {primaryAddress != undefined &&
                primaryAddress.contact_name != undefined
                  ? primaryAddress.contact_name
                  : ""}{" "}
                &nbsp;
                {primaryAddress != undefined &&
                primaryAddress.address_line1 != undefined
                  ? primaryAddress.address_line1
                  : ""}
                &nbsp;
                {primaryAddress != undefined &&
                primaryAddress.address_line2 !== undefined
                  ? primaryAddress.address_line2
                  : ""}{" "}
                {primaryAddress != undefined &&
                primaryAddress.address_line3 !== undefined
                  ? primaryAddress.address_line3
                  : ""}{" "}
                {primaryAddress != undefined &&
                primaryAddress.landmark !== undefined
                  ? primaryAddress.landmark
                  : ""}
                {primaryAddress != undefined &&
                primaryAddress.service_area !== undefined
                  ? primaryAddress.service_area
                  : ""}
              </Material.Typography>
              {window.innerWidth > 600 && (
                <Material.Typography sx={newBookingstyles.addressText}>
                  {primaryAddress != undefined &&
                  primaryAddress.pincode !== undefined
                    ? primaryAddress.pincode
                    : ""}{" "}
                  {primaryAddress != undefined &&
                  primaryAddress.service_area !== undefined
                    ? primaryAddress.service_area
                    : ""}
                </Material.Typography>
              )}
            </>
          ) : (
            <div style={{ height: "1rem" }}></div>
          )}
          {primaryAddress?.pincode === undefined ? (
            <Material.Box sx={newBookingstyles.addAddressChildBox}>
              <Material.Typography
                sx={{
                  fontSize: appTheme.fontSizeMedium,
                  color: appColors.headerTextColor,
                  fontWeight: "bold",
                }}
                onClick={() => setchooseAddressDialog(true)}
              >
                {" "}
                <AddIcon sx={{ fontSize: appTheme.fontSizexl }} />
                New Address
              </Material.Typography>
            </Material.Box>
          ) : (
            ""
          )}
        </Material.Box>

        <Material.Box sx={{ width: "20%" }}>
          <Material.Button
            sx={{ fontSize: appTheme.fontSizeSmall }}
            size="small"
            endIcon={<EditIcon sx={newBookingstyles.editButton} />}
            onClick={() => setchooseAddressDialog(true)}
          ></Material.Button>
        </Material.Box>
      </Material.Card>
    </>
  );
}
