/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appTheme from "../../../Assets/AppTheme/appTheme";

export const styles = {
  mainpaper: {
    display: "flex",
    flexDirection: "row",
    flexWrap:"wrap"
  },
  headers: {
    fontWeight: "bolder",
    color: "#000",
    fontSize: appTheme.fontSizeLarge,
  },
  ansGridBox: {},
  ans: {
    fontSize: appTheme.fontSizeMedium,
    fontWeight: "500",
    color: "#918e8e",
  },
  ansGridBox2: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: { xs: "5%", md: "10%" },
  },
  headerLink: {
    fontWeight: "bolder",
    fontSize: appTheme.fontSizeMedium,
    cursor: "pointer",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomBtn: {
    padding: 0,
    textTransform: "none",
    "&:hover": {
      backgroundColor: "black",
      color: "white",
    },
  },
  chipCard: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
  },
  chipCard1: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
};
