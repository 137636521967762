/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "./myAddressBookStyle";
import { BiCurrentLocation } from "react-icons/bi";
import { MdFiberPin } from "react-icons/md";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { SearchAvailability } from "../../../../Redux/actions/bookNowAction";
import appColors from "../../../../Assets/AppTheme/appColors";
import closeIcon from "../../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  customLabel: {
    fontSize: 12, // You can adjust the font size here
  },
}));
export function EditAddressDialogBox(props) {
  const classes = useStyles();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [nameHt, setNameHt] = React.useState("");
  const [phoneHt, setPhoneHt] = React.useState("");
  const [address1Ht, setAddress1Ht] = React.useState("");
  const [address2Ht, setAddress2Ht] = React.useState("");
  const [landmarkHt, setLandmarkHt] = React.useState("");
  const [pincodeHt, setPincodeHt] = React.useState("");
  const [selectServiceAreaHt, setSelectServiceAreaHt] = React.useState("");
  const [deliveryTimeHt, setDeliveryTimeHt] = React.useState("");
  // const [locationHt, setLocationHt] = React.useState("");
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);
  const [name, setName] = React.useState(props.data.contact_name);
  const [phone, setPhone] = React.useState(props.data.contact_phone);
  const [address1, setAddress1] = React.useState(props.data.address_line1);
  const [address2, setAddress2] = React.useState(props.data.address_line2);
  const [address3, setAddress3] = React.useState(props.data.address_line3);
  const [landmark, setLandmark] = React.useState(props.data.landmark);
  const [addressType, setAddressType] = React.useState("");

  const [postOffice, setPostOffice] = React.useState([]);
  // const [pincode, setPincode] = React.useState(props.data.pincode);
  const [areaName, setAreaName] = React.useState("");
  const [pincode, setPincode] = React.useState({
    value: "",
    message: "",
    error: true,
  });

  const [serviceArea, setServiceArea] = React.useState({
    value: "",
    message: "",
    error: true,
  });
  const [deliveryTime, setDeliveryTime] = React.useState(
    props.data.delivery_timing_option === 100 ? "1" : "2"
  );
  const [location, setLocation] = React.useState(props.data.loation);

  const [isPrimary, setIsPrimary] = React.useState(props.data.is_primary);
  const [error, setError] = React.useState(true);
  const helperTextStyles = makeStyles((theme) => ({
    root: {
      color: appColors.secondaryCard,
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: theme.palette.common.white,
      },
    },
  }));
  const helperTestClasses = helperTextStyles();

  const style = {
    display: "flex",
    justifyContent: "center",
    bgcolor: "background.paper",
    backgroundColor: appColors.secondaryCard,
  };

  //For capturing lattitude and longitutde
  React.useEffect(() => {
    // Check if the geolocation API is available in the browser
    if ("geolocation" in navigator) {
      // Get the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  //For setting pincode while mounting component
  React.useEffect(() => {
    setPincode((prev) => ({
      ...prev,
      value: props.data.pincode,
    }));
  }, [props.data]);

  //useeffect for validating value
  React.useEffect(() => {
    if (validate() === true && areaName !== "") {
      setError(false);
    } else {
      setError(true);
    }
  });
  React.useEffect(() => {
    console.log("props.data.is_primary")
    console.log(props.data.is_primary)
    const dT = props.data.delivery_timing_option === 100 ? "1" : "2";
    setName(props.data.contact_name);
    setPhone(props.data.contact_phone);
    setAddressType(props.data.address_type);
    setAddress1(props.data.address_line1);
    setAddress2(props.data.address_line2);
    setAddress3(props.data.address_line3);
    setLandmark(props.data.landmark);
    setAreaName(props.data.service_area);
    // setPincode(props.data.pincode);
    setDeliveryTime(dT);
    setLocation(props.data.loation);
    setServiceArea((prev) => ({
      ...prev,
      value: props.data.loation,
      error: false,
    }));
    setIsPrimary(props.data.is_primary);
    if (
      props.data.pincode !== undefined &&
      props.data.pincode !== "" &&
      props.data.pincode.length === 6
    ) {
      dispatch(SearchAvailability(props.data.pincode));
    }
  }, [props.data]);

  const validate = () => {
    let ret = true;
    let reg = /^[6-9][0-9]{9}$/; //Phone Number validation
    if (!reg.test(phone)) {
      setPhoneHt("Please enter a 10-digit Numbers start with 9/8/7/6");
      ret = false;
    } else {
      setPhoneHt("");
    }
    reg = /^[a-zA-Z]/; //Full Name Validation
    if (!reg.test(name)) {
      setNameHt("Please Enter valid Name");
      ret = false;
    } else {
      setNameHt("");
    }
    reg = /^[1-9][0-9]{5}$/;
    if (!reg.test(pincode.value)) {
      setPincodeHt("Please Enter valid Pincode");
      ret = false;
    } else {
      setPincodeHt("");
    }
    if (areaName === "") {
      setSelectServiceAreaHt("Please select service area");
      ret = false;
    } else {
      setSelectServiceAreaHt("");
    }
    reg = /^[a-zA-Z0-9]/; 
    if (!reg.test(address1)) {
      setAddress1Ht("Please Enter Address Line 1");
      ret = false;
    } else {
      setAddress1Ht("");
    }
    reg = /^[a-zA-Z0-9]/; 
    if (!reg.test(address2)) {
      setAddress2Ht("Please Enter Address Line 2");
      ret = false;
    } else {
      setAddress2Ht("");
    }
    if (deliveryTime === "") {
      setDeliveryTimeHt("Please Select Delivery Time");
      ret = false;
    } else {
      setDeliveryTimeHt("");
    }
    // if (details.location === "") {
    //   setLocationHt("Please Select Location");
    //   ret = false;
    // } else {
    //   setLocationHt("");
    //   ret = true;
    // }
    return ret;
  };

  const handleSubmit = () => {
    if (!error) {
      const details = {
        name: name,
        phone: phone,
        address1: address1,
        address2: address2,
        address3: address3,
        landmark: landmark,
        pincode: pincode.value,
        serviceArea: areaName,
        deliveryTime: deliveryTime,
        location: location,
        addressId: props.data.address_id,
        isPrimary: isPrimary,
        latitude: latitude,
        latlongaddress: "",
        longitude: longitude,
        address_type: addressType,
      };
      props.handleUpdateAddressDetails(details);
      props.handleClose();
    }
  };

  const dispatch = useDispatch();
  const bookNowState1 = useSelector((state) => state.bookNow);

  // console.log(`${JSON.stringify(bookNowState1)}`)

  React.useEffect(() => {
    setPostOffice(bookNowState1.pinCodeAreas);
    if (
      !bookNowState1.error &&
      bookNowState1.pincodeAreaFlag &&
      bookNowState1.pinCodeAreas !== undefined &&
      bookNowState1.pinCodeAreas.length > 0
    ) {
      bookNowState1.error = true;
      bookNowState1.pincodeAreaFlag = false;
      setPostOffice(bookNowState1.pinCodeAreas);
      // console.log(`serviceArea ${JSON.stringify(serviceArea)}`);
    }
  }, [bookNowState1]);

  const onChangePincode = (e) => {
    bookNowState1.error = false;
    bookNowState1.pincodeAreaFlag = true;
    setAreaName("");
    setPostOffice([""]);
    let error = false;
    let reg = /^\d{6}$/;
    setPincode((prev) => ({ ...prev, value: e.target.value }));
    if (!reg.test(e.target.value)) {
      error = true;
      setPincode((prev) => ({
        ...prev,
        message: "*Invalid Pincode",
        error: true,
      }));
      setServiceArea((prev) => ({ ...prev, value: "", error: true }));
    } else {
      setPincode((prev) => ({ ...prev, message: "", error: false }));
    }
    if (error === false) {
      dispatch(SearchAvailability(e.target.value));
    }
  };

  const onChangeServiceArea = (e) => {
    // console.log(postOffice);
    setAreaName(e.target.value);
    setServiceArea((prev) => ({
      ...prev,
      value: e.target.value,
      error: false,
    }));
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: "13px", // Set the desired label color
    },
  };

  //function to change delivery typr
  const handelSelectAddressType = (value) => {
    setAddressType(value);
  };

  // console.log(postOffice);
  return (
    <Material.Dialog
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
      fullWidth={fullWidth}
      maxWidth={maxWidth}
      sx={{ backgroundColor: appColors.dialogBackGround }}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.Box sx={{ backgroundColor: appColors.secondaryCard }}>
        <Material.Typography
          sx={{
            color: appColors.secondaryText,
            margin: "1rem",
            fontWeight: "bold",
          }}
        >
          Edit Address
        </Material.Typography>
      </Material.Box>
      <Material.Box sx={style}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: 600,
            width: { md: "60%", xs: "90%" },
            overflow: "auto",
          }}
        >
          <Material.Typography
            sx={{
              fontSize: ".9rem",
              color: appColors.darkGray,
              marginBottom: ".6rem",
            }}
          >
            Mark Address as*
          </Material.Typography>
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              flexDirection: "row",
              width: "100%",
            }}
          >
            {/* Primary or Not Check Box */}
            <Material.Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  addressType === "Home"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  addressType === "Home"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    addressType === "Home"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType === "Home"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("Home")}
            >
              Home
            </Material.Button>
            <Material.Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  addressType === "Work"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  addressType === "Work"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    addressType === "Work"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType === "Work"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("Work")}
            >
              Work
            </Material.Button>
            <Material.Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  addressType !== "Home" && addressType !== "Work"
                    ? appColors.lightblue
                    : appColors.textColorNew,
                color:
                  addressType !== "Home" && addressType !== "Work"
                    ? appColors.textColorNew
                    : appColors.white,
                "&:hover": {
                  backgroundColor:
                    addressType !== "Home" && addressType !== "Work"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType !== "Home" && addressType !== "Work"
                      ? appColors.textColorNew
                      : appColors.white,
                },
              }}
              onClick={() => handelSelectAddressType("OTHER")}
            >
              OTHER
            </Material.Button>
          </Material.Box>
          {/* Full Name */}
          <Material.TextField
            variant="outlined"
            label="Full Name *"
            sx={styles.modalTxt}
            size="small"
            helperText={nameHt}
            FormHelperTextProps={{ classes: helperTestClasses }}
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={(e) => setName(e.target.value)}
            value={name}
          />
          {/* Mobole Number */}
          <Material.TextField
            variant="outlined"
            label="Mobile Number *"
            type="number"
            sx={styles.modalTxt}
            size="small"
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={(e) => setPhone(e.target.value)}
            value={phone}
            helperText={phoneHt}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          {/* Address Line 1 */}
          <Material.TextField
            variant="outlined"
            label="Address Line 1 *"
            sx={styles.modalTxt}
            size="small"
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={(e) => setAddress1(e.target.value)}
            value={address1}
            helperText={address1Ht}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          {/* Address Line 2 */}
          <Material.TextField
            variant="outlined"
            label="Address Line 2 *"
            sx={styles.modalTxt}
            size="small"
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={(e) => setAddress2(e.target.value)}
            value={address2}
            helperText={address2Ht}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            {/* Address Line 3 */}
            <Material.TextField
              variant="outlined"
              label="Address Line 3"
              sx={styles.modalTxt}
              size="small"
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              onChange={(e) => setAddress3(e.target.value)}
              value={address3}
            />
            {addressType !== "Home" && addressType !== "Work" && (
              <Material.TextField
                variant="outlined"
                label="Address Type"
                sx={styles.modalTxt}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                size="small"
                onChange={(e) => setAddressType(e.target.value)}
                value={addressType}
              />
            )}
            {/* Land Mark */}
            <Material.TextField
              variant="outlined"
              label="Landmark"
              sx={styles.modalTxt}
              size="small"
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              onChange={(e) => setLandmark(e.target.value)}
              value={landmark}
              FormHelperTextProps={{ classes: helperTestClasses }}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            {/* PinCode */}
            <Material.TextField
              variant="outlined"
              label="Pincode *"
              sx={styles.modalTxt}
              size="small"
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              type="number"
              // onChange={(e) => setPincode(e.target.value)}
              onChange={onChangePincode}
              value={pincode.value}
              FormHelperTextProps={{ classes: helperTestClasses }}
            />
            <Material.FormControl
              variant="outlined"
              sx={styles.modalTxt}
              size="small"
            >
              <Material.InputLabel
                id="demo-simple-select-la"
                sx={{ color: appColors.darkGray }}
              >
                Select Area
              </Material.InputLabel>
              {postOffice?.length > 0 && (
                <Material.Select
                  value={areaName}
                  label="Select Area"
                  sx={{
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  onChange={onChangeServiceArea}
                >
                  <Material.MenuItem disabled value="none">
                    Select Service Area
                  </Material.MenuItem>
                  {
                    postOffice?.length > 0
                      ? postOffice?.map((po, key) => (
                          <Material.MenuItem value={po?.Name} key={key}>
                            {po.Name}
                          </Material.MenuItem>
                        ))
                      : "" /* or any other fallback, like an empty array */
                  }
                </Material.Select>
              )}
            </Material.FormControl>
          </Material.Box>

          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
              justifyContent: { md: "flex-end", xs: "center" },
            }}
          >
            {/* Primary or Not Check Box */}
            <Material.FormControlLabel
              sx={{ marginTop: "8px" }}
              control={<Material.Checkbox defaultChecked={isPrimary} />}
              label={
                <Material.Typography
                  sx={{ fontSize: "10px", color: appColors.darkGray }}
                >
                  Mark as Primary
                </Material.Typography>
              }
              onChange={() => setIsPrimary(!isPrimary)}
            />
            {/* SubMit Botton */}
            <Material.Box>
              <Material.Button
                disabled={error}
                size="small"
                variant="contained"
                sx={styles.btn}
                onClick={handleSubmit}
              >
                Submit
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      <img
        alt=""
        src={closeIcon}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          cursor: "pointer",
          height: "30px",
        }}
        onClick={props.handleClose}
      />
    </Material.Dialog>
  );
}
