/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  isLoading: true,
  transactions: [],
  walletTransactions: [],
  pageStatus: false,
  isTransactionsUpdated: false,
  bankDownTime: [],
};
 
const transactionDetailsReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_TRANSACTION_DETAILS:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        transactions: action.payload.data.data,
        pageStatus: action.payload.data.data.length < 1 ? true : false,
        isTransactionsUpdated: true,
      };
    case actionType.GET_WALLET_TRANSACTIONS:
      return {
        ...state,
        error: action.payload.error,
        walletTransactions: action.payload.orders,
        pageStatus: action.payload.orders.length < 1 ? true : false,
        isTransactionsUpdated: true,
      };
    case actionType.SIGN_OUT:
      return {
        ...initialProfileState,
      };
    case actionType.GET_BANK_DOWN_TIME:
      return {  
        ...state,
        error: action.payload.error,
        bankDownTime: action.payload.data
      }
    default:
      return state;
  }
};

export default transactionDetailsReducer;
