import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";

export const transactionStyle = {
  mainBox: {
    borderTopRadius: "15px",
    // width: "100%",
    margin: ".3rem",
    color: "white",
    display: "flex",
    // border: "1px solid gray",
    flexDirection: "column",
    justifyContent: "space-evenly",
    padding: ".5rem 1rem",
    background: appColors.cardColor,
    borderRadius: ".5rem",
  },
  subTest: {
    color: appColors.Textgray,
    fontSize: appTheme.fontSizeSmall,
    textAlign: "center",
  },
  headerText: {
    color: appColors.headerTextColor,
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  boxRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxCloseIconRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    color: "red",
    marginRight: "1rem",
  },
  boxColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  editBtn: {
    backgroundColor: appColors.textColorNew,
    fontSize: appTheme.fontSizexs,
  },
  closeButton: {
    backgroundColor: appColors.Failed,
    fontSize: appTheme.fontSizexs,
  },
  boxRowButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    marginTop: ".3rem",
    backgroundColor: appColors.lightblue,
    padding: ".3rem",
    borderRadius: "0 0 0.5rem 0.5rem",
  },
  editLayout: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "#bfefffed",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    borderRadius: ".5rem",
  },
  boxEditRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
  },
};
