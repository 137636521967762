/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import CustomConsole from "../coustomConsole";
// const baseURL = "https://link.bookwater.com"; 
const baseURL = process.env.REACT_APP_BW_URL

const useAxiosWithoutLoading = () => {   
  let authTokens = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  let storageType = true; //true -> Local Storage
  if (authTokens === null) {
    storageType = false;
    authTokens = sessionStorage.getItem("token");
  }
  //   CustomConsole("authTokens: " + authTokens);    
  CustomConsole(baseURL)
  //Create the Axios Instance
  //Note: Every API (GET/PUT/POST) must have Auth Token So Make it as Default.
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens}` },
  });
  //It will Execute Before Main Axios for Token verification & Refresh
  axiosInstance.interceptors.request.use(async (req) => {
    const user = jwt_decode(authTokens);
    // CustomConsole(
    //   "axiosInstance.interceptors.request User: " + JSON.stringify(user)
    // );
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;

    //If Token Expiered, refresh the Token
    const tkn = authTokens;
    const response = await axios.post(`${baseURL}/SCM/login/keepalive`, {
      headers: { Authorization: `Bearer ${tkn}` },
      tkn,
    });  
    if (response.status === 200) {
      CustomConsole("Success Response in Refresh Token");
      if (storageType) {
        localStorage.setItem("token", response.data.tkn);
      } else {
        sessionStorage.setItem("token", response.data.tkn);
      }

      authTokens = response.data.tkn;
    } else {
      CustomConsole("Error Response in Refresh Token");
      CustomConsole("End Response: " + JSON.stringify(response));
    }
    req.headers.Authorization = `Bearer ${authTokens}`;
    return req;
  });

  return axiosInstance;

  

};
export default useAxiosWithoutLoading;
