/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import * as MaterialBase from "@mui/base";
import * as Material from "@mui/material";
import { styled } from "@mui/system";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import appColors from "../../Assets/AppTheme/appColors";

export default function FilterButton({ setfilterDialog }) {
  return (
    <Material.Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexDirection: { lg: "row", md: "row", sm: "column", xs: "row" },
      }}
    >
      <Material.Button
        variant="contained"
        onClick={() => setfilterDialog(true)}
        size="small"
        startIcon={<TuneSharpIcon />}
        sx={{
          backgroundColor: appColors.textColorNew,
        }}
      >
        Filter
      </Material.Button>
    </Material.Box>
  );
}
