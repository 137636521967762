import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";
export const GetPromoCodes = (reqObj, searchedPromo) => {
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/consumer/promo/getPromoCodes?promoLabel=${searchedPromo}`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans");
        CustomConsole(response);
        dispatch({
          type: actionType.GET_PROMO_CODES,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const ApplyPromocode = (reqObj, promoId) => {
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/consumer/promo/applyPromo/${promoId}`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for apply promo codes cans");
        CustomConsole(response);
        dispatch({
          type: actionType.APPLY_PROMO_CODE,
          payload: response.data,
        });
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "success",
        // });
      } else {
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "error",
        // });
      }
    } catch (error) {
      dispatch({
        type: actionType.APPLY_PROMO_CODE,
        payload: error.response.data,
      });
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

export const ApplyWaterDrops = (reqObj) => {
  let api = useAxios(); 
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      let response = await api.post(
        `/SSM/consumer/waterDrops/UseWaterDrops`,
        reqObj
      );

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response for return cans");
        CustomConsole(response);
        dispatch({
          type: actionType.APPLY_WATER_DROPS,
          payload: response.data,
        });
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "success",
        // });
      } else {
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "error",
        // });
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      // toast.dark(error.response.data.msg, {
      //   position: "bottom-right",
      //   autoClose: 3000,
      //   type: "error",
      // });
    }
  };
};
