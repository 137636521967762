/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import React from "react";
import appColors from "../../../../Assets/AppTheme/appColors";
import infoIcon from "../../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
function OTPLessDisclaimer({
  openOtpDialog,
  setOpenOtpDialog,
  otpLessDiss,
  setSpecialReq,
  handelOtpDelivery,
}) {
  const otpControlFun = (key) => {
    if (key === 1) {
      setSpecialReq(false);
      setOpenOtpDialog(false);
      handelOtpDelivery(false);
    } else if (key === 2) {
      setSpecialReq(true);
      setOpenOtpDialog(false);
      handelOtpDelivery(true);
    }
  };
  return (
    <Dialog
      open={openOtpDialog}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <DialogContent
        sx={{ color: appColors.white, backgroundColor: appColors.textColorNew }}
      >
        <Box sx={{ display: "flex", justifyContent: "center", margin: "1rem" }}>
          <img
            src={infoIcon}
            alt=""
            style={{ height: "80px", width: "80px" }}
          />
        </Box>
        {otpLessDiss}
      </DialogContent>
      <DialogActions sx={{ backgroundColor: appColors.textColorNew }}>
        <Button
          onClick={() => otpControlFun(1)}
          sx={{ color: appColors.white }}
        >
          Take me back to safety
        </Button>
        <Button
          onClick={() => otpControlFun(2)}
          autoFocus
          sx={{ color: appColors.white }}
        >
          Yes, I understand the risks
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default OTPLessDisclaimer;
