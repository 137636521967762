/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { useState, useEffect } from "react";
import GiftBOX from "../../../Assets/newMobileIcon/menu/giftBox.svg";
import { Alert, Box, Snackbar, Typography } from "@mui/material";
import appTheme from "../../../Assets/AppTheme/appTheme";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import appColors from "../../../Assets/AppTheme/appColors";
import "./giftCards.css";
import moment from "moment";

export default function AvailedGiftCardList({
  availedGiftCards,
  handelViewGiftCard,
}) {
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const statusFunction = (status) => {
    switch (status) {
      case 100:
        return "Payment Pending";
      case 200:
        return "Claim";
      case 300:
        return "Claimed";
      case 400:
        return "Expired";
    }
  };

  //function to format text
  const formatStringWithHyphens = (input) => {
    return input
      .replace(/([A-Za-z]*)(\d{4})(\d{0,4})(\d{0,4})(\d*)/, "$1-$2-$3-$4$5")
      .replace(/-+/g, "-")
      .replace(/-$/, "");
  };

  //function to set copy referal code
  const copyReferalCode = (code) => {
    navigator.clipboard.writeText(code);
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 1000);
  };
  return (
    <>
      {availedGiftCards?.map((data, index) => (
        <>
          <div class="discount-card" key={index}>
            <div class="content">
              <p
                class="main-text"
                onClick={() => {
                  copyReferalCode(data?.details?.giftCardId);
                }}
              >
                {formatStringWithHyphens(data?.details?.giftCardId)}{" "}
                <span>
                  <ContentCopyIcon
                    sx={{
                      color: "#49B1EC",
                      cursor: "pointer",
                      zIndex: 122,
                    }}
                  />
                </span>
              </p>
              {/* <p class="sub-text">single treat</p> */}
              <div class="addToCart">
                <p class="main-text">₹ {data?.details?.payingAmount}</p>
              </div>
            </div>
            <div class="addButton">
              <p class="price">
                {moment(data?.details?.expiryDate).isBefore(moment(), "day")
                  ? "Expired"
                  : statusFunction(data?.details?.status)}
              </p>
              <a
                class="bn3637 bn38"
                style={{
                  color: appColors.giftCardColor,
                  zIndex: 10,
                  cursor: "pointer",
                }}
                onClick={() => handelViewGiftCard(data?.details?.giftCardId)}
              >
                View
              </a>
            </div>
            <div class="gift-icon">
              <img
                src={GiftBOX}
                alt="Bookwater"
                style={{ height: "4rem", width: "4rem" }}
              />
            </div>
          </div>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={2000}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
              zIndex: 1,
            }}
          >
            <Alert severity="info" sx={{ width: "100%" }}>
              Copied to the clipboard
            </Alert>
          </Snackbar>
        </>
      ))}
    </>
  );
}

const cardStyles = {
  cardStyle: {
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    width: { xs: "100%", md: "50%", lg: "40%" },
    height: "auto",
    borderRadius: "1rem",
    position: "relative",
    background: "#fff",
    padding: "0 0 0.5rem 0",
    marginBottom: "1rem",
  },
  cardHeader: {
    background: "linear-gradient(180deg, #038ec2 25%, #5ad0fe 100%);",
    width: "100%",
    height: "30%",
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "space-between",
    padding: "0 .8rem",
  },
  header: {
    margin: 0,
    fontSize: appTheme.fontSizeLarge,
    fontWeight: "bold",
  },
  subHeaderText: {
    margin: 0,
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  subHeader: {
    height: "70%",
    width: "auto",
    background: "#038ec2",
    marginBottom: ".8rem",
    padding: "0 .5rem",
    borderRadius: "0 0 .5rem .5rem",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.2)",
  },
};
