/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../../../Assets/AppTheme/appColors";

export const styles = {
  addressBookMain: {
    padding: "10px",
  },
  addressBox: {
    display: "flex",
    flexDirection: {lg:"row",xs:"column"},
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    //backgroundColor: "gray"
  },
  header: {
    display: "flex",
    width: "16rem",
    height:"10rem" ,
    // border: "solid 1px #707070",
    marginBottom:"10px",
    justifyContent:  "center" ,
    // backgroundColor: appColors.cardColor,
    borderRadius:"5px"
  },
  modalHeader: {
    display: "flex",
    backgroundColor: "#070b30",
    color: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
  },
  modalTxt: {
    width:"100%",
    marginTop: "12px",
    fontSize:"12px",

  },
  btn: {
    backgroundColor: appColors.textColorNew,
    width: "50%",
    marginTop: "20px",
  },
  addBtn: {
    textTransform: "none",
    width: "250px",
    color: appColors.darkGray,
    padding: "10px",
    backgroundColor:appColors.homePageCard
    ,"&:hover":{
      backgroundColor:appColors.homePageCard
    }
  },
  helptext: {
    color: "#f21f1f",
    fontSize: "12px",
    fontWeight: "bolder",
    marginLeft: "20%",
  },
};
