// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: screenbasedonview.js
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

//For changing ui response based on screen size
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const ScreenScroll = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; 
};

export default ScreenScroll;
