/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import { styles } from "./myAccountsStyle";
import AccountsList from "./tabs/myaccounts/myAccountsList";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import { Tab, TabPanel, TabsList } from "./tabFunctions";
import { useDispatch } from "react-redux";
import { renderData } from "../../Redux/actions/isLoadingAction";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction";
import * as MaterialBase from "@mui/base";
import { styled } from "@mui/system";
import appColors from "../../Assets/AppTheme/appColors";


function MyAccounts() {
  const Tab = styled(MaterialBase.TabUnstyled)`
  cursor: pointer;
  font-size: 1rem;
  font-weight: bold;
  background-color: transparent;
  width: 100%;
  ${"" /* padding: 12px 16px; */}
  ${"" /* margin: 6px 6px; */}
  ${"" /* padding: 12px 16px; */}
  padding: 9px;
  border: none;
  display: flex;
  justify-content: center;
  &:hover {
      border-bottom: 3px solid #707070;
    }
  
    &:focus {
      border-bottom: 3px solid #30a7ff;
    }
  
    &.${MaterialBase.tabUnstyledClasses.selected} {
      color: #30a7ff;
      border-bottom: 3px solid #30a7ff;
    }
`;

  const TabsList = styled(MaterialBase.TabsListUnstyled)`
  min-width: 200px;
  ${"" /* width: fit-content; */}
  ${"" /* margin-right: 10px; */}
  background-color:transperent;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: space-between;
`;
  const dispatch = useDispatch(); 
  const [refreshFlag, setRefreshFlag] = React.useState(true);

  const [inAccountPage, setInAccountPage] = React.useState(false)
  React.useEffect(() => {
    if (refreshFlag) {
  
      setInAccountPage(true)
      setRefreshFlag(false);
      dispatch(renderData("MyAccounts"));
    }
  }, [refreshFlag]);
  return (
    <div>
      {/* Your Main Data should be here */}
      <Material.Box sx={styles.settingsMain}>
        <TabsUnstyled defaultValue={0}>
          <Material.Box>
            <TabsList sx={{borderBottom: "2px solid #58595b"}}>
              <Tab >My Bank Accounts</Tab>
              {/* <Tab>Virtual Account</Tab> */}
            </TabsList>
          </Material.Box>
          <Material.Box sx={{ marginTop: "30px", }}>
            <TabPanel value={0} >
              <AccountsList />
            </TabPanel>
            {/* <TabPanel value={1}>
              <VirtualBankAccount />
            </TabPanel> */}
          </Material.Box>
        </TabsUnstyled>
      </Material.Box>
    </div>
  );
}

export default MyAccounts;
