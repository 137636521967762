import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
} from "@mui/material";
import React from "react";

function CustomLoaderDialog(props) {
  return (
    <Dialog open={props.open}>
      <Box
        sx={{
          // marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ height: "50px", width: "50px" }}
          src={require("../../Assets/Animations/preloader.gif")}
          alt="loading..."
        />
      </Box>

      {/* <DialogActions>
        <Button
          sx={{
            borderRadius: "15px",
            fontSize: "10px",
            size="small"
            color: "white",
            fontWeight: "bold",
            backgroundImage: "linear-gradient(#021636,#0c4aad)",
            "&:hover": {
              backgroundImage: "linear-gradient(#6b8df2,#021636)",
            },
          }}
          onClick={props.handleClose}
          autoFocus
        >
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default CustomLoaderDialog;
