/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialProfileState = {
  isLoading: true,
  renderFlag: "",
};

const isLoadingData = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.LOADING_DATA:
      return {
        ...state,
        isLoading: action.payload,
      }; 
    case actionType.RENDER_DASHBOARD:
      return {
        ...state,
        renderFlag: action.payload,
      };
    default:
      return state;
  }
};

export default isLoadingData;
