/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Typography } from "@mui/material";
// import RequiredButton from "../../SkuButtons/SkuRequiredButton";
// import ReturnButton from "../../SkuButtons/SKUReturnButton";
import DownIcon from "../../../../Assets/newMobileIcon/Booking/DownIcon.svg";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useSelector } from "react-redux";

export default function SkuBillOverViewDialog({ deliveryCharges, onClose }) {
  const [productId, setProductId] = React.useState(0);
  const [depositView, setDepositView] = React.useState(false);
  const [discountView, setDiscountView] = React.useState(false);
  const [deliveryView, setDeliveryView] = React.useState(false);
  const priceBreakUp = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  //function for closing dialog
  const handelClose = (event) => {
    event.preventDefault();
    onClose();
  };
  //function for handling selected product info
  const handelSelectedProduct = (data) => {
    if (data === productId) {
      setProductId(0);
    } else {
      setProductId(data);
    }
  };

  //function to calculate in deposit price total
  function TotalDepositPrice() {
    // Initialize total deposit price
    let totalDepositPrice = 0;
    // Iterate through each product
    priceBreakUp?.valid_products?.forEach((product) => {
      // Add the product's deposit price to the total
      totalDepositPrice += product.deposit.price;
    });
    return totalDepositPrice;
  }

  //function to handel deposit showing
  const handelDepositShow = () => {
    setDepositView(!depositView);
  };

  //function to show discount
  const handelDiscountShow = () => {
    setDiscountView(!discountView);
  };

  //function to handel delivery charge view
  const handelDeliveryCharge = () => {
    setDeliveryView(!deliveryView);
  };

  const PriceBreakUpStyles = {
    headerStyles: {
      color: appColors.headerTextColor,
      fontSize: ".9rem",
      fontWeight: "600",
    },
    headerAanswer: {
      color: appColors.darkGray,
      fontSize: ".9rem",
      fontWeight: "600",
    },
  };
  return (
    <React.Fragment>
      <Box
        sx={{
          padding: "1rem",
          height: "100%",
          backgroundColor: appColors.homePageCard,
        }}
      >
        <Typography
          sx={{
            marginBottom: ".5rem",
            marginTop: "1rem",
            fontWeight: "bold",
            color: appColors.darkGray,
            textAlign: "center",
            fontSize: "1rem",
          }}
        >
          Price Break Up
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 0.5,
            width: "100%",
            padding: ".5rem",
          }}
        >
          {priceBreakUp?.valid_products?.map((data) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "1rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                key={data?.product_id}
                onClick={() => handelSelectedProduct(data?.product_id)}
              >
                <Typography sx={PriceBreakUpStyles.headerStyles}>
                  {data?.quantity?.selected} x {data?.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={PriceBreakUpStyles.headerAanswer}>
                    ₹{parseFloat(data?.product_price).toFixed(2)}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".8rem",
                    }}
                  >
                    {productId === data?.product_id ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>
              {data?.product_id === productId && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={PriceBreakUpStyles.headerStyles}>
                      {data?.quantity?.selected} x {data?.name} Price
                    </Typography>
                    <Typography sx={PriceBreakUpStyles.headerAanswer}>
                      ₹{data?.sub_total_price}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: appColors.headerTextColor }}>
                      {data?.deposit?.quantity} x {data?.name} Security Deposit
                    </Typography>
                    <Typography
                      sx={{ color: appColors.darkGray, fontWeight: "500" }}
                    >
                      {data?.deposit?.price}
                    </Typography>
                  </Box> */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: appColors.headerTextColor }}>
                      Delivery Charge
                    </Typography>
                    <Typography
                      sx={{ color: appColors.darkGray, fontWeight: "500" }}
                    >
                      {data?.extra_charges?.price}
                    </Typography>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={PriceBreakUpStyles.headerStyles}>
                      Taxes
                    </Typography>
                    <Typography sx={PriceBreakUpStyles.headerAanswer}>
                      ₹{data?.total_gst?.charges}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          ))}

          {TotalDepositPrice() > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "1rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDepositShow()}
              >
                <Typography sx={PriceBreakUpStyles.headerStyles}>
                  Total Security Deposit
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={PriceBreakUpStyles.headerAanswer}>
                    ₹{TotalDepositPrice()}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".8rem",
                    }}
                  >
                    {depositView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>
              {priceBreakUp?.valid_products?.map((data) => (
                <>
                  {depositView && (
                    <>
                      {data?.deposit?.price > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={PriceBreakUpStyles.headerStyles}>
                            {data?.deposit?.quantity} x {data?.name} Deposit
                            Price
                          </Typography>
                          <Typography sx={PriceBreakUpStyles.headerAanswer}>
                            ₹{data?.deposit?.price}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </>
              ))}
            </Box>
          )}

          {priceBreakUp?.discount > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "1rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDiscountShow()}
              >
                <Typography sx={PriceBreakUpStyles.headerStyles}>
                  Discount(s) Applied
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={PriceBreakUpStyles.headerAanswer}>
                    - ₹{priceBreakUp?.discount}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".8rem",
                    }}
                  >
                    {discountView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>

              {discountView && (
                <>
                  {priceBreakUp?.water_drop_details?.discount_price > 0 &&
                    priceBreakUp?.water_drop_details?.is_valid === true && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={PriceBreakUpStyles.headerStyles}>
                          Water Drops Applied
                        </Typography>
                        <Typography sx={PriceBreakUpStyles.headerAanswer}>
                          - ₹
                          {priceBreakUp?.water_drop_details?.discount_price ===
                            undefined ||
                          priceBreakUp?.water_drop_details?.is_valid === false
                            ? 0
                            : priceBreakUp?.water_drop_details?.discount_price}
                        </Typography>
                      </Box>
                    )}
                  {priceBreakUp?.coupon_code_details?.discount_price > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={PriceBreakUpStyles.headerStyles}>
                        Coupon Applied
                      </Typography>
                      <Typography sx={PriceBreakUpStyles.headerAanswer}>
                        - ₹
                        {priceBreakUp?.coupon_code_details?.discount_price ===
                        undefined
                          ? 0
                          : priceBreakUp?.coupon_code_details?.discount_price}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}

          {deliveryCharges > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: "1rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDeliveryCharge()}
              >
                <Typography sx={PriceBreakUpStyles.headerStyles}>
                  Total Delivery Charges
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography sx={PriceBreakUpStyles.headerAanswer}>
                    ₹{deliveryCharges}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".8rem",
                    }}
                  >
                    {deliveryView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>

              {deliveryView && (
                <>
                  {priceBreakUp?.valid_products?.map((data) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography sx={PriceBreakUpStyles.headerStyles}>
                          {data?.name}
                        </Typography>
                        <Typography sx={PriceBreakUpStyles.headerAanswer}>
                          ₹{data?.extra_charges?.price}
                        </Typography>
                      </Box>
                    </>
                  ))}
                </>
              )}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={PriceBreakUpStyles.headerStyles}>
              Grand Total
            </Typography>
            <Typography sx={PriceBreakUpStyles.headerAanswer}>
              ₹
              {priceBreakUp?.final_price === undefined
                ? 0
                : priceBreakUp?.final_price}
            </Typography>
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  );
}
