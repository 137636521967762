/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useEffect } from "react";
import * as Material from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import VBDetailsDialogBox from "../../DialogBoxes/VirtualBankDetails/virtualBankDetails";
import { GetUserVirtualAccount } from "../../../Redux/actions/myAccountsAction";
import PayByRazorPay from "../../../Utilities/RazorPay/PayByRazorPay";
import RazorPayStatusDialog from "../../DialogBoxes/RazorPayStatusDialog";
import * as paymentMethodAction from "../../../Redux/actions/paymentMethodAction";
import {
  GetWalletTransactionDetails,
  GetBankDownTimeDetails,
} from "../../../Redux/actions/transactionsAction";
import BiconLoader from "../../../Assets/Animations/preloader.gif";
import { toast } from "react-toastify";
import moment from "moment";
import UPITransactionDialog from "../../DialogBoxes/PdwBookingSummary/UPITransactionDialog";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import appColors from "../../../Assets/AppTheme/appColors";
import RazorPayTransactionDialog from "../../DialogBoxes/PdwBookingSummary/RazorPayTransactionDialog";
import { GetUserAdressBook } from "../../../Redux/actions/settingsAction";
import { GetUserProfile } from "../../../Redux/actions/profileAction";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import UpiFailedDialog from "../../DialogBoxes/upifailed";
import CustomConsole from "../../../coustomConsole";
function MyWallet(props) {
  const vbDetails = useSelector((state) => state.myAccounts);
  const orderIdDetails = useSelector((state) => state.skuOrderIdDetails);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const pageTitleSideBar = pathname.split("/dashboard/");

  // appmetadata done vinay
  const appmetaDataDetail = useSelector((state) => state.appMetaConfigData);
  CustomConsole(appmetaDataDetail);
  const dispatch = useDispatch();
  const [pMDialogOpen, setPMDialogOpen] = React.useState(true);
  const [openRazorPay, setOpenRazorPay] = React.useState(false);
  const [OpenUpiFailed, setOpenUpiFailed] = React.useState(false);
  const [razorPayId, setRazorPayId] = React.useState("");
  const [openEnterAmountDialog, setOpenEnterAmountDialog] =
    React.useState(false);
  //user profile
  const profileDetails = useSelector((state) => state.userProfile);
  const [currentWalletAmount, setCurrentWalletAmount] = React.useState(0);
  const [upiPay, setUpiPay] = React.useState(false);
  const [razorPayDialogFlag, setRazorPayDialogFlag] = React.useState(false);
  const [upiDataReceived, setUpiDataReceived] = React.useState(false);
  const [paymentLoaderFlag, setPaymentLoaderFlag] = React.useState(false);
  const [gpayLink, setGpayLink] = React.useState("");
  const [phonepeLink, setPhonepeLink] = React.useState("");
  const [anyUpiAppLink, setAnyUpiAppLink] = React.useState("");
  const [qrCodeLink, setQrCodeLink] = React.useState("");
  const [walletPayOrderId, setWalletPayOrderId] = React.useState("");
  const [payButtonFlag, setpayButtonFlag] = React.useState(false);
  // let currentWalletAmount = 0;
  const [walletFlag, setWalletFlag] = React.useState(true);
  const [UpiFlag, setUpiFlag] = React.useState(false);
  // minimumwalletAmount Done by vinay
  const [minimumWalletAmount, setMinimumWalletAmount] = React.useState("");
  const [externalCustomerId, setexternalCustomerId] = React.useState("");
  const [upiPaymentDialogOpen, setUpiPaymentDialogOpen] = React.useState(false);
  const [upiTransactionPage, setUpiTransactionPage] = React.useState(false);
  const [openUPIStatus, setOpenUPIStatus] = React.useState(false);
  // const [upiVerifyClicked, setUpiVerifyClicked] = React.useState(false);
  const payThroughUpi = useSelector((state) => state.paymentMethods);

  const [bookWithUpiId, setBookWithUpiId] = React.useState(false);
  const history = useHistory();
  const [orderFlag, setOrderFlag] = React.useState(false);
  const [orderID, setOrderId] = React.useState("");
  CustomConsole(props.amountEntered + "mywallet amountentered");
  CustomConsole(orderIdDetails);
  React.useEffect(() => {
    if (
      orderIdDetails &&
      orderIdDetails.orderId &&
      orderIdDetails.orderId !== undefined &&
      orderIdDetails.orderId.length > 0
    ) {
      setOrderId(orderIdDetails.orderId[0].order_id);
      setOrderFlag(true);
    } else if (props.orderId !== undefined && props.orderId !== "") {
      setOrderId(props.orderId);
      setOrderFlag(true);
    } else {
      setOrderId("");
      setOrderFlag(false);
    }
  }, [orderIdDetails, props.orderId]);

  CustomConsole(orderIdDetails);
  //Useeffect for getting userid
  React.useEffect(() => {
    if (profileDetails?.user?.user_id !== undefined) {
      setexternalCustomerId(profileDetails.user.user_id);
    }
  }, [profileDetails]);

  //minimumwalletAmount updating
  React.useEffect(() => {
    // CustomConsole(appmetaDataDetail)
    appmetaDataDetail.metaData.map((data) => {
      // CustomConsole(data)
      if (data.key === "minimum_wallet_amount") {
        // CustomConsole(data.value)
        setMinimumWalletAmount(data.value[0].v);
      }
    });
  }, [appmetaDataDetail]);

  //Useeffect for getting current wallet details
  React.useEffect(() => {
    CustomConsole(walletDetails);
    if (walletFlag === true) {
      CustomConsole("inside if wallet flag");
      setCurrentWalletAmount(walletDetails.walletAmount);
      setWalletFlag(false);
    }
  }, [walletDetails]);

  React.useEffect(() => {
    if (props.myWalletInBooking === true) {
      props.setEnteredPayAmount(props.amountEntered);
    }
  }, [props.opean, props.amountEntered]);

  //Useeffect for getting address details and virtuval bank details
  React.useEffect(() => {
    if (
      vbDetails?.virtualBankDetails !== undefined &&
      vbDetails?.virtualBankDetails !== null &&
      Object.keys(vbDetails.virtualBankDetails).length == 0
    )
      dispatch(GetUserVirtualAccount());
  }, []);

  //useEffect for handling upi link based on phone model
  React.useEffect(() => {
    if (anyUpiAppLink !== "" && !payButtonFlag) {
      const isiPhone = /iPhone/i.test(navigator.userAgent);
      CustomConsole(navigator.userAgent);
      if (isiPhone) {
        setUpiDataReceived(true);
        setPaymentLoaderFlag(false);
        window.location.href = gpayLink;
        setUpiTransactionPage(true);
        setAnyUpiAppLink("");
        setpayButtonFlag(true);
      } else {
        setUpiDataReceived(true);
        setPaymentLoaderFlag(false);
        window.location.href = anyUpiAppLink;
        setUpiTransactionPage(true);
        setAnyUpiAppLink("");
        setpayButtonFlag(true);
      }
    }
  }, [anyUpiAppLink]);

  //useeffect for opening virtuval bank details
  React.useEffect(() => {
    if (props.openVbDialog === true) {
      props.setOpenVbDialog(true);
    }
  }, [props.openVbDialog]);

  //Useeffect for hitting paysharp api
  React.useEffect(() => {
    if (props.verifyUpiClicked === true) {
      CustomConsole("Hitting upi id verify api");
      dispatch(paymentMethodAction.PaythroughVPAPaysharpUPI(props.upiId));
      props.setVerifyUpiIdClicked(false);
    }
  }, [props.verifyUpiClicked]);

  React.useEffect(() => {
    if (bookWithUpiId === true) {
      CustomConsole(`props.upiIdVPAObj ${props.upiIdVPAObj}`);
      dispatch(
        paymentMethodAction.PaythroughVPAPaysharpUPIRequest(props.upiIdVPAObj)
      ).then((data) => {
        console.log(data)
        debugger
        if (data?.data?.code === 500) {
          setPaymentLoaderFlag(true);
          setUpiPaymentDialogOpen(false);
          setUpiPay(false);
          setOpenUpiFailed(true);
        }
      });
      if (pageName === null) {
        // props.setpromoCode("");
      }
    }
  }, [bookWithUpiId]);

  React.useEffect(() => {
    if (upiPay === true) {
      CustomConsole("Hitting UPI pay Api");
      dispatch(
        paymentMethodAction.PaythroughPaysharpUPI({
          orderId: orderID,
          amount: parseFloat(props.amountEntered),
          pay_type: props.payType,
          promo_code: props.promoCode,
        })
      );
      if (pageName === null) {
        // props.setpromoCode("");
      }
    }
  }, [upiPay]);

  React.useEffect(() => {
    CustomConsole("payment use effect");
    CustomConsole(payThroughUpi);
    CustomConsole(`props.payType ${props.payType}`);
    CustomConsole(props.verifyUpiClicked);
    if (
      payThroughUpi?.message !== undefined &&
      payThroughUpi?.message !== "" &&
      payThroughUpi?.message?.data !== undefined &&
      upiPay === true &&
      props?.verifyUpiClicked === false
    ) {
      if (props.payType === 100) {
        CustomConsole(`Gpay Link : ${payThroughUpi.message.data.gpayUrl}`);
        CustomConsole(`Phonepe Link: ${payThroughUpi.message.data.phonepeUrl}`);
        setUpiDataReceived(true);
        setPaymentLoaderFlag(false);
        // gpay = payThroughUpi.message.data.gpayUrl;
        setWalletPayOrderId(payThroughUpi.message.data.orderId);
        setGpayLink(payThroughUpi.message.data.gpayUrl);
        setPhonepeLink(payThroughUpi.message.data.phonepeUrl);
        setAnyUpiAppLink(payThroughUpi.message.data.intentUrl);
        setUpiPay(false);
      } else if (props.payType === 200) {
        CustomConsole(`qrCode ${payThroughUpi.message.data.qrCode}`);
        setUpiDataReceived(true);
        setPaymentLoaderFlag(false);
        setWalletPayOrderId(payThroughUpi.message.data.orderId);
        setQrCodeLink(payThroughUpi.message.data.qrCode);
        setUpiPay(false);
      }
    }
    if (props.verifyBtn === true) {
      if (
        payThroughUpi?.message !== undefined &&
        payThroughUpi?.message !== "" &&
        payThroughUpi?.message?.error === false &&
        payThroughUpi?.message?.valid_data !== undefined
      ) {
        props.setIsUpiIdValid(payThroughUpi.message.valid_data.isValid);
        // setVerifyUpiIdClicked(false);
      }
    }
    if (bookWithUpiId === true) {
      if (
        payThroughUpi?.message !== undefined &&
        payThroughUpi?.message !== "" &&
        payThroughUpi?.message.valid_data?.isValid
      ) {
        CustomConsole("after hitting upi id request");
        CustomConsole(payThroughUpi);
        setWalletPayOrderId(
          payThroughUpi?.message?.data !== undefined &&
            payThroughUpi?.message?.data?.orderId !== undefined
            ? payThroughUpi?.message?.data?.orderId
            : ""
        );
        setUpiPaymentDialogOpen(true);
        setUpiDataReceived(true);
        setPaymentLoaderFlag(false);
        setUpiTransactionPage(true);
        setBookWithUpiId(false);
      }
    }
  }, [payThroughUpi.message]);

  const handlePdwBSDialogClose = () => {
    setUpiPaymentDialogOpen(false);
    setOpenEnterAmountDialog(false);
  };

  const handlePaymentOption = () => {
    setPMDialogOpen(false);
    setQrCodeLink("");
    setUpiDataReceived(false);
    setUpiTransactionPage(false);
    CustomConsole(orderID);
    CustomConsole(props.upiUserOption);
    CustomConsole(props.payType);
    CustomConsole("hitted here" + props.amountEntered);
    CustomConsole(minimumWalletAmount);
    CustomConsole(props.payment);
    CustomConsole(upiPay);
    CustomConsole(bookWithUpiId);
    if (
      parseFloat(props.amountEntered) >= parseFloat(minimumWalletAmount) ||
      orderID !== ""
    ) {
      const data = {
        transaction_ts: moment.utc().format(),
        amount: props.amountEntered,
        order_id: orderID,
        payment_method: "WATERWALLET",
        payment_type: "PREPAID",
      };

      if (props.payment === 0) {
        setOpenEnterAmountDialog(false);
        CustomConsole("Proceed to Next Step");
        setOpenRazorPay(true);
      }
      if (props.payment === 1 && props.upiUserOption == "4") {
        setOpenEnterAmountDialog(false);
        props.setOpenVbDialog(true);
      }
      if (
        props.payment === 1 &&
        props.payType === 100 &&
        props.upiUserOption == "3"
      ) {
        CustomConsole("-------------------");
        setOpenEnterAmountDialog(false);
        setBookWithUpiId(true);
        props.setUpiIdVPAObj({
          // orderId: currentOrderId,
          orderId: orderID,
          amount: parseFloat(props.amountEntered),
          customerVPA: props.upiId,
          promo_code: props.promoCode,
        });
        setUpiTransactionPage(true);
      }
      if (
        props.payment === 1 &&
        props.payType === 100 &&
        props.upiUserOption == "2"
      ) {
        setOpenEnterAmountDialog(false);
        setUpiPay(true);
        setUpiTransactionPage(true);
      }
      if (
        props.payment === 1 &&
        props.payType === 200 &&
        props.upiUserOption == "1"
      ) {
        setOpenEnterAmountDialog(false);
        setUpiPay(true);
        setUpiTransactionPage(true);
      }
      if (props.payment === 3) {
        //water wallet
        if (
          data.order_id !== "" &&
          data.order_id !== undefined &&
          pageName !== "booking"
        ) {
          dispatch(paymentMethodAction.PayThroughWallet(data));
        }
      }
    } else {
      CustomConsole("Enter amount greater than 40");
      toast.dark("Please add atleast" + " " + minimumWalletAmount, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };

  // const [transactionDate, setTransactionDate] = React.useState(0)
  const handleRazorPayResponse = (data) => {
    CustomConsole("----------handleRazorPayResponse-------");
    CustomConsole(data);
    CustomConsole(JSON.stringify(data));
    if (data.razorpay_payment_id === null) {
      CustomConsole("data not received");
    }
    let dateWithMoment = moment();
    CustomConsole(`dateWithMoment: ${dateWithMoment}`);
    CustomConsole("User ID");
    let paymentReference = "";
    if (data.error === true) {
      paymentReference = data.response.error.metadata.payment_id;
      CustomConsole(`paymentReference if Error ${paymentReference}`);
    } else {
      paymentReference = data.response.razorpay_payment_id;
      CustomConsole(`paymentReference if not Error ${paymentReference}`);
    }

    if (data.error === false) {
      props.setrazorPayFlag(true);
    }
    // const date = new Date();
    const date = moment.utc().format();
    // const transactionDate = new Date(date + " UTC");
    // const transactionDateToString = transactionDate.toISOString();
    const transactionDateToString = date + "";
    CustomConsole(`transactionDateToString ${transactionDateToString}`);
    CustomConsole(typeof transactionDateToString);
    CustomConsole("props.amountEntered");
    CustomConsole(typeof props.amountEntered);

    let razorPayDetails = {
      amount: parseFloat(props.amountEntered),
      attemptCount: 1,
      bankName: "RazorPay Bank",
      beneficiaryName: "RazorPayBeneficiary",
      email: "",
      externalCustomerId: externalCustomerId,
      fee: 0,
      ifscCode: "RazorPayIFSC",
      mobileNo: "",
      name: "",
      netAmount: 0.0,
      order_id: orderID, //Send Empty OrderId
      payment_type: orderFlag ? "PREPAID" : "WALLET", //Prepaid/PostPaid/Wallet
      paysharpReferenceNo: paymentReference,
      tax: 0.0,
      totalFee: 0.0,
      transactionDate: transactionDateToString,
      utrNumber: 0,
      virtualAccountNo: 12345678901234567,
    };

    const paymentStatus = data.error ? "FAILED" : "SUCCESS";

    const razorPayObj = {
      razorpay_signature: data.error
        ? "FAILED_PAYMENT"
        : data.response.razorpay_signature,
      razorpay_order_id: data.error
        ? data.response.error.metadata.order_id
        : data.response.razorpay_order_id,
      transaction_id: data.error
        ? data.response.error.metadata.payment_id
        : data.response.razorpay_payment_id,
      amount: parseFloat(props.amountEntered),
      user_id: externalCustomerId,
      phone: data.response.phone,
      email: data.response.email,
      payment_type: orderFlag ? "PREPAID" : "WALLET",
      payment_status: paymentStatus,
      reason: "PAID THROUGH RAZORPAY",
      payment_method: "RAZORPAY",
      deposit_details: null,
      order_id: data.error ? "" : data.response.order_id,
      transactionDate: transactionDateToString,
      promo_code: props.promoCode,
    };
    CustomConsole(razorPayDetails);
    CustomConsole("----razorpayDetails-----");
    CustomConsole(razorPayObj);
    // const paymentStatus = data.error ? "FAILED" : "SUCCESS";

    // dispatch(paymentMethodAction.PayThroughRazorPayNew(razorPayObj));
    setRazorPayDialogFlag(true);

    if (data.error) {
      setRazorPayId(data.response.error.metadata.order_id);
      handleRazorPayClose();
    } else {
      setRazorPayId(data.response.razorpay_order_id);
    }
    if (pageName === null) {
      // props.setpromoCode("");
    }
  };

  //function for handling razor pay transaction page
  const handleRazorPayClose = () => {
    setOpenRazorPay(false);
    if (
      pageName &&
      pageName !== "subscribe" &&
      !pageName.includes("editSubscribe") &&
      pageName !== "booking"
    ) {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
    }
    if (pageName === "booking") {
      history.push("/dashboard/myorders");
    }
    if (pageTitleSideBar[1] === "buygiftcard") {
      history.push("/dashboard/giftcards");
    }
  };
  //function for closing virtual bank details
  const handleCloseVbDialog = () => {
    CustomConsole("------handleCloseVbDialog------");
    props.setOpenVbDialog(false);
  };
  const handlePaysharpStatus = (data) => {
    CustomConsole("------handlePaysharpStatus------");
    CustomConsole(data);
  };

  //For closing Add Money Dialog Box
  const handleClose = () => {
    props.setOpean(false);
    props.setPayFor("");
    setUpiPaymentDialogOpen(false);
  };

  //useeffect for triggering payment dialog box
  React.useEffect(() => {
    if (props.paymentFlag) {
      setPaymentLoaderFlag(false);
      setUpiDataReceived(false);
      handlePaymentOption();
      props.setpaymentFlag(false);
      if (props.payment !== 0 && props.payment !== 3) {
        setUpiPaymentDialogOpen(true);
      }
    }
  }, [props.paymentFlag]);

  //Function for handling razor pay dialog closing
  const handleRazorClose = () => {
    if (pageTitleSideBar[1] === "buygiftcard") {
      history.push("/dashboard/giftcards");
    } else {
      history.push("/dashboard/myorders");
    }
  };

  //useeffect for triggering payment dialog box
  React.useEffect(() => {
    if (payThroughUpi?.message === "Payment Failed") {
      setPaymentLoaderFlag(true);
      setUpiPaymentDialogOpen(false);
      setUpiPay(false);
      payThroughUpi.message = "";
      setOpenUpiFailed(true);
    }
    if (payThroughUpi?.isPaymentUpi) {
      setPaymentLoaderFlag(true);
      setUpiPaymentDialogOpen(false);
      setUpiPay(false);
      payThroughUpi.isPaymentUpi = false;
      setOpenUpiFailed(true);
    }
  }, [payThroughUpi]);

  CustomConsole(upiDataReceived === false);
  CustomConsole(paymentLoaderFlag);
  CustomConsole(upiDataReceived === false && !paymentLoaderFlag);
  CustomConsole(payThroughUpi);
  CustomConsole(upiDataReceived);
  CustomConsole(upiTransactionPage);
  CustomConsole(vbDetails.virtualBankDetails);

  //function to trigger any api link
  const handelPaymentRetry = () => {
    window.location.href = anyUpiAppLink;
  };
  return (
    <>
      {openRazorPay ? (
        <PayByRazorPay
          handleClose={handleClose}
          promoCode={props.promoCode}
          userId={externalCustomerId}
          orderId={orderID}
          amount={parseFloat(props.amountEntered)}
          handleRazorPayResponse={handleRazorPayResponse}
          handleRazorPayClose={handleRazorPayClose}
        />
      ) : (
        ""
      )}

      {props?.openVbDialog && vbDetails?.isVbDetailsExists ? (
        <VBDetailsDialogBox
          open={props.openVbDialog}
          handleClose={handleCloseVbDialog}
          price={""}
          orderId={""}
          bankDetails={vbDetails.virtualBankDetails}
          handlePaysharpStatus={handlePaysharpStatus}
          currentWalletAmount={currentWalletAmount}
          setWalletFlag={setWalletFlag}
        />
      ) : (
        ""
      )}

      {/* Dialog Box: Enter AMount to Pay through Razor Pay */}
      {props.payment !== 0 ? (
        <>
          <Material.Box>
            <Material.Dialog
              open={upiPaymentDialogOpen}
              // onClose={cancelUpiPay}
            >
              {upiDataReceived === false && !paymentLoaderFlag ? (
                <>
                  <img
                    src={BiconLoader}
                    alt="Loading..."
                    style={{ height: "50px", width: "50px" }}
                  />
                </>
              ) : (
                ""
              )}
              {/* Dialog Box: Enter AMount to Pay through Razor Pay */}
              {upiDataReceived === true ? (
                <>
                  <>
                    <UPITransactionDialog
                      upiTransactionPage={upiTransactionPage}
                      setUpiTransactionPage={setUpiTransactionPage}
                      currentOrderId={walletPayOrderId}
                      handlePdwBSDialogClose={handlePdwBSDialogClose}
                      upiDataReceived={upiDataReceived}
                      setUpiDataReceived={setUpiDataReceived}
                      inAccountPage={props.inAccountPage}
                      // handlePaymentCancel={handlePaymentCancel}
                      openUPIStatus={openUPIStatus}
                      setOpenUPIStatus={setOpenUPIStatus}
                      paytype={props.payType}
                      qrCodeLink={qrCodeLink}
                      upiUserOption={props.upiUserOption}
                      setUpiPaymentDialogOpen={setUpiPaymentDialogOpen}
                      setOpenEnterAmountDialog={setOpenEnterAmountDialog}
                      setUpiPay={setUpiPay}
                      handleClose={handleClose}
                      handelPaymentRetry={handelPaymentRetry}
                      amountEntered={parseFloat(props.amountEntered)}
                    />
                  </>
                  {/* </Material.Box> */}
                </>
              ) : (
                ""
              )}
            </Material.Dialog>
          </Material.Box>
        </>
      ) : (
        ""
      )}

      {razorPayDialogFlag && (
        <RazorPayTransactionDialog
          handleClose={handleRazorClose}
          razorPayDialogFlag={razorPayDialogFlag}
          setRazorPayDialogFlag={setRazorPayDialogFlag}
          orderId={""}
          razorPayOrderId={razorPayId}
        />
      )}
      <UpiFailedDialog
        setOpenDepositSucessDialog={setOpenUpiFailed}
        open={OpenUpiFailed}
      />
    </>
  );
}

export default MyWallet;
