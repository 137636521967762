// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 08/Jan/2024
// File Name: depositHistroyMain.jsx
// Author: Gowtham Prasath
// Email/Phone: gowtham.prasath@bookwater.com / +91-8817679918
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import CanImage from "../../Assets/Images/WaterCanImg.png";
import { Box, Typography } from "@mui/material";
import appColors from "../../Assets/AppTheme/appColors";
import PrimarySkuButton from "../../Components/Buttons/PrimarySkuButton";
import DepositRefundDialog from "../../Components/DialogBoxes/SkuDialogs/DepositDialoges/depositRefund";
import ExtraDepositDialog from "../../Components/DialogBoxes/SkuDialogs/DepositDialoges/extraDepositDialog";
import ReplacementDialog from "../Support/ReplacementDialog";
import ReturnCanReqDialog from "../Reports/ReturnCanReqDialog";
import { useDispatch, useSelector } from "react-redux";
import appTheme from "../../Assets/AppTheme/appTheme";
import ReturnCanOrderDetailsDialog from "../../Components/DialogBoxes/SkuDialogs/DepositDialoges/returnCanDetailsDialog";
import {
  ReplacementOrderHistroy,
  ReturnCanOrderHistroy,
} from "../../Redux/actions/returnReplacementAction";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import appMessages from "../../Assets/AppMeta/appMessages";

export default function DepositDetailsSku({
  selectedData,
  setOrderId,
  setBookingPaymentFlag,
  setPaymentType,
  paymentType,
  setEnteredPayAmount,
  setpaymentFlag,
  payType,
  isUpiIdValid,
  upiUserOption,
  amountEntered,
  openExtraDepositDialog,
  setOpenExtraDepositDialog,
}) {
  const [openRefundDialog, setRefundDialog] = React.useState(false);
  const dispatch = useDispatch();
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const ReturnReplacemtCanOrder = useSelector(
    (state) => state.ReturnReplacemtCanOrder
  );
  const [isReqReturnDialogOpen, setIsReqReturnDialogOpen] =
    React.useState(false);
  const [openReplacementDialog, setOpenReplacementDialog] =
    React.useState(false);
  const [replacementReasons, setReplacementReasons] = React.useState([]);
  const [orderType, setOrderType] = React.useState("");
  const [returnCanDialogOpen, setReturnCanDialogTrue] = React.useState();
  //function fpr closing refund dialog close
  const handelCloseRefundDialog = () => {
    setRefundDialog(false);
  };

  //function for handel open dialog box
  const handelOpenRefundDialog = () => {
    if (selectedData?.max_with_out_depo_qty > 0) {
      setRefundDialog(true);
    } else {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: appMessages.depositRefundToast,
        })
      );
    }
  };

  //function fpr closing extra deposit dialog close
  const handelCloseExtraDeposit = () => {
    setOpenExtraDepositDialog(false);
  };

  //function for handel open dialog box
  const handelOpenExtraDeposit = () => {
    setOpenExtraDepositDialog(true);
  };

  //useEffect for getting reason
  React.useEffect(() => {
    setUpConfigData.metaData.map((data) => {
      if (data.key === "replacementReasons") {
        setReplacementReasons(data.value);
      }
    });
  }, [setUpConfigData]);

  //function for opening return can dialog
  const handelOpenReturnCanDialog = () => {
    dispatch(
      ReturnCanOrderHistroy(
        0,
        10,
        selectedData?.container_capacity,
        selectedData?.container_material
      )
    ).then((resp) => {
      if (!resp.data.error) {
        setOrderType("return");
        setReturnCanDialogTrue(true);
      } else {
        if (selectedData?.filled_container_qty > 0) {
          setIsReqReturnDialogOpen(true);
          setOrderType("");
        } else {
          dispatch(
            ToastMsgState({
              error: true,
              containerFlag: true,
              msg: appMessages.depositReturnToast,
            })
          );
        }
      }
    });
  };

  //function for opening replacement can  dialog box
  const handelOpenReplacement = () => {
    dispatch(
      ReplacementOrderHistroy(
        0,
        10,
        selectedData?.container_capacity,
        selectedData?.container_material
      )
    ).then((resp) => {
      if (!resp.data.error) {
        setOrderType("replace");
        setReturnCanDialogTrue(true);
      } else {
        if (selectedData?.filled_container_qty > 0) {
          setOpenReplacementDialog(true);
          setOrderType("");
        } else {
          dispatch(
            ToastMsgState({
              error: true,
              containerFlag: true,
              msg: appMessages.depositReplaceToast,
            })
          );
        }
      }
    });
  };

  //function to handel return can dialog close
  const handelReturnCanDialogClose = () => {
    setIsReqReturnDialogOpen(false);
  };

  //function to close rreturn can dialog
  const handelReturnCanDialogLiseClose = () => {
    setReturnCanDialogTrue(false);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography sx={{ color: appColors.textColorNew, fontWeight: "bold" }}>
          Deposit Details
        </Typography>
        <Box sx={{ margin: "1rem" }}>
          <Box sx={{ borderBottom: "1px solid gray" }}>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Product Details
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.container_material}/
              {selectedData?.container_capacity}
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "1px solid gray" }}>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Filled Products
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.filled_container_qty}
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "1px solid gray" }}>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Deposited Products
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.total_deposited_containers_qty}
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "1px solid gray" }}>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Deposit Paid Price
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.current_deposit_amount}
            </Typography>
          </Box>
          <Box sx={{ borderBottom: "1px solid gray" }}>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Complimentary Products
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.total_undeposited_containers_qty}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.textColorNew,
              }}
            >
              Balance Products Available
            </Typography>
            <Typography
              sx={{
                fontSize: appTheme.fontSizeSmall,
                color: appColors.darkGray,
              }}
            >
              {selectedData?.new_containers_to_provide}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: 2,
              marginTop: "1rem",
            }}
          >
            <>
              <PrimarySkuButton
                btnName="Return Request"
                color={appColors.textColorNew}
                handleButtonClick={handelOpenReturnCanDialog}
              />
              <PrimarySkuButton
                btnName="Replacement Request"
                color={appColors.textColorNew}
                handleButtonClick={handelOpenReplacement}
              />
              <PrimarySkuButton
                btnName="Claim Deposit"
                color={appColors.textColorNew}
                handleButtonClick={handelOpenRefundDialog}
              />{" "}
              <PrimarySkuButton
                btnName="Add Deposit"
                color={appColors.textColorNew}
                handleButtonClick={handelOpenExtraDeposit}
              />
            </>
          </Box>
        </Box>
      </Box>

      {openRefundDialog && (
        <DepositRefundDialog
          open={openRefundDialog}
          onClose={handelCloseRefundDialog}
          data={selectedData}
        />
      )}
      {openExtraDepositDialog && (
        <ExtraDepositDialog
          open={openExtraDepositDialog}
          onClose={handelCloseExtraDeposit}
          data={selectedData}
          setOrderId={setOrderId}
          setBookingPaymentFlag={setBookingPaymentFlag}
          paymentType={paymentType}
          setEnteredPayAmount={setEnteredPayAmount}
          amountEntered={amountEntered}
          setpaymentFlag={setpaymentFlag}
          payType={payType}
          isUpiIdValid={isUpiIdValid}
          upiUserOption={upiUserOption}
        />
      )}

      {openReplacementDialog && (
        <ReplacementDialog
          openReplacementDialog={openReplacementDialog}
          setOpenReplacementDialog={setOpenReplacementDialog}
          userStock={selectedData}
          replacementReasons={replacementReasons}
        />
      )}
      {isReqReturnDialogOpen && (
        <>
          <ReturnCanReqDialog
            isReqReturnDialogOpen={isReqReturnDialogOpen}
            setIsReqReturnDialogOpen={setIsReqReturnDialogOpen}
            userStock={selectedData}
          />
        </>
      )}
      {returnCanDialogOpen && (
        <ReturnCanOrderDetailsDialog
          open={returnCanDialogOpen}
          onClose={handelReturnCanDialogLiseClose}
          orderType={orderType}
          setIsReqReturnDialogOpen={setIsReqReturnDialogOpen}
          setOpenReplacementDialog={setOpenReplacementDialog}
          selectedData={selectedData}
        />
      )}
    </>
  );
}
