import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  applyError: true,
  applyMsg: "",
  Promocodedata: [],
  discountPrice: null,
  promoDiscountPrice: 0,
  appliedPromoLabel: "",
  promoAppliedPrice: null,
  promoType: null,
  promoDiscount: null,
  usedWaterDrops: null,
  waterDropAppliedPrice: null,
  promoId: 0,
};

const PromocodeReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.GET_PROMO_CODES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        Promocodedata: action.payload.data,
      };
    case actionType.APPLY_PROMO_CODE:
      return {
        ...state,
        applyError: action.payload.error,
        applyMsg: action.payload.msg,
        discountPrice: action.payload.discount_price,
        promoDiscountPrice: action.payload.discount_price,
        promoType: action.payload.offer_type,
        promoAppliedPrice: action.payload.promo_applied_price,
        appliedPromoLabel: action.payload.promo_label,
        promoId: action.payload.promo_id,
      };
    case actionType.APPLY_WATER_DROPS:
      return {
        ...state,
        waterDropApplyError: action.payload.error,
        waterDropApplyMsg: action.payload.msg,
        discountPrice: action.payload.discount_price,
        promoDiscount: action.payload.discount_price,
        waterDropAppliedPrice: action.payload.paying_price,
        usedWaterDrops: action.payload.used_Water_Drops,
      };
    case actionType.RESET_PROMO_CODE:
      return {
        ...initialState,
     
      };
    default:
      return state;
  }
};

export default PromocodeReducer;
