/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "./myAccountsListStyle";
import * as FcIcons from "react-icons/fc";
import { makeStyles } from "@material-ui/core";
import { ThemeProvider } from "@mui/material/styles";
import validator from "validator";
import { get } from "axios";
import { toast } from "react-toastify";
import closeIcon from "../../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import appColors from "../../../../Assets/AppTheme/appColors";
import { createTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export function AddNewAccountDialogBox(props) {
  const themes = useTheme();
  const isSmallScreen = useMediaQuery(themes.breakpoints.down("sm"));
  const isFullScreen = isSmallScreen;
  const [details, setDetails] = React.useState({
    accountName: "",
    accountNumber: "",
    confirmAccountNumber: "",
    accountType: "1",
    ifscCode: "",
    micrNumber: "",
    email: "",
    phone: "",
  });
  const [bankName, setBankName] = React.useState("");
  const [branchName, setBranchName] = React.useState("");
  const [phoneHt, setPhoneHt] = React.useState("");
  const [emailHt, setemailHt] = React.useState("");
  const [accountNameHt, setNameHt] = React.useState("");
  const [acNumbereHt, setAcNumberHt] = React.useState("");
  const [confirmAcNumbereHt, setConfirmActNumberHt] = React.useState("");
  const [micrNumbereHt, setMicrNumberHt] = React.useState("");
  const [isPrimary, setIsPrimary] = React.useState(false);
  const [error, setError] = React.useState(true);

  const theme = createTheme({
    palette: {
      error: {
        main: "#0e2954", // Change this to the desired blue color
      },
    },
  });

  //For Changing helper text based onchange
  const helperTextStyles = makeStyles((theme) => ({
    root: {
      marginBottom: 10,
      color: appColors.darkGray,
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: theme.palette.common.white,
      },
    },
  }));

  const helperTestClasses = helperTextStyles();

  const style = {
    display: "flex",
    justifyContent: "center",
    bgcolor: appColors.secondaryCard,
    height: "100%",
    marginBottom: "6rem",
  };
  React.useEffect(() => {
    if (validate() === true) {
      setError(false);
    } else {
      setError(true);
    }
  }, [details, bankName, branchName]);
  const handleChange = (prop) => (event) => {
    setDetails({ ...details, [prop]: event.target.value });
  };

  //Function for validating text based on entry
  const validate = () => {
    let ret = true;
    //Account Number validation
    if (details.accountNumber.length < 9 || details.accountNumber.length > 18) {
      setAcNumberHt("Account Number Must have Min:9-18 Digits");
      ret = false;
    } else {
      setAcNumberHt("");
    }
    //Confirm Account Number  validation
    if (
      details.confirmAccountNumber.length < 9 ||
      details.confirmAccountNumber.length > 18
    ) {
      setConfirmActNumberHt("Account Number Must have 9-18 Digits");
      ret = false;
    } else {
      setConfirmActNumberHt("");
    }
    //Account Number  & Confirm Account Number validation
    if (details.accountNumber !== details.confirmAccountNumber) {
      setConfirmActNumberHt("Confirm Account Number Mismatched.");
      ret = false;
    } else {
      setConfirmActNumberHt("");
    }
    //MICR Number Validation
    if (details.micrNumber.length !== 9) {
      setMicrNumberHt("MICR Number Must be 9 digits");
    } else {
      setMicrNumberHt("");
    }
    //Full Name Validation
    let reg = /^[a-zA-Z0-9]/; //Full Name Validation
    if (!reg.test(details.accountName)) {
      setNameHt("Please Enter valid Name");
      ret = false;
    } else {
      setNameHt("");
    }
    if (bankName === "" || branchName === "") {
      ret = false;
    }
    reg = /^[6-9][0-9]{9}$/; //Phone Number validation
    if (!reg.test(details.phone)) {
      setPhoneHt("Please enter a 10-digit Numbers start with 9/8/7/6");
      ret = false;
    } else {
      setPhoneHt("");
    }
    if (!validator.isEmail(details.email)) {
      ret = false;
      setemailHt("Invalid Email. Hint:user@gmail.com");
    } else {
      setemailHt("");
    }
    return ret;
  };

  //Submiting function for bank account details
  const handleSubmit = () => {
    if (!error) {
      props.handleAddNewBankAccount(details, bankName, branchName, isPrimary);
    }
  };

  //Function for verifing ifsc code
  const handleIfscVerify = () => {
    get("https://ifsc.razorpay.com/" + details.ifscCode)
      .then((res) => {
        setBankName(res.data.BANK);
        setBranchName(res.data.BRANCH);
      })
      .catch((err) => {
        toast.dark("Invalid IFSC Code, Please Enter valid IFSC Code", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        setBankName("");
        setBranchName("");
      });
  };

  //Function for closing dialog box
  const handleClose = () => {
    props.setOpen(false);
    setDetails({
      accountName: "",
      accountNumber: "",
      confirmAccountNumber: "",
      accountType: "1",
      ifscCode: "",
      micrNumber: "",
      email: "",
      phone: "",
    });
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: "13px", // Set the desired label color
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <Material.Dialog
        fullWidth
        maxWidth={isFullScreen ? "xl" : "sm"}
        fullScreen={isFullScreen}
        open={props.open}
        onClose={props.handleClose}
        TransitionComponent={Transition}
      >
        <Material.Box
          sx={{
            backgroundColor: appColors.secondaryCard,
            padding: "1rem 1rem 0 1rem",
          }}
        >
          <Material.Typography sx={{ color: appColors.secondaryText }}>
            Add New Bank Account
          </Material.Typography>
        </Material.Box>
        <Material.DialogContent sx={{ background: appColors.secondaryCard }}>
          <Material.Box sx={style}>
            <Material.Box
              sx={{
                padding: "0 0rem 0rem 0rem",
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                maxHeight: "100vh",
              }}
            >
              {/* Accountant Name */}
              <Material.TextField
                variant="outlined"
                label="Account Holder Name *"
                sx={styles.modalTxt}
                size="small"
                helperText={accountNameHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
                onChange={handleChange("accountName")}
                value={details.accountName}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
              />
              <Material.TextField
                variant="outlined"
                label="Email Address *"
                type="email"
                sx={styles.modalTxt}
                size="small"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                onChange={handleChange("email")}
                value={details.email}
                helperText={emailHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
              />
              {/* Account number */}
              <Material.TextField
                variant="outlined"
                label="Account Number *"
                type="number"
                sx={styles.modalTxt}
                size="small"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 20);
                }}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                onChange={handleChange("accountNumber")}
                value={details.accountNumber}
                helperText={acNumbereHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
              />
              {/* Confirm Account Number */}
              <Material.TextField
                variant="outlined"
                label="Confirm Account Number *"
                sx={styles.modalTxt}
                type="number"
                size="small"
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, 20);
                }}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                onChange={handleChange("confirmAccountNumber")}
                value={details.confirmAccountNumber}
                helperText={confirmAcNumbereHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
              />

              <Material.Box
                sx={{
                  display: "flex",
                  marginBottom: "1rem",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: { md: "flex-end", xs: "center" },
                }}
              >
                {/* IFSC Number & Verify Button */}
                <Material.Box
                  sx={{
                    marginTop: "12px",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Material.TextField
                    variant="outlined"
                    label="IFSC Code *"
                    sx={{ width: "150px" }}
                    size="small"
                    onChange={handleChange("ifscCode")}
                    value={details.ifscCode}
                    InputProps={{
                      sx: {
                        bgcolor: appColors.secondaryCard,
                        color: appColors.darkGray,
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderColor: appColors.darkGray,
                        },
                      },
                    }}
                    InputLabelProps={inputLabelProps}
                  />
                  <Material.Button
                    variant="contained"
                    sx={{
                      marginLeft: "15px",
                      width: "15%",
                      textTransform: "none",
                      backgroundColor: appColors.textColorNew,
                      color: "white",
                    }}
                    onClick={handleIfscVerify}
                  >
                    Verify
                  </Material.Button>
                </Material.Box>

                {/* Branch Name */}
                <Material.TextField
                  variant="outlined"
                  label="Branch Name *"
                  sx={{ marginTop: "12px", width: "100%" }}
                  size="small"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  value={branchName}
                />
              </Material.Box>

              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: { md: "flex-end", xs: "center" },
                }}
              >
                {/* Bank Name */}
                <Material.TextField
                  variant="outlined"
                  label="Bank Name *"
                  sx={styles.modalTxt}
                  size="small"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  value={bankName}
                />
                {/* MICR Number*/}
                <Material.TextField
                  variant="outlined"
                  label="MICR Number"
                  type="number"
                  sx={styles.modalTxt}
                  size="small"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  onChange={handleChange("micrNumber")}
                  value={details.micrNumber}
                  helperText={micrNumbereHt}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                />
              </Material.Box>

              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: { md: "flex-end", xs: "center" },
                }}
              >
                {/* Account Type */}
                <Material.FormControl
                  variant="outlined"
                  sx={styles.modalTxt}
                  size="small"
                >
                  <Material.InputLabel sx={{ color: appColors.darkGray }}>
                    Account Type *
                  </Material.InputLabel>
                  <Material.Select
                    value={details.accountType}
                    onChange={handleChange("accountType")}
                    label="Account Type *"
                    sx={{
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    }}
                    InputLabelProps={inputLabelProps}
                  >
                    <Material.MenuItem disabled={true} value="">
                      Select Account Type
                    </Material.MenuItem>
                    <Material.MenuItem value="1">
                      <FcIcons.FcMoneyTransfer /> Savings Account
                    </Material.MenuItem>
                    <Material.MenuItem value="2">
                      <FcIcons.FcRatings /> Current Account
                    </Material.MenuItem>
                  </Material.Select>
                </Material.FormControl>

                {/* Mobile Number */}
                <Material.TextField
                  variant="outlined"
                  label="Mobile Number *"
                  type="number"
                  onInput={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value))
                      .toString()
                      .slice(0, 10);
                  }}
                  sx={styles.modalTxt}
                  size="small"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  onChange={handleChange("phone")}
                  value={details.phone}
                  helperText={phoneHt}
                  FormHelperTextProps={{ classes: helperTestClasses }}
                />
              </Material.Box>
              {/* Email Address */}

              <Material.Box
                sx={{
                  display: "flex",
                  marginBottom: "1rem",
                  flexDirection: "row",
                  gap: 1,
                  justifyContent: { md: "flex-end", xs: "center" },
                }}
              >
                {/* Primary or Not Check Box */}
                <Material.FormControlLabel
                  control={<Material.Checkbox />}
                  label={
                    <Material.Typography
                      sx={{ fontSize: "10px", color: appColors.darkGray }}
                    >
                      Mark as Primary
                    </Material.Typography>
                  }
                  onChange={() => setIsPrimary(!isPrimary)}
                />

                {/* SubMit Botton */}
                <Material.Box
                  sx={{
                    width: "auto",
                    textAlign: "center",
                  }}
                >
                  <Material.Button
                    disabled={error}
                    variant="contained"
                    sx={styles.btn}
                    onClick={handleSubmit}
                    size="small"
                  >
                    Submit
                  </Material.Button>
                </Material.Box>
              </Material.Box>
            </Material.Box>
          </Material.Box>
          <img
            alt=""
            src={closeIcon}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              height: "30px",
            }}
            onClick={handleClose}
          />
        </Material.DialogContent>
      </Material.Dialog>
    </ThemeProvider>
  );
}
