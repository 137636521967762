/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import { Box, Card, Grid, Radio, Typography } from "@mui/material";
import * as React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
import { useDispatch } from "react-redux";
import { AddCrateQuantity } from "../../../Redux/actions/skuBookingAction";
import ImageLoader from "../../Utils/imageLoader";

export default function CrateSelectionCard({
  selectedGreeting,
  setSelectedImage,
  selectedImage,
}) {
  const dispatch = useDispatch();

  //function to select image
  const handelSelectedImage = (data) => {
    if (data !== selectedImage) {
      setSelectedImage(data);
    } else {
      setSelectedImage("");
    }
  };
  console.log("selectedImage");
  console.log(selectedImage);
  console.log(selectedGreeting);
  return (
    <Box
      sx={{
        padding: "0 .4rem",
        display: "flex",
        flexDirection: "row",
        gap: 1,
        py: 1,
        height: "100px",
        width: "100%",
        overflowY: "auto", // Enable horizontal scrolling
        scrollSnapType: "y mandatory",
        "& > *": {
          scrollSnapAlign: "center",
        },
        "::-webkit-scrollbar": { display: "none" },
      }}
    >
      {selectedGreeting?.images?.map((item, index) => (
        <Card
          orientation="vertical"
          size="sm"
          key={index}
          variant="outlined"
          onClick={() => handelSelectedImage(item)}
          sx={{
            boxShadow: 5,
            borderRadius: "1rem",
            background: appColors.lightblue,
            minWidth: "7rem",
            minHeight: "5rem",
            border: selectedImage === item ? "2px solid blue" : "none",
          }}
          // onClick={() => handelAddCrateDetails(data)}
        >
          <Box>
            <ImageLoader
              filename={item}
              styles={{
                width: "7rem", // Allow the width to adjust according to the aspect ratio
                height: "5rem", // Allow the height to adjust according to the aspect ratio
              }}
            />
          </Box>
        </Card>
      ))}
    </Box>
  );
}
