/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import actionTypes from "./actionTypes";

export const ReturnCanOrderHistroy = (
  pageNumber,
  pageSize,
  capacity,
  material
) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/withdrawalOrderList?page_number=${pageNumber}&page_size=${pageSize}&capacity=${capacity}&material=${material}`
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionTypes.GET_RETURN_CANORDER_HISTROY,
          payload: response.data,
        });
        return response;
      } else {
        return response;
      }
    } catch (e) {}
  };
};

//function to get replacement order details

export const ReplacementOrderHistroy = (
  pageNumber,
  pageSize,
  capacity,
  material
) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/OM/admin/replaceCans/getList?page_number=${pageNumber}&page_size=${pageSize}&capacity=${capacity}&material=${material}`
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionTypes.GET_REPLACEMENT_CANORDER_HISTROY,
          payload: response.data,
        });
        return response;
      } else {
        return response;
      }
    } catch (e) {
      return e.response;
    }
  };
};

//function to edit return order order details

export const EditReturnOrder = (orderId, quantity) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/edit/withdrawalCanOrder/${orderId}`, {
        product_qty: quantity,
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (e) {
      return e.response;
    }
  };
};

//function to cancel reject can order
export const RejectReturnCanOrder = (orderId) => {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.put(`/OM/rejectWithdrawalOrder`, {
        order_id: orderId,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  };
};

//function to edit replacement order order details

export const EditReplacementOrderSubmit = (orderId, quantity) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/edit/replacementOrder/${orderId}`, {
        product_qty: quantity,
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        return response;
      } else {
        return response;
      }
    } catch (e) {
      return e.response;
    }
  };
};

//function to cancel reject replace can order
export const RejectReplacementCanOrder = (orderId) => {
  let api = useAxios();
  return async () => {
    try {
      let response = await api.put(`OM/admin/replaceCans/rejectOrder`, {
        order_id: orderId,
      });
      return response;
    } catch (e) {
      return e.response;
    }
  };
};
