/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as getDepositAction from "../../Redux/actions/getDepositHistoryAction.js";
import * as Material from "@mui/material";
import UserStockCard from "./UserStockCard";
import { renderData } from "../../Redux/actions/isLoadingAction.js";
import {
  DamageCansAccept,
  DamageCansData,
} from "../../Redux/actions/returnCansAction.js";
import DamageAcceptRejectCard from "./DamageAcceptRejectCard.jsx";
import appColors from "../../Assets/AppTheme/appColors.js";
import CustomConsole from "../../coustomConsole.js";
function Reports() {
  const dispatch = useDispatch();
  const returnCansDetails = useSelector((state) => state.returnCansDetails);
  const data = useSelector((state) => state.depositDetails);
  const [refreshFlag, setRefreshFlag] = React.useState(true);
  const [damageCansFlag, setDamageCansFlag] = React.useState(true);
  const [damageCansData, setDamageCansData] = React.useState([]);
  React.useEffect(() => {
    if (damageCansFlag) {
      dispatch(DamageCansData());
      setDamageCansFlag(false);
    }
  }, [damageCansFlag]);

  React.useEffect(() => {
    CustomConsole(returnCansDetails);
    if (returnCansDetails.damageCansData.error === false) {
      setDamageCansData(returnCansDetails.damageCansData.data);
    }
  }, [returnCansDetails]);

  React.useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false);
      dispatch(renderData("Deposit"));
    }
  }, [refreshFlag]);

  React.useEffect(() => {
    dispatch(getDepositAction.GetUserStocksAction());
  }, []);

  React.useEffect(() => {
    // CustomConsole('-------Deposit Data Changed-------')
    CustomConsole(data);
    CustomConsole(data.depositData);
    // CustomConsole(data.user_stock);
    // CustomConsole(data.msg)
  }, [data]);

  return (
    <div className="reports">
      {/* user Stock paper card */}

      <Material.Paper
        elevation={24}
        sx={{
          marginTop: "80px",
          display: "flex",
          background: appColors.cardColor,
          flexDirection: "column",
          marginBottom: { md: 0, xs: "5rem" },
          borderRadius: "20px",
          overflow: "auto",
          "&:hover": {
            boxShadow: "0px 0px 5px 5px rgb(169,169,169)",
          },
        }}
      >
        <Material.Typography
          style={{
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
            fontSize: "1rem",
            padding: "5px",
            color: appColors.darkGray,
          }}
        >
          Deposit details
        </Material.Typography>
        {data.user_stock.length >= 1 ? (
          <Material.Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              overflow: "auto",
              padding: "10px",
              gap: "15px",
            }}
          >
            {data.user_stock.map((details, key) => {
              return (
                <Material.Grid key={key} item>
                  <UserStockCard details={details} />
                </Material.Grid>
              );
            })}
          </Material.Box>
        ) : (
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/bwLogo2.png"}
              style={{ objectFit: "contain", height: "50px" }}
            />
            <Material.Typography sx={{ color: appColors.darkGray }}>
              Please Start Booking!
            </Material.Typography>
          </Material.Box>
        )}
      </Material.Paper>

      <Material.Box
        sx={{
          marginTop: "20px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "1rem",
        }}
      >
        {/* {returnCansDetails.damageCansData.error === false && (
          <>
            {returnCansDetails.damageCansData.data.map((data) => {
              return <DamageAcceptRejectCard data={data} />
            })}
          </>
        )} */}
      </Material.Box>
    </div>
  );
}

export default Reports;
