/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";

//Get all the Available Products Based on Address Orders
export const CheckOutAction = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_CHECKOUT_START });
    try {
      let response = await api.post(`/OM/booking/products/cart/update`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
      // Use setTimeout to delay setting loading to false by one second
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300); // 1000 milliseconds = 1 second
    } catch (error) {
      dispatch({
        type: actionType.CHECKOUT_PRODUCTS_ACTION,
        payload: { data: error?.response?.data, statusFlag: true },
      });
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Get all the Available Products Based on Address Orders
export const CheckOutActionLoading = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_CHECKOUT_START });
    try {
      let response = await api.post(`/OM/booking/products/cart/update`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
      // Use setTimeout to delay setting loading to false by one second
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300); // 1000 milliseconds = 1 second
    } catch (error) {
      dispatch({
        type: actionType.CHECKOUT_PRODUCTS_ACTION,
        payload: { data: error?.response?.data, statusFlag: true },
      });
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Get all the Available Products Based on Address Orders
export const SubscribeCheckOutAction = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_CHECKOUT_START });
    try {
      let response = await api.put(`/OM/subscriptions/cart/update`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
    } catch (error) {
      dispatch({
        type: actionType.CHECKOUT_PRODUCTS_ACTION,
        payload: { data: error?.response?.data, statusFlag: true },
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
    }
  };
};

//Get all the Available Products Based on Address Orders
export const SubscribeCheckOutActionLoading = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_CHECKOUT_START });
    try {
      let response = await api.put(`/OM/subscriptions/cart/update`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.CHECKOUT_PRODUCTS_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
      }
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
    } catch (error) {
      dispatch({
        type: actionType.CHECKOUT_PRODUCTS_ACTION,
        payload: { data: error?.response?.data, statusFlag: true },
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      setTimeout(() => {
        dispatch({ type: actionType.SET_LOADING_FALSE_AFTER_DELAY });
      }, 300);
    }
  };
};

//function for Reuired qty
export const AddCheckOutRequiredQty = (data) => ({
  type: actionType.ADD_CHECKOUT_REQUIRED_QTY,
  payload: { data: data, error: false },
});

//function for Redusing qty
export const ReduseCheckOutRequiredQty = (data) => ({
  type: actionType.REDUSE_CHECKOUT_REQUIRED_QTY,
  payload: { data: data, error: false },
});

//function for Reuired qty
export const AddCheckOutReturnQty = (data) => ({
  type: actionType.ADD_CHECKOUT_RETURN_QTY,
  payload: { data: data, error: false },
});

//function for Redusing qty
export const ReduseCheckOutReturnQty = (data) => ({
  type: actionType.REDUSE_CHECKOUT_RETURN_QTY,
  payload: { data: data, error: false },
});

//function for Redusing qty
export const ResetUpdateOrderError = (data) => ({
  type: actionType.RESET_UPDATE_ORDER_ERROR,
});

//function for Redusing qty
export const ClearPendingOrderData = (data) => ({
  type: actionType.CLEAR_PENDING_ORDER_DATA,
});

//function for updating error details
export const UpdateErrorDetails = (data) => ({
  type: actionType.UPDATE_CHECKOUT_ERROR_FALSE,
});
