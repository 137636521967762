/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { styles } from "./myAddressBookStyle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import NewAddressBook from "../../Components/Cards/Settings/addressBook/newAddressBook";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  AddNewAdress,
  DeleteUserAddress,
  EditAdress,
  GetUserAdressBook,
} from "../../Redux/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import appColors from "../../Assets/AppTheme/appColors";
import GoogleMapsDialog from "../../Components/DialogBoxes/GoogleAddress/googleAddressDialog";

function ProfileAddressBook(props) {
  const dispatch = useDispatch();
  const userAddressList = useSelector((state) => state.settings);
  const [open, setOpen] = React.useState(false);
  const [openDia, setOpenDia] = React.useState(false);
  const [addressId, setAddressId] = React.useState("");
  // const [isLoading, setIsLoading] = React.useState();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [address, setAddress] = React.useState({});
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);
  const [openAddAddress, setOpenAddAddress] = React.useState(false);
  const [addressType, setAddressType] = React.useState("Home");

  //function to get user address book
  React.useEffect(() => {
    if (
      userAddressList?.addresses !== undefined &&
      userAddressList?.addresses !== null &&
      userAddressList?.addresses?.length > 0
    )
      dispatch(GetUserAdressBook());
  }, []);

  //function to open delete dialog
  const handleOpenDeleteDia = (addressId) => {
    // console.log("Address ID: " + addressId);
    setOpenDia(true);
    setAddressId(addressId);
  };

  const handleCloseDia = () => {
    setOpenDia(false);
  };

  //function to open edit dialog
  const handleOpenEditDia = (address) => {
    setAddressType("edit");
    setAddress(address);
    setAddAddressDialog(true)
  };

  //function to open address dialog
  const handleClickOpen = () => {
    setAddressType("add");
    setAddress({});
    setAddAddressDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleClickOpenEditDialog = () => {
    setOpenEditDialog(true);
    props.setAvailable(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //function to handel delete address dialog
  const handleDeleteAddress = () => {
    if (addressId !== "") {
      dispatch(DeleteUserAddress(addressId));
      setOpenDia(false);
    }
  };

  return (
    <>
      {window.innerWidth > 600 ? (
        <Box>
          <Button
            onClick={handleClickOpen}
            sx={styles.addBtnAddress}
            startIcon={<AddCircleIcon />}
            size="small"
            variant="contained"
          >
            Add New Address
          </Button>
        </Box>
      ) : (
        ""
      )}
      <Box sx={styles.addressBookMain}>
        <Box sx={styles.headerPapper}>
          {userAddressList.addresses.map((data, key) => {
            return (
              <NewAddressBook
                key={key}
                addresses={data}
                handleOpenDeleteDia={handleOpenDeleteDia}
                handleOpenEditDia={handleOpenEditDia}
              />
            );
          })}

          {window.innerWidth <= 600 ? (
            <Box
              sx={{
                position: "fixed",
                bottom: 60,
                width: "100%",
                bgcolor: appColors.cardColor,
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex:3
              }}
            >
              <Button
                onClick={handleClickOpen}
                sx={styles.addBtnAddress}
                startIcon={<AddCircleIcon />}
                size="small"
                variant="contained"
              >
                Add New Address
              </Button>
            </Box>
          ) : (
            ""
          )}
          {!userAddressList.addresses.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography sx={{ color: appColors.darkGray }}>
                Please Add Address for Booking Process.!
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Box>

        {/* Address Delete COnfirmation */}
        <Dialog
          open={openDia}
          onClose={handleCloseDia}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: "20px", // Set border radius for curved effect
            },
          }}
        >
          <DialogTitle
            sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
            id="responsive-dialog-title"
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "25px" },
                fontWeight: "500",
                color: appColors.textColorNew,
              }}
            >
              {"Are you Sure?"}
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ bgcolor: appColors.secondaryCard }}>
            <DialogContentText sx={{ color: appColors.darkGray }}>
              Do you really want to delete this Address? This cannot be
              restored.
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              bgcolor: appColors.secondaryCard,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              sx={{
                bgcolor: appColors.textColorNew,
              }}
              autoFocus
              variant="contained"
              size="small"
              onClick={handleDeleteAddress}
            >
              Yes,proceed
            </Button>
            <Button
              sx={{
                width: "20%",
                backgroundColor: appColors.textColorNew,
              }}
              size="small"
              onClick={handleCloseDia}
              variant="contained"
              autoFocus
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      <GoogleMapsDialog
        open={addAdressDialog}
        setOpen={setAddAddressDialog}
        setOpenAddress={setOpenAddAddress}
        editAddressData={address}
        addressType={addressType}
      />
    </>
  );
}

export default ProfileAddressBook;
