/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../../../Assets/AppTheme/appColors";

export const styles = {
  virtualAccountPaper: {
    borderRadius: "25px",
    zIndex:5
  },
  mainBox: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "10px",
    alignItems: "center",
    padding:"5%",

  },
  heading: {
    color: appColors.Textgray,
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "10px",
    "&:hover": {
      color: "red",
    },
  },
  answer: {
    color: appColors.darkBlue,
    fontSize: "12px",
    fontWeight: "bold",
    marginLeft: "10px",
    "&:hover": {
      color: "green",
    },
  },
  noteBox: {
    display: "flex",
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "5px",
  },
  noteHeading: {
    color: appColors.darkGray,
    fontSize: "14px",
    fontWeight: "bold",
    marginLeft: "10px",
  },
  noteAnswer: {
    color: appColors.darkBlue,
    fontSize: "14px",
    fontWeight: "bold",
    marginTop: "5px",
    marginLeft: "20px",
    "&:hover": {
      color: "brown",
    },
  },
  chipCard: { display: "flex", flexDirection: "row", alignItems: "flex-end", justifyContent: "space-between" },
  chipCard1: { display: "flex", flexDirection: {sm:"row",xs:"column"}, alignItems: "flex-start", justifyContent: "space-between" },

};
