/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Button Field (Buuton Field)
 * @date 026/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import FilterButton from "../Buttons/FilterButton";
import { useSelector } from "react-redux";
export default function NavigationButtons({
  pageNumber,
  setPageNumber,
  setfilterDialog,
}) {
  const orderDetails = useSelector((state) => state.ordersDetails);
  //function for handling order back page number
  const handelPageNumberBack = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return (
    <AppBar
      sx={{
        backgroundColor: appColors.homePageCard,
        marginTop: "3.6rem",
        paddingTop: ".5rem",
        boxShadow: "none",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginLeft: { sm: "12rem", xs: 0 },
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: ".3rem",
        }}
      >
        <Button
          disabled={pageNumber > 1 ? false : true}
          variant="standard"
          size="small"
          onClick={handelPageNumberBack}
          sx={{
            textTransform: "none",
            color: appColors.darkGray,
            fontWeight: "600",
          }}
        >
          {<NavigateBeforeIcon color="primary" />} Back
        </Button>
        <Typography
          variant="button"
          display="block"
          gutterBottom
          sx={{
            marginLeft: "10px",
            marginRight: "10px",
            backgroundColor: appColors.textColorNew,
            color: appColors.white,
            padding: "3px",
            width: "30px",
            height: "30px",
            borderRadius: "15px",
            textAlign: "center",
          }}
        >
          {pageNumber}
        </Typography>
        <Button
          disabled={orderDetails.pageStatus}
          variant="standard"
          size="small"
          onClick={() => setPageNumber(pageNumber + 1)}
          sx={{
            textTransform: "none",
            color: appColors.darkGray,
            fontWeight: "600",
          }}
        >
          Next {<NavigateNextIcon color="primary" />}
        </Button>
        <FilterButton setfilterDialog={setfilterDialog} />
      </Box>
    </AppBar>
  );
}
