// useScreenSize.js
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 */
import { useMediaQuery } from '@material-ui/core';

const useScreenSize = () => {
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return isSmallScreen;
};

export default useScreenSize;
