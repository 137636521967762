/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { Card, Box, Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import appColors from "../../../Assets/AppTheme/appColors";
import danger from "../../../Assets/newMobileIcon/Danger.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ReOrderDetailsAction } from "../../../Redux/actions/skuOrderPlacingAction";
import onGoingIcon from "../../../Assets/ordersPage/ongoing.svg";
import appTheme from "../../../Assets/AppTheme/appTheme";
export default function RepeatOrderCard({
  data,
  handelDetailClick,
  setIsOrderPaymentPending,

}) {
  const history = useHistory();
  const dispatch = useDispatch();
  const paymentPendingOrder = useSelector((state) => state.recentActivities);
  //repeatOrder
  const bookNowState = useSelector((state) => state.bookNow);
  const [paymentBlockFlag, setPaymentBlockFlag] = React.useState(false);
  //function to handel repeat order
  const handelRepeatOrder = (orderId) => {
    if (!paymentBlockFlag) {
      const reqObj = {
        order_id: orderId,
      };
      dispatch(ReOrderDetailsAction(reqObj));
    } else if (paymentBlockFlag) {
      setIsOrderPaymentPending(true);
    }
  };

  //Useeffect for payment pending order
  React.useEffect(() => {
    if (paymentPendingOrder?.allDetails?.payment_pending?.length !== 0) {
      let isPendingOrder =
        paymentPendingOrder?.allDetails?.payment_pending?.some(
          (item, idx) => item.payment_type !== 300
        );
      // console.log(isPendingOrder);
      setPaymentBlockFlag(isPendingOrder);
    } else if (paymentPendingOrder?.allDetails?.payment_pending?.length === 0) {
      setPaymentBlockFlag(false);
    }
  }, [paymentPendingOrder?.allDetails]);
  return (
    <Box
      sx={{
        margin: "0 .7rem .5rem .7rem",
        height: "5.8rem",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        padding: ".5rem",
        boxShadow: 2,
        borderRadius: "10px",
        bgcolor: appColors.cardColor,
      }}
      key={data?.order_id}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: ".9rem",
              color: appColors.headerTextColor,
              fontWeight: 600,
              textAlign: "center",
            }}
          >
            Would you like to repeat your previous order?
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "row",
              justifyContent: "center",
            }}
          >
            <img
              src={onGoingIcon}
              style={{
                marginRight: ".5rem",
                marginLeft: "1rem",
                marginBottom: ".5rem",
                width: "2rem",
                backgroundColor: appColors.white,
                borderRadius: "5rem",
                boxShadow: 1,
              }}
            />
          </Box>
        </Box>
        {/* <Box sx={{ height: ".4rem" }}></Box> */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "100%",
            marginTop: ".2rem",
          }}
        >
          <Button
            variant="contained"
            size="small"
            onClick={() => handelDetailClick(data?.order_id)}
            sx={{
              fontSize: appTheme.fontSizeSmall,
              bgcolor: appColors.textColorNew,
              fontWeight: 500,
            }}
          >
            {window.innerWidth > 350 ? "View Order" : "View"}
          </Button>
          <Button
            variant="contained"
            onClick={() => handelRepeatOrder(data?.order_id)}
            size="small"
            sx={{
              fontSize: appTheme.fontSizeSmall,
              bgcolor: appColors.textColorNew,
              fontWeight: 500,
            }}
          >
            Repeat Order
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
