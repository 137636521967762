/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios";
import actionType from "./actionTypes";
import * as AppMetaData from "../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import { GetUserStocksAction } from "./getDepositHistoryAction";
import { GetWalletDetails } from "./paymentMethodAction";
import CustomConsole from "../../coustomConsole";
export const GetDepositDetailsAction = (req) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/getDepositDetails`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_DEPOSIT_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};

//function for refund depoait action
export const DepositRefundDetailsAction = (reqObj) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(`PM/refund/deposit`, reqObj);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.DEPOSIT_REFUND_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
        if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          dispatch(GetDepositDetailsAction());
          dispatch(GetUserStocksAction());
          dispatch(GetWalletDetails());
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "success",
          });
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//function for refund depoait action
export const ExtraDepositAction = (reqObj) => {
  let api = useAxios();
  CustomConsole(reqObj);
  return async (dispatch) => {
    try {
      dispatch({ type: actionType.FETCH_EXTRA_DEPOSIT_DATA_START });
      let response = await api.post(`OM/products/deposit/order/create`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.EXTRA_DEPOSIT_ACTION,
          payload: { data: response.data, statusFlag: true },
        });
        if (response.data.error === true) {
          toast.dark(response.data.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        } else {
          dispatch(GetDepositDetailsAction());
        }
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.EXTRA_DEPOSIT_ACTION,
        payload: { data: error.response.data, statusFlag: true },
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//funcion for clearing deposit data
export const ClearDepositDetailAction = (data) => ({
  type: actionType.CLEAR_DEPOSIT_DETAILS_ACTION,
});
