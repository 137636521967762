/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import { GiMaterialsScience } from "react-icons/gi";
import * as Material from "@mui/material";
import { fontFamily } from "@mui/system";
import appColors from "../../Assets/AppTheme/appColors";

export const IntervalTimerFunctional = () => {
  const [time, setTime] = React.useState(0);

  //useEffect for running timer
  React.useEffect(() => {
    const timerId = setInterval(() => {
      setTime((t) => t + 1);
    }, 1000);
    return () => clearInterval(timerId);
  }, []);

  return <Timer time={time} />;
};

export const Timer = ({ time }) => {
  const RESET_INTERVAL_S = 720; // 300s = 5m * 60s/m

  const formatTime = (time) =>
    `${String(Math.floor(time / 60)).padStart(2, "0")}`;
  const formatMin = (time) => `${String(time % 60).padStart(2, "0")}`;
  const timeRemain = RESET_INTERVAL_S - (time % RESET_INTERVAL_S);

  return (
    <>
      <Material.Box
        variant="overline"
        display="block"
        gutterBottom
        sx={{
          // marginTop: "10px",
          display: "flex",
          fontSize: "18px",
          color: "Black",
          fontFamily: "Anton",
          gap: "1rem",
        }}
      >
        <Material.Box
          sx={{
            padding: "1rem",
            borderRadius: ".3rem",
            border: "2px solid #707070",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: appColors.textColorNew,
          }}
        >
          <Material.Typography sx={{ fontWeight: "bold", color: "#707070" }}>
            Minute
          </Material.Typography>
          {formatTime(timeRemain)}
        </Material.Box>
        <Material.Box
          sx={{
            padding: "1rem",
            borderRadius: ".3rem",
            border: "2px solid #707070",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            color: appColors.textColorNew,
          }}
        >
          <Material.Typography sx={{ fontWeight: "bold", color: "#707070" }}>
            Second
          </Material.Typography>
          {formatMin(timeRemain)}
        </Material.Box>
      </Material.Box>
    </>
  );
};
