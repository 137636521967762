/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";

export const GetBankDetailsIfsc = (ifsc) => {
  let api = useAxios();

  return async (dispatch) => {
    try {
      let response = await api.post(
        `/PM/ifsc/verify/${ifsc}`
      );
     
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BANK_DETAILS_IFSC,
          payload: response.data,
        });
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

