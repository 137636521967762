/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description NewTranscationcard.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Card from "@mui/material/Card";
import { useDispatch, useSelector } from "react-redux";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import moment from "moment";
import { Typography, Box, Snackbar, Link } from "@mui/material";
import { Alert } from "@mui/material";
import Rupee from "../../Assets/AppIcons/Rupee.svg";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { styles } from "./transactionsStyle";
import CompletedIcon from "../../Assets/ordersPage/Completed - Selected Menu.svg";
import appColors from "../../Assets/AppTheme/appColors";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";

const tankerType = {
  WATER: 1,
  SEWAGE: 2,
  PACKAGED_DRINKING_WATER: 3,
};
export default function NewWalletTransactionCard(props) {
  const transactionDetails = useSelector((state) => state.transactionDetails);

  // console.log("Transactions: " + JSON.stringify(props));
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const copyReferalCode = (code) => {
    navigator.clipboard.writeText(code);
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 1000);
  };

  const endDateConverter = (endDate) => {
    let momentEndDate = moment
      .utc(endDate, "YYYY-MM-DDTHH:mm:ssZ")
      .tz("Asia/Kolkata")
      .format("DD MMM YYYY");
    return momentEndDate;
  };
  //function to handel button click
  const handleButtonClick = () => {
    window.fcWidget.open();
  };
  return transactionDetails.walletTransactions.length >= 1 ? (
    <>
      {transactionDetails.walletTransactions.map((data, key) => (
        <Box
          sx={{
            display: "flex",
            marginBottom: "0 2rem 2rem 2rem",
            flexDirection: "row",
            justifyContent: "space-around",
            borderRadius: ".5rem",
          }}
          key={key}
        >
          <Card
            sx={{
              width: 300,
              borderRadius: ".4rem",
              boxShadow: 5,
              backgroundColor: appColors.cardColor,
            }}
          >
            <Box
              sx={[
                styles.transcationCardHeaderWallet,
                {
                  backgroundColor:
                    data.payment_status === "SUCCESS"
                      ? appColors.Sucsess
                      : appColors.Failed,
                },
              ]}
            >
              <Typography
                sx={{
                  color: "black",
                  fontSize: ".8rem",
                  fontWeight: "bold",
                }}
              >
                <span></span>
                {data.payment_status === "SUCCESS"
                  ? "SUCCESSFUL"
                  : data.payment_status}
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  zIndex: 5,
                  fontSize: ".9rem",
                  fontWeight: "bold",
                }}
              >
                {" "}
                {endDateConverter(data.transaction_ts)}
              </Typography>
              <img
                src={Rupee}
                style={{
                  position: "absolute",
                  height: "100%",
                  objectFit: "cover",
                  zIndex: 1,
                }}
              />
            </Box>
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ".5rem",
                }}
              >
                <Box>
                  <Typography
                    sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                  >
                    <span
                      style={{
                        color: appColors.textColorNew,
                        fontSize: ".8rem",
                        fontWeight: "bold",
                      }}
                    >
                      Ref #:
                    </span>
                    {data.transaction_id}
                    <span>
                      <ContentCopyIcon
                        sx={{
                          color: "#49B1EC",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          copyReferalCode(data.transaction_id);
                        }}
                      />
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      color: appColors.textColorNew,
                      fontSize: ".8rem",
                      fontWeight: "bold",
                    }}
                  >
                    Order Id
                  </Typography>
                  <Typography
                    sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                  >
                    {data.order_id !== "" ? data.order_id : "-"}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: appColors.textColorNew,
                      fontSize: ".8rem",
                      fontWeight: "bold",
                    }}
                  >
                    Total Amount
                  </Typography>
                  <Typography
                    sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                  >
                    {" "}
                    ₹ {data.amount}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginBottom: ".5rem",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {data.payment_method === "" ? (
                    <>
                      <Typography
                        sx={{
                          color: appColors.textColorNew,
                          fontSize: ".8rem",
                          fontWeight: "bold",
                        }}
                      >
                        Payment Type
                      </Typography>
                      <Typography
                        sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                      >
                        {data.payment_type}
                      </Typography>
                    </>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          color: appColors.textColorNew,
                          fontSize: ".8rem",
                          fontWeight: "bold",
                        }}
                      >
                        Payment Method
                      </Typography>
                      <Typography
                        sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                      >
                        {data.payment_method}
                      </Typography>
                    </>
                  )}
                </Box>

                <Box>
                  <Typography
                    sx={{
                      color: appColors.textColorNew,
                      fontSize: ".8rem",
                      fontWeight: "bold",
                      marginRight: "2.5rem",
                    }}
                  >
                    Status
                  </Typography>
                  <Typography
                    sx={{ color: appColors.Textgray, fontSize: ".8rem" }}
                  >
                    {data.payment_status}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: ".5rem",
                  justifyContent: "center",
                }}
              >
                <PrimaryButton
                  handleButtonClick={handleButtonClick}
                  loading={false}
                  btnName="Contact Support"
                />
              </Box>
            </CardContent>
            <Snackbar
              open={openSnackBar}
              autoHideDuration={2000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
                zIndex: 1,
              }}
            >
              <Alert severity="info" sx={{ width: "100%" }}>
                Copied to the clipboard
              </Alert>
            </Snackbar>
          </Card>
        </Box>
      ))}
    </>
  ) : (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "60vh",
      }}
    >
      {" "}
      <img
        alt=""
        src={process.env.PUBLIC_URL + "/bwLogo2.png"}
        style={{ objectFit: "contain", height: "50px" }}
      />
      <Typography sx={{ color: appColors.darkGray }}>
        {" "}
        No Transaction Histroy Found!
      </Typography>
    </Box>
  );
}
