/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { injectStyle } from "react-toastify/dist/inject-style";
import { ToastContainer } from "react-toastify";
// import Authenticate from "./Pages/Authentication/Authenticate";
import Dashboard from "./Pages/Common/Dashboard";
import ProtectedRoute from "./Pages/ProtectedRoute";
import Authentication from "./Pages/Authentication/Authentication";
import MobileView from "./Pages/MobileView/MainLandingPage";
import ScreenScroll from "./ScreenScroll";
import appColors from "./Assets/AppTheme/appColors";
if (typeof window !== "undefined") {
  injectStyle();
}
class App extends React.Component {
  render() {
    // Routing to navigate to proper screen/page
    // window.onunload(() => {
    //   console.log("reloaded");
    // });
    return (
      <div style={{ background: appColors.backGround, minHeight: "100vh" }}>
        <BrowserRouter>
          <ScreenScroll />
          <Switch>
            <Route path="/authenticate">
              {/* <Authenticate /> */}
              <Authentication />
            </Route>
            <ProtectedRoute path="/dashboard">
              <Dashboard />
            </ProtectedRoute>
            <ProtectedRoute path="/mobile">
              <MobileView />
            </ProtectedRoute>
            <Route exact path="/">
              <Redirect exact from="/" to="dashboard" />
            </Route>
            <Route path="*">
              <Redirect from="/" to="dashboard" />
            </Route>
          </Switch>
          {/* commented for screen roting issue */}
        </BrowserRouter>
        <ToastContainer />
      </div>
    );
  }
}

export default App;
