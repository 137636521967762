/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionType from "./../actions/actionTypes";

const initialOrdersState = {
  error: true,
  message: "",
  invoice_error: true,
  invoice_message: "",
  pending_orders: [],
  deliveries: [],
  orders: [],
  orderDetails: {},
  deliveryDetails: {},
  invoiceDetails: {},
  detailsFlag: false,
  pageStatus: true, //True-Page End, False-Page not ended
  subscription_orders: {},
  slotsList: [],
  singleOrder: {},
  cancelOrderError: true,
  loading: false,
};

const ordersReducer = (state = initialOrdersState, action) => {
  // console.log("-------ordersReducer-------");
  // console.log(action);
  // console.log(action.payload);
  switch (action.type) {
    case actionType.GET_SUBSCRIPTION_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        subscription_orders: action.payload.data,
      };
    case actionType.GET_PDW_PAYMENT_PENDING:
    case actionType.GET_WST_PAYMENT_PENDING:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        pending_orders: action.payload.pending_orders,
        pageStatus: action.payload.pending_orders.length < 1 ? true : false,
      };
    case actionType.GET_WST_ONGOING_TRIPS:
    case actionType.GET_PDW_ONGOING_TRIPS:
    case actionType.GET_WST_COMPLETED_TRIPS:
    case actionType.GET_PDW_COMPLETED_TRIPS:
      return {
        ...state,
        error: action.payload.error,
        deliveries: action.payload.deliveries,
        pageStatus: action.payload.deliveries.length < 1 ? true : false,
      };
    case actionType.GET_WST_ALL_ORDERS:
    case actionType.GET_PDW_ALL_ORDERS:
      return {
        // ...initialOrdersState,
        ...state,
        pageStatus:
          action.payload.data === undefined || action.payload.data.length < 10
            ? true
            : false,
        error: action.payload.error,
        orders: action.payload.data,
        loading: false,
      };
    case actionType.FETCH_ORDER_DATA_START:
      return {
        ...state,
        loading: true,
      };
    case actionType.ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        orderDetails: action.payload.data,
        detailsFlag: action.payload.statusFlag,
      };
    case actionType.DELIVERY_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        deliveryDetails: action.payload.data,
        detailsFlag: action.payload.statusFlag,
      };
    case actionType.SIGN_OUT:
      return {
        ...initialOrdersState,
      };
    case actionType.INVOICE_DETAILS:
      return {
        ...state,
        invoice_error: action.payload.error,
        invoice_message: action.payload.msg,
        invoice_details: action.payload.data,
      };
    case actionType.CANCEL_ORDER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        cancelOrderError: action.payload.error,
      };
    case actionType.DOWNLOAD_INVOICE:
      // console.log(`action.payload ${JSON.stringify(action.payload)}`);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        invoiceData: action.payload,
      };
    case actionType.REQUEST_REPLACEMENT_CAN:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_DELIVERY_SLOTS_LIST:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        slotsList: action.payload.data,
      };
    case actionType.REQ_DELIVERY_TIME:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.SINGLE_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        singleOrder: action.payload.data[0],
      };
    case actionType.RESET_ORDER_DATA:
      return {
        ...state,
        singleOrder: {},
      };
    case actionType.SET_CANCEL_ORDER_ERROR:
      return {
        ...state,
        cancelOrderError: true,
      };
    default:
      return state;
  }
};

export default ordersReducer;
