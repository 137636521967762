/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

export const isLoadingData = (loadingFlag) => {
  return (dispatch) => {
    dispatch({
      type: actionType.LOADING_DATA,
      payload: false,
    });
  };
};

export const renderData = (renderFlag) => {
  return (dispatch) => {
    dispatch({
      type: actionType.RENDER_DASHBOARD,
      payload: renderFlag,
    });
  };
};
