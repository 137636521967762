/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description Custom Text Field (Password Field)
 * @date 02/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing Libraries and Other Components
import { Box, IconButton, InputAdornment, TextField } from "@mui/material";
import React from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import appMessages from "../../Assets/AppMeta/appMessages";

/**
 * @author Tahir Shaik
 * @description Custom Password Text Field (Inputs- password)
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param { lable, type, setValue, value,isValid, setIsValid, isRequired, isHintRequired, isStrongPasswordRequired}
 * @returns {UI or Authetication Page with LogIn & SignUp Components}
 */

function AuthPasswordTextField(props) {
  const [hint, setHint] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  /**
   * @author Tahir Shaik
   * @date 02/06/23
   * @description Set the State of the Required Variables based on the Conditions(If required)
   * @param {state: boolean}
   */
  const setDataForVariables = (state) => {
    props.setIsValid(state);
    if (props.isHintRequired) setHint(!state);
  };
  /**
   * @author Tahir Shaik
   * @date 02/06/23
   * @description Function To Validate the Input Text Fields
   * @param {data: password} event
   */
  const handleChange = (event) => {
    const data = event.target.value.trim() + "";
    // Password Regular Expression
    // const pswdRegEx = true;

    data.length > 6
      ? setDataForVariables(true)
      : !props.isStrongPasswordRequired && data.length < 6
      ? setDataForVariables(false)
      : setDataForVariables(true);
    props.setValue(data);
  };

  return (
    <TextField
      size="small"
      type={showPassword ? "text" : "password"}
      onChange={handleChange}
      label={props.lable}
      variant="filled"
      color="success"
      error={
        props.isRememberMe && props.value.trim() != "" ? false : !props.isValid
      }
      value={props.value}
      focused
      helperText={hint ? appMessages.STRONG_PASSWORD_HINT : ""}
      required={props.isRequired}
      inputProps={{
        maxLength: 25,
      }}
      sx={{
        backgroundColor: "white",
        fontSize: "20px",
        borderRadius: "5px",
        width: { sm: "70%", xs: "90%" },
        marginTop: "2%",
      }}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}

export default AuthPasswordTextField;
