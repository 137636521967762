/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CrateSelectionCard from "../../Cards/SkuCards/CrateSelectionCard";
import { Typography } from "@mui/material";
// import RequiredButton from "../../SkuButtons/SkuRequiredButton";
// import ReturnButton from "../../SkuButtons/SKUReturnButton";
import DownIcon from "../../../Assets/newMobileIcon/Booking/DownIcon.svg";
import appColors from "../../../Assets/AppTheme/appColors";
import "./dialogStyles.css";
import { useSelector } from "react-redux";
export default function PriceBreakUpCard({
  toggleDrawer,
  open,
  setOpen,
  deliveryCharges,
}) {
  const [productId, setProductId] = React.useState(0);
  const priceBreakUp = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  //function for closing dialog
  const handelClose = (event) => {
    event.preventDefault();
    toggleDrawer(false);
  };
  //function for handling selected product info
  const handelSelectedProduct = (data) => {
    if (data === productId) {
      setProductId(0);
    } else {
      setProductId(data);
    }
  };

  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={(e) => handelClose(e)}
          className="drawerWithRadius"
        >
          <Box
            sx={{
              padding: "1rem",
              height: "100%",
              backgroundColor: appColors.homePageCard,
            }}
          >
            <Typography
              sx={{
                marginBottom: ".5rem",
                marginTop: "1rem",
                fontWeight: "bold",
                color: appColors.darkGray,
              }}
            >
              Pice Break Up
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              {priceBreakUp?.valid_products?.map((data) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    marginBottom: ".5rem",
                    borderBottom: "2px solid gray",
                  }}
                  key={data?.product_id}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.headerTextColor,
                        fontWeight: "bold",
                        fontSize: ".8rem",
                      }}
                    >
                      {data?.quantity?.selected} x {data?.capacity}
                    </Typography>
                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                      <Typography
                        sx={{
                          color: appColors.darkGray,
                          fontWeight: "600",
                          fontSize: ".8rem",
                        }}
                      >
                        ₹{parseFloat(data?.product_price).toFixed(2)}
                      </Typography>
                      <span
                        onClick={() => handelSelectedProduct(data?.product_id)}
                        style={{
                          color: "#58595b",
                          fontSize: ".8rem",
                        }}
                      >
                        {productId === data?.product_id ? (
                          <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                        ) : (
                          <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                        )}
                      </span>
                    </Box>
                  </Box>
                  {data?.product_id === productId && (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          fontSize: ".8rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "bold",
                          }}
                        >
                          {data?.quantity?.selected} x {data?.capacity} Water
                          Price
                        </Typography>
                        <Typography
                          sx={{ color: appColors.darkGray, fontWeight: "600" }}
                        >
                          {parseFloat(data?.product_price).toFixed(2)}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "600",
                            fontSize: ".8rem",
                          }}
                        >
                          {data?.quantity?.selected * data?.sku_qty} x{" "}
                          {data?.capacity} Security Deposit
                        </Typography>
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "600",
                            fontSize: ".8rem",
                          }}
                        >
                          {data?.deposit?.price}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "600",
                            fontSize: ".8rem",
                          }}
                        >
                          Delivery Charge
                        </Typography>
                        <Typography
                          sx={{ color: appColors.darkGray, fontWeight: "600" }}
                        >
                          {data?.extra_charges?.price}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "600",
                            fontSize: ".8rem",
                          }}
                        >
                          Taxes
                        </Typography>
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "600",
                            fontSize: ".8rem",
                          }}
                        >
                          {data?.total_gst?.charges}
                        </Typography>
                      </Box>
                    </>
                  )}
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: appColors.textColorNew,
                    fontWeight: "bold",
                    fontSize: ".8rem",
                  }}
                >
                  Water Drops Applied
                </Typography>
                <Typography
                  sx={{
                    color: appColors.darkGray,
                    fontWeight: "600",
                    fontSize: ".8rem",
                  }}
                >
                  - ₹
                  {priceBreakUp?.water_drop_details?.discount_price ===
                    undefined ||
                  priceBreakUp?.water_drop_details?.is_valid === false
                    ? 0
                    : priceBreakUp?.water_drop_details?.discount_price}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: appColors.textColorNew,
                    fontWeight: "bold",
                    fontSize: ".8rem",
                  }}
                >
                  Coupon Applied
                </Typography>
                <Typography
                  sx={{
                    color: appColors.darkGray,
                    fontWeight: "600",
                    fontSize: ".8rem",
                  }}
                >
                  - ₹
                  {priceBreakUp?.coupon_code_details?.discount_price ===
                  undefined
                    ? 0
                    : priceBreakUp?.coupon_code_details?.discount_price}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: appColors.textColorNew,
                    fontWeight: "bold",
                    fontSize: ".8rem",
                  }}
                >
                  Delivery Charge
                </Typography>
                <Typography
                  sx={{
                    color: appColors.darkGray,
                    fontWeight: "600",
                    fontSize: ".8rem",
                  }}
                >
                  {deliveryCharges}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  sx={{
                    color: appColors.textColorNew,
                    fontWeight: "bold",
                    fontSize: ".8rem",
                  }}
                >
                  Grand Total
                </Typography>
                <Typography
                  sx={{
                    color: appColors.darkGray,
                    fontWeight: "600",
                    fontSize: ".8rem",
                  }}
                >
                  ₹
                  {priceBreakUp?.final_price === undefined
                    ? 0
                    : priceBreakUp?.final_price}
                </Typography>
              </Box>
            </Box>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor: appColors.textColorNew,
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "1rem",
              }}
              onClick={handelClose}
            >
              Done
            </Button>
          </Box>
          <img
            src={DownIcon}
            onClick={(e) => handelClose(e)}
            style={{
              position: "absolute",
              top: "-.2rem",
              right: "45%",
              height: "2.3rem",
              width: "3rem",
              color: appColors.darkGray,
            }}
            alt=""
          />
        </Drawer>
      </React.Fragment>
    </div>
  );
}
