/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  msg: "",
  referId: "",
  creditData: {},
  transactionDetails: [],
  bk_details: {},
  influencerAutopay: {},
  waterDropData: {
    auto_pay: true,
    redeemed_amount: "0.00",
    water_drops: 0,
  },
  pageStatus: false, //True-Page End, False-Page not ended
  waterDropCount: "",
  redeemed_amount: "",
  allData: {},
};

const referAndPromoReducer = (state = initialProfileState, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case actionType.GET_REFERRAL_CODES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        referId: action.payload.ref_id,
      };
    case actionType.GET_WATER_DROPS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        creditData: action.payload.data,
        waterDropCount: action.payload.data.water_drops,
        redeemed_amount: action.payload.data.redeemed_amount,
        allData: action.payload.data,
      };
    case actionType.REQUEST_INFLUENCER:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.WATER_DROP_TRANSACTION:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        transactionDetails: action.payload.data,
        pageStatus: action.payload.data.length < 10 ? true : false,
      };
    case actionType.CONSUMER_BK_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        bk_details: action.payload.data,
      };
    case actionType.INFLUENCER_AUTO_PAY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        influencerAutopay: action.payload.data,
      };
    case actionType.WATER_DROP_SETTLEMENT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.SIGN_OUT:
      return {
        error: true,
        msg: "",
        referId: "",
        creditData: {},
        transactionDetails: [],
        bk_details: {},
        influencerAutopay: {},
        waterDropData: {
          auto_pay: true,
          redeemed_amount: "0.00",
          water_drops: 0,
        },
        pageStatus: false, //True-Page End, False-Page not ended
        waterDropCount: "",
        redeemed_amount: "",
        allData: {},
      };
    default:
      return state;
  }
};

export default referAndPromoReducer;
