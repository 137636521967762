/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { ReturnCansAction } from "../../Redux/actions/returnCansAction";
import { toast } from "react-toastify";
import Checkbox from "@mui/material/Checkbox";
import appColors from "../../Assets/AppTheme/appColors";
import SelectAddressDetails from "./selectAddressDialog";
import CustomConsole from "../../coustomConsole";
import FormHelperText from "@mui/material/FormHelperText";
export default function ReturnCanReqDialog(props) {
  const dispatch = useDispatch();
  const returnCansDetails = useSelector((state) => state.returnCansDetails);
  const [material, setMaterial] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [Addressid, setAddressId] = React.useState("");
  const [reqReturnFlag, setReqReturnFlag] = React.useState(false);
  const [checkFlag, setCheckFlag] = React.useState(false);
  const [filledContainer, setFilledContainer] = React.useState("");

  React.useEffect(() => {
    CustomConsole(returnCansDetails);
    if (returnCansDetails.error === false && reqReturnFlag === true) {
      CustomConsole("return success");
      toast.dark(returnCansDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "success",
      });
      setReqReturnFlag(false);
      props.setIsReqReturnDialogOpen(false);
    } else if (returnCansDetails.error === true && reqReturnFlag === true) {
      CustomConsole("return failure");
      toast.dark(returnCansDetails.msg, {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
      setReqReturnFlag(false);
      props.setIsReqReturnDialogOpen(false);
    }
  }, [returnCansDetails]);

  const handleReturnCans = () => {
    if (!checkFlag) {
      const reqObj = {
        container_qty: quantity,
        container_material: material,
        container_capacity: capacity,
        address_id: Addressid,
      };
      if (
        reqObj.container_qty !== "0" &&
        reqObj.container_qty !== "" &&
        reqObj.container_capacity !== "" &&
        reqObj.container_material !== "" &&
        Addressid !== ""
      ) {
        CustomConsole(reqObj);
        dispatch(ReturnCansAction(reqObj));
        setReqReturnFlag(true);
      } else {
        toast.dark("Please fill all fields", {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
      }
    }
    if (checkFlag) {
      const reqObj = {
        container_qty: quantity,
        container_material: material,
        container_capacity: capacity,
        filled_cans_qty: filledContainer,
        address_id: Addressid,
      };
      if (
        reqObj.container_qty !== "" &&
        reqObj.container_capacity !== "" &&
        reqObj.container_material !== "" &&
        reqObj.filled_cans_qty !== ""
      ) {
        CustomConsole(reqObj);
        dispatch(ReturnCansAction(reqObj));
        setReqReturnFlag(true);
      } else {
        toast.dark("Empty fields", {
          position: "bottom-right",
          autoClose: 2000,
          type: "error",
        });
      }
    }
  };

  const materials = [
    {
      value: "PET",
    },
  ];

  const capacityLtrs = [
    {
      value: "20_Liters",
    },
  ];

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };

  const checkBoxChange = (e) => {
    setCheckFlag(e.target.checked);
  };

  const closeDialogue = () => {
    props.setIsReqReturnDialogOpen(false);
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: "13px", // Set the desired label color
    },
  };

  //useEffect for setting capacity and material
  React.useEffect(() => {
    setCapacity(props.userStock?.container_capacity);
    setMaterial(props.userStock?.container_material);
  }, [props.userStock]);
  return (
    <div>
      <Dialog
        open={props.isReqReturnDialogOpen}
        onClose={closeDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "20px",
              gap: "15px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "bold",
                  textAlign: "center",
                  color: appColors.secondaryText,
                }}
              >
                RETURN PRODUCT
              </Typography>
            </Box>
            <TextField
              sx={{ width: "100%", color: appColors.darkGray }}
              id="outlined-basic"
              label="Total Product to be Returned"
              variant="outlined"
              size="small"
              type="number"
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 4);
              }}
              value={quantity}
              onChange={handleQuantityChange}
            />
            {checkFlag ? (
              <TextField
                id="outlined-basic"
                label="Filled Can To be Returned"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                onInput={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 10);
                }}
                variant="outlined"
                type="number"
                value={filledContainer}
                onChange={(e) => {
                  setFilledContainer(e.target.value);
                }}
              />
            ) : null}
            <SelectAddressDetails
              Addressid={Addressid}
              setAddressId={setAddressId}
            />
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{
                  backgroundColor: appColors.Failed,
                  padding: "10px 20px",
                  borderRadius: "0", // Set the border radius to 0 (no rounding)
                  borderTopRightRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  height: "30px",
                  "&: hover": {
                    backgroundColor: appColors.Failed,
                  },
                }}
                onClick={() => props.setIsReqReturnDialogOpen(false)}
              >
                Cancel
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor: appColors.textColorNew,
                  padding: "10px 20px",
                  borderRadius: "0", // Set the border radius to 0 (no rounding)
                  borderTopRightRadius: "15px",
                  borderBottomLeftRadius: "15px",
                  height: "30px",
                  "&: hover": {
                    backgroundColor: appColors.textColorNew,
                  },
                }}
                onClick={handleReturnCans}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
