/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import moment from "moment";
import * as orderActions from "../../../Redux/actions/ordersAction";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import { styles } from "./filtercss";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import "../../../Components/styles.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function WaterDropFilterDialog({
  filterDialogOpen,
  setfilterDialogOpen,
  filterOption,
  setPageNumber,
  setRequestParams,
  requestParams,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [buttonDetails, setButtonDetails] = React.useState(null);
  const today = moment().add(7, "days").format("YYYY-MM-DD");
  //state for the value of dates
  const [fromValue, setFromValue] = React.useState();
  const [toValue, setToValue] = React.useState();
  const [fromDateValue, setFromDateValue] = React.useState(
    moment().subtract(180, "days").format("YYYY-MM-DD")
  );
  const [toDateValue, setToDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [sortValue, setSortValue] = React.useState("DESC");

  //function to set filter from value
  const handleChangeFrom = (e) => {
    setFromDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    console.log(momentDate);
    setFromValue(momentDate);
  };

  //function to close dialog
  const handleClose = () => {
    setfilterDialogOpen(false);
  };

  //function to sort filter option
  const SortFilterButton = (value) => {
    // Create a new array by mapping over the existing sort_by array
    setSortValue(value);
  };

  //function to handel change to
  const handleChangeTo = (e) => {
    setToDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    setToValue(momentDate);
  };

  //function to handel apply filter button
  const handelApplyFilter = () => {
    setPageNumber(1);
    setRequestParams((prev) => ({
      ...prev,
      from: fromValue,
      to: toValue,
      sort: sortValue,
      page_number: 0,
      trans_st: buttonDetails,
    }));
    handleClose();
  };

  //function to handel clear filter option
  const handelApplyClearAllFilter = () => {
    setRequestParams((prev) => ({
      ...prev,
      from: moment().subtract(180, "days").format("YYYY-MM-DD"),
      to: moment().add(7, "days").format("YYYY-MM-DD"),
      sort: "DESC",
      page_number: 0,
      trans_st: null,
    }));
    setButtonDetails("");
    setFromValue(moment().subtract(180, "days").format("YYYY-MM-DD"));
    setSortValue("DESC");
    setToValue(moment().format("YYYY-MM-DD"));
    handleClose();
  };

  //function to handel button click
  const handelButonClick = (data) => {
    setButtonDetails(Object.values(data)[0]);
  };

  //function for rendring option filter button
  const OptionButton = () => {
    return (
      <>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {filterOption.map((item, index) => (
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  buttonDetails !== Object.values(item)[0]
                    ? appColors.textColorNew
                    : appColors.lightGreen,
                "&:hover": {
                  backgroundColor:
                    buttonDetails !== Object.values(item)[0]
                      ? appColors.textColorNew
                      : appColors.lightGreen,
                },
                color: appColors.white,
              }}
              onClick={() => handelButonClick(item)}
              key={Object.keys(item)[0]}
            >
              {Object.keys(item)[0]}
            </Button>
          ))}
        </Box>
      </>
    );
  };
  return (
    <div>
      <Dialog
        open={filterDialogOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        fullScreen={fullScreen}
      >
        <AppBar
          sx={{ position: "relative", backgroundColor: appColors.cardColor }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ color: appColors.secondaryText }}
            >
              <KeyboardBackspaceTwoToneIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: appColors.secondaryText }}
              variant="h6"
              component="div"
            >
              Back
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: appColors.cardColor,
            padding: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: appColors.secondaryText,
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Filters
          </Typography>
          <Typography sx={styles.buttonHeader}>Order Status</Typography>
          <Box sx={styles.ButtonContainer}>
            <OptionButton />
          </Box>
          <Typography sx={styles.buttonHeader}>Sort By Date</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  sortValue !== "DESC"
                    ? appColors.textColorNew
                    : appColors.lightGreen,
                "&:hover": {
                  backgroundColor:
                    sortValue !== "DESC"
                      ? appColors.textColorNew
                      : appColors.lightGreen,
                },
                color: "#fff",
              }}
              onClick={() => SortFilterButton("DESC")}
            >
              <span style={{ fontSize: ".7rem" }}>Recent First</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={{
                backgroundColor:
                  sortValue !== "ASC"
                    ? appColors.textColorNew
                    : appColors.lightGreen,
                "&:hover": {
                  backgroundColor:
                    sortValue !== "ASC"
                      ? appColors.textColorNew
                      : appColors.lightGreen,
                },
                color: "#fff",
              }}
              onClick={() => SortFilterButton("ASC")}
            >
              <span style={{ fontSize: ".7rem" }}>Old First</span>
            </Button>
          </Box>
          <Typography sx={styles.buttonHeader}>Sort by Date Range</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            <Box>
              <label
                htmlFor="fromDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                From:
              </label>
              <input
                type="date"
                id="fromDay"
                name="from"
                value={fromDateValue}
                onChange={handleChangeFrom}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "120px",
                }}
                max={today}
              />
            </Box>
            <Box>
              <label
                htmlFor="toDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                To:&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <input
                type="date"
                id="toDay"
                name="to"
                value={toDateValue}
                onChange={handleChangeTo}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  width: "120px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                }}
              />
            </Box>
          </Box>
          <Box className="box-align" sx={{ marginTop: "2rem", gap: 1 }}>
            <Button sx={styles.applyFilter} onClick={handelApplyClearAllFilter}>
              Clear
            </Button>
            <Button sx={styles.applyFilter} onClick={handelApplyFilter}>
              Apply
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
