/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
 
const initialProfileState = {
  error: true, 
  msg:""
};


const startOrderReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.PUT_START_ORDER:
        // console.log("----action here---")
        // console.log(action)
      return {
        ...state,
        error: action.payload.error, 
        msg:action.payload.msg 
       };
      
    default:
      return state;
  }
};

export default startOrderReducer;
