/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and Other Files
import { Box, CardMedia, Grid, Hidden, Typography } from "@mui/material";
import React from "react";
import BWLogoIcon from "../../Assets/AppIcons/BookWaterLogo.svg";
import NambiBWIcon from "../../Assets/AppIcons/NambiBWIcon.png";
import authStyles from "./authenticationStyles";
import Login from "../../Components/Authentication/Login";
import OutlinedButton from "../../Components/Buttons/OutlinedButton";
import SignUp from "../../Components/Authentication/SignUp";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ForgetPassword from "../../Components/Authentication/ForgetPassword";
import ForgetPasswordStepper from "../../Components/Authentication/ForgetPasswordStepper";
import VerificationWithOTP from "../../Components/Authentication/VerificationWithOTP";
import { Redirect } from "react-router-dom";
import { screenSize } from "../../Assets/Constants/AppMetaData";

/**
 * @author Tahir Shaik
 * @description BookWater App Authentication Page With Login & SignUP Components
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */
function Authentication() {
  // Variable to Switch Between the Login or Signup UI -> Default Login
  // const navigate = useNavigate();
  const authStore = useSelector((state) => state.authReducer);
  // to get the referal code from the queryparam
  const location = useLocation();
  const referalParam = new URLSearchParams(location.search);
  const referalCode = referalParam.get("referrer");
  // To Change the Authentiaction UI & Header Data (Login or SignUp )
  const [authHeader, setAuthHeader] = React.useState(true);
  // To check user has clicked forget password

  // To check weather to open forget password process box or not
  const [forgetPassword, setForgetPassword] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  // To chech after user entering forget password email/phone
  const [submitToVerify, setSubmitToVerify] = React.useState(false);
  // To Check User LoggedIn or Not
  const [isLoginSucces, setIsLogInSucces] = React.useState(false);

  //For forgot password state
  const [emailPhone, setEmailPhone] = React.useState("");

  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  // User Effect to Switch from Login to SignUp -> Get Executed -> On Component Mounting & Change in the Referral Code in URL
  React.useEffect(() => {
    referalCode ? setAuthHeader(false) : setAuthHeader(true);
  }, [referalCode]);

  // UserEffect: Will Get Executed -> On Component Mounting & On Change of the authentication Store
  React.useEffect(() => {
    if (authStore.isLoggedIn) {
      if (localStorage.getItem("token") || sessionStorage.getItem("token")) {
        setIsLogInSucces(true);
      }
    }
  }, [authStore]);

  // Will Get Executed on Change of the isLoggedIn Variable
  React.useEffect(() => {}, [isLoginSucces]);

  const handleClick = () => {
    setAuthHeader(!authHeader);
    setForgetPassword(false);
    setEmailPhone("");
  };
  const handleClick1 = () => {
    setAuthHeader(authHeader);
    setForgetPassword(false);
    setEmailPhone("");
  };
  //Use Effect for Monitering a screen size
  React.useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  //Condition to redirect based on screen size
  if (isLoginSucces) {
    if (windowWidth > screenSize.MOBILE_SCREEN) {
      return <Redirect to="/dashboard/home" />;
    } else {
      return <Redirect to="/mobile" />;
    }
  }
  return (
    <Grid container sx={authStyles.mainContainer}>
      <Hidden smDown xsDown>
        <Grid container spacing={2} sx={authStyles.gridContainer}>
          {/* Left Container ->  nambi Image & Quote */}
          <Grid item xs={7}>
            <Typography
              component="div"
              variant="body1"
              style={{
                position: "relative",
              }}
            >
              {/* Nambi Image with BookWater Can */}
              <CardMedia
                sx={authStyles.nambiImage}
                component="img"
                image={NambiBWIcon}
                alt="nambi narayanan book water"
              />
              {/* Nambi Quote for BookWater */}
              <Box sx={authStyles.nambiTextContainer}>
                <Typography sx={authStyles.nambiTextItem1}>
                  "Getting <br />
                  Safe Water <br />
                  is no more <br />
                  <Typography component={"span"} sx={authStyles.nambiTextItem2}>
                    rocket science!
                  </Typography>
                  "
                  <br />
                  <Typography sx={authStyles.nambiTextItem3}>
                    <Typography
                      component={"span"}
                      sx={authStyles.nambiTextItem4}
                    >
                      Sri S Nambi Narayanan
                    </Typography>
                    <br />
                    SPACE SCIENTIST
                  </Typography>
                </Typography>
              </Box>
            </Typography>
          </Grid>

          {/* Authentication: Login & Signup */}
          <Grid item xs={5} sx={authStyles.authContainer}>
            <Box sx={authStyles.authContainer2}>
              {/* BookWater Logo in Authentication */}
              <Box sx={authStyles.bwLogoContainer}>
                <CardMedia
                  sx={authStyles.BwLogo}
                  component="img"
                  image={BWLogoIcon}
                  alt="book water logo"
                />
              </Box>
              {/* Login & Sign Up Components */}
              {forgetPassword === true && (
                <Box sx={authStyles.logInSignupMain}>
                  <ForgetPasswordStepper emailVerify={submitToVerify} />{" "}
                </Box>
              )}

              <Box sx={authStyles.logInSignupMain}>
                {/* Login & Signup Details*/}

                <Typography sx={authStyles.typeHeader}>
                  {authHeader && forgetPassword === false
                    ? "Login"
                    : forgetPassword === false
                    ? "Create an Account"
                    : forgetPassword === true && "Forgot Password"}
                </Typography>
              </Box>

              {/* Bottom Component to switch between Login & SignUp */}
              {authHeader && forgetPassword === false ? (
                <Login
                  forgetPassword={forgetPassword}
                  setForgetPassword={setForgetPassword}
                  setSubmitToVerify={setSubmitToVerify}
                />
              ) : forgetPassword === false ? (
                <SignUp />
              ) : forgetPassword === true && submitToVerify === false ? (
                <ForgetPassword
                  emailPhone={emailPhone}
                  setPhone={setPhone}
                  setEmail={setEmail}
                  email={email}
                  phone={phone}
                  setEmailPhone={setEmailPhone}
                  setSubmitToVerify={setSubmitToVerify}
                  submitToVerify={submitToVerify}
                />
              ) : (
                forgetPassword === true &&
                submitToVerify === true && (
                  <VerificationWithOTP
                    emailPhone={emailPhone}
                    email={email}
                    phone={phone}
                    setPhone={setPhone}
                    setEmail={setEmail}
                    setEmailPhone={setEmailPhone}
                    setSubmitToVerify={setSubmitToVerify}
                    setForgetPassword={setForgetPassword}
                  />
                )
              )}
              {/* Auth Bottom UI-> Horizontail Line, Text & Switching Button */}
              <Box sx={authStyles.authBottomContainer}>
                {/* Horizontal Line */}
                <hr style={authStyles.hrLine} />
                {/* Header Data based on Switch Status */}
                {forgetPassword !== true && (
                  <Typography sx={authStyles.switchHeaderData}>
                    {authHeader
                      ? "New to BookWater?"
                      : "Already have an Account?"}
                  </Typography>
                )}

                {/* Switching Button from Login <->Signup <-> Forget password */}
                {forgetPassword !== true ? (
                  <OutlinedButton
                    // handleButtonClick={() => setAuthHeader(!authHeader)}
                    handleButtonClick={handleClick}
                    btnName={authHeader ? "Signup" : "Login"}
                  />
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-evenly",
                      gap: 3,
                    }}
                  >
                    <OutlinedButton
                      handleButtonClick={handleClick}
                      btnName="Signup"
                    />
                    <OutlinedButton
                      handleButtonClick={handleClick1}
                      btnName="Login"
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smUp>
        {/* Here Login & SignUp UI should Implement */}
        <Box sx={authStyles.authContainer2}>
          {/* BookWater Logo in Authentication */}
          <Box sx={authStyles.bwLogoContainer}>
            <CardMedia
              sx={authStyles.BwLogo}
              component="img"
              image={BWLogoIcon}
              alt="nambi narayanan book water"
            />
          </Box>
          <Box sx={{ marginLeft: "30px", marginTop: "20px" }}>
            {/* Login & Sign Up Components */}
            {forgetPassword === true && (
              <Box sx={authStyles.logInSignupMain}>
                <ForgetPasswordStepper emailVerify={submitToVerify} />{" "}
              </Box>
            )}

            <Box sx={authStyles.logInSignupMain}>
              {/* Login & Signup Details*/}

              <Typography sx={authStyles.typeHeader}>
                {authHeader && forgetPassword === false
                  ? "Login"
                  : forgetPassword === false
                  ? "Create an Account"
                  : forgetPassword === true && "Forgot Password"}
              </Typography>
            </Box>

            {/* Bottom Component to switch between Login & SignUp */}
            {authHeader === true && forgetPassword === false ? (
              <Login
                forgetPassword={forgetPassword}
                setForgetPassword={setForgetPassword}
                setSubmitToVerify={setSubmitToVerify}
              />
            ) : forgetPassword === false ? (
              <SignUp />
            ) : forgetPassword === true && submitToVerify === false ? (
              <ForgetPassword
                emailPhone={emailPhone}
                setEmailPhone={setEmailPhone}
                email={email}
                phone={phone}
                setSubmitToVerify={setSubmitToVerify}
                submitToVerify={submitToVerify}
                setPhone={setPhone}
                setEmail={setEmail}
              />
            ) : (
              forgetPassword === true &&
              submitToVerify === true && (
                <VerificationWithOTP
                  emailPhone={emailPhone}
                  setPhone={setPhone}
                  email={email}
                  phone={phone}
                  setEmail={setEmail}
                  setEmailPhone={setEmailPhone}
                  setSubmitToVerify={setSubmitToVerify}
                  setForgetPassword={setForgetPassword}
                />
              )
            )}
            {/* Auth Bottom UI-> Horizontail Line, Text & Switching Button */}
            <Box sx={authStyles.authBottomContainer}>
              {/* Horizontal Line */}
              <hr style={authStyles.hrLine} />
              {/* Header Data based on Switch Status */}
              {forgetPassword !== true && (
                <Typography sx={authStyles.switchHeaderData}>
                  {authHeader
                    ? "New to BookWater?"
                    : "Already have an Account?"}
                </Typography>
              )}

              {/* Switching Button from Login <->Signup <-> Forget password */}
              {forgetPassword !== true ? (
                <OutlinedButton
                  // handleButtonClick={() => setAuthHeader(!authHeader)}
                  handleButtonClick={handleClick}
                  btnName={authHeader ? "Signup" : "Login"}
                />
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    gap: 3,
                  }}
                >
                  <OutlinedButton
                    handleButtonClick={() => {
                      setAuthHeader(false);
                      setForgetPassword(false);
                    }}
                    btnName="Signup"
                  />
                  <OutlinedButton
                    handleButtonClick={() => {
                      setAuthHeader(true);
                      setForgetPassword(false);
                    }}
                    btnName="Login"
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Hidden>
    </Grid>
  );
}

export default Authentication;
