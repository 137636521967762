/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description coupon dialog box
 * @date
 * @version
 */

import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import appColors from "../../../../Assets/AppTheme/appColors";
import Lottie from "lottie-react";
import animationData from "../../../../Assets/Animations/doneFile.json";
import { Box } from "@mui/material";
import animate from "lottie-web";
export default function BookingCouponAppliedDialog(props) {
  const { onClose, open, promoCode, checkoutDetails } = props;

  const handleClose = () => {
    onClose(false);
    setTimeout(() => {
      animate.destroy();
    }, 6000);
  };

  // useEffect to close Dialog box
  React.useEffect(() => {
    if (open) {
      setTimeout(() => {
        handleClose();
      }, 10000);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: appColors.lightblue,
        }}
      >
        {checkoutDetails?.checkOutDetails?.discount > 0 ? (
          <Box
            sx={{
              height: "6rem",
              width: "6rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Lottie animationData={animationData} autoplay={true} />
          </Box>
        ) : null}
        <List sx={{ pt: 0 }}>
          <ListItem sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
                color: appColors.grayText,
              }}
            >
              {checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid
                ? `${checkoutDetails?.checkOutDetails?.coupon_code_details?.selected} Coupon Applied `
                : null}
              {checkoutDetails?.checkOutDetails?.water_drop_details
                ?.discount_price > 0 &&
              checkoutDetails?.checkOutDetails?.water_drop_details?.is_valid ===
                true &&
              checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid
                ? " and "
                : ""}
              {checkoutDetails?.checkOutDetails?.water_drop_details
                ?.discount_price > 0 &&
              checkoutDetails?.checkOutDetails?.water_drop_details?.is_valid ===
                true
                ? `  Water drops Applied`
                : ""}
            </Typography>
          </ListItem>
          <ListItem sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
                color: appColors.grayText,
              }}
            >
              <span style={{ color: appColors.grayText }}>
                You Saved ₹{checkoutDetails?.checkOutDetails?.discount}
              </span>{" "}
              for this order
            </Typography>
          </ListItem>

          <ListItem sx={{ justifyContent: "center" }}>
            <Typography
              sx={{
                textAlign: "center",
                fontSize: ".9rem",
                fontWeight: "bold",
                color: appColors.darkGray,
              }}
              onClick={handleClose}
            >
              <span style={{ color: appColors.textColorNew }}>Close</span>{" "}
            </Typography>
          </ListItem>
        </List>
      </Box>
    </Dialog>
  );
}
