/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Button, Stack, } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { skuBookingCardsStyle } from "../Cards/SkuCards/BookingCardsStyle";
import appColors from "../../Assets/AppTheme/appColors";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function WaterDropApplyButton({
  handelAddQuantity,
  handelReduseQuantity,
  quantity,
}) {
  const { pathname, search } = useLocation();
  const [pageName, setPageTitle] = React.useState("");
  let name = "";
  // To Control the Page Heading on Click of Back & froward Button
  React.useLayoutEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    setPageTitle(pageTitle[1]);
  }, [pathname, search]);


  return (
    <>
      <Box sx={{ marginTop: ".5rem" }}>
        <Stack direction="row">
          <Box sx={skuBookingCardsStyle.orderButtonStyle}>
            <Button size="small" onClick={() => handelReduseQuantity()}>
              <RemoveIcon
                sx={{
                  fontSize: ".8rem",
                  fontWeight: "600",
                  color: "#fff",
                }}
              />
            </Button>
          </Box>
          <Box sx={skuBookingCardsStyle.waterdropStyles}>
            {" "}
            <input
              type="text"
              value={quantity}
              style={{
                wordWrap: "normal",
                border: 0,
                fontSize: "1rem",
                height:"1.8rem",
                backgroundColor:"#bfefff",
                minWidth: "100%",
                textAlign: "center",
                color: "#58595b",
                fontWeight: "bold",
                width: "100%",
                outline: "none",
              }}
            />
          </Box>
          <Box sx={skuBookingCardsStyle.orderButtonStylePlus}>
            <Button size="small" onClick={() => handelAddQuantity()}>
              <AddIcon
                sx={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: "#fff",
                }}
              />
            </Button>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
