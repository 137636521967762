import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  bannersData: [],
  bannerImages: [],
  bannerImagesFlag:false
};

const GetBannersReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.GET_BANNERS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        bannersData: action.payload.data,
      };
    case actionType.STORE_BANNER_IMAGES:
      return {
        ...state,
        bannerImages: action.payload.data,
      };
    case actionType.STORE_BANNER_IMAGES_FLAG:
      return {
        ...state,
        bannerImagesFlag: action.payload.data,
      };
    default:
      return state;
  }
};

export default GetBannersReducer;
