/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  message: "",
  isPhoneVerifed: false,
  isEmailVerified: false,
  isProfileUpdated: false,
  isAddressListUpdated: false,
  isAddressDeleted: false,
  isAddressAdded: true,
  addresses: [],
  emailError: true,
  loading: false,
};

const settingsReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.UPDATE_USER_PROFILE:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.msg,
        isProfileUpdated: action.payload.updateFlag,
      };
    case actionType.UPDATE_PASSWORD:
    case actionType.UPDATE_PHONE_NUMBER:
    case actionType.UPDATE_EMAIL:
      return {
        ...state,
        emailError: action.payload.error,
        message: action.payload.msg,
      };
    case actionType.UPDATE_PHONE_NUMBER_VERIFY:
      // console.log("action.payload: " + action.payload);
      return {
        ...state,
        isPhoneVerifed: action.payload,
      };
    case actionType.UPDATE_EMAIL_VERIFY:
      // console.log("action.payload: " + action.payload);
      return {
        ...state,
        isEmailVerified: action.payload,
      };
    case actionType.GET_USER_ADDRESS_LIST:
      return {
        ...state,
        loading: false,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        addresses: action.payload.data.addresses,
        isAddressListUpdated: action.payload.statusFlag,
      };
    case actionType.DELETE_USER_ADDRESS:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isAddressDeleted: action.payload.statusFlag,
      };
    case actionType.ADD_NEW_ADDRESS:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isAddressAdded: action.payload.data.error,
      };
    case actionType.EDIT_EXISTING_ADDRESS:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isAddressAdded: action.payload.data.error,
      };
    case actionType.RESET_ADDRESS_STATE:
      return { ...state, isAddressAdded: true };
    case actionType.FETCH_DATA_START_ADDRESS:
      return { ...state, loading: true };
    case actionType.FETCH_DATA_STOP_ADDRESS:
      return { ...state, loading: false };
    case actionType.RESET_ADDRESS_STATE:
      return { ...state, isAddressAdded: true };
    case actionType.SIGN_OUT:
      return {
        ...initialProfileState,
      };
    default:
      return state;
  }
};

export default settingsReducer;
