/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "../../Assets/Constants/AppMetaData";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";

//Get User Virtual Bank Account Details
export const GetUserVirtualAccount = () => {
  let api = useAxiosWithoutLoading();
  return async (dispatch) => {
    try {
      let response = await api.get(`/PM/payments/accounts/paysharp`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_VIRTUAL_ACCOUNTS_DETAILS,
          // payload: { data: response.data, statusFlag: true },
          payload: response.data,
          status: true,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_VIRTUAL_ACCOUNTS_DETAILS,
        // payload: { data: response.data, statusFlag: true },
        payload: "Payment Failed",
        status: true,
      });
    }
  };
};

//Get User Bank Accounts List
export const GetUserBankAccountList = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/accounts`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "success",
        // });
        // console.log("Response Accounts Actions: " + JSON.stringify(response));
        dispatch({
          type: actionType.GET_USER_ACCOUNTS_LIST,
          payload: { data: response.data, statusFlag: true },
          // payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Add New Bank Account
export const AddNewAccount = (accountDetails) => {
  const accountType =
    accountDetails.accountType === "1"
      ? AppMetaData.accountTypes.SAVINGS
      : AppMetaData.accountTypes.CURRENT;
  const data = {
    account_name: accountDetails.accountName,
    account_number: accountDetails.accountNumber,
    account_type: accountType,
    bank_name: accountDetails.bankName,
    branch_name: accountDetails.branchName,
    email: accountDetails.email,
    id: accountDetails.userId,
    ifsc_code: accountDetails.ifscCode,
    is_primary: accountDetails.isPrimary,
    micr_number: accountDetails.micrNumber,
    mobileNo: accountDetails.phone,
    name: "",
  };
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/PM/accounts`, data);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        toast.dark(response.data.data, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch({
          type: actionType.ADD_NEW_ACCOUNT,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
   
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Edit Existing Address
export const EditAccountDetails = (accountDetails) => {
  const accountType =
    accountDetails.accountType === "1"
      ? AppMetaData.accountTypes.SAVINGS
      : AppMetaData.accountTypes.CURRENT; 
  const data = {
    account_id: accountDetails.accountId,
    account_number: accountDetails.accountNumber,
    ifsc_code: accountDetails.ifscCode,
    bank_name: accountDetails.bankName,
    account_name: accountDetails.accountName,
    branch_name: accountDetails.branchName,
    user_id: accountDetails.userId,
    account_type: accountType,
    phone: accountDetails.phone,
    email: accountDetails.email,
    micr_number: accountDetails.micrNumber,
    is_primary: accountDetails.isPrimary,
    status: true,
    is_bank_account: true,
  };
  // console.log("data........: " + JSON.stringify(data));
  const account_id = accountDetails.accountId;
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/accounts/${account_id}`, data);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // console.log("Response: " + JSON.stringify(response.data));
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch({
          type: actionType.EDIT_EXISTING_ACCOUNT,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
    
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};

//Delete User Bank Account
export const DeleteUserBankAccount = (id) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/SCM/accounts/${id}/remove`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // console.log("response: " + JSON.stringify(response));
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch({
          type: actionType.DELETE_USER_BANK_ACCOUNT,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    }
  };
};
