/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { Box, Button, Dialog, DialogContent } from "@mui/material";
import { OpenCamera } from "../../Pages/ScanQr/OpenCamera";
import appColors from "../../Assets/AppTheme/appColors";

export default function QrScanDialog({
  openScanner,
  setOpenScanner,
  handelScannerResponse,
}) {
  return (
    <>
      <Dialog
        open={openScanner}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: appColors.cardColor }}>
          <Box>
            <OpenCamera
              handleCameraResp={handelScannerResponse}
              openHtmlQr={openScanner}
              setOpenHtmlQr={setOpenScanner}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={() => setOpenScanner(false)}
                size="small"
                variant="contained"
                sx={{ backgroundColor: appColors.textColorNew }}
              >
                Close
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
