/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  isTankersLoaded: false,
  convFeePercent: "",
  gateWayFee: 0,
  pdwList: [],
  is_subscribed: false,
  isActive:true,
};

const pwdListReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PDW_TANKER_LIST:
      // console.log("actionType.PDW_TANKER_LIST");
      // console.log(action.payload);
      return {
        ...state,
        error: action.payload.error,
        convFeePercent: action.payload.data.conv_fee_percent,
        gateWayFee: action.payload.data.gateway_fcharges,
        pdwList: action.payload.data.packagedrinkingwater,
        is_subscribed: action.payload.data.is_order_subscription_exists,
        isTankersLoaded: action.payload.statusFlag,
      };
    default:
      return state;
  }
};

export default pwdListReducer;
