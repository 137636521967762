/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Advertise.jsx for banner related content
 * @date 06/06/23
 * @version 0.0.6 - Initial Release
 */
import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import InfluencerImage from "../../../Assets/AppIcons/Influencer.png";
import appColors from "../../../Assets/AppTheme/appColors";
import { getBannerImg } from "../../../Redux/actions/fileHandlingActions";
import { useDispatch, useSelector } from "react-redux";
import { GetBanners } from "../../../Redux/actions/getBannersAction";
import BanneRDetails from "../../../Components/DialogBoxes/BannerDetails";

function Advertise() {
  const dispatch = useDispatch();
  const bannersDetails = useSelector((state) => state.bannersDetails);
  const fileHandler = useSelector((state) => state.fileHandler);
  const [dataURI, setDataURI] = React.useState([]);
  const [dataURIName, setDataURIName] = React.useState([]);

  React.useEffect(() => {
    if (bannersDetails.bannersData.length === 0) {
      dispatch(GetBanners());
    }
  }, []);

  React.useEffect(() => {
    if (
      fileHandler.bannerImg &&
      dataURI.length <= 8 &&
      !dataURI.includes(fileHandler.bannerImg) &&
      bannersDetails.bannerImagesFlag === false
    ) {
      setDataURI(fileHandler.bannerImg);
    }
    // Cleanup function
    return () => {
      fileHandler.bannerImg = [];
    };
  }, [fileHandler.bannerImg]);

  React.useEffect(() => {
    if (
      fileHandler.bannerImg &&
      dataURI.length <= 8 &&
      bannersDetails.bannerImagesFlag === false
    ) {
      dispatch(getBannerImg(dataURIName));
    }
  }, [dataURIName]);

  React.useEffect(() => {
    if (bannersDetails.bannerImagesFlag === false) {
      bannersDetails.bannersData.map((data) => {
        if (data.file_names.length > 0) {
          setDataURIName((prev) => [...prev, data.file_names[0]]);
        } else {
        }
      });
    }
  }, [bannersDetails.bannersData]);
  return (
    <Grid
      direction="row"
      justifyContent={{ xs: "center", sm: "center", md: "space-between" }}
      alignItems={{ xs: "center", sm: "center", md: "flex-start" }}
      container
      spacing={2}
    >
      <Grid item xs={12}>
        <Typography
          sx={{
            fontWeight: "700",
            color: appColors.darkBlue,
            fontSize: "16px",
          }}
        >
          Latest at Bookwater
        </Typography>
      </Grid>

      <Grid item md={4}>
        <Box>
          {bannersDetails.bannersData.length > 0 &&
          bannersDetails.error === false ? (
            <BanneRDetails
              data={bannersDetails}
              dataURI={dataURI}
              pageHome={true}
            />
          ) : (
            <Box>
              <img
                src={InfluencerImage}
                style={{
                  width: "100%",
                  height: "140px",

                  marginTop: "10px",
                  margin: "0",
                }}
                alt="Influencer"
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item md={8}>
        <Typography
          sx={{ color: "#30a7ff", fontWeight: "600", fontSize: "15px" }}
        >
          Become a BookWater influencer
        </Typography>
        <Typography sx={{ fontSize: "12px", color: appColors.Textgray }}>
          Dear customer
        </Typography>
        <Typography sx={{ fontSize: "12px", color: appColors.Textgray }}>
          Become an influencer at BookWater and earn while you use your
          influence to help friends and family to safe and pure drinking water.
          BookWater’s Influencer program offers long-terms healthy earnings for
          you and benefits for others too. To know more call Customer Support
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Advertise;
