/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { Card, Box, Button } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import appColors from "../../../Assets/AppTheme/appColors";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import EmailVerificationInSettings from "../../../Pages/Settings/tabs/myprofile/EmailVerificationInSettings";
const CircularProgressWithLabel = (props) => {
  const profile = useSelector((state) => state.userProfile.user);
  const [verifyEmailBtn, setVerifyEmailBtn] = React.useState(false);
  const [EmailInvoiceClicked, setEmailInvoiceClicked] = React.useState(false);

  const handleClose = () => {
    props.setemailVerificationFlag(true);
  };

  const addressExists = props.address?.addresses.length > 0;

  const handelAddressDialog = () => {
    if (addressExists) {
      setVerifyEmailBtn(true);
    } else {
      props.setchooseAddressDialog(true);
    }
  };
  return (
    <Box
      sx={{
        margin: "0 .7rem .5rem .7rem",
        // height: "6rem",
        display: "flex",
        justifyContent: "space-between",
        padding: ".5rem",
        boxShadow: 2,
        borderRadius: "10px",
        position: "relative",
        bgcolor: appColors.cardColor,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontSize: ".9rem",
            color: appColors.headerTextColor,
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          {addressExists
            ? "One step away from starting your BookWater journey"
            : "Two steps away from starting your BookWater journey"}
        </Typography>
        <Button
          variant="contained"
          onClick={handelAddressDialog}
          sx={{
            // width: "8rem",
            height: "1.8rem",
            fontSize: ".8rem",
            bgcolor: appColors.textColorNew,
            fontWeight: 500,
            marginTop: ".2rem",
          }}
        >
          {addressExists ? "Verify Email" : "Add Address"}
        </Button>
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          justifyContent: "center",
          alignItems: "center",
          marginRight: "1rem",
        }}
      >
        <CircularProgress
          variant="determinate"
          {...props}
          thickness={4}
          style={{ height: "4rem", width: "4rem" }}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
          }}
        >
          <Typography
            variant="caption"
            component="div"
            sx={{ color: appColors.darkGray }}
          >
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
      <EmailVerificationInSettings
        verifyEmailBtn={verifyEmailBtn}
        setVerifyEmailBtn={setVerifyEmailBtn}
        setEmailInvoiceClicked={setEmailInvoiceClicked}
      />
    </Box>
  );
};

export default function CircularWithValueLabel({
  address,
  setchooseAddressDialog,
  setemailVerificationFlag,
  data,
}) {
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    setProgress(address?.addresses.length > 0 ? 90 : 50);
  }, [address]);
  return (
    <CircularProgressWithLabel
      value={progress}
      address={address}
      setchooseAddressDialog={setchooseAddressDialog}
      setemailVerificationFlag={setemailVerificationFlag}
    />
  );
}
