/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import appColors from "../../../Assets/AppTheme/appColors";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import { Box, Typography } from "@mui/material";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { ListAvailabileTankers } from "../../../Redux/actions/bookNowAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
export default function DeleteProductExitBooking({ open, onClose }) {
  const histroy = useHistory();
  //Function for handling cancel order Option
  const handleClose = () => {
    onClose(false);
  };

  //function to handel route button
  const handelRouteButton = () => {
    if (window.innerWidth > 600) {
      histroy.push("/dashboard/home");
    } else {
      histroy.push("/mobile");
    }
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ bgcolor: appColors.textColorNew, color: appColors.white }}
        >
          <img
            src={infoIcon}
            alt=""
            style={{ height: "20px", width: "20px" }}
          />
          &nbsp;&nbsp;&nbsp; Exit Booking?
        </DialogTitle>
        <DialogContent
          sx={{ bgcolor: appColors.textColorNew, color: appColors.white }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: appColors.white }}
          >
            <Typography sx={{ fontSize: "1rem", textAlign: "center" }}>
              You are about to remove the last product from your booking. This
              action will cancel the entire booking. Do you wish to continue?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: appColors.textColorNew,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handelRouteButton}
            sx={{
              bgcolor: appColors.white,
              color: appColors.textColorNew,
              "&:hover": {
                bgcolor: "#fff",
              },
            }}
            size="small"
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: appColors.white,
              color: appColors.textColorNew,
              "&:hover": {
                bgcolor: "#fff",
              },
            }}
            size="small"
            variant="contained"
          >
            Close
          </Button>
        </DialogActions>
        <img
          alt=""
          src={closeIcon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            height: "30px",
          }}
          onClick={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
}
