/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { styles } from "./BilldialogStyles";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { GiCash } from "react-icons/gi";
import DoneIcon from "@mui/icons-material/Done";
import CancelIcon from "@mui/icons-material/Cancel";
import { GetUserProfile } from "../../../Redux/actions/profileAction";
import { UpdateUserProfile } from "../../../Redux/actions/settingsAction";
import { UploadFile } from "../../../Redux/actions/fileHandlingActions";
import { getFiles } from "../../../Redux/actions/getFilesActions";
import { toast } from "react-toastify";
import appColors from "../../../Assets/AppTheme/appColors";
import CustomConsole from "../../../coustomConsole";
import { FaCloudDownloadAlt, FaCloudUploadAlt } from "react-icons/fa";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  Tooltip,
  Typography,
} from "@mui/material";

const Input = styled("input")({
  display: "none",
});

export default function BillandGstDialog({
  openBillDetails,
  setOpenBillDetails,
  billDialogOpean,
}) {
  const profile = useSelector((state) => state.userProfile);
  const filesStatus = useSelector((state) => state.fileHandler);
  const profilePic = useSelector((state) => state.fileHandler);
  const updateStatus = useSelector((state) => state.settings);

  const dispatch = useDispatch();
  const [fullname, setFullname] = React.useState(profile.consumer.billing_name);
  const [panNumber, setPanNumber] = React.useState(
    profile?.consumer?.pan_number || ""
  );
  const [gstNumber, setGstNumber] = React.useState(profile.consumer.gst || "");
  const [gstFieldError, setGstFieldError] = React.useState(false);
  // const [fullNameError, setFullNameError] = React.useState(true);
  const [profileImage, setProfileImage] = React.useState("");
  const [gstFileName, setGstFileName] = React.useState("");
  const [closeGstFileName, setCloseGstFileName] = React.useState(false);
  const regExFullName = /^[a-zA-Z]+ [a-zA-Z]+$/;
  React.useEffect(() => {
    if (updateStatus.isProfileUpdated) {
      updateStatus.isProfileUpdated = false;
      dispatch(GetUserProfile());
    }
  }, [updateStatus]);

  React.useEffect(() => {
    if (!updateStatus.isProfileUpdated) {
      setFullname(profile.consumer.billing_name);
      setGstNumber(profile.consumer.gst);
      setPanNumber(profile.consumer.pan_number || "");
    }
  }, [profile]);

  const onChangeFullName = (e) => {
    CustomConsole("Here");
    setFullname(e.target.value);
  };

  const handleUpdate = () => {
    if (billDialogOpean === "pan") {
      const regexPan = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
      if (regexPan.test(panNumber)) {
        dispatch(
          UpdateUserProfile(
            fullname,
            gstNumber,
            panNumber,
            profile.consumer.gst_file_url,
            profile.consumer.profile_pic_url,
            profile.consumer.enable_notification
          )
        );
        setPanNumber("");
        setOpenBillDetails(false);
      } else {
        return toast.dark("Please enter proper PAN number", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    }
    if (billDialogOpean === "gst") {
      filesStatus.isFileUploaded = false;
      if (!gstFieldError) {
        CustomConsole("GST Updating: " + filesStatus.fileName);
        setGstFileName("");
        dispatch(
          UpdateUserProfile(
            fullname,
            gstNumber,
            profile.consumer.pan_number,
            filesStatus.fileName !== ""
              ? filesStatus.fileName
              : profile.consumer.gst_file_url,
            profile.consumer.profile_pic_url,
            profile.consumer.enable_notification
          )
        );
        setOpenBillDetails(false);
      } else {
        CustomConsole("GST number error");
        toast.dark("Please enter proper GST number", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
      }
    }
    //Put Data to Servrer
    if (billDialogOpean === "name") {
      dispatch(
        UpdateUserProfile(
          fullname,
          gstNumber,
          profile.consumer.pan_number,
          profile.consumer.gst_file_url,
          profile.consumer.profile_pic_url,
          profile.consumer.enable_notification
        )
      );
      setOpenBillDetails(false);
    }
  };

  const handleDownloadGst = () => {
    CustomConsole("Download GST");
    dispatch(getFiles(profile.consumer.gst_file_url));
    //Download GST File
    // window.open("https://www.google.com"); Just for Testing
  };

  const handleChangeGst = (event) => {
    let reg = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    var result = reg.test(event.target.value);
    setGstFieldError(!result);
    const value = event.target.value.toUpperCase();
    setGstNumber(value);
  };
  const handleChange = () => {
    CustomConsole("Test Success");
    CustomConsole(gstFileName);
    if (profileImage !== "") {
      dispatch(UploadFile(profileImage));
    } else if (gstFileName !== "") {
      dispatch(UploadFile(gstFileName));
    }
    setCloseGstFileName(false);
  };

  const handleClose = () => {
    setOpenBillDetails(false);
  };

  const handelFileName = (e) => {
    e.stopPropagation();
    setGstFileName(e.target.files[0]);
    setCloseGstFileName(true);
  };

  //function to handel pan number change
  const onChangePanNumber = (e) => {
    const value = e.target.value.toUpperCase();
    setPanNumber(value);
  };
  return (
    <div>
      <Dialog
        open={openBillDetails}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={styles.dialogContent}>
          <Box>
            {/* User Full Name */}
            {billDialogOpean === "name" ? (
              <>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: "bold",
                    display: "flex",
                    color: appColors.darkGray,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Enter Your Billing Name
                </Typography>
                <TextField
                  size="small"
                  type="email"
                  label="Billing Name"
                  variant="filled"
                  color="primary"
                  focused
                  value={fullname}
                  onChange={onChangeFullName}
                  sx={{
                    backgroundColor: "white",
                    fontSize: "18px",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "2%",
                  }}
                />{" "}
              </>
            ) : (
              ""
            )}

            {/* User Full Name */}
            {billDialogOpean === "pan" ? (
              <>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: "bold",
                    display: "flex",
                    color: appColors.darkGray,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Enter Your PAN Number
                </Typography>
                <TextField
                  size="small"
                  type="text"
                  label="PAN Number"
                  variant="filled"
                  color="primary"
                  focused
                  value={panNumber}
                  onChange={onChangePanNumber}
                  sx={{
                    backgroundColor: "white",
                    fontSize: "18px",
                    borderRadius: "5px",
                    width: "100%",
                    marginTop: "2%",
                  }}
                />{" "}
              </>
            ) : (
              ""
            )}
            {billDialogOpean === "gst" ? (
              <Box
                sx={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  },
                ]}
              >
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    fontWeight: "bold",
                    display: "flex",
                    color: appColors.darkGray,
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Enter Your GST Number
                </Typography>
                {/* GST Number */}
                <TextField
                  size="small"
                  type="email"
                  label="GST"
                  value={gstNumber}
                  error={gstFieldError}
                  variant="filled"
                  color="primary"
                  focused
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GiCash size={20} />
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChangeGst}
                  sx={{
                    backgroundColor: "white",
                    fontSize: "18px",
                    borderRadius: "5px",
                    width: "15rem",
                    marginTop: "2%",
                  }}
                />

                {gstFileName?.name && closeGstFileName ? (
                  <Box sx={styles.downloadUploadBox}>
                    <Typography sx={styles.imgName}>
                      {gstFileName.name}
                    </Typography>
                    <IconButton
                      sx={styles.fileUploadConfirmIcon}
                      onClick={() => handleChange()}
                      component="span"
                    >
                      <DoneIcon sx={{ height: ".9rem", width: ".9rem" }} />
                    </IconButton>
                    <IconButton
                      sx={styles.fileUploadCancelIcon}
                      onClick={() => setGstFileName("")}
                      component="span"
                    >
                      <CancelIcon sx={{ height: ".9rem", width: ".9rem" }} />
                    </IconButton>
                  </Box>
                ) : (
                  ""
                )}
                {/* Upload & Download GST File */}
                <Box sx={styles.downloadUploadBox}>
                  {/* GST File Upload/Remove Icon */}
                  <InputLabel htmlFor="import-button">
                    <Input
                      id="import-button"
                      type="file"
                      onChange={(e) => handelFileName(e)}
                    />
                    <Tooltip title="Upload">
                      <IconButton sx={styles.fileUploadIcon} component="span">
                        <FaCloudUploadAlt color="white" size={18} />
                      </IconButton>
                    </Tooltip>
                  </InputLabel>

                  {/* GST File Download Icon */}
                  {profile.consumer.gst_file_url !== "" && (
                    <Tooltip title="Download">
                      <IconButton
                        sx={styles.fileDownloadIcon}
                        onClick={handleDownloadGst}
                        component="span"
                      >
                        <FaCloudDownloadAlt color="white" size={18} />
                      </IconButton>
                    </Tooltip>
                  )}
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: appColors.secondaryCard,
          }}
        >
          <Button onClick={handleClose} size="small" sx={styles.buttonStyle}>
            Close
          </Button>
          <Button onClick={handleUpdate} size="small" sx={styles.buttonStyle}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
