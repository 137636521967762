/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios";
import actionType from "./../actions/actionTypes";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import CustomConsole from "../../coustomConsole";
export const GetQrData = (text) => {
  let api = useAxios();
  CustomConsole("------- GetQrData Action -----");

  return async (dispatch) => {
    try {
      let response = await api.get(`/QSM/container/details?id=${text}`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_QR_DATA,
          payload: {
            error: response.data.error,
            data: response.data.qualityDetails,
            ownerData: response.data.ownerDetails,
            msg: response.data.msg,
          },
        });
      } else {
        CustomConsole(response);
        CustomConsole("in error resp");
        dispatch({
          type: actionType.GET_QR_DATA,
          payload: {
            error: true,
            data: [{}],
            ownerData: [{}],
            msg: response.data.msg,
          },
        });
        // CustomConsole("ELSE: Response in GetUserProfile Action Error");
      }
    } catch (error) {
      CustomConsole("-- Show the Toast Message as ");
      dispatch({
        type: actionType.GET_QR_DATA,
        payload: {
          error: true,
          data: [{}],
          ownerData: [{}],
          msg: error.response.data.msg,
        },
      });
    }
  };
};
