/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import { Button, DialogActions } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RequestingForInfluencer } from "../../Redux/actions/referAndEarnAction.js";
import { GetUserProfile } from "../../Redux/actions/profileAction.js";
import { GetBankDetailsIfsc } from "../../Redux/actions/bankDetailAction.js";
import { Box } from "@mui/material";

function InfluencerFormDialog({ formDialogOpen, setFormDialogOpen }) {
  const dispatch = useDispatch();
  const [influencerPan, setInfluencerPan] = React.useState("");
  const [influencerIfscCode, setInfluencerIfscCode] = React.useState("");
  const [influencerBankName, setInfluencerBankName] = React.useState("");
  const [influencerAccountNumber, setInfluencerAccountNumber] =
    React.useState("");
  const [reEnterAccountNum, setReEnterAccountNum] = React.useState("");
  const [bankBranch, setBankBranch] = React.useState("");

  //flags
  const [panNumEr, setPanNumEr] = React.useState(false);
  const [ifscEr, setIfscEr] = React.useState(false);
  const [onClickFlag, setOnClickFlag] = React.useState(false);
  const alphabetPattern = /^[a-zA-Z ]*$/;

  //Helper text states
  const [accountNumHt, setAccountNumHt] = React.useState("");
  const [reaccountNumberHt, setReAccountNumHt] = React.useState("");
  const [panNumberHt, setPanNumberHt] = React.useState("");
  const [ifscHt, setIfscHt] = React.useState("");

  const influencerReq = useSelector((state) => state.referAndEarnDetails);
  const bankIfscDet = useSelector((state) => state.bankDetailsIfsc);

  const handleClose = () => {
    setFormDialogOpen(false);
    setInfluencerPan("");
    setInfluencerIfscCode("");
    setInfluencerBankName("");
    setInfluencerAccountNumber("");
    setReEnterAccountNum("");
    setBankBranch("");
  };

  const handlePanChange = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(e.target.value);
    setPanNumEr(!isValidPanNumber);
    setInfluencerPan(e.target.value);
    if (!isValidPanNumber) {
      setPanNumberHt("Enter Valid PAN");
    } else {
      setPanNumberHt("");
    }
  };

  React.useEffect(() => {
    // console.log(influencerReq);
    if (influencerReq.error === false && onClickFlag) {
      toast.dark(influencerReq.msg, {
        type: "success",
        position: "bottom-right",
        autoClose: 5000,
      });
      setOnClickFlag(false);
      setFormDialogOpen(false);
      dispatch(GetUserProfile());
    } else if (influencerReq.error === true && onClickFlag) {
      toast.dark(influencerReq.msg, {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
      setOnClickFlag(false);
    }
  }, [influencerReq]);

  React.useEffect(() => {
    // console.log(bankIfscDet);
    if (
      bankIfscDet.branchDetails &&
      Object.keys(bankIfscDet.branchDetails).length > 0
    ) {
      setInfluencerBankName(bankIfscDet.branchDetails.BANK);
      setBankBranch(bankIfscDet.branchDetails.BRANCH);
    }
  }, [bankIfscDet]);

  const handleIfscChange = (e) => {
    const isValidIfscCode = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(e.target.value);
    setIfscEr(!isValidIfscCode);
    setInfluencerIfscCode(e.target.value);
    if (!isValidIfscCode) {
      setIfscHt("Enter Valid IFSC");
    } else {
      setIfscHt("");
    }
  };

  const requestingFormSubmit = () => {
    const reqObj = {
      pan_number: influencerPan,
      bank_name: influencerBankName,
      ifsc_number: influencerIfscCode,
      account_number: influencerAccountNumber,
    };

    if (
      reqObj.pan_number !== "" &&
      reqObj.bank_name !== "" &&
      reqObj.ifsc_number !== "" &&
      reqObj.account_number !== "" &&
      reqObj.account_number === reEnterAccountNum &&
      !panNumEr &&
      !ifscEr
    ) {
      // console.log(reqObj);
      dispatch(RequestingForInfluencer(reqObj));
      setOnClickFlag(true);
    } else if (reqObj.account_number !== reEnterAccountNum) {
      toast.dark("Enter same account number in both fields", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    } else if (
      reqObj.pan_number === "" ||
      reqObj.bank_name === "" ||
      reqObj.ifsc_number === "" ||
      reqObj.account_number === ""
    ) {
      toast.dark("All fields are required", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  const handleChangeAccount = (e) => {
    setInfluencerAccountNumber(e.target.value);
    if (e.target.value.length < 9 || e.target.value.length > 18) {
      setAccountNumHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
    } else {
      setAccountNumHt("");
    }
  };

  const handleChangeReAccount = (e) => {
    setReEnterAccountNum(e.target.value);
    if (e.target.value !== influencerAccountNumber) {
      setReAccountNumHt("Confirm Account Number Mismatched.");
    } else {
      setReAccountNumHt("");
    }
  };

  const getIfscBankDetails = () => {
    if (!ifscEr && influencerIfscCode !== "") {
      dispatch(GetBankDetailsIfsc(influencerIfscCode));
    }
  };

  return (
    <Dialog open={formDialogOpen} onClose={handleClose}>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <TextField
            margin="dense"
            id="name"
            label="Enter your IFSC code*"
            type="text"
            fullWidth
            value={influencerIfscCode}
            onChange={handleIfscChange}
            FormHelperTextProps={{ style: { color: "red" } }}
            helperText={ifscHt}
          />
          <Button variant="contained" onClick={getIfscBankDetails}>
            Verify
          </Button>
        </Box>

        <TextField
          margin="dense"
          id="name"
          label="Bank Branch"
          type="text"
          fullWidth
          value={bankBranch}
          onChange={(e) => {
            setBankBranch(e.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="name"
          label="Enter your BANK Name*"
          type="text"
          fullWidth
          onKeyDown={(e) => {
            if (!alphabetPattern.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={influencerBankName}
          onChange={(e) => {
            setInfluencerBankName(e.target.value);
          }}
        />
        <TextField
          margin="dense"
          id="name"
          label="Enter your PAN number*"
          type="text"
          fullWidth
          value={influencerPan}
          onChange={handlePanChange}
          FormHelperTextProps={{ style: { color: "red" } }}
          helperText={panNumberHt}
        />
        <TextField
          margin="dense"
          id="name"
          label="Enter your Account number*"
          type="number"
          fullWidth
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          value={influencerAccountNumber}
          FormHelperTextProps={{ style: { color: "red" } }}
          helperText={accountNumHt}
          onChange={handleChangeAccount}
        />
        <TextField
          margin="dense"
          id="number"
          label="Re-enter your account number*"
          type="number"
          fullWidth
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          FormHelperTextProps={{ style: { color: "red" } }}
          helperText={reaccountNumberHt}
          value={reEnterAccountNum}
          onChange={handleChangeReAccount}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button variant="contained" onClick={requestingFormSubmit}>
          Send Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default InfluencerFormDialog;
