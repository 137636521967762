/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description
 * @date 06/06/23
 * @version 0.0.6 - Initial Release
 */
import React from "react";
import { Box, Button, Dialog, Grid, Typography } from "@mui/material";
import BookCan from "../../../Assets/AppIcons/BookCan.svg";
import ScanQr from "../../../Assets/AppIcons/ScanQR.svg";
import Subscribe from "../../../Assets/AppIcons/Subscribe.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { OpenCamera } from "../../ScanQr/OpenCamera.jsx";
import QrDataDiaLogBox from "../../../Components/DialogBoxes/QrDialogBox/QrDataDialogBox.jsx";
import QrQualityReportDialog from "../QrQualityReportDialog";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  getBannerImg,
  getFiles,
  getQrQualityImg,
} from "../../../Redux/actions/fileHandlingActions";
import { renderData } from "../../../Redux/actions/isLoadingAction";
import * as qrScannerAction from "../../../Redux/actions/qrScannerAction.js";
import appColors from "../../../Assets/AppTheme/appColors";
import Tour from "reactour";
import Biconcard from "../../../Assets/MobileTheme/Water wallet without Card Background.svg";
function QuickActionCard(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [show, setShow] = React.useState(false);
  const [qualityReportFlag, setQualityReportFlag] = React.useState(false);
  const [fileType, setFileType] = React.useState("");
  const [refreshFlag, setRefreshFlag] = React.useState(true);
  const [qualityReport, setQualityReport] = React.useState(false);
  const [openHtmlQr, setOpenHtmlQr] = React.useState(false);
  const qrWQDetails = useSelector((state) => state.qrScannerDetails);
  const qrReportQuality = useSelector((state) => state.fileHandler);
  const profile = useSelector((state) => state.userProfile);
  const orderDetails = useSelector((state) => state.ordersDetails);
  const [isFirstVisit, setIsFirstVisit] = React.useState(
    !localStorage.getItem("visitedBeforeHome")
  );
  const [isTourOpen, setIsTourOpen] = React.useState(false);
  const bookPageRedirect = () => {
    history.push("/dashboard/booknow");
  };

  //Function for handling quality report
  const handleQualityReport = (fileName) => {
    setFileType(fileName.substr(fileName.length - 3));
    // setQualityReportFlag(true);
    // dispatch(getFiles(fileName));
    dispatch(getQrQualityImg(fileName));
  };

  //Function for opening camera response
  const handleCameraResp = (qrId) => {
    if (qrId.length) {
      dispatch(qrScannerAction.GetQrData(qrId));
    }
  };

  //useeffect for getting qr quality details
  React.useEffect(() => {
    if (qrWQDetails.qrData !== undefined) {
      if (!qrWQDetails.error && Object.keys(qrWQDetails.qrData).length) {
        setShow(true);
        qrWQDetails.isQrDataLoaded = false;
        setOpenHtmlQr(false);
      } else if (
        qrWQDetails.error &&
        !Object.keys(qrWQDetails.qrData).length &&
        qrWQDetails.msg.length
      ) {
        if (openHtmlQr)
          toast.dark(qrWQDetails.msg, {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        setOpenHtmlQr(false);
      }
    } else {
      toast.dark(qrWQDetails.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      setOpenHtmlQr(false);
    }
  }, [qrWQDetails]);

  React.useEffect(() => {
    if (
      qualityReportFlag === true &&
      // qrReportQuality.error === false &&
      qrReportQuality.bannerImg !== "" &&
      qrReportQuality.bannerImg !== null &&
      qrReportQuality.bannerImg !== undefined
    ) {
      setRefreshFlag(false);
      setShow(false);
      setQualityReport(true);
    }
  }, [qrReportQuality]);

  React.useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false);
      dispatch(renderData("Home"));
    }
  }, [refreshFlag]);

  const openQrScanner = () => {
    setOpenHtmlQr(!openHtmlQr);
  };

  //Function for subcription page redirection
  const redirectSubs = () => {
    history.push("/dashboard/subscriptionList");
  };

  React.useEffect(() => {}, [openHtmlQr]);

  const steps = [
    {
      selector: ".tourBookingWeb",
      content: ({ goTo, inDOM }) => (
        <div>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "105%",
              marginLeft: "2.3rem",
              objectFit: "fil",
              zIndex: -1,
            }}
          />
          <div
            style={{
              color: appColors.white,
              fontWeight: "bold",
            }}
          >
            Place Your Order
          </div>
          <br />
          <p style={{ color: appColors.white }}>
            <i>
              Now BookWater's pure and hygienic water is available in 20L PET
              cans and safe Glass Bottles.
            </i>
          </p>
          <p style={{ color: appColors.white }}>
            Glass Bottles are available in 300ml, 500ml and 750 ml refills.
          </p>
        </div>
      ),
      style: {
        backgroundColor: appColors.textColorNew,
      },
    },
    {
      selector: ".tourScanWeb",
      content: ({ goTo, inDOM }) => (
        <div>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "90%",
              marginLeft: 0,
              objectFit: "fil",
              zIndex: -1,
            }}
          />
          <div
            style={{
              color: appColors.white,
              fontWeight: "bold",
            }}
          >
            Scan QR
          </div>
          <br />
          <p style={{ color: appColors.white }}>
            <i>Your journey to hydration just got smarter. </i>
          </p>
          <p style={{ color: appColors.white }}>
            Unlock the secrets of your BookWater can with a simple scan of the
            QR on your can! Stay informed on what you are drinking. Get usage
            insights and water quality details.
          </p>
        </div>
      ),
      style: {
        backgroundColor: appColors.textColorNew,
      },
    },
    {
      selector: ".tourSubscribe",
      content: ({ goTo, inDOM }) => (
        <div>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "100%",
              marginLeft: 0,
              objectFit: "fil",
              zIndex: -1,
            }}
          />
          <div
            style={{
              color: appColors.white,
              fontWeight: "bold",
            }}
          >
            Subscription
          </div>
          <br />
          <p style={{ color: appColors.white }}>
            <i>Subscribe and forget about frequent ordering. </i>
          </p>
          <p style={{ color: appColors.white }}>
            We will deliver as per your schedule
          </p>
        </div>
      ),
      style: {
        backgroundColor: appColors.textColorNew,
      },
    },
    {
      selector: ".tourWalletWeb",
      content: ({ goTo, inDOM }) => (
        <div>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "100%",
              marginLeft: 0,
              objectFit: "fil",
              zIndex: -1,
            }}
          />
          <div
            style={{
              color: appColors.white,
              fontWeight: "bold",
            }}
          >
            Water Wallet
          </div>
          <br />
          <p style={{ color: appColors.white }}>
            <i>A relief from pesky cash transactions. </i>
          </p>
          <p style={{ color: appColors.white }}>
            Recharge Your wallet and enjoy hassle-free Bookings.
          </p>
        </div>
      ),
      style: {
        backgroundColor: appColors.textColorNew,
      },
    },
    {
      selector: ".tourDarkWeb",
      content: ({ goTo, inDOM }) => (
        <div>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "100%",
              marginLeft: 0,
              objectFit: "fil",
              zIndex: -1,
            }}
          />
          <div
            style={{
              color: appColors.white,
              fontWeight: "bold",
            }}
          >
            Dark Mode
          </div>
          <br />
          <p style={{ color: appColors.white }}>
            <i>Dark mode for night owls</i>
          </p>
        </div>
      ),
      style: {
        backgroundColor: appColors.textColorNew,
      },
    },
    // Add more steps as needed
  ];

  //Function for closing app tour and setting up a variable in local storage
  const closeTour = () => {
    setIsTourOpen(false);
    localStorage.setItem("visitedBeforeHome", true);
  };

  //UseEffect for triggering app tour component
  React.useEffect(() => {
    if (isFirstVisit) {
      setIsTourOpen(true);
    }
  }, [isFirstVisit]);

  return (
    <>
      <Dialog open={openHtmlQr}>
        {openHtmlQr ? (
          <Box sx={{ width: "300px" }}>
            <OpenCamera
              handleCameraResp={handleCameraResp}
              openHtmlQr={openHtmlQr}
              setOpenHtmlQr={setOpenHtmlQr}
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
            >
              <Button
                onClick={openQrScanner}
                size="small"
                variant="contained"
                sx={{ backgroundColor: appColors.textColorNew }}
              >
                Close
              </Button>
            </Box>
          </Box>
        ) : null}
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Box>
            <Typography
              sx={{
                fontWeight: "700",
                color: appColors.darkBlue,
                fontSize: "16px",
              }}
            >
              Welcome {profile?.user?.fullname}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Box sx={{ marginBottom: "5px" }}>
            <Typography
              sx={{ color: "#30a7ff", fontWeight: "600", fontSize: "16px" }}
            >
              Quick Actions
            </Typography>
          </Box>
          <Grid container spacing={{ xs: 4, sm: 3, md: 3, lg: 2 }}>
            <Grid item xs={4} sm={4} md={4}>
              <Box className="tourBookingWeb">
                <img
                  src={BookCan}
                  className="funSvg"
                  style={{
                    width: "6.5rem",
                    height: "6.9rem",
                    cursor: "pointer",
                    marginTop: "10px",
                    "@media (width: 600px)": {
                      width: "5.3rem",
                      height: "5.5rem",
                    },
                  }}
                  alt="Booking Can"
                  onClick={bookPageRedirect}
                />
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Box className="tourScanWeb">
                <img
                  src={ScanQr}
                  className="funSvg"
                  style={{
                    width: "6.5rem",
                    height: "6.9rem",
                    cursor: "pointer",
                    marginTop: "10px",
                    "@media (width: 600px)": {
                      width: "5.3rem",
                      height: "5.5rem",
                    },
                  }}
                  alt="Booking Can"
                  onClick={openQrScanner}
                />
              </Box>
            </Grid>
            <Grid item xs={4} sm={4} md={4}>
              <Box className="tourSubscribe">
                <img
                  src={Subscribe}
                  className="funSvg"
                  style={{
                    width: "6.5rem",
                    height: "6.9rem",
                    cursor: "pointer",
                    marginTop: "10px",
                    "@media (width: 600px)": {
                      width: "5.3rem",
                      height: "5.5rem",
                    },
                  }}
                  alt="Booking Can"
                  onClick={redirectSubs}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
        {/* <ReactQrReader /> */}
        {/* <QRscanner /> */}
        {show ? (
          <QrDataDiaLogBox
            data={qrWQDetails}
            setShow={setShow}
            handleQualityReport={handleQualityReport}
          />
        ) : (
          ""
        )}
        {false ? (
          <QrQualityReportDialog
            filePath={qrReportQuality.bannerImg}
            setShow={setShow}
            setQualityReport={setQualityReport}
            fileType={fileType}
          />
        ) : (
          ""
        )}
        <Tour
          steps={steps}
          isOpen={isTourOpen}
          onRequestClose={closeTour}
          lastStepNextButton={
            <Button
              variant="contained"
              onClick={closeTour}
              sx={{ bgcolor: appColors.textColorNew }}
            >
              Close
            </Button>
          }
          rounded={10}
        />
      </Grid>
    </>
  );
}

export default QuickActionCard;
