/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import thunk from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";
import RootReducer from "./reducers/RootReducer";

//Remove it before production
//import { composeWithDevTools } from "redux-devtools-extension";

const initialState = {};
const middlewares = [thunk];

// Use compose from Redux to enable Redux DevTools Extension
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(
  RootReducer,
  initialState,
  composeEnhancers(applyMiddleware(...middlewares))
  // composeWithDevTools(applyMiddleware(...middlewares))
);
