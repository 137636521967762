/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
//   orders: [], 
// isQrDataLoaded: false,
  depositData: [],
  user_stock:[],
  msg:""
//   transactions: [], 
};


const getDepositDetailsReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_DEPOSIT_DETAILS:
      return {
        ...state,
        error: action.payload.error,  
        depositData: action.payload.data,
        msg:action.payload.msg
       };
       case actionType.GET_USER_STOCK:
        return {
          ...state,
          error: action.payload.error, 
          user_stock: action.payload.data,
          msg:action.payload.msg
         };
    default:
      return state;
  }
};

export default getDepositDetailsReducer;
