/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../Assets/AppTheme/appColors";

export const styles = {
  headings: {
    color: "#4324e0",
    fontWeight: "800",
    fontSize: "14px",
  },
  centerCol: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  answers: {
    color: appColors.darkGray, fontSize: ".8rem",
  },
  tankerLogo: {
    width: "100px",
    height: "70px",
    userSelect: "none",
  },
  bottlePng: {
    width: "100px",
    height: "150px",
    // objectFit:"cover",
    userSelect: "none",
  },
  paysharpIconLogo: {
    width: "20x",
    height: "20px",
    userSelect: "none",
  },
  razorPayIconLogo: {
    width: "20x",
    height: "30px",
    userSelect: "none",
  },
};
