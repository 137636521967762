/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import axios from "axios";
import { url } from "./../apis";
import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
import * as toastMessage from "./../../Assets/Constants/ToastMessages";

export const signUp = (user) => {
  // console.log("SignUp Action: " + JSON.stringify(user));
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/signup/consumer`, user)
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          // console.log("Response: " + JSON.stringify(res));
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.SIGN_UP,
          payload: res.data,
        });
      })
      .catch((error) => {
        // toast.dark(toastMessage.SIGNUP_ERROR_MESSAGE, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   type: "error",
        // });
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
export const SearchAvailabilityAuth = (pincode) => {
  // console.log("SignUp Action: " + JSON.stringify(user));

  return async (dispatch) => {
    await axios
      .post(`${url}/TM/system/searchAreasbyPincode`, { pincode: pincode })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          // console.log("Response: " + JSON.stringify(res));
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.PINCODE_AVAILABILITY,
          payload: res.data,
        });
      })
      .catch((error) => {
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};
// export const sendEmailOtp = () => {
//   return (dispatch) => {
//     axios.post(`${url}/SCM/sendEmailVerifyOTP`).then((res) => {
//       console.log(res)
//       if (!res.data.error) {
//         sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
//       }else {
//         // console.log("Response: " + JSON.stringify(res));
//         toast.dark(res.data.msg, {
//           position: "bottom-right",
//           autoClose: 5000,
//           type: "error",
//         });
//       }
//       dispatch({
//         type: actionType.SEND_EMAIL_OTP,
//         payload: res.data,
//       })
//     });
//   };
// };

export const logIn = (email_phone, password, storage) => {
  return (dispatch) => {
    axios
      .post(`${url}/SCM/login/consumer`, { email_phone, password })
      .then((res) => {
        if (!res.data.error) {
          if (storage === true) {
            localStorage.setItem("token", res.data.tkn);
            // sessionStorage.setItem("token", res.data.tkn);
          } else {
            sessionStorage.setItem("token", res.data.tkn);
          }
          toast.dark(toastMessage.LOGIN_SUCESS_MESSAGE, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.SIGN_IN,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        // toast.dark(toastMessage.LOGIN_ERROR_MESSAGE, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   type: "error",
        // });
        // console.log("ERROr: " + JSON.stringify(error.response));
        toast.dark(error.response.data.msg, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};

export const verifyEmail = (email_pin) => {
  let signup_jwt = sessionStorage.getItem("verify_token");

  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/verify/consumer_email`, { signup_jwt, email_pin })
      .then((res) => {
        if (!res.data.error) {
          // sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          toast.dark(toastMessage.EMAIL_VERIFICATION_ERROR_MESSAGE, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.EMAIL_VERIFICATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        toast.dark(toastMessage.EMAIL_VERIFICATION_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
        let response = {
          error: true,
        };
        dispatch({
          type: actionType.EMAIL_VERIFICATION,
          payload: response,
        });
        // toast.dark(`Unhandled Error: ${error}`, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   type: "error",
        // });
      });
    // console.log(
    //   "After verifyEmailAction: " + signup_jwt + "\tOTP: " + email_pin
    // );
  };
};

export const verifyPhone = (phone_pin, referral_code) => {
  const signup_jwt = sessionStorage.getItem("signup_jwt");
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/verify/consumer_phone`, {
        signup_jwt,
        phone_pin,
        referral_code,
      })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.PHONE_VERIFICATION,
          payload: res.data,
        });
      })
      .catch((error) => {
        toast.dark(toastMessage.PHONE_VERIFICATION_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
        // toast.dark(`Unhandled Error: ${error}`, {
        //   position: "bottom-right",
        //   autoClose: 5000,
        //   type: "error",
        // });
      });
  };
};

export const resendOtp = () => {
  const signup_jwt = sessionStorage.getItem("signup_jwt");
  return async (dispatch) => {
    await axios
      .post(`${url}/SCM/resend_signup_otp`, { signup_jwt })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.setItem("signup_jwt", res.data.signup_jwt);
          toast.dark(toastMessage.RESEND_OTP_SUCCESS_MESSAGE, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
        } else {
          toast.dark(toastMessage.RESEND_OTP_ERROR_MESSAGE, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
        dispatch({
          type: actionType.RESEND_OTP,
          payload: res.data,
        });
      })
      .catch((error) => {
        toast.dark(toastMessage.RESEND_OTP_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};

export const forgotPasswordUserVerify = (email, phone) => {
  // console.log(
  //   "forgotPasswordUserVerify data:\n Email:" + email + " Phone:" + phone
  // );
  return (dispatch) => {
    axios
      .post(`${url}/SCM/resetpass/user`, { email, phone })
      .then((res) => {
        // console.log("forgotPasswordUserVerify Response:\n" + res);
        if (!res.data.error) {
          sessionStorage.setItem("checksum", res.data.checksum);
          toast.dark(
            toastMessage.FORGOT_PASSWORD_USER_VERIFY_OTP_SUCCESS_MESSAGE,
            {
              position: "bottom-right",
              autoClose: 5000,
              type: "success",
            }
          );
          dispatch({
            type: actionType.FORGOT_PASSWORD_USER_VERIFY_OTP,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        // console.log("forgotPasswordUserVerify Error:\n" + error);
        error = error + "";
        if (error.includes("400")) {
          toast.dark("User Not Registered", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        } else {
          toast.dark("Un-Handled Error, please try after sometime", {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      });
  };
};

export const forgotPasswordNewPassword = (new_password, verify_code) => {
  const checksum = sessionStorage.getItem("checksum");
  return (dispatch) => {
    axios
      .put(`${url}/SCM/resetpass/verify_user`, {
        checksum,
        new_password,
        verify_code,
      })
      .then((res) => {
        if (!res.data.error) {
          sessionStorage.removeItem("checksum");
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "success",
          });
          dispatch({
            type: actionType.FORGOT_PASSWORD_USER_VERIFY_OTP,
            payload: res.data,
          });
        } else {
          toast.dark(res.data.msg, {
            position: "bottom-right",
            autoClose: 5000,
            type: "error",
          });
        }
      })
      .catch((error) => {
        toast.dark(toastMessage.FORGOT_PASSWORD_USER_VERIFY_OTP_ERROR_MESSAGE, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      });
  };
};

export const signOut = () => {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_TODOS",
    });

    dispatch({
      type: "SIGN_OUT",
    });
  };
};

export const ReferalCodeVerify = (referal) => {
  return async (dispatch) => {
    await axios
      .get(`${url}/SSM/verify/referral?referral_id=${referal}`)
      .then((res) => {
        dispatch({
          type: actionType.REFERAL_CODE_VERIFY,
          payload: res.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionType.REFERAL_CODE_VERIFY,
          payload: { error: true },
        });
      });
  };
};
