/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import BookWaterLog from "../../../Assets/AppIcons/BookWaterLogo.svg";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Alert, Box, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import appColors from "../../../Assets/AppTheme/appColors.js";
export default function QrDataDiaLogBox({
  data,
  setShow,
  handleQualityReport,
}) {
  const [open, setOpen] = React.useState(true);
  const dispatch = useDispatch();
  const themeState = useSelector((state) => state.darkThemeState.themeState);
  const qrQualityReport = useSelector((state) => state.qrQualityReport);
  const history = useHistory();
  const handleClose = () => {
    // setOpen(false);
    data.qrData = [];
    setShow(false);
    if (window.innerWidth < 600) {
      history.push("/mobile");
    }
  };
  const bgcolor = "linear-gradient(to right, #161068, #460e33)";
  const qualityData = [
    {
      name: "Sold To:",
      data: `${data?.ownerDetails?.assigned_to_details?.fullname}`,
    },
    {
      name: "Container Capacity:",
      data: `${data?.qrData?.container_details?.capacity}`,
    },
    {
      name: "Container Material:",
      data: `${data?.qrData?.container_details?.material}`,
    },
    {
      name: "TDS Range:",
      data: `${data?.qrData?.quality_details?.tds}`,
    },
    {
      name: "PH Range:",
      data: `${data?.qrData?.quality_details?.ph}`,
    },
    {
      name: "Mfg Date:",
      data: moment(data?.qrData?.quality_details?.mft_date).format("ll"),
    },
    {
      name: "Expiry Date:",
      data: moment(data?.qrData?.quality_details?.exp_date).format("ll"),
    },
    {
      name: "Reused Count:",
      data: `${data?.qrData?.no_of_times_used}`,
    },
  ];

  const handleEyeIcon = () => {
    handleQualityReport(data?.qrData?.quality_details?.quality_report);
  };

  return (
    <>
      {data.qrData ? (
        <Box
          sx={{
            height: "100px",
            overflowY: "scroll",
          }}
        >
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              sx: {
                borderRadius: "20px", // Set border radius for curved effect
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              sx={{
                color: "white",
                fontWeight: "bold",
                backgroundColor: appColors.cardColor,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <img
                  src={
                    themeState
                      ? BookWaterLog
                      : process.env.PUBLIC_URL + "/bwLogo2.png"
                  }
                  style={{ width: "100px", height: "40px" }}
                  alt=""
                />
                <Typography
                  variant="overline"
                  display="block"
                  gutterBottom
                  sx={{
                    marginLeft: "10px",
                    fontSize: "16px",
                    color: appColors.darkGray,
                    fontWeight: "bolder",
                  }}
                >
                  Water Quality Details
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent sx={{ backgroundColor: appColors.cardColor }}>
              <DialogContentText
                id="alert-dialog-description"
                sx={{ color: appColors.headerTextColor, padding: "5px" }}
              >
                {qualityData.map((qtyData, key) => {
                  return (
                    <Box key={key} sx={{ display: "flex", gap: "5px" }}>
                      <Box>
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          {qtyData.name}
                        </Typography>
                      </Box>
                      <Box
                        sx={{ color: appColors.darkGray, fontWeight: "bold" }}
                      >
                        <Typography
                          variant="overline"
                          display="block"
                          gutterBottom
                        >
                          {qtyData.data}{" "}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    size="medium"
                    sx={{ color: appColors.blue }}
                    startIcon={<RemoveRedEyeIcon />}
                    onClick={handleEyeIcon}
                  >
                    View Quality Report
                  </Button>
                </Box>
              </DialogContentText>
            </DialogContent>
            <DialogActions
              sx={{
                backgroundColor: appColors.cardColor,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {/* <Button onClick={handleClose}>Disagree</Button> */}
              <Button
                onClick={handleClose}
                variant="contained"
                size="small"
                sx={{ backgroundColor: appColors.blue }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Alert severity="warning">Please Scan Valid QR code</Alert>
          <Button
            onClick={handleClose}
            variant="contained"
            sx={{ marginTop: "10px", backgroundColor: appColors.darkBlue }}
          >
            Close
          </Button>
        </Box>
      )}
    </>
  );
}
