/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as Material from "@mui/material";
import React from "react";

import {
  AddNewAdress,
  DeleteUserAddress,
  EditAdress,
  GetUserAdressBook,
} from "../../../../Redux/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import appColors from "../../../../Assets/AppTheme/appColors";

function DeleteAddressDialog(props) {
  const dispatch = useDispatch();
  const userAddressList = useSelector((state) => state.settings);

  const handleCloseDia = () => {
    props.setOpenDia(false);
  };

  const handleDeleteAddress = () => {
    if (props.addressId !== "") {
      dispatch(DeleteUserAddress(props.addressId));
      props.setOpenDia(false);
    }
  };

  return (
    <>
      {/* Address Delete COnfirmation */}
      <Material.Dialog
        open={props.openDia}
        onClose={handleCloseDia}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Material.DialogTitle
          sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
          id="responsive-dialog-title"
        >
          <Material.Typography
            sx={{
              fontSize: { xs: "20px", sm: "20px", md: "25px" },
              color: appColors.textColorNew,
              fontWeight: "500",
            }}
          >
            {"Are you Sure?"}
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent
          sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
        >
          <Material.DialogContentText sx={{ color: appColors.darkGray }}>
            Do you really want to delete this Address? This cannot be restored.
          </Material.DialogContentText>
        </Material.DialogContent>
        <Material.DialogActions
          sx={{
            bgcolor: appColors.secondaryCard,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Material.Button
            sx={{
              bgcolor: appColors.textColorNew,
            }}
            autoFocus
            variant="contained"
            onClick={handleDeleteAddress}
            size="small"
          >
            Yes,proceed
          </Material.Button>
          <Material.Button
            sx={{
              backgroundColor: appColors.textColorNew,

              color: "#fff",
            }}
            onClick={handleCloseDia}
            variant="contained"
            autoFocus
            size="small"
          >
            No
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </>
  );
}

export default DeleteAddressDialog;
