/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description  Check out page where payments and order placing events are handled
 *
 */

import React, { useRef } from "react";
import Lottie from "lottie-web";
import useScreenSize from "../../Components/Utils/mobileScreen";
import CheckOutOverView from "./CheckOutOverView";
import { Box, Grid } from "@mui/material";
import MobileBookingFooterSku from "../../Components/Cards/SkuCards/PaymentFooterCard";
import ChoosePaymentDialogSKU from "../../Components/DialogBoxes/SkuDialogs/ChoosePaymentDialog";
import PriceBreakupDialog from "../../Components/DialogBoxes/SkuDialogs/PriceBreakUpDialogSKU";
import MyWallet from "../../Components/PaymentComponent/mywallet/myWallet";
import PriceBreakUpCardSkuWeb from "../../Components/Cards/SkuCards/PriceBreakUpCardWeb";
import PaymentCardSkuWeb from "../../Components/Cards/SkuCards/PaymentCardWebSku";
import Addresscard from "../../Components/Cards/SkuCards/Addresscard";
import { AddNewAddressDialogBox } from "../Settings/tabs/myaddressbook/DialogBoxes";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewAdress,
  GetUserAdressBook,
} from "../../Redux/actions/settingsAction";
import {
  CheckOutAction,
  ClearPendingOrderData,
  SubscribeCheckOutAction,
  UpdateErrorDetails,
} from "../../Redux/actions/checkOutAction";
import {
  BookSkuProductAction,
  OrderErrorChange,
  ResetOrderState,
} from "../../Redux/actions/skuOrderPlacingAction";
import moment from "moment";
import BookingSucessDiaplay from "../BookNow/SkuBooking/bookingSucess";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  SubscribeOrderAction,
  UpdateSubscriptionOrder,
} from "../../Redux/actions/skuSubscribeAction";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import BookingCouponAppliedDialog from "../../Components/DialogBoxes/SkuDialogs/Booking/bookingDiscountApply";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import ExitBookingDialog from "../../Components/DialogBoxes/info/exitBooking";
import {
  DeleteAllProductQty,
  SelectedProductaction,
  SetSelectedAddress,
} from "../../Redux/actions/skuBookingAction";
import PendingOrderDialogBox from "../../Components/DialogBoxes/BookNow/pendingOrderDialog";
import CustomConsole from "../../coustomConsole";
import appMessages from "../../Assets/AppMeta/appMessages";
export default function CheckoutMain() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const productDetails = useSelector(
    (state) => state.skuBookDetails.productDetails
  );
  const paymentBlocked = productDetails?.admin_settings?.payment_methods.map(
    (method) => method.type
  );
  const BlocketPayments = new Set(paymentBlocked);
  const appmetaDataDetail = useSelector((state) => state.appMetaConfigData);
  const isMobile = useScreenSize();
  const orderIdDetails = useSelector((state) => state.skuOrderIdDetails);
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  const walletDetails = useSelector((state) => state.paymentMethods);
  //settingsAction
  const addressDetails = useSelector((state) => state.settings);
  const [openHomeRoute, setOpenHomeRoute] = React.useState(false);
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const [bookingFlag, setBookingFlag] = React.useState(false);
  const [textRating, settextRating] = React.useState("");
  const SkuSelectedProducts = useSelector((state) => state.skuBookDetails);
  const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);
  const [updatestatus, setUpdateStatus] = React.useState(false);
  const [promoCode, setpromoCode] = React.useState("");
  const [dummyPromo, setDummyPromo] = React.useState("");
  const [openVbDialog, setOpenVbDialog] = React.useState(false);
  const [paymentFlag, setpaymentFlag] = React.useState(false);
  const [upiId, setUpiId] = React.useState("");
  const [razorPayFlag, setrazorPayFlag] = React.useState(false);
  const [deliveryCharges, setDeliveryCharges] = React.useState(0);
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const updateError = useSelector((state) => state.checkOutDetails);
  const [amountEntered, setEnteredPayAmount] = React.useState(0);
  const [upiIdVPAObj, setUpiIdVPAObj] = React.useState({});
  const [verifyUpiClicked, setVerifyUpiIdClicked] = React.useState(false);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = React.useState(paymentTypeWallet.WATER_WALLET);
  const [currentDate, setCurrentDate] = React.useState(
    SkuSelectedProducts?.selectedProduct?.delivery_date
  );
  const [upiUserOption, setUpiUserOption] = React.useState("2");
  const [upiPayUserOption, setUpiPayUserOption] = React.useState("");
  const [payType, setPayType] = React.useState(0);
  const [verifyBtn, setVerifyBtn] = React.useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = React.useState(false);
  const [opean, setOpean] = React.useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = React.useState(false);
  const [payFor, setPayFor] = React.useState("");
  const [payOptionCheck, setPayOptionCheck] = React.useState(false);
  const [choosenPartial, setChoosenPartial] = React.useState(false);
  const [bookingpaymentFlag, setBookingPaymentFlag] = React.useState(false);
  const [openPriceBreakUpDialog, setOpenPriceBreakUpDialog] =
    React.useState(false);
  const [upiPay, setUpiPay] = React.useState(false);
  const [updatePaymentClick, setUpdatePaymentClick] = React.useState(false);
  const [products, setProducts] = React.useState({
    products: [],
    crates: [],
    booking_type: SkuSelectedProducts?.selectedProduct?.booking_type,
    address_id: SkuProductDetails?.selectedAddressId,
    coupon_code: SkuSelectedProducts?.selectedProduct?.coupon_code,
    water_drops: SkuSelectedProducts?.selectedProduct?.water_drops,
    payment_method: SkuSelectedProducts?.selectedProduct?.payment_method,
    is_otp_less: SkuSelectedProducts?.selectedProduct?.is_otp_less,
    is_auto_apply_water_drops:
      SkuSelectedProducts?.selectedProduct?.is_auto_apply_water_drops,
    delivery_date: moment(currentDate).format("YYYY-MM-DD"),
    is_auto_apply_coupon:
      SkuSelectedProducts?.selectedProduct?.is_auto_apply_coupon,
    secondary_payment_method: "",
  });
  //state for storing address ID
  const [addressDetailsID, setAddressDetailsID] = React.useState();
  const [openCouponAppliedDialog, setOpenCouponAppliedDialog] =
    React.useState(false);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [chooseAdressDialog, setchooseAddressDialog] = React.useState(false);
  const [primaryAddress, setPrimaryAddress] = React.useState({});
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(
    SkuSelectedProducts?.selectedProduct?.payment_method
  );

  const [walletPayDone, setWalletPayDone] = React.useState(false);
  const [walletPayFlag, setWalletPayFlag] = React.useState(false);
  const [updateProductFlag, setUpdateProductFlag] = React.useState(false);
  const [subscribeFlag, setSubscribeFlag] = React.useState(false);
  const [updateSubscribeFlag, setUpdateSubscribeFlag] = React.useState(false);
  const [minimumWalletAmount, setMinimumWalletAmount] = React.useState(0);
  const CouponAnimation = React.useRef(null);
  const [promoCodeFlag, setPromoCodeFlag] = React.useState(false);
  const [samedayDelivery, setSameDayDelivery] = React.useState(false);
  const [subscribeObject, setSubscribeObject] = React.useState({
    user_id: "",
    order_details: {
      products: [],
      is_auto_apply_coupon: true,
      is_auto_apply_water_drops: true,
      is_otp_less: true,
    },
    repeat_days: [],
    start_date: "",
    address_id: 0,
    is_forced_create: true,
  });
  const [subscribePaymentFlag, setSubscribePaymentFlag] = React.useState(false);
  const [isOrderInPaymentPending, setIsOrderPaymentPending] =
    React.useState(false);
  const [couponFlag, setCouponFlag] = React.useState(false);
  //usestate to handel payment method and type
  const [secondaryPayment, setSecondaryPayment] = React.useState("");
  const [useWalletBalancePayFlag, setUseWalletBalancePayFlag] =
    React.useState(true);
  const [invalidProducts, setInvalidProducts] = React.useState([]);

  //useeffect to handel wallet multipayment method
  React.useEffect(() => {
    // console.log(appmetaDataDetail)
    if (BlocketPayments.has(300)) {
      setUseWalletBalancePayFlag(false);
    }
  }, [BlocketPayments]);

  //useeffect to get valid products quantity as isvaild
  React.useEffect(() => {
    const products = checkoutDetails?.checkOutDetails?.valid_products;
    if (products !== undefined && products?.length > 0) {
      const invalid = products.filter(
        (product) => !product.quantity.is_valid || !product.return.is_valid
      );
      setInvalidProducts(invalid);
    }
  }, [checkoutDetails]);

  CustomConsole("invalidProducts", invalidProducts);

  // useEffect to handle api flag and redirect on page refresh
  React.useEffect(() => {
    if (
      checkoutDetails?.checkOutDetails === undefined ||
      Object.keys(checkoutDetails?.checkOutDetails).length === 0
    ) {
      if (
        window.innerWidth <= 600 &&
        pageName &&
        !pageName.includes("editSubscribe")
      ) {
        history.replace("/mobile");
      } else if (window.innerWidth > 600) {
        history.replace("/dashboard/booknow");
      } else if (pageName && pageName.includes("editSubscribe")) {
        history.replace("/dashboard/subscriptionList");
      }
    }
  }, [checkoutDetails, addressDetails]);
  //Useeffect for setting address details
  React.useEffect(() => {
    let AddressData;
    if (
      addressDetails?.addresses !== undefined &&
      SkuProductDetails?.selectedAddressId === ""
    ) {
      AddressData =
        addressDetails?.addresses.find((item) => item.is_primary === true) ||
        addressDetails?.addresses[0];
      setPrimaryAddress(() => AddressData);
      setAddressDetailsID(() => AddressData?.address_id);
    } else {
      AddressData = addressDetails?.addresses.find(
        (item) => item.address_id === SkuProductDetails?.selectedAddressId
      );
      setPrimaryAddress(() => AddressData);
      setAddressDetailsID(() => AddressData?.address_id);
    }
  }, [addressDetails.addresses]);
  //Closing address dialog if address details added
  const handleAddAddressDialog = () => {
    setAddAddressDialog(false);
  };

  //Function for handling new address added
  const handleAddNewAddress = (details, isPrimary) => {
    dispatch(AddNewAdress(details, isPrimary));
  };

  CustomConsole(primaryAddress);
  //Toggle function for opening dialog box
  const toggleDrawer = (newOpen) => {
    setOpenPriceBreakUpDialog(newOpen);
  };

  // //useeffect for hitting update api if data changes
  React.useEffect(() => {
    if (!updatestatus) {
      setUpdateStatus(true);
      dispatch(UpdateErrorDetails());
      // updateError.updateError = false;
    }
    dispatch(ResetComponentState());
  }, []);

  //function for handling booking operation to trigger bokking api and payment
  const handelBookingFunction = () => {
    if (pageName === "booking") {
      setBookingFlag(true);
    } else if (pageName === "subscribe") {
      if (amountEntered === 0) {
        setSubscribeFlag(true);
      } else if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
      } else if (upiUserOption !== "3") {
        setpaymentFlag(true);
      } else {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      }
    } else if (pageName && pageName.includes("editSubscribe")) {
      if (amountEntered === 0) {
        setUpdateSubscribeFlag(true);
      } else if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
      } else if (upiUserOption !== "3") {
        setpaymentFlag(true);
      } else {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      }
    }
  };

  //Useeffect for triggering booking action
  React.useEffect(() => {
    if (bookingFlag && upiUserOption !== "3") {
      console.log("----Booking object---");
      console.log(SkuSelectedProducts.selectedProduct);
      dispatch(BookSkuProductAction(SkuSelectedProducts.selectedProduct));
      if (
        checkoutDetails?.checkOutDetails?.balance_to_pay >= 1 &&
        useWalletBalancePayFlag &&
        !BlocketPayments.has(300)
      ) {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.balance_to_pay);
        if (checkoutDetails?.checkOutDetails?.balance_to_pay < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.final_price);
        if (checkoutDetails?.checkOutDetails?.final_price < 10) {
          setUpdatePaymentClick(false);
        }
      }
      setBookingFlag(false);
    } else if (bookingFlag && upiUserOption === "3" && isUpiIdValid) {
      dispatch(BookSkuProductAction(SkuSelectedProducts.selectedProduct));
      if (
        checkoutDetails?.checkOutDetails?.balance_to_pay >= 1 &&
        useWalletBalancePayFlag &&
        !BlocketPayments.has(300)
      ) {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.balance_to_pay);
        if (checkoutDetails?.checkOutDetails?.balance_to_pay < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.final_price);
        if (checkoutDetails?.checkOutDetails?.final_price < 10) {
          setUpdatePaymentClick(false);
        }
      }
      setBookingFlag(false);
    } else if (bookingFlag) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Please verify your upi id to proceed`,
        })
      );
      setBookingFlag(false);
    }
  }, [bookingFlag]);

  //Useeffect for triggering subscribe action
  React.useEffect(() => {
    if (subscribeFlag && upiUserOption !== "3") {
      dispatch(SubscribeOrderAction(subscribeObject));
      setSubscribeFlag(false);
    } else if (subscribeFlag && upiUserOption === "3" && isUpiIdValid) {
      dispatch(SubscribeOrderAction(subscribeObject));
      setSubscribeFlag(false);
    } else if (subscribeFlag) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Please verify your upi id to proceed`,
        })
      );
      setSubscribeFlag(false);
    }
  }, [subscribeFlag]);

  //Useeffect for triggering update subscribe action
  React.useEffect(() => {
    if (updateSubscribeFlag && upiUserOption !== "3") {
      const subscribeNoMatch = pageName ? pageName.match(/\d+/) : null;
      const subscribeNo = subscribeNoMatch[0];
      dispatch(UpdateSubscriptionOrder(subscribeNo, subscribeObject));
      setUpdateSubscribeFlag(false);
    } else if (updateSubscribeFlag && upiUserOption === "3" && isUpiIdValid) {
      const subscribeNoMatch = pageName ? pageName.match(/\d+/) : null;
      const subscribeNo = subscribeNoMatch[0];
      dispatch(UpdateSubscriptionOrder(subscribeNo, subscribeObject));
      setUpdateSubscribeFlag(false);
    } else if (updateSubscribeFlag) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: appMessages.upiIdErrorMessage,
        })
      );
      setUpdateSubscribeFlag(false);
    }
  }, [updateSubscribeFlag]);

  //useEffect for triggering payment option
  React.useEffect(() => {
    if (!orderIdDetails?.orderError && orderIdDetails?.orderId.length > 0) {
      if (
        products.payment_method === "WALLET" &&
        products.secondary_payment_method === ""
      ) {
        setWalletPayDone(true);
      } else {
        setpaymentFlag(true);
      }
      dispatch(OrderErrorChange());
    }
  }, [orderIdDetails.orderError]);

  CustomConsole(orderIdDetails);

  //function for updating product details when date changes
  React.useEffect(() => {
    if (products?.products?.length >= 0)
      setProducts((prev) => ({
        ...prev,
        payment_method: paymentType,
        secondary_payment_method: secondaryPayment,
      }));
  }, [paymentType, secondaryPayment]);

  //Useeffect for getting waterwallet payment method
  React.useEffect(() => {
    CustomConsole(walletDetails);
    if (false) {
      setWalletPayDone(true);
      setWalletPayFlag(false);
      window.scrollTo(0, 0);
      dispatch(ResetOrderState());
    } else if (!skuSubscribeOrderDetails.subscribeOrderError) {
      setWalletPayDone(true);
      window.scrollTo(0, 0);
      skuSubscribeOrderDetails.subscribeOrderError = true;
      orderIdDetails.exitBookingFlag = false;
    }
  }, [walletDetails, skuSubscribeOrderDetails.subscribeOrderError]);

  // Cleanup function to reset component state when unmounting or when dependencies change
  React.useEffect(() => {
    return () => {
      dispatch(ResetComponentState());
      dispatch(ClearPendingOrderData());
      dispatch(SelectedProductaction({}));
      dispatch(SetSelectedAddress(""));
      skuSubscribeOrderDetails.subscribeOrderError = true;
    };
  }, []);
  // //useeffect for hitting update api if data changes
  React.useEffect(() => {
    if (
      (updateProductFlag &&
        SkuSelectedProducts.selectedProduct?.products?.length > 0) ||
      (updateProductFlag &&
        SkuSelectedProducts.selectedProduct?.crates?.length > 0)
    ) {
      if (
        (pageName && pageName === "subscribe") ||
        (pageName && pageName.includes("editSubscribe"))
      ) {
        dispatch(SubscribeCheckOutAction(SkuSelectedProducts.selectedProduct));
      } else {
        console.log("----SKU Booking checkout Data 2-----");
        console.log(SkuSelectedProducts.selectedProduct);
        dispatch(CheckOutAction(SkuSelectedProducts.selectedProduct));
      }
    }
  }, [SkuSelectedProducts.selectedProduct]);

  // //useeffect for hitting update api if data changes
  React.useEffect(() => {
    if (
      checkOutDetails !== undefined &&
      checkOutDetails !== null &&
      Object.keys(checkOutDetails).length > 0
    ) {
      if (updatestatus && updateProductFlag === false) {
        setUpdateProductFlag(true);
      }
    }
  }, [checkOutDetails, updatestatus]);

  //useEffect for getting subcribe order details
  React.useEffect(() => {
    if (
      (pageName === "subscribe" && products?.products?.length > 0) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        products?.products?.length > 0)
    ) {
      setSubscribeObject((prevSubscribeObject) => {
        // Update subscribeObject based on checkoutDetails.checkOutDetails
        const updatedSubscribeObject = {
          ...prevSubscribeObject,
          // Update other properties as needed
          user_id: checkoutDetails?.checkOutDetails?.consumer_user_id,
          order_details: {
            ...prevSubscribeObject.order_details,
            products: products.products,
            is_auto_apply_water_drops: products.is_auto_apply_water_drops,
            is_auto_apply_coupon: products.is_auto_apply_coupon,
            is_otp_less: products?.is_otp_less,
          },
          booking_type: products.booking_type,
          repeat_days: skuSubscribeOrderDetails?.selectedDays,
          start_date: moment(currentDate).format("YYYY-MM-DD"),
          address_id: products?.address_id,
          is_forced_create: true,
        };
        return updatedSubscribeObject;
      });
    }
  }, [products]);

  CustomConsole(subscribeObject);

  //useEffect for updating product final price
  React.useEffect(() => {
    if (
      pageName !== "subscribe" &&
      pageName &&
      !pageName.includes("editSubscribe")
    ) {
      setCurrentDate(SkuSelectedProducts?.selectedProduct?.delivery_date);
      if (
        checkoutDetails?.checkOutDetails?.balance_to_pay >= 1 &&
        useWalletBalancePayFlag &&
        !BlocketPayments.has(300)
      ) {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.balance_to_pay);
        if (checkoutDetails?.checkOutDetails?.balance_to_pay < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(checkoutDetails?.checkOutDetails?.final_price);
        if (checkoutDetails?.checkOutDetails?.final_price < 10) {
          setUpdatePaymentClick(false);
        }
      }
    }
  }, [
    checkoutDetails?.checkOutDetails?.final_price,
    checkoutDetails?.checkOutDetails?.balance_to_pay,
  ]);

  // //useEffect for setting promo code
  React.useEffect(() => {
    if (
      checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid &&
      checkoutDetails?.checkOutDetails?.coupon_code_details?.selected !== "" &&
      !couponFlag
    ) {
      setpromoCode(
        checkoutDetails?.checkOutDetails?.coupon_code_details?.selected
      );
      setCouponFlag(true);
    } else if (
      !checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid &&
      checkoutDetails?.checkOutDetails?.coupon_code_details?.selected === "" &&
      !couponFlag
    ) {
      setpromoCode("");
      setCouponFlag(true);
    }
  }, [checkoutDetails?.checkOutDetails?.coupon_code_details]);

  // //useEffect for setting promo code
  React.useEffect(() => {
    if (
      !checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid &&
      promoCode !== "" &&
      promoCode !== undefined &&
      promoCode !== null &&
      couponFlag
    ) {
      setpromoCode("");
    } else if (
      checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid &&
      promoCode === "" &&
      promoCode !== undefined &&
      promoCode !== null &&
      couponFlag
    ) {
      setpromoCode(
        checkoutDetails?.checkOutDetails?.coupon_code_details?.selected
      );
    } else if (
      !checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid &&
      promoCode === "" &&
      promoCode !== undefined &&
      promoCode !== null &&
      couponFlag &&
      checkoutDetails?.checkOutDetails?.coupon_code_details?.selected !== ""
    ) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Coupon code currently invalid`,
        })
      );
    }
  }, [checkoutDetails?.checkOutDetails?.coupon_code_details?.is_valid]);
  //useEffect for updating product final price
  React.useEffect(() => {
    return () => {
      dispatch(ResetOrderState());
      dispatch(DeleteAllProductQty());
    };
  }, []);

  //function to set minimum waleet amount
  //minimumwalletAmount updating
  React.useEffect(() => {
    // console.log(appmetaDataDetail)
    appmetaDataDetail.metaData.map((data) => {
      // console.log(data)
      if (data.key === "minimum_wallet_amount") {
        // console.log(data.value)
        setMinimumWalletAmount(data.value[0].v);
      }
    });
  }, [appmetaDataDetail]);
  CustomConsole(updatestatus + " updateStatus" + updateProductFlag);
  CustomConsole(SkuSelectedProducts?.selectedProduct);

  //function to hit update api after getting payment sucess responce from orderstatus api
  React.useEffect(() => {
    if (
      (walletDetails?.paymentData?.data?.payment_status === "SUCCESS" &&
        !subscribePaymentFlag &&
        pageName === "subscribe") ||
      (walletDetails?.paymentData?.data?.status === "SUCCESS" &&
        !subscribePaymentFlag &&
        pageName === "subscribe")
    ) {
      dispatch(SubscribeCheckOutAction(SkuSelectedProducts.selectedProduct));
      dispatch(SubscribeOrderAction(subscribeObject));
      setSubscribePaymentFlag(true);
    } else if (
      (walletDetails?.paymentData?.data?.payment_status === "SUCCESS" &&
        !subscribePaymentFlag &&
        pageName.includes("editSubscribe")) ||
      (walletDetails?.paymentData?.data?.status === "SUCCESS" &&
        !subscribePaymentFlag &&
        pageName.includes("editSubscribe"))
    ) {
      const subscribeNoMatch = pageName ? pageName.match(/\d+/) : null;
      const subscribeNo = subscribeNoMatch[0];
      dispatch(UpdateSubscriptionOrder(subscribeNo, subscribeObject));
    }
  }, [walletDetails.paymentData]);

  //useeffect to set the current date based on blocked dates
  React.useEffect(() => {
    if (currentDate !== undefined) {
      let newCurrentDate = moment(currentDate); // Convert currentDate to a moment object
      while (
        SkuProductDetails?.blockDates.includes(
          newCurrentDate.format("YYYY-MM-DD")
        )
      ) {
        newCurrentDate = newCurrentDate.add(1, "day"); // Move to the next day
      }
      setCurrentDate(newCurrentDate.format("YYYY-MM-DD")); // Update currentDate state
    } else {
      setCurrentDate(moment().add(1, "day").format("YYYY-MM-DD")); // Set current date if it's undefined
    }
  }, [SkuProductDetails?.blockDates, currentDate]);

  //useEffect to calculate delivercahrge sum price
  React.useEffect(() => {
    //function for getting selected quantity
    const ExtraCharges =
      checkoutDetails?.checkOutDetails?.valid_products?.reduce(
        (sum, product) => {
          if (product.extra_charges.price !== undefined) {
            sum += product.extra_charges.price;
          }
          return sum;
        },
        0
      );
    setDeliveryCharges(ExtraCharges);
  }, [checkoutDetails?.checkOutDetails]);

  //useEffect to change subscription object for auto apply water drops
  React.useEffect(() => {
    if (
      products.is_auto_apply_water_drops !==
        subscribeObject.order_details.is_auto_apply_water_drops ||
      products.is_auto_apply_coupon !==
        subscribeObject.order_details.is_auto_apply_coupon
    ) {
      setSubscribeObject((prevState) => ({
        ...prevState,
        order_details: {
          ...prevState.order_details,
          is_auto_apply_water_drops: products.is_auto_apply_water_drops,
          is_auto_apply_coupon: products.is_auto_apply_coupon,
        },
      }));
    }
  }, [products]);

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    if (pageName === "booking") {
      const finalPrice = checkoutDetails?.checkOutDetails?.final_price || 0;
      const walletBalance =
        checkoutDetails?.checkOutDetails?.wallet_balance || 0;
      const balanceToPay =
        checkoutDetails?.checkOutDetails?.balance_to_pay || 0;
      let validPayment = "";
      if (
        (pageName === "booking" &&
          finalPrice > walletBalance &&
          finalPrice !== 0) ||
        (finalPrice < 11 && finalPrice !== 0) ||
        (pageName === "booking" &&
          finalPrice <= walletBalance &&
          finalPrice !== 0)
      ) {
        if (
          !BlocketPayments.has(300) &&
          pageName !== "subscribe" &&
          pageName &&
          !pageName.includes("editSubscribe") &&
          finalPrice <= walletBalance &&
          finalPrice !== 0
        ) {
          validPayment = "WALLET";
          setPayment(paymentTypeWallet.WATER_WALLET);
          setPaymentType("WALLET");
          setSecondaryPayment("");
          setUpiUserOption("");
          setPayType(0);
          setUpdatePaymentClick(false);
        } else if (
          (!BlocketPayments.has(101) &&
            !BlocketPayments.has(100) &&
            window.innerWidth <= 600 &&
            finalPrice >= 10 &&
            balanceToPay === 0 &&
            finalPrice - walletBalance >= 10 &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(101) &&
            !BlocketPayments.has(100) &&
            window.innerWidth <= 600 &&
            finalPrice >= 10 &&
            balanceToPay >= 10 &&
            useWalletBalancePayFlag &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(101) &&
            !BlocketPayments.has(100) &&
            window.innerWidth <= 600 &&
            finalPrice >= 10 &&
            !useWalletBalancePayFlag &&
            !updatePaymentClick)
        ) {
          validPayment = "PAYSHARP";
          setPayment(paymentTypeWallet.PAYSHARP);
          setUpiUserOption("2");
          setPayType(100);
          if (
            walletBalance >= 1 &&
            finalPrice > walletBalance &&
            useWalletBalancePayFlag &&
            finalPrice - walletBalance >= 1
          ) {
            setPaymentType("WALLET");
            setSecondaryPayment("PAYSHARP");
          } else {
            setPaymentType("PAYSHARP");
            setSecondaryPayment("");
          }
        } else if (
          (!BlocketPayments.has(102) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            balanceToPay === 0 &&
            finalPrice - walletBalance >= 10 &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(102) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            balanceToPay >= 10 &&
            useWalletBalancePayFlag &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(102) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            useWalletBalancePayFlag === false &&
            !updatePaymentClick)
        ) {
          validPayment = "PAYSHARP";
          setPayment(paymentTypeWallet.PAYSHARP);
          setUpiUserOption("1");
          setPayType(200);
          if (
            walletBalance >= 1 &&
            finalPrice > walletBalance &&
            useWalletBalancePayFlag &&
            finalPrice - walletBalance >= 1
          ) {
            setPaymentType("WALLET");
            setSecondaryPayment("PAYSHARP");
          } else {
            setPaymentType("PAYSHARP");
            setSecondaryPayment("");
          }
        } else if (!BlocketPayments.has(200) && !updatePaymentClick) {
          validPayment = "RAZORPAY";
          setPayment(paymentTypeWallet.RAZORPAY);
          if (
            walletBalance >= 1 &&
            finalPrice > walletBalance &&
            useWalletBalancePayFlag &&
            finalPrice - walletBalance >= 1
          ) {
            setPaymentType("WALLET");
            setSecondaryPayment("RAZORPAY");
          } else {
            setPaymentType("RAZORPAY");
            setSecondaryPayment("");
          }
        } else if (
          (!BlocketPayments.has(103) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            balanceToPay === 0 &&
            finalPrice - walletBalance >= 10 &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(103) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            balanceToPay >= 10 &&
            useWalletBalancePayFlag &&
            !updatePaymentClick) ||
          (!BlocketPayments.has(103) &&
            !BlocketPayments.has(100) &&
            finalPrice >= 10 &&
            useWalletBalancePayFlag === false &&
            !updatePaymentClick)
        ) {
          validPayment = "PAYSHARP";
          setPayment(paymentTypeWallet.PAYSHARP);
          setUpiUserOption("3");
          setPayType(100);
          if (
            walletBalance >= 1 &&
            finalPrice > walletBalance &&
            useWalletBalancePayFlag &&
            finalPrice - walletBalance >= 1
          ) {
            setPaymentType("WALLET");
            setSecondaryPayment("PAYSHARP");
          } else {
            setPaymentType("PAYSHARP");
            setSecondaryPayment("");
          }
        }
      }
    }
  }, [
    checkoutDetails?.checkOutDetails?.final_price,
    amountEntered,
    useWalletBalancePayFlag,
  ]);

  // //useEffect for setting up an payment menthod in subscription
  React.useEffect(() => {
    let validPayment = "";
    CustomConsole(amountEntered + "checkout amountentered");
    if (
      (pageName && pageName === "subscribe") ||
      (pageName && pageName.includes("editSubscribe"))
    ) {
      if (
        !BlocketPayments.has(101) &&
        !BlocketPayments.has(100) &&
        window.innerWidth <= 600 &&
        checkoutDetails?.checkOutDetails?.final_price >= 10 &&
        amountEntered >= 10 &&
        !updatePaymentClick
      ) {
        validPayment = "PAYSHARP";
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("2");
        setPayType(100);
      } else if (
        !BlocketPayments.has(102) &&
        !BlocketPayments.has(100) &&
        checkoutDetails?.checkOutDetails?.final_price >= 10 &&
        amountEntered >= 10 &&
        !updatePaymentClick
      ) {
        validPayment = "PAYSHARP";
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("1");
        setPayType(200);
      } else if (!BlocketPayments.has(200) && !updatePaymentClick) {
        validPayment = "RAZORPAY";
        setPayment(paymentTypeWallet.RAZORPAY);
        setPaymentType("RAZORPAY");
      } else if (
        !BlocketPayments.has(103) &&
        !BlocketPayments.has(100) &&
        checkoutDetails?.checkOutDetails?.final_price >= 10 &&
        amountEntered >= 10 &&
        !updatePaymentClick
      ) {
        validPayment = "PAYSHARP";
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("3");
        setPayType(100);
      }
    }
  }, [amountEntered]);
  //useEffect for getting dates changed
  React.useEffect(() => {
    setSubscribeObject((prevState) => ({
      ...prevState,
      repeat_days: skuSubscribeOrderDetails?.selectedDays,
    }));
  }, [skuSubscribeOrderDetails?.selectedDays]);

  //useEffect for updating product final price
  React.useEffect(() => {
    if (
      checkoutDetails?.checkOutDetails?.discount > 0 &&
      !promoCodeFlag &&
      pageName &&
      !pageName.includes("editSubscribe") &&
      pageName !== "subscribe"
    ) {
      setOpenCouponAppliedDialog(true);
      setPromoCodeFlag(true);
    }
  }, [checkoutDetails]);

  //useeffect for lottie animation
  React.useEffect(() => {
    if (
      openCouponAppliedDialog &&
      pageName &&
      !pageName.includes("editSubscribe")
    ) {
      Lottie.loadAnimation({
        container: CouponAnimation.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: require("../../Assets/Animations/CouponCodeAnimation.json"),
      });
      // animate.setSpeed(0.8);
    }
  }, [openCouponAppliedDialog]);

  // //useeffect for hitting update api if data changes
  React.useEffect(() => {
    if (
      (pageName && pageName === "subscribe") ||
      (pageName && pageName.includes("editSubscribe"))
    ) {
      settextRating("Subscription");
    }
  }, []);

  //useeffect for scroling to bottom of page
  React.useEffect(() => {
    if (
      (pageName && pageName === "subscribe") ||
      (pageName && pageName.includes("editSubscribe"))
    ) {
      window.scrollTo(0, document.body.scrollHeight);
    }
  }, []);

  //useeffect for handling reorder details
  React.useEffect(() => {
    if (
      orderIdDetails?.allOrderData !== undefined &&
      orderIdDetails?.allOrderData?.order_id !== undefined &&
      orderIdDetails?.allOrderData?.order_id !== ""
    ) {
      setIsOrderPaymentPending(true);
    }
  }, [orderIdDetails?.allOrderData]);

  //Function for handling payment pending order dialogbox
  const handlePaymentPendingDialog = (data) => {};

  //useEffect to change state of selected payment flag based on multi payment
  React.useEffect(() => {
    const finalPrice = checkoutDetails?.checkOutDetails?.final_price || 0;
    const walletBalance = checkoutDetails?.checkOutDetails?.wallet_balance || 0;

    if (
      pageName === "booking" &&
      parseFloat(finalPrice) > walletBalance &&
      walletBalance >= 1 &&
      parseFloat(finalPrice) - walletBalance >= 1 &&
      !BlocketPayments.has(300) &&
      updatePaymentClick &&
      secondaryPayment === "" &&
      useWalletBalancePayFlag
    ) {
      setUpdatePaymentClick(false);
    }
  }, [checkoutDetails?.checkOutDetails?.final_price]);

  CustomConsole(checkoutDetails?.checkOutDetails);
  return (
    <>
      {/* {checkoutDetails?.loading === false ? (
        <> */}
      <Box
        sx={{
          marginTop: { xs: "3.6rem" },
          paddingRight: "5px",
          paddingBottom: "20px",
          paddingLeft: "5px",
          marginBottom: { sm: "1rem", xs: "8rem" },
        }}
      >
        <div
          ref={CouponAnimation}
          style={{ position: "absolute", top: 0, left: 0 }}
        />
        <Grid
          direction={{
            xs: "row",
            md: "row",
            sm: "row",
            position: "relative",
          }}
          justifyContent={{
            xs: "center",
            md: "center",
            sm: "center",
            lg: "space-between",
          }}
          alignItems={{
            xs: "center",
            md: "center",
            sm: "center",
            lg: "flex-start",
          }}
          container
          spacing={2}
        >
          <Grid item xs={12} lg={8}>
            {walletPayDone ? (
              <BookingSucessDiaplay textRating={textRating} />
            ) : (
              <CheckOutOverView
                primaryAddress={primaryAddress}
                setchooseAddressDialog={setchooseAddressDialog}
                setCurrentDate={setCurrentDate}
                currentDate={currentDate}
                products={products}
                setProducts={setProducts}
                setpromoCode={setpromoCode}
                promoCode={promoCode}
                setEnteredPayAmount={setEnteredPayAmount}
                minimumWalletAmount={minimumWalletAmount}
                setSubscribeObject={setSubscribeObject}
                samedayDelivery={samedayDelivery}
                setSameDayDelivery={setSameDayDelivery}
                deliveryCharges={deliveryCharges}
                invalidProducts={invalidProducts}
                setUpdatePaymentClick={setUpdatePaymentClick}
              />
            )}
          </Grid>
          {!isMobile && (
            <Grid item xs={12} md={10} lg={4}>
              <PriceBreakUpCardSkuWeb deliveryCharges={deliveryCharges} />
              {!walletPayDone && (
                <PaymentCardSkuWeb
                  setPaymentDialogOpen={setBookingPaymentFlag}
                  toggleDrawer={toggleDrawer}
                  setpaymentFlag={setpaymentFlag}
                  handelBookingFunction={handelBookingFunction}
                  paymentType={paymentType}
                  amountEntered={amountEntered}
                  skuSubscribeOrderDetails={skuSubscribeOrderDetails}
                  payType={payType}
                  upiUserOption={upiUserOption}
                  samedayDelivery={samedayDelivery}
                  isUpiIdValid={isUpiIdValid}
                  useWalletBalancePayFlag={useWalletBalancePayFlag}
                  secondaryPayment={secondaryPayment}
                  invalidProducts={invalidProducts}
                />
              )}
            </Grid>
          )}
        </Grid>
        {isMobile && !walletPayDone && (
          <MobileBookingFooterSku
            setPaymentDialogOpen={setBookingPaymentFlag}
            toggleDrawer={toggleDrawer}
            setpaymentFlag={setpaymentFlag}
            handelBookingFunction={handelBookingFunction}
            paymentType={paymentType}
            amountEntered={amountEntered}
            walletPayDone={walletPayDone}
            skuSubscribeOrderDetails={skuSubscribeOrderDetails}
            deliveryCharges={deliveryCharges}
            payType={payType}
            upiUserOption={upiUserOption}
            samedayDelivery={samedayDelivery}
            isUpiIdValid={isUpiIdValid}
            useWalletBalancePayFlag={useWalletBalancePayFlag}
            secondaryPayment={secondaryPayment}
            invalidProducts={invalidProducts}
          />
        )}
      </Box>
      <ChoosePaymentDialogSKU
        open={bookingpaymentFlag}
        pageName={pageName}
        setPaymentType={setPaymentType}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        myWalletInBooking={false}
        setpaymentFlag={setpaymentFlag}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setPerWeekPrice={amountEntered}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountEntered}
        setRazorPayAmount={amountEntered}
        setsubcribePaymentFlag={setBookingPaymentFlag}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        amountToAdd={amountEntered}
        setAmountToAdd={setEnteredPayAmount}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
        perWeekPrice={amountEntered}
        setUpiPay={setUpiPay}
        useWalletBalancePayFlag={useWalletBalancePayFlag}
        setUseWalletBalancePayFlag={setUseWalletBalancePayFlag}
        setSecondaryPayment={setSecondaryPayment}
        finalPrice={checkoutDetails?.checkOutDetails?.final_price}
        multiplePaymentFlag={true}
        setUpdatePaymentClick={setUpdatePaymentClick}
        updatePaymentClick={updatePaymentClick}
      />
      <PriceBreakupDialog
        toggleDrawer={toggleDrawer}
        open={openPriceBreakUpDialog}
        setOpen={setOpenPriceBreakUpDialog}
        deliveryCharges={deliveryCharges}
      />
      <MyWallet
        myWalletInBooking={false}
        orderId=""
        setpromoCode={setpromoCode}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        setpaymentFlag={setpaymentFlag}
        promoCode={dummyPromo}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountEntered}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        setAmountToAdd={setEnteredPayAmount}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
      />
      <Addresscard
        setAddress={setAddressDetailsID}
        setOpenEditDialog={setOpenEditDialog}
        openEditDialog={openEditDialog}
        setchooseAddressDialog={setchooseAddressDialog}
        chooseAdressDialog={chooseAdressDialog}
        addAdressDialog={addAdressDialog}
        address={addressDetailsID}
        primaryAddress={primaryAddress}
        setCurrentAddress={setPrimaryAddress}
        setAddAddressDialog={setAddAddressDialog}
      />
      {addAdressDialog && (
        <AddNewAddressDialogBox
          open={addAdressDialog}
          handleClose={handleAddAddressDialog}
          handleAddNewAddress={handleAddNewAddress}
          setAddAddressDialog={setAddAddressDialog}
          setchooseAddressDialog={setchooseAddressDialog}
        />
      )}
      <ToastMsgCotainer />
      {openHomeRoute && (
        <ExitBookingDialog
          open={openHomeRoute}
          onClose={setOpenHomeRoute}
          pageName={pageName}
        />
      )}
      {openCouponAppliedDialog && (
        <BookingCouponAppliedDialog
          open={openCouponAppliedDialog}
          onClose={setOpenCouponAppliedDialog}
          promoCode={promoCode}
          checkoutDetails={checkoutDetails}
        />
      )}
      {isOrderInPaymentPending && (
        <PendingOrderDialogBox
          open={isOrderInPaymentPending}
          handleClose={handlePaymentPendingDialog}
          paymentPendingDetails={orderIdDetails?.allOrderData?.order_id}
          setDialogClose={setIsOrderPaymentPending}
        />
      )}
    </>

    //   ) : (
    //     <Box
    //       sx={{
    //         height: "80vh",
    //         width: "100%",
    //         display: "flex",
    //         justifyContent: "center",
    //         alignItems: "center",
    //       }}
    //     >
    //       <img
    //         src={BiconLoader}
    //         alt="Loading..."
    //         style={{ height: "50px", width: "50px" }}
    //       />
    //     </Box>
    //   )}
    // </>
  );
}
