/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import closeIcon from "../../../Assets/newMobileIcon/Booking/closeIconWhite.svg";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogActions } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function NotificationInfoDialog(props) {
  //For closing dialog box
  const handleClose = () => {
    props.handelInfoDialogClose(false);
  };
  console.log("props")

console.log(props.openInfoDialog)
  return (
    <div>
      <Dialog
        open={props.openInfoDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: appColors.dialogBackGround }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Box sx={{ backgroundColor: appColors.infoCardColor }}>
          <Typography
            sx={{
              color: "#fff",
              margin: ".5rem",
              fontSize: "1.2rem",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {props.infoDialogTextHeading}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              margin: "1rem",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
            }}
          >
            {props.infoDialogTextMain}
          </Typography>
          <img
            alt=""
            src={closeIcon}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              height: "30px",
            }}
            onClick={handleClose}
          />
        </Box>
        <DialogActions
          sx={{
            bgcolor: appColors.infoCardColor,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={props.handelProceedAction}
            sx={{
              bgcolor: appColors.textColorNew,

              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            {props?.yesButtonText}
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: appColors.textColorNew,

              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            {props?.closeButtonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
