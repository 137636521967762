import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  returnCanData: [],
  replacementData: [],
  pageStatus: false,
};

const ReturnReplacemtCanReduser = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.GET_RETURN_CANORDER_HISTROY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        returnCanData: action.payload.data,
        pageStatus: action.payload.data.length < 10 ? true : false,
      };
    case actionType.GET_REPLACEMENT_CANORDER_HISTROY:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        replacementData: action.payload.data,
        pageStatus: action.payload.data.length < 10 ? true : false,
      };
    default:
      return state;
  }
};

export default ReturnReplacemtCanReduser;
