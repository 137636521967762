/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Inputs)
 * @date 01/10/24
 * @version 0.0.1 - Initial Release
 */

import "./textFieldStyles.css";
import { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function StyledInputField({
  text,
  placeholder,
  handelTextField,
  error,
  value,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div class="form__group" style={{ position: "relative" }}>
        <input
          type={showPassword ? "text" : text}
          class="form__input"
          onChange={handelTextField}
          id="name"
          placeholder={placeholder}
          required=""
          autocomplete="off"
          value={value}
        />

        {text === "password" && (
          <span
            onClick={togglePasswordVisibility}
            style={{
              position: "absolute",
              right: "10px",
              top: 13,
              transform: "translateY(-50%)",
              right:25,
              cursor: "pointer",
            }}
          >
            {showPassword ? <FaEyeSlash /> : <FaEye />}
          </span>
        )}
        {error && <span className="error-message">{error}</span>}
      </div>
    </>
  );
}
