/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Lebansty Valan
 * @description ProceedButton.jsx is a common proceed button used in the order booking page.
 * @date 06/06/23
 * @version 0.0.1 - Initial Release
 */
import React from "react";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import appColors from "../../Assets/AppTheme/appColors";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import appTheme from "../../Assets/AppTheme/appTheme";
function ProceedButton(props) {
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: appColors.textColorNew,
            width: "100%",
            fontWeight: "bold",
            fontSize:appTheme.fontSizeSmall,
            color: appColors.white,
            padding: "10px 20px",
            borderRadius: "0", // Set the border radius to 0 (no rounding)
            borderRadius: ".3rem",
            height: "30px",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <LoadingButton
        variant="contained"
        loading={checkoutDetails?.loading}
        size="small"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.totalFare > 0 ? `Pay ₹${props.totalFare}` : `${props.totalFare}`}
      </LoadingButton>
    </ThemeProvider>
  );
}

export default ProceedButton;
