// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  message: "",
  appMetaData: [],
  depositDetails: [],
  toggleState: false,
  forceAppTourData: {},
};

const appMetaDataReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(JSON.stringify(action));
  switch (action.type) {
    case actionType.APP_META_DATA:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        appMetaData: action.payload.app_data,
      };
    case actionType.GET_DEPOSIT_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        depositDetails: action.payload.data,
      };
    case actionType.RESET_TOGGLE_STATE:
      return {
        ...state,
        toggleState: !state.toggleState, // Toggle the state
      };
    case actionType.FORCE_APP_TOUR_INFO:
      return {
        ...state,
        error: action.payload,
        forceAppTourData: action.payload,
      };
    case actionType.SIGN_OUT:
      return {
        error: true,
        message: "",
        appMetaData: [],
        depositDetails: [],
        toggleState: false,
      };
    default:
      return state;
  }
};

export default appMetaDataReducer;
