/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * 
 */
import { Grid } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import FaqHeaderContent from "./FaqHeaders";
import FaqAnswerContent from "./FaqHeadersContent";
import { faqMainStyles } from "./FaqStyles";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { toggleState } from "../../Redux/actions/appMetaDataAction";
import {
    useHistory,
} from "react-router-dom";

export default function FaqMainComponent() {
    const history = useHistory();
    const appMetaDataReducer = useSelector((state) => state.appMetaDataDetails);
    const dispatch = useDispatch();
    const [bookingText, setBookingText] = React.useState("CONTACT");
    const [showText, setShowText] = React.useState(false);

    const handelShowText = () => {
        setShowText(true);
        dispatch(toggleState());
    }

    React.useEffect(() => {
        if (appMetaDataReducer.toggleState===false) {
            setShowText(false);
        }
    }, [appMetaDataReducer.toggleState]);

    return (
        <>
            <Grid container sx={{ marginTop: "4rem",marginBottom:{xs:"4rem",lg:0} }}>
                {/* <Grid item xs={12} sx={faqMainStyles.textHeading} onClick={handelShowText}>
                    SUPPORT - FAQ - {bookingText}
                </Grid>
                <Grid item xs={12} sx={faqMainStyles.textHeadingFaq}>
                    Frequently Asked Questions
                </Grid> */}
                <Grid container spacing={2} sx={{ marginTop: ".1rem", justifyContent: "center" }} >
                    {!showText && window.innerWidth <= 600 || window.innerWidth > 600 ?
                        <Grid item md={12} lg={4} >
                            <FaqHeaderContent
                                bookingText={bookingText}
                                setBookingText={setBookingText}
                                setShowText={setShowText}
                                showText={showText}
                            />
                        </Grid> : null}
                    {showText && window.innerWidth <= 600 || window.innerWidth > 600 ?
                        <Grid item md={12} lg={8} >
                            <FaqAnswerContent
                                bookingText={bookingText}
                                setBookingText={setBookingText}
                                setShowText={setShowText}
                                showText={showText}
                            />
                        </Grid> : null}
                </Grid>
            </Grid>
        </>
    )
}