import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  message: "",
  isPaymentUpdated: false,
  upiStatusData: {},
  upiStatusError: false,
};

const UPIOrderStatusReducer = (state = initialState, action) => {
  // console.log(action);
  switch (action.type) {
    case actionType.PAY_BY_UPI_ORDER_STATUS:
      // console.log(action.payload);
      return {
        ...state,
        message: action.payload.data,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.UPI_ORDER_STATUS:
      // console.log(action.payload);
      return {
        ...state,
        upiStatusError: action.payload.data.error,
        upiStatusData: action.payload.data,
      };
    case actionType.RESET_COMPONENT_STATE:
      return {
        error: true,
        message: "",
        isPaymentUpdated: false,
        upiStatusData: {},
        upiStatusError: false,
      };
    default:
      return state;
  }
};

export default UPIOrderStatusReducer;
