// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
    themeState: false,
};

const darkThemeReduser = (state = initialState, action) => {
    // console.log("-------User Details--------");
    // console.log(JSON.stringify(action));
    switch (action.type) {
        case actionType.RESET_DARK_THEME:
            return {
                ...state,
                themeState: action.payload.data, // Toggle the state
            }
        default:
            return state;
    }

};


export default darkThemeReduser;
