/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ProceedButton.jsx is a common proceed button used in the order booking page.
 * @date 06/06/23
 * @version 0.0.1 - Initial Release
 */
import React from "react";
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MailIcon from "@mui/icons-material/Mail";
import appTheme from "../../Assets/AppTheme/appTheme";
function SendInvoice(props) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: "rgba(21, 32, 87, 0.95)",
            width: "100px",
            padding: "10px 20px",
            marginLeft: ".5rem",
            marginRight: ".5rem",
            borderRadius: "0", // Set the border radius to 0 (no rounding)
            borderRadius: "15px",
            height: "25px",
            "&: hover": {
              backgroundColor: "#2FEAC5 ",
            },
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Button
        variant="contained"
        startIcon={<MailIcon />}
        onClick={props.handleSendInvoice}
        disabled={props.disabled}
      >
        <span style={{ fontSize: appTheme.fontSizeSmall }}>Invoice</span>
      </Button>
    </ThemeProvider>
  );
}

export default SendInvoice;
