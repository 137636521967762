/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import { collapseClasses } from "@mui/material";

//Get all the Available Products Based on Address Orders
export const SubscribeOrderAction = (reqObj) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`OM/subscriptions/create`, reqObj);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_SUBSCRIBE_PLACEORDER,
          payload: { data: response.data, statusFlag: true },
        });

        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
      } else {
        dispatch({
          type: actionType.SKU_SUBSCRIBE_PLACEORDER,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.SKU_SUBSCRIBE_PLACEORDER,
        payload: { data: error.response.data, statusFlag: true },
      });
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
    }
  };
};

//function for adding selected address array
export const SetSelectedDaysSubscribe = (selectedDays) => ({
  type: actionType.SET_SELECTED_DAYS_SUBSCRIBE,
  payload: { data: selectedDays, statusFlag: false },
});

//function for Getting subscription details
export const GetSubscriptinOrderDetailsSku = () => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/OM/subscriptions/view`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_SUBSCRIBE_ORDERDETAILS,
          payload: { data: response.data, statusFlag: true },
        });
      } else {
        dispatch({
          type: actionType.SKU_SUBSCRIBE_ORDERDETAILS,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.SKU_SUBSCRIBE_ORDERDETAILS,
        payload: { data: error.response.data, statusFlag: true },
      });
    }
  };
};

//function for updating subscription details
export const UpdateSubscriptionOrder = (id, details) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`/OM/subscriptions/edit/${id}`, details);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
          payload: { data: response.data, statusFlag: true },
        });
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
        dispatch(GetSubscriptinOrderDetailsSku());
      } else {
        dispatch({
          type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
        payload: { data: error.response.data, statusFlag: true },
      });
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
    }
  };
};

//function for updating subscription details
export const StopPauseSubscriptionOrder = (details) => {
  // console.log("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.put(`OM/subscriptions/status`, details);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
          payload: { data: response.data, statusFlag: true },
        });
        dispatch(GetSubscriptinOrderDetailsSku());
        toast.success(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        dispatch({
          type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
          payload: { data: response.data, statusFlag: true },
        });
      }
    } catch (error) {
      dispatch({
        type: actionType.SKU_UPDATE_SUBSCRIBEORDER,
        payload: { data: error.response.data, statusFlag: true },
      });
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
