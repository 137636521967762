/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

export const styles = {
  settingsMain: {
    marginTop: "60px",
    padding: "10px",
    width:"100%",
    marginBottom:{lg:0,md:0,sm:0,xs:"4rem"}
    // backgroundColor: "#2D81FF"
  },
  tabAlign: {
    // display : 'flex', justifyContent : 'center', alignItems : 'center'
  },
  mainTab: {
    width: "100%",
    padding: "5px",
    backgroundColor: "#2D81FF",
  },
  indicator: {
    display: "none",
  },
  nonActive: {
    backgroundColor: "#2D81FF",
    color: "blue",
  },
  active: {
    backgroundColor: "#2D81FF",
    color: "#000",
  },
};
