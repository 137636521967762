/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as Material from "@mui/material";
import React from "react";
import { styles } from "./myAddressBookStyle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Alert from "@mui/material/Alert";
import AddressBookCard from "../../../../Components/Cards/Settings/addressBook/addressBookCard";
import { Typography, Box } from "@mui/material";
import { AddNewAddressDialogBox } from "./DialogBoxes";
import { EditAddressDialogBox } from "./EditDialogBox";
import NewAddressBook from "../../../../Components/Cards/Settings/addressBook/newAddressBook";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  AddNewAdress,
  DeleteUserAddress,
  EditAdress,
  GetUserAdressBook,
} from "../../../../Redux/actions/settingsAction";
import { useDispatch, useSelector } from "react-redux";
import appColors from "../../../../Assets/AppTheme/appColors";

function AddressBook(props) {
  const dispatch = useDispatch();
  const userAddressList = useSelector((state) => state.settings);
  const [open, setOpen] = React.useState(false);
  const [openDia, setOpenDia] = React.useState(false);
  const [addressId, setAddressId] = React.useState("");
  // const [isLoading, setIsLoading] = React.useState();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [address, setAddress] = React.useState({});
  React.useEffect(() => {
    if (
      userAddressList?.addresses !== undefined &&
      userAddressList?.addresses !== null &&
      userAddressList?.addresses?.length > 0
    )
      dispatch(GetUserAdressBook());
  }, []);
  React.useEffect(() => {
    if (userAddressList.isAddressDeleted || userAddressList.isAddressAdded) {
      userAddressList.isAddressDeleted = false;
      userAddressList.isAddressAdded = false;
      handleClose();
      handleCloseEditDialog();
    }
  }, [userAddressList]);
  const handleOpenDeleteDia = (addressId) => {
    // console.log("Address ID: " + addressId);
    setOpenDia(true);
    setAddressId(addressId);
  };
  const handleCloseDia = () => {
    setOpenDia(false);
  };
  const handleOpenEditDia = (address) => {
    // console.log(
    //   "handleOpenEditDia ->AddressDetails: " + JSON.stringify(address)
    // );
    setAddress(address);
    handleClickOpenEditDialog();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleClickOpenEditDialog = () => {
    setOpenEditDialog(true);
    props.setAvailable(false);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleDeleteAddress = () => {
    if (addressId !== "") {
      dispatch(DeleteUserAddress(addressId));
      setOpenDia(false);
    }
  };
  const handleAddNewAddress = (details, isPrimary) => {
    // console.log("Update Address Details: " + JSON.stringify(details));
    // console.log("Is Primary: " + isPrimary);
    dispatch(AddNewAdress(details, isPrimary));
  };
  const handleUpdateAddressDetails = (details) => {
    // console.log("Edit Address Details: " + JSON.stringify(details));
    // console.log("Is Primary: " + isPrimary);
    dispatch(EditAdress(details));
  };
  return (
    <>
      {window.innerWidth > 600 ? (
        <Box>
          <Material.Button
            onClick={handleClickOpen}
            sx={styles.addBtnAddress}
            startIcon={<AddCircleIcon />}
            size="small"
            variant="contained"
          >
            Add New Address
          </Material.Button>
        </Box>
      ) : (
        ""
      )}
      <Material.Box sx={styles.addressBookMain}>
        <Material.Box sx={styles.headerPapper}>
          {userAddressList.addresses.map((data, key) => {
            return (
              <NewAddressBook
                key={key}
                addresses={data}
                handleOpenDeleteDia={handleOpenDeleteDia}
                handleOpenEditDia={handleOpenEditDia}
              />
            );
          })}

          {window.innerWidth <= 600 ? (
            <Box
              sx={{
                position: "fixed",
                bottom: 60,
                width: "100%",
                bgcolor: appColors.cardColor,
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Material.Button
                onClick={handleClickOpen}
                sx={styles.addBtnAddress}
                startIcon={<AddCircleIcon />}
                size="small"
                variant="contained"
              >
                Add New Address
              </Material.Button>
            </Box>
          ) : (
            ""
          )}
          {!userAddressList.addresses.length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {" "}
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography sx={{ color: appColors.darkGray }}>
                Please Add Address for Booking Process.!
              </Typography>
            </Box>
          ) : (
            ""
          )}
        </Material.Box>
        <AddNewAddressDialogBox
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleAddNewAddress={handleAddNewAddress}
        />
        {openEditDialog && (
          <EditAddressDialogBox
            open={openEditDialog}
            data={address}
            handleClose={handleCloseEditDialog}
            handleUpdateAddressDetails={handleUpdateAddressDetails}
          />
        )}
        {/* Address Delete COnfirmation */}
        <Material.Dialog
          open={openDia}
          onClose={handleCloseDia}
          aria-labelledby="responsive-dialog-title"
          PaperProps={{
            sx: {
              borderRadius: "20px", // Set border radius for curved effect
            },
          }}
        >
          <Material.DialogTitle
            sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
            id="responsive-dialog-title"
          >
            <Material.Typography
              sx={{
                fontSize: { xs: "20px", sm: "20px", md: "25px" },
                fontWeight: "500",
                color: appColors.textColorNew,
              }}
            >
              {"Are you Sure?"}
            </Material.Typography>
          </Material.DialogTitle>
          <Material.DialogContent sx={{ bgcolor: appColors.secondaryCard }}>
            <Material.DialogContentText sx={{ color: appColors.darkGray }}>
              Do you really want to delete this Address? This cannot be
              restored.
            </Material.DialogContentText>
          </Material.DialogContent>
          <Material.DialogActions
            sx={{
              bgcolor: appColors.secondaryCard,
              display: "flex",
              justifyContent: "space-evenly",
            }}
          >
            <Material.Button
              sx={{
                width: "20%",
                bgcolor: appColors.Failed,
              }}
              autoFocus
              variant="contained"
              size="small"
              onClick={handleDeleteAddress}
            >
              Delete
            </Material.Button>
            <Material.Button
              sx={{
                width: "20%",
                backgroundColor: appColors.textColorNew,
              }}
              size="small"
              onClick={handleCloseDia}
              variant="contained"
              autoFocus
            >
              Cancel
            </Material.Button>
          </Material.DialogActions>
        </Material.Dialog>
      </Material.Box>
    </>
  );
}

export default AddressBook;
