/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 */
import { useState, useEffect } from "react";
import { url } from "../../Redux/apis";
import useAxiosWithoutLoading from "../../Hooks/oldAxios";
import { useDispatch } from "react-redux";
import { StoreImageFiles } from "../../Redux/actions/getFilesActions";
import { useSelector } from "react-redux";

const useProductImages = (products, pageName) => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const api = useAxiosWithoutLoading();
  const dispatch = useDispatch();
  const imageFilesstore = useSelector(
    (state) => state.getFilesDownload.appImages
  );
  console.log("getFilesDownload........................");
  console.log(products);
  useEffect(() => {
    if (products?.length > 0) {
      const fetchImages = async () => {
        try {
          // Use Promise.all to fetch and store images in parallel
          const imagePromises = products.map(async (product) => {
            console.log("product.............");
            console.log(product);
            let filename = "";
            if (pageName === "booking") {
              if (product.image !== null) {
                filename = product.image;
              } else {
                return true;
              }
            } else if (pageName === "deposit") {
              if (
                product?.more_details?.image !== undefined &&
                product?.more_details?.image !== null
              ) {
                filename = product?.more_details?.image;
              }
            } else if (pageName === "giftcard") {
              if (product !== undefined && product !== null) {
                console.log("product.............");
                console.log(product);
                filename = product;
              }
            }

            // Check if the image is already available in local storage
            const storedImage = imageFilesstore[`image_${filename}`];

            if (!storedImage) {
              let apiUrl = `${url}/SSM/files/${filename}`;
              if (pageName === "giftcard") {
                apiUrl += "?isGiftCard=1";
              }
              // Fetch the image from the API
              const imageResponse = await api.get(apiUrl, {
                responseType: "blob", // Use blob to handle binary data
              });

              // Convert the image blob to a base64-encoded string
              const reader = new FileReader();
              reader.readAsDataURL(imageResponse.data);

              // Update the local storage with the base64-encoded string
              reader.onloadend = () => {
                const base64Image = reader.result;
                dispatch(StoreImageFiles(`image_${filename}`, base64Image));
              };

              return { [filename]: null }; // Placeholder until base64 is ready
            }

            return { [filename]: storedImage };
          });

          // Wait for all image promises to resolve
          await Promise.all(imagePromises);
          setIsLoading(false);
        } catch (error) {
          console.error("Error fetching product images:", error);
          setError(error);
          setIsLoading(false);
        }
      };

      fetchImages();
    }
  }, [products]);

  return { isLoading, error };
};

export default useProductImages;
