/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
// import actionTypes from "./../actio /actionTypes";
import actionType from "./../actions/actionTypes";
const initialState = {
  error: true,
  serviceAreaList: [],
  isAvailable: false, //If Serv ice Areas Are found then Only this will be true
};

const pdwAvailabilityReducer = (state = initialState, action) => {
  // console.log(action.type);
  switch (action.type) {
    case actionType.SEARCH_PDW_AVAILABILITY:
      return {
        ...state,
        error: action.payload.error,
        serviceAreaList: action.payload.data.length
          ? action.payload.data.map((data) => data.Name)
          : [],
        isAvailable:
          !action.payload.error && action.payload.data.length > 0
            ? true
            : false,
      };
    default:
      return state;
  }
};

export default pdwAvailabilityReducer;
