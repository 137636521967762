/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 *  @author Gowtham Prasath
 */

import * as React from "react";
import Dialog from "@mui/material/Dialog";
import List from "@mui/material/List";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import MapContainer from "../../Utils/googleMaps";
import { AddEditAddressDialogBox } from "./addAndEditAddressDialog";
import appColors from "../../../Assets/AppTheme/appColors";
import { DialogContent } from "@mui/material";
import MapContainerOl from "../../Utils/olmaps";
import CustomConsole from "../../../coustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GoogleMapsDialog({
  open,
  setOpen,
  setOpenAddress,
  editAddressData,
  addressType,
}) {
  const [selectedAddress, setSlectedAddress] = React.useState([]);
  const [openAddEditAddress, setOpenAddEditAddress] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  CustomConsole(editAddressData+"editAddressDataGoogle");

  //function to close addedit address dialog
  const handleCloseAddEditAddress = () => {
    setOpenAddEditAddress(false);
  };

  CustomConsole(addressType + " " + "addressType");
  CustomConsole(open + "open");
  // React.useEffect(() => {
  //   CustomConsole(addressType);

  // }, [addressType]);

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            bgcolor: appColors.homePageCard,
            height: "3rem",
          }}
        >
          <Toolbar sx={{ height: "3rem" }}>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon sx={{ color: appColors.headerTextColor }} />
            </IconButton>
            <Typography
              sx={{ ml: 1, flex: 1, color: appColors.headerTextColor }}
              variant="h6"
              component="div"
            >
              Select Address
            </Typography>
          </Toolbar>
        </AppBar>
        <DialogContent
          sx={{
            background: appColors.homePageCard,
            padding: 0,
            // borderRadius:"1rem"
          }}
        >
          <List sx={{ padding: 0 }}>
            <MapContainer
              handleClose={handleClose}
              setSlectedAddress={setSlectedAddress}
              setOpenAddress={setOpenAddress}
              setOpenAddAddress={setOpenAddEditAddress}
              editAddressData={editAddressData}
              addressType={addressType}
            />

            {/* <MapContainerOl
              handleClose={handleClose}
              setSlectedAddress={setSlectedAddress}
              setOpenAddress={setOpenAddress}
              setOpenAddAddress={setOpenAddEditAddress}
              editAddressData={editAddressData}
              addressType={addressType}
            /> */}

            {openAddEditAddress && (
              <AddEditAddressDialogBox
                open={openAddEditAddress}
                handleClose={handleCloseAddEditAddress}
                selectedAddress={selectedAddress}
                editAddressData={editAddressData}
                addressModule={addressType}
                setOpen={setOpen}
              />
            )}
          </List>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}
