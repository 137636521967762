/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Button Field (Buuton Field)
 * @date 02/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { Button } from "@mui/material";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

function PrimarySkuButton(props) {
  return (
    <Button
      size="small"
      sx={[styles.btnStyle, { backgroundColor:props.color }]}
      onClick={props.handleButtonClick}
    >
      {props.btnName}
    </Button>
  );
}

export default PrimarySkuButton;

const styles = {
  btnStyle: {
    color: appColors.white,
    textTransform: "none",
    fontSize: appTheme.buttonTextSize,
    "&:hover": { backgroundColor: appColors.blue },
  },
};
