/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import appColors from "../../../Assets/AppTheme/appColors";
import PromocodesNewDialog from "../../DialogBoxes/PdwBookingSummary/PromocodesNewDialog";
import {
  ApplyPromocode,
  GetPromoCodes,
} from "../../../Redux/actions/promocodesAction";
import { useDispatch, useSelector } from "react-redux";
import WaterDropApplyButton from "../../SkuButtons/waterDropButton";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import appTheme from "../../../Assets/AppTheme/appTheme";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';


export default function PromoSelectionCard({
  promoCode,
  setpromoCode,
  setProducts,
  products,
}) {
  const dispatch = useDispatch();
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const loading = useSelector((state) => state.checkOutDetails.loading);
  const promocodeData = useSelector((state) => state.promocodeDetails);
  const [isRemoved, setIsRemoved] = React.useState(false);
  const [promoId, setPromoId] = React.useState("");
  const [promoApplied, setPromoApplied] = React.useState(false);
  const [waterDropFlag, setWaterDropFlag] = React.useState(false);
  const [handelPromoFlag, setHandePromoFlag] = React.useState(false);
  const [promoFlag, setPromoFlag] = React.useState(false);
  const [promoSuccess, setPromoSuccess] = React.useState(false);
  const [showWaterDrop, setShowWaterDrops] = React.useState(false);
  const [openPromoDialog, setOpenPromoDialog] = React.useState(false);
  const [waterDrops, setWaterDrops] = React.useState(0);
  const [maxWaterDrops, setMaxWaterDrops] = React.useState(0);
  const iconList = IconListManager();

  //function for promo dialog opeaning
  const handelOpenPromo = () => {
    if (!checkoutDetails?.loading) {
      setOpenPromoDialog(true);
    }
  };
  //Function for getting promo codes details
  React.useEffect(() => {
    if (openPromoDialog) {
      const promoLabel = "";
      const reqObj = {
        final_water_price: checkOutDetails?.order_price,
        can_count: checkOutDetails?.valid_products?.length,
        is_wallet: false,
      };
      dispatch(GetPromoCodes(reqObj, promoLabel));
    }
  }, []);
  //function for searching promo code
  const searchPromoCodes = (promoLabel) => {
    const reqObj = {
      final_water_price: checkOutDetails?.order_price,
      can_count: checkOutDetails?.valid_products?.length,
      is_wallet: false,
    };
    dispatch(GetPromoCodes(reqObj, promoLabel));
  };

  //Function for setting up a promo code details in state
  React.useEffect(() => {
    if (promoCode !== "" && promocodeData.applyError === false) {
      setPromoSuccess(true);
      setOpenPromoDialog(false);
      promocodeData.applyError = true;
    }
  }, [promocodeData]);

  //function for removing promo code if remove promo button clicked
  const removePromo = () => {
    if (!checkoutDetails?.loading) {
      setPromoFlag(false);
      // dispatch(ApplyPromocode(reqObj, promoId));
      setPromoApplied(true);
      setPromoSuccess(false);
      setOpenPromoDialog(false);
      setHandePromoFlag(true);
      setpromoCode("");
    }
  };

  //function to handel check box click
  const handelClick = (e) => {
    if (!checkoutDetails?.loading) {
      setShowWaterDrops(e.target.checked);
      if (e.target.checked) {
        setProducts((prev) => ({
          ...prev,
          is_auto_apply_water_drops: e.target.checked,
          water_drops: 0,
        }));
      } else if (!e.target.checked) {
        setProducts((prev) => ({
          ...prev,
          is_auto_apply_water_drops: e.target.checked,
          water_drops: 0,
        }));
      }
    }
  };

  //useeffect for getting maxapply water drops
  React.useEffect(() => {
    if (
      checkOutDetails?.water_drop_details?.water_drops !== undefined &&
      checkOutDetails?.water_drop_details?.is_valid
    ) {
      setWaterDrops(checkOutDetails?.water_drop_details?.water_drops);
    } else if (checkOutDetails?.water_drop_details?.is_valid) {
      setWaterDrops(checkOutDetails?.water_drop_details?.max_applicable);
    }
  }, [checkOutDetails]);

  //useeffect for getting maxapply water drops
  React.useEffect(() => {
    if (
      checkOutDetails?.water_drop_details?.max_applicable !== undefined &&
      checkOutDetails?.water_drop_details?.is_valid
    ) {
      setMaxWaterDrops(checkOutDetails?.water_drop_details?.max_applicable);
    } else if (!checkOutDetails?.water_drop_details?.is_valid) {
      setMaxWaterDrops(0);
      setWaterDropFlag(false);
      if (products.is_auto_apply_water_drops) {
        setProducts((prev) => ({
          ...prev,
          water_drops: 0,
          is_auto_apply_water_drops: false,
        }));
      }
    }
  }, [checkOutDetails]);

  //function to reduse water drops
  const handelAddWaterDrops = () => {
    if (waterDrops < maxWaterDrops && !loading) {
      setWaterDrops(waterDrops + 1);
      setProducts((prev) => ({
        ...prev,
        water_drops: waterDrops + 1,
        is_auto_apply_water_drops: false,
      }));
    } else if (!loading) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Maximum You can use ${maxWaterDrops} water drops for this order`,
        })
      );
    }
  };

  //function to reduse water drops
  const handelReduseWaterDrops = () => {
    if (waterDrops > 0 && !loading) {
      setWaterDrops(waterDrops - 1);
      setProducts((prev) => ({
        ...prev,
        water_drops: waterDrops - 1,
        is_auto_apply_water_drops: false,
      }));
    }
  };

  //React.useEffect to apply water drops
  React.useEffect(() => {
    if (
      checkOutDetails?.water_drop_details?.water_drops > 0 &&
      checkOutDetails?.water_drop_details?.is_valid === true
    ) {
      setShowWaterDrops(true);
    } else if (!checkOutDetails?.water_drop_details?.is_valid) {
      setShowWaterDrops(false);
    }
  }, [checkOutDetails]);

  //useEffect for setting promo code
  React.useEffect(() => {
    if (checkOutDetails?.coupon_code_details?.is_valid) {
      setPromoSuccess(checkOutDetails?.coupon_code_details?.is_valid);
    }
  }, [checkOutDetails]);
  return (
    <>
      <Typography sx={newBookingstyles.cardHeaderStyle}>
        Coupons & Discounts
      </Typography>
      <Card sx={newBookingstyles.cardStylesCoupon}>
        <Box sx={newBookingstyles.orderBox}>
          <Typography sx={newBookingstyles.BoxHeader}>
            <Stack direction={{ xs: "column" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img
                  alt=""
                  src={iconList.couponIcon}
                  style={{ marginRight: ".5rem" }}
                  onClick={handelOpenPromo}
                />
                <Box
                  onClick={handelOpenPromo}
                  sx={[
                    newBookingstyles.promoBoxStyles,
                    {
                      justifyContent: true ? "space-between" : "center",
                    },
                  ]}
                >
                  {promoCode !== "" && promoSuccess ? (
                    <Typography
                      sx={{
                        fontSize: appTheme.fontSizeSmall,
                        fontWeight: "600",
                        textAlign: "center",
                        color: appColors.darkGray,
                        width: {
                          xs: `${window.innerWidth / 2.8}px`,
                          sm: "100%",
                        } /* Set the width to 80% */,
                        whiteSpace: {
                          sm: "wrap",
                          xs: "nowrap",
                        } /* Prevent wrapping */,
                        // overflow: "hidden" /* Hide overflowing text */,
                        textOverflow:
                          "ellipsis" /* Display ellipsis when text overflows */,
                      }}
                      noWrap
                    >
                      <>{promoCode}</>
                    </Typography>
                  ) : (
                    <>
                      <Typography
                        sx={{
                          fontSize: appTheme.fontSizeMedium,
                          fontWeight: "600",
                          textAlign: "center",
                          color: appColors.darkGray,
                        }}
                        noWrap
                      >
                        Apply Coupon Code{" "}
                        <span>
                          <KeyboardArrowRightIcon
                            sx={{
                              color: appColors.textColorNew,
                              fontSize: "2rem",
                            }}
                          />
                        </span>
                      </Typography>
                    </>
                  )}
                </Box>
                {promoCode !== "" && promoSuccess ? (
                  <Button
                    size="small"
                    sx={{ color: appColors.textColorNew }}
                    onClick={removePromo}
                  >
                    Remove
                  </Button>
                ) : null}
              </Box>
              {checkOutDetails?.water_drop_details?.total_existing > 0 ? (
                <Box sx={{ width: "100%" }}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "3rem",
                      borderRadius: "5px",
                      display: "flex",
                      alignContent: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "0.95em",
                        fontWeight: "500",
                        textAlign: "left",
                        color: appColors.darkGray,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Checkbox
                        size="small"
                        onClick={handelClick}
                        checked={showWaterDrop}
                      />
                      {/* {useWaterDrop && promocodeData?.promoDiscount
                        ? `₹${promocodeData?.promoDiscount} Worth Water Drops Applied`
                        : "Redeem  WaterDrops"} */}
                      Redeem Water Drops
                    </Typography>
                    {showWaterDrop && (
                      <Box>
                        <WaterDropApplyButton
                          quantity={waterDrops}
                          handelAddQuantity={handelAddWaterDrops}
                          handelReduseQuantity={handelReduseWaterDrops}
                        />
                      </Box>
                    )}
                  </Box>
                  {/* {showWaterDrop && (
                    <Box>
                      <Typography
                        sx={{ fontSize: ".75rem", color: appColors.darkGray }}
                      >
                        *Available Water Drops{" "}
                        {checkOutDetails?.water_drop_details?.total_existing},
                        You can use upto {maxWaterDrops}
                      </Typography>
                    </Box>
                  )} */}
                </Box>
              ) : null}
            </Stack>
          </Typography>
        </Box>
        {openPromoDialog && (
          <PromocodesNewDialog
            openPromoDialog={openPromoDialog}
            setHandePromoFlag={setHandePromoFlag}
            setOpenPromoDialog={setOpenPromoDialog}
            promoFlag={promoFlag}
            setPromoFlag={setPromoFlag}
            setPromoApplied={setPromoApplied}
            setPromoId={setPromoId}
            searchPromoCodes={searchPromoCodes}
            setpromoCode={setpromoCode}
          />
        )}
      </Card>
    </>
  );
}
