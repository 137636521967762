// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: SubscriptionDateCard.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState } from "react";
import { Card, Typography, Box, Button } from "@mui/material"; // Import MUI components
import IconListManager from "../../../Assets/AppTheme/appIcons";
import appColors from "../../../Assets/AppTheme/appColors";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { SetSelectedDaysSubscribe } from "../../../Redux/actions/skuSubscribeAction";
import CustomConsole from "../../../coustomConsole";
import { useLocation } from "react-router-dom/cjs/react-router-dom";

function SubscriptionSchedule({ selectedDays, setSelectedDays }) {
  const iconList = IconListManager();
  const { pathname } = useLocation();
  const pageTitleSideBar = pathname.split("/dashboard/");
  const dispatch = useDispatch();
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  const [nextDeliveryDate, setNextDeliveryDate] = React.useState(null);
  const [daysofDelivery, setDelivery] = useState([
    "SUN",
    "MON",
    "TUE",
    "WED",
    "THU",
    "FRI",
    "SAT",
  ]);

  CustomConsole(selectedDays);

  //Function for handling selected days
  const handleScheduleClick = (dayIndex) => {
    // Check if the day is already selected
    if (selectedDays.includes(dayIndex)) {
      // If selected, remove it from the array
      setSelectedDays(selectedDays.filter((index) => index !== dayIndex));
    } else {
      // If not selected, add it to the array
      setSelectedDays([...selectedDays, dayIndex]);
    }
  };

  //function for handling next delivery date

  React.useEffect(() => {
    const sortedArray = selectedDays.sort((a, b) => a - b);
    const currentDay = moment().day();
    CustomConsole(sortedArray[0] - currentDay);
    let graterDates = sortedArray.filter((day) => day > currentDay);
    let smallerDates = sortedArray.filter((day) => day < currentDay);
    let equalDates = sortedArray.filter((day) => day === currentDay);
    let finalArray = [...graterDates, ...smallerDates, ...equalDates];
    CustomConsole(finalArray);
    let diff = finalArray[0] - currentDay;

    if (diff < 0) {
      // If the first day in the sorted array is earlier in the week than today,
      // add 7 days to get the next occurrence.
      diff += 7;
    }
    if (diff === 0) {
      diff = 7;
    }
    if (diff > 0) {
      diff = diff;
    }
    CustomConsole(diff);
    setNextDeliveryDate(moment().add(diff, "days").format("DD MMM YYYY"));
  }, [selectedDays.length]);

  //Useeffect for setting default day selected
  React.useEffect(() => {
    const dayOfWeek = moment().day();
    CustomConsole(dayOfWeek);
    if (dayOfWeek === 6) {
      handleScheduleClick(0);
    } else {
      handleScheduleClick(dayOfWeek + 1);
    }
  }, []);

  //useEffect to update selected date array
  React.useEffect(() => {
    dispatch(SetSelectedDaysSubscribe(selectedDays));
  }, [selectedDays.length]);

  //useEffect to update selected date array
  React.useEffect(() => {
    if (skuSubscribeOrderDetails.selectedDays.length > 0) {
      setSelectedDays(skuSubscribeOrderDetails.selectedDays);
    }
  }, []);

  CustomConsole(skuSubscribeOrderDetails);
  return (
    <>
      <Typography sx={newBookingstyles.cardHeaderStyle}>
        Delivery Schedule
      </Typography>
      <Card
        sx={{
          width: "100%",
          boxShadow: "4",
          display: "flex",
          flexDirection: "column",
          backgroundColor: appColors.cardColor, // Replace with your actual color value
          borderRadius: ".7rem",
        }}
      >
        <Typography
          sx={{
            fontSize: "14px",
            color: appColors.headerTextColor, // Replace with your actual color value
            padding: ".5rem",
          }}
        >
          <img alt="" src={iconList.CalenderIcon} /> Select days for delivery
        </Typography>
        <Box
          sx={{
            display: "flex",
            marginBottom: ".5rem",
            flexDirection: "row",
            width: "100%",
            flexWrap: "wrap",
            gap: "1rem",
            padding: ".5rem",
          }}
        >
          {daysofDelivery.map((day, index) => (
            <Button
              key={index}
              size="small"
              variant="outlined"
              onClick={() => handleScheduleClick(index)}
              sx={{
                height: "25px",
                backgroundColor: selectedDays.includes(index)
                  ? appColors.textColorNew
                  : appColors.lightblue, // Replace with your actual color values
                color: selectedDays.includes(index)
                  ? appColors.white
                  : appColors.secondaryColor, // Replace with your actual color values
                "&:hover": {
                  backgroundColor: selectedDays.includes(index)
                    ? appColors.textColorNew
                    : appColors.lightblue,
                  color: selectedDays.includes(index)
                    ? appColors.white
                    : appColors.secondaryColor,
                },
              }}
            >
              {day}
            </Button>
          ))}
        </Box>
      </Card>
      {selectedDays.length > 0 && pageTitleSideBar[1] !== "checkout" ? (
        <Box sx={{ margin: "1rem 1rem 0 1rem" }}>
          <Typography
            sx={{
              fontSize: ".8rem",
              color: appColors.darkGray,
            }}
          >
            <i>
              <span style={{ color: "red" }}>*</span>Your subscription starts
              from &nbsp;{nextDeliveryDate}
            </i>
          </Typography>
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default SubscriptionSchedule;
