import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Box,
} from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";

const LogoutConfirmationDialog = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Box sx={{ background: appColors.secondaryCard }}>
        <DialogTitle
          sx={{
            color: appColors.secondaryText,
            fontWeight: "bold",
            alignItems: "center",
          }}
        >
          Logout Confirmation
        </DialogTitle>
        <DialogContent>
          <DialogContentText sx={{ color: appColors.darkBlue }}>
            Are you sure you want to log out?
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:"flex",justifyContent:"space-evenly"}}>
          <Button
            size="small"
            variant="contained"
            onClick={onClose}
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            Cancel
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={onConfirm}
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            Logout
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

export default LogoutConfirmationDialog;
