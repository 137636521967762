/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description Custom Text Field (Inputs)
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 */
// Importing the Libraruies abd the
import { TextField } from "@mui/material";
import React from "react";
import appMeta from "../../Assets/AppMeta/appMeta";
import { useDispatch, useSelector } from "react-redux";
/**
 * @author Tahir Shaik
 * @description BCustom Text Field (Inputs- Phone:1, email, name, password, Pincode)
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param { lable, type, setValue, value,isValid, setIsValid, isRequired, isHintRequired}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */
function ReferalTextField(props) {
  const [hint, setHint] = React.useState(false);
  const authenticationDetails = useSelector((state) => state.authReducer);
  /**
   * @author Tahir Shaik
   * @date 01/06/23
   * @description Set the State of the Required Variables based on the Conditions(If required)
   * @param {state: boolean}
   */
  const setDataForVariables = (state) => {
    props.setIsValid(state);
    if (props.isHintRequired) setHint(!state);
  };
  /**
   * @author Tahir Shaik
   * @date 01/06/23
   * @description Function To Validate the Input Text Fields
   * @param {data: number/text} event
   */
  const handleChange = (event) => {
    const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    const phoneRegEx = /^[6-9][0-9]{9}$/;
    const pinCodeRegEx = /^[1-9][0-9]{5}$/;
    const data = event.target.value + "";
    if (props.type === appMeta.textFieldTypes.PHONE && data.length < 11) {
      props.setValue(data);
      setDataForVariables(phoneRegEx.test(data));
    } else if (
      props.type === appMeta.textFieldTypes.PINCODE &&
      data.length <= 6
    ) {
      props.setValue(data);
      setDataForVariables(pinCodeRegEx.test(data));
    } else if (
      props.type !== appMeta.textFieldTypes.PINCODE &&
      props.type !== appMeta.textFieldTypes.PHONE
    ) {
      props.setValue(data);
    } else {
      // Max Limit Exceeds
    }
  
  };

  React.useEffect(() => {
    if (authenticationDetails.referalError === true) {
      setDataForVariables(false)
    } else {
      setDataForVariables(true)
    }
  }, [authenticationDetails.referalError])

  return (
    <TextField
      size="small"
      type="text"
      onChange={handleChange}
      error={props.isRememberMe && props.value.trim() != "" ? false : !props.isValid}
      label={props.lable}
      variant="filled"
      color="success"
      value={props.value}
      focused
      helperText={hint ? "Incorrect Referal Code" : ""}
      required={props.isRequired}
      inputProps={{
        maxLength:
          props.type === appMeta.textFieldTypes.PHONE
            ? 10
            : props.type === appMeta.textFieldTypes.PINCODE
              ? 6
              : 35,
      }}
      sx={{
        backgroundColor: "white",
        fontSize: "18px",
        borderRadius: "5px",
        width: { sm: "70%", xs: "90%" },
        marginTop: "2%",
      }}
    />
  );
}

export default ReferalTextField;
