/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch, useSelector } from "react-redux";
import {
  CancelOrder,
  GetPdwAllOrders,
} from "../../../Redux/actions/ordersAction";
import { Box, Typography } from "@mui/material";
import BiconLoader from "../../../Assets/Animations/preloader.gif";
import appColors from "../../../Assets/AppTheme/appColors";

export default function CancelOrderDialog(props) {
  const dispatch = useDispatch();
  const [pageNumber, setPageNumber] = React.useState(1);
  const orderDetails = useSelector((state) => state.ordersDetails);


  const handleCancelNo = () => {
    props.setIsCancelOrderDialogOpen(false);
   
  };
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open alert dialog
      </Button> */}
      <Dialog
        open={props.isCancelOrderDialogOpen}
        onClose={props.isCancelOrderDialogOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
      >
        {!props.orderCancelMsg ? (
          <DialogTitle
            id="alert-dialog-title"
            sx={{ bgcolor: appColors.secondaryCard, color: appColors.darkGray }}
          >
            Do you want to cancel this order?
          </DialogTitle>
        ) : (
          ""
        )}

        <DialogContent
          sx={{
            display: "flex",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            bgcolor: appColors.secondaryCard,
          }}
        >
          {!props.orderCancelMsg ? (
            <>
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: appColors.textColorNew }}
                onClick={props.handleCancelYes}
              >
                Yes
              </Button>
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: appColors.Failed }}
                onClick={handleCancelNo}
              >
                No
              </Button>
            </>
          ) : (
            ""
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
