/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Lebansty Valan
 * @description BookingRating.jsx is to collect feed back from consumer after order is placed.
 * @date 08/06/23
 * @version 0.0.1 - Initial Release
 */
import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { styles } from "../SkuBooking/newBookingPageStyles";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import DoneButton from "../../../Components/Buttons/DoneButton";
import sucessButton from "../../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import ToastMsgCotainer from "../../../Components/ToastMsg/toastMsg";
import { ConsumerRatingState } from "../../../Redux/actions/profileAction";
import { useDispatch } from "react-redux";

function BookingSucessDiaplay({ textRating }) {
  const iconList = IconListManager();
  const history = useHistory();
  const dispatch = useDispatch();
  //function to handel done button
  const handleDoneButton = () => {
    if (window.innerWidth <= 600) {
      history.push("/mobile");
    } else {
      history.push("/dashboard/home");
    }
    dispatch(ConsumerRatingState(true));
  };

  return (
    <Grid
      direction="row"
      justifyContent="center"
      alignItems="center"
      container
      spacing={3}
    >
      <Grid item md={12} sx={{ marginTop: "4rem" }}>
        <Box sx={styles.flexenter}>
          <Box sx={styles.starBowlAlignParent}>
            <img src={sucessButton} alt="" />
            <Typography sx={styles.successTypo}>
              <span>You have successfully placed your {textRating} order!</span>
            </Typography>
            <hr style={styles.hrStyling} />
          </Box>
        </Box>
      </Grid>
      <Grid item md={12}>
        <Box sx={styles.starBowlAlignParent}>
          <Typography sx={styles.thankTypo}>Thank You.</Typography>
        </Box>
        <Grid item md={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "25px",
              borderTopRightRadius: "15px",
              borderBottomLeftRadius: "15px",
            }}
          >
            <DoneButton onClick={handleDoneButton} />
          </Box>
        </Grid>
      </Grid>
      <ToastMsgCotainer />
    </Grid>
  );
}

export default BookingSucessDiaplay;
