/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";

//Get all the Available Products Based on Address Orders
export const GetAvailableGiftCards = (addressId) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    dispatch({ type: actionType.FETCH_DATA_START });
    try {
      let response = await api.get(`/SSM/giftCard/get/settings`);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_GIFTCARD_SETTINGS,
          payload: response.data,
        });
        return response?.data;
      } else {
        dispatch({
          type: actionType.GET_GIFTCARD_SETTINGS,
          payload: response.data,
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({
        type: actionType.GET_GIFTCARD_SETTINGS,
        payload: error?.response.data,
      });
      return error?.response?.data;
    }
  };
};

//function to check user details
export const CheckUserAvailableDetails = (object) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/verifyUser`, object);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        return response?.data;
      } else {
        toast.error(response?.data?.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        return response?.data;
      }
    } catch (error) {
      toast.dark("Unable to verify user details please try again later", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
      return error?.response?.data;
    }
  };
};

//function to check user details
export const BuyNewGiftCardOrder = (object) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/create`, object);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: false,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
        return response?.data;
      } else {
        dispatch({
          type: actionType.TOAST_MSG_CONTAINER,
          payload: {
            data: {
              error: true,
              containerFlag: true,
              msg: response.data.msg,
            },
          },
        });
        return response?.data;
      }
    } catch (error) {
      dispatch({
        type: actionType.TOAST_MSG_CONTAINER,
        payload: {
          data: {
            error: true,
            containerFlag: true,
            msg: error.response.data.msg,
          },
        },
      });
      return error?.response?.data;
    }
  };
};

//function to check user details
export const RedeemGiftCardAction = (object) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.post(`/SSM/giftCard/redeemNow`, object);
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};

//Get all the Available Products Based on Address Orders
export const GetAvailedGiftCards = (params) => {
  //CustomConsole("SearchAvailability: " + pincode);
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/giftCard/get/details`, {
        params,
      });
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        return response?.data;
      } else {
        return response?.data;
      }
    } catch (error) {
      return error?.response?.data;
    }
  };
};
