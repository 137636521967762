// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import appColors from "../../../Assets/AppTheme/appColors";
import { Box, Card, Typography } from "@mui/material";
import { useSelector } from "react-redux";
export default function PriceBreakUpCardSkuWeb({ deliveryCharges }) {
  const [depositView, setDepositView] = React.useState(false);
  const [discountView, setDiscountView] = React.useState(false);
  const [deliveryView, setDeliveryView] = React.useState(false);
  const iconList = IconListManager();
  const [productId, setProductId] = React.useState(0);
  const priceBreakUp = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );

  const webPaymentSummaryStyles = {
    productHeading: {
      color: appColors.headerTextColor,
      fontSize: ".9rem",
      fontWeight: "500",
    },
  };
  //function for handling selected product info
  const handelSelectedProduct = (data) => {
    if (data === productId) {
      setProductId(0);
    } else {
      setProductId(data);
    }
  };

  //function to calculate in deposit price total
  function TotalDepositPrice() {
    // Initialize total deposit price
    let totalDepositPrice = 0;
    // Iterate through each product
    priceBreakUp?.valid_products?.forEach((product) => {
      // Add the product's deposit price to the total
      totalDepositPrice += product.deposit.price;
    });
    return totalDepositPrice;
  }

  //function to handel deposit showing
  const handelDepositShow = () => {
    setDepositView(!depositView);
  };

  //function to show discount
  const handelDiscountShow = () => {
    setDiscountView(!discountView);
  };

  //function to handel delivery charge view
  const handelDeliveryCharge = () => {
    setDeliveryView(!deliveryView);
  };
  return (
    <>
      <Typography sx={newBookingstyles.cardHeaderStyle}>
        Price Breakup
      </Typography>
      <Card sx={newBookingstyles.cardStylesAddress}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            width: "100%",
            padding: "1rem",
          }}
        >
          {priceBreakUp?.valid_products?.map((data) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                key={data?.product_id}
                onClick={() => handelSelectedProduct(data?.product_id)}
              >
                <Typography sx={webPaymentSummaryStyles.productHeading}>
                  {data?.quantity?.selected} x {data?.name}
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontWeight: "500",
                      fontSize: ".9rem",
                    }}
                  >
                    ₹{parseFloat(data?.product_price).toFixed(2)}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".9rem",
                    }}
                  >
                    {productId === data?.product_id ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>
              {data?.product_id === productId && (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.headerTextColor,
                        fontSize: ".9rem",
                      }}
                    >
                      {data?.quantity?.selected} x {data?.name} Price
                    </Typography>
                    <Typography
                      sx={{
                        color: appColors.darkGray,
                        fontWeight: "500",
                        fontSize: ".9rem",
                      }}
                    >
                      ₹{data?.sub_total_price}
                    </Typography>
                  </Box>
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: appColors.headerTextColor }}>
                      {data?.deposit?.quantity} x {data?.name} Security Deposit
                    </Typography>
                    <Typography
                      sx={{ color: appColors.darkGray, fontWeight: "500" }}
                    >
                      {data?.deposit?.price}
                    </Typography>
                  </Box> */}
                  {/* <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography sx={{ color: appColors.headerTextColor }}>
                      Delivery Charge
                    </Typography>
                    <Typography
                      sx={{ color: appColors.darkGray, fontWeight: "500" }}
                    >
                      {data?.extra_charges?.price}
                    </Typography>
                  </Box> */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.headerTextColor,
                        fontSize: ".9rem",
                      }}
                    >
                      Taxes
                    </Typography>
                    <Typography
                      sx={{
                        color: appColors.darkGray,
                        fontWeight: "500",
                        fontSize: ".9rem",
                      }}
                    >
                      ₹{data?.total_gst?.charges}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          ))}

          {TotalDepositPrice() > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDepositShow()}
              >
                <Typography
                  sx={{ color: appColors.headerTextColor, fontSize: ".9rem" }}
                >
                  Total Security Deposit
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    fontSize: ".9rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontWeight: "500",
                      fontSize: ".9rem",
                    }}
                  >
                    ₹{TotalDepositPrice()}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".9rem",
                    }}
                  >
                    {depositView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>
              {priceBreakUp?.valid_products?.map((data) => (
                <>
                  {depositView && (
                    <>
                      {data?.deposit?.price > 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                          key={data?.product_id}
                        >
                          <Typography
                            sx={{
                              color: appColors.headerTextColor,
                              fontSize: ".9rem",
                            }}
                          >
                            {data?.deposit?.quantity} x {data?.name} Deposit
                            Price
                          </Typography>
                          <Typography
                            sx={{
                              color: appColors.darkGray,
                              fontWeight: "500",
                              fontSize: ".9rem",
                            }}
                          >
                            ₹{data?.deposit?.price}
                          </Typography>
                        </Box>
                      )}
                    </>
                  )}
                </>
              ))}
            </Box>
          )}

          {priceBreakUp?.discount > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDiscountShow()}
              >
                <Typography
                  sx={{ color: appColors.headerTextColor, fontSize: ".9rem" }}
                >
                  Discount(s) Applied
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontWeight: "500",
                      fontSize: ".9rem",
                    }}
                  >
                    - ₹{priceBreakUp?.discount}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".9rem",
                    }}
                  >
                    {discountView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>

              {discountView && (
                <>
                  {priceBreakUp?.water_drop_details?.discount_price > 0 &&
                    priceBreakUp?.water_drop_details?.is_valid === true && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.headerTextColor,
                            fontSize: ".9rem",
                          }}
                        >
                          Water Drops Applied
                        </Typography>
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          - ₹
                          {priceBreakUp?.water_drop_details?.discount_price ===
                            undefined ||
                          priceBreakUp?.water_drop_details?.is_valid === false
                            ? 0
                            : priceBreakUp?.water_drop_details?.discount_price}
                        </Typography>
                      </Box>
                    )}
                  {priceBreakUp?.coupon_code_details?.discount_price > 0 && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          color: appColors.headerTextColor,
                          fontSize: ".9rem",
                        }}
                      >
                        Coupon Applied
                      </Typography>
                      <Typography
                        sx={{
                          color: appColors.darkGray,
                          fontWeight: "500",
                          fontSize: ".9rem",
                        }}
                      >
                        - ₹
                        {priceBreakUp?.coupon_code_details?.discount_price ===
                        undefined
                          ? 0
                          : priceBreakUp?.coupon_code_details?.discount_price}
                      </Typography>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}

          {deliveryCharges > 0 && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginBottom: ".5rem",
                borderBottom: "2px solid gray",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
                onClick={() => handelDeliveryCharge()}
              >
                <Typography
                  sx={{ color: appColors.headerTextColor, fontSize: ".9rem" }}
                >
                  Total Delivery Charges
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontWeight: "500",
                      fontSize: ".9rem",
                    }}
                  >
                    ₹{deliveryCharges}
                  </Typography>
                  <span
                    style={{
                      color: appColors.darkGray,
                      fontSize: ".9rem",
                    }}
                  >
                    {deliveryView ? (
                      <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                    ) : (
                      <KeyboardArrowDownIcon sx={{ fontSize: "20px" }} />
                    )}
                  </span>
                </Box>
              </Box>

              {deliveryView && (
                <>
                  {priceBreakUp?.valid_products?.map((data) => (
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          sx={{
                            color: appColors.headerTextColor,
                            fontSize: ".9rem",
                          }}
                        >
                          {data?.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: appColors.darkGray,
                            fontWeight: "500",
                            fontSize: ".9rem",
                          }}
                        >
                          ₹{data?.extra_charges?.price}
                        </Typography>
                      </Box>
                    </>
                  ))}
                </>
              )}
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{ color: appColors.headerTextColor, fontSize: ".9rem" }}
            >
              Grand Total
            </Typography>
            <Typography
              sx={{
                color: appColors.darkGray,
                fontWeight: "500",
                fontSize: ".9rem",
              }}
            >
              ₹
              {priceBreakUp?.final_price === undefined
                ? 0
                : priceBreakUp?.final_price}
            </Typography>
          </Box>
        </Box>
      </Card>
    </>
  );
}
