/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import { Slide } from "react-toastify";
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";

export default function DepositSucess(props) {
  const handleClose = () => {
    props.handelClose();
  };

  return (
    <Dialog
      open={props.open}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img
            alt=""
            src={props.image}
            style={{ width: "100%", height: "8rem", objectFit: "fill" }}
          />
          <Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            {props.heading}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              color: appColors.darkGray,
              fontWeight: "500",
              marginTop: ".9rem",
              textAlign: "center",
            }}
          >
            {props.subHeading}
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
