/**
 * @author Gowtham Prasath
 * @description OTP Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Card,
  CardContent,
  DialogActions,
  Typography,
} from "@mui/material";
import "../../../Pages/Common/drawer.css";
import "./successBanner.css";
import CloseIcon from "@mui/icons-material/Close";
import { dialogStyles } from "./newPromoDialogCss";
import CheckIcon from "@mui/icons-material/Check";
import moment from "moment";
import appColors from "../../../Assets/AppTheme/appColors";
import { AllOrderOtpPaymentDetails } from "../../../Redux/actions/recentActivityAction";
export default function OtpDialog({ otpFlag, setOtpFlag }) {
  const allOtpDatails = useSelector(
    (state) => state.recentActivities.allDetails
  );
  const dispatch = useDispatch();
  // const promocodeData = useSelector((state) => state.promocodeDetails);
  // const referAndEarnDetails = useSelector((state) => state.referAndEarnDetails);
  //Function for closing Dialog Box
  const handleClose = () => {
    setOtpFlag(false);
  };

  //UseEffect for getting repeat order details
  React.useEffect(() => {
    dispatch(AllOrderOtpPaymentDetails());
  }, []);
  return (
    <>
      <Dialog
        open={otpFlag}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={dialogStyles.dialog}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={dialogStyles.dialogContent}>
          {allOtpDatails?.otp?.regular?.map((otpData) => (
            <>
              {otpData.delivery_otp !== null ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: ".3rem",
                  }}
                >
                  <Card
                    sx={{
                      bgcolor: appColors.secondaryCard,
                      border: "1px solid white",
                    }}
                  >
                    <CardContent>
                      <Box sx={dialogStyles.activeBox}>
                        <Typography sx={dialogStyles.otpTypograpy3}>
                          Delivery OTP:{" "}
                          {otpData.delivery_otp !== null &&
                          otpData.delivery_otp_type !== null &&
                          otpData.delivery_otp_type !== "OTP-LESS"
                            ? otpData.delivery_otp
                            : "No OTP"}
                        </Typography>
                        <Box sx={dialogStyles.bookingidOtp}>
                          <CheckIcon
                            sx={{
                              color: "white",
                              height: "1rem",
                            }}
                          />
                        </Box>
                      </Box>
                      <Typography sx={dialogStyles.bookingId}>
                        Order Id: {otpData.order_id}
                      </Typography>
                      <Typography sx={dialogStyles.bookingId}>
                        Delivery Date:{" "}
                        {moment(otpData?.delivery_date).format("YYYY-MMM-DD")}
                      </Typography>
                      {otpData.delivery_slot !== undefined ? (
                        <>
                          <Typography
                            sx={{
                              fontSize: ".9rem",
                              color: appColors.darkGray,
                            }}
                          >
                            Delivery Time:{" "}
                            <span>
                              {otpData.delivery_slot !== undefined &&
                              otpData.delivery_slot !== "" &&
                              otpData.delivery_slot !== null
                                ? otpData.delivery_slot
                                : "-"}
                            </span>
                          </Typography>
                        </>
                      ) : (
                        <Box sx={{ padding: "10px" }}>
                          <Typography sx={{ textAlign: "center" }}>
                            -
                          </Typography>
                        </Box>
                      )}
                    </CardContent>
                  </Card>
                </Box>
              ) : (
                ""
              )}
            </>
          ))}
          {allOtpDatails?.otp?.replacement.map((otpData) => (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: ".3rem",
              }}
            >
              <Card sx={{ bgcolor: appColors.secondaryCard }}>
                <CardContent>
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      textAlign: "center",
                      // padding: "10px",
                      marginBottom: "5px",
                      color: appColors.darkGray,
                    }}
                  >
                    Replacement Order Otp
                  </Typography>
                  <Box sx={dialogStyles.activeBox}>
                    <Typography sx={dialogStyles.otpTypograpy3}>
                      Delivery OTP:{otpData.otp}
                    </Typography>
                    <Box sx={dialogStyles.bookingidOtp}>
                      <CheckIcon
                        sx={{
                          color: "white",
                          height: "1rem",
                        }}
                      />
                    </Box>
                  </Box>
                  <Typography sx={dialogStyles.bookingId}>
                    Order Id: {otpData.order_id}
                  </Typography>
                </CardContent>
              </Card>
            </Box>
          ))}

          {allOtpDatails?.otp?.return?.length > 0 ? (
            <>
              {allOtpDatails?.otp?.return?.map((otpData) => (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: ".5rem",
                  }}
                >
                  <Card sx={{ bgcolor: appColors.secondaryCard }}>
                    <CardContent>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                          // padding: "10px",
                          marginBottom: "5px",
                          color: appColors.darkGray,
                        }}
                      >
                        Return Product Order Otp
                      </Typography>
                      <Box sx={dialogStyles.activeBox}>
                        <Typography sx={dialogStyles.otpTypograpy3}>
                          Delivery OTP:{otpData.return_can_otp}
                        </Typography>
                        <Box sx={dialogStyles.bookingidOtp}>
                          <CheckIcon
                            sx={{
                              color: "white",
                              height: "1rem",
                            }}
                          />
                        </Box>
                      </Box>
                      <Typography sx={dialogStyles.bookingId}>
                        Order Id: {otpData.return_can_order_id}
                      </Typography>
                    </CardContent>
                  </Card>
                </Box>
              ))}
            </>
          ) : (
            ""
          )}

          {allOtpDatails?.otp?.regular?.length === 0 &&
          allOtpDatails?.otp?.return?.length === 0 &&
          allOtpDatails?.otp?.replacement?.length === 0 ? (
            <Typography
              sx={{
                fontWeight: "bold",
                textAlign: "center",
                // padding: "10px",
                marginBottom: "5px",
                color: appColors.darkBlue,
              }}
            >
              You Have No Ongoing Orders
            </Typography>
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            backgroundColor: appColors.homePageCard,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{
              backgroundColor: appColors.textColorNew,
              color: "#fff",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
