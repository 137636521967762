/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import { useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog";
import appColors from "../../Assets/AppTheme/appColors";
import ProgressDetailsCard from "../../Components/Cards/SkuCards/ProgressCard.jsx";
import { useDispatch, useSelector } from "react-redux";
import PaymentPendingCard from "../../Components/Cards/SkuCards/paymentPendingCard.jsx";
import {
  CancelOrder,
  GetPdwPaymentPendingOrders,
} from "../../Redux/actions/ordersAction.js";
import RepeatOrderCard from "../../Components/Cards/SkuCards/repeatOrderCard.jsx";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction.js";
import moment from "moment/moment.js";
import PendingOrderDialogBox from "../../Components/DialogBoxes/BookNow/pendingOrderDialog.jsx";
import { ToastMsgState } from "../../Redux/actions/toastMsg.js";
import appTheme from "../../Assets/AppTheme/appTheme.js";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
export default function MobileOtpCard({
  recentActivitiesData,
  address,
  setchooseAddressDialog,
  setemailVerificationFlag,
  emailVerificationFlag,
  AddressFlag,
  handelDetailClick,
  bookNowState,
  setIsCancelOrderLoading,
}) {
  const [otpFlag, setOtpFlag] = React.useState(false);
  const paymentPendingOrder = useSelector((state) => state.recentActivities);
  const profile = useSelector((state) => state.userProfile);
  const [isOrderInPaymentPending, setIsOrderPaymentPending] =
    React.useState(false);
  // console.log("-----------------------------------------------------------");
  // console.log(otpData);
  const handelOtpClick = () => {
    setOtpFlag(!otpFlag);
  };
  const dispatch = useDispatch();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const [paymentPendingOrderId, setpaymentPendingOrderId] = React.useState("");
  const [maxStepFlag, setMaxStepFlag] = React.useState(false);
  const [maxSteps, setmaxSteps] = React.useState(0);
  const [repeatOrderData, setRepeatOrderData] = React.useState([]);
  const [progressData, setProgressData] = React.useState([]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  React.useEffect(() => {
    let newMaxSteps = 0;
    if (paymentPendingOrder?.allDetails?.completed?.length > 0) {
      newMaxSteps += paymentPendingOrder.allDetails.completed.length;
    }
    if (paymentPendingOrder?.allDetails?.payment_pending?.length > 0) {
      newMaxSteps += paymentPendingOrder.allDetails.payment_pending.length;
    }
    if (recentActivitiesData?.length > 0) {
      newMaxSteps += recentActivitiesData.length;
    }
    if (!profile.user.is_email_verified || !AddressFlag) {
      setProgressData([{ data: "order_id" }]);
      newMaxSteps += 1;
    } else if (AddressFlag && emailVerificationFlag) {
      setProgressData([]);
    }
    setmaxSteps((prevMaxSteps) => prevMaxSteps + newMaxSteps);
    return () => {
      setmaxSteps(0);
    };
  }, [
    paymentPendingOrder.allDetails,
    recentActivitiesData,
    profile,
    AddressFlag,
  ]);

  React.useEffect(() => {
    if (paymentPendingOrder?.allDetails?.completed?.length > 0) {
      setRepeatOrderData(paymentPendingOrder?.allDetails?.completed);
    }
  }, [paymentPendingOrder.allDetails]);

  // React.useEffect(() => {
  //   if (!emailVerificationFlag || !AddressFlag) {
  //     setmaxSteps(maxSteps + 1);
  //   }
  // }, [emailVerificationFlag]);

  const handelCancelOrder = (data) => {
    const reqObj = {
      order_id: data,
      reason: "Order Cancel By consumer",
    };
    dispatch(CancelOrder(reqObj));
    setIsCancelOrderLoading(true);
    setTimeout(() => {
      dispatch(AllOrderOtpPaymentDetails());
    }, 2000);
    setTimeout(() => {
      setIsCancelOrderLoading(false);
    }, 7000);
  };
  //Function for handling payment pending order dialogbox
  const handlePaymentPendingDialog = () => {};

  //useEffect for handling payment pending order id
  React.useEffect(() => {
    if (paymentPendingOrder?.allDetails?.payment_pending?.length > 0) {
      setpaymentPendingOrderId(
        paymentPendingOrder?.allDetails?.payment_pending[0].order_id
      );
    }
  }, [paymentPendingOrder?.allDetails]);

  //function to handel return and replacement order
  const handelReturnReplacementCanOrder = (data) => {
    if (data?.return_can_order_id !== undefined) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `${data?.return_can_order_id} is a return/replacement order, our delivery team will contact you shortly`,
        })
      );
    } else if (data?.replace_can_req_id !== undefined) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `${data?.order_id} is a return/replacement order, our delivery team will contact you shortly`,
        })
      );
    }
  };
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {address.loading === false && (
        <>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
            interval={maxSteps > 1 ? 4000 : 1000}
          >
            {paymentPendingOrder?.allDetails?.payment_pending?.map((data) => (
              <PaymentPendingCard
                data={data}
                key={data?.orde_id}
                handelCancelOrder={handelCancelOrder}
                handelDetailClick={handelDetailClick}
              />
            ))}
            {recentActivitiesData?.map((step, index) => (
              <Box
                key={index}
                sx={{
                  // background: "rgb(208,221,242)",
                  backgroundColor: appColors.homePageCard,
                  color: "black",
                  // height: "5.6rem",
                  boxShadow: 2,
                  margin: "0rem .7rem .5rem .7rem",
                  borderRadius: ".5rem",
                  overflow: "hidden",
                }}
                onClick={
                  step.order_id !== undefined &&
                  step?.order_id.includes("BTORD")
                    ? () => handelDetailClick(step?.order_id)
                    : () => handelReturnReplacementCanOrder(step)
                }
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    overflow: "hidden",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: ".7rem",
                        borderRight: "1px solid gray",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: appTheme.fontSizeMedium,
                          fontWeight: "bold",
                          color: appColors.darkGray,
                          paddingRight: ".5rem",
                        }}
                      >
                        {step.order_id
                          ? step.order_id
                          : step.return_can_order_id !== undefined
                          ? step.return_can_order_id
                          : ""}
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            color: appColors.secondaryText,
                          }}
                        >
                          <span>
                            {step.delivery_slot !== undefined &&
                            step.delivery_slot !== "" &&
                            step.delivery_slot !== null ? (
                              <div style={{ height: "1.2rem" }}></div>
                            ) : null}
                          </span>
                          Order Id
                        </Typography>
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        color: appColors.darkGray,
                      }}
                    >
                      {step.delivery_slot !== undefined &&
                      step.delivery_slot !== null ? (
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            color: "black",
                            marginRight: ".3rem",
                            fontWeight: "bold",
                            color: appColors.darkGray,
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <span>
                            {step.delivery_slot !== undefined &&
                            step.delivery_slot !== "" &&
                            step.delivery_slot !== null
                              ? moment(step?.delivery_date).format(
                                  "DD MMM YYYY"
                                )
                              : ""}
                          </span>
                          <span>
                            {step.delivery_slot !== undefined &&
                            step.delivery_slot !== "" &&
                            step.delivery_slot !== null
                              ? step.delivery_slot
                              : moment(step?.delivery_date).format(
                                  "DD MMM YYYY"
                                )}
                          </span>
                        </Typography>
                      ) : (
                        ""
                      )}
                      {step.return_can_order_id !== undefined ||
                      step.order_id.includes("RP") ? (
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            color: "black",
                            marginRight: ".3rem",
                            fontWeight: "bold",
                            color: appColors.darkGray,
                          }}
                        >
                          {step.return_can_order_id !== undefined &&
                          step.return_can_order_id.includes("WTHDRWL")
                            ? "Return Order"
                            : step.order_id.includes("RP")
                            ? "Replacement"
                            : ""}
                        </Typography>
                      ) : (
                        ""
                      )}
                      {step.delivery_slot !== undefined ? (
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            color: appColors.secondaryText,
                          }}
                        >
                          Schedule
                        </Typography>
                      ) : (
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            color: appColors.secondaryText,
                          }}
                        >
                          Order Type
                        </Typography>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        width: "4rem",
                        flexDirection: "column",
                        backgroundColor: appColors.blue,
                        height: "5.6rem",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: appTheme.fontSizeMedium,
                          color: appColors.white,
                          fontWeight: "bold",
                        }}
                      >
                        {step.delivery_otp !== null &&
                        step.delivery_otp_type !== undefined &&
                        step.delivery_otp_type === "" &&
                        step.delivery_otp_type !== null
                          ? step.delivery_otp
                          : step.return_can_otp !== undefined
                          ? step.return_can_otp
                          : step.otp !== undefined
                          ? step.otp
                          : "NO OTP"}
                        <span>
                          {step.delivery_slot !== undefined &&
                          step.delivery_slot !== "" &&
                          step.delivery_slot !== null ? (
                            <div style={{ height: "1rem" }}></div>
                          ) : (
                            ""
                          )}
                        </span>
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: appTheme.fontSizeMedium,
                          color: appColors.white,
                        }}
                      >
                        OTP
                      </Typography>
                    </Box>
                  </Box>
                </Box>
                {/* <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} /> */}
              </Box>
            ))}

            {repeatOrderData?.map((data) => (
              <RepeatOrderCard
                data={data}
                key={data?.order_id}
                handelDetailClick={handelDetailClick}
                setIsOrderPaymentPending={setIsOrderPaymentPending}
              />
            ))}
            {progressData?.map((data,index) => (
              <ProgressDetailsCard
                address={address}
                key={index}
                data={data}
                setchooseAddressDialog={setchooseAddressDialog}
                setemailVerificationFlag={setemailVerificationFlag}
              />
            ))}
          </AutoPlaySwipeableViews>
          {maxSteps > 0 ? (
            <MobileStepper
              sx={{
                borderRadius: "0 0 1rem 1rem",
                justifyContent: "center",
                backgroundColor: "transparent",
                marginTop: ".5rem",
                padding: 0,
                "& .MuiMobileStepper-dots .MuiMobileStepper-dot": {
                  width: 35, // Set your desired width
                  height: 2, // Set your desired height
                  borderRadius: 1,
                  backgroundColor: "#caedff",
                },
                "& .MuiMobileStepper-dots .MuiMobileStepper-dotActive": {
                  backgroundColor: "#068fff", // Set your desired active color
                },
              }}
              steps={maxSteps}
              variant="dots"
              position="static"
              activeStep={activeStep}
            />
          ) : (
            ""
          )}
          {/* <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} /> */}
        </>
      )}

      {isOrderInPaymentPending && (
        <PendingOrderDialogBox
          open={isOrderInPaymentPending}
          handleClose={handlePaymentPendingDialog}
          paymentPendingDetails={paymentPendingOrderId}
          setDialogClose={setIsOrderPaymentPending}
        />
      )}
    </Box>
  );
}
