// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 09/JAN/2024
// File Name: weeklyAndMonthlyPayment.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import Radio from "@mui/material/Radio";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import {
  Box,
  Card,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  RadioGroup,
  Typography,
} from "@mui/material";

const useStyles = makeStyles({
  root: {},
  label: { fontSize: ".8rem", fontWeight: 500 },
});
export default function WeeklyAndMonthlyPriceCard({
  setEnteredPayAmount,
  minimumWalletAmount,
  setUpdatePaymentClick,
}) {
  const classes = useStyles();
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  const [perOrderPrice, setPerOrderPrice] = React.useState(0);
  const [perWeekPrice, setPerWeekPrice] = React.useState(0);
  const [perMonthPrice, setPerMonthPrice] = React.useState(0);
  const [coustomAmount, setCoustomAmount] = React.useState(0);
  const [openTextField, setOpenTextField] = React.useState(false);
  const [payFor, setPayFor] = React.useState("single");
  //function for handling payment selection option
  const handleChangePayFor = (event, value) => {
    setPayFor(event.target.value);
    if (event.target.value === "single") {
      setOpenTextField(false);
      // setEnteredPayAmount(perWeekPrice);
    } else if (event.target.value === "weekly") {
      setOpenTextField(false);
      // setEnteredPayAmount(perWeekPrice);
    } else if (event.target.value === "monthly") {
      setOpenTextField(false);
      // setEnteredPayAmount(perMonthPrice);
    } else if (event.target.value === "partial") {
      setOpenTextField(true);
      // setEnteredPayAmount(coustomAmount);
    } else if (event.target.value === "skip" || value === "skip") {
      setOpenTextField(true);
    }
  };

  //function for calculating coustom amount
  React.useEffect(() => {
    const finalPrice = checkoutDetails?.checkOutDetails?.order_price;
    const selectedDays = skuSubscribeOrderDetails.selectedDays.length;
    const wallet_balance = checkoutDetails?.checkOutDetails?.wallet_balance;
    const depositSum = checkoutDetails?.checkOutDetails?.valid_products?.reduce(
      (acc, product) => acc + product.deposit.price,
      0
    );
    let weeklyPrice = 0;
    if (selectedDays > 1) {
      weeklyPrice = parseFloat(
        (finalPrice * selectedDays + depositSum - wallet_balance).toFixed(2)
      );
    } else {
      weeklyPrice = 0;
    }
    const monthlyPrice = parseFloat(
      (finalPrice * selectedDays * 4 + depositSum - wallet_balance).toFixed(2)
    );
    const orderPrice = parseFloat(
      (
        checkoutDetails?.checkOutDetails?.final_price +
        checkoutDetails?.checkOutDetails?.discount +
        -wallet_balance
      ).toFixed(2)
    );

    // debugger;
    setPerWeekPrice(weeklyPrice);
    setPerMonthPrice(monthlyPrice);
    setPerOrderPrice(orderPrice);
  }, [
    checkoutDetails?.checkOutDetails?.final_price,
    skuSubscribeOrderDetails.selectedDays,
  ]);

  //React.UseEffect to update monthly price initally
  React.useEffect(() => {
    if (payFor === "monthly" && perMonthPrice > 0) {
      if (perMonthPrice >= minimumWalletAmount) {
        setEnteredPayAmount(parseFloat(perMonthPrice));
        if (perMonthPrice < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(minimumWalletAmount);
        if (minimumWalletAmount < 10) {
          setUpdatePaymentClick(false);
        }
      }
    } else if (
      payFor === "monthly" &&
      perMonthPrice <= checkoutDetails?.checkOutDetails?.wallet_balance
    ) {
      setEnteredPayAmount(0);
    }
  }, [perMonthPrice, payFor]);

  //React.UseEffect to update monthly price initally
  React.useEffect(() => {
    if (payFor === "single" && perOrderPrice > 0) {
      if (perOrderPrice >= minimumWalletAmount) {
        setEnteredPayAmount(parseFloat(perOrderPrice));
        if (perOrderPrice < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(minimumWalletAmount);
        if (minimumWalletAmount < 10) {
          setUpdatePaymentClick(false);
        }
      }
    } else if (
      payFor === "single" &&
      perOrderPrice <= checkoutDetails?.checkOutDetails?.wallet_balance
    ) {
      setEnteredPayAmount(0);
    }
  }, [perOrderPrice, payFor]);

  //React.UseEffect to update weekly price initally
  React.useEffect(() => {
    if (payFor === "weekly" && perWeekPrice > 0) {
      if (perWeekPrice >= minimumWalletAmount) {
        setEnteredPayAmount(parseFloat(perWeekPrice));
        if (perWeekPrice < 10) {
          setUpdatePaymentClick(false);
        }
      } else {
        setEnteredPayAmount(minimumWalletAmount);
        if (minimumWalletAmount < 10) {
          setUpdatePaymentClick(false);
        }
      }
    } else if (
      payFor === "weekly" &&
      perWeekPrice <= checkoutDetails?.checkOutDetails?.wallet_balance
    ) {
      setEnteredPayAmount(0);
    }
  }, [perWeekPrice, payFor]);

  //React.UseEffect to set monthly price after week price is fulfilled
  React.useEffect(() => {
    if (perOrderPrice >= 1) {
      setPayFor("single");
    }

    // else if (perWeekPrice <= 0 && perMonthPrice > 0) {
    //   setPayFor("monthly");
    // } else if (perWeekPrice > 0) {
    //   setPayFor("weekly");
    // }
  }, [perOrderPrice]);

  //function for handel change value
  const handelChangeValue = (e) => {
    setCoustomAmount(e.target.value);
  };

  //useEffect to update payamount
  React.useEffect(() => {
    if (
      parseInt(coustomAmount) < perOrderPrice &&
      parseInt(coustomAmount) >= minimumWalletAmount
    ) {
      if (perOrderPrice < 10) {
        setUpdatePaymentClick(false);
      }
      setEnteredPayAmount(parseFloat(perOrderPrice));
    } else if (parseInt(coustomAmount) >= minimumWalletAmount) {
      if (coustomAmount < 10) {
        setUpdatePaymentClick(false);
      }
      setEnteredPayAmount(coustomAmount);
    } else {
      if (perOrderPrice >= minimumWalletAmount) {
        setEnteredPayAmount(parseFloat(perOrderPrice));
        if (perOrderPrice < 10) {
          setUpdatePaymentClick(false);
        }
      } else if (perOrderPrice > 0 && perOrderPrice < minimumWalletAmount) {
        setEnteredPayAmount(minimumWalletAmount);
        if (minimumWalletAmount < 10) {
          setUpdatePaymentClick(false);
        }
      } else if (perOrderPrice < 0) {
        setEnteredPayAmount(0);
      } else {
        setEnteredPayAmount(minimumWalletAmount);
        if (minimumWalletAmount < 10) {
          setUpdatePaymentClick(false);
        }
      }
    }
  }, [coustomAmount]);

  return (
    <>
      <Typography
        sx={{
          fontWeight: "bold",
          color: appColors.headerTextColor,
          fontSize: ".9rem",
          marginBottom: ".5rem",
        }}
      >
        Wallet Top-up
      </Typography>
      <Card
        sx={{
          backgroundColor: appColors.cardColor,
          bgcolor: appColors.homePageCard,
          width: "100%",
          boxShadow: "4",
          display: "flex",
          paddingLeft: "1rem",
          marginBottom: ".5rem",
          borderRadius: ".7rem",
        }}
      >
        <Box>
          <Box
            sx={{
              borderRadius: "10px",
              color: appColors.darkGray,
            }}
          >
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={payFor}
                onChange={handleChangePayFor}
                sx={{ color: appColors.darkGray }}
              >
                {perOrderPrice >= 1 && (
                  <FormControlLabel
                    value="single"
                    control={<Radio />}
                    label={`Top-up wallet with ₹${perOrderPrice.toFixed(
                      2
                    )} for an order`}
                    classes={{ label: classes.label }}
                  />
                )}
                {perWeekPrice > 0 && (
                  <FormControlLabel
                    value="weekly"
                    control={<Radio />}
                    label={`Top-up wallet with ₹${perWeekPrice.toFixed(
                      2
                    )} for a week`}
                    classes={{ label: classes.label }}
                  />
                )}
                {perMonthPrice > 0 && (
                  <FormControlLabel
                    value="monthly"
                    control={<Radio />}
                    label={`Top-up wallet with ₹${perMonthPrice} for a month`}
                    classes={{ label: classes.label }}
                  />
                )}
                <FormControlLabel
                  value="partial"
                  control={<Radio />}
                  label="Top-up Wallet with Custom amount"
                  classes={{ label: classes.label }}
                />
              </RadioGroup>
            </FormControl>
          </Box>
          {openTextField ? (
            <FormControl
              sx={{
                width: "100%",
                marginBottom: ".5rem",
                color: appColors.darkGray,
                zIndex: 0,
              }}
              size="small"
              value={coustomAmount}
              onChange={(e) => handelChangeValue(e)}
            >
              <InputLabel htmlFor="outlined-adornment-amount">
                Amount
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-amount"
                label="Amount"
                value={coustomAmount}
                sx={{ color: appColors.darkGray }}
                onInput={(e) => {
                  let inputValue = e.target.value;
                  if (inputValue === "" || isNaN(inputValue)) {
                    e.target.value = "";
                  } else {
                    e.target.value = Math.max(0, parseInt(inputValue))
                      .toString()
                      .slice(0, 5);
                  }
                }}
              />
              {perOrderPrice > minimumWalletAmount &&
              coustomAmount < parseInt(perOrderPrice) ? (
                <FormHelperText sx={{ color: appColors.Failed }}>
                  Top-up for at least <b>₹{perOrderPrice}</b> to proceed
                </FormHelperText>
              ) : (
                ""
              )}
              {perOrderPrice < minimumWalletAmount &&
              coustomAmount < parseInt(minimumWalletAmount) ? (
                <FormHelperText sx={{ color: appColors.Failed }}>
                  Top-up for at least <b>₹{minimumWalletAmount}</b> to proceed
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          ) : (
            ""
          )}
        </Box>
      </Card>
    </>
  );
}
