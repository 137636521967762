/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

const authStyles = {
  btnStyle: {
    backgroundColor: appColors.lightGreen,
    color: appColors.white,
    height: "40px",
    width: "80px",
    borderRadius: "0px 10px 0px 10px",
    textTransform: "none",
    fontWeight: "bold",
    fontSize: appTheme.buttonTextSize,
    "&:hover": { backgroundColor: appColors.blue },
    marginTop: "3%",
  },
  mainContainer: {
    backgroundColor: appColors.themeColor,
    // backgroundImage: {xs:"" , sm:`radial-gradient(circle 350px at top right , #2B87D4,&& parseInt(data) >0 )`},
    color: "white",
    height: "100vh",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#121b47",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#30a7ff" /* Set the background color of the thumb */,
    },
  },
  gridContainer: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  nambiImage: {
    height: "70%",
    width: { lg: "70%", md: "70%", sm: "90%" },
    marginLeft: { md: "30%", lg: "25%" },
    p: 2,
  },
  nambiTextContainer: {
    p: 2,
    position: "absolute",
    bottom: { md: "5%", lg: "10%" },
    left: { md: "7%", lg: "10%" },
    height: "200px",
    width: { md: "200px", lg: "290px" },
  },
  nambiTextItem1: {
    fontSize: { md: "24px", lg: "32px" },
    fontWeight: "bold",
  },
  nambiTextItem2: {
    fontSize: { md: "24px", lg: "32px" },
    fontWeight: "bold",
    color: appColors.secondaryColor,
  },
  nambiTextItem3: {
    fontSize: "12px",
  },
  nambiTextItem4: {
    fontSize: "16px",
  },
  BwLogo: {
    height: "25%",
    width: { xs: "35%", sm: "25%" },
  },
  authContainer: {
    // border: "2px solid red",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    flexDirection: "flex-start",
  },
  authContainer2: {
    // border: "2px solid yellow",
    height: "100%",
    width: "100%",
  },

  bwLogoContainer: {
    // border: "2px solid green",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5%",
  },
  logInSignupMain: {
    // border: "2px solid pink",
    display: "flex",
    flexDirection: "start",
    marginTop: "2%",
  },
  typeHeader: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  authBottomContainer: {
    width: "80%",
    display: "flex",
    height: "auto",
    // border: "1px solid yellow",
    gap: 0.5,
    flexDirection: "column",
    justifyContent: "start",
    marginTop: ".5rem",
  },
  hrLine: {
    color: "white",
    width: "100%",
    border: "1px solid white",
    margin: "1rem",
    opacity: 2,
    backgroundColor: "white",
  },
  switchHeaderData: {
    fontWeight: "bold",
    fontSize: "1.2rem",
  },
  closeButton: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
  },
};
export default authStyles;
