// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: MobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Mobile Landing page which handel booking process and redirect to checkout page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState } from "react";
import { Box, Grid, Typography, Backdrop, AppBar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import BiconLoader from "../../Assets/Animations/preloader.gif";
import {
  GetBanners,
  SetLoadinngFalse,
} from "../../Redux/actions/getBannersAction.js";
import { getBannerImg } from "../../Redux/actions/fileHandlingActions.js";
import AppBarMobile from "./appBar.jsx";
import { AllOrderOtpPaymentDetails } from "../../Redux/actions/recentActivityAction.js";
import { GetUserProfile } from "../../Redux/actions/profileAction.js";
import closeIcon from "../../Assets/newMobileIcon/rectangle-xmark (1).svg";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction.js";
import BanneRDetails from "../../Components/DialogBoxes/BannerDetails.jsx";
import MobileOtpCard from "../Home/mobileOtp.jsx";
import FooterAppBarMobile from "./FooterMobileView.jsx";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog.jsx";
import {
  AddNewAdress,
  GetUserAdressBook,
} from "../../Redux/actions/settingsAction.js";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { MobStyles } from "./MobileViewcss.js";
import { renderData } from "../../Redux/actions/isLoadingAction.js";
import { FooterStyles } from "./MobileViewcss.js";
import appColors from "../../Assets/AppTheme/appColors.js";
import {
  ForceAppTourInfo,
  GetAppMeta,
  toggleThemeState,
} from "../../Redux/actions/appMetaDataAction.js";
import CheckOutCardSku from "../../Components/Cards/SkuCards/checkOutCard.jsx";
import Addresscard from "../../Components/Cards/SkuCards/Addresscard.jsx";
import { AddNewAddressDialogBox } from "../Settings/tabs/myaddressbook/DialogBoxes.js";
import SkuBookingPage from "../BookNow/SkuBooking/SkuBookingPage.jsx";
import {
  DeleteAllProductQty,
  GetProductsByAddress,
  SelectedProductaction,
  SetSelectedAddress,
} from "../../Redux/actions/skuBookingAction.js";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg.jsx";
import { CheckOutActionLoading } from "../../Redux/actions/checkOutAction.js";
import { GetPaymentBlockDetails } from "../../Redux/actions/PaymentActionSku.js";
import { GetWaterDropsUser } from "../../Redux/actions/referAndEarnAction.js";
import CustomLoaderDialog from "../../Components/DialogBoxes/CustomLoaderDialog.jsx";
import FeatureDiscoveryPrompt from "../FeatureDiscoveryPrompt/FeatureDiscoveryPrompt.js";
import LowInternetDialogBox from "../../Components/DialogBoxes/info/lowInternetDialog.jsx";
import ConsumerRatingDialog from "../../Components/DialogBoxes/SkuDialogs/Booking/bookingRatingDialog.jsx";
import CustomConsole from "../../coustomConsole.js";
import { ToastMsgState } from "../../Redux/actions/toastMsg.js";
import HomeProductSelection from "./homeProductSelection.jsx";
//Main Function For Mobile View
export default function MobileView({ routeflag, setRouteFlag }) {
  const dispatch = useDispatch();
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  const forceUpdateData = useSelector(
    (state) => state.appMetaDataDetails.forceAppTourData
  );
  const waterDropDetails = useSelector((state) => state.referAndEarnDetails);
  const setUpConfigData = useSelector((state) => state.appMetaConfigData);
  const SkuReOrderDetails = useSelector((state) => state.skuOrderIdDetails);
  const toastMsgDetails = useSelector((state) => state.toastMsgDetails);
  const orderDetails = useSelector((state) => state.ordersDetails);
  const address = useSelector((state) => state.settings);
  const SkuDetails = useSelector((state) => state.skuBookDetails);
  const recentActivitiesData = useSelector((state) => state.recentActivities);
  const ordersDetails = useSelector((state) => state.ordersDetails);
  const profile = useSelector((state) => state.userProfile);
  const bannersDetails = useSelector((state) => state.bannersDetails);
  const [checkOutButtonClick, setCheckOutButtonClick] = React.useState(false);
  const qrWQDetails = useSelector((state) => state.qrScannerDetails);
  const history = useHistory();
  const [toastFlag, setToastFlag] = React.useState(false);
  const [otpFlag, setOtpFlag] = React.useState(false);
  const [openRatingDialog, setOpenRatingDialog] = React.useState(false);
  const [tostMsg, setTostMsg] = React.useState("");
  const [addressDetailsId, setAddressDetailsID] = React.useState();
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [chooseAdressDialog, setchooseAddressDialog] = React.useState(false);
  const [AddressFlag, setAddressFlag] = React.useState(false);
  const [otpApiFlag, setOtpApiFlag] = React.useState(false);
  const paymentBlocked =
    SkuDetails?.productDetails?.admin_settings?.payment_methods.map(
      (method) => method.type
    );
  const BlocketPayments = new Set(paymentBlocked);
  //repeatOrder
  const bookNowState = useSelector((state) => state.bookNow);
  const [emailVerificationFlag, setemailVerificationFlag] =
    React.useState(false);
  const [openOrderDetailsDialog, setOpenOrderDetailsDialog] =
    React.useState(false);
  const [primaryAddress, setPrimaryAddress] = React.useState({});
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);
  const [cancelOrderLoading, setIsCancelOrderLoading] = React.useState(false);
  //Getting theme status from local storage
  const selectedTheme = localStorage.getItem("selectedTheme");
  //variable for checking flag for progress card
  const [isFirstVisit, setIsFirstVisit] = useState(
    !localStorage.getItem("visitedBeforeHome")
  );
  const [downlinkSpeed, setDownlinkSpeed] = React.useState(null);
  const [lowInternetDialog, setLowInternetDialog] = React.useState(false);
  const [isOnline, setIsOnline] = React.useState(true);
  const [promptStates, setPromptStates] = React.useState({
    bookWater: false,
    referAndEarn: false,
    scanQR: false,
    menu: false,
    wallet: false,
    address: false,
    waterdrop: false,
    products: false,
  });

  const handelHome = () => {
    setRouteFlag(true);
    history.push("/dashboard/home");
  };
  const otpArray = [];
  const [optData, setOtpData] = React.useState([]);
  const [loadingVisible, setLoadingVisible] = useState(false);
  const fileHandler = useSelector((state) => state.fileHandler);
  const [show, setShow] = React.useState(false);
  const [refreshFlag, setRefreshFlag] = React.useState(true);
  const [menuDialogOpean, setmenuDialogOpean] = React.useState(false);
  const [dataURI, setDataURI] = React.useState([]);
  const [dataURIName, setDataURIName] = React.useState([]);
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const [validPaymetMethod, setValidPaymentMethod] = React.useState("");
  React.useState(false);
  const [cancelBtnClicked, setCancelBtnClicked] = React.useState(false);
  const spinnerState = useSelector((state) => state.spinnerReducer);
  const [openProgressBar, setOpenProgressBar] = React.useState(true);
  //For loading address details and repeat order details
  React.useEffect(() => {
    if (
      profile?.consumer?.is_android_app_rated === undefined &&
      profile?.consumerRatingFlag
    ) {
      // setTimeout(() => {
      //   setOpenRatingDialog(true);
      // }, 2000);
    }
  }, [profile]);
  // CustomConsole(supplierDeliveries.getSupplerCompanyProfile.company_name);
  React.useEffect(() => {
    if (spinnerState.isLoading) {
      setOpenProgressBar(true);
    } else {
      setTimeout(() => {
        setOpenProgressBar(false);
      }, 2000);
    }
  }, [spinnerState]);
  //Useeffect for fetching data from required apis
  React.useEffect(() => {
    setCancelBtnClicked(false);
    if (!otpApiFlag) {
      setOtpApiFlag(true);
      dispatch(AllOrderOtpPaymentDetails());
    }
  }, []);

  //useEffect for getting payment block details
  React.useEffect(() => {
    dispatch(GetPaymentBlockDetails());
    dispatch(ForceAppTourInfo());
  }, []);
  //UseEffect for getting banner images
  React.useEffect(() => {
    dispatch(GetBanners());
  }, []);

  //UseEffect for getting meta data
  React.useEffect(() => {
    if (setUpConfigData?.metaData?.length === 0) {
      // dispatch(GetSetUpConfigAction());
      dispatch(GetAppMeta());
    }
  }, []);

  //Use Effect for changing email verification flag
  React.useEffect(() => {
    if (address?.addresses !== undefined) {
      setAddressFlag(address?.addresses.length > 0);
      setemailVerificationFlag(profile.user.is_email_verified);
      // if (
      //   address?.addresses.length > 0 &&
      //   localStorage.getItem("visitedBeforeHome") &&
      //   !localStorage.getItem("isAddressFlag")
      // ) {
      //   localStorage.setItem("isAddressFlag", true);
      //   setTimeout(() => {
      //     setPromptStates((prevStates) => ({
      //       ...prevStates,
      //       products: true,
      //     }));
      //   }, 1000);
      // }
    }
  }, [address?.addresses, profile.user]);
  //For setting up theme based on user last selected
  React.useEffect(() => {
    if (selectedTheme) {
      document.querySelector("body").setAttribute("dark-theme", selectedTheme);
      localStorage.setItem("selectedTheme", selectedTheme);
    }
    if (selectedTheme === "dark") {
      dispatch(toggleThemeState(true));
    } else {
      dispatch(toggleThemeState(false));
    }
  }, []);

  //useEffect for updating OTP state details
  React.useEffect(() => {
    if (recentActivitiesData.allDetails?.otp?.return?.length > 0) {
      recentActivitiesData.allDetails?.otp?.return.map((otpData) =>
        otpArray.push(otpData)
      );
    }
    if (recentActivitiesData.allDetails?.otp?.regular.length > 0) {
      recentActivitiesData.allDetails?.otp?.regular.map((otpData) =>
        otpArray.push(otpData)
      );
    }
    if (recentActivitiesData.allDetails?.otp?.replacement.length > 0) {
      recentActivitiesData.allDetails?.otp?.replacement.map((otpData) =>
        otpArray.push(otpData)
      );
    }
    setOtpData(otpArray);
  }, [recentActivitiesData]);

  //useEffect for getting banner images
  React.useEffect(() => {
    if (
      fileHandler.bannerImg &&
      dataURI.length <= 8 &&
      bannersDetails.bannerImagesFlag === false
    ) {
      dispatch(getBannerImg(dataURIName));
    }
  }, [dataURIName]);
  //useEffect for setting up a banner image flag in state
  React.useEffect(() => {
    if (
      bannersDetails?.bannersData?.length !==
      bannersDetails?.bannerImages?.length
    ) {
      dispatch(SetLoadinngFalse(false));
    }
  }, [bannersDetails.bannersData]);

  //useEffect for setting up a banner image in state
  React.useEffect(() => {
    if (bannersDetails.bannerImagesFlag === false) {
      bannersDetails.bannersData.map((data) => {
        if (data.file_names.length > 0) {
          setDataURIName((prev) => [...prev, data.file_names[0]]);
        } else {
        }
      });
    }
  }, [bannersDetails.bannersData, bannersDetails.bannerImagesFlag]);

  //For showing error message while tost message for qr is captured
  React.useEffect(() => {
    setTimeout(() => {
      if (qrWQDetails.error === true && qrWQDetails.msg.length) {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: qrWQDetails.msg,
          })
        );
        qrWQDetails.msg = "";
      }
    }, 1000);
  }, [qrWQDetails]);

  React.useEffect(() => {
    if (
      fileHandler.bannerImg &&
      dataURI.length <= 8 &&
      !dataURI.includes(fileHandler.bannerImg) &&
      bannersDetails.bannerImagesFlag === false
    ) {
      CustomConsole(fileHandler.bannerImg);
      setDataURI(fileHandler.bannerImg);
    }
    // Cleanup function
    return () => {
      fileHandler.bannerImg = [];
    };
  }, [fileHandler.bannerImg]);

  //For closing toast message if it intiated
  //useEfffect for closing tost msg
  React.useEffect(() => {
    if (toastFlag) {
      setTimeout(() => {
        setToastFlag(false);
      }, 3000);
    }
  }, [toastFlag]);
  const data = useSelector((state) => state.qrScannerDetails);
  // const listOfAvailableTankersData = useSelector((state) => state.)
  // const [showWaterDetails, setShowWaterDetails] = useState(false);

  React.useEffect(() => {
    if (data.isQrDataLoaded) {
      // <QrDataDialogbox data={data} />
      CustomConsole("openBox INSIDE IF");
      CustomConsole(data);
      data.isQrDataLoaded = false;
    }
  }, [data]);

  //useEffect for setting render data as booknow
  React.useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false);
      dispatch(renderData("BookNow"));
    }
  }, [refreshFlag]);

  // //UseEffect for triggering app tour component
  React.useEffect(() => {
    if (isFirstVisit) {
      setPromptStates((prevStates) => ({
        ...prevStates,
        bookWater: true,
      }));
    }
    // Cleanup function
    return () => {
      localStorage.setItem("visitedBeforeHome", true);
    };
  }, [isFirstVisit]);

  // //UseEffect for triggering app tour component
  React.useEffect(() => {
    if (
      !localStorage.getItem("isUpdatedTour") &&
      localStorage.getItem("visitedBeforeHome")
    ) {
      // setIsFirstVisit(true);
    }
  }, [isFirstVisit]);

  //Closing address dialog if address details added
  const handleAddAddressDialog = () => {
    setAddAddressDialog(false);
  };

  //Function for handling new address added
  const handleAddNewAddress = (details, isPrimary) => {
    dispatch(AddNewAdress(details, isPrimary));
  };

  //Useeffect for setting address details
  React.useEffect(() => {
    const selectedAddressId = SkuProductDetails.selectedAddressId;
    const storedAddressId = localStorage.getItem("addressId");
    if (storedAddressId === null || storedAddressId === undefined) {
      let AddressData;
      if (address?.addresses !== undefined) {
        AddressData =
          address?.addresses.find((item) => item.is_primary === true) ||
          address?.addresses[0];
        CustomConsole(address);
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    } else {
      let AddressData;
      if (
        address?.addresses !== undefined &&
        storedAddressId === null &&
        storedAddressId === undefined
      ) {
        AddressData =
          address?.addresses.find(
            (item) => selectedAddressId === item.address_id
          ) || address?.addresses[0];
        CustomConsole(address);
      } else if (address?.addresses !== undefined) {
        AddressData =
          address?.addresses.find(
            (item) => storedAddressId == item.address_id
          ) || address?.addresses[0];
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    }
  }, [address.addresses]);

  //function to handel edit order details
  const handelOpenEditOrderDetails = () => {};

  //function for opening order details dialog box
  const handelDetailClick = (data) => {
    history.push(`/dashboard/myorders?orderId=${data}`);
  };

  //api for hitting address details
  React.useLayoutEffect(() => {
    if (address?.addresses?.length === 0) {
      dispatch(GetUserAdressBook());
      dispatch(GetUserProfile());
    }
  }, []);

  //api for hitting address details
  React.useEffect(() => {
    if (
      SkuDetails.productDetails !== undefined &&
      SkuDetails.productDetails !== null &&
      Object.keys(SkuDetails.productDetails).length === 0
    ) {
      setTimeout(() => {
        dispatch(GetWalletDetails());
        dispatch(GetWaterDropsUser());
      }, 200);
    }
  }, [SkuDetails.productDetails]);

  //useEffect for redirecting page for reorder details
  React.useEffect(() => {
    if (!SkuReOrderDetails.reOrderError) {
      if (
        SkuReOrderDetails?.reOrderDetails?.payment_method ===
        "PAID THROUGH RAZORPAY"
      ) {
        SkuReOrderDetails.reOrderDetails.payment_method = validPaymetMethod;
        SkuReOrderDetails.reOrderDetails.address_id =
          SkuProductDetails?.selectedAddressId;
        SkuReOrderDetails.reOrderDetails.is_otp_less = true;
        SkuReOrderDetails.reOrderDetails.is_auto_apply_water_drops =
          waterDropDetails?.allData?.auto_pay;
        SkuReOrderDetails.reOrderDetails.water_drops = 0;
        dispatch(SelectedProductaction(SkuReOrderDetails?.reOrderDetails));
        dispatch(CheckOutActionLoading(SkuReOrderDetails?.reOrderDetails));
        setCheckOutButtonClick(true);
        SkuReOrderDetails.reOrderError = true;
      } else {
        SkuReOrderDetails.reOrderDetails.address_id =
          SkuProductDetails?.selectedAddressId;
        SkuReOrderDetails.reOrderDetails.payment_method = validPaymetMethod;
        SkuReOrderDetails.reOrderDetails.is_otp_less = true;
        SkuReOrderDetails.reOrderDetails.is_auto_apply_water_drops =
          waterDropDetails?.allData?.auto_pay;
        SkuReOrderDetails.reOrderDetails.water_drops = 0;
        dispatch(SelectedProductaction(SkuReOrderDetails?.reOrderDetails));
        dispatch(CheckOutActionLoading(SkuReOrderDetails?.reOrderDetails));
        setCheckOutButtonClick(true);
        SkuReOrderDetails.reOrderError = true;
      }
    }
  }, [SkuReOrderDetails.reOrderError]);

  // console.log(waterDropDetails?.allData);
  //useeffect for reorder page details
  React.useEffect(() => {
    if (
      checkOutDetails?.pageLoading === false &&
      checkOutDetails?.loading === false &&
      checkOutDetails?.checkOutDetails !== undefined &&
      checkOutDetails?.checkOutDetails?.valid_products?.length > 0 &&
      checkOutButtonClick
    ) {
      history.push(`/dashboard/checkout?pageName=booking`);
      setCheckOutButtonClick(false);
    }
  }, [checkOutDetails?.pageLoading]);

  //useEffect for setting up an payment menthod
  React.useEffect(() => {
    let validPayment = "";
    if (!BlocketPayments.has(300)) {
      validPayment = "WALLET";
    } else if (
      (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(103))
    ) {
      validPayment = "PAYSHARP";
    } else if (!BlocketPayments.has(200)) {
      validPayment = "RAZORPAY";
    }
    setValidPaymentMethod(validPayment);
  }, [SkuDetails]);

  // React.useEffect(() => {
  //   const timer = setTimeout(() => {
  //     address.loading = false;
  //     SkuDetails.pageLoading = false;
  //     checkOutDetails.pageLoading = false;
  //     SkuReOrderDetails.loading = false;
  //   }, 20000);

  //   return () => clearTimeout(timer);
  // }, []);
  // console.log("test");
  CustomConsole(address);
  CustomConsole(address.loading);
  CustomConsole(SkuDetails);
  CustomConsole(SkuProductDetails);
  CustomConsole(checkOutDetails);
  CustomConsole(checkOutButtonClick);
  //useeffect for getting cancel order details
  React.useEffect(() => {
    if (!orderDetails.cancelOrderError) {
      setIsCancelOrderLoading(false);
      orderDetails.cancelOrderError = true;
    }
  }, [orderDetails.cancelOrderError]);

  //useEffect for changing check out flag
  React.useEffect(() => {
    if (
      checkOutDetails?.checkOutDetails === undefined &&
      SkuDetails?.productDetails?.products !== undefined &&
      SkuDetails?.productDetails?.products !== null &&
      SkuDetails?.productDetails?.products.length > 0 &&
      checkOutButtonClick
    ) {
      setCheckOutButtonClick(false);
      SkuDetails.selectedProduct = {};
    }
  }, [checkOutDetails]);

  //useeffect to check internet status
  React.useEffect(() => {
    const interval = setInterval(() => {
      setIsOnline(navigator.onLine);
      if (navigator.connection) {
        const speed = navigator.connection.downlink;
        setDownlinkSpeed(speed);
      }
    }, 1000); // Check speed every 1 second
    return () => clearInterval(interval);
  }, []);

  CustomConsole(downlinkSpeed + "downlink");
  //useeffect to check internet status
  React.useEffect(() => {
    if (
      (downlinkSpeed !== null &&
        downlinkSpeed !== undefined &&
        downlinkSpeed < 0.2) ||
      !isOnline
    ) {
      setLowInternetDialog(true);
    }
  }, [downlinkSpeed]);

  CustomConsole(forceUpdateData);
  //useeffect for forceapp tour
  React.useEffect(() => {
    if (forceUpdateData?.consumer_updates?.consumer_web?.force_app_tour) {
      if (
        forceUpdateData?.consumer_updates?.consumer_web?.consumer_version ===
          process.env.REACT_APP_VERSION &&
        forceUpdateData?.consumer_updates?.consumer_web?.force_app_tour &&
        localStorage.getItem("appVersion") !== process.env.REACT_APP_VERSION
      ) {
        localStorage.setItem(
          "appVersion",
          forceUpdateData?.consumer_updates?.consumer_web?.consumer_version
        );
        setPromptStates((prevStates) => ({
          ...prevStates,
          bookWater: true,
        }));
      }
    }
  }, [forceUpdateData]);

  CustomConsole(
    localStorage.getItem("appVersion") != process.env.REACT_APP_VERSION
  );
  CustomConsole(process.env.REACT_APP_VERSION);

  //function for checkout card rendring
  const shouldShowCheckOut = (
    SkuDetails,
    SkuProductDetails,
    address,
    checkOutButtonClick
  ) => {
    const hasProducts = SkuDetails?.selectedProduct?.products?.length > 0;
    const hasCrates =
      SkuDetails?.selectedProduct?.crates?.length > 0 &&
      SkuDetails?.selectedProduct?.crates !== undefined;
    const hasProductDetails = SkuProductDetails?.productDetails !== undefined;
    const hasAddresses = address?.addresses?.length > 0;

    return (
      (hasProducts || hasCrates) &&
      !checkOutButtonClick &&
      hasProductDetails &&
      hasAddresses
    );
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100vw",
      }}
    >
      <AppBarMobile
        setOtpFlag={setOtpFlag}
        otpFlag={otpFlag}
        promptStates={promptStates}
        setPromptStates={setPromptStates}
        setchooseAddressDialog={setchooseAddressDialog}
        setPrimaryAddress={setPrimaryAddress}
        primaryAddress={primaryAddress}
      />
      {toastFlag ? (
        <Box
          sx={{
            position: "fixed",
            alignItems: "center",
            zIndex: 6,
            right: 0,
            top: "5.5rem",
            backgroundColor: appColors.Failed,
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              margin: "0 0.3rem 0 0.3rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ fontSize: ".8rem", color: "#fff", fontWeight: "bold" }}
            >
              {tostMsg}
              &nbsp; &nbsp; &nbsp; &nbsp;
            </Typography>
            <span onClick={() => setToastFlag(false)}>
              <img alt="" src={closeIcon} />
            </span>
          </Box>
        </Box>
      ) : (
        ""
      )}
      <Grid container direction="column" sx={[MobStyles.gridMainContainer]}>
        {/* {!AddressFlag || !emailVerificationFlag ? (
          <Grid
            item
            xs={12}
            sx={[
              MobStyles.gridItemHeading,
              {
                marginTop:
                  !AddressFlag || !emailVerificationFlag ? "9.5rem" : "9.5rem",
              },
            ]}
          >
            <ProgressDetailsCard
              address={address}
              setchooseAddressDialog={setchooseAddressDialog}
              setemailVerificationFlag={setemailVerificationFlag}
            />
          </Grid>
        ) : (
         
        )} */}
        <Box sx={{ marginTop: "6.8rem" }}></Box>
        <Grid item xs={12} sx={MobStyles.bannserStyles}>
          {bannersDetails.bannersData.length > 0 &&
          bannersDetails.error === false ? (
            <BanneRDetails
              data={bannersDetails}
              dataURI={dataURI}
              pageHome={false}
            />
          ) : (
            ""
          )}
        </Grid>
        {optData.length > 0 ||
        !AddressFlag ||
        !emailVerificationFlag ||
        recentActivitiesData?.allDetails?.payment_pending?.length > 0 ||
        recentActivitiesData?.allDetails?.completed?.length > 0 ? (
          <Grid
            item
            xs={12}
            sx={[MobStyles.bannserStyles, { marginTop: "1rem" }]}
          >
            <Box>
              <MobileOtpCard
                recentActivitiesData={optData}
                address={address}
                setchooseAddressDialog={setchooseAddressDialog}
                setemailVerificationFlag={setemailVerificationFlag}
                AddressFlag={AddressFlag}
                emailVerificationFlag={emailVerificationFlag}
                handelDetailClick={handelDetailClick}
                bookNowState={bookNowState}
                setIsCancelOrderLoading={setIsCancelOrderLoading}
              />
            </Box>
          </Grid>
        ) : (
          ""
        )}

        <Grid
          item
          xs={12}
          sx={[
            MobStyles.bannserStyles,
            {
              // marginBottom: AddressFlag ? `${window.innerHeight / 3}px` : "0",
              padding: "0 1rem",
              marginBottom:"4rem"
            },
          ]}
        >
          <HomeProductSelection />
        </Grid>

        <Grid item xs={12}>
          {shouldShowCheckOut(
            SkuDetails,
            SkuProductDetails,
            address,
            checkOutButtonClick
          ) && (
            <AppBar>
              <Box sx={FooterStyles.checkOut}>
                <CheckOutCardSku pageName="booking" />
              </Box>
            </AppBar>
          )}

          <Box sx={FooterStyles.footerMain}>
            <FooterAppBarMobile
              menuDialogOpean={menuDialogOpean}
              setmenuDialogOpean={setmenuDialogOpean}
              promptStates={promptStates}
              setPromptStates={setPromptStates}
            />
          </Box>
        </Grid>
      </Grid>
      {otpFlag && <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} />}
      {chooseAdressDialog && (
        <Addresscard
          setAddress={setAddressDetailsID}
          setOpenEditDialog={setOpenEditDialog}
          openEditDialog={openEditDialog}
          setchooseAddressDialog={setchooseAddressDialog}
          chooseAdressDialog={chooseAdressDialog}
          addAdressDialog={addAdressDialog}
          address={addressDetailsId}
          primaryAddress={primaryAddress}
          setCurrentAddress={setPrimaryAddress}
          setAddAddressDialog={setAddAddressDialog}
        />
      )}
      {addAdressDialog && (
        <AddNewAddressDialogBox
          open={addAdressDialog}
          handleClose={handleAddAddressDialog}
          handleAddNewAddress={handleAddNewAddress}
          setAddAddressDialog={setAddAddressDialog}
          setchooseAddressDialog={setchooseAddressDialog}
        />
      )}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={address.loading}
      >
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BiconLoader}
            alt="Loading..."
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </Backdrop>
      {openProgressBar && (
        <CustomLoaderDialog
          open={openProgressBar}
          handleClose={() => setOpenProgressBar(false)}
        />
      )}

      {lowInternetDialog && (
        <LowInternetDialogBox
          open={lowInternetDialog}
          onClose={setLowInternetDialog}
        />
      )}
      <ToastMsgCotainer />
      {openRatingDialog && (
        <ConsumerRatingDialog
          openRatingDialog={openRatingDialog}
          setOpenRatingDialog={setOpenRatingDialog}
        />
      )}
    </Box>
  );
}
