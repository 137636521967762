// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/AUG/2023
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import * as Material from "@mui/material";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import appColors from "../../../Assets/AppTheme/appColors";
import wavePattern from "../../../Assets/newMobileIcon/Booking/Card Wave.svg";
import moment from "moment";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import InformationDialog from "../../DialogBoxes/info/infoDialogBox";
import { useSelector } from "react-redux";
import appTheme from "../../../Assets/AppTheme/appTheme";
export default function BookingSelectDateCard({
  samedayDelivery,
  currentDate,
  setcalenderOpen,
  deliveryCharges,
  extraCharges,
}) {
  const iconList = IconListManager();
  const appMeta = useSelector((state) => state.appMetaDataDetails);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [infoDialogText, setInfoDialogText] = React.useState("");
  const [otpLessDiss, setOtpLessDiss] = React.useState("");

  //For opening info dialog box
  const handelInfoIcon = (text) => {
    setOpenInfoDialog(true);
    setInfoDialogText(text);
  };

  return (
    <>
      <Material.Grid item xs={12} md={12}>
        <Material.Typography sx={newBookingstyles.cardHeaderStyle}>
          Delivery Date &nbsp;
          <span>
            <img
              src={iconList.infoIconIcon}
              alt=""
              onClick={() => handelInfoIcon("Date")}
            />
          </span>
        </Material.Typography>
        <Material.Card
          sx={[newBookingstyles.cardStyles, { position: "relative" }]}
        >
          <Material.Box sx={newBookingstyles.MainBox}>
            <Material.Typography sx={newBookingstyles.BoxHeader}>
              <img alt="" src={iconList.CalenderIcon} />
              &nbsp;&nbsp; {samedayDelivery ? "Today" : "Standard"} &nbsp;&nbsp;
              <span>
                {moment(currentDate).tz("Asia/Kolkata").format("DD MMM YYYY")}
              </span>
            </Material.Typography>
          </Material.Box>
          <Material.Box sx={{ width: "20%" }}>
            <Material.Button
              onClick={() => setcalenderOpen(true)}
              sx={{ fontSize: appTheme.fontSizeSmall }}
              size="small"
              endIcon={<EditIcon sx={newBookingstyles.editButton} />}
            ></Material.Button>
          </Material.Box>
          <img alt="" src={wavePattern} style={newBookingstyles.wavePattern} />
          <span
            style={{
              color: "#58595b",
              fontWeight: "bold",
              position: "absolute",
              bottom: 0,
              marginLeft: "1rem",
              fontSize: appTheme.fontSizexs,
            }}
          >
            {samedayDelivery
              ? deliveryCharges !== undefined || deliveryCharges > 0
                ? `Delivery Charges ₹${deliveryCharges} Applied`
                : `Delivery Charges ₹${
                    extraCharges > 0 ? extraCharges : 15
                  } Per Product`
              : "Free Delivery"}
          </span>
        </Material.Card>
      </Material.Grid>
      <InformationDialog
        setOpenInfoDialog={setOpenInfoDialog}
        otpLessDiss={otpLessDiss}
        openInfoDialog={openInfoDialog}
        infoDialogText={infoDialogText}
        setInfoDialogText={setInfoDialogText}
        setcalenderOpen={setcalenderOpen}
        extraCharges={extraCharges}
      />
    </>
  );
}
