/**
 * @author Tahir Shaik
 * @description All App Constants which are mentioned from Backend
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param {}
 * @returns {UI og Authetication Page with LogIn & SignUp Components}
 */

const appConstants = {
    appMessageType:{
        ERROR:"error",
        SUCCESS:"success",
        WARNING:"warning",
        INFO:"info",
    }

}

export default appConstants;