// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 19/DEC/2023
// File Name: toastMsg.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { Box, Typography } from "@mui/material";
import React from "react";
import closeIcon from "../../Assets/newMobileIcon/rectangle-xmark (1).svg";
import appColors from "../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import toastMsgReduser from "../../Redux/reducers/toastMsgReduser";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function ToastMsgCotainer() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const ToastMsgDetails = useSelector((state) => state.toastMsgDetails);

  //Function for closing Toast dialog
  const toastDialogClose = () => {
    dispatch(
      ToastMsgState({
        error: false,
        toastMsg: "",
        toastContainerFlag: false,
      })
    );
  };
  // useEffect to close toast msg container after 3 seconds
  React.useEffect(() => {
    if (ToastMsgDetails.toastContainerFlag) {
      const timeout = setTimeout(() => {
        toastDialogClose();
      }, 5000);
      return () => clearTimeout(timeout);
    }
  }, [ToastMsgDetails.toastContainerFlag]);

  // useEffect to close toast msg container when path changes or unmounts
  React.useEffect(() => {
      return () => {
        toastDialogClose();
      };
  }, [pathname]);
  return (
    <>
      {ToastMsgDetails.toastContainerFlag && (
        <Box
          sx={{
            position: "fixed",
            alignItems: "center",
            zIndex: 6,
            right: 0,
            top: pathname === "/mobile" ? "5.8rem" : "3.6rem",
            backgroundColor: ToastMsgDetails.error
              ? appColors.Failed
              : appColors.sucessColor,
            width: "100%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              margin: "0 0.3rem 0 0.3rem",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: ".8rem",
                color: "#fff",
                fontWeight: "bold",
                textAlign:"center"
              }}
            >
              {ToastMsgDetails.toastMsg}&nbsp; &nbsp;
            </Typography>
            <span onClick={toastDialogClose}>
              <img alt="" src={closeIcon} />
            </span>
          </Box>
        </Box>
      )}
    </>
  );
}
