/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { Button } from "@mui/material";
import { styles } from "./ButtonStyle";
const ButtonCustom = (props) => {
  // const [flag, setFlag] = useState(true);
  // const [buttonId, setButtonId] = useState(-1);

  // const listNav = [
  //   { index: 0, title: "Home" },
  //   { index: 1, title: "Book Now" },
  //   { index: 2, title: "My Orders" },
  //   { index: 3, title: "Transactions" },
  //   { index: 4, title: "Water Wallet" },
  //   { index: 5, title: "Reports" },
  //   { index: 6, title: "Support" },
  //   { index: 7, title: "Settings" },
  // ];
  // const clickHandler = () => {
  //   setFlag(!flag);
  //   setButtonId(props.id);
  //   console.log("button handler");
  //   console.log(props.id);
  //   bId = props.id;
  // };

  console.log(props)
  return (
    <Button
      variant="contained"
      startIcon={props.icon}
      size="small"
      disabled={props.disabled}
      sx={{ ...styles.button, ...props.styles }} 
      onClick={props.handleButtonClick} 
    >
      {props.title}
    </Button>
  );
};
export default ButtonCustom;
