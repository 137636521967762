// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: UPITransactionDialog.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import canIcon from "../../../Assets/newMobileIcon/Payment processing(Can set).svg";
import * as Material from "@mui/material";
import { useLocation } from "react-router-dom";
import BiconLoader from "../../../Assets/Animations/preloader.gif";
import CircularProgress from "@mui/material/CircularProgress";
import { IntervalTimerFunctional } from "../CountDownTimer";
import SouthIcon from "@mui/icons-material/South";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { PaythroughUPIOrderStatus } from "../../../Redux/actions/paymentMethodAction";
import UPIPayStatusDialog from "../UPIPayStatusDialog";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";
import DownloadIcon from "@mui/icons-material/Download";
import appColors from "../../../Assets/AppTheme/appColors";
import { Backdrop, Box } from "@mui/material";
import { ResetComponentState } from "../../../Redux/actions/appMetaDataAction";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import CustomConsole from "../../../coustomConsole";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function UPITransactionDialog(props) {
  const { pathname, search } = useLocation();
  const name = "";
  const pageTitleSideBar = pathname.split("/dashboard/");

  const pageName = new URLSearchParams(search).get("pageName");
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const bookNowState = useSelector((state) => state.bookNow);
  const [openUPIStatusDialog, setOpenUPIStatusDialog] = React.useState(false);
  const [orderAmount, setOrderAmount] = React.useState("");
  const upiOrderStatus = useSelector((state) => state.UPIOrderStatus);
  CustomConsole(props.currentOrderId);
  const dispatch = useDispatch();
  const history = useHistory();
  //function for closing transaction dialog
  const handleClose = (e) => {
    e.preventDefault();
    props.handleClose();
  };

  //function for handling time out
  React.useEffect(() => {
    let timeout = setTimeout(() => {
      history.push("/dashboard/myorders");
      setTimeout(() => {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Payment Failed`,
          })
        );
      }, 2000);
    }, 720000);
    return () => {
      clearTimeout(timeout);
    };
    // }
  }, []);

  //function for handling close icon
  const handleCloseIcon = () => {
    props.setUpiTransactionPage(false);
    if (pageName === "booking") {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
    } else if (pageTitleSideBar[1] === "myorders") {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
    } else if (pageTitleSideBar[1] === "buygiftcard") {
      history.push("/dashboard/giftcards");
    } else {
      dispatch(ResetComponentState());
      props.setUpiPaymentDialogOpen(false);
    }
    setDialogOpen(false);
    setTimeout(() => {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Payment Failed`,
        })
      );
    }, 1000);
  };

  //function for closing razor pay dialog box
  const handleCloseRPDialog = () => {
    setOpenUPIStatusDialog(false);
    props.handleCloseRPDialog();
  };
  let orderFinalPrice = "100";

  // const [apiInterval, setApiInterval] = React.useState(true);
  React.useEffect(() => {
    CustomConsole(props.currentOrderId);
    CustomConsole(bookNowState.orderId);
    CustomConsole(`upiTransactionPage ${props.upiTransactionPage}`);

    let interval = setInterval(() => {
      dispatch(PaythroughUPIOrderStatus(props.currentOrderId));
    }, 3000);
    CustomConsole(interval);
    return () => {
      clearInterval(interval);
    };
  }, [props.currentOrderId]);

  const [error, setError] = React.useState(false);

  //useeffect for setting upi pay status
  React.useEffect(() => {
    CustomConsole(upiOrderStatus);
    CustomConsole(`openUPIStatusDialog ${openUPIStatusDialog}`);
    if (
      upiOrderStatus.isPaymentUpdated === true &&
      upiOrderStatus.message.error === false
    ) {
      if (upiOrderStatus.message.data.status === "SUCCESS") {
        CustomConsole("Payment Success");
        setOrderAmount(upiOrderStatus.message.data.amount);
        setError(false);
        props.setUpiTransactionPage(false);
        setOpenUPIStatusDialog(true);
        props.setOpenUPIStatus(true);
      } else if (
        upiOrderStatus.message.data.status === "FAILED" ||
        upiOrderStatus.message.data.status === "TIMEOUT"
      ) {
        CustomConsole("Payment Failed");
        // dispatch(CancelOrder(upiOrderStatus.message.data.orderId));
        setError(true);
        props.setUpiTransactionPage(false);
        props.setOpenUPIStatus(true);
        setOpenUPIStatusDialog(true);
        setOrderAmount(props.amountEntered);
      }
    } else {
      CustomConsole("order status UPI else block");
    }
  }, [upiOrderStatus, upiOrderStatus.message]);

  //function for downloading qr image
  const handleQrImgDownload = () => {
    CustomConsole(props.qrCodeLink);
    saveAs(props.qrCodeLink, "image.png");
    CustomConsole(props.payType);
    CustomConsole(props.qrCode);
  };

  const handleBackdropClick = (event) => {
    event.preventDefault(); // Prevents the default behavior of closing the dialog
  };
  return (
    <div>
      <Dialog
        fullScreen
        open={props.upiTransactionPage}
        onClose={handleClose}
        // onClose={cancelUpiPay}
        BackdropProps={{
          onClick: handleBackdropClick,
        }}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", backgroundColor: appColors.homePageCard }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setDialogOpen(true)}
              aria-label="close"
              sx={{ color: appColors.secondaryText }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: appColors.secondaryText }}
              variant="h6"
              component="div"
            >
              Payment request
            </Typography>
          </Toolbar>
        </AppBar>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            alignItems: "center",
            bgcolor: appColors.cardColor,
            height: "100vh",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Material.Typography
              sx={{
                display: "flex",
                flexWrap: "wrap",
                marginTop: "30px",
                fontWeight: "bold",
                fontSize: "1.3rem",
                color: appColors.secondaryText,
                // width: "600px",
                textAlign: "center",
              }}
            >
              Don't close or refresh this window.
            </Material.Typography>
            {props.qrCodeLink ? (
              <>
                <Material.Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                  }}
                >
                  <Material.Typography
                    variant="overline"
                    display="block"
                    gutterBottom
                    sx={{
                      fontSize: "14px",
                      fontWeight: "bold",
                      color: appColors.darkGray,
                    }}
                  >
                    Scan and Pay
                  </Material.Typography>
                  <img src={props.qrCodeLink} alt="qrCode" />
                  <Material.Button
                    // endIcon={DownloadIcon}
                    onClick={handleQrImgDownload}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: "10px",
                      backgroundColor: appColors.textColorNew,
                      color: "#fff",
                    }}
                    // startIcon={DownloadIcon}
                  >
                    QR Code
                    <DownloadIcon />
                  </Material.Button>
                </Material.Box>
              </>
            ) : (
              <>
                <Divider />
                <Material.Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "1rem",
                  }}
                >
                  <img alt="" src={canIcon} />
                </Material.Box>
                {/* <Material.Box sx={{ marginTop: "20px" }}>
                  <Material.Button
                    // endIcon={DownloadIcon}
                    onClick={props.handelPaymentRetry}
                    variant="contained"
                    size="small"
                    sx={{
                      marginTop: "10px",
                      backgroundColor: appColors.textColorNew,
                      color: "#fff",
                    }}
                    // startIcon={DownloadIcon}
                  >
                    Try other UPI apps
                  </Material.Button>
                </Material.Box> */}
              </>
            )}

            <Material.Box sx={{ marginTop: "20px" }}>
              {" "}
              <CircularProgress />
            </Material.Box>
            <Material.Typography
              sx={{
                color: "black",
                fontWeight: "bold",
                fontSize: "1.2rem",
                color: appColors.darkGray,
              }}
            >
              Time left
            </Material.Typography>
            <IntervalTimerFunctional />
          </Material.Box>
          <Material.Typography
            sx={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: "30px",
              fontWeight: "bold",
              fontSize: "1.2rem",
              color: appColors.secondaryText,
              // width: "600px",
              textAlign: "center",
            }}
          >
            Waiting for payment confirmaiton from your UPI Application.
          </Material.Typography>
        </Material.Box>
      </Dialog>
      <Dialog
        open={dialogOpen}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Material.DialogContent sx={{ background: appColors.secondaryCard }}>
          <Material.Typography
            sx={{
              color: appColors.secondaryText,
              fontWeight: "bold",
            }}
          >
            Closing this window may affect this payment. Do you want to
            continue?
          </Material.Typography>
        </Material.DialogContent>
        <Material.DialogActions
          sx={{
            background: appColors.secondaryCard,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={handleCloseIcon}
            size="small"
            variant="contained"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            Yes
          </Button>
          <Button
            onClick={() => setDialogOpen(false)}
            size="small"
            variant="contained"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
            }}
          >
            No
          </Button>
        </Material.DialogActions>
        {/* <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={!props.openUPIStatus ? true : false}
        >
          <Box
            sx={{
              height: "80vh",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={BiconLoader}
              alt="Loading..."
              style={{
                height: "50px",
                width: "50px",
                backgroundColor: "transparent",
              }}
            />
          </Box>
        </Backdrop> */}
      </Dialog>
      {props.openUPIStatus ? (
        <>
          <UPIPayStatusDialog
            open={props.openUPIStatus}
            setOpenUPIStatus={props.setOpenUPIStatus}
            setOpenUPIStatusDialog={setOpenUPIStatusDialog}
            amount={orderAmount}
            handleClose={props.handleClose}
            orderId={props.currentOrderId}
            handleCloseRPDialog={handleCloseRPDialog}
            handlePdwBSDialogClose={props.handlePdwBSDialogClose}
            handlePaymentCancel={props.handlePaymentCancel}
            error={error}
            inAccountPage={props.inAccountPage}
            setUpiPaymentDialogOpen={props.setUpiPaymentDialogOpen}
            setUpiPay={props.setUpiPay}
            inBookingPage={props.inBookingPage}
            setOpenEnterAmountDialog={props.setOpenEnterAmountDialog}
            setUpiTransactionPage={props.setUpiTransactionPage}
            setUpiDataReceived={props.setUpiDataReceived}
            setPaymentAmount={props.setRazorPayAmount}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
}
