/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { styles } from "./bankAccountCardStyle";
import chipIcon from "../../../Assets/newMobileIcon/chip.svg";
import { makeStyles } from "@mui/styles";
import Bicon from "../../../Assets/newMobileIcon/Water wallet without Card Background.svg";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Link,
  Typography,
} from "@mui/material";

function BankAccountCard({
  bankdetails,
  handleOpenDeleteDia,
  handleOpenEditDia,
}) {
  const useStyles = makeStyles({
    atmCard: {
      width: 300,
      backgroundColor: appColors.walletCard,
      color: "white",
      borderRadius: 10,
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    cardNumber: {
      fontSize: appTheme.fontSizeSmall,
    },
    cardHolder: {
      fontSize: appTheme.fontSizeSmall,
    },
    expiration: {
      fontSize: appTheme.fontSizeSmall,
    },
  });
  const handleRemove = () => {
    handleOpenDeleteDia(bankdetails.account_id);
  };
  const handleEdit = () => {
    handleOpenEditDia(bankdetails);
  };

  const classes = useStyles();
  return (
    <Box sx={styles.mainpaper}>
      <Grid container direction="column">
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card className={classes.atmCard}>
            <CardContent>
              <Box sx={styles.chipCard1}>
                <Box>
                  <Typography className={classes.cardNumber}>
                    {" "}
                    {bankdetails.account_name}
                  </Typography>
                  <Typography className={classes.expiration}>
                    {bankdetails.bank_name}
                  </Typography>
                </Box>
                <Box>{bankdetails.is_primary ? " Primary Account" : ""}</Box>
              </Box>
              <Box sx={styles.chipCard}>
                <Box>
                  <Typography className={classes.cardNumber}>
                    Acct: {bankdetails.account_number}
                  </Typography>
                  <Typography className={classes.cardHolder}>
                    Ifsc: {bankdetails.ifsc_code}
                  </Typography>
                </Box>
                <Box>
                  <img
                    alt=""
                    src={chipIcon}
                    style={{ height: "2rem", marginTop: "2rem" }}
                  />
                </Box>
              </Box>
            </CardContent>
            <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Link
                onClick={handleEdit}
                underline="none"
                sx={{ zIndex: 1, color: "white", cursor: "pointer" }}
              >
                Edit
              </Link>
              <Link
                onClick={handleRemove}
                underline="none"
                sx={{ zIndex: 1, cursor: "pointer", color: appColors.white }}
              >
                Remove
              </Link>
            </CardActions>
            <img
              alt=""
              src={Bicon}
              style={{
                position: "absolute",
                height: "15rem",
                marginLeft: "4rem",
                objectFit: "fil",
              }}
            />
            {/* <img alt=""
              src={Bicon}
              style={{ position: "absolute", marginLeft: "4rem", objectFit: "cover", zIndex: 1 }}
            /> */}
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default BankAccountCard;
