/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./../src/Redux/Store";
import { ThemeProvider } from "@mui/material/styles";
import App from "./App";
import "./index.css";
import theme from "./theme";
// document.addEventListener('contextmenu', event => event.preventDefault());

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
