/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import * as Material from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";
import { paymentStatus } from "../../Assets/Constants/AppMetaData";
import appColors from "../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function FilterDepositDetails(props) {
  //   const [paymentMethod, setPaymentMethod] = React.useState("");
  //   const [tankers, setTankers] = React.useState("3");
  //   const [status, setStatus] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");
  const [paymentStatus, setPaymentStatus] = React.useState("");
  //   React.useEffect(() => {}, [paymentMethod, tankers, status]);
  React.useEffect(() => { }, [orderBy, paymentStatus]);
  const handleClose = () => {
    setOrderBy("");
    setPaymentStatus("");
    props.handleCloseFilter();
  };

  const onFilterSubmit = () => {
    const filterObject = {
      //   paymentMethod: paymentMethod,
      //   tankers: tankers,
      //   status: status,
      orderBy: orderBy,
      paymentStatus: paymentStatus,
    };
    // console.log(filterObject);
    props.handleFilterSubmit(filterObject);
    props.handleCloseFilter();
  };

  // const onFilterSubmit = () => {
  //   const filterObject = {
  //     tdsRange: tdsRange,
  //     capacityRange: capacityRange,
  //     priceRange: priceRange,
  //     supplierRatings: supplierRatings,
  //     isVerified: isVerified,
  //   };
  //   props.handleFilterData(filterObject);
  //   handleClose();
  // };

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
    >

      <Material.DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "centers",
          bgcolor:appColors.secondaryCard
        }}
      >
        {/* Date */}
        <Material.Typography sx={{ fontWeight: "bold", color: appColors.lightGreen, fontSize: "1.2rem" }}>
          Filter
        </Material.Typography>
        <Material.FormControl sx={{ marginTop: "10px" }}>
          <Material.InputLabel>Sort By Date</Material.InputLabel>
          <Material.Select
            variant="standard"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            sx={{bgcolor:"#fff"}}
          >
            <Material.MenuItem value="ASC">Old First</Material.MenuItem>
            <Material.MenuItem value="DESC">Recent First</Material.MenuItem>
          </Material.Select>
        </Material.FormControl>

        {/* payment */}
        <Material.FormControl sx={{ marginTop: "25px" }}>
          <Material.InputLabel>Payment Status</Material.InputLabel>

          <Material.Select
            variant="standard"
            value={paymentStatus}
            onChange={(e) => setPaymentStatus(e.target.value)}
            sx={{bgcolor:"#fff"}}
          >
            <Material.MenuItem value="true">Paid</Material.MenuItem>
            <Material.MenuItem value="false">Unpaid</Material.MenuItem>
          </Material.Select>
        </Material.FormControl>

        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Material.Button
            onClick={handleClose}
            size="small"
            sx={{
              textTransformation: "none",
              backgroundColor: appColors.Failed,
              color: "white",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: appColors.Failed,
              },
            }}
          >
            Close
          </Material.Button>
          <Material.Button
            onClick={onFilterSubmit}
            size="small"
            sx={{
              textTransformation: "none",
              backgroundColor: appColors.textColorNew,
              marginLeft: "10px",
              color: "white",
              "&:hover": {
                backgroundColor: appColors.textColorNew,
              },
            }}
          >
            Filter
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
