/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../../Assets/AppTheme/appColors";

export const deleteAccountStyles = {
  stepOneTypo: {
    color: "#fff",
    margin: "1rem",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "center",
    justifyContent: "center",
    color: appColors.white,
  },
  stepOneSubTypo: {
    color: "#fff",
    margin: "0 1rem",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "flex-start",
    fontSize: ".9rem",
    color: appColors.white,
    marginBottom: ".5rem",
  },
  acceptButton: {
    color: appColors.white,
    boxShadow:5,
 
  },
  closeIcon: {
    position: "absolute",
    right: 0,
    top: 0,
    cursor: "pointer",
    height: "30px",
  },
  stepTwoSubTypo:{
    color: "#fff",
    marginTop: "1rem",
    display: "flex",
    flexWrap: "wrap",
    textAlign: "flex-start",
    fontSize: ".9rem",
    color: appColors.white,
    marginBottom: ".5rem",
  }
};
