// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";

export const skuBookingCardsStyle = {
  orderButtonStyle: {
    backgroundColor: appColors.textColorNew,
    height: "1.8rem",
    width: "1.4rem",
    display: "flex",
    justifyContent: "center",
    borderRadius: "0.3rem 0 0 .3rem",
    color: "#fff",
  },
  orderButtonStylePlus: {
    backgroundColor: appColors.textColorNew,
    height: "1.8rem",
    width: "1.4rem",
    display: "flex",
    justifyContent: "center",
    borderRadius: "0 0.3rem .3rem 0",
    color: "#fff",
  },
  canQtyStyle: {
    backgroundColor: "#bfefff",
    height: "1.8rem",
    width: "2.5rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    fontWeight: "600",
    fontSize: appTheme.fontSizeSmall,
  },
  waterdropStyles: {
    backgroundColor: "#bfefff",
    height: "1.8rem",
    width: "2.8rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    fontWeight: "600",
    fontSize: appTheme.fontSizeSmall,
  },
};
