// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 24/Nov/2023
// File Name: Circles.jsx
// Author: Gowtham Prasath

/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */



import React, { Component } from "react";
import PropTypes from "prop-types";
import { render, unmountComponentAtNode } from "react-dom";
import Circles from "./Circles";

export default class FeatureDiscoveryPrompt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pos: {
        top: 1,
        right: 1,
        bottom: 1,
        left: 1,
        width: 1,
      },
    };
  }

  componentDidMount() {
    this.portal = document.createElement("div");
    document.body.appendChild(this.portal);
    this.portal.style.position = "fixed";
    this.portal.style.zIndex = 13333;
    this.portal.style.top = 0;
    this.portal.style.left = 0;
    this.renderCircle();
  }

  componentWillUnmount() {
    unmountComponentAtNode(this.portal);
    this.portal = null;
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props?.open !== undefined &&
      nextProps.open !== undefined &&
      nextProps.open &&
      !this.props?.open
    ) {
      if (this.circles) {
        this.circles.open();
      }
    } else if (!nextProps.open && this.props.open) {
      if (this.circles) {
        this.circles.close();
      }
    }
  }

  componentDidUpdate() {
    this.renderCircle();
  }

  renderCircle() {
    if (this.circles == null) {
      const { backgroundColor, description, title } = this.props;

      render(
        <Circles
          backgroundColor={backgroundColor}
          description={description}
          element={this}
          // onClose={onClose}
          ref={(ref) => {
            this.circles = ref;
          }}
          title={title}
          setPromptStates={this.props.setPromptStates}
          componentState={this.props.componentState}
          triggerState={this.props.triggerState}
          setmenuDialogOpean={this.props.setmenuDialogOpean}
          subdescription={this.props.subdescription}
          subdescription1={this.props.subdescription1}
        />,
        this.portal
      );
    }
  }

  render() {
    const child = React.Children.only(this.props.children);
    return React.cloneElement(child, {
      style: {
        ...child.props.style,
        position:
          child.props.style != null &&
          child.props.style.position != null &&
          child.props.style.position !== "static"
            ? child.props.style.position
            : "relative",
        zIndex: 2,
      },
    });
  }
}


