/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import closeIcon from "../../../Assets/newMobileIcon/Booking/closeIconWhite.svg";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InformationDialog(props) {
  //For closing dialog box
  const handleClose = () => {
    props.setOpenInfoDialog(false);
  };

  return (
    <div>
      <Dialog
        open={props.openInfoDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: appColors.dialogBackGround }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Box sx={{ backgroundColor: appColors.infoCardColor }}>
          <Box
            sx={{ display: "flex", justifyContent: "center", margin: "1rem" }}
          >
            <img
              src={infoIcon}
              alt=""
              style={{ height: "120px", width: "120px" }}
            />
          </Box>
          <Typography
            sx={{
              color: "#fff",
              margin: ".5rem",
              fontSize: "1.2rem",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            {props.infoDialogText === "OTP"
              ? "OTP-Less Delivery"
              : "Same Day Delivery?"}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              margin: "1rem",
              display: "flex",
              flexWrap: "wrap",
              textAlign: "center",
            }}
          >
            {props.infoDialogText === "OTP"
              ? props.otpLessDiss
              : `You are opting for same day delivery. You will be charged ₹${props.extraCharges} per product.`}
          </Typography>
          <img
            alt=""
            src={closeIcon}
            style={{
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              height: "30px",
            }}
            onClick={handleClose}
          />
        </Box>
      </Dialog>
    </div>
  );
}
