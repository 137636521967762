import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import CustomConsole from "../../coustomConsole";
export const GetBanners = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/banners/consumer/getBanners`);

      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        // CustomConsole("REsponse: " + JSON.stringify(response.data));
        CustomConsole("Response in getting banners");
        CustomConsole(response);
        dispatch({
          type: actionType.GET_BANNERS,
          payload: response.data,
        });
        // toast.dark(response.data.msg, {
        //   position: "bottom-right",
        //   autoClose: 3000,
        //   type: "success",
        // });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
    //   toast.dark(error.response.data.msg, {
    //     position: "bottom-right",
    //     autoClose: 3000,
    //     type: "error",
    //   });
    }
  };
};


//action to store banner images
export const StoreBannerImages = (Images) => ({
  type: actionType.STORE_BANNER_IMAGES,
  payload: { data: Images, statusFlag: false },
});

//loading false Api
export const SetLoadinngFalse = (data) => ({
  type: actionType.STORE_BANNER_IMAGES_FLAG,
  payload: { data: data,},
  
});
