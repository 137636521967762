/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

//Intial state
const initialAccountsState = {
  error: true,
  message: "",
  virtualBankDetails: {},
  accounts: [],
  isAccountListUpdated: false,
  isAccountDeleted: false,
  isAccountAdded: false,
  isVbDetailsExists: false,
}; 

//Reducer wll peform based on action type
const myAccountReducer = (state = initialAccountsState, action) => {
  switch (action.type) {
    case actionType.GET_VIRTUAL_ACCOUNTS_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        virtualBankDetails: action.payload.data, 
        isVbDetailsExists: action.payload.data,
      };
    case actionType.GET_USER_ACCOUNTS_LIST:
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.data.msg,
        accounts: action.payload.data.accounts,
        isAccountListUpdated: action.payload.statusFlag,
      };
    case actionType.ADD_NEW_ACCOUNT:
    case actionType.EDIT_EXISTING_ACCOUNT:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.data,
        isAccountAdded: action.payload.statusFlag,
      };
    case actionType.DELETE_USER_BANK_ACCOUNT:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isAccountDeleted: action.payload.statusFlag,
      };
    case actionType.SIGN_OUT:
      return {
        ...initialAccountsState,
      };
    default:
      return state;
  }
};

export default myAccountReducer;
