/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Link from "@mui/material/Link";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog";
export default function OtpCard({ recentActivitiesData }) {
  const [otpFlag, setOtpFlag] = React.useState(false);
  // console.log("-----------------------------------------------------------");
  // console.log(otpData);
  const handelOtpClick = () => {
    setOtpFlag(!otpFlag);
  };

  return (
    <Card
      sx={{
        width: "6.4rem",
        height: "6.5rem",
        // background: "rgb(208,221,242)",
        backgroundColor: "#1e3e70",
        paddingBottom: "0",
        color: "#fff",
        borderTopRightRadius: "15px",
        borderBottomLeftRadius: "15px",
      }}
    >
      <CardContent>
        <Typography
          sx={{ fontSize: 10, marginBottom: ".4rem", fontWeight: "bold" }}
        >
          {recentActivitiesData.allDetails?.otp?.regular.length > 0
            ? recentActivitiesData?.allDetails?.otp?.regular[0].order_id
            : recentActivitiesData?.allDetails?.otp?.replacement.length > 0
            ? recentActivitiesData?.allDetails?.otp?.replacement[0].order_id
            : recentActivitiesData?.allDetails?.otp?.return.length > 0
            ? recentActivitiesData?.allDetails?.otp?.return[0]
                .return_can_order_id
            : "-"}
        </Typography>
        <Typography
          sx={{ fontSize: 10, marginBottom: ".4rem", fontWeight: "bold" }}
        >
          <span>OTP</span>{" "}
          <span>
            {recentActivitiesData.allDetails?.otp?.regular.length > 0 &&
            recentActivitiesData?.allDetails?.otp?.regular[0]
              .delivery_otp_type !== null &&
            recentActivitiesData?.allDetails?.otp?.regular[0]
              .delivery_otp_type !== "OTP-LESS"
              ? recentActivitiesData?.allDetails?.otp?.regular[0].delivery_otp
              : recentActivitiesData?.allDetails?.otp?.replacement.length > 0 &&
                recentActivitiesData.allDetails?.otp?.regular?.length === 0
              ? recentActivitiesData?.allDetails?.otp?.replacement[0].otp
              : recentActivitiesData?.allDetails?.otp?.return.length > 0 &&
                recentActivitiesData.allDetails?.otp?.regular?.length === 0
              ? recentActivitiesData?.allDetails?.otp?.return[0].return_can_otp
              : "LESS"}
          </span>
        </Typography>

        <>
          {recentActivitiesData?.allDetails?.otp?.regular.length > 0 ? (
            <Typography
              sx={{ fontSize: 10, color: "white", fontWeight: "bold" }}
            >
              {recentActivitiesData?.allDetails?.otp?.regular[0]
                .packaged_drinking_water?.delivery_slots !== undefined &&
              recentActivitiesData?.allDetails?.otp?.regular[0]
                .packaged_drinking_water?.delivery_slots !== "" &&
              recentActivitiesData?.allDetails?.otp?.regular[0]
                .packaged_drinking_water?.delivery_slots !== null
                ? recentActivitiesData?.allDetails?.otp?.regular[0]
                    .packaged_drinking_water?.delivery_slots
                    .admin_selected_delivery_slot !== undefined &&
                  recentActivitiesData?.allDetails?.otp?.regular[0]
                    .packaged_drinking_water?.delivery_slots
                    .admin_selected_delivery_slot !== "" &&
                  recentActivitiesData?.allDetails?.otp?.regular[0]
                    .packaged_drinking_water?.delivery_slots
                    .admin_selected_delivery_slot !== null
                  ? recentActivitiesData?.allDetails?.otp?.regular[0]
                      .packaged_drinking_water?.delivery_slots
                      .admin_selected_delivery_slot
                  : recentActivitiesData?.allDetails?.otp?.regular[0]
                      .packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot !== undefined &&
                    recentActivitiesData?.allDetails?.otp?.regular[0]
                      .packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot !== "" &&
                    recentActivitiesData?.allDetails?.otp?.regular[0]
                      .packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot !== null
                  ? recentActivitiesData?.allDetails?.otp?.regular[0]
                      .packaged_drinking_water?.delivery_slots
                      .supplier_selected_delivery_slot
                  : "-"
                : "-"}
            </Typography>
          ) : (
            ""
          )}
        </>
        {recentActivitiesData?.allDetails?.otp?.regular?.length === 0 &&
        recentActivitiesData?.allDetails?.otp?.return?.length === 0 &&
        recentActivitiesData?.allDetails?.otp?.replacement?.length === 0 ? (
          ""
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: ".3rem",
            }}
          >
            <Link
              onClick={handelOtpClick}
              underline="none"
              sx={{ color: "#08D5C9", fontSize: "12px" }}
            >
              More
            </Link>
          </Box>
        )}
      </CardContent>
      {otpFlag && <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} />}
    </Card>
  );
}
