/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */


import { Box, Typography } from "@mui/material";
import React from "react";
import canImage from "../../Assets/newMobileIcon/ORDER.svg"
import booking from "../../Assets/newMobileIcon/FAQ(Booking).svg"
import otp from "../../Assets/newMobileIcon/FAQ(Otp & order).svg"
import appColors from "../../Assets/AppTheme/appColors";
import Location from "../../Assets/newMobileIcon/Booking/Delivery Address.svg";
import area from "../../Assets/newMobileIcon/Profile.svg";
import { toggleState } from "../../Redux/actions/appMetaDataAction";
import Transaction from "../../Assets/newMobileIcon/Transactions.svg";
import Carrear from "../../Assets/newMobileIcon/Careear.svg";
import { useDispatch, useSelector } from "react-redux";
import Qrcode from "../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import ReferandEarn from "../../Assets/newMobileIcon/REFER.svg";
import Cancel from "../../Assets/newMobileIcon/cancel.svg";
import refund from "../../Assets/newMobileIcon/Refund.svg";
import quality from "../../Assets/newMobileIcon/quality.svg";
import address from "../../Assets/newMobileIcon/Address Book.svg"
import delivery from "../../Assets/newMobileIcon/delivery.svg";
import support from "../../Assets/newMobileIcon/Customer.svg"
import about from "../../Assets/newMobileIcon/App Tour.svg";
import promo from "../../Assets/newMobileIcon/Booking/Coupon.svg"
import { FaqHeaderContentStyles } from "./FaqStyles";
import { makeStyles } from '@mui/styles';






export default function FaqHeaderContent({
    bookingText,
    setBookingText,
    setShowText,
    showText
}) {
    const dispatch = useDispatch();
    //Function for setting up value for faq content
    const handelCard = (value) => {
        setBookingText(value)
        setShowText(true)
        dispatch(toggleState());
        if (window.innerWidth < 1200 && window.innerWidth > 600) {
            scrollToBottom();
        } else {
            scrollToTop();
        }
    }

    //For scrolling to bottom of page
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    }

    //Scroll to top while clicking headers
    const scrollToTop = () => {
        window.scrollTo({
            top: 0, // Set the top position to 0 to scroll to the top of the page
            behavior: 'smooth', // Optional: Smooth scrolling animation
        });
    }

    return (
        <>
            <Box sx={FaqHeaderContentStyles.mainBox}>
                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'CONTACT' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,
                    },
                ]} onClick={() => handelCard("CONTACT")}>
                    <Box>
                        <img alt="" src={support} style={{ height: "30px", width: "25px" }}
                        />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Contact</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>



                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'ABOUT' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,
                    },
                ]} onClick={() => handelCard("ABOUT")}>
                    <Box>
                        <img alt="" src={about} style={{ width: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>About</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>


                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'OUR PRODUCTS' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("OUR PRODUCTS")}>
                    <Box>
                        <img alt="" src={canImage} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Our Products</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>

                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'CAREERS' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("CAREERS")}>
                    <Box>
                        <img alt="" src={Carrear} style={{ height: "20px", height: "20px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Careers</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>



                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'AREA OF SERVICE' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("AREA OF SERVICE")}>
                    <Box>
                        <img alt="" src={Location} style={{ height: "25px", width: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Serviceable Area</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>



                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'REGISTRATION AND BOOKING' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("REGISTRATION AND BOOKING")}>
                    <Box>
                        <img alt="" src={canImage} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Registration and Booking</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>



                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'QR CODE' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("QR CODE")}>
                    <Box>
                        <img alt="" src={Qrcode} style={{ height: "30px", width: "30px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>QR Code</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>


                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'QUALITY' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("QUALITY")}>
                    <Box>
                        <img alt="" src={quality} style={{ width: "25px", height: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Quality</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>

                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'Significance of OTP for delivery' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("Significance of OTP for delivery")}>
                    <Box>
                        <img alt="" src={refund} style={{ width: "25px", height: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Significance of OTP for delivery</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>

                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'CANCELLATION' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("CANCELLATION")}>
                    <Box>
                        <img alt="" src={Cancel} style={{ width: "25px", height: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Order Cancellation and Refund</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>


                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'DELIVERY' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("DELIVERY")}>
                    <Box>
                        <img alt="" src={delivery} style={{ height: "25px", width: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Delivery</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>





                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'REFER & EARN' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("REFER & EARN")}>
                    <Box>
                        <img alt="" src={ReferandEarn} style={{ width: "25px", height: "25px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>Refer & Earn</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>

                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'BOOKWATER PROMOTIONS' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("BOOKWATER PROMOTIONS")}>
                    <Box>
                        <img alt="" src={promo} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>BookWater Promotions</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>



                <Box sx={[
                    FaqHeaderContentStyles.boxContent,
                    {
                        border: bookingText === 'BookWater Account Deactivation' ? "2px solid #068fff" : null,
                        color: appColors.darkGray,

                    },
                ]} onClick={() => handelCard("BookWater Account Deactivation")}>
                    <Box>
                        <img alt="" src={area} style={{ width: "30px" }} />
                    </Box>
                    <Box sx={FaqHeaderContentStyles.boxInnerContent}>
                        <Typography sx={FaqHeaderContentStyles.BoxInnerText}>BookWater Account Deactivation</Typography>
                        {/* <Typography sx={FaqHeaderContentStyles.BoxInnerText1}>A small description of the topic comes  of here</Typography> */}
                    </Box>
                </Box>
            </Box>
        </>
    )
}