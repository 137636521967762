import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import moment from "moment";
import * as orderActions from "../../../Redux/actions/ordersAction";
import KeyboardBackspaceTwoToneIcon from "@mui/icons-material/KeyboardBackspaceTwoTone";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { Box } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import { styles } from "./filtercss";
import "../../../Components/styles.css";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FilterDialogOrders({
  filterDialog,
  setfilterDialog,
  options,
  setreqobj,
  ApiCall,
  reqObj,
  setPageNumber
}) {
  const dispatch = useDispatch();
  const today = moment().add(7, "days").format("YYYY-MM-DD");
  //state for the value of dates
  const [fromValue, setFromValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const [toValue, setToValue] = React.useState(moment().format("YYYY-MM-DD"));
  const [fromDateValue, setFromDateValue] = React.useState(
    moment().subtract(180, "days").format("YYYY-MM-DD")
  );
  const [toDateValue, setToDateValue] = React.useState(
    moment().format("YYYY-MM-DD")
  );
  const handleChangeFrom = (e) => {
    setFromDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    
    setFromValue(momentDate);
    setreqobj((prev) => ({
      ...prev,
      delivery_from_date: momentDate,
    }));
    setPageNumber(1)
  };
  const handleClose = () => {
    setfilterDialog(false);
  };

  const FilterButton = (value) => {
    value === options.PAYMENT_PENDING
      ? (styles.buttonFilter1.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter1.backgroundColor = appColors.textColorNew);
    value === options.ONGOING_TRIPS
      ? (styles.buttonFilter2.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter2.backgroundColor = appColors.textColorNew);
    value === options.COMPLETED_TRIPS
      ? (styles.buttonFilter3.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter3.backgroundColor = appColors.textColorNew);
    value === options.DELIVERED
      ? (styles.buttonFilter6.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter6.backgroundColor = appColors.textColorNew);
    value === options.CANCELLED
      ? (styles.buttonFilter7.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter7.backgroundColor = appColors.textColorNew);
    setreqobj((prev) => ({
      ...prev,
      order_status:
        value === options.PAYMENT_PENDING
          ? 100
          : value === options.ONGOING_TRIPS
          ? 200
          : value === options.CANCELLED
          ? 400
          : value === options.DELIVERED
          ? 500
          : "",
    }));
    setPageNumber(1)
  };
  const SortFilterButton = (value) => {
    value === "DESC"
      ? (styles.buttonFilter4.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter4.backgroundColor = appColors.textColorNew);
    value === "ASC"
      ? (styles.buttonFilter5.backgroundColor = appColors.lightGreen)
      : (styles.buttonFilter5.backgroundColor = appColors.textColorNew);
    // Create a new array by mapping over the existing sort_by array
    const newSortBy = reqObj.sort_by.map((item) => {
      if (item.key === "order_id") {
        return { ...item, value: value };
      }
      return item;
    });
    setreqobj((prevState) => ({
      ...prevState,
      sort_by: newSortBy,
    }));
    setPageNumber(1)
  };
  const handleChangeTo = (e) => {
    setToDateValue(e.target.value);
    const momentDate = moment(e.target.value).format("YYYY-MM-DD");
    
    setToValue(momentDate);
    setreqobj((prev) => ({
      ...prev,
      delivery_to_date: momentDate,
    }));
    setPageNumber(1)
  };

  const handelApplyFilter = () => {
    ApiCall();
    handleClose();
  };
  const handelApplyClearAllFilter = () => {
    const reqObj = {
      delivery_from_date: moment().subtract(28, "days").format("YYYY-MM-DD"),
      delivery_to_date: moment().add(7,"days").format("YYYY-MM-DD"),
      page_size: 10,
      order_status: "",
      delivery_status: "",
      page_number: 1,
      sort_by: [{ key: "order_id", value: "DESC" }],
    };
    dispatch(orderActions.GetPdwAllOrders(reqObj));
    handleClose();
    FilterButton("");
  };

  return (
    <div>
      <Dialog
        open={filterDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{ position: "relative", backgroundColor: appColors.cardColor }}
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
              sx={{ color: appColors.secondaryText }}
            >
              <KeyboardBackspaceTwoToneIcon />
            </IconButton>
            <Typography
              sx={{ ml: 2, flex: 1, color: appColors.secondaryText }}
              variant="h6"
              component="div"
            >
              Back
            </Typography>
          </Toolbar>
        </AppBar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            backgroundColor: appColors.cardColor,
            padding: "2rem",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              color: appColors.secondaryText,
              fontWeight: "bold",
              marginBottom: "1rem",
            }}
          >
            Filters
          </Typography>
          <Typography sx={styles.buttonHeader}>Order Status</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              sx={styles.buttonFilter1}
              onClick={() => FilterButton(options.PAYMENT_PENDING)}
            >
              <span style={{ fontSize: ".7rem" }}>Unpaid</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={styles.buttonFilter2}
              onClick={() => FilterButton(options.ONGOING_TRIPS)}
            >
              <span style={{ fontSize: ".7rem" }}>Ongoing</span>{" "}
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={styles.buttonFilter6}
              onClick={() => FilterButton(options.DELIVERED)}
            >
              <span style={{ fontSize: ".7rem" }}>Completed</span>
            </Button>
          </Box>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              variant="contained"
              sx={styles.buttonFilter7}
              onClick={() => FilterButton(options.CANCELLED)}
            >
              <span style={{ fontSize: ".7rem" }}>Cancelled</span>
            </Button>
            <Button
              size="small"
              variant="contained"
              sx={styles.buttonFilter3}
              onClick={() => FilterButton(options.COMPLETED_TRIPS)}
            >
              <span style={{ fontSize: ".7rem" }}>All Orders</span>
            </Button>
          </Box>
          <Typography sx={styles.buttonHeader}>Sort By Date</Typography>
          <Box sx={styles.ButtonContainer}>
            <Button
              size="small"
              sx={styles.buttonFilter4}
              onClick={() => SortFilterButton("DESC")}
            >
              <span style={{ fontSize: ".7rem" }}>Recent First</span>
            </Button>
            <Button
              size="small"
              sx={styles.buttonFilter5}
              onClick={() => SortFilterButton("ASC")}
            >
              <span style={{ fontSize: ".7rem" }}>Old First</span>
            </Button>
          </Box>
          <Typography sx={styles.buttonHeader}>Sort by Date Range</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            <Box>
              <label
                htmlFor="fromDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                From:
              </label>
              <input
                type="date"
                id="fromDay"
                name="from"
                value={fromDateValue}
                onChange={handleChangeFrom}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  borderRadius: "10px",
                  marginLeft: "5px",
                  width: "120px",
                }}
                max={today}
              />
            </Box>
            <Box>
              <label
                htmlFor="toDay"
                style={{
                  fontSize: "12px",
                  fontWeight: "600",
                  color: appColors.darkGray,
                }}
              >
                To:&nbsp;&nbsp;&nbsp;&nbsp;
              </label>
              <input
                type="date"
                id="toDay"
                name="to"
                value={toDateValue}
                onChange={handleChangeTo}
                style={{
                  backgroundColor: appColors.textColorNew,
                  border: 0,
                  color: appColors.white,
                  padding: "10px",
                  fontSize: "12px",
                  height: "30px",
                  fontWeight: "500",
                  width: "120px",
                  borderRadius: "10px",
                  marginLeft: "5px",
                }}
              />
            </Box>
          </Box>
          <Box className="box-align" sx={{ marginTop: "2rem", gap: 1 }}>
            <Button sx={styles.applyFilter} onClick={handelApplyClearAllFilter}>
              Clear 
            </Button>
            <Button sx={styles.applyFilter} onClick={handelApplyFilter}>
              Apply 
            </Button>
          </Box>
        </Box>
      </Dialog>
    </div>
  );
}
