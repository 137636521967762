// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import WaterWalletIcon from "../../../Assets/newMobileIcon/Booking/Waterwallet - Colour 1.svg";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import UPISvg from "../../../Assets/AppIcons/PGUPI.svg";
import razorPay from "../../../Assets/newMobileIcon/Booking/Cards.svg";
import scanQr from "../../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import appColors from "../../../Assets/AppTheme/appColors";
import { Box, Card, Typography } from "@mui/material";
import ProceedButton from "../../../Components/Buttons/ProceedButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import appTheme from "../../../Assets/AppTheme/appTheme";
export default function PaymentCardSkuWeb({
  setPaymentDialogOpen,
  toggleDrawer,
  setpaymentFlag,
  handelBookingFunction,
  paymentType,
  amountEntered,
  skuSubscribeOrderDetails,
  payType,
  isUpiIdValid,
  samedayDelivery,
  secondaryPayment,
  useWalletBalancePayFlag,
  invalidProducts,
}) {
  const [primaryAddress, setprimaryAddress] = React.useState([]);
  const iconList = IconListManager();
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const [buttonFlag, setButtonFlag] = React.useState(false);
  //function for handling payment and booking action
  const handelPaymentFlag = () => {
    // setpaymentFlag(true);
    handelBookingFunction();
    setButtonFlag(true);
    setTimeout(() => {
      setButtonFlag(false);
    }, 2000);
  };

  //useEffect to disable button if selected days is 0
  React.useEffect(() => {
    if (
      (pageName === "subscribe" &&
        skuSubscribeOrderDetails?.selectedDays.length === 0) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        skuSubscribeOrderDetails?.selectedDays.length === 0)
    ) {
      setButtonFlag(true);
    } else if (
      (pageName === "subscribe" &&
        skuSubscribeOrderDetails?.selectedDays.length > 0) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        skuSubscribeOrderDetails?.selectedDays.length > 0)
    ) {
      setButtonFlag(false);
    } else if (pageName === null || pageName === undefined) {
      setButtonFlag(true);
    }
  }, [skuSubscribeOrderDetails?.selectedDays]);
  // //useEeefect to check wallet balance
  // React.useEffect(() => {
  //   if (
  //     checkOutDetails?.payment_method?.payment_method === "WALLET" &&
  //     checkOutDetails?.wallet_balance < checkOutDetails?.final_price
  //   ) {
  //     dispatch(
  //       ToastMsgState({
  //         error: true,
  //         containerFlag: true,
  //         msg: `Not having enough wallet balance please recharge`,
  //       })
  //     );
  //   }
  // }, [checkOutDetails]);
  return (
    <>
      <Box sx={{ marginTop: "1.2rem" }}>
        <Typography sx={newBookingstyles.cardHeaderStyle}>
          Payment Method
        </Typography>
        {(checkOutDetails?.discount > 0 && pageName) ||
        (checkOutDetails?.balance_to_pay >= 1 && useWalletBalancePayFlag) ||
        !samedayDelivery ? (
          <Box
            sx={{
              // height: "1.2rem",
              width: "100%",
              backgroundColor: appColors.textColorNew,
              borderRadius: ".5rem 0.5rem 0rem 0",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              fontSize: ".75rem",
              color: "#fff",
            }}
          >
            {" "}
            <Typography
              sx={{
                fontSize: ".75rem",
                width: "100%",
                borderRadius: ".5rem 0.5rem 0rem 0",
                textAlign: "center",
                backgroundColor: "#8a4cd6",
              }}
            >
              {checkOutDetails?.balance_to_pay >= 1 &&
                checkOutDetails?.wallet_balance >= 1 &&
                useWalletBalancePayFlag &&
                pageName &&
                !pageName.includes("editSubscribe") &&
                pageName !== "subscribe" &&
                `₹${checkOutDetails?.wallet_balance}  will be deducted from your wallet`}
            </Typography>
            <Typography
              sx={{
                fontSize: ".75rem",
                textAlign: "center",
              }}
            >
              {checkOutDetails?.discount > 0 &&
                pageName &&
                !pageName.includes("editSubscribe") &&
                pageName !== "subscribe" &&
                `🎊You saved ₹${checkOutDetails?.discount} for this Order🎊`}
              {/* {checkOutDetails?.discount > 0 && !samedayDelivery ? "," : ""} */}
              {!samedayDelivery ? "  🎊Free Delivery Unlocked  🎊" : ""}
            </Typography>
          </Box>
        ) : null}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            boxShadow: "5",
            borderRadius: "0 0 0.7rem 0.7rem",
          }}
        >
          {amountEntered > 0 && (
            <Box
              sx={{
                backgroundColor: appColors.cardColor,
                color: appColors.darkGray,
                fontWeight: "bold",
                width: "100%",
                borderRadius: ".3rem",
                marginRight: ".3rem",
                height: "45px",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                justifyContent: "space-between", // Align text and icon at opposite ends
                padding: "0.5rem",
                fontSize: "14px",
                borderRadius: "0 0 0 0",
              }}
              onClick={() => setPaymentDialogOpen(true)}
            >
              <span>Payment&nbsp;&nbsp;</span>
              <span>
                <img
                  alt=""
                  src={
                    (payType === 100 &&
                      secondaryPayment === "PAYSHARP" &&
                      useWalletBalancePayFlag) ||
                    (paymentType === "PAYSHARP" && payType === 100)
                      ? UPISvg
                      : (payType === 200 &&
                          secondaryPayment === "PAYSHARP" &&
                          useWalletBalancePayFlag) ||
                        (paymentType === "PAYSHARP" && payType === 200)
                      ? scanQr
                      : paymentType === "RAZORPAY" ||
                        (secondaryPayment === "RAZORPAY" &&
                          useWalletBalancePayFlag)
                      ? razorPay
                      : WaterWalletIcon
                  }
                />
              </span>
              <span style={{ fontSize: "12px" }}>
                {" "}
                {(payType === 100 &&
                  secondaryPayment === "PAYSHARP" &&
                  useWalletBalancePayFlag) ||
                (paymentType === "PAYSHARP" && payType === 100)
                  ? "UPI"
                  : (payType === 200 &&
                      secondaryPayment === "PAYSHARP" &&
                      useWalletBalancePayFlag) ||
                    (paymentType === "PAYSHARP" && payType === 200)
                  ? "SCAN"
                  : paymentType === "RAZORPAY" ||
                    (secondaryPayment === "RAZORPAY" && useWalletBalancePayFlag)
                  ? "RAZORPAY"
                  : "WATER WALLET"}
              </span>
              <KeyboardArrowUpIcon />
            </Box>
          )}
          {pageName === "booking" && (
            <Box
              sx={{
                padding: ".5rem",
                backgroundColor: appColors.cardColor,
                borderRadius: "0 0 0.7rem 0.7rem",
                fontSize: appTheme.fontSizeMedium,
                textAlign: "center",
                color: appColors.darkGray,
              }}
            >
              {amountEntered > 0 ? (
                <ProceedButton
                  totalFare={amountEntered}
                  disabled={buttonFlag || invalidProducts?.length > 0}
                  onClick={() => handelPaymentFlag()}
                />
              ) : (
                <Typography>Amount will be deducted from deposit</Typography>
              )}
            </Box>
          )}

          {pageName && pageName === "subscribe" ? (
            <Box
              sx={{
                padding: ".5rem",
                backgroundColor: appColors.cardColor,
                borderRadius: "0 0 0.7rem 0.7rem",
              }}
            >
              <Box>
                <ProceedButton
                  totalFare={
                    pageName === "subscribe" && amountEntered > 0
                      ? amountEntered
                      : "Subscribe"
                  }
                  disabled={buttonFlag || invalidProducts?.length > 0}
                  onClick={() => handelPaymentFlag()}
                />
              </Box>
            </Box>
          ) : null}

          {pageName && pageName.includes("editSubscribe") ? (
            <Box
              sx={{
                padding: ".5rem",
                backgroundColor: appColors.cardColor,
                borderRadius: "0 0 0.7rem 0.7rem",
              }}
            >
              <Box>
                <ProceedButton
                  totalFare={
                    amountEntered > 0 ? amountEntered : "Update & Proceed"
                  }
                  disabled={buttonFlag || invalidProducts?.length > 0}
                  onClick={() => handelPaymentFlag()}
                />
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </>
  );
}
