// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: FooterMobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState, useEffect } from "react";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import razorPay from "../../Assets/newMobileIcon/Booking/Cards.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Box, Button, Grid, Typography } from "@mui/material";
import scanQr from "../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import { FooterStyles } from "../../Components/Cards/SkuCards/cardStyles";
import appColors from "../../Assets/AppTheme/appColors";
import UPISvg from "../../Assets/AppIcons/PGUPI.svg";

export default function WalletFooter({
  amountEntered,
  upiUserOption,
  handelPaymentFlag,
  setpromoCode,
  amountToAdd,
  setpaymentFlag,
  payment,
  setsubcribePaymentFlag,
  setOpenVbDialog,
  minimumWalletAmount,
  promoCode,
}) {
  const handelBookNow = () => {
    handelPaymentFlag();
  };
  return (
    <>
      <AppBar position="fixed" sx={FooterStyles.footerMainWallet}>
        {promoCode !== "" ? (
          <Box
            sx={{
              height: "1.2rem",
              width: "100%",
              backgroundColor: appColors.textColorNew,
              borderRadius: ".5rem 0.5rem 0rem 0",
              display: "flex",
              justifyContent: "center",
              fontSize: ".8rem",
            }}
          >
            🎊 {promoCode.substring(0, 55)} Applied 🎊
          </Box>
        ) : null}
        <Toolbar>
          <Box sx={FooterStyles.footerIcons}>
            <Toolbar
              sx={{
                padding: 0,
                display: "flex",
                justifyContent: "space-evenly",
                width: "100%",
                marginBottom: ".4rem",
                gap: ".5rem",
              }}
            >
              {amountEntered >= parseInt(minimumWalletAmount) ? (
                <>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      color: "black",
                      borderRadius: ".3rem",
                    }}
                  >
                    <Box
                      sx={{
                        minWidth: "11rem", // Set a fixed width for both boxes (50% each)
                        backgroundColor: "#fff",
                        color: appColors.darkTextColor,
                        fontWeight: "bold",
                        borderRadius: ".3rem",
                        // marginRight: ".3rem",
                        height: "2.2rem",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        justifyContent: "space-between",
                        // padding: "0.5rem 0 0.5rem 0.5rem",
                        fontSize: ".8rem",
                        boxShadow: 2,
                      }}
                      onClick={() => setsubcribePaymentFlag(true)}
                    >
                      &nbsp;Payment
                      <span>
                        <img
                          alt=""
                          src={
                            (payment === 1 && upiUserOption == "3") ||
                            (payment === 1 && upiUserOption == "2")
                              ? UPISvg
                              : payment === 1 && upiUserOption == "1"
                              ? scanQr
                              : payment === 0
                              ? razorPay
                              : ""
                          }
                        />
                      </span>
                      <Box
                        style={{
                          backgroundColor: appColors.textColorNew,
                          height: "2.2rem",
                          width: "2rem",
                          color: "#fff",
                          borderRadius: ".3rem",
                          // marginLeft: ".3rem",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <KeyboardArrowUpIcon />
                      </Box>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      borderRadius: ".3rem",
                      boxShadow: 2,
                    }}
                  >
                    <Button
                      onClick={handelBookNow}
                      sx={{
                        backgroundColor: appColors.textColorNew,
                        width: "100%",
                        color: appColors.white,
                        height: "2.2rem",
                        padding: ".6rem 1rem",
                        borderRadius: ".3rem", // Set the border radius to 0 (no rounding)
                        "&: hover": {
                          backgroundColor: "#2FEAC5 ",
                        },
                      }}
                    >
                      Pay ₹&nbsp;
                      {amountEntered >= parseInt(minimumWalletAmount)
                        ? amountEntered
                        : minimumWalletAmount}
                    </Button>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Toolbar>
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}
