/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import * as React from "react";
import { Box, Grid } from "@mui/material";
import { styles } from "./profileStyles";
import ProfileTopBar from "./ProfileTab";
import ProfileDetails from "./ProfileDetails";
import AddressBook from "../Settings/tabs/myaddressbook/myAddressBook";
import { useLocation } from "react-router-dom";
import ProfileAddressBook from "./profileAddress";

export default function ProfileMainPage() {
  const [processTabs, setProcessTabs] = React.useState("0");
  const { pathname, search } = useLocation();

  // To Control the Page Heading on Click of Back & froward Button

  React.useEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    const name = new URLSearchParams(search).get("pageName");

    if (name === "address") {
      setProcessTabs("1");
    } else {
      setProcessTabs("0");
    }
  }, [pathname, search]);
  return (
    <>
      <Box sx={styles.profileMain}>
        <Grid container direction="column" sx={{}}>
          <Grid item lg={12}>
            <ProfileTopBar
              processTabs={processTabs}
              setProcessTabs={setProcessTabs}
            />
          </Grid>
          {processTabs === "0" ? (
            <Grid item lg={12} sx={styles.profileDetailsGrid}>
              <ProfileDetails />{" "}
            </Grid>
          ) : (
            <Grid item lg={12} sx={styles.addressContainer}>
              <ProfileAddressBook />{" "}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  );
}
