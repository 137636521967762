/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

export const styles = {
  transMain: {
    width: "100%",
    marginTop: "10px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  }, 
  transLists: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 950,
    overflow: "auto", 

    // display: "flex",
    // flexDirection: "column",
    // maxHeight: 750,
    // overflow: "auto",
    // alignItems: "center",
  },
  transPaper: {
    display: "flex",
    padding: "10px",
    marginTop: "20px",
    flexDirection: { xs: "column", sm: "row", md: "row", lg: "row" },
    justifyContent: "center",
    alignItems: "flex-end",
    cursor: "pointer",
    userSelect: "none",
    boxShadow: 3,
  },
  transDetails: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  transPrice: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  headings: {
    color: "#4324e0",
    fontWeight: "800",
    fontSize: "12px",
  },
  answers: {
    color: "#000",
    fontWeight: "800",
    fontSize: "12px",
  },
  paymentStatusFailure: {
    fontSize: "15px",
    color: "#D22B2B",
    fontWeight: "800",
  },
  paymentStatusSuccess: {
    fontSize: "15px",
    color: "#50C878",
    fontWeight: "800",
  },
  order1: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingSpinner: {
    paddingTop: "20%",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  transcationCardHeader:{
    height: 70, backgroundColor: "#152057", display: "flex", flexDirection: "column",
    justifyContent: "center", alignItems: "center",  position: "relative"
},
transcationCardHeaderWallet:{
  height: 70, backgroundColor: "#152057", display: "flex", flexDirection: "column",
  justifyContent: "center", alignItems: "center", position: "relative"
},
transcationCardHeaderDeposit:{
  height: 70, backgroundColor: "#152057", display: "flex", flexDirection: "column",
  justifyContent: "center", alignItems: "center",  position: "relative"
}
};
