import { Grid } from "@mui/material";
import React from "react";
import AddMoney from "./AddMoney";
import "./wallet.css";
import { GetWalletDetails } from "../../Redux/actions/paymentMethodAction";
import { useDispatch, useSelector } from "react-redux";


function WaterWalletNew() {
  const dispatch = useDispatch();
  const walletDetails = useSelector((state) => state.paymentMethods);

  //Effect function for getting wallet details 
  React.useEffect(() => {
    dispatch(GetWalletDetails());
  }, [])


  return (
    <Grid
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      container
      spacing={4}
      sx={{ marginTop: "2rem" }}
      marginBottom={{ xs: "50px", sm: "20px" }}
    >
      <Grid item xs={12}>
        <AddMoney />
      </Grid>
    </Grid>
  );
}

export default WaterWalletNew;
