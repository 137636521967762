/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { Box, Button, Grid, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import AvailedGiftCardList from "../../Components/Cards/GiftCards/availedGiftCardsList";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import AvailableGiftCardList from "../../Components/Cards/GiftCards/availableGiftCards";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import GiftCardDetailsDialog from "../../Components/DialogBoxes/GiftCardDialog/giftCardDetails";
import giftCardHeader from "../../Assets/newMobileIcon/GiftCards/gift card icon blue L_.png";
import StyledInputField from "../../Components/TextFields/styledTextFile";
import QrScanDialog from "../../Components/DialogBoxes/qrScanDialog";
import { useDispatch } from "react-redux";
import { RedeemGiftCardAction } from "../../Redux/actions/giftCardAction";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
export default function ClaimGiftCard() {
  const dispatch = useDispatch();
  const [giftCardNumer, setGiftCardNumber] = useState("");
  const [openScanner, setOpenScanner] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [giftCardDetails, setGiftCardDetails] = useState({});
  const [error, setError] = useState("");
  //function to handel claim gift card
  const handelScannerResponse = (qrId) => {
    if (qrId.length) {
      handelRedeemAction(qrId);
    }
  };

  //function to handel qr dialog open
  const handelQrSearch = () => {
    setOpenScanner(true);
  };

  //function to handel api call for redeem
  const handelRedeemAction = (id) => {
    dispatch(
      RedeemGiftCardAction({
        giftCardId: id,
      })
    ).then((data) => {
      setOpenScanner(false);
      if (
        data?.data?.isPasswordCorrect === false ||
        data?.data?.isPasswordCorrect === true
      ) {
        setOpenDialog(true);
        setGiftCardDetails(data?.data);
        setGiftCardNumber("");
      } else if (data?.error) {
        setGiftCardDetails({});
        setGiftCardNumber("");
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: data?.msg,
          })
        );
      } else {
        setGiftCardDetails({});
      }
    });
  };

  //function to handel proceed click
  const handelProceedClick = () => {
    handelRedeemAction(giftCardNumer);
  };

  //Function to handel gift card id and api hit
  useEffect(() => {
    if (giftCardNumer?.length === 16 && error === "") {
      handelProceedClick();
    }
  }, [giftCardNumer]);
  //function to handel buy gift card text onchange
  const handelEnterGiftCardId = (e) => {
    const inputValue = e.target.value.toUpperCase().replace(/-/g, "");
    const isBWGCFormat = inputValue.startsWith("BWGC");

    if (inputValue?.length <= 16) {
      setGiftCardNumber(inputValue);
    }
    if (isBWGCFormat) {
      setError("");
    } else {
      setError("Enter Valid Gift Card Id");
    }
  };
  return (
    <>
      <Grid container gap={0} direction={{ xs: "column", md: "row" }}>
        <Grid item xs={12} md={12}>
          <Box sx={claimGiftCardStyles.imageComponent}>
            <img
              alt="BookWater"
              src={giftCardHeader}
              style={claimGiftCardStyles.imageStyles}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingBottom: ".3rem",
            }}
            onClick={handelQrSearch}
          >
            <Typography
              style={{
                fontSize: appTheme.fontSizexl,
                color: appColors.headerTextColor,
                fontWeight: "bold",
              }}
            >
              Scan Qr <QrCodeScannerIcon />
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={12}>
          <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
            <StyledInputField
              placeholder="Enter Gift Card 16 digit code"
              text="text"
              value={giftCardNumer}
              handelTextField={handelEnterGiftCardId}
              error={error}
            />

            {/* <ButtonCustom
              title="Proceed"
              styles={{
                borderRadius: "1rem",
                background: appColors.giftCardColor,
              }}
              handleButtonClick={handelProceedClick}
            /> */}
          </Box>
        </Grid>
      </Grid>
      {openScanner && (
        <QrScanDialog
          openScanner={openScanner}
          setOpenScanner={setOpenScanner}
          handelScannerResponse={handelScannerResponse}
        />
      )}
      <ToastMsgCotainer />

      {openDialog && (
        <GiftCardDetailsDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
          giftCardDetails={giftCardDetails}
          setGiftCardDetails={setGiftCardDetails}
        />
      )}
    </>
  );
}

const claimGiftCardStyles = {
  imageComponent: {
    height: "20vh",
    width: "100%",
    overflow: "hidden",
    textAlign: "center",
    borderRadius: "1rem 1rem 0 0",
    boxShadow: "0 4px 2px -7px rgba(0, 0, 0, 0.2)",
    background:
      "linear-gradient(180deg, rgba(3,142,194,1) 25%, rgba(90,208,254,0) 100%)",
  },
  imageStyles: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
    
  },
};
