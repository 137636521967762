/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import appColors from "../../Assets/AppTheme/appColors";

export const styles = {
  mainBox: {
    display: "flex",
    marginLeft: { md: "5rem", xs: 0 },
    flexDirection: { md: "row", sm: "column", xs: "column" },
    gap: { md: 6, xs: 1 },
  },
  profileMain: {
    marginTop: "4rem",
    marginBottom: { lg: "0", md: "0", sm: "4rem", xs: "4rem" },
  },
  addressContainer: {
    gap: "1rem",
    maxHeight: window.innerHeight,
    overflowY: "auto",
  },
  containerHeight: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "80vh",
  },
  profileDetailsGrid: {
    gap: "1rem",
    display: "flex",
    justifyContent: "space-around",
    maxHeight: window.innerHeight,
    overflowY: "auto",
    alignContent: "center",
  },
  deatailHeading: {
    fontSize: "1rem",
    color: appColors.blue,
    fontWeight: "bold",
    marginBottom: "1rem",
  },
  subHeading: {
    fontSize: ".8rem",
    color: appColors.darkGray,
    fontWeight: "bold",
  },
  welcomeText: {
    fontSize: "1rem",
    color: appColors.blue,
    fontWeight: "bold",
    marginTop: "1rem",
  },
  subHeading1: {
    fontSize: ".8rem",
    color: appColors.Textgray,
    fontWeight: "bold",
  },
  marginSubHeading: {
    marginBottom: ".5rem",
  },
  edit: {
    fontSize: ".8rem",
    color: appColors.blue,
    marginLeft: ".5rem",
  },
  circle: {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    display: "flex",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.4)",
    alignItems: "center",
    justifyContent: "center",
    bgcolor: "#152057",
  },
  logoutstyles:{
    display:"flex",
    justifyContent:"space-between"
  }
};
