/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import useAxios from "../../Hooks/useAxios"; 
import actionType from "./../actions/actionTypes";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData"; 

export const GetSetUpConfigAction = (req) => {
    let api = useAxios();
    return async (dispatch) => {
        // var queryParams = `?page_number=${req.page_number}&date=${req.date}&payment_status=${req.payment_status}`
        // var queryParams = `?app_id=${req}&app_version${req}`
      try {
        let response = await api.get(`/SCM/setupconfig/appmeta`);
     
        if (response.status === AppMetaData.httpStatus.SUCCESS) {
          dispatch({
            type: actionType.GET_CONFIG_DATA,
            payload: response.data,
          });
          dispatch({
            type: actionType.APP_META_DATA,
            payload: response.data,
          });
        } else {
          // console.log("ELSE: Response in GetPdwAllOrders Action Error");
        }
      } catch (error) {}
    };
  };