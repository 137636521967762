/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import * as MaterialBase from "@mui/base";
import * as Material from "@mui/material";
import { styled } from "@mui/system";
import appColors from "../../Assets/AppTheme/appColors";

export default function ProfileTopBar({ processTabs, setProcessTabs }) {
  const Tab = styled(MaterialBase.TabUnstyled)`
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    ${"" /* padding: 12px 16px; */}
    ${"" /* margin: 6px 6px; */}
    ${"" /* padding: 12px 16px; */}
    padding: 9px;
    border: none;
    display: flex;
    justify-content: center;
    &:hover {
      border-bottom: 3px solid #707070;
    }

    &:focus {
      border-bottom: 3px solid #30a7ff;
    }

    &.${MaterialBase.tabUnstyledClasses.selected} {
      color: #30a7ff;
      border-bottom: 3px solid #30a7ff;
    }
  `;

  const TabsList = styled(MaterialBase.TabsListUnstyled)`
    min-width: 200px;
    ${"" /* width: fit-content; */}
    ${"" /* margin-right: 10px; */}
    background-color:transperent;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;

  const handleProcessTabshange = (event, newProcessValue) => {
    setProcessTabs(newProcessValue);
    // console.log("newProcessValue: " + newProcessValue);
  };
  return (
    <Material.Box>
      {window.innerWidth > 600 && (
        <MaterialBase.TabsUnstyled
          value={processTabs}
          onChange={handleProcessTabshange}
        >
          <TabsList
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              display: "flex",
              flexDirection: { md: "row", xs: "row" },
              borderBottom: "2px solid #58595b",
            }}
          >
            <Tab value="0" sx={{ color: appColors.darkGray }}>
              Profile
            </Tab>
            <Tab value="1" sx={{ color: appColors.darkGray }}>
              Address Book
            </Tab>
            {/* <Tab value={options.SUBSCRIPTION_ORDER}>Subscription</Tab> */}
          </TabsList>
        </MaterialBase.TabsUnstyled>
      )}
    </Material.Box>
  );
}
