/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import useAxios from "../../Hooks/useAxios";
import CustomConsole from "../../coustomConsole";
const PayByRazorPay = (props) => {
  const api = useAxios();
  CustomConsole("----props here----");
  CustomConsole(props);
  const dispatch = useDispatch();
  CustomConsole("PayByRazorPay");
  CustomConsole(props.orderId);
  CustomConsole(props.amount);
  const profileDetails = useSelector((state) => state.userProfile);
  const vbDetails = useSelector((state) => state.myAccounts);
  const discribe = {
    order_id: props.orderId ? props.orderId : "WLT",
    user_id: props.userId,
  };

  React.useEffect(() => {
    if (props.promoCode !== undefined && props.promoCode !== "") {
      discribe.promo_code = props.promoCode;
    }
  }, [props.promoCode]);

  const openPayModal = () => {
    const options = {
      key: process.env.REACT_APP_BW_RAZORPAY_KEY,
      amount: 0, //  = INR 1
      name: "Book Water",
      order_id: "",
      description: JSON.stringify(discribe),
      // image: BIcon,/
      handler: function (response) {
        CustomConsole("---------------payment.Success---------------");
        response.name = profileDetails.user.fullname;
        response.phone = profileDetails.user.phone;
        response.email = profileDetails.user.email;
        response.order_id = props.orderId;
        CustomConsole(response);
        props.handleRazorPayResponse({ error: false, response });
      },
      retry: {
        enabled: false,
        max_count: 1,
      },
      timeout: 600, //5 Minutes Razor Pay will close
      modal: {
        escape: false,
        ondismiss: function () {
          props.handleRazorPayClose();
        },
      },
      prefill: {
        name: profileDetails.user.fullname,
        contact: profileDetails.user.phone,
        email: profileDetails.user.email,
      },
      notes: {
        address: "",
      },
      theme: {
        color: "#090342",
        hide_topbar: false,
      },
    };
    // var ret = false;

    api
      // .post("https://stage.bookwater.com/PM/razorpay/createOrder", {
      .post("/PM/razorpay/createOrder", {
        amount: parseInt(
          (parseFloat(props.amount).toFixed(2) * 100).toString(),
          10
        ),
      })
      .then((res) => {
        CustomConsole("--- Response From Backend ----");
        CustomConsole(res);
        options.order_id = res.data.order_id;
        options.amount = parseInt(
          (parseFloat(props.amount).toFixed(2) * 100).toString(),
          10
        );
        CustomConsole("-----options here-----");
        CustomConsole(options);
        var rzp1 = new window.Razorpay(options);
        rzp1.open();
        rzp1.on("payment.failed", function (response) {
          CustomConsole("---------------payment.Failed---------------");
          CustomConsole(response);

          props.handleRazorPayResponse({ error: true, response });
        });
      });
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = process.env.REACT_APP_RAZORPAY_CHECKOUT_URL;
    script.async = true;
    document.body.appendChild(script);
    openPayModal();
  }, []);

  return <></>;
};

export default PayByRazorPay;
