/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as FcIcons from "react-icons/fc";
import razorPayIcon from "../../../../Assets/Images/razorPayIcon.png"
import paySharpIcon from "../../../../Assets/Images/paysharp.png"
import postPaidIcon from "../../../../Assets/Images/postpaidIcon.png"
// import waterWalletIcon from "../../../../Assets/Images/waterWalletImage.png"

import waterTankerIcon from "../../../../Assets/Images/waterTanker.png"
import sewageIcon from "../../../../Assets/Images/sewageTanker.png"
import pdwIcon from "../../../../Assets/Images/packagedDrinkingWater.png"
//src/Assets/Images/waterTanker.png
///home/bookwater2/Desktop/BWConsumerWebApp/src/Assets/userLogo.png
export const capacityRangeData = [
  {
    id: "",
    icon: <FcIcons.FcShipped />,
    range: "Select Capacity",
    isDisabled: true,
  },
  {
    id: "none",
    icon: <FcIcons.FcClearFilters />,
    range: "Un-Select",
    isDisabled: false,
  },
  {
    id: "8,12",
    icon: <FcIcons.FcShipped />,
    range: "(8-12)KL",
    isDisabled: false,
  },
  {
    id: "12,16",
    icon: <FcIcons.FcShipped />,
    range: "(12-16)KL",
    isDisabled: false,
  },
  {
    id: "16,9999",
    icon: <FcIcons.FcShipped />,
    range: "Above 16KL",
    isDisabled: false,
  },
];

export const tdsRangeData = [
  {
    id: "",
    icon: <FcIcons.FcCableRelease />,
    range: "Select TDS Range",
    isDisabled: true,
  },
  {
    id: "none",
    icon: <FcIcons.FcClearFilters />,
    range: "Un-Select",
    isDisabled: false,
  },
  {
    id: "0,500",
    icon: <FcIcons.FcCableRelease />,
    range: "Below 500",
    isDisabled: false,
  },
  {
    id: "500,800",
    icon: <FcIcons.FcCableRelease />,
    range: "500-800",
    isDisabled: false,
  },
  {
    id: "800,1200",
    icon: <FcIcons.FcCableRelease />,
    range: "800-1200",
    isDisabled: false,
  },
  {
    id: "1200,9999",
    icon: <FcIcons.FcCableRelease />,
    range: "Above 1200",
    isDisabled: false,
  },
];

export const priceRangeData = [
  {
    id: "",
    icon: <FcIcons.FcCurrencyExchange />,
    range: "Select TDS Range",
    isDisabled: true,
  },
  {
    id: "none",
    icon: <FcIcons.FcClearFilters />,
    range: "Un-Select",
    isDisabled: false,
  },
  {
    id: "0,500",
    icon: <FcIcons.FcCurrencyExchange />,
    range: "Below 500",
    isDisabled: false,
  },
  {
    id: "500,1500",
    icon: <FcIcons.FcCurrencyExchange />,
    range: "500-1500",
    isDisabled: false,
  },
  {
    id: "1500,2000",
    icon: <FcIcons.FcCurrencyExchange />,
    range: "1500-2000",
    isDisabled: false,
  },
  {
    id: "2000,999999",
    icon: <FcIcons.FcCurrencyExchange />,
    range: "Above 2000",
    isDisabled: false,
  },
];

export const supplierRatingsData = [
  {
    id: "",
    icon: <FcIcons.FcRating />,
    range: "Select Supplier Ratings",
    isDisabled: true,
  },
  {
    id: "none",
    icon: <FcIcons.FcClearFilters />,
    range: "Un-Select",
    isDisabled: false,
  },
  {
    id: "4.01,5.00",
    icon: <FcIcons.FcRating />,
    range: "Above 4 Star",
    isDisabled: false,
  },
  {
    id: "3.01,5.00",
    icon: <FcIcons.FcRating />,
    range: "Above 3 Star",
    isDisabled: false,
  },
  {
    id: "2.01,5.00",
    icon: <FcIcons.FcRating />,
    range: "Above 2 Star",
    isDisabled: false,
  },
  {
    id: "1.01,5.00",
    icon: <FcIcons.FcRating />,
    range: "Above 1 Star",
    isDisabled: false,
  },
];

export const FilterTransactionPayment = [
  {
    value: "RAZORPAY",
    icon: razorPayIcon,
    name: "Razor Pay",
  },
  {
    value: "PAYSHARP",
    icon: paySharpIcon,
    name: "Pay Sharp",
  },
  {
    value: "POSTPAID",
    icon: postPaidIcon,
    name: "Post Paid",
  },
  {
    value: "WATERWALLET",
    icon: "",
    name: "Water Wallet",
  },
] 

export const FilterTransactionTanker = [
  // {
  //   value: "1",
  //   icon: waterTankerIcon,
  //   name: "Water",
  // },
  // {
  //   value: "2",
  //   icon: sewageIcon,
  //   name: "Sewage",
  // },
  {
    value: "3",
    icon: pdwIcon,
    name: "Packaged Drinking Water",
  },

]
