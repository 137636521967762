// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  productDetails: {},
  selectedAddressId: null,
  addressFlag: true,
  productError: true,
  selectedProduct: {},
  loading: false,
  blockDates: [],
  pageLoading: false,
};

const skuBookNowReducer = (state = initialState, action) => {
  // console.log(action.payload);
  console.log("----Action Type----");
  console.log(action.type);
  switch (action.type) {
    case actionType.SKU_AVAILABLE_PRODUCTLIST:
      return {
        ...state,
        error: action.payload.data.error,
        productError: action.payload.data.error,
        msg: action.payload.data.msg,
        productDetails: action.payload.data.data,
        loading: false,
      };
    case actionType.SET_LOADING_FALSE_AFTER_DELAY_Booking:
      return {
        ...state,
        pageLoading: false, // Set loading to false after one second
      };
    case actionType.FETCH_DATA_START:
      return { ...state, loading: true, pageLoading: true };
    case actionType.BLOCKED_DATE_INFO:
      return { ...state, blockDates: action.payload.data };
    case actionType.SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddressId: action.payload.data,
        addressFlag: action.payload.statusFlag,
      };
    case actionType.SELECTED_PRODUCT_ACTION:
      console.log("----after update cart----");
      console.log(action.payload.data);
      return {
        ...state,
        selectedProduct: action.payload.data,
      };
    case actionType.ADD_SKU_PRODUCT_QTY:
      const productAddId = action.payload.data.id;
      const capacityAdd = action.payload.data.capacity;
      const materialAdd = action.payload.data.material;
      const bookingTypeAdd = action.payload.bookingType;
      // Find the product in user_stock
      const StockProductAdd = state.productDetails.user_stock.find(
        (item) =>
          item.container_capacity === capacityAdd &&
          item.container_material === materialAdd
      );
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          products: state.productDetails.products.map((item) => {
            if (
              (item.id === productAddId && item.quantity < item.max_book_qty) ||
              (item.id === productAddId && item.quantity === undefined)
            ) {
              if (item.quantity === undefined) {
                return {
                  ...item,
                  quantity: item.min_book_qty + (item.quantity || 0),
                };
              } else if (
                StockProductAdd?.max_return !== undefined &&
                parseInt(item.quantity * item.sku_qty[0]) <
                  parseInt(StockProductAdd?.max_return) &&
                parseInt(item.quantity * item.sku_qty[0]) +
                  parseInt(item.sku_qty[0]) <=
                  parseInt(StockProductAdd?.max_return) &&
                bookingTypeAdd === 1
              ) {
                return {
                  ...item,
                  quantity: 1 + (item.quantity || 0),
                  return: (item.return || 0) + item.sku_qty[0],
                };
              } else if (
                StockProductAdd?.max_return !== undefined &&
                parseInt(StockProductAdd?.max_return) -
                  parseInt(item.quantity * item.sku_qty[0]) <
                  item.sku_qty[0] &&
                parseInt(StockProductAdd?.max_return) -
                  parseInt(item.quantity * item.sku_qty[0]) >
                  0 &&
                bookingTypeAdd === 1
              ) {
                return {
                  ...item,
                  quantity: 1 + (item.quantity || 0),
                  return:
                    (item.return || 0) +
                    parseInt(StockProductAdd.max_return) -
                    parseInt(item.quantity * item.sku_qty[0]),
                };
              } else {
                return {
                  ...item,
                  quantity: 1 + (item.quantity || 0),
                };
              }
            }
            return item;
          }),
        },
      };

    case actionType.REDUCE_SKU_PRODUCT_QTY:
      const productIdQty = action.payload.data.id;
      const capacity1 = action.payload.data.capacity;
      const material1 = action.payload.data.material;
      const bookingType = action.payload.bookingType;
      // Find the product in user_stock
      const stockProduct = state.productDetails.user_stock.find(
        (item) =>
          item.container_capacity === capacity1 &&
          item.container_material === material1
      );

      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          products: state.productDetails.products.map((item) => {
            if (
              item.id === productIdQty &&
              item.quantity >= item.min_book_qty
            ) {
              let newQuantity = item.quantity - 1 || 0;
              let newReturn = item.return || 0;
              // Check if reducing quantity affects return quantity
              if (
                stockProduct?.max_return !== undefined &&
                item.quantity !== item.min_book_qty &&
                parseInt(newQuantity * item.sku_qty[0]) <=
                  parseInt(item.return) &&
                parseInt(item.return) <= parseInt(stockProduct?.max_return) &&
                bookingType === 1
              ) {
                newReturn = newQuantity * item.sku_qty[0];
              } else if (item.quantity === item.min_book_qty) {
                newReturn = undefined;
              }
              return {
                ...item,
                quantity: newQuantity,
                return: newReturn,
              };
            }
            return item;
          }),
        },
      };

    case actionType.ADD_RETURN_PRODUCT_QTY:
      const capacity = action.payload.data.capacity;
      const material = action.payload.data.material;
      const productId = action.payload.data.id;
      // Find the product in user_stock
      const userStockProduct = state.productDetails.user_stock.find(
        (item) =>
          item.container_capacity === capacity &&
          item.container_material === material
      );
      // console.log(userStockProduct);
      if (userStockProduct) {
        return {
          ...state,
          productDetails: {
            ...state.productDetails,
            products: state.productDetails.products.map((item) => {
              if (
                (item.id === productId &&
                  item.return < userStockProduct.max_return &&
                  parseInt(item.return / item.sku_qty[0]) < item.quantity) ||
                (item.id === productId &&
                  item.return === undefined &&
                  userStockProduct.max_return !== 0)
              ) {
                if (
                  item.return === undefined &&
                  userStockProduct.max_return <=
                    parseInt(item.quantity * item.sku_qty[0])
                ) {
                  return {
                    ...item,
                    return:
                      (item.return || 0) +
                      parseInt(userStockProduct.max_return),
                  };
                } else if (
                  item.return > parseInt(item.quantity * item.sku_qty[0])
                ) {
                  return {
                    ...item,
                    return:
                      (item.return || 0) -
                      parseInt(item.quantity * item.sku_qty[0]),
                  };
                } else if (
                  item.return > parseInt(item.quantity * item.sku_qty[0]) &&
                  item.return === undefined
                ) {
                  return {
                    ...item,
                    return: parseInt(
                      userStockProduct.max_return / item.quantity
                    ),
                  };
                } else if (
                  item.return === undefined &&
                  parseInt(item.min_book_qty * item.sku_qty[0]) <=
                    userStockProduct.max_return
                ) {
                  return {
                    ...item,
                    return: parseInt(item.min_book_qty * item.sku_qty[0]),
                  };
                } else {
                  return {
                    ...item,
                    return: (item.return || 0) + 1,
                  };
                }
              }
              return item;
            }),
          },
        };
      } else {
        return state;
      }
    case actionType.REDUCE_RETURN_PRODUCT_QTY:
      const capacityReturn = action.payload.data.container_capacity;
      const materialReturn = action.payload.data.container_material;
      const productIdReturn = action.payload.data.id;
      // Find the product in user_stock
      const userStockProductReturn = state.productDetails.user_stock.find(
        (item) =>
          item.capacity === capacityReturn && item.material === materialReturn
      );
      if (userStockProductReturn) {
        return {
          ...state,
          productDetails: {
            ...state.productDetails,
            products: state.productDetails.products.map((item) => {
              if (item.id === productIdReturn && item.return >= 1) {
                if (item.return <= parseInt(item.quantity * item.sku_qty[0])) {
                  return {
                    ...item,
                    return: (item.return || 0) - 1,
                  };
                } else {
                  return {
                    ...item,
                    quantity: (item.quantity || 0) - 1,
                    return: (item.return || 0) - 1,
                  };
                }
              }
              return item;
            }),
          },
        };
      } else {
        return state;
      }

    case actionType.DELETE_SKU_PRODUCT_QTY:
      const productDeleteQty = action.payload.data.id;
      // Find the product in user_stock
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          products: state.productDetails.products.map((item) => {
            if (item.id === productDeleteQty) {
              return {
                ...item,
                quantity: (item.min_book_qty || 0) - 1,
                return: undefined,
              };
            }
            return item;
          }),
          crates: state?.productDetails?.crates?.map((item) => {
            if (item.id === productDeleteQty) {
              return {
                ...item,
                quantity: 0,
              };
            }
            return item;
          }),
        },
      };
    case actionType.DELETE_ALLSELECTED_PRODUCT_QTY:
      // Find the product in user_stock
      return {
        ...state,
        productDetails: {
          ...state.productDetails,
          products: state?.productDetails?.products?.map((item) => {
            return {
              ...item,
              quantity: item.min_book_qty - 1,
              return: undefined,
            };
          }),
          crates: state?.productDetails?.crates?.map((item) => {
            return {
              ...item,
              quantity: 0,
            };
          }),
        },
      };

    case actionType.ADD_CRATE_QUANTITY:
      // console.log("actionType.PDW_TANKER_LIST");
      // console.log(action.payload);
      const addCrateDetails = { ...action.payload.data.product };
      // Find the product in user_stock
      const CrateProductStock = state.productDetails.user_stock.find(
        (item) => item.container_material === addCrateDetails.name
      );
      console.log("CrateProductStock.................");
      console.log(CrateProductStock);
      console.log("addCrateDetails.................");
      console.log(addCrateDetails);
      const addCrateId = addCrateDetails.id;
      if (
        addCrateDetails.quantity === undefined &&
        CrateProductStock === undefined
      ) {
        addCrateDetails.quantity = 1;
      } else if (
        addCrateDetails.quantity === undefined &&
        CrateProductStock?.max_return > 0
      ) {
        addCrateDetails.quantity = 1;
        addCrateDetails.return = 1;
      } else if (
        addCrateDetails.quantity === undefined &&
        CrateProductStock?.max_return === 0
      ) {
        addCrateDetails.quantity = 1;
        addCrateDetails.return = 1;
      } else if (
        addCrateDetails.quantity !== undefined &&
        CrateProductStock?.max_return > addCrateDetails.quantity
      ) {
        addCrateDetails.quantity += 1;
        addCrateDetails.return += 1;
      } else {
        addCrateDetails.quantity += 1;
      }

      return {
        ...state,
        ...state.productDetails,
        productDetails: {
          ...state.productDetails,
          crates: state.productDetails.crates.map((item) => {
            if (item.id === addCrateId) {
              return {
                ...item,
                quantity: 1 + (item.quantity || 0),
              };
            }
            return item;
          }),
        },
      };
    case actionType.REDUSE_CRATE_QUANTITY:
      let reduseDetails = { ...action.payload.data.product };
      const reduseCrateId = reduseDetails.id;
      if (
        reduseDetails.quantity > 1 &&
        reduseDetails.return === reduseDetails.quantity
      ) {
        reduseDetails.quantity -= 1;
        reduseDetails.return -= 1;
      } else if (reduseDetails.quantity > 1) {
        reduseDetails.quantity -= 1;
      } else {
        reduseDetails = undefined;
      }
      return {
        ...state,
        ...state.productDetails,
        productDetails: {
          ...state.productDetails,
          crates: state.productDetails.crates.map((item) => {
            if (item.id === reduseCrateId) {
              return {
                ...item,
                quantity: item.quantity - 1 || 0,
              };
            }
            return item;
          }),
        },
      };

    case actionType.ADD_CRATE_RETURN_QUANTITY:
      let addReturnDetails = { ...action.payload.data.product };
      const addReturnCrateId = action.payload.data.id;
      // Find the product in user_stock
      const CrateReturnProductStock = state.productDetails.user_stock.find(
        (item) => item.container_material === addReturnDetails.name
      );
      if (
        addReturnDetails.return < addReturnDetails.quantity &&
        addReturnDetails.return < 5
      ) {
        addReturnDetails.return += 1;
      }
      return {
        ...state,
        ...state.productDetails,
        productDetails: {
          ...state.productDetails,
          products: state.productDetails.products.map((item) => {
            if (item.id === addReturnCrateId) {
              return {
                ...item,
                crateDetails: addReturnDetails,
              };
            }
            return item;
          }),
        },
      };
    case actionType.REDUSE_CRATE_RETURN_QUANTITY:
      let reduseReturnDetails = { ...action.payload.data.product };
      const reduseReturnCrateId = action.payload.data.id;

      if (reduseReturnDetails?.return > 0) {
        reduseReturnDetails.return -= 1;
      } else {
        reduseReturnDetails.return = 0;
      }
      return {
        ...state,
        ...state.productDetails,
        productDetails: {
          ...state.productDetails,
          products: state.productDetails.products.map((item) => {
            if (item.id === reduseReturnCrateId) {
              return {
                ...item,
                crateDetails: reduseReturnDetails,
              };
            }
            return item;
          }),
        },
      };

    case actionType.SIGN_OUT:
      return {
        ...state,
        error: true,
        msg: "",
        productDetails: {},
        selectedAddressId: null,
        addressFlag: true,
        productError: true,
        selectedProduct: {},
        loading: false,
        blockDates: [],
        pageLoading: false,
      };

    default:
      return state;
  }
};

export default skuBookNowReducer;
