/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import appColors from "../../../Assets/AppTheme/appColors";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import { Box, Typography } from "@mui/material";
import infoIcon from "../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { ListAvailabileTankers } from "../../../Redux/actions/bookNowAction";
export default function DeleteProductInfoCard({
  openDeleteDialog,
  setOpenDeleteDilog,
  handelDeleteButton,
}) {
  //Function for handling cancel order Option
  const handleClose = () => {
    setOpenDeleteDilog(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openDeleteDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: appColors.secondaryCard,
            color: appColors.headerTextColor,
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Discard Cart Items?
        </DialogTitle>
        <DialogContent
          sx={{
            bgcolor: appColors.secondaryCard,
            color: appColors.headerTextColor,
          }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: appColors.headerTextColor }}
          >
            <Typography sx={{ textAlign: "center" }}>
              Are you sure want to discard the items in your cart?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: appColors.secondaryCard,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={handelDeleteButton}
            sx={{
              bgcolor: appColors.textColorNew,
              color: appColors.white,
              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            Yes
          </Button>
          <Button
            onClick={handleClose}
            sx={{
              bgcolor: appColors.textColorNew,
              color: appColors.white,
              "&:hover": {
                bgcolor: appColors.textColorNew,
              },
            }}
            size="small"
            variant="contained"
          >
            No
          </Button>
        </DialogActions>
        <img
          alt=""
          src={closeIcon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            height: "30px",
          }}
          onClick={handleClose}
        />
      </Dialog>
    </React.Fragment>
  );
}
