/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  message: "",
  isPaymentUpdated: false,
  isWalletSucess: false,
  walletAmount: 0,
  paymentStatus: "",
  isPaymentUpi: false,
  paymentData: {},
};

const paymentMethodReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.PAY_BY_RAZORPAY:
      // console.log(action.payload);
      return {
        ...state,
        message: action.payload.data.msg,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_BY_RAZORPAY_NEW:
      // console.log(action.payload);
      return {
        ...state,
        message: action.payload.data.msg,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_BY_PAYSHARP_UPI:
      // console.log(action.payload);
      return {
        ...state,
        message: action.payload.data,
        isPaymentUpi: action.payload.data.error,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_BY_VPA_PAYSHARP_UPI:
      // console.log(action.payload);
      return {
        ...state,
        message: action.payload.data,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_BY_VPA_PAYSHARP_UPI_REQUEST:
      return {
        ...state,
        message: action.payload.data,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_BY_UPI_ORDER_STATUS:
      // console.log(action.payload);
      return {
        ...state,
        paymentStatus: action.payload.data,
        paymentData: action.payload.data,
      };
    case actionType.PAYSHARP_HANDLE_STATUS:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isPaymentUpdated: action.payload.statusFlag,
      };
    case actionType.PAY_THROUGH_WALLET:
      return {
        ...state,
        error: action.payload.data.error,
        message: action.payload.data.msg,
        isPaymentUpdated: action.payload.statusFlag,
        isWalletSucess: action.payload.statusFlag,
      };
    case actionType.GET_WALLET_DETAILS:
      // Extract payload properties safely with default values
      const { error = null, data = {} } = action.payload || {};
      const { amount } = data;

      // Parse the amount safely with a fallback
      const walletAmount = isNaN(parseFloat(amount))
        ? "0.00"
        : parseFloat(amount).toFixed(2);

      return {
        ...state,
        error, // directly assign error
        walletAmount,
      };
    case actionType.RESET_COMPONENT_STATE:
      return {
        ...state,
        error: true,
        message: "",
        isPaymentUpdated: false,
        isWalletSucess: false,
        paymentStatus: "",
        isPaymentUpi: false,
        paymentData: {},
      };
    case actionType.SIGN_OUT:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default paymentMethodReducer;
