/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styles } from "./transactionsStyle";
import GetTransactionDetails from "../../Redux/actions/transactionsAction";
import RazorPayIcon from "../../Assets/Images/razorPayIcon.png";
import PaySharpIcon from "../../Assets/Images/paysharpIcon.png";
import PostPaidIcon from "../../Assets/Images/postpaidIcon.png";
import PdwIcon from "../../Assets/Images/packagedDrinkingWater.png";
import { renderData } from "../../Redux/actions/isLoadingAction";
import FilterTransaction from "../../Components/DialogBoxes/BookNow/Filters/FilterTransaction";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import NewTranscationCard from "./NewTranscationcard";
import appColors from "../../Assets/AppTheme/appColors";
import PageNavigation from "../../Components/CommonComponents/PageNavigationDetails";
import { Box, Button, Typography } from "@mui/material";
import TransactionFilterDialog from "../../Components/DialogBoxes/FilterDialogBox/TransactionFilter";
function Transactions() {
  const transactionsList = useSelector((state) => state.transactionDetails);
  const [filterDialogOpen, setfilterDialogOpen] = React.useState(false);
  const loadingFlag = useSelector((state) => state.isLoadingData);
  const dispatch = useDispatch();

  const [pageNumber, setPageNumber] = React.useState(1);
  // const [loadingFlag, setLoadingFlag] = useState(false);
  const [filter, setFilter] = useState({
    dateFilter: "DESC",
    paymentMethodFilter: "",
    tankerTypeFilter: "",
    statusFilter: "",
  });

  const [refreshFlag, setRefreshFlag] = React.useState(true);
  React.useEffect(() => {
    // console.log("Transactions: " + refreshFlag);
    if (refreshFlag || !transactionsList.error) {
      dispatch(renderData("Transactions"));
      setRefreshFlag(false);
    }
  }, [refreshFlag, transactionsList.error]);

  useEffect(() => {}, [transactionsList]);

  const handleChange = (prop) => (event) => {
    setFilter({ ...filter, [prop]: event.target.value });
  };
  const handleFilterSubmit = (filterObject) => {
    // <MaxWidthDialog />
    setPageNumber(1);
    dispatch(
      GetTransactionDetails(
        filterObject.status,
        filterObject.paymentMethod,
        filterObject.tankers,
        filterObject.orderBy,
        pageNumber - 1
      )
    );
    setFilter((prev) => ({
      ...prev,
      dateFilter: filterObject.orderBy,
      paymentMethodFilter: filterObject.paymentMethod,
      statusFilter: filterObject.status,
    }));
  };

  //function to clear filter
  const handelClearFilter = () => {
    setFilter({
      dateFilter: "DESC",
      paymentMethodFilter: "",
      tankerTypeFilter: "",
      statusFilter: "",
    });
    setPageNumber(1);
    handleCloseFilter();
  };
  React.useEffect(() => {
    dispatch(
      GetTransactionDetails(
        filter.statusFilter,
        filter.paymentMethodFilter,
        filter.tankerTypeFilter,
        filter.dateFilter,
        pageNumber - 1
      )
    );
  }, [pageNumber]);

  const [openFilter, setOpenfilter] = useState(false);

  const handleFilterOpen = () => {
    setfilterDialogOpen(true)
  };

  const handleCloseFilter = () => {
    setOpenfilter(false);
  };

  return (
    <>
      <Box sx={styles.transMain}>
        {/* Filter Details */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <PageNavigation
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
            pageList={transactionsList.pageStatus}
            styles={{marginTop:"1rem"}}

          />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              marginTop: "20px",
            }}
          >
            {/* Order By Details Filters (Date : Ascending or Descending Order) */}

            {/* Payment Methos Selection Filters */}

            {/* filter button */}
            <Button
              variant="contained"
              startIcon={<TuneSharpIcon />}
              size="small"
              onClick={handleFilterOpen}
              sx={{
                width: "auto",
                backgroundColor: appColors.textColorNew,
                marginBottom: "20px",
              }}
            >
              Filter
            </Button>
            {openFilter ? (
              <FilterTransaction
                open={openFilter}
                handleCloseFilter={handleCloseFilter}
                RazorPayIcon={RazorPayIcon}
                PaySharpIcon={PaySharpIcon}
                PostPaidIcon={PostPaidIcon}
                PdwIcon={PdwIcon}
                handleFilterSubmit={handleFilterSubmit}
                handelClearFilter={handelClearFilter}
              />
            ) : (
              ""
            )}
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "row",
            maxHeight: window.innerHeight,
            "&::-webkit-scrollbar": {
              display: "none", // Webkit browsers
            },
            overflowY: "auto",
            justifyContent: "space-around",
            gap: 2,
          }}
        >
          {transactionsList.transactions.length >= 1 ? (
            transactionsList.transactions.map((data, key) => {
              return <NewTranscationCard transactions={data} key={key} />;
            })
          ) : (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              {" "}
              <img
                alt=""
                src={process.env.PUBLIC_URL + "/bwLogo2.png"}
                style={{ objectFit: "contain", height: "50px" }}
              />
              <Typography sx={{ color: appColors.darkGray }}>
                {" "}
                No Transaction Histroy Found!
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <TransactionFilterDialog
        filterDialogOpen={filterDialogOpen}
        setfilterDialogOpen={setfilterDialogOpen}
        handleFilterSubmit={handleFilterSubmit}
        setPageNumber={setPageNumber}
        value={0}
      />
    </>
  );
}

export default Transactions;
