/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import closeIcon from "../../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import { styles } from "./myAccountsListStyle";
import { createTheme } from "@mui/material/styles";
import { useTheme } from "@mui/material/styles";
import * as FcIcons from "react-icons/fc";
import { makeStyles } from "@material-ui/core";
import validator from "validator";
import useMediaQuery from "@mui/material/useMediaQuery";
import { get } from "axios";
import { toast } from "react-toastify";
import appColors from "../../../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export function EditAccountDialogBox(props) {
  const themes = useTheme();
  const [accountName, setAccountName] = React.useState("");
  const [accountNumber, setAccountNumber] = React.useState("");
  const [confirmAccountNumber, setConfirmAccountNumber] = React.useState("");
  const [accountType, setAccountType] = React.useState("");
  const [ifscCode, setIfscCode] = React.useState("");
  const [micrNumber, setMicrNumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [bankName, setBankName] = React.useState("");
  const [branchName, setBranchName] = React.useState("");
  const [isPrimary, setIsPrimary] = React.useState(false);
  const [error, setError] = React.useState(true);

  const [phoneHt, setPhoneHt] = React.useState("");
  const [emailHt, setemailHt] = React.useState("");
  const [accountNameHt, setNameHt] = React.useState("");
  const [acNumbereHt, setAcNumberHt] = React.useState("");
  const [confirmAcNumbereHt, setConfirmActNumberHt] = React.useState("");
  const [micrNumbereHt, setMicrNumberHt] = React.useState("");

  const isSmallScreen = useMediaQuery(themes.breakpoints.down("sm"));
  const isFullScreen = isSmallScreen;
  const helperTextStyles = makeStyles((theme) => ({
    root: {
      margin: 10,
      color: appColors.darkGray,
      fontWeight: "bold",
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: theme.palette.common.white,
      },
    },
  }));
  const helperTestClasses = helperTextStyles();

  const style = {
    display: "flex",
    justifyContent: "center",
    bgcolor: appColors.secondaryCard,
    minHeight: "100%",
  };
  const validate = () => {
    let ret = true;
    //Account Number validation
    if (accountNumber.length < 9 || accountNumber.length > 18) {
      setAcNumberHt("Account Number Must have Min: 9-Digits & Max: 18-Digits");
      ret = false;
    } else {
      setAcNumberHt("");
    }
    //Confirm Account Number  validation
    if (confirmAccountNumber.length < 9 || confirmAccountNumber.length > 18) {
      setConfirmActNumberHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
      ret = false;
    } else {
      setConfirmActNumberHt("");
    }
    //Account Number  & Confirm Account Number validation
    if (accountNumber !== confirmAccountNumber) {
      setConfirmActNumberHt("Confirm Account Number Mismatched.");
      ret = false;
    } else {
      setConfirmActNumberHt("");
    }
    //MICR Number Validation
    if (micrNumber.length !== 9) {
      setMicrNumberHt("MICR Number Must be 9 digits");
    } else {
      setMicrNumberHt("");
    }
    //Full Name Validation
    let reg = /^[a-zA-Z0-9]/; //Full Name Validation
    if (!reg.test(accountName)) {
      setNameHt("Please Enter valid Name");
      ret = false;
    } else {
      setNameHt("");
    }
    if (bankName === "" || branchName === "") {
      ret = false;
    }
    reg = /^[6-9][0-9]{9}$/; //Phone Number validation
    if (!reg.test(phone)) {
      setPhoneHt("Phone Number Must be 10 Numbers & start with 9/8/7/6");
      ret = false;
    } else {
      setPhoneHt("");
    }
    if (!validator.isEmail(email)) {
      ret = false;
      setemailHt("Invalid Email. Hint:user@gmail.com");
    } else {
      setemailHt("");
    }
    let regex = new RegExp(/^[A-Z]{4}0[A-Z0-9]{6}$/);
    if (regex.test(ifscCode) == false) {
      ret = false;
    }
    return ret;
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray, // Set the desired label color
    },
  };

  React.useEffect(() => {
    if (validate() === true) {
      setError(false);
    } else {
      setError(true);
    }
  });

  React.useEffect(() => {
    // console.log("PROPS: " + JSON.stringify(props));
    const acT = props.data.account_type === 500 ? "1" : "2";
    setAccountName(props.data.account_name + "");
    setPhone(props.data.phone + "");
    setAccountNumber(props.data.account_number + "");
    setConfirmAccountNumber(props.data.account_number + "");
    setAccountType(acT + "");
    setIfscCode(props.data.ifsc_code + "");
    setMicrNumber(props.data.micr_number + "");
    setBankName(props.data.bank_name + "");
    setEmail(props.data.email + "");
    setBranchName(props.data.branch_name + "");
    setIsPrimary(props.data.is_primary);
    // handleValidationonChange();
  }, [props.data]);
  // const validate = () => {};
  const handleSubmit = () => {
    if (!error) {
      const details = {
        accountName,
        accountNumber,
        confirmAccountNumber,
        accountType,
        ifscCode,
        micrNumber,
        email,
        phone,
        bankName,
        branchName,
        isPrimary,
        accountId: props.data.account_id,
        userId: props.data.user_id,
      };
      // console.log("Data: " + JSON.stringify(details));
      props.handleUpdateAccountDetails(details);
    }
  };
  const handleIfscVerify = () => {
    get("https://ifsc.razorpay.com/" + ifscCode)
      .then((res) => {
        setBankName(res.data.BANK);
        setBranchName(res.data.BRANCH);
      })
      .catch((err) => {
        toast.dark("Invalid IFSC Code, Please Enter valid IFSC Code", {
          position: "bottom-right",
          autoClose: 3000,
          type: "error",
        });
        setBankName("");
        setBranchName("");
      });
  };

  return (
    <Material.Dialog
      fullWidth
      maxWidth={isFullScreen ? "xl" : "sm"}
      fullScreen={isFullScreen}
      open={props.open}
      onClose={props.handleClose}
      TransitionComponent={Transition}
    >
      <Material.Box
        sx={{ backgroundColor: appColors.secondaryCard, padding: "1rem 1rem 0 1rem" }}
      >
        <Material.Typography sx={{ color: appColors.secondaryText }}>
          Edit Bank Account
        </Material.Typography>
      </Material.Box>
      <Material.DialogContent sx={{ background: appColors.secondaryCard }}>
        <Material.Box sx={style}>
          <Material.Box
            sx={{
              padding: "0 0 0 0",
              marginTop: "10px",
              display: "flex",
              flexDirection: "column",
              maxHeight: "100vh",
              marginBottom: "2%",
            }}
          >
            {/* Accountant Name */}
            <Material.TextField
              variant="outlined"
              label="Account Holder Name *"
              sx={styles.modalTxt}
              size="small"
              helperText={accountNameHt}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              onChange={(e) => setAccountName(e.target.value)}
              value={accountName}
            />

            {/* Email Address */}
            <Material.TextField
              variant="outlined"
              label="Email Address *"
              type="email"
              sx={styles.modalTxt}
              size="small"
              InputProps={{
                startAdornment: (
                  <Material.InputAdornment position="start">
                    <FcIcons.FcFeedback size={20} />
                  </Material.InputAdornment>
                ),
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              helperText={emailHt}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputLabelProps={inputLabelProps}
            />
            {/* Account number */}
            <Material.TextField
              variant="outlined"
              label="Account Number *"
              type="number"
              sx={styles.modalTxt}
              size="small"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 20);
              }}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              onChange={(e) => setAccountNumber(e.target.value)}
              value={accountNumber}
              helperText={acNumbereHt}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputLabelProps={inputLabelProps}
            />
            {/* Confirm Account Number */}
            <Material.TextField
              variant="outlined"
              label="Confirm Account Number *"
              sx={styles.modalTxt}
              size="small"
              type="number"
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 20);
              }}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              onChange={(e) => setConfirmAccountNumber(e.target.value)}
              value={confirmAccountNumber}
              helperText={confirmAcNumbereHt}
              FormHelperTextProps={{ classes: helperTestClasses }}
              InputLabelProps={inputLabelProps}
            />

            <Material.Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
                flexDirection: "row",
                gap: 1,
                justifyContent: { md: "flex-end", xs: "center" },
              }}
            >
              {/* IFSC Number & Verify Button */}
              <Material.Box
                sx={{
                  marginTop: "12px",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Material.TextField
                  variant="outlined"
                  label="IFSC Code *"
                  size="small"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  sx={{ width: "150px" }}
                  onChange={(e) => setIfscCode(e.target.value)}
                  value={ifscCode}
                  InputLabelProps={inputLabelProps}
                />
                <Material.Button
                  variant="contained"
                  sx={{
                    marginLeft: "15px",
                    width: "15%",
                    textTransform: "none",
                    backgroundColor: appColors.textColorNew,
                    color: "white",
                  }}
                  onClick={handleIfscVerify}
                >
                  Verify
                </Material.Button>
              </Material.Box>
              {/* Branch Name */}
              <Material.TextField
                variant="outlined"
                label="Branch Name *"
                sx={styles.modalTxt}
                size="small"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                value={branchName}
                InputLabelProps={inputLabelProps}
              />
            </Material.Box>

            <Material.Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
                flexDirection: "row",
                gap: 1,
                justifyContent: { md: "flex-end", xs: "center" },
              }}
            >
              {/* Bank Name */}
              <Material.TextField
                variant="outlined"
                label="Bank Name *"
                sx={styles.modalTxt}
                size="small"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                value={bankName}
                InputLabelProps={inputLabelProps}
              />
              {/* MICR Number*/}
              <Material.TextField
                variant="outlined"
                label="MICR Number"
                type="number"
                sx={styles.modalTxt}
                size="small"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                onChange={(e) => setMicrNumber(e.target.value)}
                value={micrNumber}
                helperText={micrNumbereHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
                InputLabelProps={inputLabelProps}
              />
            </Material.Box>

            <Material.Box
              sx={{
                display: "flex",
                marginBottom: "1rem",
                flexDirection: "row",
                gap: 1,
                justifyContent: { md: "flex-end", xs: "center" },
              }}
            >
              {/* Account Type */}
              <Material.FormControl
                variant="outlined"
                sx={styles.modalTxt}
                size="small"
              >
                <Material.InputLabel sx={{ color: appColors.darkGray }}>
                  Account Type *
                </Material.InputLabel>
                <Material.Select
                  value={accountType}
                  onChange={(e) => setAccountType(e.target.value)}
                  label="Account Type *"
                  sx={{
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                >
                  <Material.MenuItem disabled={true} value="">
                    Select Account Type
                  </Material.MenuItem>
                  <Material.MenuItem value="1">
                    <FcIcons.FcMoneyTransfer /> Savings Account
                  </Material.MenuItem>
                  <Material.MenuItem value="2">
                    <FcIcons.FcRatings /> Current Account
                  </Material.MenuItem>
                </Material.Select>
              </Material.FormControl>

              {/* Mobile Number */}
              <Material.TextField
                variant="outlined"
                label="Mobile Number *"
                type="number"
                sx={styles.modalTxt}
                size="small"
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                helperText={phoneHt}
                FormHelperTextProps={{ classes: helperTestClasses }}
                InputLabelProps={inputLabelProps}
              />
            </Material.Box>

            {/* Primary or Not Check Box */}
            <Material.FormControlLabel
              sx={{ marginTop: "8px" }}
              control={<Material.Checkbox defaultChecked={isPrimary} />}
              label={
                <Material.Typography
                  sx={{ fontSize: "13px", color: appColors.darkGray }}
                >
                  Mark as Primary
                </Material.Typography>
              }
              onChange={() => setIsPrimary(!isPrimary)}
            />
            {/* <Material.Typography
            sx={{
              fontSize: "12px",
              marginLeft: "10px",
              color: appColors.darkGray,
            }}
          >
            Note : Refund will be credited to primary account
          </Material.Typography> */}
            {/* SubMit Botton */}
            <Material.Box sx={{ width: "100%", textAlign: "center" }}>
              <Material.Button
                disabled={error}
                variant="contained"
                sx={styles.btn}
                size="small"
                onClick={handleSubmit}
              >
                Submit
              </Material.Button>
            </Material.Box>
          </Material.Box>
        </Material.Box>
        <img
          alt=""
          src={closeIcon}
          style={{
            position: "absolute",
            right: 0,
            top: 0,
            cursor: "pointer",
            height: "30px",
          }}
          onClick={props.handleClose}
        />
      </Material.DialogContent>
    </Material.Dialog>
  );
}
