/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description DeliveryDateAndTimeCard.jsx the form to collect the order data.
 * @date 09/01/24
 * @version 0.0.1 - Initial Release
 */
import React, { useRef } from "react";
import "./Cards.css";
import "moment-timezone";
import {
  Box,
  Card,
  CardContent,
  Dialog,
  Grid,
  Radio,
  TextField,
  Typography,
  Button,
} from "@mui/material";
import moment from "moment";
import InstaDeliveryPopUp from "../../../Components/DialogBoxes/PdwBookingSummary/InstaDeliveryPopUp";
import appColors from "../../../Assets/AppTheme/appColors";
import InstaDeliveryDialog from "./Booking/InstaDeliveryPopup";
import { useSelector } from "react-redux";
import CustomConsole from "../../../coustomConsole";
function DateAndTimeSelectionCard({
  setCurrentDate,
  currentDate,
  setSameDayDelivery,
  deliveryType,
  setDeliveryType,
  setOpenInstaAlertDialog,
  openInstaAlertDialog,
  openOtpFlag,
  setOpenOtpFlag,
  calenderOpen,
  setcalenderOpen,
  setToastFlag,
  setTostMsg,
  extraCharges
}) {
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const [currentMonth, setCurrentMonth] = React.useState(moment());
  const minDate = moment().format("YYYY-MM-DD"); // Example of min date
  const maxDate = moment().add(6, "days").format("YYYY-MM-DD"); // Example of max date
  const [blockedDates, setBlockedState] = React.useState([]); // Example of blocked dates
  const disablePast = moment().add(1, "days").format("YYYY-MM-DD");
  const [standardDate, setStandardDate] = React.useState(
    // moment().add(1, "days").format("YYYY-MM-DD")
    moment().tz("Asia/Kolkata").add(1, "days").format("YYYY-MM-DD")
  );

  CustomConsole(minDate);
  const daysInMonth = () => {
    const days = [];
    const currentMonthStart = currentMonth.clone().startOf("month");
    const endOfMonth = currentMonth.clone().endOf("month");

    // Check if blockedDates is defined before using it
    if (blockedDates) {
      while (currentMonthStart.isSameOrBefore(endOfMonth, "day")) {
        const isBlocked = blockedDates.some((blockedDate) =>
          moment(blockedDate).isSame(currentMonthStart, "day")
        );
        const isDisabled =
          currentMonthStart.isBefore(minDate) ||
          currentMonthStart.isAfter(maxDate);
        days.push({ date: currentMonthStart.clone(), isBlocked, isDisabled });
        currentMonthStart.add(1, "day");
      }
    }
    return days;
  };

  //function to handel click
  const handleDateClick = (day) => {
    if (!day.isBlocked && !day.isDisabled) {
      // setCurrentDate(day.date);
      const isToday = moment(day.date).isSame(moment(), "day");
      if (isToday) {
        setOpenInstaAlertDialog(true);
      } else {
        setCurrentDate(day.date);
        setcalenderOpen(false);
      }
    }
  };

  //for handling select tommorow date
  const handleDeliveryTypeTommorow = (value) => {
    if (value === 0) {
      setDeliveryType(() => value);
      CustomConsole("Setting date");
      setStandardDate(
        moment().tz("Asia/Kolkata").add(1, "days").format("YYYY-MM-DD")
      );
      setCurrentDate(moment(disablePast));
      setcalenderOpen(false);
    }
  };

  // useEffect for blockdate state
  React.useEffect(() => {
    setBlockedState(SkuProductDetails.blockDates || []);
  }, [SkuProductDetails.blockDates]);

  CustomConsole(blockedDates);
  return (
    <Dialog
      open={calenderOpen}
      sx={{ backgroundColor: appColors.dialogBackGround }}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Box
        container
        sx={{
          display: "flex",
          alignItems: "stretch",
          justifyContent: "flex-start",
          flexDirection: "column",
          gap: "1rem",
          padding: "2rem",
          background: appColors.secondaryCard,
          boxShadow: 5,
        }}
      >
        <div
          className="calendar-container"
          style={{
            border: "1px solid gray",
            borderRadius: "5px",
            padding: ".5rem",
          }}
        >
          <div className="header">
            <button
              onClick={() =>
                setCurrentMonth(currentMonth.clone().subtract(1, "month"))
              }
              className="button-91"
            >
              <span>{"<"}</span>
            </button>
            <div className="selectedText" style={{ margin: "0 .5rem" }}>
              {currentMonth.format("MMMM YYYY")}
            </div>
            <button
              onClick={() =>
                setCurrentMonth(currentMonth.clone().add(1, "month"))
              }
              className="button-91"
            >
              {">"}
            </button>
          </div>
          <div className="days">
            {daysInMonth().map((day) => (
              <div
                key={day.date.format("YYYY-MM-DD")}
                className={`${day.isBlocked ? "blocked" : "active"} ${
                  day.isDisabled ? "disabled" : ""
                } ${
                  day.date.isSame(currentDate, "day") ? "selected" : "hover"
                }`}
                onClick={() => handleDateClick(day)}
              >
                {day.date.format("D")}
              </div>
            ))}
          </div>
        </div>
        <InstaDeliveryDialog
          setOpenInstaAlertDialog={setOpenInstaAlertDialog}
          openInstaAlertDialog={openInstaAlertDialog}
          setDeliveryType={setDeliveryType}
          openOtpFlag={openOtpFlag}
          setOpenOtpFlag={setOpenOtpFlag}
          handleDeliveryTypeTommorow={handleDeliveryTypeTommorow}
          setcalenderOpen={setcalenderOpen}
          setCurrentDate={setCurrentDate}
          extraCharges={extraCharges}
        />
        <Button
          variant="contained"
          onClick={() => setcalenderOpen(false)}
          size="small"
          sx={{ backgroundColor: appColors.textColorNew, color: "#fff" }}
        >
          Close
        </Button>
      </Box>
    </Dialog>
  );
}

export default DateAndTimeSelectionCard;
