import React from "react";

export default function MenuIcon({ stroke }) {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0775 0H0.769264C0.565242 0 0.369577 0.0821784 0.225312 0.228457C0.0810471 0.374736 0 0.573132 0 0.780001V13.26C0 13.4669 0.0810471 13.6653 0.225312 13.8116C0.369577 13.9578 0.565242 14.04 0.769264 14.04H13.0775C13.2815 14.04 13.4772 13.9578 13.6214 13.8116C13.7657 13.6653 13.8467 13.4669 13.8467 13.26V0.780001C13.8467 0.573132 13.7657 0.374736 13.6214 0.228457C13.4772 0.0821784 13.2815 0 13.0775 0ZM12.3082 12.48H1.53853V1.56H12.3082V12.48Z"
        fill={stroke}
      />
      <path
        d="M13.0775 15.9598H0.769294C0.565273 15.9598 0.369607 16.042 0.225343 16.1883C0.0810777 16.3346 3.05176e-05 16.533 3.05176e-05 16.7398V29.2199C3.05176e-05 29.4267 0.0810777 29.6251 0.225343 29.7714C0.369607 29.9177 0.565273 29.9999 0.769294 29.9999H13.0775C13.2815 29.9999 13.4772 29.9177 13.6215 29.7714C13.7657 29.6251 13.8468 29.4267 13.8468 29.2199V16.7398C13.8468 16.533 13.7657 16.3346 13.6215 16.1883C13.4772 16.042 13.2815 15.9598 13.0775 15.9598ZM12.3082 28.4399H1.53856V17.5198H12.3082V28.4399Z"
        fill={stroke}
      />
      <path
        d="M28.8179 -0.00012207H16.5097C16.3057 -0.00012207 16.11 0.0820564 15.9657 0.228335C15.8215 0.374614 15.7404 0.57301 15.7404 0.779879V13.2599C15.7404 13.4668 15.8215 13.6652 15.9657 13.8114C16.11 13.9577 16.3057 14.0399 16.5097 14.0399H28.8179C29.0219 14.0399 29.2176 13.9577 29.3618 13.8114C29.5061 13.6652 29.5872 13.4668 29.5872 13.2599V0.779879C29.5872 0.57301 29.5061 0.374614 29.3618 0.228335C29.2176 0.0820564 29.0219 -0.00012207 28.8179 -0.00012207ZM28.0486 12.4799H17.2789V1.55988H28.0486V12.4799Z"
        fill={stroke}
      />
      <path
        d="M28.8179 15.9598H16.5097C16.3057 15.9598 16.11 16.042 15.9657 16.1883C15.8215 16.3346 15.7404 16.533 15.7404 16.7398V29.2199C15.7404 29.4267 15.8215 29.6251 15.9657 29.7714C16.11 29.9177 16.3057 29.9999 16.5097 29.9999H28.8179C29.0219 29.9999 29.2176 29.9177 29.3618 29.7714C29.5061 29.6251 29.5872 29.4267 29.5872 29.2199V16.7398C29.5872 16.533 29.5061 16.3346 29.3618 16.1883C29.2176 16.042 29.0219 15.9598 28.8179 15.9598ZM28.0486 28.4399H17.2789V17.5198H28.0486V28.4399Z"
        fill={stroke}
      />
    </svg>
  );
}
