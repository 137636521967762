/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

export const ReferlinkStyles = {
  referText: {
    fontWeight: "bold",
    color: appColors.headerTextColor,
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
  },
  referTextBottom: {
    fontWeight: "bold",
    color: appColors.darkGray,
    fontSize: appTheme.fontSizeSmall,
    display: "flex",
    justifyContent: "center",
  },
  referTextBottom1: {
    fontWeight: "bold",
    color: appColors.darkGray,
    fontSize: appTheme.fontSizeSmall,
    display: "flex",
    justifyContent: "center",
  },
  circle: {
    width: "74px",
    height: "74px",
    borderRadius: "50%",
    backgroundImage: appColors.backGroundGradient,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: ".5rem",
  },
  circleBox: {
    marginTop: "1rem",
  },
  circleText: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  circleTypo: {
    fontSize: appTheme.fontSizeSmall,
    color: appColors.darkGray,
    fontWeight: "bold",
  },
  number: {
    color: "#fff",
    fontWeight: "bold",
  },
  referText1: {
    fontWeight: "bold",
    color: appColors.blue,
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
    marginTop: "1rem",
  },
  referText2: {
    fontWeight: "bold",
    color: appColors.headerTextColor,
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
  },
  TextBoxWaterdrops: {
    display: "flex",
    width: { xs: "100%", sm: "20rem" },
    justifyContent: "space-between",
    alignItems: "center",
    border: "1px solid #121b47",
    backgroundColor: appColors.homePageCard,
    borderRadius: "10px",
    height: "40px",
    marginBottom: "1rem",
  },
  waterdropText: {
    fontSize: appTheme.fontSizeSmall,
    marginLeft: "1rem",
    fontWeight: "bold",
    color: appColors.darkGray,
  },
  waterdropNumber: {
    fontSize: appTheme.fontSizeSmall,
    marginRight: "1rem",
    fontWeight: "bold",
    color: appColors.darkGray,
  },
};
