/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import * as Material from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { red } from "@mui/material/colors";
import { FilterTransactionPayment } from "./tankerFilterData";
import appColors from "../../../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function FilterTransaction(props) {
  const [paymentMethod, setPaymentMethod] = React.useState("");
  const [tankers, setTankers] = React.useState("3");
  const [status, setStatus] = React.useState("");
  const [orderBy, setOrderBy] = React.useState("");

  React.useEffect(() => {}, [paymentMethod, tankers, status]);

  const handleClose = () => {
    // setTdsRange("none");
    // setCapacityRange("none");
    // setPriceRange("none");
    // setSupplierRatings("none");
    // setIsVerified(false);
    // setTankerType("all");
    setPaymentMethod("");
    setTankers("");
    setStatus("");
    setOrderBy("");
    props.handleCloseFilter();
  };

  const onFilterSubmit = () => {
    const filterObject = {
      paymentMethod: paymentMethod,
      tankers: tankers,
      status: status,
      orderBy: orderBy,
    };

    props.handleFilterSubmit(filterObject);
    props.handleCloseFilter();
  };

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
    >
      <Material.DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "centers",
          bgcolor: appColors.secondaryCard,
        }}
      >
        <Material.Typography
          sx={{
            fontWeight: "bold",
            color: appColors.lightGreen,
            fontSize: "1.2rem",
          }}
        >
          Filter
        </Material.Typography>
        {/* Date */}
        <Material.FormControl sx={{ marginTop: "10px" }}>
          <Material.InputLabel>Order By Date</Material.InputLabel>
          <Material.Select
            variant="standard"
            value={orderBy}
            onChange={(e) => setOrderBy(e.target.value)}
            sx={{ bgcolor: appColors.white }}
          >
            <Material.MenuItem value="AESC">Old First</Material.MenuItem>
            <Material.MenuItem value="DESC">Recent First</Material.MenuItem>
          </Material.Select>
        </Material.FormControl>

        {/* payment */}
        <Material.FormControl sx={{ marginTop: "25px" }}>
          <Material.InputLabel>Payment</Material.InputLabel>

          <Material.Select
            variant="standard"
            value={paymentMethod}
            onChange={(e) => setPaymentMethod(e.target.value)}
            sx={{ bgcolor: appColors.white }}
          >
            {FilterTransactionPayment.map((payment, key) => (
              <Material.MenuItem value={payment.value}>
                {payment.name}
              </Material.MenuItem>
            ))}
          </Material.Select>
        </Material.FormControl>

        {/* Tanker type */}
        {/* <Material.FormControl sx={{ marginTop: "25px" }}> */}
        {/* <Material.InputLabel>Tanker Type</Material.InputLabel> */}
        {/* <Material.Select
            variant="standard"
            value={tankers}
            onChange={(e) => setTankers(e.target.value)}
          > */}
        {/* {FilterTransactionTanker.map((tanker, key) => (
              <Material.MenuItem value={tanker.value}>
                Packaged Drinking Water
                <Material.ListItemIcon>
                  <img
                    src={tanker.icon}
                    alt=""
                    style={{
                      height: "30px",
                      width: "10px",
                      // objectFit: "cover",
                      padding: "5px",
                    }}
                  />
                </Material.ListItemIcon>
              </Material.MenuItem>
            ))} */}
        {/* </Material.Select> */}
        {/* </Material.FormControl> */}

        {/* status */}
        <Material.FormControl sx={{ marginTop: "25px" }}>
          <Material.InputLabel>Status</Material.InputLabel>

          <Material.Select
            variant="standard"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            sx={{ bgcolor: appColors.white }}
          >
            <Material.MenuItem value="SUCCESS">
              Success <CheckCircleIcon color="success" />
            </Material.MenuItem>
            <Material.MenuItem value="FAILED">
              Failed <ErrorIcon sx={{ color: red[500] }} />
            </Material.MenuItem>
          </Material.Select>
        </Material.FormControl>

        {/* Close & Filter Buttons */}
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "25px",
          }}
        >
          <Material.Button
            onClick={handleClose}
            size="small"
            sx={{
              textTransformation: "none",
              backgroundColor: appColors.Failed,
              color: "white",
              marginRight: "10px",
              "&:hover": {
                backgroundColor: appColors.Failed,
              },
            }}
          >
            Close
          </Material.Button>
          {/* <Material.Button
            onClick={props.handelClearFilter}
            size="small"
            sx={{
              textTransformation: "none",
              backgroundColor: appColors.darkBlue,
              marginLeft: "10px",
              color: "white",
              "&:hover": {
                backgroundColor: appColors.darkBlue,
              },
            }}
          >
            Clear
          </Material.Button> */}
          <Material.Button
            onClick={onFilterSubmit}
            size="small"
            sx={{
              textTransformation: "none",
              backgroundColor: appColors.textColorNew,
              marginLeft: "10px",
              color: "white",
              "&:hover": {
                backgroundColor: appColors.textColorNew,
              },
            }}
          >
            Filter
          </Material.Button>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
