import appColors from "../../../Assets/AppTheme/appColors";

/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author 
 * @description 
 * @date 06/06/23
 * @version 0.0.6 - Initial Release
 */
export const faqStyles = {
    BookACanHeading: {
        fontWeight: "bold",
        color: "#30a7ff",
        fontSize: ".8rem",
        // margin:"0.5rem"
    },
    para: {
        color: appColors.Textgray,
        fontSize: ".8rem",
        // margin:"0.5rem"
    }, heading:
    {
        // border: "1px solid #121b47",
        height: "10rem", marginTop: "10px",
        overflowX: "hidden",
        '&::-webkit-scrollbar': {
            width: '0.2em',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: "#121b47"
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor:
                "#30a7ff", /* Set the background color of the thumb */
        }

    },
    faqStyle:
    {
        // border: "1px solid #121b47",
        // marginTop: "10px",

    }
}