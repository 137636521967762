/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import CustomConsole from "../../coustomConsole";
export default function GetTransactionDetails(
  paymentStatus,
  paymentMethod,
  tankerType,
  sortType,
  pageNumber
) {
  let api = useAxios();
  var payment_status = [];
  var payment_method = [];
  var tanker_type = [];
  const sort = sortType;

  if (paymentStatus !== "") {
    payment_status.push(paymentStatus);
  }
  if (paymentMethod !== "") {
    payment_method.push(paymentMethod);
  }
  if (tankerType !== "") {
    tanker_type.push(tankerType);
  }

  return async (dispatch) => {
    try {
      const result = await api.post("/PM/getUserPaymenthistory", {
        payment_status,
        payment_method,
        sort,
        pageNumber,
      });
      CustomConsole(result);
      if (result.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_TRANSACTION_DETAILS,
          payload: result,
        });
      } else {
      }
    } catch (error) {}
  };
}

export function GetWalletTransactionDetails(object) {
  let api = useAxios();
  return async (dispatch) => {
    try {
      const response = await api.get("/OM/wallet_transactions", {
        params: object,
      });
      CustomConsole(response);
      CustomConsole(response.data);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_WALLET_TRANSACTIONS,
          payload: response.data,
        });
      } else {
      }
    } catch (error) {}
  };
}

export function GetBankDownTimeDetails() {
  let api = useAxios();
  return async (dispatch) => {
    try {
      const response = await api.get("/SSM/bankDownTime");
      CustomConsole(`Response GetBankDownTimeDetails`);
      CustomConsole(response.data);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_BANK_DOWN_TIME,
          payload: response.data,
        });
      } else {
      }
    } catch (error) {}
  };
}
