/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import waterDrop from "../../../Assets/newMobileIcon/waterDrops 1.svg";
import ShareIcon from "@mui/icons-material/Share";
import { makeStyles } from "@mui/styles";
import signup from "../../../Assets/newMobileIcon/signup.svg";
import appColors from "../../../Assets/AppTheme/appColors";
const useStyles = makeStyles({
  rootStep: {
    "& .Mui-disabled .MuiStepIcon-root": { color: "#489CFB" },
    "& .MuiStepLabel-label": {
      color: appColors.darkGray,
      fontWeight: "600",
    },
  },
});
export default function ReferInfoCard() {
  const classes = useStyles();

  //for icon color
  const iconColorStyle = {
    color: appColors.textColorNew, // Change 'blue' to the desired color
  };

  //function for custamized icons
  function CustomStepIcon() {
    // Custom component to render your desired image as an icon
    return (
      <img
        src={waterDrop}
        style={{ height: "3rem", width: "1.5rem" }}
        alt="Bookwater"
      />
    );
  }

  //function for custamized icons
  function CustomStepIconSignup() {
    // Custom component to render your desired image as an icon
    return (
      <img
        src={signup}
        style={{ height: "3rem", width: "2rem" }}
        alt="Bookwater"
      />
    );
  }
  return (
    <Stepper className={classes.rootStep} orientation="vertical">
      <Step disabled>
        <StepLabel
          StepIconComponent={() => <ShareIcon style={iconColorStyle} />}
        >
          Invite a friend to use Bookwater.
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={CustomStepIconSignup}>
          Wait for your friend to signup
        </StepLabel>
      </Step>
      <Step>
        <StepLabel StepIconComponent={CustomStepIcon}>
          Earn rewards as water drops
        </StepLabel>
      </Step>
    </Stepper>
  );
}
