/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Child Component for check out page
 *
 */

import React from "react";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import IconListManager from "../../../Assets/AppTheme/appIcons";
import CustomizedSwitches from "../../Buttons/SwitchButton";
import { useSelector } from "react-redux";
import InformationDialog from "../../DialogBoxes/info/infoDialogBox";
import { Box, Card, Typography } from "@mui/material";
export default function SpecialRequestCard({ handelOtpDelivery, specialReq }) {
  const iconList = IconListManager();
  const appMeta = useSelector((state) => state.appMetaDataDetails);
  const [openInfoDialog, setOpenInfoDialog] = React.useState(false);
  const [infoDialogText, setInfoDialogText] = React.useState("");
  const [otpLessDiss, setOtpLessDiss] = React.useState("");
  //useEffect for getting otp less disclimar text details
  React.useEffect(() => {
    let otpless = appMeta?.appMetaData?.find(
      (item) => item.key === "otp_less_delivery_disclaimer"
    );
    //  console.log(otpless.value[0].v);
    setOtpLessDiss(otpless?.value[0]?.v);
  }, [appMeta]);
  //For opening info dialog box
  const handelInfoIcon = (text) => {
    setOpenInfoDialog(true);
    setInfoDialogText(text);
  };
  return (
    <>
      <Typography sx={newBookingstyles.cardHeaderStyle}>
        Special Request&nbsp;
        <span>
          <img
            src={iconList.infoIconIcon}
            alt=""
            onClick={() => handelInfoIcon("OTP")}
          />
        </span>
      </Typography>
      <Card sx={newBookingstyles.cardStyles3}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            padding: "1rem",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={newBookingstyles.BoxHeader}>
            <img alt="" src={iconList.otpIcon} /> Delivery Without OTP
          </Typography>
          <CustomizedSwitches
            handelOtpDelivery={handelOtpDelivery}
            specialReq={specialReq}
          />
        </Box>
      </Card>
      <InformationDialog
        setOpenInfoDialog={setOpenInfoDialog}
        otpLessDiss={otpLessDiss}
        openInfoDialog={openInfoDialog}
        infoDialogText={infoDialogText}
        setInfoDialogText={setInfoDialogText}
      />
    </>
  );
}
