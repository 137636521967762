/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";

import { Box, Dialog, Typography } from "@mui/material";
import { AddEditAddressComponent } from "../../CommonComponents/addressAddEdit";
import CustomConsole from "../../../coustomConsole";

export function AddEditAddressDialogBox({
  open,
  handleClose,
  selectedAddress,
  addressModule,
  editAddressData,
  setOpen,
}) {
  CustomConsole(selectedAddress+"selectedAddress");
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ backgroundColor: appColors.dialogBackGround }}
      PaperProps={{
        sx: {
          // backgroundColor: appColors.secondaryColor, // Set the background color for the dialog
          borderRadius: "20px",
        },
      }}
    >
      <Box sx={{ backgroundColor: appColors.secondaryCard, padding: "1rem" }}>
        <Typography
          sx={{
            color: appColors.secondaryText,
            fontWeight: "bold",
          }}
        >
          {addressModule === "add"
            ? "Add Address Details"
            : "Edit Address Details"}
        </Typography>
      </Box>
      <AddEditAddressComponent
        handelClose={handleClose}
        selectedAddress={selectedAddress}
        addressModule={addressModule}
        editAddressData={editAddressData}
        setOpen={setOpen}
      />
      <img
        alt=""
        src={closeIcon}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          cursor: "pointer",
          height: "30px",
        }}
        onClick={handleClose}
      />
    </Dialog>
  );
}
