// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  orderError: true,
  msg: "",
  orderId: [],
  reOrderError: true,
  reOrderDetails: {},
  loading: false,
  editOrderError: true,
  editOrderMsg: "",
  editDetails: {},
  newOrderId: {},
  newOrderIdError: true,
  allOrderData: {},
  exitBookingFlag: true,
};

const skuOrderPlacingReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.SKU_ORDER_PLACING_ACTION:
      return {
        ...state,
        error: action.payload.data.error,
        orderError: action.payload.data.error,
        msg: action.payload.data.msg,
        orderId: action.payload.data.data,
        allOrderData: action.payload.data,
        exitBookingFlag:action.payload.data.error,
      };
    case actionType.CLEAR_PENDING_ORDER_DATA:
      return { ...state, allOrderData: {} };
    case actionType.RE_ORDER_DETAILS_FLAG:
      return { ...state, loading: true };
    case actionType.REORDER_DETAILS_ACTION:
      return {
        ...state,
        error: action.payload.data.error,
        reOrderError: action.payload.data.error,
        msg: action.payload.data.msg,
        reOrderDetails: action.payload.data.data,
        loading: false,
      };
    case actionType.EDIT_ORDER_ACTION_SKU:
      return {
        ...state,
        error: action.payload.data.error,
        editOrderError: action.payload.data.error,
        editOrderMsg: action.payload.data.msg,
        editDetails: action.payload.data,
        loading: false,
      };
    case actionType.NEW_ORDERID_PAYSHARP:
      return {
        ...state,
        newOrderIdError: action.payload.data.error,
        error: action.payload.data.error,
        newOrderId: action.payload.data.data,
      };
    case actionType.RESET_ORDER_STATE:
      return {
        ...state,
        editOrderError: true,
        error: true,
        orderError: true,
        newOrderId: {},
        msg: "",
        orderId: [],
      };
    case actionType.CANCEL_BOOKING_ORDER_ERROR:
      return {
        ...state,
        orderError: true,
      
      };
    default:
      return state;
  }
};

export default skuOrderPlacingReduser;
