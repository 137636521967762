/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styles } from "./myAddressBookStyle";
import { ThemeProvider } from "@mui/material/styles";
import { BiCurrentLocation } from "react-icons/bi";
import { MdFiberPin } from "react-icons/md";
import { makeStyles } from "@material-ui/core";
import { SearchAvailability } from "../../../../Redux/actions/bookNowAction";
import { useDispatch, useSelector } from "react-redux";
import { createTheme } from "@mui/material/styles";
import axios from "axios";
import appColors from "../../../../Assets/AppTheme/appColors";
import closeIcon from "../../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import appTheme from "../../../../Assets/AppTheme/appTheme";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});
const API_endpoint = `https://api.openweathermap.org/data/2.5/onecall?`;
const API_key = `44ab22517042e132e781e42ad40d784f`;

export function AddNewAddressDialogBox(props) {
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [details, setDetails] = React.useState({
    name: "",
    phone: "",
    address1: "",
    address2: "",
    address3: "",
    landmark: "",
    pincode: "",
    serviceArea: "",
    deliveryTime: 100,
    location: "",
    address_type: "Home",
  });
  const [serviceArea, setServiceArea] = React.useState({
    value: "none",
    message: "",
    error: true,
  });
  const [latitude, setLatitude] = React.useState(0);
  const [longitude, setLongitude] = React.useState(0);
  const [nameHt, setNameHt] = React.useState("");
  const [phoneHt, setPhoneHt] = React.useState("");
  const [address1Ht, setAddress1Ht] = React.useState("");
  const [address2Ht, setAddress2Ht] = React.useState("");
  const [addressType, setAddressType] = React.useState("Home");
  const [landmarkHt, setLandmarkHt] = React.useState("");
  const [pincodeHt, setPincodeHt] = React.useState("");
  const [deliveryTimeHt, setDeliveryTimeHt] = React.useState("");
  // const [locationHt, setLocationHt] = React.useState("");

  const [isPrimary, setIsPrimary] = React.useState(false);
  const [error, setError] = React.useState(true);
  const helperTextStyles = makeStyles((theme) => ({
    root: {
      color: appColors.darkGray,
    },
    error: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: theme.palette.common.white,
      },
    },
  }));
  const helperTestClasses = helperTextStyles();

  const useStyles = makeStyles((theme) => ({
    modalTxt: {
      width: "100%",
      margin: "10px 0",
    },
    btn: {
      marginLeft: "auto",
    },
  }));

  const style = {
    display: "flex",
    justifyContent: "center",
    bgcolor: "background.paper",
    backgroundColor: appColors.secondaryCard,
  };
  const theme = createTheme({
    palette: {
      error: {
        main: "#152057",
        color: "#fff", // Change this to the desired blue color
      },
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#707070", // Change this to your desired border color
    },
  });

  // pincode and area new logic
  const classes = useStyles();
  const dispatch = useDispatch();
  const bookNowState = useSelector((state) => state.bookNow);
  const userAddressList = useSelector((state) => state.settings);
  const [postOffice, setPostOffice] = React.useState([""]);
  const [addAddressFlag, setAddAddressFlag] = React.useState(false);

  React.useEffect(() => {
    if (userAddressList.isAddressListUpdated && addAddressFlag) {
      setDetails({
        name: "",
        phone: "",
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        pincode: "",
        serviceArea: "",
        deliveryTime: 100,
        location: "",
        latitude: latitude,
        longitude: longitude,
        address_type: "Home",
      });
      setPincode({
        value: "",
        message: "",
        error: true,
      });
      props.handleClose();
      setAddAddressFlag(false);
    }
  }, [userAddressList]);

  React.useEffect(() => {
    setPostOffice(bookNowState.pinCodeAreas);
    if (!bookNowState.error && bookNowState.pincodeAreaFlag) {
      bookNowState.error = true;
      bookNowState.pincodeAreaFlag = false;
      setPostOffice(bookNowState.pinCodeAreas);
      // console.log(`serviceArea ${JSON.stringify(serviceArea)}`);
    }
  }, [bookNowState]);

  const [pincode, setPincode] = React.useState({
    value: "",
    message: "",
    error: true,
  });

  //For capturing lattitude and longitutde
  React.useEffect(() => {
    // Check if the geolocation API is available in the browser
    if ("geolocation" in navigator) {
      // Get the current position
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLatitude(position.coords.latitude);
          setLongitude(position.coords.longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not available in this browser.");
    }
  }, []);

  const onChangePincode = (e) => {
    bookNowState.error = false;
    bookNowState.pincodeAreaFlag = true;
    setPostOffice([""]);
    let error = false;
    let reg = /^\d{6}$/;
    setPincode((prev) => ({ ...prev, value: e.target.value }));
    setDetails({ ...details, pincode: e.target.value });
    setServiceArea({ ...details, serviceArea: "" });
    setDetails((prev) => ({ ...prev, serviceArea: "" }));
    if (!reg.test(e.target.value)) {
      error = true;
      setPincode((prev) => ({
        ...prev,
        message: "*Invalid Pincode",
        error: true,
      }));
      setServiceArea((prev) => ({ ...prev, value: "", error: true }));
    } else {
      setPincode((prev) => ({ ...prev, message: "", error: false }));
    }
    if (error === false) {
      dispatch(SearchAvailability(e.target.value));
      //setDetails({ ...details, serviceArea: ""});
    }
  };

  const onChangeServiceArea = (e) => {
    setServiceArea((prev) => ({
      ...prev,
      value: e.target.value,
      error: false,
    }));
    setDetails({ ...details, serviceArea: e.target.value });
  };

  React.useEffect(() => {
    if (validate() === true && details.serviceArea !== "") {
      setError(false);
    } else {
      setError(true);
    }
  }, [details]);

  const handleChange = (prop) => (event) => {
    setDetails({ ...details, [prop]: event.target.value });
  };
  const validate = () => {
    let ret = true;
    let reg = /^[6-9][0-9]{9}$/; //Phone Number validation
    if (!reg.test(details.phone)) {
      setPhoneHt("Please enter a 10-digit Numbers start with 9/8/7/6");
      ret = false;
    } else {
      setPhoneHt("");
    }
    reg = /^[a-zA-Z]+$/;

    reg = /^[1-9][0-9]{5}$/;
    if (!reg.test(details.pincode)) {
      setPincodeHt("Please Enter valid Pincode");
      ret = false;
    } else {
      setPincodeHt("");
    }
    if (details.name.trim() === "") {
      setNameHt("Please Enter Valid Name");
      ret = false;
    } else {
      setNameHt("");
    }
    if (details.address1.trim() === "") {
      setAddress1Ht("Please Enter Address Line 1");
      ret = false;
    } else {
      setAddress1Ht("");
    }
    if (details.address2.trim() === "") {
      setAddress2Ht("Please Enter Address Line 2");
      ret = false;
    } else {
      setAddress2Ht("");
    }
    if (details.deliveryTime === "") {
      setDeliveryTimeHt("Please Select Delivery Time");
      ret = false;
    } else {
      setDeliveryTimeHt("");
    }
    return ret;
  };

  const handleSubmit = () => {
    if (!error) {
      props.handleAddNewAddress(details, isPrimary);
      setAddAddressFlag(true);
      props.setAddAddressDialog(false);
      Reset();
      props.setchooseAddressDialog(false);
    }
  };

  //Function for reseeting fields after close or submit
  function Reset() {
    setPostOffice([""]);
    setDetails({
      name: "",
      phone: "",
      address1: "",
      address2: "",
      address3: "",
      landmark: "",
      pincode: "",
      serviceArea: "",
      deliveryTime: 100,
      location: "",
      address_type: "Home",
    });
    setPincode({
      value: "",
      message: "",
      error: true,
    });
  }

  //Use effect for tracking onclose dialogbox
  React.useEffect(() => {
    Reset();
  }, [props.open]);

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: appTheme.fontSizeSmall, // Set the desired label color
    },
  };

  //function to change delivery typr
  const handelSelectAddressType = (value) => {
    setAddressType(value);
    if (value !== "Other") {
      setDetails({ ...details, address_type: value });
    }
  };

  React.useEffect(() => {
    if (props?.data) {
      console.log("props.data.is_primary");
      console.log(props?.data?.is_primary);
      setIsPrimary(props?.data?.is_primary);
    }
  }, [props?.data]);


  console.log("props?.data")
  console.log(props?.data)
  return (
    <Material.Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth
      maxWidth="sm"
      sx={{ backgroundColor: appColors.dialogBackGround }}
    >
      <Material.Box
        sx={{ backgroundColor: appColors.secondaryCard, padding: "1rem" }}
      >
        <Material.Typography
          sx={{
            color: appColors.secondaryText,
            fontWeight: "bold",
          }}
        >
          Add Address Details
        </Material.Typography>
      </Material.Box>
      <Material.Box sx={style}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            maxHeight: "100%",
            width: { md: "60%", xs: "90%" },
            margin: "auto",
          }}
        >
          <Material.Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Material.Typography
              sx={{
                fontSize: appTheme.fontSizeMedium,
                color: appColors.darkGray,
                marginBottom: ".6rem",
              }}
            >
              Mark Address as*
            </Material.Typography>
            <Material.Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                flexDirection: "row",
                width: "100%",
              }}
            >
              {/* Primary or Not Check Box */}
              <Material.Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor:
                    addressType === "Home"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType === "Home"
                      ? appColors.textColorNew
                      : appColors.white,
                  "&:hover": {
                    backgroundColor:
                      addressType === "Home"
                        ? appColors.lightblue
                        : appColors.textColorNew,
                    color:
                      addressType === "Home"
                        ? appColors.textColorNew
                        : appColors.white,
                  },
                }}
                onClick={() => handelSelectAddressType("Home")}
              >
                Home
              </Material.Button>
              <Material.Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor:
                    addressType === "Work"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType === "Work"
                      ? appColors.textColorNew
                      : appColors.white,
                  "&:hover": {
                    backgroundColor:
                      addressType === "Work"
                        ? appColors.lightblue
                        : appColors.textColorNew,
                    color:
                      addressType === "Work"
                        ? appColors.textColorNew
                        : appColors.white,
                  },
                }}
                onClick={() => handelSelectAddressType("Work")}
              >
                Work
              </Material.Button>
              <Material.Button
                size="small"
                variant="contained"
                sx={{
                  backgroundColor:
                    addressType === "Other"
                      ? appColors.lightblue
                      : appColors.textColorNew,
                  color:
                    addressType === "Other"
                      ? appColors.textColorNew
                      : appColors.white,
                  "&:hover": {
                    backgroundColor:
                      addressType === "Other"
                        ? appColors.lightblue
                        : appColors.textColorNew,
                    color:
                      addressType === "Other"
                        ? appColors.textColorNew
                        : appColors.white,
                  },
                }}
                onClick={() => handelSelectAddressType("Other")}
              >
                Other
              </Material.Button>
            </Material.Box>
          </Material.Box>
          {/* Full Name */}
          <Material.TextField
            variant="outlined"
            label="Full Name *"
            sx={styles.modalTxt}
            size="small"
            helperText={nameHt}
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            FormHelperTextProps={{ classes: helperTestClasses }}
            onChange={handleChange("name")}
            value={details.name}
          />
          {/* Mobole Number */}
          <Material.TextField
            variant="outlined"
            label="Mobile Number *"
            type="number"
            sx={styles.modalTxt}
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            size="small"
            onChange={handleChange("phone")}
            value={details.phone}
            helperText={phoneHt}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          {/* Address Line 1 */}
          <Material.TextField
            variant="outlined"
            label="Address Line 1 *"
            sx={styles.modalTxt}
            size="small"
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={handleChange("address1")}
            value={details.address1}
            helperText={address1Ht}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          {/* Address Line 2 */}
          <Material.TextField
            variant="outlined"
            label="Address Line 2 *"
            sx={styles.modalTxt}
            size="small"
            InputProps={{
              sx: {
                bgcolor: appColors.secondaryCard,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              },
            }}
            InputLabelProps={inputLabelProps}
            onChange={handleChange("address2")}
            value={details.address2}
            helperText={address2Ht}
            FormHelperTextProps={{ classes: helperTestClasses }}
          />
          <Material.Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            {/* Address Line 3 */}
            <Material.TextField
              variant="outlined"
              label="Address Line 3"
              sx={styles.modalTxt}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              size="small"
              onChange={handleChange("address3")}
              value={details.address3}
            />
            {addressType === "Other" && (
              <Material.TextField
                variant="outlined"
                label="Address Type"
                sx={styles.modalTxt}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                size="small"
                onChange={handleChange("address_type")}
                value={details.address_type}
              />
            )}
            {/* Land Mark */}
            <Material.TextField
              variant="outlined"
              label="Landmark"
              sx={styles.modalTxt}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              size="small"
              onChange={handleChange("landmark")}
              value={details.landmark}
              FormHelperTextProps={{ classes: helperTestClasses }}
            />
          </Material.Box>
          <Material.Box
            sx={{
              display: "flex",
              flexDirection: { md: "row", xs: "column" },
              gap: 1,
            }}
          >
            <Material.TextField
              // sx={{ width: "100%", marginTop: "10px", padding: "10px" }}
              variant="outlined"
              sx={styles.modalTxt}
              size="small"
              label="Enter Pincode *"
              value={pincode.value}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 6);
              }}
              onChange={onChangePincode}
              InputProps={{
                sx: {
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                },
              }}
              InputLabelProps={inputLabelProps}
              type="number"

              // FormHelperTextProps={{ classes: helperTestClasses }}
            />
            <Material.FormControl
              // fullWidth
              size="small"
              // sx={{ marginTop: "10px", padding: "10px" }}
              variant="outlined"
              sx={styles.modalTxt}
            >
              <Material.InputLabel
                id="demo-simple-select-la"
                sx={{ color: appColors.darkGray }}
              >
                Select Area
              </Material.InputLabel>
              <Material.Select
                value={serviceArea.value}
                label="Select Area"
                disabled={serviceArea.error}
                onChange={onChangeServiceArea}
                sx={{
                  bgcolor: appColors.secondaryCard,
                  color: appColors.darkGray,
                  "& .MuiOutlinedInput-notchedOutline": {
                    borderColor: appColors.darkGray,
                  },
                }}
                InputLabelProps={inputLabelProps}
              >
                <Material.MenuItem
                  disabled
                  value="none"
                  sx={{
                    fontSize: appTheme.fontSizexl,
                    color: appColors.darkGray,
                  }}
                >
                  Select Service Area
                </Material.MenuItem>
                {postOffice?.length > 0
                  ? postOffice?.map((po, key) => {
                      return (
                        <Material.MenuItem value={po?.Name} key={key}>
                          {po.Name}
                        </Material.MenuItem>
                      );
                    })
                  : ""}
              </Material.Select>
            </Material.FormControl>
          </Material.Box>

          <Material.Box
            sx={{
              display: "flex",
              marginBottom: "1rem",
              flexDirection: "row",
              gap: 1,
              justifyContent: { md: "flex-end", xs: "center" },
            }}
          >
            {/* Primary or Not Check Box */}
            <Material.FormControlLabel
              sx={{ marginTop: "8px", color: appColors.darkGray }}
              control={<Material.Checkbox />}
              label={
                <Material.Typography sx={{ fontSize: appTheme.fontSizexs }}>
                  Mark as Primary
                </Material.Typography>
              }
              onChange={() => setIsPrimary(!isPrimary)}
            />
            {/* SubMit Botton */}

            <Material.Button
              disabled={error}
              variant="contained"
              size="small"
              sx={styles.btn}
              onClick={handleSubmit}
            >
              Save
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.Box>
      <img
        alt=""
        src={closeIcon}
        style={{
          position: "absolute",
          right: 0,
          top: 0,
          cursor: "pointer",
          height: "30px",
        }}
        onClick={props.handleClose}
      />
    </Material.Dialog>
  );
}
