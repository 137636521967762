// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 08/Jan/2024
// File Name: depositHistroyMain.jsx
// Author: Gowtham Prasath
// Email/Phone: gowtham.prasath@bookwater.com / +91-8817679918
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import CanImage from "../../Assets/Images/WaterCanImg.png";
import { Box, Typography } from "@mui/material";
import appColors from "../../Assets/AppTheme/appColors";
import { useSelector } from "react-redux";
import canImage from "../../Assets/Images/WaterCanImg.png";
import ImageLoader from "../../Components/Utils/imageLoader";
import useProductImages from "../../Components/Utils/productImages";
import appTheme from "../../Assets/AppTheme/appTheme";
export default function SkuDetailsProfile({ setSelectedData }) {
  const [selectedItem, setSelectedItem] = React.useState(0);
  const depositDetails = useSelector((state) => state.depositDetails);
  //function for handling selection box
  const handleItemClick = (index, data) => {
    setSelectedItem(data?.user_stock_master_id);
    setSelectedData(data);
  };

  //useEffect for setting default product
  React.useEffect(() => {
    let seletedProduct = depositDetails?.user_stock?.find(
      (data) => data.user_stock_master_id === selectedItem
    );
    if (seletedProduct !== undefined) {
      setSelectedData(seletedProduct);
    } else {
      setSelectedData(depositDetails?.user_stock[0]);
      setSelectedItem(depositDetails?.user_stock[0].user_stock_master_id);
    }
  }, [depositDetails.user_stock]);

  const { images, isLoading, error } = useProductImages(
    depositDetails?.user_stock,
    "deposit"
  );
  return (
    <>
      {depositDetails?.user_stock?.map((details, index) => (
        <Box
          key={index}
          sx={{
            boxShadow: 5,
            width: "90%",
            margin: "1rem .5rem",
            borderRadius: "15px",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            padding: ".2rem",
            bgcolor:
              selectedItem === details?.user_stock_master_id
                ? "#60baff"
                : appColors.cardColor,
            "&:hover": {
              bgcolor: "#60baff",
            },
          }}
          onClick={() => handleItemClick(index, details)}
        >
          <ImageLoader
            filename={details?.more_details?.image}
            styles={{
              width: "100%", // Allow the width to adjust according to the aspect ratio
              height: "5rem", // Allow the height to adjust according to the aspect ratio
              objectFit: "fill",
            }}
          />
          <Typography
            sx={{
              color: appColors.darkGray,
              fontSize: ".8rem",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            {details?.more_details?.name === null
              ? details?.container_capacity + " " + details?.container_material
              : details?.more_details?.name}
          </Typography>
        </Box>
      ))}
    </>
  );
}
