/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import Lottie from "lottie-web";
import animate from "lottie-web";
import scanQr from "../../Assets/newMobileIcon/Booking/qr-code-scan.svg";
import {
  Box,
  Card,
  Button,
  Typography,
  Grid,
  CardContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import appColors from "../../Assets/AppTheme/appColors";
import { newBookingstyles } from "../BookNow/SkuBooking/newBookingPageStyles";
import {
  ApplyPromocode,
  GetPromoCodes,
} from "../../Redux/actions/promocodesAction";
import BiconLoader from "../../Assets/Animations/preloader.gif";
import MyWallet from "../../Components/PaymentComponent/mywallet/myWallet";
import { makeStyles } from "@mui/styles";
import WalletFooter from "./walletFooter";
import razorPay from "../../Assets/newMobileIcon/Booking/Cards.svg";
import UPISvg from "../../Assets/AppIcons/PGUPI.svg";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import IconListManager from "../../Assets/AppTheme/appIcons";
import chipIcon from "../../Assets/newMobileIcon/chip.svg";
import Biconcard from "../../Assets/newMobileIcon/Water wallet without Card Background.svg";
import PromocodesNewCard from "../../Components/DialogBoxes/PdwBookingSummary/PromodetailsCard";
import WalletExplain from "./WalletExplain";
import ChoosePaymentDialogSKU from "../../Components/DialogBoxes/SkuDialogs/ChoosePaymentDialog";
import { GetSetUpConfigAction } from "../../Redux/actions/getSetUpConfigAction";
import { ResetPromoComponentState } from "../../Redux/actions/ordersAction";
import CouponAppliedDialog from "../../Components/DialogBoxes/SkuDialogs/Booking/couponAppliedDialog";
import WalletTransactionCard from "../../Components/Cards/SkuCards/transactionCard";
import { GetWalletTransactionDetails } from "../../Redux/actions/transactionsAction";
import { ToastMsgState } from "../../Redux/actions/toastMsg";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import { ResetComponentState } from "../../Redux/actions/appMetaDataAction";
import appTheme from "../../Assets/AppTheme/appTheme";
import moment from "moment";

function AddMoney() {
  const { pathname, search } = useLocation();
  const dispatch = useDispatch();
  const iconList = IconListManager();
  const promocodeData = useSelector((state) => state.promocodeDetails);
  const paymentBlockDetails = useSelector(
    (state) => state.paymentDetailsSku.paymentBlockData
  );
  const profile = useSelector((state) => state.userProfile);
  const transactionDetails = useSelector((state) => state.transactionDetails);
  const BlocketPayments = new Set(paymentBlockDetails);
  const pageTitleSideBar = pathname.split("/dashboard/");
  const appmetaDataDetail = useSelector((state) => state.appMetaConfigData);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [handelPromoFlag, setHandePromoFlag] = React.useState(false);
  const [promoFlag, setPromoFlag] = React.useState(false);
  const [promoApplied, setPromoApplied] = React.useState(false);
  const [openVbDialog, setOpenVbDialog] = React.useState(false);
  const [promoId, setPromoId] = React.useState("");
  const [minimumWalletAmount, setMinimumWalletAmount] = React.useState(0);
  const [upiPay, setUpiPay] = React.useState(false);
  const [openPromoDialog, setOpenPromoDialog] = React.useState(false);
  const [amountEntered, setEnteredPayAmount] = React.useState(0);
  const [promoSuccess, setPromoSuccess] = React.useState(true);
  const [pMDialogOpen, setPMDialogOpen] = React.useState(false);
  const [pmDialogBoxInWallet, setPmDialogBoxInWallet] = React.useState(false);
  const [appliedPromo, setAppliedPromo] = React.useState("");
  const [promoCode, setpromoCode] = React.useState("");
  const [previousPromoCode, setpreviousPromoCode] = React.useState("");
  const [paymentFlag, setpaymentFlag] = React.useState(false);
  const [upiId, setUpiId] = React.useState("");
  const [razorPayFlag, setrazorPayFlag] = React.useState(false);
  const [upiIdVPAObj, setUpiIdVPAObj] = React.useState({});
  const [verifyUpiClicked, setVerifyUpiIdClicked] = React.useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = React.useState(false);
  const [animation, setAnimation] = React.useState(false);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = React.useState(paymentTypeWallet.PAYSHARP);
  const [upiUserOption, setUpiUserOption] = React.useState("2");
  const [upiPayUserOption, setUpiPayUserOption] = React.useState("");
  const [payType, setPayType] = React.useState(0);
  const [verifyBtn, setVerifyBtn] = React.useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = React.useState(false);
  const [opean, setOpean] = React.useState(false);
  const [payFor, setPayFor] = React.useState("");
  const [payOptionCheck, setPayOptionCheck] = React.useState(false);
  const [referLoading, setReferLoading] = React.useState(true);
  const [choosenPartial, setChoosenPartial] = React.useState(false);
  const [subcribePaymentFlag, setsubcribePaymentFlag] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState("PAYSHARP");
  const CouponAnimation = React.useRef(null);
  const [promoDialogOpen, setPromoDialogOpen] = React.useState(false);
  const [updatePaymentClick, setUpdatePaymentClick] = React.useState(false);
  const [showPromocode, setShowPromocodes] = React.useState(true);
  const useStyles = makeStyles({
    atmCard: {
      backgroundColor: appColors.walletCard,
      color: "white",
      borderRadius: 10,
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    cardNumber: {
      fontSize: 15,
      marginBottom: 15,
    },
    cardHolder: {
      fontSize: 15,
      color: "#d4ebff",
      // textTransform: "uppercase",
    },
    expiration: {
      fontSize: 14,
    },
  });
  const classes = useStyles();
  const openPaymentDialog = () => {
    if (amountEntered >= parseFloat(minimumWalletAmount)) {
      setPMDialogOpen(true);
      setPmDialogBoxInWallet(true);
    }
  };

  //minimumwalletAmount updating
  React.useEffect(() => {
    // console.log(appmetaDataDetail)
    appmetaDataDetail.metaData.map((data) => {
      // console.log(data)
      if (data.key === "minimum_wallet_amount") {
        // console.log(data.value)
        setMinimumWalletAmount(data.value[0].v);
      }
    });
  }, [appmetaDataDetail]);

  //function for validating typing value in textfield
  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    // Allow only numbers and disallow minus sign
    if (
      charCode === 45 ||
      (charCode > 31 && (charCode < 48 || charCode > 57))
    ) {
      event.preventDefault();
    }
  };

  //function for search promocodes based on value
  const searchPromoCodes = (promoLabel) => {
    const reqObj = {
      final_water_price: 1,
      can_count: 0,
      is_wallet: true,
    };
    dispatch(GetPromoCodes(reqObj, promoLabel));
  };

  // To Control the Page Heading on Click of Back & froward Button
  React.useEffect(() => {
    const pageTitle = pathname.split("/dashboard/");
    const name = new URLSearchParams(search).get("rechargeValue");
    if (name >= minimumWalletAmount) {
      setEnteredPayAmount(name);
    }
    if (name === null) {
      setEnteredPayAmount(parseInt(minimumWalletAmount * 32.99));
    }
  }, [pathname, search, minimumWalletAmount]);

  //useEffect for getting promocode details
  React.useEffect(() => {
    if (openPromoDialog) {
      const promoLabel = "";
      const reqObj = {
        final_water_price: 1,
        can_count: 0,
        is_wallet: true,
      };
      dispatch(GetPromoCodes(reqObj, promoLabel));
    }
  }, [openPromoDialog]);

  //useeffect for lottie animation
  React.useEffect(() => {
    if (promoCode !== "" && showPromocode) {
      Lottie.loadAnimation({
        container: CouponAnimation.current,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: require("../../Assets/Animations/CouponCodeAnimation.json"),
      });
      animate.setSpeed(0.6);
      setAnimation(true);
    }
  }, [promoCode]);

  const removePromo = () => {
    setpromoCode("");
  };

  const handelOpenPromo = () => {
    setOpenPromoDialog(true);
  };

  //function to handel input change
  const handleInputChange = (e) => {
    const inputText = e.target.value.replace(/\D/g, "");
    const limitedInput = inputText.slice(0, 5);
    if (limitedInput < 10 && payment !== 0) {
      setUpdatePaymentClick(false);
    }
    setEnteredPayAmount(limitedInput);
    const appliedPromo = promocodeData?.Promocodedata?.find(
      (promo) => promo.promo_label === promoCode
    );
    setShowPromocodes(false);
    // Check if there's an applied promo and whether the input is less than its minimum required amount
    if (
      appliedPromo &&
      parseFloat(limitedInput) < parseFloat(appliedPromo.min_charges_to_use)
    ) {
      removePromo(); // Remove promo only if input is below the required minimum charges
      setpreviousPromoCode("");
    }
    // setpreviousPromoCode("")
  };

  //function to handel animation function
  React.useEffect(() => {
    if (promoCode === "") {
      setAnimation(false);
    }
  }, [promoCode]);

  //to get setup config data
  React.useEffect(() => {
    dispatch(ResetComponentState());
    return () => {
      dispatch(ResetPromoComponentState());
    };
  }, []);
  const buttonValues = [1, 2, 5, 10, 32.99];

  //function to update payment method on click
  const handelPaymentClick = (data) => {
    if (parseInt(minimumWalletAmount * data) < 10 && payment !== 0) {
      setUpdatePaymentClick(false);
    }
    setShowPromocodes(true);
    setpreviousPromoCode("");
    setEnteredPayAmount(parseInt(minimumWalletAmount * data));

    const appliedPromo = promocodeData?.Promocodedata?.find(
      (promo) => promo.promo_label === promoCode
    );
    // Check if there's an applied promo and whether the input is less than its minimum required amount
    if (
      appliedPromo &&
      parseInt(minimumWalletAmount * data) <
        parseFloat(appliedPromo.min_charges_to_use)
    ) {
      removePromo(); // Remove promo only if input is below the required minimum charges
      setpreviousPromoCode("");
    }
  };
  //function for handling button action
  const renderButtons = () => {
    return buttonValues.map((value) => {
      const isButtonActive =
        parseInt(amountEntered) === parseInt(minimumWalletAmount * value);
      return (
        <Button
          key={value}
          variant="contained"
          sx={{
            marginRight: ".5rem",
            zIndex: 3,
            fontSize: ".75rem",
            width: "5.3rem",
            marginTop: isButtonActive ? "1rem" : "1rem",
            backgroundColor: isButtonActive
              ? appColors.lightblue
              : appColors.textColorNew,
            color: isButtonActive ? appColors.textColorNew : appColors.white,
            "&:hover": {
              backgroundColor: isButtonActive
                ? appColors.lightblue
                : appColors.textColorNew,
              color: isButtonActive ? appColors.textColorNew : appColors.white,
            },
          }}
          onClick={() => handelPaymentClick(value)}
        >
          ₹ {parseInt(minimumWalletAmount * value)}
        </Button>
      );
    });
  };

  // //useEffect for setting up an payment menthod
  React.useEffect(() => {
    if (
      !BlocketPayments.has(100) &&
      !BlocketPayments.has(101) &&
      amountEntered >= 10 &&
      window.innerWidth <= 600 &&
      !updatePaymentClick
    ) {
      setPayment(paymentTypeWallet.PAYSHARP);
      setUpiUserOption("2");
      setPayType(100);
    } else if (
      !BlocketPayments.has(100) &&
      !BlocketPayments.has(102) &&
      amountEntered >= 10 &&
      !updatePaymentClick
    ) {
      setPayment(paymentTypeWallet.PAYSHARP);
      setUpiUserOption("1");
      setPayType(200);
    } else if (
      !BlocketPayments.has(100) &&
      !BlocketPayments.has(103) &&
      amountEntered >= 10 &&
      !updatePaymentClick
    ) {
      setPayment(paymentTypeWallet.PAYSHARP);
      setUpiUserOption("3");
      setPayType(100);
    } else if (!BlocketPayments.has(200) && !updatePaymentClick) {
      setPayment(paymentTypeWallet.RAZORPAY);
    } else if (!BlocketPayments.has(103) && !updatePaymentClick) {
      setPayment(4);
      setUpiUserOption("");
      setPayType("");
    }
  }, [paymentBlockDetails, amountEntered]);

  //useEffect for promocoupon dialog
  React.useEffect(() => {
    if (promoCode !== "" && showPromocode) {
      setPromoDialogOpen(true);
    }
  }, [promoCode]);

  //function to get wallet transaction details
  React.useEffect(() => {
    dispatch(
      GetWalletTransactionDetails({
        from: moment().subtract(180, "days").format("YYYY-MM-DD"),
        to: moment().add(10, "days").format("YYYY-MM-DD"),
      })
    );
  }, []);

  //function for handel payment function
  const handelPaymentFlag = () => {
    if (payment !== 4) {
      if (upiUserOption === "3" && isUpiIdValid) {
        setpaymentFlag(true);
      } else if (upiUserOption === "3" && !isUpiIdValid) {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      } else {
        setpaymentFlag(true);
      }
    } else {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `No Payment methods currently available.Please try again later`,
        })
      );
    }
  };

  //function for loading icon and clering selected products after orders page
  React.useEffect(() => {
    setTimeout(() => {
      setReferLoading(false);
    }, 2000);
  }, []);

  //fynction to auto apply promo code
  React.useEffect(() => {
    if (amountEntered > 0) {
      console.log(promocodeData?.Promocodedata);
      if (promocodeData?.Promocodedata?.length > 0) {
        const validPromos = promocodeData.Promocodedata.filter(
          (data) =>
            parseFloat(data.min_charges_to_use) <= parseFloat(amountEntered)
        );

        if (validPromos.length > 0) {
          // Find the promo code with the highest min_charges_to_use
          const bestPromo = validPromos.reduce((prev, current) =>
            parseFloat(prev.min_charges_to_use) >
            parseFloat(current.min_charges_to_use)
              ? prev
              : current
          );
          // Apply promo only if it's different from the previous one
          if (promoCode !== bestPromo.promo_label) {
            setpreviousPromoCode(bestPromo.promo_label);
            setpromoCode(bestPromo.promo_label);
            setPromoApplied(true);
            setPromoFlag(true);
            setPromoId(bestPromo.promo_master_id);
            setHandePromoFlag(false);
          }
        }
      }
    }
  }, [promocodeData.Promocodedata, amountEntered]);

  console.log("promoID...........");
  console.log(promoId);
  return (
    <Grid container direction="row" spacing={4} sx={{ position: "relative" }}>
      <div
        ref={CouponAnimation}
        style={{ position: "absolute", top: 0, left: 0 }}
      />
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography
          sx={{
            fontWeight: "700",
            color: appColors.headerTextColor,
            fontSize: appTheme.fontSizeMedium,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          Recharge Your Wallet
          {previousPromoCode !== "" ? (
            <span style={{ color: appColors.textColorNew }}>
              {previousPromoCode.substring(0, 15) + ".."} Applied
            </span>
          ) : (
            ""
          )}
        </Typography>
        {/* <Box
          sx={{
            marginTop: "10px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <input
            type="text"
            style={{ width: "6rem" }}
            className="customTextField"
            placeholder="Enter your amount"
            value={amountEntered}
            onChange={(e) => handleInputChange(e)}
            onKeyDown={handleKeyPress}
          />
          <Box>
            <Typography
              sx={{
                color: appColors.darkGray,
                fontSize: ".9rem",
                fontWeight: "bold",
              }}
            >
              Wallet Ballence&nbsp;₹&nbsp;
              {walletDetails.walletAmount === undefined
                ? 0
                : parseInt(walletDetails.walletAmount)}
            </Typography>
        </Box>
        </Box> */}
        <Card
          className={classes.atmCard}
          elevation={3}
          sx={{ position: "relative", marginTop: ".4rem" }}
        >
          <CardContent sx={{ padding: "1rem 1rem 0 1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Box>
                <Typography sx={{ color: "#d4ebff" }}>Name</Typography>
                <Typography className={classes.cardNumber}>
                  {profile?.user?.fullname}
                </Typography>
              </Box>
              <Box>
                <Typography className={classes.cardHolder}>Balance</Typography>
                <Typography className={classes.expiration}>
                  ₹&nbsp;{parseFloat(walletDetails?.walletAmount)}
                </Typography>
              </Box>
            </Box>
            <Typography sx={{ color: "#d4ebff" }}>
              Wallet Recharge Options
            </Typography>
            {renderButtons()}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ margin: "1rem 0", zIndex: 11 }}>
                <input
                  type="number"
                  className="customTextField"
                  placeholder="Enter your amount"
                  value={amountEntered}
                  onChange={(e) => handleInputChange(e)}
                  onKeyDown={handleKeyPress}
                />
              </Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <img alt="" src={chipIcon} style={{ height: "2.3rem" }} />
              </Box>
            </Box>
          </CardContent>
          <img
            alt=""
            src={Biconcard}
            style={{
              position: "absolute",
              height: "22rem",
              marginLeft: "4rem",
              objectFit: "fil",
              zIndex: 1,
            }}
          />
        </Card>
        {amountEntered >= parseFloat(minimumWalletAmount) ? (
          ""
        ) : (
          <Typography
            sx={{
              fontSize: ".8rem",
              marginTop: ".5rem",
              color: appColors.darkGray,
            }}
          >
            Minimum Recharge Value is ₹{parseFloat(minimumWalletAmount)}
          </Typography>
        )}

        <Box
          sx={{
            width: "100%",
            marginTop: "1rem",
          }}
        >
          <PromocodesNewCard
            openPromoDialog={openPromoDialog}
            setHandePromoFlag={setHandePromoFlag}
            setOpenPromoDialog={setOpenPromoDialog}
            promoFlag={promoFlag}
            setpromoCode={setpromoCode}
            setPromoFlag={setPromoFlag}
            twtyLtrWaterPrice={amountEntered}
            setPromoApplied={setPromoApplied}
            setPromoId={setPromoId}
            searchPromoCodes={searchPromoCodes}
            promoCode={promoCode}
            setpreviousPromoCode={setpreviousPromoCode}
            setAmountToAdd={setEnteredPayAmount}
          />
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <>
          {window.innerWidth >= 600 &&
          amountEntered >= parseFloat(minimumWalletAmount) ? (
            <>
              <Typography sx={[newBookingstyles.cardHeaderStyle]}>
                Payment Method
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  boxShadow: 1,
                  borderRadius: "5px 5px 0 0",
                  border: "1px solid #30a7ff",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: appColors.cardColor,
                    color: appColors.darkGray,
                    fontWeight: "bold",
                    width: "100%",
                    borderRadius: ".5rem 0.5rem 0 0",
                    marginRight: ".3rem",
                    height: "45px",
                    display: "flex",
                    alignItems: "center",
                    cursor: "pointer",
                    justifyContent: "space-between", // Align text and icon at opposite ends
                    padding: "0.5rem",
                    fontSize: "14px",
                  }}
                  onClick={() => setsubcribePaymentFlag(true)}
                >
                  <span>Payment&nbsp;&nbsp;</span>
                  <span>
                    <img
                      alt=""
                      src={
                        upiUserOption === "2" && payment === 1
                          ? UPISvg
                          : upiUserOption === "3" && payment === 1
                          ? UPISvg
                          : upiUserOption === "1" && payment === 1
                          ? scanQr
                          : payment === 0
                          ? razorPay
                          : ""
                      }
                    />
                  </span>
                  <span style={{ fontSize: "12px" }}>
                    {upiUserOption === "2" && payment === 1
                      ? "UPI"
                      : upiUserOption === "3" && payment === 1
                      ? "UPI ID"
                      : upiUserOption === "1" && payment === 1
                      ? "Scan Qr"
                      : payment === 0
                      ? "Razorpay"
                      : ""}
                  </span>
                  <KeyboardArrowUpIcon />
                </Box>
                <Box
                  sx={{
                    padding: ".5rem",
                    backgroundColor: appColors.cardColor,
                    borderRadius: "0 0 .5rem .5rem",
                  }}
                >
                  <Box>
                    <Button
                      onClick={handelPaymentFlag}
                      disabled={amountEntered < parseFloat(minimumWalletAmount)}
                      sx={{
                        backgroundColor: appColors.textColorNew,
                        width: "100%",
                        color: appColors.white,
                        height: "35px",
                        padding: "10px 20px",
                        borderRadius: ".5rem", // Set the border radius to 0 (no rounding)
                        "&: hover": {
                          backgroundColor: appColors.textColorNew,
                        },
                      }}
                    >
                      Pay ₹ &nbsp;{amountEntered}
                    </Button>
                  </Box>
                </Box>
              </Box>
            </>
          ) : (
            ""
          )}
          <MyWallet
            myWalletInBooking={false}
            setpromoCode={setpromoCode}
            openVbDialog={openVbDialog}
            setOpenVbDialog={setOpenVbDialog}
            setpaymentFlag={setpaymentFlag}
            promoCode={promoCode}
            upiId={upiId}
            setrazorPayFlag={setrazorPayFlag}
            setUpiId={setUpiId}
            upiIdVPAObj={upiIdVPAObj}
            setUpiIdVPAObj={setUpiIdVPAObj}
            setVerifyUpiIdClicked={setVerifyUpiIdClicked}
            verifyUpiClicked={verifyUpiClicked}
            payment={payment}
            setPayment={setPayment}
            upiUserOption={upiUserOption}
            setUpiUserOption={setUpiUserOption}
            setUpiPayUserOption={setUpiPayUserOption}
            amountEntered={amountEntered}
            payType={payType}
            paymentTypeWallet={paymentTypeWallet}
            verifyBtn={verifyBtn}
            setVerifyBtn={setVerifyBtn}
            setPayType={setPayType}
            isUpiIdValid={isUpiIdValid}
            setIsUpiIdValid={setIsUpiIdValid}
            paymentFlag={paymentFlag}
            opean={opean}
            setOpean={setOpean}
            setMyWalletInBooking={setMyWalletInBooking}
            setAmountToAdd={setEnteredPayAmount}
            setPayFor={setPayFor}
            setPayOptionCheck={setPayOptionCheck}
            choosenPartial={choosenPartial}
            setChoosenPartial={setChoosenPartial}
          />
          <ChoosePaymentDialogSKU
            open={subcribePaymentFlag}
            pageName="wallet"
            setPaymentType={setPaymentType}
            openVbDialog={openVbDialog}
            setOpenVbDialog={setOpenVbDialog}
            myWalletInBooking={false}
            setpaymentFlag={setpaymentFlag}
            upiId={upiId}
            setrazorPayFlag={setrazorPayFlag}
            setPerWeekPrice={amountEntered}
            setUpiId={setUpiId}
            upiIdVPAObj={upiIdVPAObj}
            setUpiIdVPAObj={setUpiIdVPAObj}
            setVerifyUpiIdClicked={setVerifyUpiIdClicked}
            verifyUpiClicked={verifyUpiClicked}
            payment={payment}
            setPayment={setPayment}
            upiUserOption={upiUserOption}
            setUpiUserOption={setUpiUserOption}
            setUpiPayUserOption={setUpiPayUserOption}
            amountEntered={amountEntered}
            setRazorPayAmount={amountEntered}
            setsubcribePaymentFlag={setsubcribePaymentFlag}
            payType={payType}
            paymentTypeWallet={paymentTypeWallet}
            verifyBtn={verifyBtn}
            setVerifyBtn={setVerifyBtn}
            setPayType={setPayType}
            isUpiIdValid={isUpiIdValid}
            setIsUpiIdValid={setIsUpiIdValid}
            paymentFlag={paymentFlag}
            opean={opean}
            setOpean={setOpean}
            setMyWalletInBooking={setMyWalletInBooking}
            amountToAdd={amountEntered}
            setAmountToAdd={setEnteredPayAmount}
            setPayFor={setPayFor}
            setPayOptionCheck={setPayOptionCheck}
            choosenPartial={choosenPartial}
            setChoosenPartial={setChoosenPartial}
            perWeekPrice={amountEntered}
            setUpiPay={setUpiPay}
            setUpdatePaymentClick={setUpdatePaymentClick}
          />
        </>
        <Box>
          {/* <WalletExplain /> */}
          {transactionDetails.walletTransactions.length >= 1 && (
            <WalletTransactionCard />
          )}
        </Box>
      </Grid>
      {window.innerWidth < 600 ? (
        <WalletFooter
          handelPaymentFlag={handelPaymentFlag}
          upiUserOption={upiUserOption}
          open={subcribePaymentFlag}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          setpromoCode={setpromoCode}
          setsubcribePaymentFlag={setsubcribePaymentFlag}
          amountEntered={amountEntered}
          setpaymentFlag={setpaymentFlag}
          payment={payment}
          minimumWalletAmount={minimumWalletAmount}
          promoCode={promoCode}
        />
      ) : (
        ""
      )}
      {promoDialogOpen && (
        <CouponAppliedDialog
          open={promoDialogOpen}
          onClose={setPromoDialogOpen}
          promoCode={promoCode}
        />
      )}
      <ToastMsgCotainer />

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={referLoading}
      >
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BiconLoader}
            alt="Loading..."
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </Backdrop> */}
    </Grid>
  );
}

export default AddMoney;
