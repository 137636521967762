import appColors from "../../Assets/AppTheme/appColors";

export const legalStyles={
    accrodDetails:{backgroundColor:appColors.cardColor},
    header:{
        color:appColors.headerTextColor,
        fontWeight:"bold"
        
    },
    subHeader:{
        color:appColors.darkGray,
        fontSize:".9rem",
        marginTop:".5rem",
        fontWeight:"bold"
    },
    AccrodialStyle:{
        backgroundColor:appColors.homePageCard,
        color:appColors.darkGray,
    },
    licenseDocuments:{
        fontSize:".8rem",
        marginTop:".5rem",
        color:appColors.darkGray,
    },
    licenseDocuments1:{
        fontSize:".8rem",
        color:appColors.darkGray,
    }
}