// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  selectedDays: [],
  subscribeOrderError: true,
  subscribeOrderDetails: [],
  updateOrderError: true,
};

const skuSubscribeReduser = (state = initialState, action) => {
  // console.log(action.payload);
  switch (action.type) {
    case actionType.SET_SELECTED_DAYS_SUBSCRIBE:
      return {
        ...state,
        selectedDays: action.payload.data,
      };

    case actionType.SKU_SUBSCRIBE_PLACEORDER:
      return {
        ...state,
        error: action.payload.data.error,
        subscribeOrderError: action.payload.data.error,
        msg: action.payload.data.msg,
      };
    case actionType.SKU_SUBSCRIBE_ORDERDETAILS:
      return {
        ...state,
        error: action.payload.data.error,
        subscribeOrderDetails: action.payload.data.data,
        msg: action.payload.data.msg,
      };
    case actionType.SKU_UPDATE_SUBSCRIBEORDER:
      return {
        ...state,
        error: action.payload.data.error,
        updateOrderError: action.payload.data.error,
        msg: action.payload.data.msg,
      };
    default:
      return state;
  }
};

export default skuSubscribeReduser;
