import { toast } from 'react-toastify';

const useToastMessage = ({msgType, msg}) =>{
    toast.dark(msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: msgType,
      });
}

export default useToastMessage