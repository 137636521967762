/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import { getFiles } from "./fileHandlingActions";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import CustomConsole from "../../coustomConsole";
//Get the User Profile data
export const GetAppMeta = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SCM/setupconfig/appmeta`);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_CONFIG_DATA,
          payload: response.data,
        });
        dispatch({
          type: actionType.APP_META_DATA,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetUserProfile Action Error");
      }
    } catch (error) {
      CustomConsole("error in catch Meta Data Action");
      CustomConsole(error);
    }
  };
};

//funct for force app update api
export const ForceAppTourInfo = () => {
  const axios = useAxios();
  return async (dispatch) => {
    try {
      const response = await axios.get("/SSM/admin/app_updates");
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.FORCE_APP_TOUR_INFO,
          payload: response.data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetUserProfile Action Error");
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};

//Get the Master Deposit Details
export const GetDepositDetails = () => {
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.get(`SSM/getDepositDetails`);
    if (response.status === AppMetaData.httpStatus.SUCCESS) {
      dispatch({
        type: actionType.GET_DEPOSIT_DETAILS,
        payload: {
          error: response.error,
          msg: response.msg,
          data: response.data,
        },
      });
    } else {
      // CustomConsole("ELSE: Response in GetUserProfile Action Error");
    }
  };
};

export const ResetComponentState = () => {
  return {
    type: actionType.RESET_COMPONENT_STATE,
  };
};

export const toggleState = () => {
  return {
    type: actionType.RESET_TOGGLE_STATE,
  };
};

export const toggleThemeState = (data) => {
  return {
    type: actionType.RESET_DARK_THEME,
    payload: { data: data },
  };
};


