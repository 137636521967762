/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import React from "react";
import { Box, Typography, Button, Grid, Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Tooltip } from "@mui/material";
import { ReferlinkStyles } from "./referalStyles";

import { GetPromoCodesUser } from "../../Redux/actions/referAndEarnAction.js";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ShareIcon from "@mui/icons-material/Share";
import { makeStyles } from "@mui/styles";

import referImg from "../../Assets/newMobileIcon/ReferandEarnMainPic.svg";
import appColors from "../../Assets/AppTheme/appColors";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ReferInfoCard from "../../Components/Cards/SkuCards/referalInfo.jsx";
import { ToastMsgState } from "../../Redux/actions/toastMsg.js";

const useStyles = makeStyles({
  root: {},
  accord: {
    backgroundColor: "#EAF2FC",
  },
  pTagU: {
    fontSize: "13px",
    fontWeight: "700",
  },
  pTagB: {
    fontSize: "13px",
    fontWeight: "700",
    marginTop: "10px",
  },

  rootStep: {
    "& .Mui-disabled .MuiStepIcon-root": { color: "#489CFB" },
    "& .MuiStepLabel-label": {
      color: appColors.darkGray,
      fontWeight: "600",
    },
  },
  copyReferal: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: appColors.secondaryCard,
    borderRadius: "10px",
    height: "40px",
    padding: "0 .5rem",
  },
  referalTypo: {
    fontSize: "13px",
    fontWeight: "700",
    color: appColors.darkGray,
  },
  referalCode: {
    padding: "10px",

    fontSize: "13px",
    fontWeight: "700",
    color: appColors.darkGray,
  },
  tabUi: {
    "& .MuiTabs-flexContainer": {
      display: "flex",
      justifyContent: "space-evenly",
    },
    "& .PrivateTabIndicator-colorSecondary-12": {
      backgroundColor: "#1FA1D6",
    },
    "& .MuiTab-wrapper": {
      fontWeight: "600",
    },
  },
  tabPlace: {
    display: "flex",
    justifyContent: "center",
  },
  shareBtn: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    opacity: 1,
    backgroundColor: appColors.textColorNew,
    color: "#fff",
    fontWeight: "700",
    fontSize: "12px",
    "&:hover": {
      opacity: 0.8,
    },
  },
  shiningIcon: {
    position: "relative",
    animation: "$shine 2s infinite",
    color: "#2887FD",
  },
  "@keyframes shine": {
    "0%": {
      filter: "drop-shadow(0 0 0 #ffd700)",
    },
    "50%": {
      filter: "drop-shadow(0 0 0.5rem #ffd700)",
    },
    "100%": {
      filter: "drop-shadow(0 0 0 #ffd700)",
    },
  },
});

export default function ReferandEarnLink() {
  const classes = useStyles();
  const [openSnackBar, setOpenSnackBar] = React.useState(false);
  const [promocode, setPromocode] = React.useState("");
  const dispatch = useDispatch();
  const promocodes_ref = useSelector((state) => state.referAndEarnDetails);

  const sharingText = `
    Water is essential for life, and so are friends. Refer a friend to our app and help them stay hydrated while earning rewards for yourself!
    To Signup using our android app follow the link:
    https://play.google.com/store/apps/details?id=com.bookwater.consumerapp&hl=en_IN&gl=US&referrer=${promocode}
    or to use our webapp follow :
    https://webapp.bookwater.com/authenticate?referrer=${promocode}`;

  const [expanded, setExpanded] = React.useState(false);

  //function to handel chage function
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  React.useEffect(() => {
    // console.log("----In Referal and Promo Card----");
    if (promocodes_ref.referId === "") {
      dispatch(GetPromoCodesUser());
    }
  }, []);

  //function to copy referal code
  const copyReferalCode = (code) => {
    // console.log(code);
    navigator.clipboard.writeText(code);
    setOpenSnackBar(true);
    setTimeout(() => {
      setOpenSnackBar(false);
    }, 1000);
  };
  React.useEffect(() => {
    if (
      promocodes_ref?.referId !== undefined &&
      promocodes_ref?.referId &&
      promocodes_ref.referId !== ""
    ) {
      setPromocode(promocodes_ref?.referId || "-");
    }
  }, [promocodes_ref]);

  //function to open indend for sharing refer and earn details
  //function to share order details
  const shareContent = async (data) => {
    if (promocode !== "") {
      try {
        if (navigator.share) {
          await navigator.share({
            title: document.title,
            text: sharingText,
          });
        } else {
          throw new Error("Web Share API is not supported in this browser.");
        }
      } catch (error) {
        console.error("Error sharing content:", error);
        // Fallback behavior in case sharing fails
        // You can provide alternative methods here, such as opening a modal with sharing options
      }
    } else {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: "Referal Code Not found!",
        })
      );
    }
  };

  return (
    <>
      <Grid container alignSelf="center">
        <Grid item xs={12} lg={6}>
          <img
            alt="BookWater"
            src={referImg}
            style={{ height: "100%", width: "100%" }}
          />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid item xs={12}>
            <Typography sx={ReferlinkStyles.referText1}>
              Referral Link
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={ReferlinkStyles.referTextBottom1}>
              Share your unique Referal Code available below to earn
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Box className={classes.copyReferal} sx={{ boxShadow: 1 }}>
                    <Typography className={classes.referalCode}>
                      {promocode}
                    </Typography>
                    <Tooltip title="Click to copy">
                      <ContentCopyIcon
                        sx={{
                          marginRight: "10px",
                          color: appColors.textColorNew,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          copyReferalCode(promocode);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Button
                      onClick={shareContent}
                      className={classes.shareBtn}
                      size="small"
                      variant="contained"
                    >
                      <ShareIcon /> &nbsp; Share
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
              sx={{ backgroundColor: appColors.cardColor, boxShadow: 1 }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography
                  sx={{
                    width: "100%",
                    flexShrink: 0,
                    color: appColors.darkGray,
                  }}
                >
                  How does BookWater Referral work?
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <ReferInfoCard />
              </AccordionDetails>
            </Accordion>
          </Grid>
          <Snackbar
            open={openSnackBar}
            autoHideDuration={2000}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="info" sx={{ width: "100%" }}>
              Copied to the clipboard
            </Alert>
          </Snackbar>
        </Grid>
      </Grid>
    
    </>
  );
}
