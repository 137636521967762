import React from "react";

export default function OrderIcon({ stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 23.335 36.845"
    >
      <g id="Orders" transform="translate(-13 -2)">
        <path
          id="Path_25942"
          data-name="Path 25942"
          d="M33.036,58.913H17.07A3.074,3.074,0,0,1,14,55.842V54.614A.614.614,0,0,1,14.614,54H35.493a.614.614,0,0,1,.614.614v1.228A3.074,3.074,0,0,1,33.036,58.913ZM15.228,55.228v.614a1.842,1.842,0,0,0,1.842,1.842H33.036a1.842,1.842,0,0,0,1.842-1.842v-.614Z"
          transform="translate(-0.386 -20.068)"
          fill={stroke}
        />
        <path
          id="Path_25943"
          data-name="Path 25943"
          d="M35.721,52.527H13.614A.614.614,0,0,1,13,51.913v-4.3A.614.614,0,0,1,13.614,47H35.721a.614.614,0,0,1,.614.614v4.3A.614.614,0,0,1,35.721,52.527ZM14.228,51.3H35.107v-3.07H14.228Z"
          transform="translate(0 -17.367)"
          fill={stroke}
        />
        <path
          id="Path_25944"
          data-name="Path 25944"
          d="M35.721,28.527H13.614A.614.614,0,0,1,13,27.913v-4.3A.614.614,0,0,1,13.614,23H35.721a.614.614,0,0,1,.614.614v4.3A.614.614,0,0,1,35.721,28.527ZM14.228,27.3H35.107v-3.07H14.228Z"
          transform="translate(0 -8.104)"
          fill={stroke}
        />
        <path
          id="Path_25945"
          data-name="Path 25945"
          d="M35.493,41.667H14.614A.614.614,0,0,1,14,41.053V30.614A.614.614,0,0,1,14.614,30H35.493a.614.614,0,0,1,.614.614V41.053A.614.614,0,0,1,35.493,41.667ZM15.228,40.439h19.65V31.228H15.228Z"
          transform="translate(-0.386 -10.806)"
          fill={stroke}
        />
        <path
          id="Path_25946"
          data-name="Path 25946"
          d="M14.614,18.439A.614.614,0,0,1,14,17.825v-1.7a3.086,3.086,0,0,1,2.427-3l4.714-1.01a1.852,1.852,0,0,0,1.457-1.8v-1.7a.614.614,0,1,1,1.228,0v1.7a3.086,3.086,0,0,1-2.427,3l-4.714,1.01a1.852,1.852,0,0,0-1.457,1.8v1.7A.614.614,0,0,1,14.614,18.439Z"
          transform="translate(-0.386 -2.316)"
          fill={stroke}
        />
        <path
          id="Path_25947"
          data-name="Path 25947"
          d="M43.211,18.439a.614.614,0,0,1-.614-.614v-1.7a1.852,1.852,0,0,0-1.457-1.8l-4.714-1.01a3.087,3.087,0,0,1-2.427-3v-1.7a.614.614,0,1,1,1.228,0v1.7a1.852,1.852,0,0,0,1.457,1.8l4.714,1.01a3.087,3.087,0,0,1,2.427,3v1.7a.614.614,0,0,1-.614.614Z"
          transform="translate(-8.104 -2.316)"
          fill={stroke}
        />
        <path
          id="Path_25948"
          data-name="Path 25948"
          d="M32.527,6.913H27.614A.614.614,0,0,1,27,6.3V2.614A.614.614,0,0,1,27.614,2h4.913a.614.614,0,0,1,.614.614V6.3A.614.614,0,0,1,32.527,6.913Zm-4.3-1.228h3.684V3.228H28.228Z"
          transform="translate(-5.403)"
          fill={stroke}
        />
      </g>
    </svg>
  );
}
