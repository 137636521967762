// theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: "Open Sans,  sans-serif,Arial",
    h1: {
      fontFamily: "Open Sans,  sans-serif,Arial",
      fontWeight: 700, // You can adjust the font weight if needed
    },
    h2: {
      fontFamily: "Open Sans,  sans-serif,Arial",
      fontWeight: 600,
    },
    body1: {
      fontFamily: "Open Sans,  sans-serif,Arial",
      fontWeight: 400,
    },
    // Customize other typography variants as needed
  },
});

export default theme;
