/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as Material from "@mui/material";
import React from "react";
import { styles } from "./myAccountsListStyle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { FcSearch } from "react-icons/fc";
import Alert from "@mui/material/Alert";
import { AddNewAccountDialogBox } from "./NewBankAccountDialogBox";
import { EditAccountDialogBox } from "./EditDialogBox";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useDispatch, useSelector } from "react-redux";
import BankAccountCard from "../../../../Components/Cards/addNewBankAccount/BankAccountCard";
import {
  AddNewAccount,
  GetUserBankAccountList,
  EditAccountDetails,
  DeleteUserBankAccount,
} from "../../../../Redux/actions/myAccountsAction";
import { GetUserAdressBook } from "../../../../Redux/actions/settingsAction";
import { toast } from "react-toastify";
import appColors from "../../../../Assets/AppTheme/appColors";
import "../../../../index.css";
function AccountsList() {
  const dispatch = useDispatch();
  const userAccountsList = useSelector((state) => state.myAccounts);
  const address = useSelector((state) => state.settings);
  const [open, setOpen] = React.useState(false);
  const [openDia, setOpenDia] = React.useState(false);
  const [accountId, setAccountId] = React.useState("");
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [account, setAccount] = React.useState({});
  const [isAddressExists, setIsAddressExists] = React.useState(false);
  const [user_id, setUserId] = React.useState("");
  React.useEffect(() => {
    if (!address.isAddressListUpdated) {
      dispatch(GetUserAdressBook()); //Load the Address
      // console.log("I'm checking the Adddresses.....");
    }
  }, []);
  React.useEffect(() => {
    if (address?.addresses !== undefined) {
      if (
        address?.addresses?.length !== 0 &&
        address.addresses[0] !== undefined
      ) {
        // console.log("Adddresses Exists.....");
        setIsAddressExists(true);
        setUserId(address.addresses[0].user_id);
        // console.log(
        //   `bookNowState.convFeePercent: ${bookNowState.convFeePercent}`
        // );
        // console.log(`bookNowState.gateWayFee: ${bookNowState.gateWayFee}`);
      } else {
        address.error = true;
        address.isAddressListUpdated = false;
        setIsAddressExists(false);
      }
    }
  }, [address]);
  React.useEffect(() => {}, [account]);
  React.useEffect(() => {
    if (
      !userAccountsList.isAccountListUpdated ||
      userAccountsList.isAccountDeleted ||
      userAccountsList.isAccountAdded
    ) {
      userAccountsList.isAccountDeleted = false;
      userAccountsList.isAccountAdded = false;
      dispatch(GetUserBankAccountList());
      handleClose();
      handleCloseEditDialog();
    }
  }, [userAccountsList]);
  const handleOpenDeleteDia = (accountId) => {
    // console.log("Account ID: " + accountId);
    setOpenDia(true);
    setAccountId(accountId);
  };
  const handleCloseDia = () => {
    setOpenDia(false);
  };
  const handleOpenEditDia = (account) => {
    setAccount(account);
    setOpenEditDialog(true);
  };
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleClickOpen = () => {
    if (isAddressExists && user_id !== "") {
      setOpen(true);
    } else {
      toast.dark("Please Add an Address to add a Bank Acccount.", {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
        className: "custom-toast",
      });
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteAccount = () => {
    if (accountId !== "") {
      dispatch(DeleteUserBankAccount(accountId));
      setOpenDia(false);
    }
  };

  const handleAddNewBankAccount = (
    details,
    bankName,
    branchName,
    isPrimary
  ) => {
    if (isAddressExists && user_id !== "") {
      const bankDetails = {
        accountName: details.accountName,
        accountNumber: details.accountNumber,
        accountType: details.accountType,
        bankName: bankName,
        branchName: branchName,
        email: details.email,
        userId: user_id,
        ifscCode: details.ifscCode,
        isPrimary: isPrimary,
        micrNumber: details.micrNumber,
        phone: details.phone,
      };
      dispatch(AddNewAccount(bankDetails));
    } else {
      toast.dark("Please Add an Address to add a Bank Acccount.", {
        position: "bottom-right",
        autoClose: 1000,
        type: "error",
      });
    }
  };

  const handleUpdateAccountDetails = (details) => {
    dispatch(EditAccountDetails(details));
  };

  return (
    <Material.Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap:"wrap",
        gap:2
      }}
    >
      <Material.Box sx={styles.addressBox}>
        <Material.Box sx={styles.header}>
          <Material.Button
            variant="contained"
            onClick={handleClickOpen}
            sx={styles.addBtn}
            startIcon={<AddCircleIcon />}
          >
            Add New Bank Account
          </Material.Button>
        </Material.Box>
      </Material.Box>
      {userAccountsList.accounts.map((data, key) => {
        return (
          <BankAccountCard
            key={key}
            bankdetails={data}
            handleOpenDeleteDia={handleOpenDeleteDia}
            handleOpenEditDia={handleOpenEditDia}
          />
        );
      })}
      {open && (
        <AddNewAccountDialogBox
          open={open}
          setOpen={setOpen}
          handleClose={handleClose}
          handleAddNewBankAccount={handleAddNewBankAccount}
        />
      )}
      {openEditDialog && (
        <EditAccountDialogBox
          open={openEditDialog}
          data={account}
          handleClose={handleCloseEditDialog}
          handleUpdateAccountDetails={handleUpdateAccountDetails}
        />
      )}
      {}
      <Material.Dialog
        open={openDia}
        onClose={handleCloseDia}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <Material.DialogTitle
          sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
          id="responsive-dialog-title"
        >
          <Material.Typography
            sx={{
              fontSize: { xs: "20px", sm: "20px", md: "25px" },
              color: appColors.textColorNew,
              fontWeight: "500",
            }}
          >
            {"Are you Sure?"}
          </Material.Typography>
        </Material.DialogTitle>
        <Material.DialogContent
          sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
        >
          <Material.DialogContentText sx={{ color: appColors.darkGray }}>
            Do you really want to delete this Bank Account? This cannot be
            restored.
          </Material.DialogContentText>
        </Material.DialogContent>
        <Material.DialogActions
          sx={{ textAlign: "center", bgcolor: appColors.secondaryCard }}
        >
          <Material.Button
            sx={{
              width: "50%",
            }}
            autoFocus
            color="error"
            variant="contained"
            onClick={handleDeleteAccount}
            size="small"
          >
            Delete
          </Material.Button>
          <Material.Button
            sx={{
              backgroundColor: appColors.textColorNew,
              width: "50%",
              color: "#fff",
            }}
            onClick={handleCloseDia}
            variant="contained"
            autoFocus
            size="small"
          >
            Cancel
          </Material.Button>
        </Material.DialogActions>
      </Material.Dialog>
    </Material.Box>
  );
}

export default AccountsList;
