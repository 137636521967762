// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Side Navigation & Top App Bar)
// Date of Creation: 24/Nov/2023
// File Name: Circles.jsx
// Author: Gowtham Prasath

/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */




const injectStyle = (style) => {
  const styleElement = document.createElement('style')
  let styleSheet = null

  document.head.appendChild(styleElement)

  styleSheet = styleElement.sheet

  styleSheet.insertRule(style, styleSheet.cssRules.length)
}

export default injectStyle
