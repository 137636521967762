/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Typography, Box, Link, Button } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import Biconcard from "../../../../Assets/MobileTheme/Water wallet without Card Background.svg";
import appTheme from "../../../../Assets/AppTheme/appTheme";

export default function NewAddressBook({
  addresses,
  handleOpenDeleteDia,
  handleOpenEditDia,
}) {
  const handleRemove = () => {
    handleOpenDeleteDia(addresses.address_id);
    // console.log(addresses);
  };
  const handleEdit = () => {
    // console.log(addresses);
    handleOpenEditDia(addresses);
  };

  return (
    <Card
      sx={{
        width: { xs: "100%", sm: "20rem" },
        margin: "6px 22px 0 0",
        border: "solid 1px #fff",
        boxShadow: 5,
        borderRadius: ".5rem",
        backgroundColor: appColors.textColorNew,
        position: "relative",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          bgcolor: appColors.secondaryCard,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: ".5rem",
          }}
        >
          {" "}
          <Typography sx={{ display: "flex", justifyContent: "space-between" }}>
            {" "}
            <span
              style={{
                color: appColors.textColorNew,
                fontWeight: "bold",
              }}
            >
              {addresses?.address_type}
            </span>{" "}
            {addresses.is_primary ? (
              <span
                style={{
                  color: appColors.textColorNew,
                  marginLeft: "5rem",
                  fontWeight: "bold",
                }}
              >
                Primary
              </span>
            ) : (
              ""
            )}
          </Typography>
          <Typography
            sx={{
              color: appColors.darkGray,
              fontSize: appTheme.fontSizeMedium,
            }}
          >
            {addresses.contact_name}
          </Typography>
          <Typography
            sx={{
              color: appColors.darkGray,
              fontSize: appTheme.fontSizeSmall,
              width: { xs: "14rem", sm: "80%" } /* Set the width to 80% */,
              whiteSpace: "nowrap" /* Prevent wrapping */,
              overflow: "hidden" /* Hide overflowing text */,
              textOverflow:
                "ellipsis" /* Display ellipsis when text overflows */,
            }}
          >
            {addresses.address_line1}
          </Typography>
          <Typography
            sx={{
              color: appColors.darkGray,
              fontSize: appTheme.fontSizeSmall,
              width: { xs: "14rem", sm: "80%" } /* Set the width to 80% */,
              whiteSpace: "nowrap" /* Prevent wrapping */,
              overflow: "hidden" /* Hide overflowing text */,
              textOverflow:
                "ellipsis" /* Display ellipsis when text overflows */,
            }}
          >
            {addresses.address_line2}
          </Typography>
          <Typography
            sx={{ color: appColors.darkGray, fontSize: appTheme.fontSizeSmall }}
          >
            {addresses.pincode}, {addresses.service_area}
          </Typography>
        </Box>
        <Box
          sx={{ display: "flex", justifyContent: "space-around", gap: "1rem" }}
        >
          <Button
            variant="contained"
            onClick={() => handleEdit()}
            sx={{
              bgcolor: appColors.textColorNew,
              color: "#fff",
              borderRadius: ".5rem",
              zIndex: 2,
            }}
          >
            Edit
          </Button>

          <Button
            variant="contained"
            onClick={() => handleRemove()}
            sx={{
              bgcolor: appColors.Failed,
              color: "#fff",
              borderRadius: ".5rem",
              zIndex: 2,
            }}
          >
            Delete
          </Button>
        </Box>
      </CardContent>
      <img
        alt=""
        src={Biconcard}
        style={{
          position: "absolute",
          height: "20rem",
          objectFit: "contain",
          zIndex: 1,
          top: 0,
        }}
      />
    </Card>
  );
}
