/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
 
const initialProfileState = {
  error: true, 
  metaData: [],
  msg:""
};


const getSetUpConfigActionReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_CONFIG_DATA:
        // console.log("----action here---")
        // console.log(action)
      return {
        ...state,
        error: action.payload.error, 
        metaData: action.payload.app_data, 
        msg:action.payload.msg 
       };
      
    default:
      return state;
  }
};

export default getSetUpConfigActionReducer;
