/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author 
 * @description 
 * @date 06/06/23
 * @version 0.0.6 - Initial Release
 */
import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { faqStyles } from "./faqStyle";
import appColors from "../../../Assets/AppTheme/appColors";
import { useTheme } from '@material-ui/core/styles';

//variable for storing list of reason
const Questions = [{
  title: "Order Booking"
},
{ title: "Payment" },
{ title: "QR Code" },
{ title: "Quality" },
{ title: "Cancellation" },
{ title: "Delivery" }
]


function FreqQues() {
  const theme = useTheme();
  const [value, setValue] = React.useState(Questions[0]);

  return (
    <Box >
      <Typography
        sx={{
          fontWeight: "700",
          color: appColors.darkBlue,
          fontSize: "1rem",
        }}
      >
        Frequently Asked Questions
      </Typography>
      <Autocomplete
        size="small"
        options={Questions}
        value={value}
        onChange={(event, newValue) => {
          if (newValue !== null && newValue !== undefined) {
            setValue(newValue);
          }
          else {
            setValue(Questions[0])
          }
        }}
        getOptionLabel={(option) => option.title}
        renderInput={(params) => (
          <TextField
            {...params}
            id="clear-on-escape"
            variant="standard"
            placeholder="Questions"
            InputProps={{
              sx:{color:appColors.darkGray}
            }}
          />
        )}
      />

      <Box sx={faqStyles.heading}>
        {value.title === Questions[0].title ? <Box >
          <Typography sx={faqStyles.BookACanHeading}> How to Book Water?</Typography>
          <Typography sx={faqStyles.para}>
            To ensure security and ease of use, all bookings are done online only. Kindly follow the following steps to book water.
            If you are an Android user download the BookWater app from Playstore. Register yourself on the app. iPhone users can use our web app <a href="https://www.bookwater.com" target="_blank">www.bookwater.com</a> and register yourself.
          </Typography>
          <Typography sx={faqStyles.para}>Register yourself using your name, mobile number, email id, and the address you want the water delivered to. You may add multiple addresses to a single account.
            Each phone number may be registered as a single user. There can be multiple registrations through different phone numbers for a single address.  </Typography>
          <Typography sx={faqStyles.para}>Use the Mobile app or Web app to book water. Choose the number of water cans needed and the address of delivery. Choose when you would like the water to be delivered. There are three delivery options based on when the water delivery is needed: Regular, Same – Day and Subscription.
            For urgent requirements, customers can opt for Same-day delivery. This service will attract a nominal delivery fee of Rs. 15 per can. There a minimum order Value for 2 cans for regular delivery and 1 can for Insta-Delivery.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Regular Order?</Typography>
          <Typography sx={faqStyles.para}>
            Orders are placed a day in advance for delivery the next day. E.g. if you wish the water to be delivered on 5th June place the order on 4th June.
            NO Delivery Fee applicable for Regular Deliveries: Not applicable.
          </Typography>
          <Typography sx={faqStyles.para}>The minimum order for Regular Delivery is : 2 cans</Typography>
          <Typography sx={faqStyles.BookACanHeading}> Same-Day Delivery?</Typography>
          <Typography sx={faqStyles.para}>
            Customers who want the water delivered urgently may opt for same-day delivery. Orders can be placed for same-day delivery will be delivered within a few hours on the day of booking. E.g. An order placed on 4th June will be on 4th June
            Same-day delivery incurs a Delivery Fee of Rs. 15 per can
            The minimum order for same-day delivery is: 1 can</Typography>
          <Typography sx={faqStyles.BookACanHeading}>Subscribtion?</Typography>
          <Typography sx={faqStyles.para}>
            Leave your water delivery schedule to us! Orders can be placed for a specific schedule in a week/month (e.g., 3 cans every Monday and Thursday).
            No Delivery Fee is applicable for Subscription orders The minimum order for Subscription is – 2 cans
            To place a Subscription order, go to the Subscribe option on the main menu, choose the delivery address, the days of the week when the water is needed, and the start date for your Subscription. Then pay the amount for the subscription. Please note that payments for the Subscription are deducted from the water wallet so keep your water wallet loaded at all times with the adequate amount Our delivery hours are between 6.00 am and 9.00 pm.</Typography>
          <Typography sx={faqStyles.BookACanHeading}>Do you support bulk orders?</Typography>
          <Typography sx={faqStyles.para}>
            Customers may order in bulk for their organizations. Kindly write to <a href="https://www.bookwater.com/contact/" target="_blank">https://www.bookwater.com/contact/</a> regarding bulk orders pricing and delivery
          </Typography>
        </Box > : ""}

        {value.title === Questions[1].title ? <Box >
          <Typography sx={faqStyles.BookACanHeading}> How can I pay for BookWater? </Typography>
          <Typography sx={faqStyles.para}>
            -We accept the following payment methods to book water: -
            BookWater’s Water Wallet The Water Wallet is a safe and easy way for payments. For ease of payment, BookWater provides its customers the convenience of a Water Wallet. Customers may load their Water Wallet with the desired amount and use the same to book water. Any reimbursements for cancelled orders will be credited back to the customer’s water wallet. This amount may be used for future orders. Customers can request the amount to be reimbursed to their bank accounts if desired. Load your water wallet with an adequate amount and enjoy hassle-free water booking.
            We support the following payment methods
            -	UPI Transfer
            -	Razor Pay through Credit or Debit Card
            -	Net Banking
            -	Other Payment wallets

          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>UPI QR Code/UPI not Working?</Typography>
          <Typography sx={faqStyles.para}>
            Kindly check your internet connection and UPI id and re-try.
            Please connect to us at customer care for additional information and assistance
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Is the option for Cash on Delivery available?</Typography>
          <Typography sx={faqStyles.para}>
            No Cash on Delivery is not available. All bookings have to be paid for in advance through an online payment method
            Please connect to us at customer care for additional information and assistance
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Not able to add money in BookWater wallet?</Typography>
          <Typography sx={faqStyles.para}>
            If you are not able to add money into your Water Wallet with the current payment method, please try an alternate payment method.
            Please connect to us at customer care for additional information and assistance

            If the issue persists, contact Customer Care through the chat option in the BookWater app or www.bookwater.com/support You may also call us at 08069592837 to speak to our customer care executive.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Is it safe to use Debit or Credit card?</Typography>
          <Typography sx={faqStyles.para}>
            All card transactions are secured through RazorPay and are completely safe.
          </Typography>
        </Box > : ""}

        {value.title === Questions[2].title ? <Box >
          <Typography sx={faqStyles.BookACanHeading}>What is the QR code on the water bottle?  </Typography>
          <Typography sx={faqStyles.para}>
            Every water can is equipped with 2 QR codes. One directs you to the BookWater website while the other QR code towards the lower end of the can is a wealth of information regarding the quality of the can and the water it contains.
          </Typography>
          <Typography sx={faqStyles.para}>
            At BookWater we believe in quality and transparency of information. Scanning the QR code gives information on when the can was packed, the expiry date for the can, the number of times the can has been reused, and the complete Water Test Report for the water in that particular can.
          </Typography>
          <Typography sx={faqStyles.para}>
            The QR code gives the following information:- TDS levels as per government guidelines pH of the water Can usage count Now you know your can and know your water too!
          </Typography>  <Typography sx={faqStyles.para}>
            Can Usage Microplastics can cause irreparable damage to the human body. Overused plastic cans leach microplastics into the water and lead to serious ailments. A can at BookWater is reused only 30 times after which it is upcycled into fibre and made into T-Shirts. Organizations who share our passion for the environment may order T-Shirts made from upcycled cans.
          </Typography>
        </Box > : ""}




        {value.title === Questions[3].title ? <Box >
          <Typography sx={faqStyles.para}> At BookWater quality and transparency is of highest importance.
            The QR code on every bottle gives an indepth information on the quality of water it contains.    </Typography>
          <Typography sx={faqStyles.BookACanHeading}>How many times is a can reused? / What is our Environment policy? </Typography>
          <Typography sx={faqStyles.para}>
            A can at BookWater is reused only 30 times after which it is upcycled into fibre and made into T-Shirts. Organizations who share our passion for the environment may order T-Shirts made from upcycled cans.
          </Typography>

        </Box > : ""}


        {value.title === Questions[4].title ? <Box >
          <Typography sx={faqStyles.BookACanHeading}> How can I cancel my order?  </Typography>
          <Typography sx={faqStyles.para}>
            An order can be cancelled the previous day for a regular order and 3 hours in advance for an insta-delivery order. Cancelling may be done through the BookWater app. The refunds for cancelled orders will be credited back to the customer’s water wallet. This amount may be used for future orders.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>How can I cancel my subscription with BookWater?</Typography>
          <Typography sx={faqStyles.para}>
            To cancel the subscription go the subscriptions page on the app and Pause or Delete the Subscription. The refunds for cancelled orders will be credited back to the customer’s water wallet. This amount may be used for future orders.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>How can I Deactivate my account with BookWater? </Typography>
          <Typography sx={faqStyles.para}>
            Following are the steps to deactivate a BookWater account. The customer may request for deactivation of account thorough email (support@bookwater.com) or through chat in the BookWater app.
          </Typography>
          <Typography sx={faqStyles.para}>
            Through the app, the customer can request for return of cans in their possession. The empty cans will be picked up and inspected at our facility. Post inspection, the deposit for the cans will be credited to the customer’s water wallet. The refund for the amount in the water wallet will be then initiated. As per bank payment rules, the refund will be credited to the customer’s bank account within 7 working days. All refunds will be made through bank transfer only.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>What is the refund policy? / How can I get a refund? </Typography>
          <Typography sx={faqStyles.para}>
            customers may Any refunds for cancelled orders / wrong payments etc. will be credited back to the customer’s water wallet. This amount may be used for future orders. Customers can request the amount to be reimbursed to their bank accounts if desired.
          </Typography>

        </Box > : ""}



        {value.title === Questions[5].title ? <Box >
          <Typography sx={faqStyles.BookACanHeading}> Where does BookWater deliver water?  </Typography>
          <Typography sx={faqStyles.para}>
            As of now, BookWater delivers in the city of Chennai and its suburbs. In the next few months, we will begin operations in Bangalore and Hyderabad. Over the next 12 months, BookWater will be operational in all major cities in India. To find out if your area is serviceable, kindly check the PIN Code of your area in the BookWater app.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>How many water bottles can I order? </Typography>
          <Typography sx={faqStyles.para}>
            For a Standard delivery, the minimum order is 2 bottles. For an Insta-Delivery customers may order even a single bottle.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Can I add more than 1 Delivery address to an account? </Typography>
          <Typography sx={faqStyles.para}>
            Yes, an account may have multiple delivery addresses. Customers should take care to choose the right address while booking an order.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>Do you Charge for Delivery? </Typography>
          <Typography sx={faqStyles.para}>
            <Typography sx={faqStyles.para}> Regular deliveries are free. </Typography>
            Is it possible for same day delivery: BookWater has introduced Insta-Delivery for same day water delivery within 3 hours. The delivery charges for Insta-Delivery are Rs. 15 per water can. E.g. if a customer wants 3 water cans to be delivered urgently he will be paying Rs. 45 as delivery charges with the regular water charges.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>How long do you take to deliver? </Typography>
          <Typography sx={faqStyles.para}>
            One day for Regular booking and 3 hours for Insta-Booking. For advance (previous-day) bookings Customers may choose their delivery slots.
            Insta- Delivery orders will be delivered within 3 hours of booking.
          </Typography>
          <Typography sx={faqStyles.para}>
            <Typography sx={faqStyles.para}> Regular deliveries are free. </Typography>
            Is it possible for same day delivery: BookWater has introduced Insta-Delivery for same day water delivery within 3 hours. The delivery charges for Insta-Delivery are Rs. 15 per water can. E.g. if a customer wants 3 water cans to be delivered urgently he will be paying Rs. 45 as delivery charges with the regular water charges.
          </Typography>
          <Typography sx={faqStyles.BookACanHeading}>What are your delivery hours?
          </Typography>
          <Typography sx={faqStyles.para}>
            Our delivery hours are between 6.00 am and 9.00 pm.
            Can I have multiple Registrations in the same address?
            Each phone number may be registered as a single user. There can be multiple registrations through different phone numbers for a single address.

          </Typography>
        </Box > : ""}
      </Box>
    </Box>
  );
}

export default FreqQues;
