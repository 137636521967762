/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import danger from "../../../Assets/newMobileIcon/Danger.svg";
import { Grid, Typography } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import ImageLoader from "../../Utils/imageLoader";
import { useSelector } from "react-redux";
import appTheme from "../../../Assets/AppTheme/appTheme";
export default function DatesAvailableInfo({ toggleDrawer, open }) {
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  //function for closing dialog
  const handelClose = (event) => {
    event.preventDefault();
    toggleDrawer(false);
  };

  const crateStyles = {};
  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={(e) => handelClose(e)}
          className="drawerWithRadius"
        >
          <Grid
            container
            sx={{
              padding: "1rem 1rem 1rem 1rem",
              bgcolor: appColors.homePageCard,
            }}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: ".2rem",
                }}
              >
                {" "}
                <img
                  src={danger}
                  alt=""
                  style={{ height: "2rem", width: "2rem" }}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: appTheme.fontSizeLarge,
                  color: appColors.headerTextColor,
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {
                  SkuProductDetails?.productDetails?.admin_settings?.valid_dates
                    ?.msg
                }
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                sx={{
                  fontSize: appTheme.fontSizeMedium,
                  color: appColors.darkGray,
                  textAlign: "center",
                }}
              >
                Booking are currently unavailable. Kindly try again later.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Button
                size="small"
                variant="outlined"
                sx={{
                  background: appColors.homePageCard,

                  color: appColors.darkGray,
                  width: "100%",
                  marginTop: ".5rem",
                  borderRadius: "10px",
                }}
                onClick={handelClose}
              >
                Try later
              </Button>
            </Grid>
          </Grid>

          {/* <img
            src={DownIcon}
            onClick={(e) => handelClose(e)}
            style={{
              position: "absolute",
              top: "-.2rem",
              right: "45%",
              height: "2.3rem",
              width: "3rem",
              color: appColors.darkGray,
            }}
            alt=""
          /> */}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
