/**
 * @author Bookwater Tech
 * @description Action for New Payment changes
 * @copyright Bookwater tech pvt ltd
 * @author Gowtham Prasath
 * @date 21-02-2024
 */

import actionType from "./../actions/actionTypes";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import { toast } from "react-toastify";
import * as toastMessage from "./../../Assets/Constants/ToastMessages";
import { url } from "../apis";

// Action to get payment blocking details
export const GetPaymentBlockDetails = () => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(
        `/SCM/admin_settings/blocked/payment_methods`
      );
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        dispatch({
          type: actionType.GET_PAYMENTBLOCKING_DETAILS,
          payload: response.data,
        });
      } else {
        // console.log("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {}
  };
};
