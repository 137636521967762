/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../../Assets/AppTheme/appColors";

export const styles = {
  dialogContent: {
    color: appColors.blue,
    backgroundColor: appColors.secondaryCard,
    fontSize: "1rem",
  },
  dialogContent1: {
    color: appColors.blue,
    backgroundColor: appColors.secondaryCard,
    fontSize: "1rem",
    display: "flex",
    justifyContent: "center",
  },

  accordionDetails: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "10px",
  },
  btn: {
    backgroundColor: "#070b30",
    width: { xs: "70%", sm: "70%", md: "40%" },
    marginTop: "18px",
    "&:hover": {
      backgroundColor: "#070b30",
    },
  },
  proImg: {
    width: "100%",
    height: "100%",
    borderRadius: "50%",
  },
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  insideBox: {
    color: "#ff0d0d",
    fontWeight: "bolder",
    fontSize: "14px",
  },
  removeBtn: {
    color: "#fff",
    textTransform: "none",
    fontWeight: "bolder",
    backgroundColor: "#2522e0",
    "&:hover": {
      backgroundColor: "#000",
    },
  },
  helptext: {
    color: "#f21f1f",
    fontSize: "12px",
    fontWeight: "bolder",
  },
  editIcon: {
    backgroundColor: "#0ec767",
    padding: "10px",
    position: "absolute",
    bottom: "5px",
    right: "5px",
    "&:hover": {
      backgroundColor: "#08a353",
    },
  },
  cancelIcon: {
    backgroundColor: "#f52c1d",
    padding: "10px",
    position: "absolute",
    bottom: "5px",
    right: "5px",
    "&:hover": {
      backgroundColor: "#b81e12",
    },
  },
  imgName: {
    color: appColors.darkGray,
    fontSize: "14px",
  },
  input2: {
    width: { xs: "80%", sm: "80%", md: "50%" },
    marginTop: "15px",
  },
  fileIcon: {
    backgroundColor: "#333aff",
    padding: "10px",
    fontSize: "12px",
    "&:hover": {
      backgroundColor: "#202487",
    },
  },
  fileDownloadIcon: {
    backgroundColor: appColors.textColorNew,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#202487",
    },
  },
  fileRemoveIcon: {
    backgroundColor: appColors.lightGreen,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#202487",
    },
  },
  fileUploadIcon: {
    backgroundColor: appColors.lightGreen,
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: "#202487",
    },
  },
  fileUploadConfirmIcon: {
    backgroundColor: appColors.lightGreen,
    color: "#fff",
    marginLeft: "10px",
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  fileUploadCancelIcon: {
    backgroundColor: appColors.Failed,
    marginLeft: "10px",
    color: "#fff",
    "&:hover": {
      backgroundColor: appColors.Failed,
    },
  },
  downloadUploadBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "10px",
  },
  buttonStyle: {
    width: "80px",
    backgroundColor: appColors.textColorNew,
    borderRadius: "0",
    borderTopRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    "&:hover": { backgroundColor: appColors.blue },
    display: "flex",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
  },
};
