/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  message: "",
  paymentBlockData: [],
};

const PaymentReducerSku = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_PAYMENTBLOCKING_DETAILS:
      // console.log(action.payload);
      return {
        ...state,
        error: action.payload.error,
        message: action.payload.msg,
        paymentBlockData: action.payload.payment_methods.map(
          (method) => method.type
        ),
      };
    default:
      return state;
  }
};

export default PaymentReducerSku;
