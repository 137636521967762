/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */ import React from "react";
import {
  Box,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { ReferlinkStyles } from "./referalStyles";
import { InfluencerAutoPayAction } from "../../Redux/actions/referAndEarnAction";
import { influencerType } from "../../Assets/Constants/AppMetaData";
import appColors from "../../Assets/AppTheme/appColors";
import InfluencerFormDialog from "./InfluencerFormDialog";
import RedeemAmountToAccount from "./RedeemAmountToAccount";
import waterDrop from "../../Assets/newMobileIcon/waterDrops 1.svg";

export default function WaterDropsDetails() {
  const dispatch = useDispatch();
  const promocodes_ref = useSelector((state) => state.referAndEarnDetails);
  const profileDetails = useSelector((state) => state.userProfile);
  const [formDialogOpen, setFormDialogOpen] = React.useState(false);
  const [openRedeem, setOpenRedeem] = React.useState(false);
  const [isSwitchAutoPayEnabled, setIsSwitchAutoPayEnabled] = React.useState(
    promocodes_ref.allData.auto_pay
  );
  const redeemedAmount = promocodes_ref.allData.redeemed_amount || 0;
  const formattedAmount = redeemedAmount
    ? parseInt(redeemedAmount).toFixed(0)
    : "0";

  const handleChangeInfluencerAutopay = (event) => {
    // console.log("----handleChangeInfluencerAutopay-------");
    let reqObj = {
      auto_pay: true,
    };

    if (event.target.checked) {
      setIsSwitchAutoPayEnabled(true);
      reqObj.auto_pay = true;
    } else if (event.target.checked === false) {
      setIsSwitchAutoPayEnabled(false);
      reqObj.auto_pay = false;
    }
    dispatch(InfluencerAutoPayAction(reqObj));
  };

  const openRedeemDetails = () => {
    setOpenRedeem(true);
    // dispatch(BankDetailsConsumer());
  };

  return (
    <>
      <Grid container sx={{ marginTop: "1rem" }}>
        <Grid item xs={12} alignItems="center" justifyContent="center">
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ...ReferlinkStyles.referText,
            }}
          >
            <span>
              <img
                src={waterDrop}
                style={{ height: "3rem", width: "1.5rem" }}
                alt="Bookwater"
              />
            </span>
            &nbsp; &nbsp;My Water Drop Balance
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={ReferlinkStyles.referTextBottom}>
            A quick view of your Water Drops Account
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Box sx={ReferlinkStyles.TextBoxWaterdrops}>
              <Typography sx={ReferlinkStyles.waterdropText}>
                Water Drops Available
              </Typography>
              <Typography sx={ReferlinkStyles.waterdropNumber}>
                {promocodes_ref?.allData.water_drops
                  ? promocodes_ref.allData.water_drops
                  : 0}
                <img
                  src={waterDrop}
                  style={{ height: "2rem", width: "1rem" }}
                  alt="Bookwater"
                />
              </Typography>
            </Box>
          </Box>
          {promocodes_ref.allData.redeemed_amount > 0 && (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box sx={ReferlinkStyles.TextBoxWaterdrops}>
                <Typography sx={ReferlinkStyles.waterdropText}>
                  Water Drops Redeemed
                </Typography>
                <Typography sx={ReferlinkStyles.waterdropNumber}>
                  {promocodes_ref.allData.redeemed_amount ? formattedAmount : 0}
                  <img
                    src={waterDrop}
                    style={{ height: "2rem", width: "1rem" }}
                    alt="Bookwater"
                  />
                </Typography>
              </Box>
            </Box>
          )}
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box sx={ReferlinkStyles.TextBoxWaterdrops}>
              <Typography sx={ReferlinkStyles.waterdropText}>
                {" "}
                Use Water Drops by Default
              </Typography>
              <FormControlLabel
                control={<Switch checked={isSwitchAutoPayEnabled} />}
                label={isSwitchAutoPayEnabled ? "Enable" : "Disable"}
                sx={{
                  color: appColors.darkGray, // Set your desired label color
                }}
                onChange={handleChangeInfluencerAutopay}
              />
            </Box>
          </Box>
          {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                        {
                            influencerType.USER_TYPE_INFLUENCER === parseInt(profileDetails.consumer.user_type) ||
                                influencerType.USER_TYPE_INFLUENCER_DISABLED === parseInt(profileDetails.consumer.user_type) ? (
                                <Button
                                    variant="contained"
                                    sx={{
                                        marginBottom: "10px",
                                        borderRadius: "20px",
                                        backgroundColor: appColors.textColorNew,
                                        color: appColors.white
                                    }}
                                    onClick={openRedeemDetails}
                                >
                                    Redeem Water Drop
                                </Button>
                            ) : null
                        }
                    </Box> */}
        </Grid>
        <InfluencerFormDialog
          formDialogOpen={formDialogOpen}
          setFormDialogOpen={setFormDialogOpen}
        />
        <RedeemAmountToAccount
          setOpenRedeem={setOpenRedeem}
          openRedeem={openRedeem}
        />
      </Grid>
    </>
  );
}
