/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  //   orders: [],
  // isQrDataLoaded: false,
  depositDetails: [],
  loading: false,
  msg: "",
  extraDeposiData: {},
  extraDepositError: true,
  //   transactions: [],
};

const getDepositDataReducer = (state = initialProfileState, action) => {
  // console.log(action.payload)
  switch (action.type) {
    case actionType.GET_DEPOSIT_DATA:
      return {
        ...state,
        error: action.payload.error,
        depositDetails: action.payload.data,
        msg: action.payload.msg,
      };
    case actionType.DEPOSIT_REFUND_ACTION:
      return {
        ...state,
        error: action.payload.error,
        refundDeposit: action.payload.data,
        msg: action.payload.msg,
      };
    case actionType.EXTRA_DEPOSIT_ACTION:
      return {
        ...state,
        error: action.payload.error,
        extraDepositError: action.payload.data.error,
        extraDeposiData: action.payload.data.data,
        msg: action.payload.msg,
        loading: false,
      };
    case actionType.CLEAR_DEPOSIT_DETAILS_ACTION:
      return {
        ...state,
        extraDepositError: true,
        extraDeposiData: {},
        msg: "",
      };
    case actionType.FETCH_EXTRA_DEPOSIT_DATA_START:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
};

export default getDepositDataReducer;
