// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  isLoading: true,
  filePath: "",
  fileName: "",
  isFileUploaded: false,
  isPoFileUploaded: false,
  msg: "",
  bannerImgData: "",
  bannerImg: [],
  qrimage: "",
};

const getFilesReducer = (state = initialProfileState, action) => {
  // console.log("------- (getFilesReducer) File Details--------");
  // console.log(JSON.stringify(action));
  switch (action.type) {
    case actionType.GET_FILES:
      return {
        ...state,
        error: false,
        filePath: action.payload,
        isLoading: false,
      };
    case actionType.UPLOAD_FILE:
      return {
        ...state,
        error: action.payload.error,
        fileName: action.payload.data.filename,
        isFileUploaded: action.payload.statusFlag,
      };
    case actionType.UPLOAD_PO_FILE:
      return {
        ...state,
        error: action.payload.data.error,
        fileName: action.payload.data.msg,
        isPoFileUploaded: action.payload.statusFlag,
      };
    case actionType.SIGN_OUT:
      return {
        ...initialProfileState,
      };
    case actionType.BANNERS_IMG:
      return {
        ...initialProfileState,
        bannerImgData: action.payload,
      };
    case actionType.GET_FILES_BANNER:
      return {
        ...initialProfileState,
        bannerImg: action.payload,
      };
    case actionType.GET_QUALITY_REPORT:
      return {
        ...initialProfileState,
        qrimage: action.payload,
      };

    default:
      return state;
  }
};

export default getFilesReducer;
