/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useState } from "react";

import Html5QrcodePlugin1 from "../../Components/QrScanner/HtmlQrcodePlugin1.jsx";
import { Box } from "@mui/material";

export function OpenCamera(props) {
  const onNewScanResultQrCode = (decodedText, decodedResult) => {
  
    if (
      decodedResult.result.format.formatName === "QR_CODE" ||
      decodedResult.result.format.formatName === "CODE_128"
    ) {
      // setScannedQrCodeDetails(decodedText);
      // dispatch(GetDetailsFromQrcode(decodedText));
      props.handleCameraResp(decodedText);
      // dispatch(qrScannerAction.GetQrData(decodedText));
    }
    if (decodedResult.result.format.formatName === "CODE_128") {
      // setScannedQrCodeDetails(decodedText);
    }
  };

  return (
    <Box>
      <Html5QrcodePlugin1
        fps={10}
        qrbox={250}
        // scanQrFlag={scanQrFlag}
        disableFlip={false}
        qrCodeSuccessCallback={onNewScanResultQrCode}
        setOpenHtmlQr={props.setOpenHtmlQr}
        openHtmlQr={props.openHtmlQr}
      />
    </Box>
  );
}
