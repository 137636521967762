/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  isQrDataLoaded: false,
  qrData: {},
  ownerDetails: {},
  waterQuality: [],
  msg: "",
};

const qrScannerReducer = (state = initialProfileState, action) => {
  // console.log(action)
  switch (action.type) {
    case actionType.GET_QR_DATA:
      // console.log("qrScannerReducer")
      // console.log(action.payload)
      return {
        ...initialProfileState,
        error: action.payload.error,
        msg: action.payload.msg,
        qrData: action.payload.data[0],
        ownerDetails: action.payload.ownerData[0],
      };
    default:
      return state;
  }
};

export default qrScannerReducer;
