/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import appColors from "../../../../Assets/AppTheme/appColors";
export const dialogStyles = {
  dialog: {
    backgroundColor: "#152057cc",
    vw: "30rem",
    vh: "30rem",
  },
  dialogContent: {
    padding: "0rem",
    overflowY: "auto", // Enable vertical scrollbar
    overflowX: "hidden", // Hide horizontal scrollbar
    "&::-webkit-scrollbar": {
      width: "0.2em",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#fff",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#30a7ff" /* Set the background color of the thumb */,
    },
    background: appColors.cardColor,
  },
  closeIcon: {
    position: "absolute",
    top: -1,
    right: 0,
    backgroundColor: "white",
    borderRadius: "1rem",
    cursor: "pointer",
  },

  ///OTP Styles are Written below

  otpDialog: {
    backgroundColor: appColors.lightGreen,
    borderRadius: "1rem",
    cursor: "pointer",
  },

  OrderId: {
    color: "#30a7ff",
    fontWeight: "bold",
  },
  orderIdStyle: { display: "flex", flexDirection: "column" },
};

export const orderDetailsStyles = {
  customerDetailsCard: {
    display: "flex",
    marginBottom: "1rem",
    flexDirection: "row",
    borderRadius: "1rem",
    backgroundColor: appColors.cardColor,
    border: "1px solid #fff",
  },
  customerDetailsTypo: {
    color: appColors.darkGray,
    fontWeight: "bold",
    fontSize: ".8rem",
  },
  customerResultTypo: { color: appColors.darkGray, fontSize: ".8rem" },
  orderDetailsCard: {
    display: "flex",
    marginBottom: "1rem",
    flexDirection: "column",
    borderRadius: "1rem",
    backgroundColor: appColors.cardColor,
    border: "1px solid #fff",
  },
  paymentDetailsStyles: {
    display: "flex",
    marginBottom: "1rem",
    flexDirection: "column",
    borderRadius: "1rem",
    backgroundColor: appColors.cardColor,
    border: "1px solid #fff",
  },
};
