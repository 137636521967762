/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";
import { getFiles } from "./fileHandlingActions";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";

export const ToastMsgState = (data) => {
  return {
    type: actionType.TOAST_MSG_CONTAINER,
    payload: { data: data },
  };
};
