/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as Material from "@mui/material";
import * as FcIcons from "react-icons/fc"; 
import * as ImIcons from "react-icons/im";
import * as FaIcons from "react-icons/fa";
import {
  orderType,
  orderStatus,
  orderTerm,
  tankerType,
  paymentStatus,
  paymentType,
  postpaidStatus,
  paysharpStatus,
  deliveryStatus,
  deliveryTimingOptions,
} from "./../../Assets/Constants/AppMetaData";
import { styles } from "./metaFunctionStyles";
import waterTanker from "./../../Assets/Images/waterTanker.png";
import sewageTanker from "./../../Assets/Images/sewageTanker.png";
import waterCanimg from "./../../Assets/Images/WaterCanImg.png";
import razorPayIcon from "./../../Assets/Images/razorPayIcon.png";
import paySharpIcon from "./../../Assets/Images/paysharpIcon.png";
import postPaidIcon from "./../../Assets/Images/postpaidIcon.png";

export const orderTermFun = (order_terms) => {
  // console.log("Order Term: " + order_terms);
  switch (order_terms) {
    case orderTerm.SINGLE:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Single Booking
        </Material.Typography>
      );
    case orderTerm.FORTNIGHTLY:
      return (
        <Material.Typography sx={styles.answers} component="span">
          FORTNIGHTLY Booking
        </Material.Typography>
      );
    case orderTerm.MONTHLY:
      return (
        <Material.Typography sx={styles.answers} component="span">
          MONTHLY Booking
        </Material.Typography>
      );
    default:
      return "-";
  }
};
export const orderTypeFun = (order_type, order_terms) => {
  switch (order_type) {
    case orderType.LONG_TERM_BOOKING:
      switch (order_terms) {
        case orderTerm.SINGLE:
          return (
            <Material.Typography sx={styles.answers} component="span">
              LONGTERM/Single Booking
            </Material.Typography>
          );
        case orderTerm.WEEKLY:
          return (
            <Material.Typography sx={styles.answers} component="span">
              Weekly Booking
            </Material.Typography>
          );
        case orderTerm.FORTNIGHTLY:
          return (
            <Material.Typography sx={styles.answers} component="span">
              FORTNIGHTLY Booking
            </Material.Typography>
          );
        case orderTerm.MONTHLY:
          return (
            <Material.Typography sx={styles.answers} component="span">
              MONTHLY Booking
            </Material.Typography>
          );
        default:
          return "-";
      }

    case orderType.SINGLE_BOOKING:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Single Term Order
        </Material.Typography>
      );
    default:
      return "-";
  }
};
export const orderStatusFun = (order_status) => {
  switch (order_status) {
    case orderStatus.NOT_CONFIRMED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Unpaid 
        </Material.Typography>
      );
    case orderStatus.CONFIRMED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Ongoing
        </Material.Typography>
      );
    case orderStatus.REJECTED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Rejected
        </Material.Typography>
      );
    case orderStatus.CANCELLED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Cancelled
        </Material.Typography>
      );
    case orderStatus.DELIVERED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Delivered 
        </Material.Typography>
      );
    case orderStatus.COMPLETED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Completed 
        </Material.Typography>
      );
    default:
      return "";
  }
};
export const tripStatusFun = (delivery_status, tankertype) => {
  switch (delivery_status) {
    case deliveryStatus.SCHEDULED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          SCHEDULED {<FcIcons.FcClock />}
        </Material.Typography>
      );
    case deliveryStatus.DELIVERING:
      return (
        <Material.Typography sx={styles.answers} component="span">
          {tankertype === tankerType.SEWAGE ? "EVACUATING" : "ONGOING"}{" "}
          {<FcIcons.FcInTransit size="20px" />}
        </Material.Typography>
      );
    case deliveryStatus.DELIVERED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          {tankertype === tankerType.SEWAGE ? "EVACUATED" : "DELIVERED"}{" "}
          {<FcIcons.FcShipped size="20px" />}
        </Material.Typography>
      );
    case deliveryStatus.CANCELLED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Cancelled {<FaIcons.FaTimesCircle color="red" />}
        </Material.Typography>
      );
    case orderStatus.DELIVERED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          {tankertype === tankerType.SEWAGE ? "EVACUATED" : "DELIVERED"}
          {<FcIcons.FcShipped />}
        </Material.Typography>
      );
    case orderStatus.COMPLETED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Completed {<FcIcons.FcOk />}
        </Material.Typography>
      );
    default:
      return "";
  }
};
export const tankerTypeFun = (tanker_type, isVerified) => {
  switch (tanker_type) {
    case tankerType.WATER:
      return (
        <Material.Box>
          <img style={styles.tankerLogo} src={waterTanker} alt="" />
          {isVerified === true ? (
            <Material.Box
              sx={[styles.centerCol, { marginTop: { xs: "0px", sm: "15px" } }]}
            >
              <FcIcons.FcRating size={20} />
              <Material.Typography
                sx={{
                  color: "#fc3a3a",
                  fontWeight: "bolder",
                  fontSize: "12px",
                  marginLeft: { xs: "20px", sm: "0" },
                }}
              >
                Verified
              </Material.Typography>
            </Material.Box>
          ) : (
            ""
          )}
        </Material.Box>
      );
    case tankerType.SEWAGE:
      return <img style={styles.tankerLogo} src={sewageTanker} alt="" />;
    case tankerType.PACKAGED_DRINKING_WATER:
      return (
        <img style={styles.bottlePng} src={waterCanimg} alt="" />
      );
    default:
      return "";
  }
};
export const tankerTypeTdsCapacityFun = (
  isVerified,
  tanker_type,
  minTds,
  maxTds,
  tdsBSensor,
  tdsTSensor,
  capacity
) => {
  // console.log("tdsBSensor: " + tdsBSensor);
  switch (tanker_type) {
    case tankerType.WATER:
      return (
        <Material.Box>
          <Material.Box sx={{ marginTop: "25px" }}>
            <Material.Typography
              sx={[styles.headings, { color: "brown" }]}
              component="span"
            >
              Claimed TDS :{" "}
            </Material.Typography>
            <Material.Typography
              sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
              component="span"
            >
              {minTds} - {maxTds} ppm
            </Material.Typography>
          </Material.Box>
          {isVerified ? (
            <Material.Box sx={{ marginTop: "5px" }}>
              <Material.Typography
                sx={[styles.headings, { color: "brown" }]}
                component="span"
              >
                Live TDS :{" "}
              </Material.Typography>
              <Material.Typography
                sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
                component="span"
              >
                {tdsBSensor === null || tdsBSensor === ""
                  ? tdsTSensor === null || tdsTSensor === ""
                    ? " -"
                    : tdsTSensor + " ppm"
                  : tdsBSensor + " ppm"}
              </Material.Typography>
            </Material.Box>
          ) : (
            ""
          )}

          <Material.Box sx={{ marginTop: "5px" }}>
            <Material.Typography
              sx={[styles.headings, { color: "brown" }]}
              component="span"
            >
              Tanker Capacity :{" "}
            </Material.Typography>
            <Material.Typography
              sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
              component="span"
            >
              {capacity} KL
            </Material.Typography>
          </Material.Box>
        </Material.Box>
      );
    case tankerType.SEWAGE:
      return (
        <Material.Box sx={{ marginTop: "15px" }}>
          <Material.Typography
            sx={[styles.headings, { color: "brown" }]}
            component="span"
          >
            Tanker Capacity :{" "}
          </Material.Typography>
          <Material.Typography
            sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
            component="span"
          >
            {capacity} KL
          </Material.Typography>
        </Material.Box>
      );
    case tankerType.PACKAGED_DRINKING_WATER:
    default:
      return "";
  }
};
export const paymentStatusFun = (payment_status) => {
  switch (payment_status) {
    case paymentStatus.PAID:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Paid {<FcIcons.FcPaid size="20px" />}
        </Material.Typography>
      );
    case paymentStatus.PAYMENT_PENDING:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Payment Pending {<FcIcons.FcSynchronize size="20px" />}
        </Material.Typography>
      );
    case paymentStatus.PAYMENT_MISMATCH:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Payment Mismatch
        </Material.Typography>
      );
    case paymentStatus.FAILED:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Failed {<FcIcons.FcHighPriority size="20px" />}
        </Material.Typography>
      );
    default:
      return "";
  }
};
export const paymentTypeFun = (payment_type) => {
  switch (payment_type) {
    case paymentType.PREPAID:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Pre-Paid
        </Material.Typography>
      );
    case paymentType.POSTPAID:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Post-Paid
        </Material.Typography>
      );
    case paymentType.CASH_ON_DELIVERY:
      return (
        <Material.Typography sx={styles.answers} component="span">
          Cash On Delivery
        </Material.Typography>
      );
    default:
      return "";
  }
};
export const paymentMode = (payment_method) => {
  switch (payment_method) {
    case "RAZORPAY":
      return <img style={styles.razorPayIconLogo} src={razorPayIcon} alt="" />;
    case "PAYSHARP":
      return <img style={styles.paysharpIconLogo} src={paySharpIcon} alt="" />;
    case "POSTPAID":
      return <FcIcons.FcMoneyTransfer size="20px" />;
    default:
      return "";
  }
};
export const postPaidStatusFun = (postpaidrequest) => {
  switch (postpaidrequest) {
    case postpaidStatus.REQUESTED:
      return (
        <Material.Box sx={{ marginTop: "5px" }}>
          <Material.Typography sx={styles.headings} component="span">
            Post-Paid Status :{" "}
          </Material.Typography>
          <Material.Typography sx={styles.answers} component="span">
            REQUESTED{" "}
          </Material.Typography>
          <FcIcons.FcProcess size={20} />
        </Material.Box>
      );
    case postpaidStatus.APPROVED:
      return (
        <Material.Box sx={{ marginTop: "5px" }}>
          <Material.Typography sx={styles.headings} component="span">
            Post-Paid Status :{" "}
          </Material.Typography>
          <Material.Typography sx={styles.answers} component="span">
            APPROVED{" "}
          </Material.Typography>
          <FcIcons.FcApproval size={20} />
        </Material.Box>
      );
    case postpaidStatus.REJECTED:
      return (
        <Material.Box sx={{ marginTop: "5px" }}>
          <Material.Typography sx={styles.headings} component="span">
            Post-Paid Status :{" "}
          </Material.Typography>
          <Material.Typography sx={styles.answers} component="span">
            REJECTED{" "}
          </Material.Typography>
          <FcIcons.FcDisapprove size={20} />
        </Material.Box>
      );
    default:
      return "";
  }
};
export const hintNoteForCustomerPdw = (paymentMethod) => {
  let time = "";
  switch (paymentMethod) {
    case "RAZORPAY":
    case "WATERWALLET":
      time = "15 Mintues";
      break;
    case "PAYSHARP":
      time = "2 hours";
      break;
    default:
      return "";
  }
  return (
    <Material.Box sx={{ marginTop: "15px" }}>
      <Material.Typography
        sx={[styles.headings, { color: "red" }]}
        component="span"
      >
        Note :{" "}
      </Material.Typography>
      <Material.Typography
        sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
        component="span"
      >
        Please pay the amount within {time} {<FcIcons.FcOvertime size="20px" />}
      </Material.Typography>
    </Material.Box>
  );
};
export const hintNoteForCustomer = (postpaidrequest) => {
  switch (postpaidrequest) {
    case postpaidStatus.APPROVED:
      return "";
    default:
      return (
        <Material.Box sx={{ marginTop: "15px" }}>
          <Material.Typography
            sx={[styles.headings, { color: "red" }]}
            component="span"
          >
            Note :{" "}
          </Material.Typography>
          <Material.Typography
            sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
            component="span"
          >
            Please pay the amount within 2 hours{" "}
            {<FcIcons.FcOvertime size="20px" />}
          </Material.Typography>
        </Material.Box>
      );
  }
};
export const paysharpStatusFun = (paysharphandle) => {
  switch (paysharphandle) {
    case paysharpStatus.PAYSHARP_ENABLE:
      return (
        <Material.Box sx={{ marginTop: "15px" }}>
          <Material.Typography
            sx={[styles.headings, { color: "brown" }]}
            component="span"
          >
            Paysharp Status :{" "}
          </Material.Typography>
          <Material.Typography
            sx={{ color: "#000", fontWeight: "bolder", fontSize: "14px" }}
            component="span"
          >
            Paysharp Enabled for this Order
          </Material.Typography>
        </Material.Box>
      );
    default:
      return "";
  }
};

export const tripRateFun = (
  order_type,
  tripRate,
  pipeLengthCharges,
  gateWayFee
) => {
  const finalRate = parseFloat(tripRate) + parseFloat(pipeLengthCharges);

  switch (order_type) {
    case orderType.LONG_TERM_BOOKING:
      return finalRate;

    case orderType.SINGLE_BOOKING:
      return finalRate - parseFloat(gateWayFee);
    default:
      return "-";
  }
};

export const deliveryTimeFun = (dTime) => {
  switch (dTime) {
    case deliveryTimingOptions.SIX_SIX:
      return "Delivery Time (6 AM - 6 PM)";
    case deliveryTimingOptions.ANY_TIME:
      return "24 Hours";
    default:
      return "-";
  }
};
