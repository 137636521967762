/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import { Box, Button, Typography } from "@mui/material";
import { FcNext, FcPrevious } from "react-icons/fc";
export default function PageNavigation({
  setPageNumber,
  pageNumber,
  pageList,
  styles
}) {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: { sm: "10px", xs: "10px" },
          ...styles,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Button
            disabled={pageNumber > 1 ? false : true}
            variant="standard"
            size="small"
            onClick={() => setPageNumber(pageNumber - 1)}
            sx={{
              textTransform: "none",
              color: appColors.darkGray,
              fontWeight: "600",
            }}
          >
            {<FcPrevious />}
            Back
          </Button>
          <Typography
            variant="button"
            display="block"
            gutterBottom
            sx={{
              marginLeft: "10px",
              marginRight: "10px",
              backgroundColor: appColors.textColorNew,
              color: appColors.white,
              padding: "3px",
              width: "30px",
              height: "30px",
              borderRadius: "15px",
              textAlign: "center",
            }}
          >
            {pageNumber}
          </Typography>
          <Button
            disabled={pageList}
            variant="standard"
            size="small"
            onClick={() => setPageNumber(pageNumber + 1)}
            sx={{
              textTransform: "none",
              color: appColors.darkGray,
              fontWeight: "600",
            }}
          >
            Next
            {<FcNext />}
          </Button>
        </Box>
      </Box>
    </>
  );
}
