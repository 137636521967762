/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Button Field (Buuton Field)
 * @date 026/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { AppBar, Box, Button, Typography } from "@mui/material";
import React from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useSelector } from "react-redux";
export default function NavigationButtons({
  rowsPerPage,
  setRowsPerPage,
  page,
  setPage,
  pageStatus
}) {
  const orderDetails = useSelector((state) => state.ordersDetails);
  //function for handling order back page number
  const handelPageNumberBack = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: ".3rem",
      }}
    >
      <Button
        disabled={page > 1 ? false : true}
        variant="standard"
        size="small"
        onClick={handelPageNumberBack}
        sx={{ textTransform: "none", color: appColors.Textgray }}
      >
        {<NavigateBeforeIcon color="primary" />} Back
      </Button>
      <Typography
        variant="button"
        display="block"
        gutterBottom
        sx={{
          marginLeft: "10px",
          marginRight: "10px",
          backgroundColor: appColors.textColorNew,
          color: appColors.white,
          padding: "3px",
          width: "30px",
          height: "30px",
          borderRadius: "15px",
          textAlign: "center",
        }}
      >
        {page}
      </Typography>
      <Button
        disabled={pageStatus}
        variant="standard"
        size="small"
        onClick={() => setPage(page + 1)}
        sx={{ textTransform: "none", color: appColors.Textgray }}
      >
        Next {<NavigateNextIcon color="primary" />}
      </Button>
    </Box>
  );
}
