/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Lebansty Valan
 * @description Addresscard.jsx the Card for consumer to select address while booking.
 * @date 06/06/23
 * @version 0.0.1 - Initial Release
 */
import React, { useEffect } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Dialog,
  Grid,
  Link,
  Radio,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import closeIcon from "../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import { cardStyle } from "../../Cards/SkuCards/cardStyles";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import comletedIcon from "../../../Assets/ordersPage/Completed - Selected Menu.svg";
import appColors from "../../../Assets/AppTheme/appColors";
import { EditAddressDialogBox } from "../../../Pages/Settings/tabs/myaddressbook/EditDialogBox";
import { EditAdress } from "../../../Redux/actions/settingsAction";
import Biconcard from "../../../Assets/MobileTheme/Water wallet without Card Background.svg";
import { makeStyles } from "@mui/styles";
import { SetSelectedAddress } from "../../../Redux/actions/skuBookingAction";
import DeleteAddressDialog from "../../DialogBoxes/SkuDialogs/Booking/deleteAddressDialog";
import appTheme from "../../../Assets/AppTheme/appTheme";
import { AddNewAddressDialogBox } from "../../../Pages/Settings/tabs/myaddressbook/DialogBoxes";
import GoogleMapsDialog from "../../DialogBoxes/GoogleAddress/googleAddressDialog";

const useStyles = makeStyles((theme) => ({
  fullscreenDialog: {
    maxWidth: "100%",
    width: "100%",
  },
  halfscreenDialog: {
    maxWidth: "100%",
    width: "100%",
  },
}));
function Addresscard({
  setAddress,
  address,
  primaryAddress,
  setCurrentAddress,
  setchooseAddressDialog,
  chooseAdressDialog,
  setOpenEditDialog,
  openEditDialog,
  handleAddNewAddress,
}) {
  const dispatch = useDispatch();
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);
  const addressDetails = useSelector((state) => state.settings);
  const [addressEdit, setAddressEdit] = React.useState({});
  const [openAddAddress, setOpenAddAddress] = React.useState(false);
  const [openDia, setOpenDia] = React.useState(false);
  const [addressId, setAddressId] = React.useState("");
  const [addressType, setAddressType] = React.useState("");
  const handelAddress = (primaryAddress) => {
    setCurrentAddress(primaryAddress);
    setAddress(primaryAddress.address_id);
    dispatch(SetSelectedAddress(primaryAddress.address_id));
    localStorage.setItem("addressId", primaryAddress.address_id);
    setchooseAddressDialog(false);
  };

  const handleOpenEditDia = (address) => {
    setAddressType("edit");
    setAddressEdit(address);
    handleClickOpenEditDialog();
  };

  const handleClickOpenEditDialog = () => {
    setAddAddressDialog(true);
    // console.log(openEditDialog);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleUpdateAddressDetails = (details) => {
    dispatch(EditAdress(details));
    handleCloseEditDialog();
  };

  React.useEffect(() => {
    setCurrentAddress(primaryAddress);
  }, [addressDetails]);

  //function to handel delete address
  const handleRemoveAddress = (addressId) => {
    setOpenDia(true);
    setAddressId(addressId);
  };

  //function to close add address dialog
  const handelCloseAddAddress = () => {
    setOpenAddAddress(false);
  };

  //function to handel addaddress
  const handelAddAddress = () => {
    setAddAddressDialog(true);
    setAddressType("add");
  };

  return (
    <Dialog
      open={chooseAdressDialog}
      sx={{ backgroundColor: appColors.dialogBackGround }}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          position: "relative",
          background: appColors.secondaryCard,
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            marginTop: ".5rem",
            marginLeft: "1rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#fff",
              height: "2.5rem",
              background: appColors.secondaryCard,
            }}
          >
            <Typography
              sx={{
                fontSize: appTheme.fontSizexl,
                color: appColors.secondaryText,
                fontWeight: "bold",
                margin: ".5rem",
              }}
            >
              Select Address
            </Typography>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={10}
          sx={{
            margin: "0rem 1.5rem 0 1.2rem",
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            height: addressDetails?.addresses?.length > 0 ? "24rem" : "2rem",
            overflowY: "scroll",
          }}
        >
          {addressDetails?.addresses.map((primaryAddress, index) => (
            <div key={index}>
              <Card
                sx={{
                  // border: "1px solid gray",
                  boxShadow: 5,
                  borderRadius: "1rem",
                  margin: ".3rem",
                  position: "relative",
                  ...cardStyle.addressParentCard,
                  backgroundColor:
                    address === primaryAddress.address_id
                      ? appColors.textColorNew
                      : "inherit",
                  color:
                    address === primaryAddress.address_id
                      ? "#fff"
                      : appColors.darkGray,
                  "& .MuiRadio-colorPrimary": {
                    color:
                      address === primaryAddress.address_id
                        ? "#fff"
                        : appColors.darkGray,
                  },
                }}
              >
                <CardContent onClick={() => handelAddress(primaryAddress)}>
                  <Box>
                    <Box sx={cardStyle.addressHolder}>
                      <Box sx={{ display: "flex" }}>
                        <Radio
                          size="small"
                          checked={address === primaryAddress?.address_id}
                          value={primaryAddress?.service_area}
                          sx={{ padding: "0px" }}
                        />
                        &nbsp;
                        <Typography
                          sx={{
                            fontSize: appTheme.fontSizeMedium,
                            fontWeight: "bold",
                          }}
                        >
                          {`${primaryAddress?.address_type}`}
                        </Typography>
                      </Box>
                      {primaryAddress?.is_primary && (
                        <Box
                          sx={{
                            height: "2.5rem",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: appTheme.fontSizeMedium,
                              color:
                                address === primaryAddress.address_id
                                  ? "#fff"
                                  : appColors.textColorNew,
                              fontWeight: "bold",
                              margin: ".5rem",
                            }}
                          >
                            Primary
                          </Typography>
                        </Box>
                      )}
                    </Box>
                    <Box>
                      <Typography sx={cardStyle.addressHolderTypo}>
                        {primaryAddress?.contact_name}
                      </Typography>
                      <Typography sx={cardStyle.addressHolderTypo}>
                        {`${primaryAddress?.address_line1},${primaryAddress?.address_line2},${primaryAddress?.landmark}`}
                      </Typography>
                      {/* <Typography sx={{ fontSize: 13 }}>Tamil Nadu</Typography> */}
                      <Typography sx={cardStyle.addressHolderTypo}>
                        {primaryAddress?.pincode},{" "}
                        {primaryAddress?.service_area}
                      </Typography>
                    </Box>
                  </Box>
                  <img
                    alt=""
                    src={Biconcard}
                    style={{
                      position: "absolute",
                      height: "20rem",
                      objectFit: "contain",
                      zIndex: 1,
                      top: 0,
                    }}
                  />
                </CardContent>
                <CardActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        cursor: "pointer",
                        borderRadius: ".5rem",
                        zIndex: 1,
                        backgroundColor:
                          address === primaryAddress.address_id
                            ? appColors.white
                            : appColors.textColorNew,
                        color:
                          address === primaryAddress.address_id
                            ? appColors.textColorNew
                            : appColors.white,
                      }}
                      onClick={() => handleOpenEditDia(primaryAddress)}
                    >
                      Edit
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={() =>
                        handleRemoveAddress(primaryAddress?.address_id)
                      }
                      sx={{
                        bgcolor: appColors.Failed,
                        color: "#fff",
                        borderRadius: ".5rem",
                        zIndex: 1,
                      }}
                    >
                      Delete
                    </Button>
                  </Box>
                  {address === primaryAddress.address_id ? (
                    <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                      <img src={comletedIcon} alt="" />
                    </Box>
                  ) : (
                    ""
                  )}
                </CardActions>
              </Card>
            </div>
          ))}
        </Grid>
        <Grid item xs={12} md={10}>
          <Card
            sx={{
              backgroundColor: appColors.textColorNew,
              ...cardStyle.addAdressParentCard,
              height: !primaryAddress ? "2rem" : "100%",
            }}
            onClick={() => handelAddAddress()}
          >
            <Box sx={cardStyle.addAddressChildBox}>
              <Typography sx={{ fontSize: appTheme.fontSizexs, color: "#fff" }}>
                ADD ADDRESS
              </Typography>
            </Box>
          </Card>
        </Grid>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
            width: "100%",
          }}
        >
          <Button
            variant="contained"
            onClick={() => setchooseAddressDialog(false)}
            size="small"
            sx={{
              backgroundColor: appColors.lightblue,
              width: { xs: "100%", sm: "auto" },
              marginLeft: "1rem",
              color: appColors.darkTextColor,
              "&:hover": {
                backgroundColor: appColors.lightblue,
              },
            }}
          >
            Close
          </Button>
        </Box>
      </Grid>

      {openEditDialog && (
        <EditAddressDialogBox
          open={openEditDialog}
          data={addressEdit}
          handleClose={handleCloseEditDialog}
          handleUpdateAddressDetails={handleUpdateAddressDetails}
        />
      )}
      {openAddAddress && (
        <AddNewAddressDialogBox
          open={openAddAddress}
          handleClose={handelCloseAddAddress}
          handleAddNewAddress={handleAddNewAddress}
          setAddAddressDialog={setOpenAddAddress}
          setchooseAddressDialog={setchooseAddressDialog}
        />
      )}
      <DeleteAddressDialog
        setOpenDia={setOpenDia}
        openDia={openDia}
        addressId={addressId}
      />

      <GoogleMapsDialog
        open={addAdressDialog}
        setOpen={setAddAddressDialog}
        setOpenAddress={setOpenAddAddress}
        editAddressData={addressEdit}
        addressType={addressType}
      />

      {/* <img
      alt=""
      src={closeIcon}
      style={{
        position: "absolute",
        right: 0,
        top: 0,
        cursor: "pointer",
        height: "30px",
        zIndex: 1,
      }}
      onClick={() => setchooseAddressDialog(false)}
    /> */}
    </Dialog>
  );
}

export default Addresscard;
