/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description  Check out page where payments and order placing events are handled
 *
 */

import React, { useRef } from "react";
import useScreenSize from "../../Components/Utils/mobileScreen";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CheckOutAction } from "../../Redux/actions/checkOutAction";
import CheckoutMain from "./checkOutMain";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function CheckOutLandingPage() {
  const dispatch = useDispatch();
  const isMobile = useScreenSize();
  const history = useHistory();
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const SkuSelectedProducts = useSelector((state) => state.skuBookDetails);
  const [apiFlag, setApiFlag] = React.useState(false);
  // //useeffect for hitting update api if data changes
  // React.useEffect(() => {
  //   if (SkuSelectedProducts.selectedProduct.products !== undefined)
  //     dispatch(CheckOutAction(SkuSelectedProducts.selectedProduct));
  // }, []);

  //useEffect to handel api flag
  React.useEffect(() => {
    if (checkoutDetails?.loading === false) {
      setApiFlag(true);
    }
  }, [checkoutDetails?.loading]);


  return (
    <>
      <CheckoutMain />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!apiFlag}
      >
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BiconLoader}
            alt="Loading..."
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </Backdrop> */}
    </>
  );
}
