/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description coupon dialog box
 * @date
 * @version
 */

import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Box,
  Typography,
  TextField,
} from "@mui/material";
import orderSucess from "../../../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import Rating from "@mui/material/Rating";
import { useDispatch } from "react-redux";
import appColors from "../../../../Assets/AppTheme/appColors";
import appTheme from "../../../../Assets/AppTheme/appTheme";
import {
  ConsumerAppRating,
  ConsumerRatingState,
} from "../../../../Redux/actions/profileAction";

function ConsumerRatingDialog({ openRatingDialog, setOpenRatingDialog }) {
  const [value, setValue] = useState(5);
  const [bodyContent, setBodyContent] = useState("");
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
    },
  };

  //rating dialog handler
  const handelRatingDialogClose = () => {
    setOpenRatingDialog(false);
    dispatch(ConsumerRatingState(false));
  };

  //function to handel rating submit
  const handelRatingSubmit = () => {
    if (!bodyContent.trim() && value < 3) {
      setError("Please provide a rating and your feedback.");
      return;
    }
    if (value === null) {
      setError("Please provide a rating and your feedback.");
      return;
    }
    setError(""); // Clear any previous error
    handelRatingDialogClose();
    dispatch(
      ConsumerAppRating({
        rating: value,
        reason: bodyContent,
      })
    );
  };

  return (
    <Dialog
      open={openRatingDialog}
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <DialogContent sx={{ backgroundColor: appColors.homePageCard }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            {" "}
            <img
              alt=""
              src={orderSucess}
              style={{ objectFit: "contain", height: "6rem", width: "6rem" }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: appTheme.fontSizeLarge,
              color: appColors.darkGray,
              fontWeight: "bold",
            }}
          >
            Rate us
          </Typography>
          <Box sx={{ "& > legend": { mt: 1 }, marginTop: ".5rem" }}>
            <Rating
              name="simple-controlled"
              value={value}
              onChange={(event, newValue) => {
                if (newValue !== null) {
                  setValue(newValue);
                }
              }}
              size="large"
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >
          <Box sx={{ "& > legend": { mt: 1 } }}>
            <TextField
              id="outlined-multiline-static"
              label="Type Your Comments"
              size="small"
              multiline
              rows={2}
              value={bodyContent}
              onChange={(e) => {
                if (e.target.value.length <= 100) {
                  setBodyContent(e.target.value);
                  setError("");
                } else {
                  setError("Comments should be under 100 characters");
                }
              }}
              sx={{
                width: "100%",
                marginTop: "1rem",
                bgcolor: appColors.cardColor,
                color: appColors.darkGray,
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: appColors.darkGray,
                },
              }}
              InputLabelProps={inputLabelProps}
              inputProps={{
                style: {
                  color: appColors.darkGray, // Set the color of the text input
                },
              }}
            />
            {error && (
              <Typography variant="caption" sx={{ color: appColors.Failed }}>
                {error}
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: appColors.homePageCard,
          display: "flex",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          variant="contained"
          size="small"
          onClick={handelRatingDialogClose}
          sx={{ color: appColors.white, bgcolor: appColors.Failed }}
        >
          Close
        </Button>
        <Button
          variant="contained"
          onClick={handelRatingSubmit}
          size="small"
          sx={{ color: appColors.white, bgcolor: appColors.textColorNew }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConsumerRatingDialog;
