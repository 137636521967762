/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import actionType from "./actionTypes";
import actionType from "./../actions/actionTypes";
const initialState = {
  error: true,
  toastMsg: "",
  toastContainerFlag: false,
};

const toastMsgReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.TOAST_MSG_CONTAINER:
      return {
        ...state,
        error: action.payload.data.error,
        toastMsg: action.payload.data.msg,
        toastContainerFlag: action.payload.data.containerFlag,
      };
    default:
      return state;
  }
};

export default toastMsgReduser;
