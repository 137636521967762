import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  subOrderId: "",
  subOrderAmount: "",
  particularOrderData: {},
};

const EditOrderReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.EDIT_ORDER_USER:
      return {
        ...state,
        error: action.payload.data.error,
        msg: action.payload.data.msg,
        subOrderId: action.payload.data.subOrderId,
        subOrderAmount: action.payload.data.subOrderAmount,
      };
    case actionType.GET_PWD_PARTICULAR_ORDER:
      return {
        ...state,
        error: action.payload.data.error,
        msg: action.payload.data.msg,
        particularOrderData: action.payload.data,
      };
    default:
      return state;
  }
};

export default EditOrderReducer;
