/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description
 * @date 06/06/23
 * @version 0.0.6 - Initial Release
 */
import { Grid } from "@mui/material";
import React from "react";
import QuickActionCard from "./QuickActionCard";
import * as orderActions from "../../../Redux/actions/ordersAction";
import FreqQues from "./FreqQues";
import Advertise from "./Advertise";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { AllOrderOtpPaymentDetails } from "../../../Redux/actions/recentActivityAction";
import OtpCard from "../OtpCard";
import { renderData } from "../../../Redux/actions/isLoadingAction";
import { GetWalletDetails } from "../../../Redux/actions/paymentMethodAction";
import Box from "@mui/material/Box";
import { useHistory, useLocation } from "react-router-dom";
import { faqStyles } from "./faqStyle";
import ToastMsgCotainer from "../../../Components/ToastMsg/toastMsg";
import ConsumerRatingDialog from "../../../Components/DialogBoxes/SkuDialogs/Booking/bookingRatingDialog";

function NewHome() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const [openRatingDialog, setOpenRatingDialog] = React.useState(false);
  const profile = useSelector((state) => state.userProfile);
  const orderDetails = useSelector((state) => state.ordersDetails);
  const [refreshFlag, setRefreshFlag] = React.useState(true);
  const data = useSelector((state) => state.qrScannerDetails);
  const [openPdwSummaryDialog, setOpenPdwSummaryDialog] = React.useState(false);
  const [isOrderInPaymentPending, setIsOrderPaymentPending] =
    React.useState(false);
  const recentActivitiesData = useSelector((state) => state.recentActivities);

  //For loading address details and repeat order details
  React.useEffect(() => {
    if (
      profile?.consumer?.is_android_app_rated === undefined &&
      profile?.consumerRatingFlag
    ) {
      // setTimeout(() => {
      //   setOpenRatingDialog(true);
      // }, 2000);
    }
  }, [profile]);

  //UseEffect for getting repeat order details
  React.useEffect(() => {
    dispatch(AllOrderOtpPaymentDetails());
  }, []);
  //For handing opening mobile ui in phones
  React.useLayoutEffect(() => {
    const name = new URLSearchParams(search).get("pageName");
    if (window.innerWidth < 600 && name !== "faq") {
      history.push("/mobile");
    }
  }, []);

  // console.log(window);
  //For getting payment panding order datails
  React.useEffect(() => {
    if (orderDetails.pending_orders.length !== 0) {
      orderDetails.pending_orders.map((order, key) => {
        if (order.payment_method !== "POSTPAID") {
          setIsOrderPaymentPending(true);
        } else {
          setIsOrderPaymentPending(false);
        }
      });
    }
  }, [orderDetails]);

  //For handling qr dialog box
  React.useEffect(() => {
    if (data.isQrDataLoaded) {
      data.isQrDataLoaded = false;
    }
  }, [data]);

  React.useEffect(() => {
    if (refreshFlag) {
      setRefreshFlag(false);
      dispatch(renderData("BookNow"));
    }
  }, [refreshFlag]);

  //For redirection to subscribe page
  const onSubmit = () => {
    history.push("/dashboard/subscribe");
  };


  return (
    <Grid
      direction="row"
      justifyContent="space-between"
      alignItems={{ xs: "center", md: "flex-start" }}
      container
      spacing={2}
      sx={{
        marginTop: "50px",
        marginBottom: { lg: 0, md: 0, sm: "4rem", xs: "4rem" },
      }}
    >
      <Grid item xs={12} sm={12} md={5}>
        <QuickActionCard onSubmit={onSubmit} />
      </Grid>
      <Grid item xs={12} sm={12} md={2} lg={2}>
        <Box sx={{ marginTop: { lg: "5.2rem", md: "5rem", sm: 0 } }}>
          {recentActivitiesData?.allDetails?.otp?.regular?.length > 0 ||
          recentActivitiesData?.allDetails?.otp?.return?.length > 0 ||
          recentActivitiesData?.allDetails?.otp?.replacement?.length > 0 ? (
            <OtpCard recentActivitiesData={recentActivitiesData} />
          ) : (
            ""
          )}
        </Box>
      </Grid>
      <Grid item xs={12} sm={12} md={5} sx={faqStyles.faqStyle}>
        <FreqQues />
      </Grid>
      <Grid item md={12}>
        <hr
          style={{
            border: "solid 1px black",
          }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <Advertise />
      </Grid>
      <ToastMsgCotainer />
      <ConsumerRatingDialog
        openRatingDialog={openRatingDialog}
        setOpenRatingDialog={setOpenRatingDialog}
      />
    </Grid>
  );
}

export default NewHome;
