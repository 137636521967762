// import * as React from "react";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as Material from "@mui/material";
import * as FcIcons from "react-icons/fc";
import LoadingButton from "@mui/lab/LoadingButton";
// import { verifyEmail } from "../../../Redux/actions/settingsAction";
import { verifyEmail, sendEmailOtp } from "../../../Redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { SendEmailOtp } from "../../../Redux/actions/bookNowAction";
import { GetUserProfile } from "../../../Redux/actions/profileAction";
import appColors from "../../../Assets/AppTheme/appColors";
import CustomConsole from "../../../coustomConsole";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmailVericationDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [eloading, setELoading] = useState(false);
  const [verifyYourEmail, setVerifyYourEmail] = useState(false);
  const [yesOrNo, setYesOrNo] = useState(true);
  const dispatch = useDispatch();
  const bookNowState = useSelector((state) => state.bookNow);
  const auth = useSelector((state) => state.authentications);
  const profile = useSelector((state) => state.userProfile);

  const style = {
    loadingButton: {
      marginTop: "10px",
      width: "100px",
      backgroundColor: appColors.lightGreen,
      borderTopRightRadius: "15px",
      borderBottomLeftRadius: "15px",
      "&:hover": { backgroundColor: appColors.darkBlue },
      borderRadius: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    },
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    CustomConsole(profile);
    if (profile.user.is_email_verified === true) {
      props.setEmailInvoiceClicked(false);
      props.setIsEmailVerified(profile.user.is_email_verified);
    }
  }, [profile]);

  React.useEffect(() => {
    // CustomConsole(`auth ${JSON.stringify(auth)}`);
    CustomConsole(auth);
  }, [auth]);

  const handleClose = () => {
    props.setEmailInvoiceClicked(false);
    setOpen(false);
  };
  React.useEffect(() => {
    // dispatch(sendEmailOtp());
    if (verifyYourEmail) {
      dispatch(SendEmailOtp());
    }
    CustomConsole(bookNowState);
  }, [verifyYourEmail]);

  function handleEmailSubmit() {
    if (emailVerificationCode !== null && emailVerificationCode.length === 6) {
      setELoading(true);
      dispatch(verifyEmail(emailVerificationCode));
      setTimeout(() => {
        dispatch(GetUserProfile());
      }, 2000);
      setTimeout(() => setELoading(false), 2000);
    } else {
      toast.dark("Please Enter a 6-Digit Valid Email OTP", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  }

  React.useEffect(() => {
    CustomConsole(auth);
    if (auth.error && eloading) {
      CustomConsole("inside first if");
      setEmailVerify(false);
    } else if (!auth.error && eloading) {
      auth.error = true;
      setEmailVerify(true);
    }
  }, [auth, eloading]);
  const handleYes = () => {
    setVerifyYourEmail(true);
    setYesOrNo(false);
  };

  const handleNo = () => {
    props.setEmailInvoiceClicked(false);
  };

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={props.emailInvoiceClicked}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent sx={{ marginTop: "10px" }}>
          {yesOrNo ? (
            <>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Material.Typography
                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                  variant="overline"
                  display="block"
                  gutterBottom
                >
                  Verify your Email with OTP
                </Material.Typography>
                <Material.Button
                  variant="contained"
                  size="small"
                  onClick={handleYes}
                >
                  Yes
                </Material.Button>
                <Material.Button
                  variant="contained"
                  size="small"
                  onClick={handleNo}
                >
                  No
                </Material.Button>
              </Material.Box>
            </>
          ) : (
            ""
          )}

          {verifyYourEmail ? (
            <>
              <Material.Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                variant="overline"
                display="block"
                gutterBottom
              >
                Email Verification
              </Material.Typography>
              <DialogContentText id="alert-dialog-slide-description">
                <Material.TextField
                  label="E-Mail Verification Code"
                  //   variant="outlined"
                  type="number"
                  required
                  value={emailVerificationCode}
                  InputProps={{
                    startAdornment: (
                      <Material.InputAdornment position="start">
                        <FcIcons.FcOrganization size="30px" />
                      </Material.InputAdornment>
                    ),
                  }}
                  sx={{ width: "250px", marginTop:"10px"}}
                  onChange={(e) => setEmailVerificationCode(e.target.value)}
                />
              </DialogContentText>
              <LoadingButton
                disabled={emailVerify}
                type="submit"
                onClick={handleEmailSubmit}
                loading={eloading}
                variant="contained"
                startIcon={emailVerify ? <FcIcons.FcOk /> : ""}
                sx={
                  emailVerify
                    ? style.loadingButtonVerified
                    : style.loadingButton 
                }
              >
                {emailVerify ? "Verified" : "Verify"}
              </LoadingButton>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Verify</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
