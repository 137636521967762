import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Box, Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { toast } from "react-toastify";
import appColors from "../../../Assets/AppTheme/appColors";
import CustomConsole from "../../../coustomConsole";

export default function EditCancelReasonDialog(props) {
  const [isOtherReason, setIsOtherReason] = React.useState(false);
  const [error, setError] = React.useState("");
  const handleReasonChange = (event) => {
    setError(false);
    props.setCancelReason(event.target.value);
    if (event.target.value !== "999") {
      props.cancelOrderReasonsValues.map((data) => {
        if (parseInt(event.target.value) === data.k) {
          props.setCancelReasonStr(data.v);
        }
      });
    } else {
      props.setCancelReasonStr(props.cancelOtherReason);
    }
  };

  const handleClose = () => {
    props.setCancelOrderClicked(false);
    props.setCancelReason("");
  };

  CustomConsole(props.cancelReasonStr+"cancelReasonStr");
  const handleContinue = () => {
    if (props.cancelReasonStr !== "") {
      if (props.cancelReasonStr === "999") {
        if (props.cancelReasonStr.length >= 5) {
          props.setCancelReason(props.cancelOtherReason);
          // props.setIsCancelOrderDialogOpen(true);
          props.setCancelOrderClicked(false);
          props.handleCancelYes();
        } else {
          toast.dark("Enter your reason to cancel this order", {
            position: "bottom-right",
            autoClose: 3000,
            type: "error",
          });
        }
      }
      if (
        props.cancelReasonStr !== "999" &&
        props.cancelReasonStr.length >= 5
      ) {
        // props.setIsCancelOrderDialogOpen(true);
        props.setCancelOrderClicked(false);
        props.handleCancelYes();
      } else {
        setError(true);
      }
    } else {
      toast.dark("Choose your reason to cancel this order", {
        position: "bottom-right",
        autoClose: 3000,
        type: "Please enter minimum 5 character",
      });
    }
  };

  React.useEffect(() => {
    if (props.cancelReason === "999") {
      setIsOtherReason(true);
    } else {
      setIsOtherReason(false);
    }
  }, [props.cancelReason]);

  //function to handel reason
  const handleReasonChangeEntered = (event) => {
    props.setCancelReasonStr(event.target.value);
    setError(false);
  };
  return (
    <div>
      <Dialog
        open={props.cancelOrderClicked}
        onClose={props.cancelOrderClicked}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ background: appColors.secondaryCard }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  color: appColors.secondaryText,
                }}
              >
                Select reason to cancel this order
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: "10px",
              }}
            >
              <FormControl onChange={handleReasonChange}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  sx={{ color: appColors.darkGray }}
                >
                  {props.cancelOrderReasonsValues.map((reason, key) => {
                    return (
                      <FormControlLabel
                        value={reason.k}
                        control={<Radio />}
                        label={reason.v}
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            </Box>
            <Box sx={{ padding: "10px" }}>
              {isOtherReason && (
                <Box>
                  <TextField
                    size="small"
                    id="outlined-multiline-flexible"
                    label="Other reasons"
                    value={props.cancelReasonStr}
                    onChange={(event) => handleReasonChangeEntered(event)}
                    multiline
                    maxRows={4}
                    error={error}
                    helperText={
                      error ? "Please enter minimum 5 characters" : ""
                    }
                  />
                </Box>
              )}
            </Box>
            <Box sx={{ padding: "10px" }}>
              <Alert severity="info">
                Refund If any will be credited to your water wallet.
              </Alert>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "20px",
              }}
            >
              <Box>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={handleClose}
                  sx={{ backgroundColor: appColors.white }}
                >
                  Back
                </Button>
              </Box>
              <Box>
                <Button
                  size="small"
                  variant="contained"
                  onClick={handleContinue}
                  sx={{ backgroundColor: appColors.textColorNew }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
