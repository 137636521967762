import { useState, useEffect } from "react";
import AvailedGiftCards from "../availedGiftCards";
import { AppBar, Box, Button, Card } from "@mui/material";
import ClaimGiftCard from "../claimGiftCard";
import appColors from "../../../Assets/AppTheme/appColors";
import { FooterStyles } from "../../MobileView/MobileViewcss";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import PriceDetailsandNotification from "./buyGiftCardPriceNotify";
import giftCard from "../../../Assets/newMobileIcon/GiftCards/gift card icon blue L_.png";
import { useDispatch, useSelector } from "react-redux";
import {
  BuyNewGiftCardOrder,
  GetAvailableGiftCards,
} from "../../../Redux/actions/giftCardAction";
import SelectGreetingNotification from "./selectGreeting";
import ExtraDepositPayDialog from "../../../Components/DialogBoxes/SkuDialogs/extraDepositPayDialog";
import MyWallet from "../../../Components/PaymentComponent/mywallet/myWallet";
import CustomConsole from "../../../coustomConsole";
import { GetPaymentBlockDetails } from "../../../Redux/actions/PaymentActionSku";
import { ResetComponentState } from "../../../Redux/actions/appMetaDataAction";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import ToastMsgCotainer from "../../../Components/ToastMsg/toastMsg";
import appMessages from "../../../Assets/AppMeta/appMessages";
import DepositSucess from "../../../Components/DialogBoxes/SkuDialogs/depositSucessPopup";

export default function BuyNewGiftCards() {
  const history = useHistory();
  const dispatch = useDispatch();

  const [selectedImage, setSelectedImage] = useState("");
  const [notifyUserClick, setNotifyUserClick] = useState(false);
  const [sendGreetingFlag, setSendGreetingFlag] = useState(false);

  const [userPhone, setUserPhone] = useState("");
  const [userEmailId, setUserEmailId] = useState("");
  const [orderId, setOrderId] = useState("");
  const [amountToPay, setAmountToPay] = useState(0);
  const [priceError, setPriceError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [wishes, setWishes] = useState(null);
  const [paymentFlag, setPaymentFlag] = useState(false);
  const [passwordRequired, setPasswordRequired] = useState(true);
  const [loadWalletFlag, setLoadWalletFlag] = useState(false);
  const [reciverInfo, setReciverInfo] = useState({
    phone: null,
    email: null,
    isLoadGiftMoneyDirectly: false,
  });
  const [price, setPrice] = useState(0);
  const [finalOrderPrice, setFinalOrderPrice] = useState(0);
  const [selectedGreeting, setSelectedGreeting] = useState({});
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [promoCode, setpromoCode] = useState("");
  const [openVbDialog, setOpenVbDialog] = useState(false);
  const [upiId, setUpiId] = useState("");
  const [razorPayFlag, setrazorPayFlag] = useState(false);
  const [upiIdVPAObj, setUpiIdVPAObj] = useState({});
  const [verifyUpiClicked, setVerifyUpiIdClicked] = useState(false);
  const [checkOutButtonClick, setCheckOutButtonClick] = useState(false);
  const [orderPaymentMethod, setOrderPaymentMethod] = useState("");
  const [referLoading, setReferLoading] = useState(true);
  const paymentTypeWallet = {
    RAZORPAY: 0,
    PAYSHARP: 1,
    POSTPAID: 2,
    WATER_WALLET: 3,
    CLEARALL: 4,
  };
  const [payment, setPayment] = useState(paymentTypeWallet.PAYSHARP);
  const [upiUserOption, setUpiUserOption] = useState("2");
  const [upiPayUserOption, setUpiPayUserOption] = useState("");
  const [payType, setPayType] = useState(100);
  const [refundPaymentFlag, setRefundPaymentFlag] = useState(false);
  const [verifyBtn, setVerifyBtn] = useState(false);
  const [isUpiIdValid, setIsUpiIdValid] = useState(false);
  const [opean, setOpean] = useState(false);
  const [myWalletInBooking, setMyWalletInBooking] = useState(false);
  const [payFor, setPayFor] = useState("");
  const [payOptionCheck, setPayOptionCheck] = useState(false);
  const [choosenPartial, setChoosenPartial] = useState(false);
  const [bookingpaymentFlag, setBookingPaymentFlag] = useState(false);
  const [upiPay, setUpiPay] = useState(false);
  const paymentBlockDetails = useSelector(
    (state) => state.paymentDetailsSku.paymentBlockData
  );
  const BlocketPayments = new Set(paymentBlockDetails);
  //state for storing address ID
  const [paymentType, setPaymentType] = useState("PAYSHARP");
  const [paymentSucess, setOpenPaymentSuccessDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [multiplePaymentFlag, setMultiplePayment] = useState(false);
  const [
    useWalletBalancePayFlagforDeposit,
    setUseWalletBalancePayFlagforDeposit,
  ] = useState(true);
  const [openExtraDepositDialog, setOpenExtraDepositDialog] = useState(false);
  const [paymentTriggerFlag, setpaymentTriggerFlag] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);
  const [buttonError, setButtonError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  //useEffect to get gift card details
  useEffect(() => {
    dispatch(GetAvailableGiftCards());
  }, []);

  //function to trigger gift card
  const handelCreateGiftCard = () => {
    let reqObj = {
      amount: price,
      isPasswordRequired: passwordRequired,
      paymentMethod: paymentType,
      redeemThrough: "WALLET",
      userGreeting: {
        selectedCategory: sendGreetingFlag ? selectedGreeting?.category : null,
        selectedGreetingImage: sendGreetingFlag ? selectedImage : null,
      },
    };
    if (notifyUserClick) {
      reqObj.receiver = reciverInfo;
    }
    if (sendGreetingFlag && wishes !== null) {
      reqObj.userGreeting.ownWishes = wishes;
    }

    dispatch(BuyNewGiftCardOrder(reqObj)).then((data) => {
      if (data.error === false) {
        console.log("gift card order data");
        console.log(data);
        setOrderId(data?.data?.giftCardId);
        setpaymentTriggerFlag(true);
      }
    });

    console.log("reqObj.........");
    console.log(reqObj);
  };

  //function to open paymentdialog
  const handelOpenPaymentDialog = () => {
    setAmountToPay(price);
    setFinalOrderPrice(price);
    setBookingPaymentFlag(true);
  };

  // //useEffect for setting up an payment menthod
  useEffect(() => {
    let validPayment = "";
    if (!BlocketPayments.has(200)) {
      validPayment = "RAZORPAY";
      setPayment(paymentTypeWallet.RAZORPAY);
    } else if (
      (!BlocketPayments.has(100) && !BlocketPayments.has(101)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(102)) ||
      (!BlocketPayments.has(100) && !BlocketPayments.has(103))
    ) {
      validPayment = "PAYSHARP";
    } else if (!BlocketPayments.has(300)) {
      validPayment = "WALLET";
      setPayment(paymentTypeWallet.WATER_WALLET);
    }
    setPaymentType(validPayment);
  }, []);

  //useEffect for getting payment block details
  useEffect(() => {
    dispatch(GetPaymentBlockDetails());
    return () => {
      dispatch(ResetComponentState());
    };
  }, []);

  //useEffect for getting payment block details
  useEffect(() => {
    if (window.innerWidth > 600) {
      setUpiUserOption("1");
      setPayType(200);
    }
    dispatch(ResetComponentState());
  }, []);

  //function to hit update api after getting payment sucess responce from orderstatus api
  useEffect(() => {
    if (
      (walletDetails?.paymentData?.data?.payment_status === "SUCCESS" &&
        !refundPaymentFlag) ||
      (walletDetails?.paymentData?.data?.status === "SUCCESS" &&
        !refundPaymentFlag)
    ) {
      setRefundPaymentFlag(true);
    }
  }, [walletDetails.paymentData]);

  // //useEffect for setting up an payment menthod
  useEffect(() => {
    if (walletDetails?.walletAmount >= finalOrderPrice) {
      if (
        !BlocketPayments.has(101) &&
        !BlocketPayments.has(100) &&
        window.innerWidth <= 600
      ) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("2");
        setPayType(100);
      } else if (!BlocketPayments.has(102) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("1");
        setPayType(200);
      } else if (!BlocketPayments.has(300)) {
        setPaymentType("WALLET");
        setPayment(paymentTypeWallet.WATER_WALLET);
      } else if (!BlocketPayments.has(200)) {
        setPayment(paymentTypeWallet.RAZORPAY);
        setPaymentType("RAZORPAY");
      } else if (!BlocketPayments.has(103) && !BlocketPayments.has(100)) {
        setPaymentType("PAYSHARP");
        setPayment(paymentTypeWallet.PAYSHARP);
        setUpiUserOption("3");
        setPayType(100);
      }
    }

    if (finalOrderPrice !== undefined) {
      setFinalPrice(parseFloat(finalOrderPrice));
    }
  }, [finalOrderPrice]);

  //Useeffect for getting waterwallet payment method
  useEffect(() => {
    if (walletDetails?.isWalletSucess) {
      setPaymentType("PAYSHARP");
      setOpenPaymentSuccessDialog(true);
      dispatch(ResetComponentState());
    }
  }, [walletDetails]);

  // useEffect to calculate final price for multipayment commented due to hold in release
  useEffect(() => {
    if (
      useWalletBalancePayFlagforDeposit &&
      walletDetails?.walletAmount >= 1 &&
      bookingpaymentFlag &&
      amountToPay > walletDetails?.walletAmount &&
      amountToPay - parseFloat(walletDetails?.walletAmount) >= 1
    ) {
      CustomConsole(amountToPay + " " + "amountToPay");
      CustomConsole(
        walletDetails?.walletAmount + " " + "walletDetails?.walletAmount"
      );
      CustomConsole(amountToPay - walletDetails?.walletAmount);
      setAmountToPay(
        parseFloat(
          (amountToPay - parseFloat(walletDetails?.walletAmount)).toFixed(2)
        )
      );
    } else if (!useWalletBalancePayFlagforDeposit && bookingpaymentFlag) {
      setAmountToPay(parseFloat(finalOrderPrice));
    }
  }, [useWalletBalancePayFlagforDeposit, bookingpaymentFlag]);

  //useEffect to trigger payment option
  useEffect(() => {
    if (paymentTriggerFlag) {
      if (upiUserOption === "3" && isUpiIdValid) {
        setPaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else if (upiUserOption !== "3") {
        setPaymentFlag(true);
        setOpenExtraDepositDialog(false);
      } else {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Please verify your upi id to proceed`,
          })
        );
      }
      setpaymentTriggerFlag(false);
    }
  }, [paymentTriggerFlag]);

  //useEffect to set error to button field
  useEffect(() => {
    if (
      (errorMsg === "you send gift cards, other than book water consumer" &&
        reciverInfo.email !== "" &&
        reciverInfo.email !== null) ||
      (errorMsg === "User verified successfully" &&
        reciverInfo.email !== "" &&
        reciverInfo.email !== null) ||
      !notifyUserClick
    ) {
      setButtonError(false);
    } else {
      setButtonError(true);
    }
  }, [notifyUserClick, errorMsg,reciverInfo]);

  //function to handel payment close dialog
  const handelClosePaymentSucess = () => {
    setOpenPaymentSuccessDialog(false);
    history.push("/dashboard/giftcards");
  };
  CustomConsole("reciverInfo/.....")
  CustomConsole(reciverInfo)
  return (
    <>
      <Box
        style={{
          marginTop: "4rem",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          borderRadius: "1rem",
          height: "calc(100% - 3rem)", // Full viewport height
          overflow: "hidden", // Prevent overflow of the entire container
        }}
      >
        <Box
          sx={{
            borderRadius: "1rem",
            background: appColors.cardColor,
            margin: { sm: "1rem" },
            width: { xs: "100vw", md: "50vw", sm: "60vw" },
          }}
        >
          <Box>
            <PriceDetailsandNotification
              notifyUserClick={notifyUserClick}
              setNotifyUserClick={setNotifyUserClick}
              userPhone={userPhone}
              setUserPhone={setUserPhone}
              userEmailId={userEmailId}
              setUserEmailId={setUserEmailId}
              priceError={priceError}
              setPriceError={setPriceError}
              emailError={emailError}
              setEmailError={setEmailError}
              phoneError={phoneError}
              setPhoneError={setPhoneError}
              price={price}
              setPrice={setPrice}
              setPasswordRequired={setPasswordRequired}
              passwordRequired={passwordRequired}
              loadWalletFlag={loadWalletFlag}
              setLoadWalletFlag={setLoadWalletFlag}
              setReciverInfo={setReciverInfo}
              reciverInfo={reciverInfo}
              errorMsg={errorMsg}
              setErrorMsg={setErrorMsg}
            />
          </Box>
          <Box
            sx={{
              overflowY: "auto",
              padding: "0 0 2rem 0",
            }}
          >
            <SelectGreetingNotification
              setSelectedImage={setSelectedImage}
              selectedImage={selectedImage}
              selectedGreeting={selectedGreeting}
              setSelectedGreeting={setSelectedGreeting}
              wishes={wishes}
              setWishes={setWishes}
              sendGreetingFlag={sendGreetingFlag}
              setSendGreetingFlag={setSendGreetingFlag}
            />
          </Box>
        </Box>

        <Box
          sx={{
            bottom: 0,
            left: { xs: 0, sm: "50%" },
            background: { xs: appColors.checkOutCard, sm: "transparent" },
            width: { sm: "20rem", xs: "100%" },
            position: "fixed",
            borderRadius: "1rem",
            padding: ".5rem",
            textAlign: "center",
          }}
        >
          <Button
            variant="contained"
            size="medium"
            onClick={handelOpenPaymentDialog}
            disabled={buttonError}
            sx={{
              background: appColors.giftCardColor,
              borderRadius: "1rem",
              width: "100%",
            }}
          >
            Proceed Next
          </Button>
        </Box>
      </Box>
      <MyWallet
        myWalletInBooking={false}
        setpromoCode={setpromoCode}
        openVbDialog={openVbDialog}
        setOpenVbDialog={setOpenVbDialog}
        setpaymentFlag={setPaymentFlag}
        promoCode={promoCode}
        upiId={upiId}
        setrazorPayFlag={setrazorPayFlag}
        setUpiId={setUpiId}
        upiIdVPAObj={upiIdVPAObj}
        setUpiIdVPAObj={setUpiIdVPAObj}
        setVerifyUpiIdClicked={setVerifyUpiIdClicked}
        verifyUpiClicked={verifyUpiClicked}
        payment={payment}
        setPayment={setPayment}
        upiUserOption={upiUserOption}
        setUpiUserOption={setUpiUserOption}
        setUpiPayUserOption={setUpiPayUserOption}
        amountEntered={amountToPay}
        payType={payType}
        paymentTypeWallet={paymentTypeWallet}
        verifyBtn={verifyBtn}
        setVerifyBtn={setVerifyBtn}
        setPayType={setPayType}
        isUpiIdValid={isUpiIdValid}
        setIsUpiIdValid={setIsUpiIdValid}
        paymentFlag={paymentFlag}
        opean={opean}
        setOpean={setOpean}
        setMyWalletInBooking={setMyWalletInBooking}
        setAmountToAdd={setAmountToPay}
        setPayFor={setPayFor}
        setPayOptionCheck={setPayOptionCheck}
        choosenPartial={choosenPartial}
        setChoosenPartial={setChoosenPartial}
        orderId={orderId}
      />
      {bookingpaymentFlag && (
        <ExtraDepositPayDialog
          orderPaymentMethod={orderPaymentMethod}
          open={bookingpaymentFlag}
          setsubcribePaymentFlag={setBookingPaymentFlag}
          setPaymentType={setPaymentType}
          openVbDialog={openVbDialog}
          setOpenVbDialog={setOpenVbDialog}
          myWalletInBooking={false}
          setpaymentFlag={setPaymentFlag}
          upiId={upiId}
          setrazorPayFlag={setrazorPayFlag}
          setPerWeekPrice={amountToPay}
          setUpiId={setUpiId}
          upiIdVPAObj={upiIdVPAObj}
          setUpiIdVPAObj={setUpiIdVPAObj}
          setVerifyUpiIdClicked={setVerifyUpiIdClicked}
          verifyUpiClicked={verifyUpiClicked}
          payment={payment}
          setPayment={setPayment}
          upiUserOption={upiUserOption}
          setUpiUserOption={setUpiUserOption}
          setUpiPayUserOption={setUpiPayUserOption}
          amountEntered={amountToPay}
          setRazorPayAmount={amountToPay}
          payType={payType}
          paymentTypeWallet={paymentTypeWallet}
          verifyBtn={verifyBtn}
          setVerifyBtn={setVerifyBtn}
          setPayType={setPayType}
          isUpiIdValid={isUpiIdValid}
          setIsUpiIdValid={setIsUpiIdValid}
          paymentFlag={paymentFlag}
          opean={opean}
          setOpean={setOpean}
          setMyWalletInBooking={setMyWalletInBooking}
          amountToAdd={amountToPay}
          setAmountToAdd={setAmountToPay}
          setPayFor={setPayFor}
          setPayOptionCheck={setPayOptionCheck}
          choosenPartial={choosenPartial}
          setChoosenPartial={setChoosenPartial}
          perWeekPrice={amountToPay}
          setUpiPay={setUpiPay}
          pageName="giftcard"
          finalPrice={finalPrice}
          useWalletBalancePayFlag={useWalletBalancePayFlagforDeposit}
          setUseWalletBalancePayFlag={setUseWalletBalancePayFlagforDeposit}
          multiplePaymentFlag={multiplePaymentFlag}
          setpaymentTriggerFlag={setpaymentTriggerFlag}
          handelCreateGiftCard={handelCreateGiftCard}
        />
      )}
      {paymentSucess && (
        <DepositSucess
          open={paymentSucess}
          handelClose={handelClosePaymentSucess}
          heading={appMessages.giftCardHeading}
          subHeading={appMessages.giftCardSubHeading}
          image={giftCard}
        />
      )}{" "}
      <ToastMsgCotainer />
    </>
  );
}
