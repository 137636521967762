/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description
 * 
 */
import appColors from "../../Assets/AppTheme/appColors";



export const faqMainStyles = {
    textHeading: {
        fontSize: ".8rem",
        color: appColors.Textgray,
        fontWeight: "bold"
    },
    textHeadingFaq: {
        fontSize: "1rem",
        color: appColors.textColorNew,
        fontWeight: "bold",
        marginTop: ".5rem"
    }
}


export const FaqHeaderContentStyles = {
    mainBox: { display: "flex", flexDirection: "column", gap: "1rem", },
    boxContent: { borderRadius: "10px", padding: "1rem", boxShadow: 5, gap: "1rem", backgroundColor: appColors.homePageCard, display: "flex", flexDirection: "row", alignItems: "center" },
    boxInnerContent: { display: "flex", flexDirection: "column", justifyContent: "center" },
    BoxInnerText: { fontSize: "1rem" },
    BoxInnerText1: { fontSize: ".8rem",color:appColors.darkGray }
}