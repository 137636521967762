import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  returnCanOtpData: [],
  damageCansData: [],
  damageAcceptReject: [],
};

const ReturnCansReducer = (state = initialState, action) => {
  // console.log("-------User Details--------");
  // console.log(action);
  switch (action.type) {
    case actionType.RETURN_CANS_REQUEST:
      return {
        ...state,
        error: action.payload.data.error,
        msg: action.payload.data.msg,
      };

    case actionType.RETURN_CANS_OTP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        returnCanOtpData: action.payload.data,
      };
    case actionType.DAMAGE_CANS_DATA:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        damageCansData: action.payload.data,
      };
    case actionType.DAMAGE_CANS_ACCEPT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        damageAcceptReject: action.payload.data,
      };
    default:
      return state;
  }
};

export default ReturnCansReducer;
