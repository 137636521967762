/**
 *  @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Tahir Shaik
 * @description Custom Button Field (Buuton Field)
 * @date 026/06/23
 * @version 0.0.1 - Initial Release
 */

// Importing the Libraries and other Components
import { Button } from "@mui/material";
import React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";

/**
 * @author Tahir Shaik
 * @description Custom Password Text Field (Inputs- password)
 * @date 01/06/23
 * @version 0.0.1 - Initial Release
 * @param { lable, type, setValue, value,isValid, setIsValid, isRequired, isHintRequired, isStrongPasswordRequired}
 * @returns {UI or Authetication Page with LogIn & SignUp Components}
 */
function OutlinedButton(props) {
  return (
    <Button
      variant="outlined"
      sx={styles.btnStyle}
      onClick={props.handleButtonClick}
    >
      {props.btnName}
    </Button>
  );
}

export default OutlinedButton;
const styles = {
  btnStyle: {
    borderColor: appColors.white,
    borderWidth: "3px",
    color: appColors.white,
    height: "40px",
    width: "150px",
    borderRadius: "0px 10px 0px 10px",
    textTransform: "none",
    marginBottom:"1rem",
    fontWeight: "bold",
    fontSize: appTheme.buttonTextSize,
    "&:hover": { background: "none", borderColor: appColors.white, borderWidth: "3px" },
  },
};
