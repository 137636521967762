/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import appColors from "../../../../Assets/AppTheme/appColors";
import infoIcon from "../../../../Assets/newMobileIcon/Booking/info-circle-white.svg";
import { Box, Typography } from "@mui/material";
import moment from "moment";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function InstaDeliveryDialog({
  setOpenInstaAlertDialog,
  openInstaAlertDialog,
  setDeliveryType,
  openOtpFlag,
  setOpenOtpFlag,
  handleDeliveryTypeTommorow,
  setcalenderOpen,
  setCurrentDate,
  extraCharges,
}) {
  const handleClose = () => {
    setOpenInstaAlertDialog(false);
    setcalenderOpen(false);
  };

  const handelYesProceed = () => {
    setOpenInstaAlertDialog(false);
    setcalenderOpen(false);
    setCurrentDate(moment().format("YYYY-MM-DD"));
  };
  const handleClickDeliveryItTomorrow = () => {
    setOpenInstaAlertDialog(false);
  };
  return (
    <div>
      <Dialog
        open={openInstaAlertDialog}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: appColors.textColorNew }}>
          <DialogContentText
            id="alert-dialog-slide-description"
            sx={{ color: appColors.white }}
          >
            <Box
              sx={{ display: "flex", justifyContent: "center", margin: "1rem" }}
            >
              <img
                src={infoIcon}
                alt=""
                style={{ height: "80px", width: "80px" }}
              />
            </Box>
            <Typography
              sx={{
                color: "#fff",
                margin: ".5rem",
                fontSize: "1.3rem",
                display: "flex",
                // flexWrap: "wrap",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              Same Day Delivery?
            </Typography>
            <Typography
              sx={{
                color: "#fff",
                margin: ".5rem",
                fontSize: ".9rem",
                display: "flex",
                // flexWrap: "wrap",
                textAlign: "center",
                justifyContent: "center",
              }}
            >
              You are opting for same day delivery. You will be charged{" "}₹
              {extraCharges > 0 ? extraCharges : 15}{" "}
              per product. Are you sure you want to continue?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: appColors.textColorNew,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={handleClickDeliveryItTomorrow}
            variant="contained"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
              textTransform: "capitalize",
            }}
          >
            Show Calender
          </Button>
          <Button
            onClick={handelYesProceed}
            variant="contained"
            sx={{
              color: appColors.white,
              backgroundColor: appColors.textColorNew,
              textTransform: "capitalize",
            }}
          >
            Yes, Proceed
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
