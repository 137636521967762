/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import appColors from "../../../../Assets/AppTheme/appColors";

export const styles = {
  addressBookMain: {
    padding: "10px",
    marginBottom:{lg:0,md:0,sm:"4rem",xs:"4rem"},
    // backgroundColor: "gray",
    display:"flex",
    alignItems:"stretch",
    flexDirection:{md:"row",xs:"column-reverse"}
  },
  addressBox: {
    display: "flex",
    width:"100vw",
    flexDirection: {lg:"row",md:"column",sm:"column",xs:"column"},
    alignItems: "center",
    gap:2,
    padding: "20px",
    justifyContent:"space-around",
    maxHeight: "100rem",
    overflowY: "auto",
    overflowX: "hidden",
    borderRadius: "20px", 
    backgroundColor:appColors.white
  },
  header: {
    display: "flex",
    width: "16rem",
    height:"10rem" ,
    margin: "6px 22px 0 0",
    border: "solid 1px #707070",
    marginBottom:"10px",
    justifyContent:  "space-around" ,
    backgroundColor: appColors.cardColor,
  },
  headerPapper: {
    display: "flex",
    marginBottom:"10px",
    gap:1,
    flexWrap:"wrap",
    justifyContent: { xs: "center", md: "center",sm:"center" },
    alignItems:"center",
    flexDirection: { xs: "column", md: "row" },
  },
  modalHeader: {
    display: "flex",
    backgroundColor: "#070b30",
    color: "#fff",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "10px",
  },
  modalTxt: {
    marginTop: "12px",
    fontSize:"12px",
    width: "100%",

  },
  btn: {
    backgroundColor: appColors.textColorNew,
    width: "40%",
    marginTop: "10px",
    "&:hover": {
      backgroundColor:appColors.textColorNew ,
    },
  },
  addBtn: {
    textTransform: "none",
    backgroundColor: "inherit",
    color: appColors.darkGray,
    textAlign: "center",
    width: "200px",
    padding: "10px",
  },
  addBtnAddress: {
    textTransform: "none",
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    textAlign: "center",
    padding: "5px",
    width:{xs:"90%",sm:"300px"}
  },
  helptext: {
    color: appColors.darkBlue,
    fontSize: "12px",
    fontWeight: "bolder",
    marginLeft: "20%",
  },
};
