// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: appBar.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import { Box, Button, Grid, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useDispatch, useSelector } from "react-redux";
import BookWaterLog from "../../Assets/AppIcons/BookWaterLogo.svg";
import actionTypes from "./../../Redux/actions/actionTypes";
import WatterWallet from "./../../Assets/newMobileIcon/Water Wallet.svg";
import { AppBarStyle } from "./MobileViewcss";
import appColors from "../../Assets/AppTheme/appColors";
import LogoutConfirmationDialog from "../../Components/DialogBoxes/Authentication/logoutDialog";
import FeatureDiscoveryPrompt from "../FeatureDiscoveryPrompt/FeatureDiscoveryPrompt";
import IconListManager from "../../Assets/AppTheme/appIcons";
import waterDropImag from "../../Assets/newMobileIcon/waterDrop.svg";
import { GetWaterDropsUser } from "../../Redux/actions/referAndEarnAction";
import appTheme from "../../Assets/AppTheme/appTheme";
export default function AppBarMobile({
  setOtpFlag,
  otpFlag,
  promptStates,
  setPromptStates,
  setchooseAddressDialog,
  setPrimaryAddress,
  setAddress,
  primaryAddress,
}) {
  const IconList = IconListManager();
  const address = useSelector((state) => state.settings);
  const [isLogOut, setIsLogOut] = useState(false);
  const [logOutDialogOpen, setlogOutDialogOpen] = useState(false);
  const waterDrops = useSelector((state) => state.referAndEarnDetails);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const themeState = useSelector((state) => state.darkThemeState.themeState);
  const SkuDetails = useSelector((state) => state.skuBookDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  const [truncatedAddress, settruncatedAddress] = React.useState("");
  const AddressFlag = address?.addresses.length > 0;

  React.useEffect(() => {
    if (AddressFlag) {
      const maxLength = window.innerWidth / 13; // Set your desired maximum length

      let truncatedAddress =
        primaryAddress?.contact_name +
        " " +
        primaryAddress?.address_line1 +
        " " +
        primaryAddress?.service_area +
        " " +
        primaryAddress?.pincode;

      if (
        truncatedAddress !== undefined &&
        truncatedAddress.length > maxLength
      ) {
        settruncatedAddress(truncatedAddress);
      } else {
        settruncatedAddress(truncatedAddress);
      }
    }
  }, [primaryAddress]);

  if (isLogOut) {
    return <Redirect to="authenticate" />;
  }

  //Function for handling logout dialog opening
  const handleOpenDialog = () => {
    setlogOutDialogOpen(true);
  };

  //Function for handling logout dialog closing
  const handleCloseDialog = () => {
    setlogOutDialogOpen(false);
  };

  // Implement your logout logic here
  const handleConfirmLogout = () => {
    dispatch({
      type: actionTypes.SIGN_OUT,
    });
    setIsLogOut(true);
  };

  //function for converting 1000 and above to 1k
  const NumberFormatter = ({ value }) => {
    const formatNumber = (num) => {
      if (num >= 1000) {
        return (num / 1000).toFixed(2) + "k";
      } else {
        return num.toString();
      }
    };
    return <span>{formatNumber(value)}</span>;
  };

  return (
    <>
      <AppBar
        sx={{
          ...AppBarStyle.appBarMain,
          position: "fixed", // Fixed position if scrolled, else relative
        }}
      >
        <Box
          sx={{
            alignItems: "space-between",
            justifyContent: "flex-start",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0 1rem",
            }}
          >
            <img
              src={
                themeState
                  ? BookWaterLog
                  : process.env.PUBLIC_URL + "/bwLogo2.png"
              }
              alt=""
              style={AppBarStyle.bookWaterLogo}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <FeatureDiscoveryPrompt
                setmenuDialogOpean=""
                setPromptStates={setPromptStates}
                open={
                  promptStates?.waterdrop !== undefined
                    ? promptStates.waterdrop
                    : false
                }
                backgroundColor={appColors.textColorNew}
                componentState="waterdrop"
                triggerState="referAndEarn"
                title="Water Drops"
                description="Collect your rewards through references and promotions as water drops."
                subdescription="Water drops can be redeemed when you order."
              >
                <Box
                  onClick={() =>
                    history.push("/dashboard/referandearn?pageValue=1")
                  }
                >
                  <Button
                    sx={{
                      backgroundColor: appColors.cardColor,

                      borderRadius: "10px", // Set the border radius to 0 (no rounding)
                      marginLeft: "10px",
                      height: "2rem",

                      fontSize: ".8rem",
                    }}
                    size="small"
                    variant="contained"
                  >
                    <img
                      alt=""
                      style={{
                        width: "2rempx",
                        height: "1.5rem",
                      }}
                      src={waterDropImag}
                    />{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        color: appColors.darkGray,
                      }}
                    >
                      <NumberFormatter
                        value={
                          SkuDetails?.productDetails?.water_drop_details
                            ?.water_drops !== undefined &&
                          SkuDetails?.productDetails?.water_drop_details
                            ?.water_drops !== null
                            ? SkuDetails?.productDetails?.water_drop_details
                                ?.water_drops
                            : waterDrops?.allData?.water_drops !== undefined
                            ? waterDrops.allData.water_drops
                            : 0
                        }
                      />
                    </span>
                  </Button>
                </Box>
              </FeatureDiscoveryPrompt>
              <FeatureDiscoveryPrompt
                setmenuDialogOpean=""
                setPromptStates={setPromptStates}
                open={
                  promptStates?.wallet !== undefined
                    ? promptStates.wallet
                    : false
                }
                backgroundColor={appColors.textColorNew}
                componentState="wallet"
                triggerState="waterdrop"
                title="Water Wallet"
                description="Fed-up with inconvenient cash transactions?"
                subdescription="Recharge Your wallet and enjoy hassle-free Bookings with just ONE click."
              >
                <Box className="tourWallet">
                  <Button
                    sx={{
                      backgroundColor: appColors.cardColor,
                      borderRadius: "10px", // Set the border radius to 0 (no rounding)
                      marginLeft: "10px",
                      height: "2rem",

                      fontSize: ".8rem",
                    }}
                    size="small"
                    variant="contained"
                    onClick={() => history.push("/dashboard/wallet")}
                  >
                    <img
                      alt=""
                      style={{
                        width: "2rem",
                        height: "1.5rem",
                      }}
                      src={WatterWallet}
                    />{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        marginLeft: "5px",
                        color: appColors.darkGray,
                      }}
                    >
                      ₹
                      <NumberFormatter
                        value={
                          SkuDetails?.productDetails?.water_wallet_balance !==
                            undefined &&
                          SkuDetails?.productDetails?.water_wallet_balance !==
                            null
                            ? parseInt(
                                SkuDetails?.productDetails?.water_wallet_balance
                              )
                            : walletDetails.walletAmount === undefined
                            ? 0
                            : parseInt(walletDetails.walletAmount)
                        }
                      />
                    </span>
                  </Button>
                </Box>
              </FeatureDiscoveryPrompt>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              color: appColors.darkGray,
              padding: ".3rem 1rem",
              bgcolor: appColors.appBrColor,
            }}
          >
            {" "}
            <FeatureDiscoveryPrompt
              setmenuDialogOpean=""
              setPromptStates={setPromptStates}
              open={
                promptStates?.address !== undefined
                  ? promptStates.address
                  : false
              }
              backgroundColor={appColors.textColorNew}
              componentState="address"
              triggerState="products"
              title="Add Address"
              description="Want us to deliver to more than one location in the city? 
              Sure."
              subdescription="Add multiple delivery addresses in your profile and choose the location you would like us to deliver."
            >
              <Box sx={{ fontSize: ".8rem" }}>
                {AddressFlag ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => setchooseAddressDialog(true)}
                  >
                    <Box sx={{ width: "95%" }}>
                      <Typography
                        sx={{
                          color: appColors.Textgray,
                          fontSize: appTheme.fontSizeSmall,
                          color: appColors.darkGray,
                          fontWeight: "bold",
                          width: "100%" /* Set the width to 80% */,
                          whiteSpace: "nowrap" /* Prevent wrapping */,
                          overflow: "hidden" /* Hide overflowing text */,
                          textOverflow:
                            "ellipsis" /* Display ellipsis when text overflows */,
                        }}
                      >
                        {" "}
                        <span>
                          {" "}
                          <img
                            src={IconList.locationIcon}
                            alt=""
                            style={AppBarStyle.AddressStyle}
                          />
                        </span>{" "}
                        &nbsp; {primaryAddress?.address_type} &nbsp;
                        {truncatedAddress}
                      </Typography>
                    </Box>
                    <KeyboardArrowDownIcon
                      sx={{ color: appColors.textColorNew }}
                    />
                  </Box>
                ) : (
                  <Box
                    onClick={() => setchooseAddressDialog(true)}
                    sx={{
                      color: appColors.headerTextColor,
                      fontWeight: "bold",
                      fontSize:appTheme.fontSizeMedium
                    }}
                  >
                    {" "}
                    <span>
                      {" "}
                      <img
                        src={IconList.locationIcon}
                        alt=""
                        style={AppBarStyle.AddressStyle}
                      />
                      &nbsp;
                    </span>  &nbsp;
                    Add Address +
                  </Box>
                )}
              </Box>
            </FeatureDiscoveryPrompt>
          </Box>
        </Box>
      </AppBar>
      <LogoutConfirmationDialog
        open={logOutDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmLogout}
      />
    </>
  );
}
