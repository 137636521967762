import { Box, Typography } from "@mui/material";
import React, { useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import "../../Authentication/OtpInput.css";
import authStyles from "./../../../Pages/Authentication/authenticationStyles";
import Alert from "@mui/material/Alert";
import PrimaryButton from "../../Buttons/PrimaryButton";
import { useDispatch, useSelector } from "react-redux";
import { logIn, verifyPhone } from "../../../Redux/actions/ProfileActions";
import OutlinedButton from "../../Buttons/OutlinedButton";
import appColors from "../../../Assets/AppTheme/appColors";
import { toast } from "react-toastify";
import { Navigate, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import OtpInput from "react-otp-input";
import { resendOtp } from "../../../Redux/actions/authAction";
import "./Otpstyles.css";
// Showing msg Whether delivery is available and verify OTP to sign-up

const mainContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: "10px",
};
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const AvailablityDialog = (props) => {
  const [otp, setOtp] = React.useState("");
  // const [referralCode, setReferralCode] = React.useState(props.consumerReferal);
  const [isLogin, setIsLogin] = React.useState(false);
  const [verifyBtnFlag, setVerifyBtnFlag] = React.useState(false);
  const inputs = useRef([]);
  const timerStart = 30; //Seconds
  const [emailCounter, setEmailCounter] = React.useState(timerStart);
  const authenticationDetails = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const authentications = useSelector((state) => state.authReducer);
  const handleClose = () => {
    props.setOpenDialogConsumerVerify(false);
    inputs.current.forEach((input) => {
      input.value = ""; // Assuming the input fields are controlled using refs
    });
    setOtp("");
  };

  const handleClickVerify = () => {
   
    if (otp.length === 6) {
      dispatch(
        verifyPhone(
          otp,
          authentications.referalError === false ? props.consumerReferal : ""
        )
      );
      setVerifyBtnFlag(true);
    } else {
      toast.dark("Please enter proper OTP", {
        position: "bottom-right",
        autoClose: 1500,
        type: "error",
      });
    }
  };

  //For controlling Timer
  React.useEffect(() => {
    const timer =
      emailCounter > 0 &&
      setInterval(() => setEmailCounter(emailCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [emailCounter]);

  //Resend E-Mail OTP
  const handleEmailResend = () => {
    setEmailCounter(timerStart);
    dispatch(resendOtp());
  };
  // console.log(window)
  //   //For Autofilling otp for SignUpOtp password
  //   React.useEffect(() => {
  //     if ("OTPCredential" in window) {
  //       const ac = new AbortController();

  //       navigator.credentials
  //         .get({
  //           otp: { transport: ["sms"] },
  //           signal: ac.signal
  //         })
  //         .then((otpCredential) => {
  //           setOtp(otpCredential.code);
  //           ac.abort();
  //         })
  //         .catch((err) => {
  //           ac.abort();
  //           console.log(err);
  //         });
  //     }
  //   }, [emailCounter]);

  React.useEffect(() => {
   
    if (authentications.signUpVerifyerror === false && verifyBtnFlag) {
      dispatch(
        logIn({
          email_phone: props.values.phone,
          password: props.values.password,
        })
      );
      setVerifyBtnFlag(false);
      sessionStorage.removeItem("signup_jwt");
      handleClose();
      setIsLogin(true);
    }
  }, [authentications]);

  React.useEffect(() => {

    if (isLogin) {
      // navigate("/dashboard/home");
      // <Redirect to="/dashboard/home" />
      // setIsLogin(false);
    }
  }, [isLogin]);
  if (isLogin) {
    return <Redirect to="/dashboard/home" />;
  }
  return (
    <Box>
      <Dialog
        open={props.openDialogConsumerVerify}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            // backgroundColor: appColors.secondaryColor, // Set the background color for the dialog
            borderRadius: "20px",
          },
        }}
      >
        <DialogContent>
          <Box sx={mainContainer}>
            <Typography sx={authStyles.typeHeader}>
              Phone number verification
            </Typography>
            <Box>
              {/*  Email OTP Resent Button */}
              <Button
                disabled={emailCounter !== 0}
                sx={{
                  color: "blue",
                  fontWeight: "bold",
                  textTransform: "none",
                  "&:hover": { color: "white" },
                }}
                onClick={handleEmailResend}
              >
                {/* Resend OTP in */}
                {/* E-Mail Resent OTP Timer */}
                {emailCounter === 0 ? (
                  <span style={{ color: "#0CD8BE" }}>Resend OTP</span>
                ) : (
                  <span
                    style={{
                      color: "#0CD8BE",
                      fontWeight: "bold",
                      marginLeft: "5px",
                    }}
                  >
                    Please Wait 00:{emailCounter} Seconds to Regenerate OTP
                  </span>
                )}
              </Button>
            </Box>
            <Box className="otp-container">
              <OtpInput
                value={otp}
                onChange={(otp) => {
                  // Filter out non-numeric characters
                  const numericOtp = otp.replace(/\D/g, "");
                  setOtp(numericOtp);
                }}
                inputStyle="inputStyle"
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
              />
            </Box>
            <Box sx={authStyles.closeButton}>
              <Button sx={authStyles.btnStyle} onClick={handleClickVerify}>
                Verify
              </Button>
              <Button sx={authStyles.btnStyle} onClick={handleClose}>
                Close
              </Button>
            </Box>
            {props.isAreaAvailable === false && (
              <Alert severity="warning">
                BookWater does not deliver to your location however do complete
                the signup process and we will inform when services commence in
                your area.
              </Alert>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};
