/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import appColors from "../../Assets/AppTheme/appColors";
import * as Material from "@mui/material";
import orderSucess from "../../Assets/newMobileIcon/Booking/Can Order successful.svg";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Material.Slide direction="up" ref={ref} {...props} />;
});

export default function UpiFailedDialog(props) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageTitle = pathname.split("/dashboard/");
  const pageName = new URLSearchParams(search).get("pageName");
  const handleClose = () => {
    props.setOpenDepositSucessDialog(false);
    if (pageName && pageName === "booking") {
      history.push("/dashboard/myorders");
    } else {
      if (window.innerWidth > 600) {
        history.push("/dashboard/home");
      } else {
        history.push("/mobile");
      }
    }
  };

  return (
    <Material.Dialog
      open={props.open}
      TransitionComponent={Transition}
      keepMounted
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Material.DialogContent sx={{ backgroundColor: appColors.secondaryCard }}>
        <Material.Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img alt="" src={orderSucess} />
          <Material.Typography
            sx={{
              fontSize: "16px",
              color: appColors.darkGray,
              fontWeight: "bold",
              marginTop: "1rem",
              textAlign: "center",
            }}
          >
            Payment Failed.
          </Material.Typography>
          <Material.Typography
            sx={{
              fontSize: "14px",
              color: appColors.darkGray,
              fontWeight: "500",
              marginTop: ".9rem",
              textAlign: "center",
            }}
          >
            Your payment has failed. Please try again.
          </Material.Typography>
          <Material.Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Material.Button
              variant="contained"
              sx={{
                marginTop: "15px",
                height: "30px",
                backgroundColor: appColors.textColorNew,
                color: "white",
              }}
              onClick={handleClose}
            >
              Close
            </Material.Button>
          </Material.Box>
        </Material.Box>
      </Material.DialogContent>
    </Material.Dialog>
  );
}
