/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react"; 
import actionType from "../actions/actionTypes";

const initialProfileState = { 
  error: true, 
  waterQuality: "",
  msg:""
};


const qrQualityReportReducer = (state = initialProfileState, action) => {
  switch (action.type) {
    case actionType.GET_QR_REPORT_QUALITY:
      return {
        ...initialProfileState,
        error: action.payload.error,
        msg:action.payload.msg,
        waterQuality:action.payload.data
      };
    default:
      return state;
  }
};

export default qrQualityReportReducer;
