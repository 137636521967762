/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import * as Material from "@mui/material";
function NotFound() {
  return (
    <div>
      <Material.Toolbar />
      <h1> Page Not Found (404)</h1>
    </div>
  );
}

export default NotFound;
