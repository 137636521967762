/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import { styled } from "@mui/material/styles";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { toggleThemeState } from "./Redux/actions/appMetaDataAction";
import { useDispatch, useSelector } from "react-redux";
import appColors from "./Assets/AppTheme/appColors";
import { Box } from "@mui/material";
import appTheme from "./Assets/AppTheme/appTheme";

//mui styles for button
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 50,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 25,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(20px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 26,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export default function DarkLightThemeSwitch() {
  const dispatch = useDispatch();
  const themeState = useSelector((state) => state.darkThemeState.themeState);
  //Getting theme status from local storage
  const selectedTheme = localStorage.getItem("selectedTheme");

  // console.log(selectedTheme === "dark");
  //Useeffect for identifing theme status while reloding a page
  React.useEffect(() => {
    if (selectedTheme === "dark") {
      // console.log("i am hitted dark mode");
      setDarkMode();
      dispatch(toggleThemeState(true));
    } else {
      // console.log("i am hitted");
      setLightMode();
      dispatch(toggleThemeState(false));
    }
  }, []);

  //Function for setting up a darkmode
  const setDarkMode = () => {
    document.querySelector("body").setAttribute("dark-theme", "dark");
    localStorage.setItem("selectedTheme", "dark");
  };
  //Function for setting up a lightmode
  const setLightMode = () => {
    document.querySelector("body").setAttribute("dark-theme", "light");
    localStorage.setItem("selectedTheme", "light");
  };

  //Toggle function for changing a theme
  const toggleTheme = (e) => {
    if (e.target.checked) {
      // console.log("toggle function");
      setDarkMode();
      dispatch(toggleThemeState(true));
    } else {
      setLightMode();
      dispatch(toggleThemeState(false));
    }
  };

  return (
    <FormGroup onChange={toggleTheme}>
      <Box direction="column" sx={{ marginLeft: ".5rem" }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <AntSwitch
            defaultChecked={selectedTheme === "dark" ? true : false}
            inputProps={{ "aria-label": "ant design" }}
          />
        </Box>
        <Typography sx={{ color: appColors.darkGray, fontSize:appTheme.fontSizeMedium }}>
          {themeState ? "Switch to light" : "Switch to dark"}
        </Typography>
      </Box>
    </FormGroup>
  );
}
