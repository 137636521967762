/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import appColors from "../../../Assets/AppTheme/appColors";
import infoIcon from "../../../Assets/ordersPage/Pending - Selected Menu.svg";
import closeIcon from "../../../Assets/newMobileIcon/Booking/closeIconWhite.svg";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { CancelOrder } from "../../../Redux/actions/ordersAction";
import { useDispatch } from "react-redux";
import { Box, Typography } from "@mui/material";
import { AllOrderOtpPaymentDetails } from "../../../Redux/actions/recentActivityAction";
import { ClearPendingOrderData } from "../../../Redux/actions/checkOutAction";

export default function PendingOrderDialogBox({
  open,
  handleClose,
  paymentPendingDetails,
  setDialogClose,
}) {
  const history = useHistory();
  const { pathname, search } = useLocation();
  const pageTitleSideBar = pathname.split("/dashboard/");
  const dispatch = useDispatch();
  const [orderCancelMsg, setOrderCancelMsg] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  //Function for handling Payment Button
  const handelPayOrder = () => {
    if (pageTitleSideBar[1] === "myorders") {
      handleClose(paymentPendingDetails);
    } else {
      history.push(`/dashboard/myorders?orderId=${paymentPendingDetails}`);
    }
  };
  
  //Function for handling cancel order Option
  const handleCancelYes = () => {
    const reqObj = {
      order_id: paymentPendingDetails,
      reason: "Order Cancel By consumer",
    };
    dispatch(CancelOrder(reqObj));
    setOrderCancelMsg(true);
    setLoading(true);
    dispatch(ClearPendingOrderData());
    setTimeout(() => {
   
      setLoading(false);
      setDialogClose(false);
      // window.location.reload();
    }, 2000);
  };

  //Closing Dialog Box
  const handleClosePendingDialog = () => {
    setDialogClose(false);
    dispatch(ClearPendingOrderData());
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        // onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: appColors.infoCardColor,
            color: appColors.white,
            flexDirection: "column",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              bgcolor: "transparent",
            }}
          >
            <img
              src={infoIcon}
              alt=""
              style={{ height: "4rem", width: "4rem" }}
            />
          </Box>
          Payment Pending Order
        </DialogTitle>
        <DialogContent
          sx={{ bgcolor: appColors.infoCardColor, color: appColors.darkGray }}
        >
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: appColors.white }}
          >
            <Typography>There is already an order pending payment.</Typography>
            <Typography>
              Do you wish to pay for the previous order or cancel the previous
              order?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions
          sx={{
            bgcolor: appColors.infoCardColor,
            display: "flex",
            justifyContent: "space-evenly",
          }}
        >
          <Button
            onClick={handelPayOrder}
            sx={{ bgcolor: appColors.textColorNew }}
            size="small"
            variant="contained"
          >
            View & Pay
          </Button>
          <Button
            onClick={handleCancelYes}
            sx={{ bgcolor: appColors.textColorNew }}
            size="small"
            variant="contained"
          >
            Cancel Order
          </Button>
        </DialogActions>
        <Box
          sx={{
            position: "absolute",
            right: 0,
            top: 0,
            // bgcolor: appColors.white,
          }}
        >
          <img
            alt=""
            src={closeIcon}
            style={{
              cursor: "pointer",
              height: "30px",
            }}
            onClick={handleClosePendingDialog}
          />
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
