/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useState, useEffect } from "react";
import appColors from "../../../Assets/AppTheme/appColors";
import appTheme from "../../../Assets/AppTheme/appTheme";
import { useSelector } from "react-redux";
import useProductImages from "../../../Components/Utils/productImages";
import CrateSelectionCard from "../../../Components/Cards/SkuCards/CrateSelectionCard";
import ImageLoader from "../../../Components/Utils/imageLoader";
export default function SelectGreetingNotification({
  setSelectedImage,
  selectedImage,
  selectedGreeting,
  setSelectedGreeting,
  wishes,
  setWishes,
  sendGreetingFlag,
  setSendGreetingFlag,
}) {
  const giftCardDetails = useSelector(
    (store) => store.GiftCardDetails.giftCardSetting
  );
  const [greetingImages, setGreetingImages] = useState([]);

  // coustom hook to get gift card images
  const productImages = useProductImages(greetingImages, "giftcard");
  //function to handel buy gift card back button
  const handelNotifyUser = (e) => {
    setSendGreetingFlag(e.target.checked);
  };

  //useEffect to get gift card details
  useEffect(() => {
    console.log("giftCardDetails");
    console.log(giftCardDetails);
  }, []);
  console.log(sendGreetingFlag);

  //useEffect set gift card details
  useEffect(() => {
    if (giftCardDetails?.greetings?.length) {
      const imageFiles = giftCardDetails.greetings.flatMap(
        (data) => data.images
      );
      setGreetingImages(imageFiles);
      setSelectedImage(imageFiles[0]);
      setSelectedGreeting(giftCardDetails.greetings[0]);
    }
  }, [giftCardDetails]);

  //useEffect set gift card details
  useEffect(() => {
    if (greetingImages?.length) {
    }
  }, [greetingImages]);

  //function to select selected category
  const handelSelectedCategorey = (data) => {
    setSelectedGreeting(data);
    setSelectedImage(data?.images[0]);
  };
  console.log(greetingImages);

  //function to handel wished change
  const handelWishesChange = (e) => {
    const value = e.target.value === "" ? null : e.target.value;
    setWishes(value);
  };
  return (
    <>
      <Box sx={selectGreetingStyles.mainContent}>
        <Box sx={selectGreetingStyles.greetingTextBox}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendGreetingFlag}
                onChange={handelNotifyUser}
                sx={{
                  color: appColors.darkGray, // Default color
                  "&.Mui-checked": {
                    color: appColors.darkGray, // Color when checked
                  },
                }}
              />
            }
            label=<span style={{ color: appColors.darkGray }}>
              Send Greeting
            </span>
          />
        </Box>
        {sendGreetingFlag && (
          <>
            <Box sx={selectGreetingStyles.sendGreetingBox}>
              {giftCardDetails?.greetings?.map((data, index) => (
                <Card
                  sx={{
                    padding: ".5rem",
                    // width: { xs: "100%", sm: "11rem" },
                    borderRadius: appTheme.fontSizeLarge,
                    boxShadow: 2,
                    color:
                      selectedGreeting?.category === data?.category
                        ? appColors.textColorNew
                        : appColors.headerTextColor,
                    bgcolor:
                      selectedGreeting?.category === data?.category
                        ? appColors.lightblue
                        : appColors.cardColor,
                    cursor: "pointer",
                    fontSize: "0.75rem",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  onClick={() => handelSelectedCategorey(data)}
                >
                  <span> {data?.category}</span>
                </Card>
              ))}
            </Box>
            <Box sx={selectGreetingStyles.enterWishesStyles}>
              <Typography sx={selectGreetingStyles.typoStyles}>
                Enter wishes , which you need to share
              </Typography>

              <textarea
                style={selectGreetingStyles.textAreaStyles}
                placeholder="Enter Wishes"
                value={wishes}
                text="text"
                onChange={handelWishesChange}
              ></textarea>
            </Box>
            <Box
              sx={{ borderBottom: "2px dashed gray", margin: ".5rem" }}
            ></Box>
            <Box sx={{ marginBottom: "1rem" }}>
              <Card sx={selectGreetingStyles.imageLoaderBox}>
                <ImageLoader
                  filename={selectedImage}
                  styles={{
                    // Allow the height to adjust according to the aspect ratio
                    width: "20rem",
                    height: "10rem",
                  }}
                />
              </Card>
              <Box sx={{ width: { xs: "90vw", sm: "50vw", md: "48vw" } }}>
                <CrateSelectionCard
                  selectedGreeting={selectedGreeting}
                  setSelectedImage={setSelectedImage}
                  selectedImage={selectedImage}
                />
              </Box>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}

const selectGreetingStyles = {
  mainContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "0 .5rem",
    gap:1
  },
  greetingTextBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  sendGreetingBox: {
    width: "100%",
    padding: "0 .4rem",
    display: "flex",
    flexDirection: "row",
    gap:1,
    flexWrap: "wrap",
    justifyContent: "space-evenly",
  },
  enterWishesStyles: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    margin: "0rem 0 1rem 0",
  },
  typoStyles: {
    fontSize: appTheme.fontSizeLarge,
    color: appColors.darkGray,
    fontWeight: "600",
  },
  textAreaStyles: {
    width: "100%",
    height: "auto",
    boxSizing: " border-box",
    border: "2px solid #ccc",
    borderRadius: "1rem",
    backgroundColor: "#f8f8f8",
    fontSize: ".9rem",
    padding: ".2rem",
  },
  imageLoaderBox: {
    borderRadius: "1rem",
    width: "18rem",
    height: "10rem",
    boxShadow: 5,
  },
};
