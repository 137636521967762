/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import { Box, Button, Typography } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { styles } from "./BilldialogStyles";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdateEmail,
  VerifyEmail,
} from "../../../Redux/actions/settingsAction";
import { toast } from "react-toastify";
import { GetUserProfile } from "../../../Redux/actions/profileAction";

import ForgetPasswordStepper from "../../Authentication/ForgetPasswordStepper";
import appColors from "../../../Assets/AppTheme/appColors";
import OTPInput from "react-otp-input";

const timerStart = 30;
export default function ChangeEmailDialog({
  openEmailDetails,
  setOpenEmailDetails,
}) {
  const dispatch = useDispatch();
  const inputs = useRef([]);
  const [emailCounter, setEmailCounter] = React.useState(timerStart);
  const [otp, setOtp] = React.useState("");
  const [emailVerify, setEmailVerify] = React.useState(false);
  const [eloading, setPLoading] = React.useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = React.useState("");
  const profile = useSelector((state) => state.userProfile);
  const [open, setOpen] = React.useState(false);
  const updateStatus = useSelector((state) => state.settings);
  const [value, setValue] = React.useState({
    email: profile.user.email,
    error: "",
  });
  const handleClose = () => {
    setOpenEmailDetails(false);
    setEmailVerify(false)
    setOpen(false)
    setEmailCounter(30)
  };

  const handleCloseOtp = () => {
    setOpen(false);
  };
  const handleCloseafterVerify = () => {
    setOpenEmailDetails(false);
    setOpen(false);
    setEmailVerify(false);
  };
  React.useEffect(() => {
    // console.log(`updateStatus: ${JSON.stringify(updateStatus)}`);
    if (!updateStatus.emailError) {
      // updateStatus.error = true;

      //open popUp and Verify OTP
      handleClickOpen();
    }
    if (updateStatus.isEmailVerified) {
      updateStatus.isEmailVerified = false;
      setEmailVerify(true);
      dispatch(GetUserProfile());
    }
  }, [updateStatus]);

  React.useEffect(() => {
    const timer =
      emailCounter > 0 &&
      setInterval(() => setEmailCounter(emailCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [emailCounter]);

  //Open Dialog Box
  const handleClickOpen = () => {
    setOpen(true);
  };

  const onSubmit = () => {
    const emailRegEx = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
    if (emailRegEx.test(value.email)) {
      setEmailCounter(timerStart);
      //   console.log("API Call Validation Success");
      dispatch(UpdateEmail(value.email, profile.user.email));
    } else {
      toast.dark("Please Enter Proper Email", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  };

  const handleChange = (index, value) => {
    // This will auto move the cursor to next textfield as the user enter there OTP

    if (value.length === 1 && index < inputs.current.length - 1) {
      inputs.current[index + 1].focus();
    }
    if (value.length !== 1) {
      inputs.current[index - 1].focus();
    }
    // Update the OTP value
    const newOtp =
      emailVerificationCode.substring(0, index) +
      value +
      emailVerificationCode.substring(index + 1);

    setEmailVerificationCode(newOtp);
  };

  //Email Verification
  function handleEmailSubmit() {
    if (emailVerificationCode !== null && emailVerificationCode.length === 6) {
      setPLoading(true);
      //   console.log(`emailVerificationCode: ${emailVerificationCode}`);
      dispatch(VerifyEmail(emailVerificationCode));
      setTimeout(() => setPLoading(false), 2000);
    } else {
      toast.dark("Please Enter a 6-Digit Valid Email OTP", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  }
  //Resend Email OTP
  const handleEmailResend = () => {
    setEmailCounter(timerStart);
    //Re-Send OTP to Email
    dispatch(UpdateEmail(value.email, profile.user.email));
  };
  return (
    <div>
      <Dialog
        open={openEmailDetails}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle sx={styles.dialogContent}>
          {" "}
          <ForgetPasswordStepper emailVerify={open} />{" "}
        </DialogTitle>
        {open === false && emailVerify === false ? (
          <>
            <DialogContent sx={styles.dialogContent}>
              <Typography
                sx={{
                  fontSize: ".8rem",
                  color: appColors.secondaryText,
                  fontWeight: "bold",
                }}
              >
                Update your Mail ID
              </Typography>
              <TextField
                size="small"
                type="email"
                label="Email"
                variant="filled"
                color="primary"
                focused
                value={value.email}
                onChange={(e) =>
                  setValue((prev) => ({ ...prev, email: e.target.value }))
                }
                sx={{
                  backgroundColor: "white",
                  fontSize: "18px",
                  borderRadius: "5px",
                  width: "100%",
                  marginTop: "2%",
                }}
              />
            </DialogContent>
            <DialogActions sx={styles.dialogContent}>
              <Button
                onClick={handleClose}
                size="small"
                sx={styles.buttonStyle}
              >
                Close
              </Button>
              <Button onClick={onSubmit} size="small" sx={styles.buttonStyle}>
                Submit
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
        {open === true && emailVerify === false ? (
          <>
            <DialogContent sx={styles.dialogContent}>
              <Typography
                sx={{
                  marginBottom: ".5rem",
                  fontSize: ".9rem",
                  color: appColors.secondaryText,
                  textAlign:"center"
                }}
              >
                {" "}
                Enter the 6 digit OTP sent to {value.email}
              </Typography>
              <Box className="otp-container">
                {/* {[0, 1, 2, 3, 4, 5].map((index) => (
                  <input
                    key={index}
                    type="text"
                    pattern="[0-9]*"
                    inputMode="numeric"
                    maxLength="1"
                    className="otp-input"
                    ref={(el) => (inputs.current[index] = el)}
                    onChange={(e) => handleChange(index, e.target.value)}
                  />
                ))} */}
                <OTPInput
                  value={emailVerificationCode}
                  onChange={(otp) => {
                    // Ensure only numbers are entered
                    const filteredOtp = otp.replace(/\D/g, "");
                    // Remove non-digit characters
                    if (/^\d*$/.test(filteredOtp)) {
                      setEmailVerificationCode(filteredOtp);
                    }
                  }}
                  inputStyle="inputStyle"
                  numInputs={6}
                  renderSeparator={<span></span>}
                  renderInput={(props) => <input {...props} />}
                />
              </Box>
              <Button
                disabled={emailCounter !== 0 || emailVerify}
                sx={styles.resendButton}
                onClick={handleEmailResend}
              >
                <span
                  style={{
                    fontSize: ".8rem",
                    color: appColors.secondaryText,
                    fontWeight: "bold",
                  }}
                >
                  Resend OTP in
                </span>{" "}
                {/* Timer for Email Verification */}
                <span style={{ color: appColors.darkGray }}>
                  &nbsp;00:{emailCounter}
                </span>
              </Button>
            </DialogContent>{" "}
            <DialogActions sx={styles.dialogContent}>
              <Button
                onClick={handleCloseOtp}
                size="small"
                sx={styles.buttonStyle}
              >
                Back
              </Button>
              <Button
                onClick={handleEmailSubmit}
                size="small"
                sx={styles.buttonStyle}
              >
                Verify
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
        {emailVerify === true ? (
          <>
            <DialogContent sx={styles.dialogContent1}>
              <Typography sx={{ marginRight: ".5rem" }}>
                {" "}
                <HowToRegIcon />
              </Typography>
              <Typography>
                {" "}
                You have successfully updated your Mail ID!
              </Typography>
            </DialogContent>{" "}
            <DialogActions sx={styles.dialogContent}>
              <Button
                onClick={handleCloseafterVerify}
                size="small"
                sx={styles.buttonStyle}
              >
                Close
              </Button>
            </DialogActions>
          </>
        ) : (
          ""
        )}
      </Dialog>
    </div>
  );
}
