/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Box, Tooltip, Typography } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { RequestReplacementCan } from "../../Redux/actions/ordersAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { UploadFile } from "../../Redux/actions/fileHandlingActions";
import ClearIcon from "@mui/icons-material/Clear";
import appColors from "../../Assets/AppTheme/appColors";
import SelectAddressDetails from "../Reports/selectAddressDialog";
import appTheme from "../../Assets/AppTheme/appTheme";
import CustomConsole from "../../coustomConsole";
export default function ReplacementDialog({
  openReplacementDialog,
  setOpenReplacementDialog,
  userStock,
  replacementReasons,
}) {
  CustomConsole(replacementReasons);
  const dispatch = useDispatch();
  const fileHandler = useSelector((state) => state.fileHandler);
  const [fileSetFlag, setFileSetFlag] = React.useState(false);
  const [material, setMaterial] = React.useState("");
  const [Addressid, setAddressId] = React.useState("");
  const [capacity, setCapacity] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [reason, setReason] = React.useState("");
  const [openTextfield, setOpenTextfield] = React.useState(false);
  const [otherReason, setOtherReason] = React.useState("");

  const [uploadingFiles, setUploadingFiles] = React.useState([]);

  const handleChangeMaterial = (event) => {
    setMaterial(event.target.value);
  };
  const handleChangeCapacity = (event) => {
    setCapacity(event.target.value);
  };
  const handleChangeQuantity = (event) => {
    setQuantity(event.target.value);
  };
  const handleChangeReason = (e) => {
    if (e.target.value === 999) {
      setOpenTextfield(true);
      setReason(e.target.value);
    } else {
      setReason(e.target.value);
      setOpenTextfield(false);
    }
  };
  const handleClose = () => {
    setOpenReplacementDialog(false);
  };
  const handleRequest = () => {
    let reqObj = {
      replace_can_qty: quantity,
      container_material: material,
      container_capacity: capacity,
      replacement_reason: reason,
      file_names: uploadingFiles,
      address_id: Addressid,
    };
    CustomConsole(reqObj);
    if (uploadingFiles.length === 0) {
      return toast.dark("Please upload images to proceed", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
    if (reason !== "") {
      if (reason === 999) {
        if (
          reqObj.replace_can_qty !== "0" &&
          reqObj.replace_can_qty !== "" &&
          reqObj.container_material !== "" &&
          reqObj.container_capacity !== "" &&
          reason === 999 &&
          Addressid !== ""
        ) {
          CustomConsole("Dispatching other reason");
          reqObj.replacement_reason = otherReason;
          dispatch(RequestReplacementCan(reqObj));
          setMaterial("");
          setCapacity("");
          setQuantity("");
          setReason("");
          setOpenTextfield(false);
          setOtherReason("");
          setOpenReplacementDialog(false);
        } else {
          CustomConsole("Error");
          toast.dark("Please Enter Vaild Value", {
            position: "bottom-right",
            autoClose: 2000,
            type: "error",
          });
        }
      }

      if (reason !== 999) {
        if (
          reqObj.replace_can_qty !== "0" &&
          reqObj.replace_can_qty !== "" &&
          reqObj.container_material !== "" &&
          reqObj.container_capacity !== "" &&
          reason !== 999 &&
          Addressid !== ""
        ) {
          CustomConsole("Dispatching");
          dispatch(RequestReplacementCan(reqObj));
          setMaterial("");
          setCapacity("");
          setQuantity("");
          setReason("");
          setOpenTextfield(false);
          setOtherReason("");
          setOpenReplacementDialog(false);
        } else {
          CustomConsole("Error 2");
          toast.dark("Please Enter Vaild Value", {
            position: "bottom-right",
            autoClose: 2000,
            type: "error",
          });
        }
      }
    } else {
      toast.dark("Please Select Vaild Reason for Replacement", {
        position: "bottom-right",
        autoClose: 2000,
        type: "error",
      });
    }
  };

  const uploadingImages = (e) => {
    if (uploadingFiles.length <= 4) {
      dispatch(UploadFile(e.target.files[0]));
      setFileSetFlag(true);
    } else {
      toast.error("Already uploaded 5 files", {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  React.useEffect(() => {
    CustomConsole(fileHandler);
    if (fileSetFlag && !fileHandler.error) {
      setUploadingFiles([...uploadingFiles, fileHandler.fileName]);
      CustomConsole(uploadingFiles);
      setFileSetFlag(false);
    }
  }, [fileHandler]);

  const removeFile = (index) => {
    const newFileArray = [...uploadingFiles];
    newFileArray.splice(index, 1);
    setUploadingFiles(newFileArray);
  };

  const inputLabelProps = {
    sx: {
      color: appColors.darkGray,
      fontSize: appTheme.fontSizexs, // Set the desired label color
    },
  };

  //useEffect for setting capacity and material
  React.useEffect(() => {
    setCapacity(userStock?.container_capacity);
    setMaterial(userStock?.container_material);
  }, [userStock, openReplacementDialog]);
  return (
    <div>
      <Dialog
        open={openReplacementDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ background: appColors.secondaryCard }}>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: appTheme.fontSizeLarge,
              fontWeight: "bold",
              padding: "10px",
              color: appColors.secondaryText,
            }}
          >
            Request for Replacement
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              //   alignItems: "center",
              //   justifyContent: "center",
              gap: "10px",
            }}
          >
            <Box>
              <TextField
                size="small"
                id="outlined-basic"
                label="Enter quantity"
                sx={{ width: "100%" }}
                variant="outlined"
                value={quantity}
                InputProps={{
                  sx: {
                    bgcolor: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  },
                }}
                InputLabelProps={inputLabelProps}
                onChange={handleChangeQuantity}
                onKeyDown={(e) => {
                  if (!/^([0-9]\b)+$/.test(e.key)) {
                    e.preventDefault();
                  }
                  if (e.key === "Backspace") {
                    setQuantity((preQty) => preQty.slice(0, -1));
                  }
                }}
              />
            </Box>
            <Box sx={{ width: "100%" }}>
              <FormControl size="small" sx={{ width: "100%" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: appColors.darkGray }}
                >
                  Select reason
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-simple-select"
                  value={reason}
                  label="Select reason"
                  onChange={handleChangeReason}
                  sx={{
                    background: appColors.secondaryCard,
                    color: appColors.darkGray,
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: appColors.darkGray,
                    },
                  }}
                >
                  {replacementReasons.map((value) => {
                    return <MenuItem value={value.k}>{value.v}</MenuItem>;
                  })}
                </Select>
              </FormControl>
              {openTextfield ? (
                <TextField
                  size="small"
                  sx={{
                    marginTop: "10px",
                    width: "100%",
                  }}
                  id="outlined-basic"
                  InputProps={{
                    sx: {
                      bgcolor: appColors.secondaryCard,
                      color: appColors.darkGray,
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: appColors.darkGray,
                      },
                    },
                  }}
                  InputLabelProps={inputLabelProps}
                  label="Enter the reason"
                  variant="outlined"
                  value={otherReason}
                  onChange={(e) => {
                    setOtherReason(e.target.value);
                  }}
                />
              ) : null}
            </Box>
            <Box>
              <SelectAddressDetails
                Addressid={Addressid}
                setAddressId={setAddressId}
              />
            </Box>
            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Tooltip title="You can upload max of 5 files" arrow>
                <Button
                  variant="contained"
                  component="label"
                  size="small"
                  sx={{
                    backgroundColor: "#3498DB",
                  }}
                >
                  Upload images
                  <input
                    hidden
                    multiple
                    type="file"
                    onChange={uploadingImages}
                  />
                </Button>
              </Tooltip>
            </Box>
            <Box>
              {uploadingFiles && uploadingFiles.length > 0
                ? uploadingFiles.map((val, idx) => {
                    return (
                      <Box
                        key={idx + 1}
                        sx={{
                          padding: "5px",
                          backgroundColor: "#0a0c4a",
                          borderRadius: "20px",
                          marginTop: "5px",
                          fontSize: appTheme.fontSizexs,
                          fontWeight: "700",
                          display: "flex",
                          gap: "20px",
                          justifyContent: "space-around",
                          alignItems: "center",
                          color: "white",
                        }}
                      >
                        Uploaded {idx + 1}
                        <ClearIcon
                          sx={{
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            removeFile(idx);
                          }}
                        />
                      </Box>
                    );
                  })
                : null}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions sx={{ background: appColors.secondaryCard }}>
          <Button
            onClick={handleClose}
            sx={{
              backgroundColor: appColors.Failed,
              padding: "10px 20px",
              borderRadius: "0", // Set the border radius to 0 (no rounding)
              borderTopRightRadius: "15px",
              borderBottomLeftRadius: "15px",
              height: "30px",
              color: "white",
              "&: hover": {
                backgroundColor: appColors.Failed,
                color: "white",
              },
            }}
          >
            Close
          </Button>
          <Button
            onClick={handleRequest}
            autoFocus
            sx={{
              backgroundColor: appColors.textColorNew,
              padding: "10px 20px",
              color: "white",
              borderRadius: "0", // Set the border radius to 0 (no rounding)
              borderTopRightRadius: "15px",
              borderBottomLeftRadius: "15px",
              height: "30px",
              "&: hover": {
                backgroundColor: appColors.textColorNew,
                color: "white",
              },
            }}
          >
            Request
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
