/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author 
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import React from 'react';
import { Button } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import appColors from '../../Assets/AppTheme/appColors';

function DoneButton(props) {
  const theme = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor:appColors.textColorNew,
            width: "150px",
            padding: "10px 20px",
            borderRadius: "0", // Set the border radius to 0 (no rounding)
            borderTopRightRadius: "15px",
            borderBottomLeftRadius: "15px",
            color: "#fff",
            height: "40px",
            "&: hover": {
              backgroundColor: "#121b47",
              color: "#fff",
            },
          },
        },
      },
    },
  });
  return (
    <ThemeProvider theme={theme}>
      <Button variant="outlined" onClick={props.onClick}>
        Done
      </Button>
    </ThemeProvider>
  )
}

export default DoneButton