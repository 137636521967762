/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Button from "@mui/material/Button";
import appColors from "../../Assets/AppTheme/appColors";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";

export default function QrQualityReportDialog(props) {
  const history = useHistory();

  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    props.setQualityReport(false);
    props.setShow(false);
    if (window.innerWidth < 600) {
      history.push("/mobile");
    }
  };
  const bgcolor = appColors.backGroundGradient;

  // const filePath = props.filePath

  return (
    <Dialog
      open={props.qualityReport}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          borderRadius: "20px", // Set border radius for curved effect
        },
      }}
    >
      <Box>
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            bgcolor: appColors.white,
            color: appColors.textColorNew,
            fontWeight: "bold",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "24px",
                color: appColors.darkGray,
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Water Quality Report
            </Typography>
            {props.fileType === "jpg" || props.fileType === "png" ? (
              <img
                src={props.filePath}
                style={{ marginTop: "10px", width: "70%", height: "50%" }}
                alt=""
              />
            ) : (
              <embed
                src={props.filePath}
                style={{ marginTop: "10px", width: "100%", height: "100%" }}
                alt=""
                type="application/pdf"
              />
            )}
            {/* <img
                src={props.filePath}
                style={{ marginTop: "10px", width: "70%", height: "50%" }}
                alt=""
              /> */}

            {/* <embed src="file_name.pdf" width="800px" height="2100px" /> */}
          </Box>
        </DialogTitle>
        <DialogActions
          sx={{
            bgcolor: appColors.white,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <Button
            onClick={handleClose}
            variant="contained"
            autoFocus
            sx={{
              bgColor: appColors.textColorNew,
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
