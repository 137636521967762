/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

// import { toast } from "react-toastify";
// import jwtDecode from "jwt-decode";
import actionType from "./../actions/actionTypes";

const initialProfileState = {
  error: true,
  orders: [],
  isLoading: true,
  transactions: [],
  ongoingOrders: [],
  postpaidOrders: [],
  replacementOrders: [],
  allDetails: {},
};

const recentActivityReducer = (state = initialProfileState, action) => {
  // console.log(`recent activities ${action.type}`);

  switch (action.type) {
    case actionType.GET_RECENT_ACTIVITIES:
      // console.log("------- (recentActivityReducer) User Details--------");
      // console.log(JSON.stringify(action));

      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
        orders: action.payload.orders,
        transactions: action.payload.Transactions,
        postpaidOrders: action.payload.postpaidOrders,
      };
    case actionType.GET_PWD_ORDER_OTP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        ongoingOrders: action.payload.ongoingOrders,
      };
    case actionType.REPLACEMENT_CANS_OTP:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        replacementOrders: action.payload.data,
      };
    case actionType.ALL_OTP_AND_ORDER_DETAILS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        allDetails: action.payload.data,
      };
    case actionType.SIGN_OUT:
      return {
        error: true,
        orders: [],
        isLoading: true,
        transactions: [],
        ongoingOrders: [],
        postpaidOrders: [],
        replacementOrders: [],
        allDetails: {},
      };
    default:
      return state;
  }
};

export default recentActivityReducer;
