/**
 * @author Gowtham Prasath
 * @description Promo Code Apply and Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, Typography } from "@mui/material";
import { dialogStyles } from "../../../Components/DialogBoxes/PdwBookingSummary/newPromoDialogCss";
import { ApplyPromocode } from "../../../Redux/actions/promocodesAction";
import appColors from "../../../Assets/AppTheme/appColors";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import NavigationButtons from "../../CommonComponents/pageNavigation";
import {
  replacementCanOrdersStatus,
  returnCanOrderStatus,
} from "../../../Assets/Constants/AppMetaData";
import { transactionStyle } from "./transactionStyles";
import WaterDropApplyButton from "../../SkuButtons/waterDropButton";
import { EditReturnOrder } from "../../../Redux/actions/returnReplacementAction";
import CloseIcon from "@mui/icons-material/Close";
export default function ReturnCanDetailsCard({
  data,
  handelAddQuantity,
  handelReduseQuantity,
  handelEditOrder,
  handelEditSubmit,
  editOrderId,
  quantity,
  setEditOrderId,
  haandelSubmitCancel,
  orderType,
  editType,
  handelCloseButton,
  handelSubmitCancelType,
}) {
  const dispatch = useDispatch();
  const [orderData, setOrderData] = React.useState([]);

  //useeffect to set data
  React.useEffect(() => {
    if (orderType === "return") {
      setOrderData(data?.returnCanData);
    } else if (orderType === "replace") {
      setOrderData(data?.replacementData);
    }
  }, [data]);

  //function to show date formate
  const endDateConverter = (endDate) => {
    const formattedDate = moment(endDate, "DD-MM-YYYY").format("DD MMM YYYY");
    return formattedDate;
  };

  //function to identify order status
  const orderStatusFun = (order_status) => {
    if (orderType === "return") {
      switch (order_status) {
        case returnCanOrderStatus.CONSUMER_ACCEPTED:
          return "COMPLETED";
        case returnCanOrderStatus.APPROVED:
          return "APPROVED";
        case returnCanOrderStatus.REFUND:
          return "REFUND";
        case returnCanOrderStatus.CONSUMER_REJECTED:
          return "CANCELLED";
        case returnCanOrderStatus.CANCELLED:
          return "CANCELLED";
        case returnCanOrderStatus.DELIVERED:
          return "DELIVERED";
        case returnCanOrderStatus.REJECTED:
          return "CANCELLED";
        case returnCanOrderStatus.PENDING:
          return "PENDING";
        default:
          return "";
      }
    } else if (orderType === "replace") {
      switch (order_status) {
        case replacementCanOrdersStatus.CAN_REPLACE_ORDER_REQUESTED:
          return "NEW REQUEST";
        case replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_ACCEPTED:
          return "ACCEPTED";
        case replacementCanOrdersStatus.CAN_REPLACE_ORDER_ADMIN_REJECTED:
          return "CANCELLED";
        case replacementCanOrdersStatus.CAN_REPLACE_ORDER_STARTED:
          return "STARTED";
        case replacementCanOrdersStatus.CAN_REPLACE_ORDER_FINISHED:
          return "DELIVERED";
        default:
          return "";
      }
    }
  };

  //function to close edit option
  const handelCloseEditDialog = () => {
    setEditOrderId("");
  };
  return (
    <>
      <Box sx={[dialogStyles.scrollPromoCard]}>
        {orderData?.length > 0 ? (
          <Box sx={{}}>
            {orderData.map((data, index) => {
              return (
                <Box
                  sx={[
                    dialogStyles.mainCard,
                    { boxShadow: 5, margin: ".3rem", position: "relative" },
                  ]}
                  key={index}
                >
                  <Box sx={transactionStyle.mainBox}>
                    <Box
                      sx={[transactionStyle.boxRow, { marginBottom: ".5rem" }]}
                    >
                      <Typography
                        sx={[
                          transactionStyle.headerText,

                          { marginRight: "3rem" },
                        ]}
                      >
                        Order Id&nbsp;
                        <span style={transactionStyle.subTest}>
                          {orderType === "return"
                            ? data?.return_can_order_id
                            : data?.order_id}
                        </span>
                      </Typography>

                      <Typography sx={[transactionStyle.headerText]}>
                        Status &nbsp;{" "}
                        <span style={transactionStyle.subTest}>
                          {orderStatusFun(data.order_status)}
                        </span>
                      </Typography>
                    </Box>
                    <Box sx={transactionStyle.boxRow}>
                      <Box sx={transactionStyle.boxColumn}>
                        <Typography sx={transactionStyle.headerText}>
                          Quantity
                        </Typography>
                        <Typography sx={transactionStyle.subTest}>
                          {orderType === "return"
                            ? data?.container_qty
                            : data?.replace_can_qty}
                        </Typography>
                      </Box>
                      {data?.order_status === 100 ||
                      data?.order_status === 200 ? (
                        <Box sx={transactionStyle.boxColumn}>
                          <Typography sx={transactionStyle.headerText}>
                            OTP
                          </Typography>
                          <Typography sx={transactionStyle.subTest}>
                            {orderType === "return"
                              ? data?.return_can_otp
                              : data?.otp}
                          </Typography>
                        </Box>
                      ) : (
                        ""
                      )}
                      <Box sx={transactionStyle.boxColumn}>
                        <Typography sx={transactionStyle.headerText}>
                          Created
                        </Typography>
                        <Typography sx={transactionStyle.subTest}>
                          {endDateConverter(data?.created_at)}
                        </Typography>
                      </Box>
                      {data?.order_status === 500 && (
                        <Box sx={transactionStyle.boxColumn}>
                          <Typography sx={transactionStyle.headerText}>
                            Completed
                          </Typography>
                          <Typography sx={transactionStyle.subTest}>
                            {endDateConverter(data?.updated_at)}
                          </Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  {data?.order_status === 200 && (
                    <Box sx={transactionStyle.boxRowButton}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => handelEditOrder(data)}
                        sx={transactionStyle.editBtn}
                      >
                        Edit
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        sx={transactionStyle.closeButton}
                        onClick={() => handelSubmitCancelType(data)}
                      >
                        Cancel
                      </Button>
                    </Box>
                  )}
                  {(orderType === "return" &&
                    editOrderId === data?.return_can_order_id) ||
                  (orderType === "replace" &&
                    editOrderId === data?.order_id) ? (
                    <Box sx={transactionStyle.editLayout}>
                      <Box sx={transactionStyle.boxCloseIconRow}>
                        <CloseIcon onClick={handelCloseEditDialog} />
                      </Box>
                      {editType === "edit" ? (
                        <Box sx={transactionStyle.boxEditRow}>
                          <WaterDropApplyButton
                            handelAddQuantity={handelAddQuantity}
                            handelReduseQuantity={handelReduseQuantity}
                            quantity={quantity}
                          />
                          <Button
                            size="small"
                            variant="contained"
                            onClick={handelEditSubmit}
                            sx={[
                              transactionStyle.editBtn,
                              { marginTop: ".5rem" },
                            ]}
                          >
                            Submit
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={[
                            transactionStyle.boxColumn,
                            { alignItems: "center" },
                          ]}
                        >
                          <Typography>
                            Are you sure you want to cancel?
                          </Typography>
                          <Box sx={transactionStyle.boxEditRow}>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={handelCloseButton}
                              sx={[
                                transactionStyle.closeButton,
                                { marginTop: ".5rem", marginRight: "2rem" },
                              ]}
                            >
                              No
                            </Button>
                            <Button
                              size="small"
                              variant="contained"
                              onClick={() => haandelSubmitCancel(data)}
                              sx={[
                                transactionStyle.editBtn,
                                { marginTop: ".5rem" },
                              ]}
                            >
                              Yes
                            </Button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ) : (
                    ""
                  )}
                </Box>
              );
            })}
          </Box>
        ) : (
          <Typography
            sx={{
              textAlign: "center",
              fontWeight: "bold",
              padding: "10px",
              color: appColors.textColorNew,
            }}
          >
            No Transaction Available
          </Typography>
        )}
      </Box>
    </>
  );
}
