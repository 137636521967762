/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetBankDetailsIfsc } from "../../Redux/actions/bankDetailAction.js";
import { SettlementRequests } from "../../Redux/actions/referAndEarnAction.js";
import { toast } from "react-toastify";
import appColors from "../../Assets/AppTheme/appColors.js";

function RedeemAmountToAccount({ openRedeem, setOpenRedeem }) {
  const dispatch = useDispatch();
  const influencerReq = useSelector((state) => state.referAndEarnDetails);
  const [influencerPan, setInfluencerPan] = React.useState("");
  const [influencerIfscCode, setInfluencerIfscCode] = React.useState("");
  const [influencerBankName, setInfluencerBankName] = React.useState("");
  const [influencerAccountNumber, setInfluencerAccountNumber] =
    React.useState("");
  const [reEnterAccountNum, setReEnterAccountNum] = React.useState("");
  // const userDetails = useSelector((state) => state.referAndEarnDetails);
  const bankIfscDet = useSelector((state) => state.bankDetailsIfsc);
  const profileDetails = useSelector((state) => state.userProfile);

  //helper text for fields
  const [accountNumHt, setAccountNumHt] = React.useState("");
  const [reaccountNumberHt, setReAccountNumHt] = React.useState("");
  const [panNumberHt, setPanNumberHt] = React.useState("");
  const [ifscHt, setIfscHt] = React.useState("");

  //flags
  const [panNumEr, setPanNumEr] = React.useState(false);
  const [ifscEr, setIfscEr] = React.useState(false);
  const [settleToast, setSettleToast] = React.useState(false);
  const alphabetPattern = /^[a-zA-Z ]*$/;

  const handlePanChange = (e) => {
    const isValidPanNumber = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(
      e.target.value.toUpperCase()
    );
    setPanNumEr(!isValidPanNumber);
    setInfluencerPan(e.target.value);
    if (!isValidPanNumber) {
      setPanNumberHt("Enter Valid PAN");
    } else {
      setPanNumberHt("");
    }
  };

  const handleIfscChange = (e) => {
    const isValidIfscCode = /^[A-Z]{4}0[A-Z0-9]{6}$/.test(e.target.value);
    setIfscEr(!isValidIfscCode);
    setInfluencerIfscCode(e.target.value);
    if (!isValidIfscCode) {
      setIfscHt("Enter Valid IFSC");
    } else {
      setIfscHt("");
    }
  };

  const handleChangeAccount = (e) => {
    setInfluencerAccountNumber(e.target.value);
    if (e.target.value !== reEnterAccountNum) {
      setReAccountNumHt("Confirm Account Number Mismatched.");
    }
    if (e.target.value === reEnterAccountNum) {
      setReAccountNumHt("");
    }
    if (e.target.value.length < 9 || e.target.value.length > 18) {
      setAccountNumHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
    } else {
      setAccountNumHt("");
    }
  };

  const handleChangeReAccount = (e) => {
    setReEnterAccountNum(e.target.value);
    if (e.target.value !== influencerAccountNumber) {
      setReAccountNumHt("Confirm Account Number Mismatched.");
    } else if (e.target.value.length < 9 || e.target.value.length > 18) {
      setReAccountNumHt(
        "Account Number Must have Min: 9-Digits & Max: 18-Digits"
      );
    } else {
      setReAccountNumHt("");
    }
  };

  const handleClose = () => {
    setOpenRedeem(false);
  };

  React.useEffect(() => {
    // console.log(influencerReq);
    if (!influencerReq.error && settleToast) {
      toast.dark(influencerReq.msg, {
        type: "success",
        position: "bottom-right",
        autoClose: 5000,
      });
      setSettleToast(false);
    }
    if (influencerReq.error && settleToast) {
      toast.dark(influencerReq.msg, {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
      setSettleToast(false);
    }
  }, [influencerReq]);

  // React.useEffect(() => {
  //   if (
  //     userDetails.bk_details &&
  //     Object.keys(userDetails.bk_details).length > 0
  //   ) {
  //     setInfluencerPan(userDetails.bk_details.consumer_profile.pan_number);
  //     setInfluencerIfscCode(
  //       userDetails.bk_details.consumer_profile.IFSC_number
  //     );
  //     setInfluencerBankName(userDetails.bk_details.consumer_profile.bank_name);
  //     setInfluencerAccountNumber(
  //       userDetails.bk_details.consumer_profile.account_number
  //     );
  //     setReEnterAccountNum(
  //       userDetails.bk_details.consumer_profile.account_number
  //     );
  //   }

  //   console.log(userDetails.bk_details);
  // }, [userDetails]);

  React.useEffect(() => {
    if (
      profileDetails.consumer &&
      Object.keys(profileDetails.consumer).length > 0
    ) {
      setInfluencerPan(profileDetails.consumer.pan_number);
      setInfluencerIfscCode(profileDetails.consumer.IFSC_number);
      setInfluencerBankName(profileDetails.consumer.bank_name);
      setInfluencerAccountNumber(profileDetails.consumer.account_number);
      setReEnterAccountNum(profileDetails.consumer.account_number);
    }
  }, []);

  React.useEffect(() => {
    if (
      bankIfscDet.branchDetails &&
      Object.keys(bankIfscDet.branchDetails).length > 0
    ) {
      setInfluencerBankName(bankIfscDet.branchDetails.BANK);
    }
  }, [bankIfscDet]);

  const getIfscBankDetails = () => {
    if (!ifscEr && influencerIfscCode !== "") {
      dispatch(GetBankDetailsIfsc(influencerIfscCode));
    }
  };

  const settlementRequest = () => {
    if (!ifscEr && !panNumEr && influencerAccountNumber === reEnterAccountNum) {
      const reqObj = {
        pan_number: influencerPan,
        bank_name: influencerBankName,
        ifsc_number: influencerIfscCode,
        account_number: influencerAccountNumber,
      };

      dispatch(SettlementRequests(reqObj));
      setSettleToast(true);
    } else {
      console.log("---Condition failed---");
    }
  };

  return (
    <Dialog
      open={openRedeem}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle textAlign="center" id="alert-dialog-title">
        To Be Transferred To This Account
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <TextField
            size="small"
            margin="dense"
            id="name"
            label="Enter your IFSC code*"
            type="text"
            fullWidth
            value={influencerIfscCode}
            onChange={handleIfscChange}
            FormHelperTextProps={{ style: { color: "red" } }}
            helperText={ifscHt}
          />
          <Button
            variant="contained"
            onClick={getIfscBankDetails}
            size="small"
            sx={{ backgroundColor: appColors.textColorNew }}
          >
            Verify
          </Button>
        </Box>
        <TextField
          size="small"
          margin="dense"
          id="name"
          label="Enter your BANK Name*"
          type="text"
          fullWidth
          onKeyDown={(e) => {
            if (!alphabetPattern.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={influencerBankName}
          onChange={(e) => {
            setInfluencerBankName(e.target.value);
          }}
        />

        <TextField
          size="small"
          margin="dense"
          id="name"
          label="Enter your PAN number*"
          InputProps={{
            inputProps: {
              style: { textTransform: "uppercase" },
            },
          }}
          type="text"
          fullWidth
          value={influencerPan}
          onChange={handlePanChange}
          FormHelperTextProps={{ style: { color: "red" } }}
          helperText={panNumberHt}
        />

        <TextField
          size="small"
          margin="dense"
          id="name"
          label="Enter your BANK Name*"
          type="text"
          fullWidth
          onKeyDown={(e) => {
            if (!alphabetPattern.test(e.key)) {
              e.preventDefault();
            }
          }}
          value={influencerBankName}
          onChange={(e) => {
            setInfluencerBankName(e.target.value);
          }}
        />
        <TextField
          size="small"
          margin="dense"
          id="name"
          label="Enter your Account number*"
          type="number"
          fullWidth
          value={influencerAccountNumber}
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          helperText={accountNumHt}
          FormHelperTextProps={{ style: { color: "red" } }}
          onChange={handleChangeAccount}
        />
        <TextField
          size="small"
          margin="dense"
          id="name"
          label="Re-enter your account number*"
          type="number"
          fullWidth
          onInput={(e) => {
            e.target.value = Math.max(0, parseInt(e.target.value))
              .toString()
              .slice(0, 18);
          }}
          helperText={reaccountNumberHt}
          FormHelperTextProps={{ style: { color: "red" } }}
          value={reEnterAccountNum}
          onChange={handleChangeReAccount}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button
          onClick={settlementRequest}
          variant="contained"
          size="small"
          sx={{ backgroundColor: appColors.textColorNew }}
        >
          Send Request
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RedeemAmountToAccount;
