import React from "react";

export default function SubcribeIcon({ stroke }) {
  return (
    <svg
      width="25"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7779 4.48047C12.72 4.48047 10.7084 5.0994 8.9974 6.25898C7.28638 7.41857 5.9528 9.06674 5.1653 10.9951C4.3778 12.9234 4.17175 15.0453 4.57322 17.0924C4.97468 19.1394 5.96562 21.0198 7.42073 22.4957C8.87584 23.9716 10.7298 24.9767 12.748 25.3839C14.7663 25.791 16.8584 25.5821 18.7595 24.7833C20.6607 23.9846 22.2857 22.632 23.429 20.8965C24.5722 19.1611 25.1825 17.1208 25.1825 15.0335C25.1805 12.2353 24.0837 9.55226 22.1329 7.57361C20.182 5.59495 17.5367 4.48247 14.7779 4.48047ZM14.7779 24.6745C12.8973 24.6749 11.0588 24.1097 9.49494 23.0503C7.9311 21.9908 6.71215 20.4848 5.99227 18.7226C5.27239 16.9605 5.08392 15.0213 5.4507 13.1505C5.81748 11.2797 6.72303 9.56126 8.05281 8.2125C9.3826 6.86373 11.0769 5.94526 12.9214 5.57325C14.7659 5.20123 16.6777 5.39239 18.415 6.12255C20.1524 6.8527 21.6373 8.08905 22.6818 9.67521C23.7263 11.2614 24.2836 13.1261 24.2832 15.0335C24.2812 17.5899 23.2791 20.0409 21.497 21.8485C19.7148 23.6561 17.2982 24.6725 14.7779 24.6745Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M15.2267 13.3043V9.48261C15.2267 9.36225 15.1796 9.24683 15.0957 9.16172C15.0118 9.07662 14.898 9.02881 14.7793 9.02881C14.6607 9.02881 14.5469 9.07662 14.463 9.16172C14.3791 9.24683 14.3319 9.36225 14.3319 9.48261V13.3036C13.9636 13.4165 13.6397 13.6442 13.4062 13.9545C13.1728 14.2647 13.0416 14.6417 13.0314 15.0318C13.0365 15.334 13.1132 15.6305 13.2551 15.8963L10.7414 18.399C10.6914 18.4385 10.6502 18.4884 10.6205 18.5452C10.5909 18.6021 10.5736 18.6648 10.5696 18.729C10.5657 18.7932 10.5753 18.8575 10.5977 18.9177C10.6202 18.9779 10.655 19.0325 10.6998 19.078C10.7447 19.1235 10.7986 19.1588 10.8579 19.1816C10.9172 19.2043 10.9806 19.214 11.0439 19.2101C11.1073 19.2061 11.169 19.1885 11.2251 19.1584C11.2812 19.1284 11.3303 19.0866 11.3693 19.0358L13.8808 16.5793C14.1461 16.7576 14.4573 16.8526 14.7756 16.8523C15.2372 16.8447 15.6778 16.6556 16.0046 16.3249C16.3314 15.9941 16.5188 15.5476 16.5272 15.0795C16.5248 14.6817 16.3974 14.2952 16.1635 13.976C15.9296 13.6567 15.6015 13.4215 15.2267 13.3043ZM14.7793 15.897C14.5556 15.8895 14.3432 15.796 14.1849 15.6355C14.0266 15.475 13.9345 15.2594 13.927 15.0326C13.9237 14.9184 13.9435 14.8048 13.9851 14.6987C14.0267 14.5926 14.0893 14.4963 14.169 14.4157C14.2487 14.335 14.3438 14.2718 14.4485 14.2299C14.5533 14.1879 14.6653 14.1682 14.7778 14.1719C15.0015 14.1794 15.214 14.2729 15.3723 14.4334C15.5305 14.5939 15.6227 14.8095 15.6302 15.0363C15.6294 15.265 15.5392 15.4841 15.3794 15.6454C15.2196 15.8068 15.0033 15.8972 14.7778 15.897H14.7793Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M14.7772 8.25622C14.8369 8.25974 14.8966 8.25041 14.9524 8.22885C15.0083 8.20728 15.059 8.17398 15.1013 8.13112C15.1435 8.08825 15.1764 8.03681 15.1976 7.98016C15.2189 7.92351 15.2281 7.86293 15.2246 7.80242V6.61725C15.2246 6.4969 15.1775 6.38147 15.0936 6.29637C15.0097 6.21126 14.8959 6.16345 14.7772 6.16345C14.6585 6.16345 14.5447 6.21126 14.4608 6.29637C14.3769 6.38147 14.3298 6.4969 14.3298 6.61725V7.80015C14.3295 7.85994 14.3409 7.91919 14.3632 7.97451C14.3856 8.02983 14.4185 8.08012 14.46 8.1225C14.5016 8.16488 14.551 8.19851 14.6055 8.22146C14.6599 8.24441 14.7183 8.25622 14.7772 8.25622Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M14.7772 21.8113C14.7176 21.8078 14.6578 21.8171 14.602 21.8386C14.5461 21.8602 14.4954 21.8935 14.4532 21.9364C14.4109 21.9792 14.3781 22.0307 14.3568 22.0873C14.3355 22.144 14.3263 22.2046 14.3298 22.2651V23.448C14.3298 23.5683 14.377 23.6837 14.4609 23.7689C14.5448 23.854 14.6586 23.9018 14.7772 23.9018C14.8959 23.9018 15.0097 23.854 15.0936 23.7689C15.1775 23.6837 15.2246 23.5683 15.2246 23.448V22.2651C15.2246 22.2055 15.2131 22.1465 15.1906 22.0914C15.1681 22.0363 15.1351 21.9863 15.0936 21.9442C15.0521 21.902 15.0027 21.8686 14.9484 21.8458C14.8942 21.823 14.836 21.8113 14.7772 21.8113Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M21.4599 15.0327C21.4564 15.0932 21.4656 15.1537 21.4869 15.2104C21.5081 15.267 21.541 15.3185 21.5832 15.3614C21.6255 15.4042 21.6762 15.4375 21.7321 15.4591C21.7879 15.4806 21.8477 15.49 21.9073 15.4865H23.0736C23.1922 15.4865 23.306 15.4386 23.3899 15.3535C23.4738 15.2684 23.521 15.153 23.521 15.0327C23.521 14.9123 23.4738 14.7969 23.3899 14.7118C23.306 14.6267 23.1922 14.5789 23.0736 14.5789H21.9073C21.7887 14.5789 21.6749 14.6267 21.5909 14.7118C21.507 14.7969 21.4599 14.9123 21.4599 15.0327Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.09587 15.0329C8.09934 14.9724 8.09014 14.9118 8.06889 14.8552C8.04763 14.7985 8.01479 14.7471 7.97253 14.7042C7.93027 14.6613 7.87955 14.628 7.8237 14.6065C7.76784 14.5849 7.70812 14.5756 7.64846 14.5791H6.47997C6.36131 14.5791 6.2475 14.6269 6.1636 14.712C6.07969 14.7971 6.03255 14.9125 6.03255 15.0329C6.03255 15.1532 6.07969 15.2687 6.1636 15.3538C6.2475 15.4389 6.36131 15.4867 6.47997 15.4867H7.64622C7.70517 15.487 7.76359 15.4755 7.81813 15.4528C7.87267 15.4301 7.92226 15.3968 7.96404 15.3546C8.00582 15.3124 8.03898 15.2623 8.0616 15.2071C8.08423 15.1519 8.09587 15.0927 8.09587 15.0329Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M18.1866 9.21199C18.2394 9.2429 18.2977 9.26272 18.3582 9.27027C18.4187 9.27782 18.4801 9.27294 18.5386 9.25594C18.5972 9.23893 18.6518 9.21013 18.6992 9.17127C18.7466 9.13241 18.7858 9.08426 18.8145 9.02972L19.3976 8.02909C19.4569 7.92047 19.4713 7.79239 19.4375 7.67304C19.4037 7.55368 19.3246 7.45282 19.2175 7.39264C19.1104 7.33246 18.9841 7.3179 18.8665 7.35215C18.7488 7.38641 18.6493 7.46667 18.59 7.57529L18.0069 8.57592C17.9765 8.62941 17.9571 8.68855 17.9497 8.74981C17.9423 8.81108 17.9472 8.87322 17.9639 8.93256C17.9807 8.99189 18.009 9.0472 18.0473 9.0952C18.0855 9.14321 18.1329 9.18292 18.1866 9.21199Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M11.3702 20.8564C11.3174 20.8255 11.2591 20.8057 11.1986 20.7981C11.1381 20.7906 11.0767 20.7955 11.0181 20.8125C10.9596 20.8295 10.9049 20.8583 10.8576 20.8971C10.8102 20.936 10.771 20.9841 10.7423 21.0387L10.1592 22.0393C10.0999 22.1479 10.0855 22.276 10.1193 22.3954C10.1531 22.5147 10.2322 22.6156 10.3393 22.6758C10.4464 22.7359 10.5727 22.7505 10.6903 22.7162C10.808 22.682 10.9074 22.6017 10.9668 22.4931L11.5499 21.4925C11.5803 21.439 11.5997 21.3798 11.6071 21.3186C11.6145 21.2573 11.6096 21.1952 11.5929 21.1358C11.5761 21.0765 11.5478 21.0212 11.5095 20.9732C11.4713 20.9252 11.4239 20.8855 11.3702 20.8564Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M22.1326 18.9008L21.1461 18.3094C21.039 18.2492 20.9127 18.2346 20.795 18.2689C20.6773 18.3032 20.5779 18.3834 20.5186 18.492C20.4592 18.6007 20.4449 18.7287 20.4787 18.8481C20.5124 18.9675 20.5916 19.0683 20.6987 19.1285L21.6852 19.7199C21.7382 19.7497 21.7965 19.7687 21.8568 19.7756C21.917 19.7825 21.978 19.7774 22.0362 19.7604C22.0945 19.7435 22.1489 19.715 22.1964 19.6767C22.2438 19.6385 22.2833 19.5911 22.3127 19.5373C22.3421 19.4835 22.3607 19.4244 22.3676 19.3633C22.3744 19.3022 22.3693 19.2403 22.3526 19.1812C22.3359 19.1221 22.3079 19.067 22.2701 19.0188C22.2324 18.9707 22.1856 18.9306 22.1326 18.9008Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M7.42247 11.1671L8.40901 11.7585C8.5161 11.8187 8.64238 11.8333 8.76006 11.799C8.87773 11.7647 8.97718 11.6845 9.03651 11.5759C9.09584 11.4672 9.1102 11.3392 9.07642 11.2198C9.04265 11.1005 8.96352 10.9996 8.85642 10.9394L7.86988 10.348C7.81685 10.3182 7.75856 10.2993 7.69833 10.2923C7.63809 10.2854 7.5771 10.2905 7.51883 10.3075C7.46057 10.3244 7.40616 10.3529 7.35873 10.3912C7.31129 10.4294 7.27176 10.4768 7.24238 10.5306C7.213 10.5844 7.19436 10.6435 7.18751 10.7046C7.18066 10.7657 7.18574 10.8276 7.20247 10.8867C7.21919 10.9458 7.24722 11.0009 7.28497 11.0491C7.32272 11.0972 7.36944 11.1373 7.42247 11.1671Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M20.4727 11.4861C20.5039 11.5382 20.5453 11.5833 20.5943 11.6186C20.6433 11.6539 20.6989 11.6787 20.7577 11.6914C20.8164 11.7042 20.8772 11.7046 20.9361 11.6927C20.9951 11.6808 21.051 11.6568 21.1005 11.6222L22.0871 11.0307C22.1851 10.963 22.2531 10.8591 22.2766 10.7411C22.3 10.6232 22.2771 10.5006 22.2126 10.3996C22.1481 10.2987 22.0472 10.2273 21.9315 10.2008C21.8158 10.1743 21.6945 10.1947 21.5934 10.2578L20.6069 10.8492C20.5149 10.9229 20.4512 11.0269 20.4267 11.1431C20.4022 11.2594 20.4184 11.3807 20.4727 11.4861Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M8.45463 18.3993L7.46808 18.9907C7.37004 19.0584 7.30203 19.1624 7.27857 19.2803C7.25512 19.3983 7.2781 19.5208 7.34258 19.6218C7.40707 19.7228 7.50796 19.7942 7.62366 19.8207C7.73936 19.8472 7.8607 19.8267 7.96173 19.7637L8.94827 19.1722C8.99965 19.1406 9.04411 19.0986 9.07893 19.0489C9.11375 18.9992 9.13819 18.9428 9.15076 18.8832C9.16333 18.8235 9.16376 18.7619 9.15202 18.7021C9.14028 18.6423 9.11662 18.5856 9.0825 18.5354C9.01254 18.4385 8.90991 18.3711 8.79413 18.346C8.67835 18.3209 8.55752 18.3399 8.45463 18.3993Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M18.859 20.9929C18.8273 20.9406 18.7856 20.8953 18.7363 20.8596C18.6871 20.8239 18.6313 20.7985 18.5723 20.785C18.5133 20.7715 18.4522 20.7701 18.3926 20.7808C18.333 20.7916 18.2761 20.8143 18.2253 20.8477C18.1745 20.8811 18.1308 20.9244 18.0968 20.9752C18.0627 21.0259 18.039 21.083 18.0271 21.1432C18.0151 21.2034 18.0151 21.2654 18.0271 21.3256C18.0391 21.3857 18.0628 21.4429 18.0969 21.4936L18.68 22.4942C18.7117 22.5465 18.7534 22.5918 18.8027 22.6275C18.8519 22.6632 18.9077 22.6886 18.9667 22.7021C19.0258 22.7156 19.0869 22.7171 19.1464 22.7063C19.206 22.6955 19.2629 22.6728 19.3137 22.6394C19.3645 22.606 19.4082 22.5627 19.4422 22.512C19.4763 22.4612 19.5 22.4041 19.512 22.3439C19.5239 22.2837 19.5239 22.2217 19.5119 22.1616C19.4999 22.1014 19.4762 22.0443 19.4421 21.9935L18.859 20.9929Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M11.2805 9.25673C11.3318 9.22507 11.3763 9.1831 11.4111 9.1334C11.4459 9.08369 11.4704 9.0273 11.483 8.96767C11.4955 8.90804 11.4959 8.84644 11.4842 8.78664C11.4725 8.72684 11.4488 8.6701 11.4147 8.6199L10.8316 7.61928C10.7648 7.51984 10.6623 7.45085 10.546 7.42706C10.4297 7.40328 10.3089 7.42658 10.2093 7.49199C10.1098 7.5574 10.0394 7.65972 10.0133 7.77707C9.98713 7.89443 10.0073 8.0175 10.0695 8.11997L10.6526 9.12059C10.7252 9.21388 10.8277 9.27853 10.9423 9.30339C11.057 9.32824 11.1766 9.31174 11.2805 9.25673Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M2.2653 15.3996L3.87971 13.7599C3.94669 13.6726 3.97992 13.5635 3.97316 13.4531C3.96639 13.3427 3.9201 13.2386 3.84298 13.1603C3.76585 13.0821 3.66321 13.0351 3.55436 13.0283C3.4455 13.0214 3.33792 13.0551 3.25184 13.1231L2.44501 13.9414C2.66977 11.1635 3.80215 8.54117 5.66269 6.48994C7.52323 4.43871 10.0055 3.07595 12.7161 2.61764C12.7777 2.6088 12.8369 2.58728 12.8901 2.5544C12.9432 2.52153 12.9892 2.478 13.0252 2.42647C13.0611 2.37494 13.0863 2.3165 13.0993 2.25474C13.1122 2.19297 13.1125 2.12919 13.1002 2.06729C13.088 2.00539 13.0634 1.94668 13.028 1.89476C12.9926 1.84284 12.9471 1.79881 12.8943 1.76536C12.8415 1.73191 12.7825 1.70975 12.721 1.70024C12.6594 1.69073 12.5966 1.69406 12.5364 1.71004C9.63181 2.21776 6.97604 3.69044 4.98685 5.89642C2.99766 8.10239 1.78792 10.9165 1.54794 13.896L0.785105 12.9862C0.747512 12.9403 0.701168 12.9027 0.648858 12.8754C0.596549 12.8482 0.539353 12.832 0.480707 12.8277C0.422061 12.8235 0.363175 12.8313 0.307588 12.8508C0.252001 12.8702 0.200859 12.9008 0.157236 12.9408C0.11203 12.9789 0.0748899 13.0259 0.0480474 13.079C0.0212049 13.132 0.00521376 13.1901 0.00103471 13.2495C-0.00314434 13.309 0.00457497 13.3687 0.0237289 13.4251C0.0428827 13.4815 0.0730759 13.5334 0.112494 13.5776L1.63743 15.352C1.67477 15.3982 1.72102 15.4362 1.77334 15.4637C1.82566 15.4912 1.88296 15.5075 1.94173 15.5118C2.0005 15.516 2.0595 15.5081 2.11514 15.4884C2.17077 15.4687 2.22187 15.4377 2.2653 15.3974V15.3996Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M15.0919 27.8154L13.4775 26.178C13.3914 26.11 13.2838 26.0763 13.1749 26.0832C13.0661 26.09 12.9634 26.137 12.8863 26.2152C12.8092 26.2934 12.7629 26.3975 12.7561 26.5079C12.7494 26.6184 12.7826 26.7275 12.8496 26.8148L13.6572 27.6339C10.8981 27.3528 8.30907 26.1505 6.29641 24.2158C4.28376 22.281 2.96136 19.7233 2.53673 16.9439C2.5102 16.8318 2.44322 16.7339 2.34899 16.6695C2.25477 16.6051 2.14016 16.5788 2.02778 16.5959C1.9154 16.613 1.81343 16.6722 1.74199 16.7619C1.67055 16.8515 1.63484 16.965 1.6419 17.0801C2.08607 20.0464 3.49059 22.7787 5.63419 24.8463C7.7778 26.914 10.5387 28.1996 13.4819 28.5006L12.5401 29.1813C12.445 29.2538 12.3821 29.3615 12.3654 29.481C12.3486 29.6004 12.3793 29.7217 12.4506 29.8182C12.522 29.9147 12.6283 29.9784 12.746 29.9954C12.8638 30.0125 12.9834 29.9813 13.0785 29.9089L15.0069 28.453C15.1095 28.3588 15.1737 28.229 15.1866 28.0892C15.2261 28.0431 15.1814 27.9062 15.0919 27.8154Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M29.4873 16.5347L27.9631 14.7604C27.9226 14.7172 27.8739 14.6828 27.8199 14.6594C27.766 14.6359 27.7079 14.6238 27.6491 14.6238C27.5904 14.6238 27.5323 14.6359 27.4783 14.6594C27.4244 14.6828 27.3757 14.7172 27.3352 14.7604L25.72 16.3986C25.67 16.4381 25.6288 16.488 25.5992 16.5448C25.5695 16.6017 25.5522 16.6644 25.5483 16.7286C25.5443 16.7928 25.5539 16.8571 25.5763 16.9173C25.5988 16.9775 25.6336 17.0321 25.6784 17.0776C25.7233 17.1231 25.7772 17.1584 25.8365 17.1812C25.8958 17.2039 25.9593 17.2136 26.0226 17.2097C26.0859 17.2057 26.1476 17.1881 26.2037 17.158C26.2598 17.128 26.309 17.0862 26.3479 17.0354L27.1592 16.2125C26.9111 19.0469 25.7258 21.7131 23.7956 23.7782C21.8655 25.8433 19.3038 27.186 16.5265 27.5885C16.4145 27.6149 16.3142 27.6781 16.2413 27.7682C16.1684 27.8583 16.1268 27.9704 16.123 28.0869C16.1419 28.2045 16.2025 28.311 16.2934 28.3864C16.3844 28.4618 16.4993 28.5008 16.6167 28.4961C19.6104 28.0589 22.3701 26.6071 24.4467 24.3768C26.5234 22.1464 27.7948 19.269 28.054 16.2125L28.8616 17.1201C28.8992 17.166 28.9456 17.2036 28.9979 17.2309C29.0502 17.2581 29.1074 17.2743 29.166 17.2786C29.2247 17.2828 29.2836 17.275 29.3391 17.2555C29.3947 17.2361 29.4459 17.2055 29.4895 17.1655C29.5534 17.073 29.5875 16.9627 29.5871 16.8498C29.5867 16.7368 29.5518 16.6268 29.4873 16.5347Z"
        fill={stroke}
        strokeWidth="1.5"
      />
      <path
        d="M14.4641 2.29696L16.0785 3.93441C16.1646 4.00235 16.2722 4.03605 16.381 4.02919C16.4899 4.02233 16.5925 3.97538 16.6696 3.89715C16.7468 3.81893 16.7931 3.71483 16.7998 3.60441C16.8066 3.494 16.7733 3.38489 16.7064 3.29758L15.8995 2.47923C18.6745 2.75732 21.2776 3.97017 23.2935 5.92422C25.3094 7.87828 26.6219 10.4609 27.0215 13.26C27.0393 13.3746 27.1012 13.4774 27.1937 13.5457C27.2863 13.614 27.4017 13.6422 27.5147 13.6241C27.6278 13.6061 27.7291 13.5432 27.7964 13.4494C27.8638 13.3556 27.8916 13.2385 27.8738 13.1238C27.4656 10.1136 26.0637 7.3326 23.8969 5.23433C21.7302 3.13607 18.9263 1.84444 15.9428 1.57012L16.8413 0.79564C16.8865 0.75751 16.9237 0.710505 16.9505 0.657449C16.9774 0.604393 16.9934 0.54638 16.9975 0.486897C17.0017 0.427414 16.994 0.367688 16.9748 0.311308C16.9557 0.254927 16.9255 0.203055 16.8861 0.158809C16.8484 0.113065 16.802 0.0755208 16.7496 0.0484312C16.6972 0.0213415 16.64 0.00526529 16.5814 0.00116809C16.5227 -0.00292911 16.4639 0.0050372 16.4083 0.0245886C16.3528 0.04414 16.3017 0.0748731 16.2582 0.114942L14.5088 1.61475C14.4544 1.65108 14.409 1.6997 14.3762 1.75679C14.3434 1.81388 14.3241 1.87789 14.3198 1.94382C14.3154 2.00975 14.3262 2.07581 14.3513 2.13681C14.3763 2.19781 14.4149 2.2521 14.4641 2.29544V2.29696Z"
        fill={stroke}
        strokeWidth="1.5"
      />
    </svg>
  );
}
