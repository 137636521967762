// Copyright: This Software is owned and Licensed by BookWater Tech Pvt Ltd,
//            Copying or distributing any part of this code is prohibited
// Revision History: 0.1 (Mobile UI )
// Date of Creation: 20/JUN/2023
// File Name: MobileView.jsx
// Author: Gowtham Prasath
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog";
import { OpenCamera } from "./OpenCamera.jsx";
import QrDataDiaLogBox from "../../Components/DialogBoxes/QrDialogBox/QrDataDialogBox.jsx";
import QrQualityReportDialog from "../Home/QrQualityReportDialog";
import {
  getQrQualityImg,
  getFiles,
} from "../../Redux/actions/fileHandlingActions";
import * as qrScannerAction from "../../Redux/actions/qrScannerAction.js";
import { toast } from "react-toastify";
import appColors from "../../Assets/AppTheme/appColors";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min.js";
import CustomConsole from "../../coustomConsole.js";

//Main Function For Mobile View
export default function QrScan() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = React.useState(false);
  const [refreshFlag, setRefreshFlag] = React.useState(true);
  const [qrFlag, setqrFlag] = React.useState(false);
  const [qualityReportFlag, setQualityReportFlag] = React.useState(false);
  const [fileType, setFileType] = React.useState("");
  const [qualityReport, setQualityReport] = React.useState(false);
  const qrWQDetails = useSelector((state) => state.qrScannerDetails);
  const qrReportQuality = useSelector((state) => state.fileHandler);
  const [openHtmlQr, setOpenHtmlQr] = React.useState(true);
  const [toastMsgFlag, setToastMsgFlag] = React.useState(false);

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  CustomConsole(isSafari);

  const handleQualityReport = (fileName) => {
    CustomConsole("----fileName-----");
    CustomConsole(fileName);
    // const fileType = fileName.substr(fileName.length - 3)
    CustomConsole(fileName.substr(fileName.length - 3));
    setFileType(fileName.substr(fileName.length - 3));
    if (isSafari) {
      setQualityReportFlag(true);
    }
    // dispatch(getFiles(fileName));
    dispatch(getQrQualityImg(fileName));
  };

  const handleCameraResp = (qrId) => {
    CustomConsole("--- handleCameraResp ----");
    CustomConsole(`qrId: ${qrId}`);
    if (qrId.length) {
      CustomConsole("---QR Exists!!!---");
      dispatch(qrScannerAction.GetQrData(qrId));
      setToastMsgFlag(true);
    }
  };

  React.useEffect(() => {
    if (toastMsgFlag) {
      if (qrWQDetails.qrData !== undefined) {
        if (!qrWQDetails.error && Object.keys(qrWQDetails.qrData).length > 0) {
          CustomConsole("---Open the Dialog Box--");
          setShow(true);
          CustomConsole(qrWQDetails);
          qrWQDetails.isQrDataLoaded = false;
          setqrFlag(true);
          setToastMsgFlag(false);
        } else if (
          qrWQDetails.error === true &&
          qrWQDetails.msg.length &&
          toastMsgFlag
        ) {
          CustomConsole("--Show the Error Message ---");
          setOpenHtmlQr(false);
          setToastMsgFlag(false);
          if (window.innerWidth < 600) {
            history.push("/mobile");
          }
        }
      } else {
        setOpenHtmlQr(false);
        setToastMsgFlag(false);
        if (window.innerWidth < 600) {
          history.push("/mobile");
        }
      }
    }
  }, [qrWQDetails]);

  React.useEffect(() => {
    CustomConsole("something");
    CustomConsole(qrReportQuality);
    if (
      qualityReportFlag === true &&
      // qrReportQuality.error === false &&
      qrReportQuality.qrimage !== "" &&
      qrReportQuality.qrimage !== null &&
      qrReportQuality.qrimage !== undefined
    ) {
      setRefreshFlag(false);
      CustomConsole("---filePath----");
      CustomConsole(qrReportQuality.filePath);
      setShow(false);
      if (isSafari) {
        setQualityReport(true);
        // Perform Safari-specific actions or UI changes if needed
      }
    }
  }, [qrReportQuality]);

  // const openQrScanner = () => {
  //     setOpenHtmlQr((prev) => !prev);
  // };

  React.useEffect(() => {
    CustomConsole("-----This is the file-----");
    CustomConsole(fileType);
  }, [fileType]);

  return (
    <div style={{ minHeight: "100vh", marginTop: "8rem" }} className="page">
      <Grid container direction="column">
        <Grid item sm={12}>
          <Box>
            {qrFlag === false ? (
              <OpenCamera
                handleCameraResp={handleCameraResp}
                openHtmlQr={openHtmlQr}
                setOpenHtmlQr={setOpenHtmlQr}
              />
            ) : (
              ""
            )}
          </Box>
        </Grid>
      </Grid>
      {show ? (
        <QrDataDiaLogBox
          data={qrWQDetails}
          setShow={setShow}
          handleQualityReport={handleQualityReport}
        />
      ) : (
        ""
      )}
      {qualityReport ? (
        <QrQualityReportDialog
          filePath={qrReportQuality.qrimage}
          setShow={setShow}
          setQualityReport={setQualityReport}
          fileType={fileType}
          qualityReport={qualityReport}
        />
      ) : (
        ""
      )}
    </div>
  );
}
