/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import { url } from "../apis";
import { toast } from "react-toastify";
import actionType from "./../actions/actionTypes";
// import actionType from "./actionTypes";

import * as toastMessage from "../../Assets/Constants/ToastMessages";
import useAxios from "../../Hooks/useAxios";
import * as AppMetaData from "./../../Assets/Constants/AppMetaData";
import CustomConsole from "../../coustomConsole";
//Get File From the server
export const getFiles = (filename) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/SSM/files/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const reader = new FileReader();
          reader.onload = () => {
            const imageDataUrl = reader.result;
            // CustomConsole(imageDataUrl);
            dispatch({
              type: actionType.GET_FILES,
              payload: imageDataUrl,
            });
          };
          reader.readAsDataURL(blob);
        })

        .catch((error) => {
          CustomConsole("getFiles Action Error : " + error);
        });
    };
  } else {
    toast.dark(toastMessage.TOKEN_INVALID, {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};

export const getBannerImg = (filenames) => {
  // Assuming filenames is an array of filenames

  return async (dispatch) => {
    let token = localStorage.getItem("token");
    if (token === "" || token === null) {
      token = sessionStorage.getItem("token");
    }

    if (token !== "") {
      let api = useAxios(); // Make sure useAxios is defined in your code

      try {
        const fetchPromises = filenames.map(async (filename) => {
          const response = await api.get(`/SSM/files/${filename}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            responseType: "blob",
          });

          const blob = response.data;

          return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = () => {
              const imageDataUrl = reader.result;
              resolve({
                filename,
                imageDataUrl,
              });
            };
            reader.readAsDataURL(blob);
          });
        });

        const results = await Promise.all(fetchPromises);
        const validResults = results.filter((result) => result !== null);
        dispatch({
          type: actionType.GET_FILES_BANNER,
          payload: validResults,
        });
      } catch (error) {
        CustomConsole("getFiles Action Error : " + error);
      }
    } else {
      toast.dark(toastMessage.TOKEN_INVALID, {
        position: "bottom-right",
        autoClose: 5000,
        type: "error",
      });
    }
  };
};

export const getQrQualityImg = (filename) => {
  //   CustomConsole("------- (getFiles) getFiles Action--------");
  let token = localStorage.getItem("token");
  if (token === "" || token === null) {
    token = sessionStorage.getItem("token");
  }
  if (token !== "") {
    return async (dispatch) => {
      await fetch(`${url}/SSM/files/${filename}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          // "Content-Type": "image/jpeg",
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          const fileType = blob.type;
          // Create a Blob URL from the Blob
          const url = window.URL.createObjectURL(
            new Blob([blob], { type: fileType })
          );
          // Create an anchor element
          const link = document.createElement("a");
          link.href = url;
          // Check if the file is a PDF
          if (fileType === "application/pdf") {
            // Open PDF in a new window or tab
            window.open(link.href);
          } else {
            // For other file types (assumed to be images), open in a new window
            const newWindow = window.open();
            // Create an image element
            const img = document.createElement("img");
            // Set the source of the image to the Blob URL
            img.src = url;
            // Append the image to the new window's body
            newWindow.document.body.appendChild(img);
            // Optionally, you can close the new window after a certain period or based on user interaction
            // setTimeout(() => newWindow.close(), 5000); // Close the window after 5 seconds
          }
          // Assuming you want to dispatch the file URL somewhere
          dispatch({
            type: actionType.GET_QUALITY_REPORT,
            payload: url,
          });
        })
        .catch((error) => {
          CustomConsole("getFiles Action Error : " + error);
        });
    };
  } else {
    toast.dark(toastMessage.TOKEN_INVALID, {
      position: "bottom-right",
      autoClose: 5000,
      type: "error",
    });
  }
};

//Upload GST & Profile Files/Images
export const UploadFile = (uploadedFile) => {
  let file = new FormData();
  file.append("name", "filename");
  file.append("filename", uploadedFile);
  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    let response = await api.post("/SSM/files/upload", file).catch((e) => {
      error = e;
      CustomConsole(`Error: ${e}`);
    });
    if (error !== "" && error.message.includes("400")) {
    } else if (response.status === AppMetaData.httpStatus.SUCCESS) {
      CustomConsole(
        `response.status: ${response.status}, ${JSON.stringify(response.data)}`
      );
      dispatch({
        type: actionType.UPLOAD_FILE,
        payload: { data: response.data, statusFlag: true },
      });
      toast.dark("File Uploded Successfully", {
        position: "bottom-right",
        autoClose: 3000,
        type: "success",
      });
    } else {
      CustomConsole("Unhandled Error: " + error);
    }
  };
};
export const BannerImg = (fileName) => {
  let api = useAxios();
  return async (dispatch) => {
    try {
      let response = await api.get(`/SSM/files/${fileName}`, {
        responseType: "arraybuffer",
      });
      CustomConsole(response);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole("-----------Banner img-----------");
        const data = Buffer.from(response.data, "binary").toString("base64");
        CustomConsole(data);
        dispatch({
          type: actionType.BANNERS_IMG,
          payload: data,
        });
      } else {
        // CustomConsole("ELSE: Response in GetPdwAllOrders Action Error");
      }
    } catch (error) {
      CustomConsole("Error: " + JSON.stringify(error));
      toast.dark(error.response.data.msg, {
        position: "bottom-right",
        autoClose: 3000,
        type: "error",
      });
    }
  };
};
//Get File From the server
export const UploadPoFile = (data) => {
  CustomConsole("-------------UploadPoFile----------------------");
  CustomConsole(data);
  let file = new FormData();
  file.append("order_id", data.order_id);
  file.append("order_price", data.order_price);
  file.append("postpaid_perc", data.postpaid_perc);
  file.append("postpaid_charges", data.postpaid_charges);
  file.append("final_order_price", data.final_order_price);
  file.append("user_id", data.user_id);
  file.append("payment_type", data.payment_type);
  file.append("status_id", data.status_id);
  file.append("status", data.status);
  file.append("conv_fee", data.conv_fee);
  file.append("conv_charges", data.conv_charges);
  file.append("filename", data.poFile);

  let error = "";
  let api = useAxios();
  return async (dispatch) => {
    try {
      toast.dark("Loading....", {
        position: "bottom-right",
        autoClose: 3000,
        type: "info",
      });
      let response = await api.post("/SSM/files/poupload", file);
      if (response.status === AppMetaData.httpStatus.SUCCESS) {
        CustomConsole(`response.status: ${response.status}}`);
        CustomConsole(response);
        dispatch({
          type: actionType.UPLOAD_PO_FILE,
          payload: { data: response.data, statusFlag: true },
        });
        toast.dark(response.data.msg, {
          position: "bottom-right",
          autoClose: 3000,
          type: "success",
        });
      } else {
        CustomConsole("Unhandled Error: " + error);
      }
    } catch (error) {
      CustomConsole(error);
    }
  };
};
