/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import { Box, Typography } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import appTheme from "../../../../Assets/AppTheme/appTheme";
import ReturnCanDetailsCard from "../../../Cards/Transactions/returnCanTransaction";
import PaginationComponent from "../../../Cards/pagination/pagination";
import {
  EditReplacementOrderSubmit,
  EditReturnOrder,
  RejectReplacementCanOrder,
  RejectReturnCanOrder,
  ReplacementOrderHistroy,
  ReturnCanOrderHistroy,
} from "../../../../Redux/actions/returnReplacementAction";

export default function ReturnCanOrderDetailsDialog({
  open,
  onClose,
  orderType,
  setIsReqReturnDialogOpen,
  setOpenReplacementDialog,
  selectedData,
}) {
  const dispatch = useDispatch();
  const ReturnReplacemtCanOrder = useSelector(
    (state) => state.ReturnReplacemtCanOrder
  );
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [quantity, setQuantity] = React.useState(1);
  const [editOrderId, setEditOrderId] = React.useState("");
  const [error, setError] = React.useState({ type: false, msg: "" });
  const [editType, setEditType] = React.useState("");
  const hasMounted = React.useRef(false);
  const handleClose = () => {
    onClose();
    setPage(1);
  };

  //function to hit return can api
  React.useEffect(() => {
    if (hasMounted.current) {
      if (orderType === "return") {
        dispatch(
          ReturnCanOrderHistroy(
            page - 1,
            10,
            selectedData?.container_capacity,
            selectedData?.container_material
          )
        );
      } else if (orderType === "replace") {
        dispatch(
          ReplacementOrderHistroy(
            page - 1,
            10,
            selectedData?.container_capacity,
            selectedData?.container_material
          )
        );
      }
    } else {
      hasMounted.current = true;
    }
  }, [page]);

  //function to handel return replace button
  const handelReturnReplaceButton = () => {
    if (orderType === "return") {
      if (selectedData?.filled_container_qty > 0) {
        setIsReqReturnDialogOpen(true);
        handleClose();
      } else {
        setError({
          type: true,
          msg: "You Don't Have enough filled products to return",
        });
      }
    } else {
      if (selectedData?.filled_container_qty > 0) {
        setOpenReplacementDialog(true);
        handleClose();
      } else {
        setError({
          type: true,
          msg: "You Don't Have enough filled products to return",
        });
      }
    }
  };

  //function to handel edit add quantity
  const handelAddQuantity = (data) => {
    if (selectedData?.filled_container_qty > quantity) {
      setQuantity(quantity + 1);
    }
  };

  //function to reduse quantity
  const handelReduseQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  //consst edit order click
  const handelEditOrder = (data) => {
    if (orderType === "return") {
      setEditOrderId(data?.return_can_order_id);
      setQuantity(data?.container_qty);
    } else {
      setEditOrderId(data?.order_id);
      setQuantity(data?.replace_can_qty);
    }
    setEditType("edit");
  };

  //function to handel edit submit
  const handelEditSubmit = () => {
    if (orderType === "return") {
      dispatch(EditReturnOrder(editOrderId, quantity)).then((resp) => {
        if (resp?.data?.error === false) {
          dispatch(
            ReturnCanOrderHistroy(
              page - 1,
              10,
              selectedData?.container_capacity,
              selectedData?.container_material
            )
          );
          setError({ type: false, msg: resp?.data?.msg });
          setEditOrderId("");
        } else if (resp?.data?.error === true) {
          setError({ type: true, msg: resp?.data?.msg });
          setEditOrderId("");
        }
      });
    } else {
      dispatch(EditReplacementOrderSubmit(editOrderId, quantity)).then(
        (resp) => {
          if (resp?.data?.error === false) {
            dispatch(
              ReplacementOrderHistroy(
                page - 1,
                10,
                selectedData?.container_capacity,
                selectedData?.container_material
              )
            );
            setError({ type: false, msg: resp?.data?.msg });
            setEditOrderId("");
          } else if (resp?.data?.error === true) {
            setError({ type: true, msg: resp?.data?.msg });
            setEditOrderId("");
          }
        }
      );
    }
  };

  //function to close error msg
  React.useEffect(() => {
    if (error.msg !== "") {
      setTimeout(() => {
        setError({
          type: false,
          msg: "",
        });
      }, 3000);
    }
  }, [error.msg]);

  //function to cancel edit order
  const handelSubmitCancel = (data) => {
    if (orderType === "return") {
      dispatch(RejectReturnCanOrder(data?.return_can_order_id)).then((resp) => {
        if (resp?.data?.error === false) {
          dispatch(
            ReturnCanOrderHistroy(
              page - 1,
              10,
              selectedData?.container_capacity,
              selectedData?.container_material
            )
          );
          setError({ type: false, msg: resp?.data?.msg });
          setEditOrderId("");
        } else if (resp?.data?.error === true) {
          setError({ type: true, msg: resp?.data?.msg });
          setEditOrderId("");
        }
      });
    } else {
      dispatch(RejectReplacementCanOrder(data?.order_id)).then((resp) => {
        if (resp?.data?.error === false) {
          dispatch(
            ReplacementOrderHistroy(
              page - 1,
              10,
              selectedData?.container_capacity,
              selectedData?.container_material
            )
          );
          setError({ type: false, msg: resp?.data?.msg });
          setEditOrderId("");
        } else if (resp?.data?.error === true) {
          setError({ type: true, msg: resp?.data?.msg });
          setEditOrderId("");
        }
      });
    }
  };

  //function to cancel edit order
  const handelSubmitCancelType = (data) => {
    if (orderType === "return") {
      setEditOrderId(data?.return_can_order_id);
      setQuantity(data?.container_qty);
    } else {
      setEditOrderId(data?.order_id);
      setQuantity(data?.replace_can_qty);
    }
    setEditType("cancel");
  };

  //function to close cancel order button
  const handelCloseButton = () => {
    setEditOrderId("");
  };
  return (
    <React.Fragment>
      <Dialog
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            width: { xs: window.innerWidth - 10, sm: "auto" }, // Set border radius for curved effect
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            p: 2,
            bgcolor: appColors.cardColor,
            color: appColors.headerTextColor,
            fontSize: appTheme.fontSizeLarge,
            fontWeight: "bold",
          }}
          id="customized-dialog-title"
        >
          {orderType === "return"
            ? "Return Product Details"
            : "Replacement Product Details"}
        </DialogTitle>
        {error.msg !== "" && (
          <Box
            sx={{
              background: error.type ? appColors.Failed : appColors.sucessColor,
              // borderRadius: ".5rem",
              padding: "0 0.5rem",
            }}
          >
            <span className="centerComponent errorMsgText">{error.msg}</span>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0 1rem",
            bgcolor: appColors.cardColor,
          }}
        >
          <Typography
            sx={{
              color: appColors.headerTextColor,
              fontSize: appTheme.fontSizeMedium,
              fontWeight: 500,
            }}
          >
            {selectedData?.container_capacity}&nbsp;
            {selectedData?.container_material}
          </Typography>
          <Button
            onClick={handelReturnReplaceButton}
            variant="contained"
            size="small"
            sx={{
              backgroundColor: appColors.buttonColorSec,
              color: appColors.cardColor,
              borderRadius: ".5rem",
              fontSize: appTheme.fontSizexs,
            }}
          >
            {orderType === "return"
              ? "+ Return Request"
              : "+ Replacement Request"}
          </Button>
        </Box>
        <Box sx={{ padding: ".5rem", background: appColors.cardColor }}>
          <ReturnCanDetailsCard
            data={ReturnReplacemtCanOrder}
            setreqobj=""
            setfilterDialog=""
            handelAddQuantity={handelAddQuantity}
            handelReduseQuantity={handelReduseQuantity}
            handelEditOrder={handelEditOrder}
            handelEditSubmit={handelEditSubmit}
            haandelSubmitCancel={handelSubmitCancel}
            editOrderId={editOrderId}
            quantity={quantity}
            setEditOrderId={setEditOrderId}
            orderType={orderType}
            editType={editType}
            handelCloseButton={handelCloseButton}
            handelSubmitCancelType={handelSubmitCancelType}
          />
        </Box>
        {page - 1 === 0 && ReturnReplacemtCanOrder?.pageStatus ? (
          ""
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              background: appColors.cardColor,
            }}
          >
            <PaginationComponent
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              page={page}
              setPage={setPage}
              pageStatus={ReturnReplacemtCanOrder?.pageStatus}
            />
          </Box>
        )}
        <DialogActions
          sx={{
            justifyContent: "space-evenly",
            background: appColors.cardColor,
          }}
        >
          <Button
            onClick={handleClose}
            variant="contained"
            size="small"
            sx={{
              backgroundColor: appColors.textColorNew,
              color: "#fff",
              marginTop: "1rem",
            }}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
