// import * as React from "react";
/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import * as Material from "@mui/material";
import * as FcIcons from "react-icons/fc";
import { styles } from "../../../../Components/DialogBoxes/NewProfileDialog/BilldialogStyles";
import LoadingButton from "@mui/lab/LoadingButton";
// import { verifyEmail } from "../../../Redux/actions/settingsAction";
import {
  verifyEmail,
  sendEmailOtp,
} from "../../../../Redux/actions/authAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { SendEmailOtp } from "../../../../Redux/actions/bookNowAction";
import { GetUserProfile } from "../../../../Redux/actions/profileAction";
import appColors from "../../../../Assets/AppTheme/appColors";
import CustomConsole from "../../../../coustomConsole";
import OTPInput from "react-otp-input";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function EmailVerificationInSettings(props) {
  const style = {
    loadingButton: {
      marginTop: "10px",
      width: "100px",
      backgroundColor: appColors.lightGreen,
      borderTopRightRadius: "15px",
      borderBottomLeftRadius: "15px",
      "&:hover": { backgroundColor: appColors.darkBlue },
      borderRadius: "0",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  };
  const inputs = useRef([]);
  const timerStart = 30;
  const [open, setOpen] = React.useState(false);
  const [emailVerificationCode, setEmailVerificationCode] = useState("");
  const [emailVerify, setEmailVerify] = useState(false);
  const [eloading, setELoading] = useState(false);
  const [verifyYourEmail, setVerifyYourEmail] = useState(false);
  const [yesOrNo, setYesOrNo] = useState(true);
  const dispatch = useDispatch();
  const bookNowState = useSelector((state) => state.bookNow);
  const auth = useSelector((state) => state.authentications);
  const profile = useSelector((state) => state.userProfile);
  const [emailCounter, setEmailCounter] = React.useState(timerStart);
  const handleClickOpen = () => {
    setOpen(true);
  };

  React.useEffect(() => {
    CustomConsole(profile);
    if (profile.user.is_email_verified === true) {
      //   props.setEmailInvoiceClicked(false);
      //   props.setIsEmailVerified(profile.user.is_email_verified);
    }
  }, [profile]);

  // const handleChange = (index, value) => {
  //   // This will auto move the cursor to next textfield as the user enter there OTP
  //   CustomConsole(inputs.current.length);
  //   if (value.length === 1 && index < inputs.current.length - 1) {
  //     inputs.current[index + 1].focus();
  //   }
  //   if (value.length !== 1) {
  //     inputs.current[index - 1].focus();
  //   }
  //   // Update the OTP value
  //   const newOtp =
  //     emailVerificationCode.substring(0, index) +
  //     value +
  //     emailVerificationCode.substring(index + 1);
  //   CustomConsole(newOtp);
  //   setEmailVerificationCode(newOtp);
  // };

  React.useEffect(() => {
    // CustomConsole(`auth ${JSON.stringify(auth)}`);
    CustomConsole(auth);
  }, [auth]);

  const handleClose = () => {
    props.setVerifyEmailBtn(false);
    setOpen(false);
    setYesOrNo(true)
    setVerifyYourEmail(false)
    setEmailCounter(30)
  };
  React.useEffect(() => {
    // dispatch(sendEmailOtp());
    if (verifyYourEmail === true) {
      dispatch(SendEmailOtp());
    }
    CustomConsole(bookNowState);
  }, [verifyYourEmail]);

  function handleEmailSubmit() {
    if (emailVerificationCode !== null && emailVerificationCode.length === 6) {
      setELoading(true);
      dispatch(verifyEmail(emailVerificationCode));
      setTimeout(() => {
        dispatch(GetUserProfile());
      }, 2000);
      setTimeout(() => setELoading(false), 2000);
    } else {
      toast.dark("Please Enter a 6-Digit Valid Email OTP", {
        type: "error",
        position: "bottom-right",
        autoClose: 5000,
      });
    }
  }

  React.useEffect(() => {
    CustomConsole(auth);
    if (auth.error && eloading) {
      CustomConsole("inside first if");
      setEmailVerify(false);
    } else if (!auth.error && eloading) {
      auth.error = true;
      setEmailVerify(true);
      setTimeout(()=>{
        handleClose()
      },1000)
    }
  }, [auth, eloading]);
  const handleYes = () => {
    setVerifyYourEmail(true);
    setYesOrNo(false);
  };

  const handleNo = () => {
    props.setEmailInvoiceClicked(false);
    handleClose();
  };

  //Resend Email OTP
  const handleEmailResend = () => {
    setEmailCounter(timerStart);
    //Re-Send OTP to Email
    dispatch(SendEmailOtp());
  };

  React.useEffect(() => {
    const timer =
      emailCounter > 0 &&
      setInterval(() => setEmailCounter(emailCounter - 1), 1000);
    return () => clearInterval(timer);
  }, [emailCounter]);
  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Slide in alert dialog
      </Button> */}
      <Dialog
        open={props.verifyEmailBtn}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ bgcolor: appColors.secondaryCard }}>
          {yesOrNo ? (
            <>
              <Material.Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Material.Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "bold",
                    color: appColors.darkGray,
                  }}
                  display="block"
                >
                  Verify & proceed to get invoices for the upcoming orders.
                </Material.Typography>
                <Material.Box sx={{ display: "flex", gap: "10px" }}>
                  <Material.Button
                    variant="contained"
                    size="small"
                    onClick={handleNo}
                    sx={{ backgroundColor: appColors.Failed }}
                  >
                    Cancel
                  </Material.Button>
                  <Material.Button
                    variant="contained"
                    size="small"
                    onClick={handleYes}
                    sx={{ backgroundColor: appColors.textColorNew }}
                  >
                    Proceed
                  </Material.Button>
                </Material.Box>
              </Material.Box>
            </>
          ) : (
            ""
          )}

          {verifyYourEmail ? (
            <>
              <Material.Typography
                sx={{
                  fontSize: ".9rem",
                  fontWeight: "bold",
                  display: "flex",
                  color: appColors.secondaryText,
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign:"center"
                }}
                gutterBottom
              >
                Enter the OTP sent to {profile?.user?.email}
              </Material.Typography>

              <DialogContentText id="alert-dialog-slide-description">
                <Material.Box className="otp-container">
                  {/* {[0, 1, 2, 3, 4, 5].map((index) => (
                    <input
                      key={index}
                      type="number"
                      pattern="[0-9]*"
                      inputMode="numeric"
                      maxLength="1"
                      className="otp-input"
                      ref={(el) => (inputs.current[index] = el)}
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  ))} */}
                  <OTPInput
                    value={emailVerificationCode}
                    onChange={(otp) => {
                      // Ensure only numbers are entered
                      const filteredOtp = otp.replace(/\D/g, "");
                      // Remove non-digit characters
                      if (/^\d*$/.test(filteredOtp)) {
                        setEmailVerificationCode(filteredOtp);
                      }
                    }}
                    inputStyle="inputStyle"
                    numInputs={6}
                    renderSeparator={<span></span>}
                    renderInput={(props) => <input {...props} />}
                  />
                </Material.Box>
                <Button
                  disabled={emailCounter !== 0 || emailVerify}
                  sx={styles.resendButton}
                  onClick={handleEmailResend}
                >
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: appColors.secondaryText,
                      fontWeight: "bold",
                    }}
                  >
                    Resend OTP in
                  </span>{" "}
                  {/* Timer for Email Verification */}
                  <span style={{ color: appColors.darkGray }}>
                    &nbsp;00:{emailCounter}
                  </span>
                </Button>
              </DialogContentText>
              <Material.Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginTop: ".5rem",
                }}
              >
                <Button
                  disabled={emailVerify}
                  size="small"
                  onClick={handleClose}
                  variant="contained"
                  sx={{ backgroundColor: appColors.textColorNew }}
                >
                  Close
                </Button>
                <LoadingButton
                  disabled={emailVerify}
                  type="submit"
                  size="small"
                  onClick={handleEmailSubmit}
                  loading={eloading}
                  variant="contained"
                  startIcon={emailVerify ? <FcIcons.FcOk /> : ""}
                  sx={{ backgroundColor: appColors.textColorNew }}
                >
                  {emailVerify ? "Verified" : "Verify"}
                </LoadingButton>
              </Material.Box>
            </>
          ) : (
            ""
          )}
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose}>Verify</Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
