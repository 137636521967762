/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

export const url = process.env.REACT_APP_BW_URL;
// export const url = "https://link.bookwater.com";

export const setHeaders = () => { 
  const headers = {
    headers: {
      "x-auth-token": localStorage.getItem("token"),
    },
  }; 
  return headers;
};






