/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date
 * @version
 */
import React from "react";
import { Button, Grid, Box } from "@mui/material";
import LogoutConfirmationDialog from "../../Components/DialogBoxes/Authentication/logoutDialog";
import { useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Slide from "@mui/material/Slide";
import NonLinearSlider from "./swipableButton";
import Biconcard from "../../Assets/newMobileIcon/Water wallet without Card Background.svg";
import appColors from "../../Assets/AppTheme/appColors";
import OtpDialog from "../../Components/DialogBoxes/PdwBookingSummary/otpDialog";
import {
  BrowserRouter as Router,
  Redirect,
  useHistory,
} from "react-router-dom";
import { MenuStyle } from "./MobileViewcss";
import { Card, CardContent } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FooterStyles } from "./MobileViewcss";
import IconListManager from "../../Assets/AppTheme/appIcons";
import chipIcon from "../../Assets/newMobileIcon/chip.svg";
import actionTypes from "./../../Redux/actions/actionTypes";

const useStyles = makeStyles({
  atmCard: {
    backgroundColor: appColors.walletCard,
    color: "white",
    borderRadius: 10,
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  cardNumber: {
    fontSize: 18,
    marginBottom: 15,
  },
  cardHolder: {
    fontSize: 16,
    color: "#d4ebff",
    textTransform: "uppercase",
  },
  expiration: {
    fontSize: 14,
  },
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const MenuOptionDialog = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const IconList = IconListManager();
  const orderDetails = useSelector((state) => state.ordersDetails);
  const appmetaDataDetail = useSelector((state) => state.appMetaConfigData);
  const walletDetails = useSelector((state) => state.paymentMethods);
  const [otpFlag, setOtpFlag] = React.useState(false);
  const [SwipeText, setSwipeText] = React.useState("Swipe to Recharge");
  const [logOutDialogOpen, setlogOutDialogOpen] = React.useState(false);
  const [isLogOut, setIsLogOut] = React.useState(false);
  const [minimumWalletAmount, setMinimumWalletAmount] = React.useState(0);
  const [amountTorecharge, setAmountToRecharge] = React.useState(0);
  const profile = useSelector((state) => state.userProfile);
  const history = useHistory();
  const classes = useStyles();
  const [isFirstVisitMenu, setIsFirstVisitMenu] = React.useState(
    localStorage.getItem("visiteddcxsBeforeMenu")
  );
  const [isTourOpenMenu, setIsTourOpenMenu] = React.useState(false);

  //UseEffect for triggering app tour component
  React.useEffect(() => {
    if (isFirstVisitMenu) {
      setIsTourOpenMenu(true);
    }
  }, [isFirstVisitMenu]);

  //minimumwalletAmount updating
  React.useEffect(() => {
    // console.log(appmetaDataDetail)
    appmetaDataDetail.metaData.map((data) => {
      // console.log(data)
      if (data.key === "minimum_wallet_amount") {
        // console.log(data.value)
        setMinimumWalletAmount(data.value[0].v);
      }
    });
  }, [appmetaDataDetail]);
  const handleOpenDialog = () => {
    setlogOutDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setlogOutDialogOpen(false);
  };

  //minimumwalletAmount updating
  React.useEffect(() => {
    // console.log(appmetaDataDetail)
    setAmountToRecharge(parseInt(minimumWalletAmount * 32.99));
  }, [minimumWalletAmount]);

  // Function for handling logout
  const handleConfirmLogout = () => {
    dispatch({
      type: actionTypes.SIGN_OUT,
    });
    setIsLogOut(true);
  };

  if (isLogOut) {
    return <Redirect to="authenticate" />;
  }

  const redirectSubs = () => {
    history.push("/dashboard/subscriptionList");
  };

  function handelRouteonClick(value) {
    switch (value) {
      case 0:
        history.push("/dashboard/settings");
        break;
      case 1:
        history.push("/dashboard/settings?pageName=address");
        break;
      case 2:
        history.push("/dashboard/myaccounts");

        break;
      case 3:
        history.push("/dashboard/referandearn");
        break;
      case 4:
        history.push("/dashboard/myorders");
        break;
      case 5:
        redirectSubs();
        break;
      case 6:
        history.push("/dashboard/transactions");
        break;
      case 7:
        history.push("/dashboard/deposit");
        break;
      case 8:
        history.push("/dashboard/support");
        break;
      case 9:
        history.push("/dashboard/legal");
        break;
      case 10:
        history.push("/dashboard/home?pageName=faq");
        break;
      case 11:
        history.push(`/dashboard/wallet?rechargeValue=${amountTorecharge}`);
        break;
      case 12:
        history.push("/mobile");
        break;
      case 13:
        history.push("/dashboard/giftcards");
        break;
    }
  }

  const handelPassAmount = (value) => {
    setAmountToRecharge(value);
  };

  const buttonValues = [1, 2, 5, 10, 32.99];

  const renderButtons = () => {
    return buttonValues.map((value) => {
      const isButtonActive =
        amountTorecharge === parseInt(minimumWalletAmount * value);
      // console.log(minimumWalletAmount * value);
      return (
        <Button
          key={value}
          variant="contained"
          sx={{
            marginRight: ".5rem",
            zIndex: 3,
            fontSize: ".75rem",
            width: "5.3rem",
            marginTop: isButtonActive ? "1rem" : "1rem",
            backgroundColor: isButtonActive
              ? appColors.lightblue
              : appColors.textColorNew,
            color: isButtonActive ? appColors.textColorNew : appColors.white,
            "&:hover": {
              backgroundColor: isButtonActive
                ? appColors.lightblue
                : appColors.textColorNew,
              color: isButtonActive ? appColors.textColorNew : appColors.white,
            },
          }}
          onClick={() =>
            handelPassAmount(parseInt(minimumWalletAmount * value))
          }
        >
          ₹ {parseInt(minimumWalletAmount * value)}
        </Button>
      );
    });
  };

  //Function for closing app tour and setting up a variable in local storage
  const closeTour = () => {
    setIsTourOpenMenu(false);
    localStorage.setItem("visitedBeforeMenu", true);
  };

  return (
    <Box sx={{ background: appColors.backGround }}>
      {/* <AppBar sx={{ position: "relative", background: appColors.appBrColor }}>
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography
              sx={MenuStyle.homeText}
              onClick={() => handelRouteonClick(12)}
            >
              <KeyboardBackspaceIcon /> Home
            </Typography>
          </Box>
          <Box className="tourDarkMode">
            <DarkLightThemeSwitch />
          </Box>
        </Toolbar>
      </AppBar> */}
      <Box>
        <Grid container direction="column">
          <Grid item xs={12}>
            <Typography sx={MenuStyle.homeText}>Water Wallet</Typography>
          </Grid>
          <Grid item xs={12} sx={{ marginTop: "2rem" }}>
            <Card
              className={classes.atmCard}
              elevation={3}
              sx={{ position: "relative" }}
            >
              <CardContent sx={{ padding: "1rem 1rem 0 1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    <Typography sx={{ color: "#d4ebff" }}>Name</Typography>
                    <Typography className={classes.cardNumber}>
                      {profile.user.fullname}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={classes.cardHolder}>
                      Balance
                    </Typography>
                    <Typography className={classes.expiration}>
                      ₹{parseFloat(walletDetails?.walletAmount)}
                    </Typography>
                  </Box>
                </Box>
                <Typography sx={{ color: "#d4ebff" }}>
                  Wallet Recharge Options
                </Typography>
                {renderButtons()}
                <Box sx={MenuStyle.chipCard}>
                  <Box>
                    <NonLinearSlider
                      handelRouteonClick={handelRouteonClick}
                      SwipeText={SwipeText}
                    />
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <img
                      alt="bookwater"
                      src={chipIcon}
                      style={{ height: "2.5rem" }}
                    />
                  </Box>
                </Box>
              </CardContent>
              <img
                alt="bookwater"
                src={Biconcard}
                style={{
                  position: "absolute",
                  height: "22rem",
                  marginLeft: "4rem",
                  objectFit: "fil",
                  zIndex: 1,
                }}
              />
            </Card>
          </Grid>

          {/* personol details card */}
          <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
            <Typography sx={MenuStyle.homeText}>Personal</Typography>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{ background: appColors.cardColor, borderRadius: "10px" }}
            >
              <Box sx={{ padding: "1rem" }}>
                <Box sx={MenuStyle.footerIcons}>
                  <Box
                    onClick={() => handelRouteonClick(0)}
                    sx={[FooterStyles.orderStyle, { marginLeft: ".7rem" }]}
                  >
                    <img src={IconList.profileIcon} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      Profile
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handelRouteonClick(1)}
                    sx={FooterStyles.orderStyle}
                  >
                    <img src={IconList.addressIcon} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      Address
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handelRouteonClick(2)}
                    sx={FooterStyles.orderStyle}
                  >
                    <img src={IconList.bankIcon} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      Bank
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handelRouteonClick(7)}
                    sx={FooterStyles.orderStyle}
                  >
                    <img src={IconList.depositIcon} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      Deposit
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* order and Transaction */}
          <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
            <Typography sx={MenuStyle.homeText}>
              Orders & Transactions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{ background: appColors.cardColor, borderRadius: "10px" }}
            >
              <Box sx={{ padding: "1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "flex-end",
                  }}
                >
                  <Box
                    onClick={() => handelRouteonClick(4)}
                    sx={FooterStyles.orderStyle}
                  >
                    <img src={IconList.myOrders} alt="bookwater" />
                    <Typography sx={MenuStyle.WallettextDetails} align="center">
                      My Orders
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handelRouteonClick(5)}
                    sx={FooterStyles.orderStyle}
                    className="tourSubscribe"
                  >
                    <img src={IconList.subcribeIcon} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      Subscribe
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => handelRouteonClick(6)}
                    sx={[FooterStyles.orderStyle, { marginRight: ".5rem" }]}
                  >
                    <img src={IconList.transaction} alt="bookwater" />
                    <Typography
                      sx={[MenuStyle.PaymenttextDetails]}
                      align="center"
                    >
                      Transaction
                    </Typography>
                  </Box>
                  <Box
                    onClick={() => setOtpFlag(true)}
                    sx={[FooterStyles.orderStyle, { marginRight: ".5rem" }]}
                  >
                    <img src={IconList.menuOtp} alt="bookwater" />
                    <Typography sx={MenuStyle.textDetails} align="center">
                      OTP
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Card>
          </Grid>

          {/* Others */}
          <Grid item xs={12} sx={{ marginTop: "1rem", marginBottom: ".5rem" }}>
            <Typography sx={MenuStyle.homeText}>Others</Typography>
          </Grid>
          <Grid item xs={12}>
            <Card
              elevation={3}
              sx={{ background: appColors.cardColor, borderRadius: "10px" }}
            >
              <Box sx={{ padding: "1rem" }}>
                <Box>
                  <Box sx={MenuStyle.footerIcons}>
                    <Box
                      onClick={() => handelRouteonClick(3)}
                      sx={[FooterStyles.orderStyle, { marginLeft: ".7rem" }]}
                    >
                      <img src={IconList.referIcon} alt="bookwater" />
                      <Typography
                        sx={[MenuStyle.WallettextDetails]}
                        align="center"
                      >
                        Refer
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => handelRouteonClick(8)}
                      sx={FooterStyles.orderStyle}
                    >
                      <img src={IconList.supportIcon} alt="bookwater" />
                      <Typography sx={MenuStyle.textDetails} align="center">
                        Support
                      </Typography>
                    </Box>
                    <Box
                      onClick={() => handelRouteonClick(9)}
                      sx={FooterStyles.orderStyle}
                    >
                      <img src={IconList.legalIcon} alt="bookwater" />
                      <Typography sx={MenuStyle.textDetails} align="center">
                        Legal
                      </Typography>
                    </Box>
                    {/* <Box
                      onClick={handleOpenDialog}
                      sx={[FooterStyles.orderStyle, { marginLeft: ".3rem" }]}
                    >
                      <img src={IconList.logoutIcon} alt="bookwater" />
                      <Typography sx={MenuStyle.textDetails} align="center">
                        Logout
                      </Typography>
                    </Box> */}
                    <Box
                      onClick={() => handelRouteonClick(13)}
                      sx={FooterStyles.orderStyle}
                    >
                      <img
                        src={IconList.GiftCardSmall}
                        alt="bookwater"
                        sx={{}}
                      />
                      <Typography sx={MenuStyle.textDetails} align="center">
                        Gift Cards
                      </Typography>
                    </Box>
                  </Box>
                  {/* <Box sx={[MenuStyle.footerIcons, { marginTop: ".5rem" }]}>
                    <Box
                      onClick={handleOpenDialog}
                      sx={[FooterStyles.orderStyle, { marginLeft: ".3rem" }]}
                    >
                      <img src={IconList.logoutIcon} alt="bookwater" />
                      <Typography sx={MenuStyle.textDetails} align="center">
                        Logout
                      </Typography>
                    </Box>
                  </Box> */}
                </Box>
              </Box>
            </Card>
          </Grid>
          <Grid>
            <Typography
              sx={{
                marginTop: "1rem",
                color: appColors.darkGray,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {" "}
              BookWater: {process.env.REACT_APP_VERSION}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      {/* <DialogActions sx={{background:"transparent"}}>
                <Button size="small" onClick={onClose} >
                    Close
                </Button>
            </DialogActions> */}
      <LogoutConfirmationDialog
        open={logOutDialogOpen}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmLogout}
      />
      {otpFlag && <OtpDialog otpFlag={otpFlag} setOtpFlag={setOtpFlag} />}
    </Box>
  );
};

export default MenuOptionDialog;
