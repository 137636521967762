import React from "react";
import * as Material from "@mui/material";
import { newBookingstyles } from "../../../Pages/BookNow/SkuBooking/newBookingPageStyles";
import appColors from "../../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import WaterDropApplyButton from "../../SkuButtons/waterDropButton";
export default function SubScriptionPromoSelectionCard({
  setProducts,
  products,
  setpromoCode,
}) {
  const dispatch = useDispatch();
  const SkuSelectedProducts = useSelector((state) => state.skuBookDetails);
  const [waterDropFlag, setWaterDropFlag] = React.useState(
    SkuSelectedProducts?.selectedProduct?.is_auto_apply_water_drops !==
      undefined
      ? SkuSelectedProducts?.selectedProduct?.is_auto_apply_water_drops
      : true
  );
  const [couponCodeFlag, setCouponCodeFlag] = React.useState(
    SkuSelectedProducts?.selectedProduct?.is_auto_apply_coupon !== undefined
      ? SkuSelectedProducts?.selectedProduct?.is_auto_apply_coupon
      : false
  );
  //function to handel check box click
  const handelClick = (e) => {
    setProducts((prev) => ({
      ...prev,
      is_auto_apply_water_drops: e.target.checked,
    }));
    setWaterDropFlag(e.target.checked);
  };

  //function to handel coupon code click
  const handelCouponClick = (e) => {
    if (e.target.checked === false) {
      setpromoCode("");
      setProducts((prev) => ({
        ...prev,
        coupon_code: "",
        is_auto_apply_coupon: e.target.checked,
      }));
      setCouponCodeFlag(e.target.checked);
    } else {
      setProducts((prev) => ({
        ...prev,
        is_auto_apply_coupon: e.target.checked,
      }));
      setCouponCodeFlag(e.target.checked);
    }
  };


  return (
    <>
      <Material.Typography sx={newBookingstyles.cardHeaderStyle}>
        Coupons & Discounts
      </Material.Typography>
      <Material.Card sx={newBookingstyles.cardStylesCoupon}>
        <Material.Box sx={newBookingstyles.orderBox}>
          <Material.Typography sx={newBookingstyles.BoxHeader}>
            <Material.Stack direction={{ xs: "column" }}>
              <Material.Box sx={{ width: "100%" }}>
                <Material.Box
                  sx={{
                    width: "100%",
                    borderRadius: "5px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Material.Typography
                    sx={{
                      fontSize: "0.95em",
                      fontWeight: "500",
                      textAlign: "left",
                      color: appColors.darkGray,
                      display: "flex",
                      alignItems: "center",
                    }}
                    noWrap
                  >
                    <Material.Checkbox
                      size="small"
                      onClick={handelClick}
                      checked={waterDropFlag}
                    />
                    Use Water Drops by Default
                  </Material.Typography>
                </Material.Box>
                <Material.Box
                  sx={{
                    width: "100%",
                    borderRadius: "5px",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Material.Typography
                    sx={{
                      fontSize: "0.95em",
                      fontWeight: "500",
                      textAlign: "left",
                      color: appColors.darkGray,
                      display: "flex",
                      alignItems: "center",
                    }}
                    noWrap
                  >
                    <Material.Checkbox
                      size="small"
                      onClick={handelCouponClick}
                      checked={couponCodeFlag}
                    />
                    Use Coupons by Default
                  </Material.Typography>
                </Material.Box>
              </Material.Box>
            </Material.Stack>
          </Material.Typography>
        </Material.Box>
      </Material.Card>
    </>
  );
}
