/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath R
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogContent, IconButton } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import ImageLoader from "../../Utils/imageLoader";
import useProductImages from "../../Utils/productImages";
import ShareIcon from "@mui/icons-material/Share";
import {
  GetAvailedGiftCards,
  RedeemGiftCardAction,
} from "../../../Redux/actions/giftCardAction";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import GiftBOX from "../../../Assets/newMobileIcon/menu/giftBox.svg";
import appTheme from "../../../Assets/AppTheme/appTheme";
import CancelIcon from "@mui/icons-material/Cancel";
import { GetWalletDetails } from "../../../Redux/actions/paymentMethodAction";
import moment from "moment";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AvailedGiftCardDialog({
  openDialog,
  setOpenDialog,
  giftCardId,
  handleFilterSubmit,
  handelOpenPaymentDialog,
}) {
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.userProfile);

  const [password, setPassword] = React.useState("");
  const [images, setImgages] = React.useState([]);
  const [error, setError] = React.useState("");
  const [claimError, setClaimError] = React.useState("");
  const [giftCardDetails, setGiftCardDetails] = React.useState({});
  const [giftCardPasswordDetails, setGiftCardPasswordDetails] =
    React.useState(false);
  const [expiryDate, setExpiryDate] = React.useState(false);

  // coustom hook to get gift card images
  const productImages = useProductImages(images, "giftcard");
  //For closing dialog box
  const handleClose = () => {
    setOpenDialog(false);
    setPassword("");
  };

  console.log("giftCardDetails");
  console.log(giftCardDetails);
  //useEffect to set images
  React.useEffect(() => {
    if (giftCardDetails?.greeting?.selectedImage) {
      setImgages([giftCardDetails?.greeting?.selectedImage]);
    }
  }, [giftCardDetails]);

  //function to handel password changes
  const handelEnterPassword = (e) => {
    setPassword(e.target.value);
  };

  //Function to handel password change and api hit
  React.useEffect(() => {
    if (password?.length === 6) {
      const reqObject = {
        giftCardId: giftCardDetails?.giftCardId,
        giftCardPassword: password, // Include the password in the reqObject
      };
      handelRedeemAction(reqObject);
    }
  }, [password]);
  //function to handel api call for redeem
  const handelRedeemAction = (reqObject) => {
    dispatch(RedeemGiftCardAction(reqObject)).then((data) => {
      if (data?.error) {
        setError(data?.msg);
        setClaimError(data?.msg);
        console.log(data);
        if (Object.keys(data?.data).length) {
          setGiftCardPasswordDetails(data?.data?.isPasswordCorrect);
        }
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: data?.msg,
          })
        );
      } else if (!data?.error) {
        setGiftCardPasswordDetails(data?.data?.isPasswordCorrect);
        dispatch(GetWalletDetails());
        handleFilterSubmit();
        dispatch(
          ToastMsgState({
            error: false,
            containerFlag: true,
            msg: data?.msg,
          })
        );
        handleClose();
      } else {
        // setGiftCardPasswordDetails(data?.data?.isPasswordCorrect);
      }
    });
  };

  //function to handel add to cart
  const handelAddToList = () => {
    const reqObject = {
      giftCardId: giftCardDetails?.giftCardDetails?.giftCardId,
      giftCardPassword: password,
      isProceedTo: "ADDTOLIST",
    };
    handelRedeemAction(reqObject);
  };

  //function to handel redeem now button
  const handelRedeemNow = () => {
    const reqObject = {
      giftCardId: giftCardDetails?.giftCardId,
      giftCardPassword: giftCardDetails?.password,
      isProceedTo: "REDEEMNOW",
    };
    handelRedeemAction(reqObject);
  };

  //useEffect to set usestate as empty
  React.useEffect(() => {
    if (claimError !== "") {
      setTimeout(() => {
        setClaimError("");
      }, 5000);
    }
  }, [claimError]);

  //useEffect to get giftcardDetails
  React.useEffect(() => {
    if (giftCardId !== "") {
      const reqObj = {
        giftCardId: giftCardId,
      };

      dispatch(GetAvailedGiftCards(reqObj)).then((data) => {
        if (!data?.error) {
          setGiftCardDetails(data?.data[0]?.details);
        } else {
          setGiftCardDetails({});
        }
      });
    }
  }, [giftCardId]);
  console.log("giftCardDetails");

  console.log(giftCardDetails);
  console.log(giftCardDetails);

  //function to share order details
  const shareContent = async (data) => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: document.title,
          text: `BookWater Order Details

Gift Card Id: ${data?.giftCardId}
Gifted By: ${data?.creatorName}
Expiry Date: ${data.expiryDate} 
Status: ${expiryDate ? "Expired" : viewStatus(data?.status)}
Greeting Message: ${data?.greeting?.ownWishes}
Total Price: ₹ ${data?.payingAmount}


`,
        });
      } else {
        throw new Error("Web Share API is not supported in this browser.");
      }
    } catch (error) {
      console.error("Error sharing content:", error);
      // Fallback behavior in case sharing fails
      // You can provide alternative methods here, such as opening a modal with sharing options
    }
  };

  //function to get details of status
  // Function to get details of status
  const viewStatus = (status) => {
    switch (status) {
      case 100:
        return "Pending";
      case 200:
        return "Claim";
      case 300:
        return "Claimed";
      case 400:
        return "Expired";
      default:
        return "Gift";
    }
  };

  //useEffect to caluculate expiry date
  React.useEffect(() => {
    if (giftCardDetails) {
      let expiryDate = moment(giftCardDetails?.expiryDate);
      const today = moment();
      setExpiryDate(expiryDate.isBefore(today, "day"));
    }
  }, [giftCardDetails]);
  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: "#038ec2cc" }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent
          sx={{
            position: "relative",
            overflow: "hidden",
            background: appColors.giftCardColor,
            padding: 0,
            width: {
              xs: window.innerWidth - 75,
              sm: "20rem",
              md: "25rem",
              lg: "30rem",
              xl: "35rem",
            },
          }}
        >
          <Box sx={dialogStyles.cardStyle}>
            <Box sx={dialogStyles.cardHeader}>
              <Box>
                <Typography sx={dialogStyles.header}>
                  {giftCardDetails?.giftCardId}
                </Typography>
                {/* <Typography
                  sx={{ fontSize: ".9rem", margin: 0, textAlign: "start" }}
                >
                  Gift Card Id
                </Typography> */}
              </Box>
              <Box sx={dialogStyles.subHeader}>
                <Typography sx={{ fontSize: ".8rem" }}>
                  {expiryDate ? "Expired" : viewStatus(giftCardDetails?.status)}
                </Typography>
              </Box>
            </Box>

            <Box sx={dialogStyles.imageContent}>
              <Box sx={dialogStyles.giftCardImage}>
                <Box sx={dialogStyles.imageContainer}>
                  <ImageLoader
                    filename={giftCardDetails?.greeting?.selectedImage}
                    styles={{
                      // Allow the height to adjust according to the aspect ratio
                      width: "10rem",
                      height: "4rem",
                      borderRadius: ".3rem",
                      
                    }}
                  />{" "}
                </Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  ₹ {giftCardDetails?.payingAmount}
                </Typography>
              </Box>
              <Box onClick={() => shareContent(giftCardDetails)}>
                <ShareIcon
                  style={{
                    color: appColors.giftCardColor,
                    transform: "rotate(360deg)",
                  }}
                />
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Typography sx={dialogStyles.subHeaderText}>
                Wishes:
                <span style={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.greeting?.ownWishes}
                </span>
              </Typography>
            </Box>
            {/* Created At and Expiry date info */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: ".5rem",
              }}
            >
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Created At
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.createdDate}
                </Typography>
              </Box>
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Expiry Date
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.expiryDate}
                </Typography>
              </Box>
            </Box>

            {/* Password and redeem info */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                padding: ".5rem",
              }}
            >
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Password
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.isPasswordNeeded
                    ? giftCardDetails?.password
                    : "Not required"}
                </Typography>
              </Box>
              <Box>
                <Typography sx={dialogStyles.subHeaderText}>
                  Redeem Through
                </Typography>
                <Typography sx={dialogStyles.subHeaderText1}>
                  {giftCardDetails?.redeemThrough}
                </Typography>
              </Box>
            </Box>
            <div
              style={{ width: "100%", borderBottom: "2px dashed gray" }}
            ></div>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Creater Name:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorName}
                  </span>
                </Typography>
                {/* <Typography sx={dialogStyles.subHeaderText}>
                  Created Date {giftCardDetails?.createdDate}
                </Typography> */}
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Creater Phone:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorPhone}
                  </span>
                </Typography>
                {/* <Typography sx={dialogStyles.subHeaderText}>
                  Created Date {giftCardDetails?.createdDate}
                </Typography> */}
              </Box>
            </Box>
            <Box sx={{ padding: "0 .5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography sx={dialogStyles.subHeaderText}>
                  Creater Email:
                  <span style={dialogStyles.subHeaderText1}>
                    {giftCardDetails?.creatorEmail}
                  </span>
                </Typography>
                {/* <Typography sx={dialogStyles.subHeaderText}>
                  Created Date {giftCardDetails?.createdDate}
                </Typography> */}
              </Box>
            </Box>

            <div
              style={{ width: "100%", borderBottom: "2px dashed gray" }}
            ></div>
            {giftCardDetails?.receiver?.name && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Receiver Name:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.name}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {giftCardDetails?.receiver?.name && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Receiver Phone:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.phone}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {giftCardDetails?.receiver?.email && (
              <Box sx={{ padding: "0 .5rem" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography sx={dialogStyles.subHeaderText}>
                    Receiver Email:{" "}
                    <span style={dialogStyles.subHeaderText1}>
                      {giftCardDetails?.receiver?.email}
                    </span>
                  </Typography>
                </Box>
              </Box>
            )}
            {/* {giftCardDetails?.isPasswordNeeded &&
            !giftCardPasswordDetails &&
            giftCardDetails?.status === 200 ? (
              <Box>
                <Typography
                  style={{
                    fontSize: appTheme.fontSizeMedium,
                    color: appColors.darkGray,
                    fontWeight: "600",
                  }}
                >
                  Enter Gift Card Password :
                </Typography>
                <StyledInputField
                  placeholder="Password"
                  text="password"
                  handelTextField={handelEnterPassword}
                  value={password}
                  error={error}
                />
              </Box>
            ) : (
              ""
            )} */}
            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              {(giftCardDetails?.status === 200 &&
                giftCardDetails?.receiver?.userId ===
                  profileDetails?.user?.user_id &&
                !expiryDate) ||
              (giftCardDetails?.isAnyOneCanUse &&
                giftCardDetails?.status === 200 &&
                !expiryDate) ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop:".5rem"
                  }}
                >
                  <Button
                    sx={{
                      color: appColors.white,
                      background: appColors.giftCardColor,
                      borderRadius: "1rem",
                      "&:hover": {
                        background: appColors.giftCardColor,
                      },
                    }}
                    varient="contained"
                    size="small"
                    onClick={handelRedeemNow}
                  >
                    Redeem Now
                  </Button>
                </Box>
              ) : (
                ""
              )}
              {giftCardDetails?.status === 100 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    sx={{
                      color: appColors.white,
                      background: appColors.giftCardColor,
                      borderRadius: "1rem",
                      "&:hover": {
                        background: appColors.giftCardColor,
                      },
                    }}
                    varient="contained"
                    size="small"
                    onClick={() =>
                      handelOpenPaymentDialog(giftCardDetails?.payingAmount)
                    }
                  >
                    Proceed to payment
                  </Button>
                </Box>
              )}
            </Box>
            <Box sx={{ position: "absolute", top: 150, right: 100 }}>
              <img
                src={GiftBOX}
                alt="Bookwater"
                style={{ height: "5rem", width: "5rem" }}
              />
            </Box>
          </Box>
          <Box sx={{ textAlign: "center" }}>
            <IconButton
              aria-label="fingerprint"
              color="success"
              onClick={handleClose}
              sx={{ padding: "0 0 .3rem 0" }}
            >
              <CancelIcon sx={{ color: appColors.white }} />
            </IconButton>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

const dialogStyles = {
  headingBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  cardStyle: {
    boxShadow: 5,
    transition: "0.3s",

    height: "auto",
    borderRadius: "1rem",
    position: "relative",
    background: "#fff",
    padding: "0 0 0.5rem 0",
    marginBottom: ".5rem",
  },
  cardHeader: {
    background: "linear-gradient(180deg, #038ec2 25%, #5ad0fe 100%);",
    width: "100%",
    height: "30%",
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "space-between",
    padding: "0 .8rem",
  },
  header: {
    margin: 0,
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  subHeaderText: {
    margin: 0,
    fontSize: appTheme.fontSizeSmall,
    color: appColors.giftCardColor,
    fontWeight: "bold",
  },
  subHeaderText1: {
    margin: 0,
    color: "#7e7e7e",
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  subHeader: {
    height: "60%",
    width: "auto",
    background: "#038ec2",
    marginBottom: ".8rem",
    padding: "0 .3rem",
    borderRadius: "0 0 .5rem .5rem",
    boxShadow: "0 1px 1px 0 rgba(0,0,0,0.1)",
  },

  imageContent: {
    display: "flex",
    justifyContent: "space-between",
    gap: 2,
    width: "100%",
    // padding: "0 .5rem",
  },
  giftCardImage: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 2,
  },
  imageContainer: {
    height: "5rem",
    width: "60%",
    display: "flex",
    alignItems: "center",
    padding:".3rem"
  },
};
