/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import actionType from "./../actions/actionTypes";

const initialState = {
  error: true,
  msg: "",
  giftCardSetting: {},
};

const GiftCardReduser = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_GIFTCARD_SETTINGS:
      // console.log(action.payload);
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        giftCardSetting: action.payload.data.gift_card_settings,
      };
    default:
      return state;
  }
};

export default GiftCardReduser;
